import { createSlice } from '@reduxjs/toolkit';

const initialState = { verificationInfo: {}, isFetching: false };

const verificationSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    setVerificationMode(state, { payload }) {},
    getVerificationInfo() {},
    setVerificationInfo(state, action) {
      const { verificationInfo } = action.payload;
      return {
        ...state,
        verificationInfo,
      };
    },
    sendCoordinates(state, { payload }) {},
    setVerificationIsFetching(state, { payload }) {
      return {
        ...state,
        isFetching: payload.isFetching,
      };
    },
  },
});

export const verificationActions = verificationSlice.actions;

export default verificationSlice.reducer;

import { GoalStatus, IGoal } from '../GoalTrackerTypes';
import bigGoalDone from '../../../images/big-goal.svg';
import bigGoal from '../../../images/big-goal-done.svg';
import { getProgressWidth } from '../../../helpers/goalsHelper';
import styles from './GoalTrackerProgressBar.module.scss';

interface IProgressBar {
  goals: IGoal[];
  progress: number;
}

const GoalTrackerProgressBar = ({ goals, progress }: IProgressBar) => (
  <div className={styles.progressBarContainer}>
    <div className={styles.progressBar}>
      {goals.map((goal: IGoal) => (
        <div className={styles.goalProgress} key={goal.id}>
          <div className={styles.colorProgress}>
            <div
              className={styles.notAchievedProgress}
              style={{
                width: getProgressWidth(goal, progress),
                borderRadius: progress > 0 ? '0px' : 'auto',
              }}
            ></div>
          </div>
          <div className={styles.progressBadgeContainer}>
            <img className={styles.progressBadge} src={goal.status === GoalStatus.ACTIVE ? bigGoal : bigGoalDone} />
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default GoalTrackerProgressBar;

import { createSelector } from 'reselect';

export const selectedProvidersSelectorFactory = () =>
  createSelector(
    (state) => state.bonusShop.providers,
    (_, categoryId) => categoryId,
    (providers, categoryId) =>
      providers ? providers.filter((provider) => String(provider.category_id) === String(categoryId)) : [],
  );

export const getSelectedProvider = createSelector(
  [(state) => state.bonusShop.providers, (state) => state.bonusShop.selectedProviderId],
  (providers, selectedProviderId) => {
    if (providers && selectedProviderId) {
      return providers.find((i) => i.id === selectedProviderId);
    } else {
      return null;
    }
  },
);

export const getSelectedProductId = createSelector(
  (state) => state.bonusShop.selectedProductId,
  (id) => id,
);

export const getPrizesSelector = createSelector([(state) => state.bonusShop.prizes], (prizes) => prizes);

export const getTransfersSelector = createSelector([(state) => state.bonusShop.transfers], (transfers) => transfers);

export const getOppaTransfersSelector = createSelector(
  [(state) => state.bonusShop?.transfers?.transfers],
  (transfers) => transfers,
);

export const disabledgetOppaTimerButton = createSelector(
  [(state) => state.bonusShop?.disabledButton],
  (disabledButton) => disabledButton,
);

export const launchTimerOppaButton = createSelector(
  [(state) => state.bonusShop?.launchTimer],
  (launchTimer) => launchTimer,
);

export const getIsTransferPendingSelector = createSelector(
  [(state) => state.bonusShop.isTransferPending],
  (isTransferPending) => isTransferPending,
);

export const getFirstViewSelector = createSelector(
  [(state) => state.bonusShop.isTutorialPassed],
  (isTutorialPassed) => isTutorialPassed,
);

export const getHeaderImageSelector = createSelector([(state) => state.bonusShop.categories], (categories) => {
  const transferCategory = categories.find((category) => category.type === 3);
  return transferCategory ? transferCategory.header_image : null;
});

export const getUmicoHeaderImageSelector = createSelector([(state) => state.bonusShop.categories], (categories) => {
  const transferCategory = categories.find((category) => category.type === 5);
  return transferCategory ? transferCategory.header_image : null;
});

export const getPERXHeaderImageSelector = createSelector([(state) => state.bonusShop.categories], (categories) => {
  const transferCategory = categories.find((category) => category.id === '1000');
  return transferCategory ? transferCategory.header_image : null;
});

export const getProductsBySelectedProvider = createSelector(
  [(state) => state.bonusShop.products, (state) => state.bonusShop.selectedProviderId],
  (products, selectedProviderId) => {
    if (products && selectedProviderId) {
      return products.filter((i) => i.provider_id === selectedProviderId);
    } else {
      return [];
    }
  },
);

export const getCurrentCatergoryType = createSelector(
  [(state) => state.bonusShop.categories, (state) => state.bonusShop.currentCategoryId],
  (categories, categoryId) => {
    if (categoryId) {
      return categories.find((i) => i.id === categoryId).type;
    }
    return null;
  },
);

export const getCurrentCatergory = createSelector(
  [(state) => state.bonusShop.categories, (state) => state.bonusShop.currentCategoryId],
  (categories, categoryId) => {
    if (categoryId) {
      return categories.find((i) => i.id === categoryId);
    }
    return null;
  },
);

export const getSelectedProduct = createSelector(
  [(state) => state.bonusShop.products, (state) => state.bonusShop.selectedProductId],
  (products, selectedProductId) => {
    if (products && selectedProductId) {
      return products.find((i) => i.id === selectedProductId);
    } else {
      return null;
    }
  },
);

export const getAddress = createSelector(
  [(state) => state.bonusShop.counties, (state) => state.bonusShop.cities, (state) => state.bonusShop.user],
  (counties, cities, user) => ({ counties, cities, user }),
);

export const getIsFetching = createSelector([(state) => state.bonusShop.isFetching], (isFetching) => isFetching);

export const getCurrentCoinsReserved = createSelector(
  [(state) => state.bonusShop.currentCoinsReserved],
  (currentCoinsReserved) => currentCoinsReserved,
);

export const getPerxSegmentationCoefficient = createSelector(
  [(state) => state.bonusShop.coefficient],
  (coefficient) => coefficient,
);

export const getUmicoTransfersSelector = createSelector(
  [(state) => state.bonusShop?.transfers?.transfers],
  (transfers) => transfers,
);

export const getUserAccountsSelector = createSelector(
  [(state) => state.bonusShop.cardAccounts],
  (cardAccounts) => cardAccounts,
);

export const getUnicardTransfersSelector = createSelector(
  [(state) => state.bonusShop.unicardTransfers],
  (cardAccounts) => cardAccounts,
);

export const getUnicardUserAccount = createSelector(
  [(state) => state.bonusShop.unicardAccount],
  (unicardAccount) => unicardAccount,
);

export const selectEpulTransfers = createSelector(
  [(state) => state.bonusShop.epulTransfers],
  (epulTransfers) => epulTransfers,
);

export const selectErrorMessage = createSelector(
  [(state) => state.bonusShop.errorMessage],
  (errorMessage) => errorMessage,
);

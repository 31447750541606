import { ReactElement } from 'react';
import classnames from 'classnames';

import { UncheckedNotificationBadge } from '../common/UncheckedNotificationBadge/UnchakedNotificationBadge';
import './quizCard.scss';

interface IBrand {
  name?: string;
  quiz_tagline?: string;
  count?: number;
}

interface ICurrentBrand {
  id: number;
  color: string;
}

interface IQuizCard {
  title: string;
  brand: IBrand;
  onClick: () => void;
  isNew?: boolean;
  minutesToRead?: number | null;
  publishedAt?: string | undefined;
  showBrand?: boolean;
  showDate?: boolean;
  minutesToReadMessage?: string;
  currentBrand?: ICurrentBrand;
  cardDescription?: string;
  rewardElement?: () => ReactElement;
}

const QuizCard = ({
  title,
  brand,
  onClick,
  isNew,
  minutesToRead,
  publishedAt,
  showBrand,
  showDate,
  minutesToReadMessage,
  currentBrand,
  cardDescription,
  rewardElement,
}: IQuizCard): ReactElement => {
  return (
    <div className="quiz-card-block" onClick={onClick}>
      <div className="quiz-card-block-main">
        <p className={classnames('quiz-card-block-main-name', { themed: isNew })}>{title}</p>
        {isNew && (
          <UncheckedNotificationBadge
            customClass="quiz-card-block-main-unread-badge"
            children={undefined}
            withoutTheme={undefined}
          />
        )}
      </div>
      <div className="quiz-card-block-info">
        <div className="quiz-card-block-info-block">
          {!!cardDescription && (
            <>
              <span className="quiz-card-block-info-counter">{brand?.count || 0}</span>
              <div className="quiz-card-block-info-description">{cardDescription}</div>
            </>
          )}
          {showDate && publishedAt && rewardElement?.()}
          {minutesToRead && <p className="quiz-card-block-info-block-badge">{minutesToReadMessage}</p>}
        </div>
        {showBrand && brand && (
          <p className="quiz-card-block-info-brand" style={{ color: currentBrand ? currentBrand.color : '#000000' }}>
            #{brand.name || brand.quiz_tagline}
          </p>
        )}
      </div>
    </div>
  );
};

export default QuizCard;

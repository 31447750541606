import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  achievements: null,
  stats: null,
  selectedAchievement: null,
  isFetching: false,
};

const achievementsSlice = createSlice({
  name: 'achievements',
  initialState,
  reducers: {
    getAchievements() {},
    setAchievements(state, action) {
      const { achievements, stats } = action.payload;
      return {
        ...state,
        achievements: achievements,
        stats: stats,
      };
    },
    getAchievementById(id) {},
    setSelectedAchievement(state, action) {
      return {
        ...state,
        selectedAchievement: action.payload,
      };
    },
    claimReward(payload) {},
    resetSelectedAchievement(state) {
      return {
        ...state,
        selectedAchievement: null,
      };
    },
    setIsFetching(state, { payload }) {
      return {
        ...state,
        isFetching: payload.isFetching,
      };
    },
  },
});

export const achievementsActions = achievementsSlice.actions;

export default achievementsSlice.reducer;

import { useState } from 'react';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { CloseIcon } from '../../icons/close-icon';
import card from './assets/card.png';

const Banner = () => {
  const { t } = useInternationalization();

  const [isBannerOpen, setIsBannerOpen] = useState(true);

  if (!isBannerOpen) {
    return null;
  }

  return (
    <div className="banner">
      <div className="label">{t('promo_code_scan.banner.title')}</div>
      <div className="card-wrapper">
        <img className="card" src={card} />
      </div>
      <CloseIcon className="сlose-icon" stroke="#fff" onClick={() => setIsBannerOpen(false)} />
    </div>
  );
};

export default Banner;

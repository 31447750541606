import { API_URL } from './baseApi';
import axios from 'axios';

export const lepApi = {
  getCompetitions: async () => {
    return await axios.get(`${API_URL}/v1/lep`);
  },

  validationCode: async (code, competition_id) => {
    return await axios.get(`${API_URL}/v1/lep/validation?code=${code}&competition_id=${competition_id}`);
  },
  getCodes: async (competition_id) => {
    return await axios.get(`${API_URL}/v1/lep/${competition_id}`);
  },
  setCode: async (code, competition_id) => {
    return await axios.post(`${API_URL}/v1/lep/code`, { code, competition_id });
  },
  setCodeUnregistered: async (userData) => {
    return await axios.post(`${API_URL}/v1/lep/code-unregistered`, userData);
  },
  singup: async (userData) => {
    return await axios.post(`${API_URL}/v1/lep/register`, userData);
  },
};

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import SwipeableViews from 'react-swipeable-views';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { NavigationMenu } from '../../../components';
import { ProfileSettingsBelarus } from '../../profileSettings/profileSettingsBelarus';
import PlugCheckIn from '../profileCaucasusLayout/PlugCheckIn/PlugCheckIn';
import '../profilePage.scss';

export const ProfileBelarusLayout = () => {
  const [activePage, setActivePage] = useState(0);
  const history = useHistory();

  const { formatMessage } = useInternationalization();
  const location = history.location;

  const setCurrentLocation = (index: number) => {
    switch (index) {
      case 0:
        history.push('/profile');
        return;
      case 1:
        history.push('/check-in-status');
        return;
      default:
        return;
    }
  };

  const currentIndexOfPage = (pathname: string) => {
    switch (pathname) {
      case '/profile':
        return 0;
      case '/check-in-status':
        return 1;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setActivePage(currentIndexOfPage(location.pathname));
  }, [location]);

  return (
    <div className="profile-page">
      <div className="profile-page__swipeable-header">
        <div
          onClick={() => setCurrentLocation(0)}
          className={activePage === 0 ? 'profile-page__active-tab' : 'profile-page__tab'}
        >
          {formatMessage('Profile')}
        </div>
        <div
          onClick={() => setCurrentLocation(1)}
          className={activePage === 1 ? 'profile-page__active-tab' : 'profile-page__tab'}
        >
          {formatMessage('Check-in')}
        </div>
      </div>
      <SwipeableViews
        index={activePage}
        id="swipable-views"
        onSwitching={(index) => setCurrentLocation(index)}
        className="profile-page__views"
      >
        <ProfileSettingsBelarus />
        <PlugCheckIn />
      </SwipeableViews>
      <NavigationMenu location={location} />
      <DisclaimerFooter />
    </div>
  );
};

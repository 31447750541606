import { API_URL } from './baseApi';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ChatRole, MessageType } from '../redux/chat/chatTypes';

export const chatApi = {
  getMessages: async ({
    limit,
    offset,
    role = ChatRole.Administrator,
    ticketId = '',
    isNotification = false,
  }: {
    limit: number;
    offset: number;
    role?: ChatRole;
    ticketId?: string;
    isNotification?: boolean;
  }) => {
    return await axios.get(
      `${API_URL}/v3/messages?limit=${limit}&offset=${offset}&roles=${role}&ticket_id=${ticketId}&notification=${isNotification}`,
    );
  },

  sendMessage: async (
    message: { text: string; image?: object },
    role = ChatRole.Administrator,
    ticket_id: string = '',
    type?: number,
  ) => {
    return await axios.post(`${API_URL}/v3/messages/send?roles=${role}&ticket_id=${ticket_id}`, {
      message,
      id: uuidv4(),
      type,
    });
  },
  getUnreadCount: async () => {
    return await axios.get(`${API_URL}/v3/messages/unread-count`);
  },
  completeTutorialStep: async (step_id: number) => {
    return await axios.post(`${API_URL}/v4/tutorial/step/complete`, { step_id });
  },
  getProblems: async (ticketId: string, parentId?: number) => {
    return await axios.get(`${API_URL}/v3/tickets/problems?ticket_id=${ticketId}&parent_id=${parentId || ''}`);
  },
  chooseProblem: async (problemId: number, ticketId: number) => {
    return await axios.post(`${API_URL}/v3/tickets/choose-problem?problem_id=${problemId}&ticket_id=${ticketId}`);
  },
  chooseOther: async (ticketId: string, text: string, type?: MessageType) => {
    return await axios.post(
      `${API_URL}/v3/tickets/other?ticket_id=${ticketId}&text=${text}${type ? `&type=${type}` : ''}`,
    );
  },
  getTickets: async () => {
    return await axios.get(`${API_URL}/v3/tickets?limit=${20}`);
  },
  getTicket: async (ticketId: string) => {
    return await axios.get(`${API_URL}/v3/tickets/${ticketId}`);
  },
  createTicket: async () => {
    return await axios.post(`${API_URL}/v3/tickets`);
  },
  closeTicket: async (ticketId: string) => {
    return await axios.put(`${API_URL}/v3/tickets?id=${ticketId}`);
  },
  updateLastCheckedTicketMessage: async (ticketId: string) => {
    return await axios.put(`${API_URL}/v3/messages/last-ticket-read?ticket_id=${ticketId}`);
  },
};

import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { quickTipsSelectors } from '../../../redux/quickTips/quickTipsSelectors';
import { quickTipsActions } from '../../../redux/quickTips/quickTipsSlice';

import { Routes } from '../../../const';
import { Button } from '../../../components/common/Button';
import { ContentSpinner } from '../../../components/common/Spinner/ContentSpinner';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';

import failIcon from '../../../images/icon-fail.png';
import successIcon from '../../../images/icon-success.png';

import styles from './QuickTipResult.module.scss';

const QuickTipResult = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useInternationalization();
  const result = useSelector(quickTipsSelectors.getResult);
  const isFetching = useSelector(quickTipsSelectors.getIsFetching);

  const resultMessage = result?.correct ? 'Your answer is correct' : 'Your answer is incorrect';

  const onButtonClick = () => {
    dispatch(quickTipsActions.resetQuickTip());
    history.push(Routes.MainRoute);
  };

  return (
    <div className={styles.container}>
      {isFetching ? (
        <ContentSpinner />
      ) : (
        <>
          <img className={styles.image} src={result?.correct ? successIcon : failIcon} alt="" />
          <div className={styles.textBlock}>
            <span className={classnames(styles.correct, { [styles.unCorrect]: !result?.correct })}>
              {formatMessage(`${result?.correct ? '+' : ''}${result?.coins} points`)}
            </span>
            <span className={styles.resultMessage}>{formatMessage(resultMessage)}</span>
          </div>
          <Button onClick={onButtonClick} customClass={styles.button}>
            {formatMessage('OK')}
          </Button>
          <DisclaimerFooter withoutNavigation />
        </>
      )}
    </div>
  );
};

export default QuickTipResult;

export const ChatRoutes = {
  Notifications: '/notifications',
  Support: '/support',
  JTI: '/jti',
};

export const Routes = {
  EmptyRoute: '/',
  SuccessPage: '/success',
  ErrorPage: '/error',
  ...ChatRoutes,
  Recognition: '/recognition',
  CheckInInfoRoute: '/check-in-info',
  AddressRegistration: '/address-registration',
  PassportStatement: '/passport-statement',
  PassportSigning: '/passport-signing',
  PassportSuccess: '/passport-success',
  MainRoute: '/main',
  QuizRoute: '/quiz',
  BonusShopRoute: '/bonus-shop',
  OrderDetails: '/order-details',
  BalanceRoute: '/balance',
  PrizesRoute: '/prizes',
  MoneyForThePhone: '/money-for-the-phone',
  NoBonusGamesRoutes: '/no-bonus-games-info',
  KnowledgeBankRoute: '/knowledge-bank',
  BrandingBanRoute: '/branding-ban',
  ProfileRoute: '/profile',
  ProfileSettingsRoute: '/profile-settings',
  SuccessfulOrderRoute: '/successful-order',
  PromoCodeRoute: '/promo-code',
  PromoCodeScan: '/promo-code-scan',
  ChallengeRoute: '/challenge',
  ChallengesRoute: '/challenges',
  ChanceRoute: '/chance',
  ArticleRoute: '/article',
  BannerRoute: '/banner',
  InteractiveArticleRoute: '/interactive-article',
  QuestionOfTheDayRoute: '/question-of-the-day',
  SpecialQuizRoute: '/special-quiz',
  InvitationRoute: '/invitation',
  EventModeRoute: '/event-mode',
  NoBonusSpecialQuizRoute: '/no-bonus-special-quiz',
  SoloMode: '/solo-mode',
  SingleMode: '/single-mode',
  DailyRewardsRoute: '/daily-rewards',
  SalesTips: '/sales-tips',
  BrandsRoute: '/brands',
  ProgressRoute: '/progress',
  SurveyRoute: '/survey',
  noBonusSurveyRoute: '/no-bonus-survey',
  MultibrandQuizRoute: '/multibrand-quiz',
  LoyaltyProgramRoute: '/loyalty-program',
  CheckInFAQRoute: '/check-in-faq',
  CheckInStatusRoute: '/check-in-status',
  ManagerPageRoute: '/manager',
  SalesSimulators: '/sales-simulators',
  ScenarioRoute: '/scenario',
  GetPassportPhoto: '/get-passport-photo',
  PassportSettings: '/passport-settings',
  BonusShopLimit: '/bonus-shop-limit',
  InviteCodeRoute: '/invite-code',
  PrivacyPolicyUpdate: '/privacy-policy-update',
  MoneyForTheWallet: '/money-for-the-wallet',
  DigitalFramePhoto: '/digital-frame-photo',
  Boosters: '/boosters',
  Achievements: '/achievements',
  SelectedAchievement: '/achievement',
  LEP: '/lep',
  OPPAMainRoute: '/oppa',
  M10MainRoute: '/m10',
  PERX: '/perx',
  Image: '/image',
  DailyGame: '/daily-game',
  Unicard: '/unicard',
  Epul: '/epul',
  BrandInformation: '/brand-information',
  QuickTips: '/quick-tips',
  QuickTipsResult: '/quick-tips-result',
  IdCard: '/id-card',
  IdCardInfo: '/id-card-info',
  IdCardResult: '/id-card-result',
  RecognitionFaq: '/recognition-faq',
  MysteryShopperStartPage: '/mystery-shopper-start',
  MysteryShopperQuizPage: '/mystery-shopper-quiz',
  MysteryShopperList: '/mystery-shopper-list',
  PassedMysteryShopperList: '/passed-mystery-shopper-list',
  MysteryShopperRecommendations: '/mystery-shopper-recommendations',
  MysteryShopperResult: '/mystery-shopper-result',
  MysteryShopperModeration: '/mystery-shopper-moderation',
  ChanceList: '/chance-list',
  Moderation: '/moderation',
  BaStatistic: '/ba-statistic',
  MonthlyConfirmation: '/monthly-confirmation',
  CashoutDemo: '/cashout-demo',
  MyVouchers: '/redeemed-vouchers',
  VoucherDetails: '/voucher-details',
  ColleaguesInvitation: '/colleagues-invitation',
  SelfRegistration: '/registration',
  PasswordChange: '/change-password',
};

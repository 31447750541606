import { appConstants } from '../../const/appConstants';

export const getPhoneCode = () => {
  if (!appConstants) {
    console.warn('No app constants defined');
  }

  return appConstants.APP_PHONE_CODE;
};

export const AUTH_ACTION_TYPES = {
  SEND_PHONE: 'auth/sendPhone',

  RESET_PASSWORD: 'auth/resetPassword',

  CHANGE_PASSWORD: 'auth/changePassword',

  SIGN_IN: 'auth/signIn',

  AUTH_BY_PHONE: 'auth/authByPhone',

  AUTH_BY_TOKEN: 'auth/authByToken',

  LOGOUT: 'auth/logout',

  REMOVE_ACCOUNT: 'auth/removeAccount',

  SIGNUP_SUCCESS: 'auth/signupSuccess',
};

export const PHONE_CODE = getPhoneCode();

export const ESIGN_STATUSES = {
  NOT_REQUESTED: 0,
  REQUESTED: 1,
  COMPLETED: 2,
  FAILED: 3,
  EXPIRED: 4,
};

export const USER_STATUS = {
  INACTIVE: 0,
  ACTIVE: 1,
  WAITING_MODERATION: 2,
  WAITING_REMOVE: 3,
  BLOCKED: 4,
  WAITING_REGISTRATION: 7,
  BLOCK_BY_ADMIN: 8,
};

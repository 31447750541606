import axios from 'axios';

import { API_URL } from './baseApi';

export const quizApi = {
  searchUser: async (brand_id) => {
    return await axios.post(`${API_URL}/v3/games/search/user`, {
      brand_id,
      for_coins: true,
    });
  },
  searchBot: async (brand_id, for_coins = false) => {
    return await axios.post(`${API_URL}/v3/games/search/bot`, {
      brand_id,
      for_coins,
    });
  },
  inviteOpponent: async (user_id, pack_id, brand_id) => {
    return await axios.post(`${API_URL}/v4/games/invite`, {
      user_id,
      pack_id,
      brand_id,
    });
  },
  startQuiz: async (pack_id) => {
    return await axios.post(`${API_URL}/v4/games/start`, {
      pack_id,
    });
  },
  startSpecialQuiz: async (pack_id) => {
    return await axios.post(`${API_URL}/v4/games/start/quiz`, {
      pack_id,
    });
  },
  startQuizWithBot: async (pack_id) => {
    return await axios.post(`${API_URL}/v4/games/start/bot`, {
      pack_id,
    });
  },
  finishQuizWithBot: async ({ pack_id, type, user_points, bot_id, bot_points, answers, has_user_left }) => {
    return await axios.post(`${API_URL}/v3/games/finish/bot`, {
      pack_id,
      type,
      user_points,
      bot_id,
      bot_points,
      answers,
      has_user_left,
    });
  },
  finishQuiz: async ({ pack_id, type, user_points, user2_id, user2_points, answers, has_user_left }) => {
    return await axios.post(`${API_URL}/v3/games/finish`, {
      pack_id,
      type,
      user_points,
      user2_id,
      user2_points,
      answers,
      has_user_left,
    });
  },
  finishSpecialQuiz: async (pack_id, type, answers, article_id) => {
    return await axios.post(`${API_URL}/v4/games/finish/quiz`, {
      pack_id,
      article_id,
      type,
      answers,
    });
  },
  startEventMode: async () => {
    return await axios.post(`${API_URL}/v1/event-mode/start`);
  },
  finishEventMode: async (pack_id, correct_answers) => {
    return await axios.post(`${API_URL}/v1/event-mode/finish`, { pack_id, correct_answers });
  },
  startSoloMode: async (brand_id) => {
    return await axios.post(`${API_URL}/v3/solo-mode/start`, { brand_id });
  },
  finishSoloMode: async (correct_answers, pack_id, answers) => {
    return await axios.post(`${API_URL}/v3/solo-mode/finish`, { correct_answers, pack_id, answers });
  },
  startSingleMode: async () => {
    return await axios.post(`${API_URL}/v1/single-mode/start`);
  },
  finishSingleMode: async (stages, pack_id) => {
    return await axios.post(`${API_URL}/v1/single-mode/finish`, { stages, pack_id });
  },
  startMultibrandQuiz: async () => {
    return await axios.get(`${API_URL}/v3/games/multibrand`);
  },
  finishMultibrandQuiz: async (pack_id, answers, article_id) => {
    return await axios.post(`${API_URL}/v4/games/finish/quiz`, {
      pack_id,
      article_id,
      answers,
    });
  },
  setQuizRate: async (pack_id, quiz_mark) => {
    return await axios.post(`${API_URL}/v1/games/quizzes-rates`, { pack_id, quiz_mark });
  },
};

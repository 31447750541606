import { createSelector } from 'reselect';

export const getUserIdSelector = createSelector([(state) => state.auth.userId], (userId) => userId);

export const isAuthorizedSelector = createSelector([(state) => state.auth], (authReducer) => !!authReducer.token);

export const getSignUpDataSelector = createSelector([(state) => state.auth.signUpData], (signUpData) => signUpData);

export const getIsButtonDisable = createSelector(
  [(state) => state.auth.isButtonDisabled],
  (isButtonDisabled) => isButtonDisabled,
);

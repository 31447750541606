import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { BONUS_SHOP_ACTION_TYPES, EPUL_PAY_STATUSES, EPUL_SMS_STATUSES } from './bonusShopConstants';
import { bonusShopActions } from './bonusShopSlice';
import { profileActions } from '../profile/profileSlice';
import { bonusShopApi } from '../../api/bonusShopApi';
import { Routes } from '../../const';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';
import { APP_TYPES } from '../../const/appConstants';
import { getCurrentCatergoryType, launchTimerOppaButton } from './bonusShopSelectors';
import { getUserInfoSelector } from '../profile/profileSelectors';
import { getUserInfoSaga } from '../profile/profileSagas';
import { oppaSteps } from '../../pages/Cashout/OPPA/OPPA';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { amountTimerOppaButton } from '../../pages/Cashout/OPPA/oppaWallet/oppaMainPage';
import { oppaTutorialSteps } from '../../pages/Cashout/OPPA/oppaTutorial/oppaTutorial';
import { m10Steps } from '../../pages/Cashout/M10/M10';
import { unicardTimerValue } from '../../pages/Cashout/unicard/unicardMainPage/UnicardMainPage';
import { perxSteps } from '../../pages/Cashout/PERX/perxTrensferMoneyPage';
import { perxTimerValue } from '../../pages/Cashout/PERX/PERXMainPage';
import { analyticHelper } from '../../helpers/analyticHelper';
import { getUserIdSelector } from '../auth/authSelectors';
import { selectPathname } from '../router/routerSelectors';
import epulLogo from '../../images/epulLogo.png';
import { epulSteps } from '../../pages/Cashout/epul/Epul';
import { epulTimerValue } from '../../pages/Cashout/epul/EpulMainPage/EpulMainPage';

function* getCategoriesSaga() {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.getCategories();

    if (response.data) {
      yield put(bonusShopActions.setCategories({ categories: response.data }));
    }
  } catch (err) {
    console.error('getCategories error', err);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* selectCategorySaga({ payload: { selectedCategoryId } }) {
  try {
    yield put(push(`${Routes.BonusShopRoute}/${selectedCategoryId}`));
  } catch (err) {
    console.error('selectCategory error', err);
  }
}

function* getProvidersSaga({ payload: { selectedCategoryId } }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.getProviders(selectedCategoryId);

    if (response.data) {
      const callStack = [];
      yield put(bonusShopActions.setProviders({ providers: response.data }));
      if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) {
        yield call(getProductsSaga, { payload: { selectedProviderId: response.data[0].id } });

        response.data.slice(1).forEach((provider) => {
          callStack.push(
            call(getProductsSaga, {
              payload: {
                selectedProviderId: provider.id,
                isAddMode: true,
              },
            }),
          );
        });
        yield all(callStack);
      } else {
        yield put(bonusShopActions.setProducts({ products: response.data[0].products }));

        response.data.slice(1).forEach((provider) => {
          callStack.push(put(bonusShopActions.addProducts({ products: provider.products })));
        });
        yield all(callStack);
      }
    }
  } catch (err) {
    console.error('getCategories error', err);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* getProductsSaga({ payload: { selectedProviderId, isAddMode } }) {
  try {
    const response = yield bonusShopApi.getProducts(selectedProviderId);

    if (response.data) {
      if (isAddMode) {
        yield put(bonusShopActions.addProducts({ products: response.data }));
      } else {
        yield put(bonusShopActions.setProducts({ products: response.data }));
      }
    }
  } catch (err) {
    console.error('getCategories error', err);
  }
}

function* selectProductSaga({ payload: { selectedProductId } }) {
  try {
    yield put(bonusShopActions.setSelectedProductId({ selectedProductId }));
  } catch (err) {
    console.error('getCategories error', err);
  }
}

function* checkAvailabilitySaga({ payload }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));

    const { productId } = payload;
    const type = yield select(getCurrentCatergoryType);

    const response = yield bonusShopApi.checkAvailability({ productId, type });

    if (response?.status === 200) {
      yield put(push(Routes.OrderDetails));
    }
  } catch (err) {
    if (err.response && err.response.status === 400) {
      yield put(
        modalActions.openPromptModal({
          btnText: 'OK',
          description: err.response.data.message,
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
          onClose: () => store.dispatch(modalActions.closeModal()),
        }),
      );
    }
  }
  yield put(bonusShopActions.setIsFetching(false));
}

function* makeOrderSaga({ payload }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));

    const { productId, voucherId, product_color_id, order_details } = payload;
    const type = yield select(getCurrentCatergoryType);

    const response = yield bonusShopApi.makeOrder({
      type,
      productId,
      voucherId,
      product_color_id,
      order_details,
    });

    if (response && response.status === 200) {
      yield put(push(Routes.SuccessfulOrderRoute));

      yield put(profileActions.getUserInfo());
      yield call(getPrizesSaga);
    }
  } catch (err) {
    if (err.response && err.response.status === 400) {
      yield put(
        modalActions.openPromptModal({
          btnText: 'OK',
          description: err.response.data.message,
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
          onClose: () => store.dispatch(modalActions.closeModal()),
        }),
      );
    }
  }
  yield put(bonusShopActions.setIsFetching(false));
}

function* getPrizesSaga() {
  try {
    const response = yield bonusShopApi.getPrizes();

    if (response && response.data) {
      yield put(bonusShopActions.setPrizes({ prizes: response.data }));
    }
  } catch (err) {
    console.error('getPrizes error', err);
  }
}

function* getTransfersSaga(payload) {
  const isKibritTransfers = payload?.payload;

  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.getTransfers(isKibritTransfers);

    if (response && response.data) {
      let transfers = response.data;
      if (isKibritTransfers) {
        transfers = transfers.filter((transfer) => transfer.shop === 'KirbitTech');
      }

      yield put(bonusShopActions.setTransfers({ transfers }));
    }
  } catch (err) {
    console.error('getTransfers error', err);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* setOppaWalletSaga({ payload }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const { wallet_number, personal_number } = payload;

    const response = yield bonusShopApi.setOppaWallet({
      wallet_number: wallet_number,
      personal_number: personal_number,
    });

    if (response && response.status === 200) {
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.oppaStepList,
          stepId: oppaSteps.successCreateWallet,
        }),
      );
    }
  } catch (err) {
    yield put(
      modalActions.openPromptModal({
        title: 'Something went wrong. Try again later',
        btnText: 'OK',
        description: err.response?.data?.message,
      }),
    );
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* requestOppaSmsSaga({ payload }) {
  try {
    const { onClose } = payload;
    yield put(bonusShopActions.setTransferPending(true));
    const response = yield bonusShopApi.requestOppaSms();

    if (response && response.status === 200) {
      yield put(modalActions.openSMSCodeInputModal({ onClose }));
    }
  } catch (err) {
    yield put(
      modalActions.openPromptModal({
        title: 'Ooops',
        btnText: 'OK',
        description: 'Something went wrong. Try again later',
      }),
    );
  } finally {
    yield put(bonusShopActions.setTransferPending(false));
  }
}

function* makeOppaTransferSaga({ payload }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const { amount, code } = payload;
    const response = yield bonusShopApi.makeOppaTransfer({ amount, code });

    if (response && response.status === 200) {
      const isTutorial = !localStorage.getItem('oppaCompletedTutorial');
      const syncRecursionModal = () => {
        store.dispatch(
          modalActions.openPromptModal({
            title: 'Success',
            btnText: 'OK',
            description: 'Your transaction is completed',
            onButtonClick: () => {
              if (isTutorial) {
                store.dispatch(
                  stepListActions.setStep({
                    stepListId: STEP_LIST_IDS.oppaTutorialStepList,
                    stepId: oppaTutorialSteps.step4,
                  }),
                );
                store.dispatch(modalActions.closeModal());
              } else {
                store.dispatch(modalActions.closeModal());
              }
            },
            onClose: () => {
              if (isTutorial) {
                store.dispatch(
                  modalActions.openPromptModal({
                    title: 'Are you sure you want to finish?(oppa)',
                    btnText: 'Yes, exit',
                    onButtonClick: () => {
                      localStorage.setItem('oppaCompletedTutorial', true);
                      store.dispatch(
                        stepListActions.setStep({
                          stepListId: STEP_LIST_IDS.oppaTutorialStepList,
                          stepId: oppaTutorialSteps.stepNull,
                        }),
                      );
                      store.dispatch(modalActions.closeModal());
                    },
                    onClose: syncRecursionModal,
                  }),
                );
              }
            },
          }),
        );
      };
      syncRecursionModal();
      yield call(getUserInfoSaga);
      const launchTimer = yield select(launchTimerOppaButton);
      localStorage.setItem('endTimeDisableOppaButton', new Date().getTime() + amountTimerOppaButton);
      yield put(bonusShopActions.launchTimerOppaButton({ launchTimer: !launchTimer }));
    }
  } catch (err) {
    yield put(
      modalActions.openPromptModal({
        title: 'Something went wrong. Try again later',
        btnText: 'OK',
        description: err?.response?.data?.message,
      }),
    );
    yield call(getUserInfoSaga);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* setUmicoWalletSaga({ payload }) {
  yield put(bonusShopActions.setIsFetching(true));
  try {
    const { wallet_number } = payload;
    yield bonusShopApi.setUmicoWallet({ wallet_number });

    yield put(bonusShopActions.setIsFetching(false));
    yield put(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.umicoStepList,
        stepId: m10Steps.enterCardNumberSuccess,
      }),
    );

    yield call(getUserInfoSaga);
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));
    yield put(
      modalActions.openPromptModal({
        title: 'Oops. Something went wrong.',
        btnText: 'OK',
        description: err.response.data.message,
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
      }),
    );
  }
}

function* makeUmicoTransferSaga({ payload }) {
  try {
    const { amount } = payload;
    const response = yield bonusShopApi.makeUmicoTransfer({ amount });

    if (response && response.status === 200) {
      yield put(
        modalActions.openPromptModal({
          title: 'Success',
          btnText: 'OK',
          description: 'Your transaction is completed',
        }),
      );
      const launchTimer = yield select(launchTimerOppaButton);
      yield put(bonusShopActions.launchTimerOppaButton({ launchTimer: !launchTimer }));
    }
  } catch (err) {
    yield put(modalActions.closeModal());
    yield put(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.umicoStepList,
        stepId: m10Steps.errorPage,
      }),
    );
    const launchTimer = yield select(launchTimerOppaButton);
    yield put(bonusShopActions.launchTimerOppaButton({ launchTimer: !launchTimer }));
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* makePerxTransferSaga({ payload }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.makePerxTransfer(payload);

    if (response && response.status === 200) {
      yield call(getTransfersSaga);
      yield put(bonusShopActions.setIsFetching(false));
      yield put(modalActions.closeModal());
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.perxStepList,
          stepId: perxSteps.transferSuccess,
        }),
      );
      const launchTimer = yield select(launchTimerOppaButton);
      localStorage.setItem('endTimeDisablePerxButton', new Date().getTime() + perxTimerValue);
      yield put(bonusShopActions.launchTimerPerxButton({ launchTimer: !launchTimer }));
    }
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));
    yield put(modalActions.closeModal());
    yield put(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.perxStepList,
        stepId: perxSteps.error,
      }),
    );
    const launchTimer = yield select(launchTimerOppaButton);
    localStorage.setItem('endTimeDisablePerxButton', new Date().getTime() + perxTimerValue);
    yield put(bonusShopActions.launchTimerPerxButton({ launchTimer: !launchTimer }));
  }
}

function* getAddressSaga() {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.getAddress();
    yield call(getUserInfoSaga);
    yield call(getTransfersSaga);
    yield call(getPerxSegmentationCoefficient);

    if (response && response.data) {
      yield put(bonusShopActions.setAddress({ address: response.data }));
    }
  } catch (err) {
    console.error('getAddress error', err);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* setPerxUserInfoSaga({ payload }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.setPerxUserInfo({
      county: payload.county,
      city_id: payload.city,
      address: payload.personalAddress,
      email: payload.eMail,
    });

    if (response && response.status === 200) {
      yield put(bonusShopActions.setIsFetching(false));
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.perxStepList,
          stepId: perxSteps.chooseAmount,
        }),
      );
    }
  } catch (err) {
    console.error('getAddress error', err);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* getPerxCardInvoice({ payload: { cardId } }) {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.getPerxCardInvoice(cardId);

    if (response && response.status === 200) {
      window.location.href = response.data.url;
      yield put(bonusShopActions.setIsFetching(false));
    }
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));
    console.error('getInvoice error', err);
  }
}

function* getPerxSegmentationCoefficient() {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const response = yield bonusShopApi.getPerxSegmentationCoefficient();

    if (response && response.status === 200) {
      yield put(bonusShopActions.setPerxSegmentationCoefficient({ coefficient: response.data }));
      yield put(bonusShopActions.setIsFetching(false));
    }
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));
    console.error('getCoefficient error', err);
  }
}

function* makeTransferSaga({ payload }) {
  try {
    const { amount, isTutorial } = payload;
    const { id, phone } = yield select(getUserInfoSelector);
    const pathname = yield select(selectPathname);

    yield put(bonusShopActions.setTransferPending(true));

    const response = yield bonusShopApi.makeTransfer({
      credentials: {
        amount,
        msisdn: phone.substring(4),
      },
      userId: id,
    });

    if (response && response.status === 200 && !isTutorial) {
      yield put(bonusShopActions.setTransferPending(false));
      yield put(modalActions.closeModal());
      yield call(getUserInfoSaga);
      yield put(
        modalActions.openPromptModal({
          btnText: 'OK transfer',
          title: 'Success',
          description: 'Payment on moderation',
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
          onClose: () => store.dispatch(modalActions.closeModal()),
        }),
      );
      if (pathname === Routes.MoneyForThePhone) {
        yield put(bonusShopActions.getTransfers({ isKibritTransfers: true }));
      } else {
        yield call(getTransfersSaga);
      }
    }
    if (response && response.status === 200 && isTutorial) {
      yield put(bonusShopActions.setTransferPending(false));
      yield call(getUserInfoSaga);
      yield call(getTransfersSaga);
    }
  } catch (err) {
    yield put(bonusShopActions.setTransferPending(false));
    yield put(modalActions.closeModal());
    yield put(bonusShopActions.closeTutorial());
    if (err.response && err.response.status === 400) {
      yield put(
        modalActions.openPromptModal({
          title: 'Ooops',
          btnText: 'OK',
          description: err.response.data.message,
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
          onClose: () => store.dispatch(modalActions.closeModal()),
        }),
      );
    }
    console.error('makeTransfer error', err);
  }
}

function* getUnicardUserAccounts() {
  yield put(bonusShopActions.setIsFetching(true));

  const { phone } = yield select(getUserInfoSelector);

  try {
    const userId = yield select(getUserIdSelector);

    const { data } = yield bonusShopApi.getUnicardUserAccounts(userId);

    if (data.length) {
      const mappedData = data.map((accountNumber, index) => ({ answer: accountNumber, id: index }));

      yield put(bonusShopActions.setUnicardUserAccounts(mappedData));
      yield put(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.unicardStepList }));
    } else {
      yield put(
        modalActions.openPromptModal({
          title: 'Something went wrong. Try again later',
          btnText: 'OK',
        }),
      );
    }
    yield put(bonusShopActions.setIsFetching(false));
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));

    yield put(
      modalActions.openPromptModal({
        title: 'Oops',
        btnText: 'OK',
        description: `Seems like you do not have Unicard account linked to your phone number {replacement}. Go to the payunicard.ge and create a new one`,
        descriptionReplacement: phone,
      }),
    );
  }
}

function* setUnicardWallet({ payload }) {
  yield put(bonusShopActions.setIsFetching(true));

  try {
    const { wallet_number, unicardLogo } = payload;

    yield bonusShopApi.setUnicardWalletNumber({ wallet_number });

    yield put(bonusShopActions.setIsFetching(false));

    yield put(
      push(Routes.SuccessPage, {
        title: 'Success',
        description: 'Your UNIWallet is added',
        btnText: 'OK',
        image: unicardLogo,
      }),
    );

    yield call(getUserInfoSaga);
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));
    yield put(
      modalActions.openPromptModal({
        title: 'Oops. Something went wrong.',
        btnText: 'OK',
        description: err.response.data.message,
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
      }),
    );
  }
}

function* getUnicardTransfers() {
  try {
    yield put(bonusShopActions.setIsFetching(true));
    const { data } = yield bonusShopApi.getUnicardTransfers();

    yield put(bonusShopActions.setUnicardTransfers({ transfers: data }));
    yield put(bonusShopActions.setIsFetching(false));
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));

    yield put(
      modalActions.openPromptModal({
        title: 'Something went wrong. Try again later',
        btnText: 'OK',
        description: err?.response?.data?.message,
      }),
    );
  }
}

function* requestUnicardSms({ payload }) {
  yield put(bonusShopActions.setIsFetching(true));

  try {
    const { onClose, makeTransfer } = payload;
    yield put(modalActions.openSMSCodeInputModal({ onClose, makeTransfer }));

    yield bonusShopApi.requestUnicardSms();

    yield put(bonusShopActions.setIsFetching(false));
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));

    yield put(
      modalActions.openPromptModal({
        title: 'Ooops',
        btnText: 'OK',
        description: 'Something went wrong. Try again later',
      }),
    );
  }
}

function* makeUnicardTransfer({ payload }) {
  const { amount, code, setAsyncError } = payload;

  try {
    yield put(bonusShopActions.setIsFetching(true));

    yield bonusShopApi.makeUnicardTransfer({ amount, code });

    yield put(bonusShopActions.getUnicardTransfers());
    yield put(bonusShopActions.setIsFetching(false));

    const launchTimer = yield select(launchTimerOppaButton);
    localStorage.setItem('endTimeDisableUnicardButton', new Date().getTime() + unicardTimerValue);
    yield put(bonusShopActions.launchTimerOppaButton({ launchTimer: !launchTimer }));

    yield call(getUserInfoSaga);

    yield put(
      modalActions.openPromptModal({
        title: 'Success',
        btnText: 'OK',
        description: 'Your transaction is completed',
        onButtonClick: () => {
          analyticHelper.unicardSuccessTransfer();
          store.dispatch(modalActions.closeModal());
        },
        onClose: () => {
          analyticHelper.unicardSuccessTransfer();
          store.dispatch(modalActions.closeModal());
        },
      }),
    );
  } catch (err) {
    yield put(bonusShopActions.setIsFetching(false));

    if (err?.response?.data?.message === 'Otp Not Valid') {
      setAsyncError('You’ve entered an incorrect code');
    } else {
      yield put(
        modalActions.openPromptModal({
          title: 'Something went wrong. Try again later',
          btnText: 'OK',
          description: err?.response?.data?.message,
        }),
      );
    }
  }
}

function* viewed({ payload: { reward_id } }) {
  try {
    yield bonusShopApi.viewed(reward_id);
    yield call(getPrizesSaga);
  } catch (e) {
    console.error('viewedSaga', e);
  }
}

function* isUsed({ payload: { is_used, reward_id } }) {
  try {
    yield bonusShopApi.isUsed(is_used, reward_id);
    yield call(getPrizesSaga);
  } catch (e) {
    console.error('isUsedSaga', e);
  }
}

function* checkEpulNumber({ payload }) {
  const { setIsTimerOn } = payload;

  yield put(bonusShopActions.setIsFetching(true));
  const userInfo = yield select(getUserInfoSelector);
  try {
    const { data } = yield bonusShopApi.checkEpulNumber();
    if (data === EPUL_PAY_STATUSES.ERROR) {
      yield put(
        modalActions.openPromptModal({
          title: `Oops`,
          description: `Seems like you do not have Epul account linked to your phone number {replacement}. Go to the e-pul.az and create a new one`,
          descriptionReplacement: userInfo.phone,
          btnText: 'epul OK',
          onButtonClick: () => {
            store.dispatch(modalActions.closeModal());
          },
        }),
      );
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.epulStepsList,
          stepId: epulSteps.addCardPage,
        }),
      );
    } else {
      setIsTimerOn(true);

      yield put(
        modalActions.openSMSCodeInputModal({
          makeTransfer: () => {
            store.dispatch(bonusShopActions.sendEpulSms({}));
          },
        }),
      );
    }
  } catch (error) {
    yield put(
      modalActions.openPromptModal({
        title: 'Oops. Something went wrong.',
        btnText: 'OK',
        description: error.response?.data?.message,
      }),
    );
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* resendEpulSms({ payload }) {
  const { setIsTimerOn } = payload;

  yield put(bonusShopActions.setIsFetching(true));
  try {
    const { data } = yield bonusShopApi.resendEpulSms();
    if (data === EPUL_SMS_STATUSES.RETRY_LIMIT) {
      yield put(modalActions.closeModal());

      yield put(
        push(Routes.ErrorPage, {
          description:
            'Your verification code were failed 3 times. To receive a new verification code contact Epul support',
          buttonText: 'OK',
          route: Routes.BonusShopRoute,
        }),
      );
    } else {
      setIsTimerOn(true);
    }
  } catch (error) {
    yield put(
      modalActions.openPromptModal({
        title: 'Oops. Something went wrong.',
        btnText: 'OK',
      }),
    );
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* sendEpulSms() {
  yield put(bonusShopActions.setIsFetching(true));

  try {
    const code = yield select((state) => state.form?.smsCode?.values?.smsCode);
    const { data } = yield bonusShopApi.sendEpulSms({ code });

    if (data === EPUL_SMS_STATUSES.INCORRECT_CODE) {
      yield put(bonusShopActions.setErrorMessage('You’ve entered an incorrect code'));
    } else if (data === EPUL_SMS_STATUSES.RETRY_LIMIT) {
      yield put(bonusShopActions.setErrorMessage('SMS code has expired'));
    } else if (data.status === EPUL_SMS_STATUSES.OK) {
      yield put(
        push(Routes.SuccessPage, {
          title: 'epul Success',
          description: 'Your EPULWallet is added',
          btnText: 'epul OK',
          image: epulLogo,
        }),
      );
      yield put(modalActions.closeModal());
    }
  } catch (error) {
    console.error('sendEpulSms', error);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* getEpulTransfers() {
  yield put(bonusShopActions.setIsFetching(true));
  try {
    const userInfo = yield select(getUserInfoSelector);

    const { data } = yield bonusShopApi.getEpulTransfers({ userId: userInfo.id });

    yield put(bonusShopActions.setEpulTransfers(data.result));

    if (data.is_verified) {
      yield put(stepListActions.setStep({ stepListId: STEP_LIST_IDS.epulStepsList, stepId: epulSteps.mainPage }));
    } else {
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.epulStepsList,
          stepId: epulSteps.addCardPage,
        }),
      );
    }
  } catch (error) {
    console.error('sendEpulSms', error);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* makeEpulTransfer({ payload: { amount } }) {
  yield put(bonusShopActions.setIsFetching(true));
  try {
    const { data } = yield bonusShopApi.makeEpulTransfer({ amount });
    const isSuccess = data.status === 3;

    if (isSuccess) {
      yield put(
        modalActions.openPromptModal({
          title: 'epul Success',
          description: 'Your e-pul transaction is completed',
          btnText: 'epul OK',
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
          onClose: () => store.dispatch(modalActions.closeModal()),
        }),
      );
      yield call(getEpulTransfers);
    }

    const launchTimer = yield select(launchTimerOppaButton);
    localStorage.setItem('endTimeDisableEpulButton', new Date().getTime() + epulTimerValue);
    yield put(bonusShopActions.launchTimerOppaButton({ launchTimer: !launchTimer }));
  } catch (error) {
    yield put(
      modalActions.openPromptModal({
        title: 'epul Ooops',
        description: error.response?.data?.message,
        btnText: 'epul OK',
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
        onClose: () => store.dispatch(modalActions.closeModal()),
      }),
    );
    console.error('makeEpulTransfer', error);
  } finally {
    yield put(bonusShopActions.setIsFetching(false));
  }
}

function* sagas() {
  yield all([
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_CATEGORIES, getCategoriesSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.CHOOSE_CATEGORY, selectCategorySaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PROVIDERS, getProvidersSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PRODUCTS, getProductsSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.CHOOSE_PRODUCT, selectProductSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.MAKE_ORDER, makeOrderSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.CHECK_AVAILABILITY, checkAvailabilitySaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PRIZES, getPrizesSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_TRANSFERS, getTransfersSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.MAKE_TRANSFER, makeTransferSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.SET_OPPA_WALLET, setOppaWalletSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.REQUEST_OPPA_SMS, requestOppaSmsSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.MAKE_OPPA_TRANSFER, makeOppaTransferSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.SET_UMICO_WALLET, setUmicoWalletSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.MAKE_UMICO_TRANSFER, makeUmicoTransferSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.MAKE_PERX_TRANSFER, makePerxTransferSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_ADDRESS, getAddressSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.SET_PERX_USER_INFO, setPerxUserInfoSaga),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PERX_CARD_INVOICE, getPerxCardInvoice),
    takeLatest(BONUS_SHOP_ACTION_TYPES.GET_PERX_SEGMENTATION_COEFFICIENT, getPerxSegmentationCoefficient),
    takeLatest(bonusShopActions.setUnicardWallet.type, setUnicardWallet),
    takeLatest(bonusShopActions.requestUnicardSms.type, requestUnicardSms),
    takeLatest(bonusShopActions.makeUnicardTransfer.type, makeUnicardTransfer),
    takeLatest(bonusShopActions.getUnicardUserAccounts.type, getUnicardUserAccounts),
    takeLatest(bonusShopActions.getUnicardTransfers.type, getUnicardTransfers),
    takeLatest(bonusShopActions.viewed.type, viewed),
    takeLatest(bonusShopActions.isUsed.type, isUsed),
    takeLatest(bonusShopActions.checkEpulNumber, checkEpulNumber),
    takeLatest(bonusShopActions.resendEpulSms, resendEpulSms),
    takeLatest(bonusShopActions.sendEpulSms, sendEpulSms),
    takeLatest(bonusShopActions.getEpulTransfers, getEpulTransfers),
    takeLatest(bonusShopActions.makeEpulTransfer, makeEpulTransfer),
  ]);
}

export const bonusShopSagas = sagas;

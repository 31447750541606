import { useDispatch } from 'react-redux';

import { useInternationalization } from '../../../../../hooks/useTranslationHook';
import { stepListActions } from '../../../../../components/containers/StepList/stepListSlice';
import { bonusShopActions } from '../../../../../redux/bonusShop/bonusShopSlice';
import { email, required, minLength } from '../../../../../helpers/formValidations';

import './enterUserInfo.scss';

import { ArrowButton } from '../../../../../components/common/ArrowButton/ArrowButton';
import { DisclaimerFooter } from '../../../../../components/disclaimerFooter/disclaimerFooter';
import { BackArrowButton } from '../../../../../components/common/BackArrowButton/BackArrowButton';
import { STEP_LIST_IDS } from '../../../../../components/containers/StepList/stepListConstants';
import { Input } from '../../../../../components/common/Input';
import { Select } from '../../../../../components/common/Select';
import { isIPhoneWithHomeBar } from '../../../../../helpers/checkIsIphoneX';

const EnterUserInfo = ({ invalid, handleSubmit, cities, counties, userEmail, formValues, ...props }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const countiesToObject = counties.map((county) => ({ id: county, name: county }));

  const handleEnterUserInfo = () => {
    const { county, city, personalAddress, eMail } = formValues.values;
    dispatch(bonusShopActions.setPerxUserInfo({ county, city, personalAddress, eMail }));
  };

  const handleOnBackButton = () => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.perxStepList }));
  };

  return (
    <div className="enter-user-info-page">
      <BackArrowButton
        onHistoryReplaceCallback={handleOnBackButton}
        withoutTheme
        customClass="enter-user-info-page__back-button"
      />
      <div className="enter-user-info-page__content">
        <div className="enter-user-info-page__content-title-container">
          <p className="enter-user-info-page__content-title-container-title-bold">
            {formatMessage('Complete the form to create a Perx virtual card')}
          </p>
          <p className="enter-user-info-page__content-title-container-title">
            {formatMessage('Use alphanumeric characters')}
          </p>
        </div>
        <form onSubmit={handleSubmit(handleEnterUserInfo)} className="enter-user-info-form">
          <p className="enter-user-info-page__content-input-label">{formatMessage('Name:')}</p>
          <Input
            validate={[required]}
            type="text"
            customClass="enter-user-info-page__content-input"
            name="name"
            disabled
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('Surname:')}</p>
          <Input
            validate={[required]}
            type="text"
            customClass="enter-user-info-page__content-input"
            name="surname"
            disabled
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('Email:')}</p>
          <Input
            validate={[email, required]}
            type="email"
            customClass="enter-user-info-page__content-input"
            name="eMail"
            placeholder={formatMessage('Enter email address')}
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('Mobile country code:')}</p>
          <Input
            validate={[required]}
            type="text"
            customClass="enter-user-info-page__content-input"
            name="countryCode"
            disabled
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('Mobile number:')}</p>
          <Input
            validate={[required]}
            type="text"
            customClass="enter-user-info-page__content-input"
            name="mobileNumber"
            disabled
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('Country:')}</p>
          <Input
            validate={[required]}
            type="text"
            customClass="enter-user-info-page__content-input"
            name="country"
            disabled
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('County:')}</p>
          <Select
            validate={[required]}
            type="text"
            customClass="enter-user-info-page__content-select-input"
            name="county"
            options={countiesToObject}
            placeholder={formatMessage('County')}
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('City:')}</p>
          <Select
            validate={[required]}
            type="text"
            customClass="enter-user-info-page__content-select-input"
            name="city"
            options={cities}
            placeholder={formatMessage('City')}
          />
          <p className="enter-user-info-page__content-input-label">{formatMessage('Personal address:')}</p>
          <Input
            validate={[minLength(2, formatMessage), required]}
            type="text"
            customClass="enter-user-info-page__content-input"
            name="personalAddress"
            placeholder={formatMessage('Personal address')}
          />
          <div
            style={{ bottom: isIPhoneWithHomeBar() ? '49px' : '15px' }}
            className="enter-user-info-page__content-bottom-button"
          >
            <ArrowButton
              withoutTheme
              customClass={'enter-user-info-page__content-bottom-button-next-step'}
              fillcolor="#01c1b6"
            />
          </div>
        </form>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

export default EnterUserInfo;

import { useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { Header } from '../../components/common/Header';
import { useState } from 'react';
import { Routes } from '../../const';
import {
  getIsFetching,
  getSelectedSimulatorByIdSelector,
  getSelectedSimulatorScenariosSelector,
} from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { CheckIcon } from '../../icons';
import { NavigationMenu } from '../../components';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { scenarioActions } from '../../redux/scenario/scenarioSlice';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';

const ScenariosListComponent = ({ match, history, location }) => {
  const selectedSimulatorId = match.params.id;
  const { formatMessage } = useInternationalization();

  const [availableScenarios, setAvailableScenarios] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(knowledgeBankActions.getSalesSimulatorScenarios({ selectedSimulatorId }));
  }, [dispatch, selectedSimulatorId]);

  const onBackCallback = useCallback(() => {
    history.replace(Routes.KnowledgeBankRoute + Routes.SalesSimulators);
  }, [history]);

  const onScenarioClickCallback = useCallback(
    (screnario) => {
      dispatch(scenarioActions.startScenario({ scenario_id: screnario.id }));
    },
    [dispatch],
  );

  const isFetching = useSelector(getIsFetching);
  const screnarios = useSelector(getSelectedSimulatorScenariosSelector);
  const simualtor = useSelector((state) => getSelectedSimulatorByIdSelector(state, selectedSimulatorId));

  useEffect(() => {
    let undoneScenarios = screnarios.filter((i) => i.is_new);

    setAvailableScenarios(screnarios.length - undoneScenarios.length);
  }, [screnarios]);

  if (!simualtor) {
    onBackCallback();
    return null;
  }

  return (
    <div>
      <ScrollBlock fromTop>
        <Header hasBackButton goBackCallback={onBackCallback}>
          <p className="knowledge-bank-page__header-text">{simualtor.name}</p>
        </Header>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div className="sales-tips__body">
            <div className="sales-tips__tip-steps">
              {screnarios.map((i, index) => (
                <div key={i.id} onClick={index > availableScenarios ? () => {} : () => onScenarioClickCallback(i)}>
                  <div className={`sales-tips__tip-step-row${index > availableScenarios ? '-unavailable' : ''}`}>
                    <div className="sales-tips__tip-step-row-text">
                      <div className="sales-tips__tip-step-row-header">
                        {formatMessage('Step {index}', { index: index + 1 })}
                      </div>
                      <div className="sales-tips__tip-step-row-info">{i.name}</div>
                    </div>
                    {!i.is_new && (
                      <div className="sales-tips__done-step-icon-container">
                        <CheckIcon className="sales-tips__done-step-icon" />
                      </div>
                    )}
                  </div>
                  <div className="sales-tips__divider" />
                </div>
              ))}
            </div>
          </div>
        )}
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const ScenariosListPage = withRouter(ScenariosListComponent);

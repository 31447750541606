import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  mainScreenData: null,
  error: null,
  currentComponent: null,
};

const mainPageSlice = createSlice({
  name: 'mainPage',
  initialState,
  reducers: {
    getMainScreenData(state) {},
    setMainScreenData(state, action) {
      const { mainScreenData } = action.payload;
      return {
        ...state,
        mainScreenData,
        error: null,
      };
    },
    onRequestFailure(state) {
      return {
        ...state,
        fetching: false,
        error: null,
      };
    },
    setCurrentComponent(state, { payload }) {
      const { componentData } = payload;

      return {
        ...state,
        currentComponent: componentData,
      };
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const mainPageActions = mainPageSlice.actions;
export const mainPageReducer = mainPageSlice.reducer;

import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback } from 'react';
import { reset } from 'redux-form';

import './signupPage.scss';
import { AUTH_STEPS, STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { signupMybrandSchema } from '../../../validation/SignupMybrandSchema';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { toInternational } from '../../../helpers/numberFormatter';
import { CommonInput } from '../../../components/common/Input';
import { authActions } from '../../../redux/auth/authSlice';
import { Button } from '../../../components/common/Button';
import { authApi } from '../../../api/authApi';

interface SignupFormData {
  name: string;
  surname: string;
  username: string;
  password: string;
  passwordConfirm: string;
}

const SignUpPageMybrand = () => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();

  const { phone } = useSelector(({ form }: any) => form.enterPhoneForm.values);

  const { control, setError, handleSubmit } = useForm<SignupFormData>({
    // @ts-ignore https://github.com/react-hook-form/resolvers/issues/643
    resolver: yupResolver<SignupFormData>(signupMybrandSchema),
    defaultValues: {
      name: '',
      surname: '',
      username: '',
      password: '',
      passwordConfirm: '',
    },
  });

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: AUTH_STEPS.ENTER_PHONE,
      }),
    );
  }, [dispatch]);

  const onSubmit = async ({ name, surname, username, password }: SignupFormData) => {
    try {
      const data = {
        name,
        surname,
        username,
        password,
        phone: toInternational(phone),
      };

      await authApi.signup(data);

      dispatch(authActions.signupSuccess());
    } catch (error: any) {
      if (error?.message === 'This username is already taken') {
        return setError('username', { message: t(error.message) });
      }
      console.warn(error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="signup-page">
      <div className="signup-page__top-section">
        <Button withoutTheme type="button" className="signup-page__back-button" onClick={onBackCallback}>
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{t('Fill in all the fields')}</div>
        {/* @ts-ignore https://github.com/react-hook-form/react-hook-form/issues/6679 */}
        <Controller
          control={control}
          name="name"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('Name')}
              customClass="signup-page__input"
            />
          )}
        />
        <Controller
          control={control}
          name="surname"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('Surname')}
              customClass="signup-page__input"
            />
          )}
        />
        <Controller
          control={control}
          name="username"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('Username')}
              customClass="signup-page__input"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('common.password')}
              customClass="signup-page__input"
              type="password"
            />
          )}
        />
        <Controller
          control={control}
          name="passwordConfirm"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('common.password_confirm')}
              customClass="signup-page__input"
              type="password"
            />
          )}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme customClass={undefined} />
      </div>
    </form>
  );
};

export default SignUpPageMybrand;

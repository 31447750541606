import { RootState } from '../../store';
import { groupBy, formatDate } from '../../../helpers';

import { messageAdaptor } from './ticketChatSlice';
import { ChatMessage, Problem, Ticket } from '../chatTypes';
import { descDateComparer } from '../chatServices';

const messageSelectors = messageAdaptor.getSelectors((state: RootState) => state.chat.ticketChat.messages);

const selectHasMore = (state: RootState): boolean => state.chat.ticketChat.hasMore;
const selectIsFetching = (state: RootState): boolean => state.chat.ticketChat.isFetching;
const selectIsImageUploading = (state: RootState): boolean => state.chat.ticketChat.isImageUploading;
const selectProblems = (state: RootState): Problem[] => state.chat.ticketChat.problems;
const selectTicket = (state: RootState): Ticket | undefined => state.chat.ticketChat.ticket;
const selectLastMessage = (state: RootState): ChatMessage | undefined => {
  const messages = messageSelectors.selectAll(state);

  return messages[messages.length - 1];
};
const selectMessagesByDate = (state: RootState) =>
  Object.entries(
    groupBy<ChatMessage>(messageSelectors.selectAll(state), (message) =>
      formatDate(new Date(message.createdAt), 'dd MMM yyyy'),
    ),
  ).sort(([aDate, [aMessage]], [bDate, [bMessage]]) => descDateComparer(aMessage.createdAt, bMessage.createdAt));

export const ticketChatSelectors = {
  selectHasMore,
  selectIsFetching,
  selectIsImageUploading,
  selectProblems,
  selectMessagesByDate,
  selectLastMessage,
  selectTicket,
  ...messageSelectors,
};

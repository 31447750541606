import { API_URL } from './baseApi';
import axios from 'axios';

export const quickTipsApi = {
  getQuickTip: async () => {
    return await axios.get(`${API_URL}/v3/quick-tips`);
  },
  passQuickTip: async (quick_tip_id: number, user_answer: string) => {
    return await axios.post(`${API_URL}/v3/quick-tips`, { quick_tip_id, user_answer });
  },
};

import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack } from 'connected-react-router';

import './surveyPage.scss';

import { BRAND_THEMES } from '../brandThemes/brandThemes';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { SurveyResultComponent } from './surveyResultComponent';
import { SURVEY_STEPS } from '../../../redux/survey/surveyConstants';
import { SurveyGameComponent } from './surveyComponent';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { surveyActions } from '../../../redux/survey/surveySlice';

const SurveyPageComponent = (props) => {
  const { history } = props;
  const theme = BRAND_THEMES.default;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.surveyStepList,
        stepId: 1,
      }),
    );
    return () => dispatch(surveyActions.reset());
  });

  const onBackCallback = useCallback(() => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('Are you sure you want to finish?'),
        btnText: formatMessage('Yes, exit'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(goBack());
        },
      }),
    );
  }, [dispatch, formatMessage]);

  const SurveyStepList = createStepListContainer(STEP_LIST_IDS.surveyStepList, [
    {
      id: SURVEY_STEPS.GAME,
      Component: () => <SurveyGameComponent theme={theme} history={history} onLeaveCallback={onBackCallback} />,
    },
    {
      id: SURVEY_STEPS.RESULT,
      Component: () => <SurveyResultComponent theme={theme} history={history} />,
    },
  ]);
  return (
    <div className="survey-page">
      <SurveyStepList />
    </div>
  );
};

export const SurveyPage = withRouter(SurveyPageComponent);

import classnames from 'classnames';

import { useInternationalization } from '../../hooks/useTranslationHook';

import { ScrollBlock } from '../../components/common/ScrollBlock';
import { Button } from '../../components/common/Button';
import { LottieWrapper } from '../../components/lootieWrapper/lootieWrapper';

import boosterSuccess from './boosterSuccess.json';

import './boostersSuccessPage.scss';

export const BoostersSuccessPage = ({ onButtonClick, customClass }) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className={classnames('boosters-success-page', customClass)}>
      <ScrollBlock fromTop>
        <div className="boosters-success-page__content">
          <LottieWrapper
            options={options}
            style={{
              height: '340px',
            }}
          />
          <div className={'boosters-success-page__content-bottom'}>
            <p className="boosters-success-page__content-bottom-caption">
              {formatMessage('Hey, congratulations! (BoostersShop)')}
            </p>
            <p className={'boosters-success-page__content-bottom-description'}>
              {formatMessage('You get the booster')}
            </p>
            <Button onClick={onButtonClick} customClass={'boosters-success-page__content-bottom-button'}>
              {'OK'}
            </Button>
          </div>
        </div>
      </ScrollBlock>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: boosterSuccess,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

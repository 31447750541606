import React, { InputHTMLAttributes } from 'react';
import { Field } from 'redux-form';
import styles from './CheckboxInput.module.scss';

type Props = {
  customClass?: string;
  customInputClass?: string;
  startValue?: string;
  input?: InputHTMLAttributes<HTMLInputElement>;
  inputId: string;
  name: string;
  validate: any;
  onClick?: () => void;
};

const Checkbox = ({ customClass, input, inputId, name, customInputClass, onClick }: Props) => {
  return (
    <div className={styles.inputContainer}>
      <label className={styles.label} htmlFor={inputId}>
        <input
          id={inputId}
          type="checkbox"
          value={input?.value}
          className={customInputClass ? customInputClass : styles.input}
          onChange={input?.onChange}
          name={name}
        />
        <span className={customClass ? customClass : styles.checkbox} onClick={onClick} />
      </label>
    </div>
  );
};

export const CheckboxInput = (props: Props) => <Field component={Checkbox} value={props.startValue} {...props} />;

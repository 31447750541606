import { START_COLOR } from '../constants/general';

type Props = {
  stroke?: string;
};

export const FocusIcon = ({ stroke = START_COLOR }: Props) => {
  return (
    <svg width="317" height="222" viewBox="0 0 317 222" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M296 171V193C296 197.418 292.418 201 288 201H266"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M21 171V193C21 197.418 24.5817 201 29 201H51" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M266 21H288C292.418 21 296 24.5817 296 29V51" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
      <path d="M51 21H29C24.5817 21 21 24.5817 21 29V51" stroke={stroke} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

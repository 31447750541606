import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { challengeSelectors } from '../../redux/challenge/challengeSelectors';
import { challengeActions } from '../../redux/challenge/challengeSlice';
import { analyticHelper } from '../../helpers/analyticHelper';
import { getWordsFormat } from '../../helpers/getWordsFormat';
import { useInternationalization } from '../../hooks/useTranslationHook';
import useChallengeTimeLeft from '../../hooks/useChallengeTimeLeftHook';

import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { NavigationMenu } from '../../components';
import { Button } from '../../components/common/Button';
import { Header } from '../../components/common/Header';
import { CheckIcon } from '../../icons';

import './Challenge.scss';
import { s3FilePaths } from '../../const/appConstants';

export const Challenge = (props) => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();
  const filesInputRef = useRef(null);

  useEffect(() => {
    dispatch(challengeActions.getChallenge());
    analyticHelper.challengeOpen();
  }, [dispatch]);

  const challenge = useSelector(challengeSelectors.selectCurrentChallenge);
  const tasks = challenge && challenge.tasks;

  const time = useChallengeTimeLeft(challenge);

  const Tasks = () => {
    return tasks ? (
      tasks.map((el) => {
        return (
          <div key={el.id} className={'challenge-page__content-task'}>
            {el.done ? (
              <div className={'challenge-page__content-task-status challenge-page__content-task-status-done'}>
                <CheckIcon />
              </div>
            ) : (
              <div className={'challenge-page__content-task-status'} />
            )}
            <div className={'challenge-page__content-task-description'}>{el.description}</div>
          </div>
        );
      })
    ) : (
      <></>
    );
  };

  const handleSetNewPhoto = async () => {
    const files = filesInputRef && filesInputRef.current && filesInputRef.current.files;
    const file = files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('file_path', s3FilePaths.challenge);

    dispatch(
      challengeActions.uploadChallengePhoto({
        formData: formData,
        id: challenge.id,
      }),
    );
    analyticHelper.challengePhoto();
  };

  const ChallengeContent = () => {
    return (
      <div className="challenge-page__body">
        <div className={'challenge-page__content-info'}>
          <div className={'challenge-page__content-header'}>{challenge.description}</div>
          <div className={'challenge-page__content-time-left'}>
            <div className={'challenge-page__content-time'}>
              <span className={'challenge-page__content-time-left-value'}>{time.days}</span> <br />
              {formatMessage(getWordsFormat(time.days, 'days'))}
            </div>
            :
            <div className={'challenge-page__content-time'}>
              <span className={'challenge-page__content-time-left-value'}>{time.hours}</span> <br />
              {formatMessage(getWordsFormat(time.hours, 'hours'))}
            </div>
            :
            <div className={'challenge-page__content-time'}>
              <span className={'challenge-page__content-time-left-value'}>{time.minutes}</span> <br />
              {formatMessage(getWordsFormat(time.minutes, 'minutes'))}
            </div>
          </div>
          <img
            className={'challenge-page__content-icon'}
            src={challenge.products.length && challenge.products[0].photo}
            alt="challenge reward"
          />
          <div className={'challenge-page__content-points'}>{challenge.name}</div>
          <div className={'challenge-page__content-horizontal-line'} />
          <div className={'challenge-page__content-tasks-container'}>{Tasks()} </div>
        </div>

        {challenge.attachmentsEnabled && (
          <Button
            customClass={'challenge-page__content-upload-button'}
            onClick={() => {
              filesInputRef.current.click();
            }}
          >
            <input
              type="file"
              id={'challenge-page-file'}
              hidden={true}
              ref={filesInputRef}
              onChange={handleSetNewPhoto}
              accept="image/*"
            />
            {formatMessage('Send a photo')}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={'challenge-page'}>
      <ScrollBlock fromTop>
        <Header hasBackButton customClass="challenge-page__header">
          <p className="challenge-page__header-text">{formatMessage('Challenge')}</p>
        </Header>
        {challenge && challenge.name ? (
          ChallengeContent()
        ) : (
          <div className="challenge-page__no-challenge">
            <p className="challenge-page__no-challenge-caption">
              {formatMessage('There are no active challenges at the moment')}
            </p>
          </div>
        )}
        <NavigationMenu location={props.location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categories: [],
  allUnread: 0,
  articles: [],
  selectedArticle: null,
  brands: [],
  salesTips: [],
  selectedTipSteps: [],
  salesSimulators: [],
  selectedSimulatorScenarios: [],
  gameProgress: null,
  isFetching: false,
  categoryId: null,
  brandId: null,
};

const knowledgeBankSlice = createSlice({
  name: 'knowledgeBank',
  initialState,
  reducers: {
    getCategories() {},
    setCategories(state, action) {
      const { categories } = action.payload;
      return {
        ...state,
        categories,
        allUnread: categories.map((category) => category.unread_count).reduce((a, b) => a + b, 0),
      };
    },
    getKBBrands() {},
    setKBBrands(state, action) {
      const { brands } = action.payload;
      return {
        ...state,
        brands,
      };
    },
    chooseKBBrand(state, action) {},
    socketHandle({ data }) {},
    chooseCategory(state, action) {},
    incUnreadTopics(state) {
      return {
        ...state,
        allUnread: state.allUnread + 1,
      };
    },
    getArticles(state, action) {},
    getCurrentArticle(state, action) {},
    getInteractiveArticle() {},
    passInteractiveArticle() {},
    passCorrectInteractiveArticle() {},
    setCoinsForInteractiveArticle(state, action) {
      const coinsForInteractiveArticle = action.payload;

      return {
        ...state,
        coinsForInteractiveArticle: coinsForInteractiveArticle,
      };
    },
    openArticle(state, { payload }) {},
    readArticle(state) {},
    loseGame(state) {},
    setGameProgress(state, action) {
      const { gameProgress } = action.payload;
      return {
        ...state,
        gameProgress,
      };
    },
    setCurrentArticle(state, action) {
      state.selectedArticle = action.payload;
    },
    setArticles(state, action) {
      const { articles } = action.payload;
      return {
        ...state,
        articles,
      };
    },
    setInteractiveArticle(state, action) {
      const { interactiveArticle } = action.payload;
      return {
        ...state,
        interactiveArticle,
      };
    },
    resetArticles(state) {
      return {
        ...state,
        articles: [],
      };
    },
    resetInteractiveArticles(state) {
      return {
        ...state,
        interactiveArticle: [],
      };
    },
    getSalesTips() {},
    setSalesTips(state, { payload }) {
      return {
        ...state,
        salesTips: payload,
      };
    },
    getSalesTipSteps(state, { payload }) {},
    setSalesTipSteps(state, { payload }) {
      return {
        ...state,
        selectedTipSteps: payload,
      };
    },
    getSalesSimulators() {},
    setSalesSimulators(state, { payload }) {
      return {
        ...state,
        salesSimulators: payload,
      };
    },
    getSalesSimulatorScenarios() {},
    setSalesSimulatorScenarios(state, { payload }) {
      return {
        ...state,
        selectedSimulatorScenarios: payload,
      };
    },
    setIsFetching(state, { payload }) {
      return {
        ...state,
        isFetching: payload.isFetching,
      };
    },
    setSelectedBrand(state, action) {
      state.selectedBrand = action.payload;
    },
  },
});

export const knowledgeBankActions = knowledgeBankSlice.actions;
export const knowledgeBankReducer = knowledgeBankSlice.reducer;

import { Field } from 'redux-form';
import classnames from 'classnames';

import './Input.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { useMemo } from 'react';

export const CommonInput = (props) => {
  const {
    customClass,
    type,
    pattern,
    placeholder,
    meta: { touched, error },
    disabled,
    InputButton,
    onClick,
    countryCode,
  } = props;
  const { formatMessage } = useInternationalization();

  const isDateInput = props.input.type === 'date';

  return (
    <div className={classnames('input-block', customClass)}>
      <div className="input-block__with-warning">
        <input
          type={type}
          pattern={pattern}
          placeholder={placeholder}
          className={`input-block__input${touched && error ? '-invalid' : ''}`}
          disabled={disabled}
          onClick={onClick}
          {...props.input}
          value={countryCode ? countryCode + props.input.value.slice(countryCode.length) : props.input.value}
        />
        {InputButton ? InputButton : touched && error && !isDateInput && <div className="input-block__warning">!</div>}
      </div>
      {touched && error && <div className="input-block__warning-message">{formatMessage(error)}</div>}
    </div>
  );
};

export const Input = (props) => {
  return <Field component={CommonInput} {...props} />;
};

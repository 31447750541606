import { createSelector } from 'reselect';

export const getLepCompetitionsSelector = createSelector(
  [(state) => state.lep.competitions],
  (competitions) => competitions,
);
export const getLepCodesSelector = createSelector([(state) => state.lep.codes], (codes) => codes);

export const getLepSelectedCompetitionsSelector = createSelector(
  [(state) => state.lep.selectedCompetition],
  (selectedCompetition) => selectedCompetition,
);
export const getLepRegistrationBannerSelector = createSelector(
  [(state) => state.lep.registration_banner],
  (registration_banner) => registration_banner,
);
export const getLepWinnerBannerSelector = createSelector(
  [(state) => state.lep.winner_banner],
  (winner_banner) => winner_banner,
);

export const getLepInputCodeSelector = createSelector([(state) => state.form?.lepCode?.values], (values) =>
  values ? values : '',
);
export const getLepValidateErrorCodeSelector = createSelector(
  [(state) => state.form?.lepCode?.syncErrors?.lepCode],
  (values) => values,
);
export const getLepDataUserSelector = createSelector(
  [(state) => state.form?.signupFormLep?.values],
  (values) => values,
);

import classNames from 'classnames';

import { ModerationIcon } from '../../../../icons/moderation-icon';
import { AcceptedStatusIcon } from '../../../loyaltyProgram/statusIcons/accepted';
import { noAnswerIcon } from '../../../../icons/no-answer-icon';
import { ComponentLike } from '../../../../types';
import { formatDate } from '../../../../helpers';

import { Ticket as TicketModel, TicketStatus } from '../../../../redux/chat/chatTypes';

import styles from './Ticket.module.scss';
import { NewMessageBadge } from '../newMessageBadge/NewMessageBadge';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../../config';

type TicketProps = {
  ticket: TicketModel;
  className?: string;
  onClick: (ticketId: number) => void;
};

const DEFAULT_TICKET_TITLE = 'Other';

export const Ticket = ({ ticket, className, onClick }: TicketProps) => {
  const { formatMessage } = useInternationalization();
  const Theme: Record<TicketStatus, { className: string; StatusIcon: ComponentLike; statusLabel: string }> = {
    [TicketStatus.Checking]: {
      className: styles.checking,
      StatusIcon: ModerationIcon,
      statusLabel: formatMessage('chat.status.checking'),
    },
    [TicketStatus.Completed]: {
      className: styles.completed,
      StatusIcon: AcceptedStatusIcon,
      statusLabel: formatMessage('chat.status.completed'),
    },
    [TicketStatus.NoAnswer]: {
      className: styles.noAnswer,
      StatusIcon: noAnswerIcon,
      statusLabel: formatMessage('chat.status.no_answer'),
    },
  };
  const { createdAt, status, id, name, hasNewMessages } = ticket;
  const { className: themeClassName, statusLabel, StatusIcon } = Theme[status];
  let ticketName = '';

  const time = formatDate(new Date(createdAt), 'HH:mm');

  if (isFeatureEnabled(CONFIG_KEYS.CHAT_WITHOUT_OPTIONS_ENABLED) && name === DEFAULT_TICKET_TITLE) {
    ticketName = `#${id} ${formatMessage('ticket.question')}`;
  } else {
    ticketName = `#${id} ${name}`;
  }

  const handleClick = (): void => {
    onClick(id);
  };

  return (
    <div className={classNames(styles.ticket, className, themeClassName)} onClick={handleClick}>
      {hasNewMessages && <NewMessageBadge className={styles.newMessageBadge} />}

      <div className={styles.name}>{ticketName}</div>
      <div className={styles.footer}>
        <div className={styles.status}>
          <StatusIcon />
          {statusLabel}
        </div>
        <div className={styles.time}>{time}</div>
      </div>
    </div>
  );
};

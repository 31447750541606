import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { useHistory } from 'react-router';

import './Lep.scss';

import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { Button } from '../../../components/common/Button';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { formatDate, formatTime } from '../../../helpers';
import useDailyRewardsTimeLeft from '../../../hooks/useDailyRewardsTimeLeftHook';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ExclamationPointIcon } from '../../../icons/exclamation-point-icon';
import { ArrowIcon } from '../../../icons/arrow-icon';
import {
  getLepRegistrationBannerSelector,
  getLepSelectedCompetitionsSelector,
  getLepWinnerBannerSelector,
  getLepCodesSelector,
  getLepDataUserSelector,
  getLepCompetitionsSelector,
} from '../../../redux/LEP/lepSelector';
import { lepSteps } from './LepStart';
import { Routes } from '../../../const';
import { lepActions } from '../../../redux/LEP/lepSlice';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';

export const LepResult = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const {
    draw_end_timer,
    end_date,
    main_image,
    help_link,
    rules_link,
    button_text_color,
    accent_color,
    background_color,
    text_color,
  } = useSelector(getLepSelectedCompetitionsSelector);
  const activatedCodes = useSelector(getLepCodesSelector);
  const registrationBanner = useSelector(getLepRegistrationBannerSelector);
  const winnerBanner = useSelector(getLepWinnerBannerSelector);
  const dataUser = useSelector(getLepDataUserSelector);
  const competitions = useSelector(getLepCompetitionsSelector);

  const [showWinBanner, setShowWinBanner] = useState(false);

  useEffect(() => {
    setShowWinBanner(!!activatedCodes.find((el) => el.showWin));
  }, [dispatch, activatedCodes]);

  const date = end_date && formatDate(new Date(end_date), 'dd:MM:y');
  const time = end_date && formatTime(new Date(end_date), { hour: 'numeric', minute: 'numeric', hour12: true });

  const { days, hours, minutes } = useDailyRewardsTimeLeft(draw_end_timer);

  const previosStep = () => {
    dispatch(lepActions.resetCodes());
    if (competitions.length === 1) {
      history.push(Routes.MainRoute);
    } else {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.lepStepList,
          stepId: lepSteps.chooseCompetition,
        }),
      );
    }
  };

  const openCompleteRegistrationModal = () => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('Join JTI Connex'),
        description: [
          formatMessage(
            'Tap ‘Continue’ to register in the JTI Connex app where you could get even more prizes and brand information',
          ),
        ],
        btnText: formatMessage('Continue'),
        onButtonClick: () => {
          dispatch(lepActions.signUp());
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const handleEnterCode = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.lepStepList,
        stepId: lepSteps.enterCode,
        props: { prevStep: lepSteps.result },
      }),
    );
  };
  return (
    <div
      className="lep-page__result"
      style={{
        backgroundColor: background_color,
        backgroundImage: `url(${main_image})`,
      }}
    >
      <BackArrowButton
        buttonColor={text_color}
        onHistoryReplaceCallback={previosStep}
        customClass="lep-page__back-button"
      />
      <div style={{ marginTop: isIPhoneWithHomeBar() ? '300px' : '190px' }} className="lep-page__result-content">
        <div className="lep-page__result-content-top">
          <a className="lep-page__result-content-box-icon" href={help_link} rel="noopener noreferrer" target="_blank">
            <ExclamationPointIcon className="lep-page__result-content-info-icon" />
          </a>
          <div className="lep-page__result-content-timeLeft" style={{ borderColor: background_color }}>
            Game closes in
            {days ? ` ${days}d` : ''}
            {hours ? ` ${hours}h` : ''}
            {minutes ? ` ${minutes}m` : ''}
          </div>
          <div className="lep-page__result-content-header">Amber Leaf Rolling Rewards competition</div>
          <div className="lep-page__result-content-description">
            Find code on the bundle and use it sign in the competition
          </div>
          <Button
            customClass="lep-page__result-content-button"
            withoutTheme={true}
            onClick={handleEnterCode}
            style={{
              color: button_text_color,
              backgroundColor: accent_color,
            }}
          >
            Enter the code
          </Button>
          <div className="lep-page__result-content-competitionEnd">
            competition ends {date} at {time}
          </div>
        </div>

        {dataUser?.username && dataUser?.position_id && (
          <div
            onClick={openCompleteRegistrationModal}
            className="lep-page__result-content-registration"
            style={{
              backgroundImage: `url(${registrationBanner}`,
            }}
          >
            <div className="lep-page__result-content-registration-text">Finish registration in JTI Connex app</div>
            <ArrowIcon />
          </div>
        )}

        {showWinBanner && (
          <div
            className="lep-page__result-content-lucky-codes"
            style={{
              backgroundImage: `url(${winnerBanner})`,
            }}
          >
            You have lucky codes!
          </div>
        )}

        <div className="lep-page__result-content-codes">
          <div className="lep-page__result-content-codes-header">Your activated codes:</div>
          {activatedCodes.map((card, ind) => {
            return (
              (card.showWin || card.status !== 1) && (
                <div
                  className={classNames('lep-page__result-content-codes-card', { 'lep-page__code-win': card.showWin })}
                  key={ind}
                >
                  <div className="lep-page__result-content-codes-code">
                    <div>{card.code}</div>
                    <div>{formatDate(new Date(card.assigned_at), 'dd:MM:y')}</div>
                  </div>
                  <div className="lep-page__result-content-codes-status">{card.showWin ? 'win' : 'in a draw'}</div>
                </div>
              )
            );
          })}
        </div>
        <a
          href={rules_link}
          rel="noopener noreferrer"
          target="_blank"
          className="lep-page__result-game-rules"
          style={{ marginBottom: isIPhoneWithHomeBar() ? '64px' : '30px' }}
        >
          Game rules
        </a>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDailyGame } from './dailyGamesTypes';

export interface IDailyGamesState {
  currentDailyGame: IDailyGame | null;
}

const initialState: IDailyGamesState = {
  currentDailyGame: null,
};

const dailyGamesSlice = createSlice({
  name: 'dailyGames',
  initialState,
  reducers: {
    openDailyGame: (state: IDailyGamesState, action: PayloadAction<{ dailyGame: IDailyGame }>): void => {},
    setCurrentDailyGame: (state: IDailyGamesState, action: PayloadAction<IDailyGame>) => {
      state.currentDailyGame = action.payload;
    },
  },
});

export const dailyGamesActions = dailyGamesSlice.actions;
export const dailyGamesReducer = dailyGamesSlice.reducer;

import { all, put, takeLatest, select } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { chatApi } from '../../../api/chatApi';
import { MESSAGE_LIMIT } from '../chatConstants';
import { mapMessage, mapMessages } from '../chatServices';
import { supportChatActions } from './supportChatSlice';
import { supportChatSelectors } from './supportChatSelectors';
import { serviceApi } from '../../../api/serviceApi';

function* sendMessage(action: PayloadAction<{ text: string; image?: object }>) {
  const { text, image } = action.payload;

  try {
    const { data } = yield chatApi.sendMessage({ text, image });

    const message = mapMessage(data);

    yield put(supportChatActions.messageReceived(message));
  } catch (e) {}
}

function* uploadImage(action: PayloadAction<{ formData: object }>) {
  const { formData } = action.payload;
  yield put(supportChatActions.setIsImageUploading(true));

  try {
    const { data } = yield serviceApi.uploadPhoto(formData);

    yield put(supportChatActions.sendMessage({ text: '', image: data.file_key }));
  } catch (e) {
  } finally {
    yield put(supportChatActions.setIsImageUploading(false));
  }
}

function* fetchMessages(action: PayloadAction<{ offset: number }>) {
  const { offset } = action.payload;

  yield put(supportChatActions.setIsFetching(true));

  try {
    const { data } = yield chatApi.getMessages({ limit: MESSAGE_LIMIT, offset });
    const messages = mapMessages(data.messages);

    yield put(supportChatActions.messagesReceived(messages));

    const total: number = yield select(supportChatSelectors.selectTotal);

    yield put(supportChatActions.setHasMore(total < data.total));
  } catch (e) {
  } finally {
    yield put(supportChatActions.setIsFetching(false));
  }
}

export function* supportChatSagas() {
  yield all([
    takeLatest(supportChatActions.fetchMessages.type, fetchMessages),
    takeLatest(supportChatActions.sendMessage.type, sendMessage),
    takeLatest(supportChatActions.uploadImage.type, uploadImage),
  ]);
}

import { useDispatch } from 'react-redux';

import './oppaTutorial.scss';

import { ArrowButton } from '../../../../components/common/ArrowButton/ArrowButton';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { oppaTutorialSteps } from './oppaTutorial';
import { SkipIcon } from '../../../../icons/skip-icon';

export const OppaTutorialStep5 = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const handleNextStep = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.oppaTutorialStepList }));
  };

  const handleSkip = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure you want to finish?',
        btnText: 'Yes, exit',
        onButtonClick: () => {
          localStorage.setItem('oppaCompletedTutorial', true);
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.oppaTutorialStepList,
              stepId: oppaTutorialSteps.stepNull,
            }),
          );
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className="oppa-tutorial__blackout">
      <div className="oppa-tutorial__skip" onClick={() => handleSkip()}>
        {formatMessage('Skip')}
        <SkipIcon className="oppa-tutorial__skip-icon" />
      </div>
      <div className="oppa-tutorial__descriptionBox-bottom">
        <div className="achievements-tutorial__descriptionBox-step">4/5</div>
        <div className="oppa-tutorial__descriptionBox-header">{formatMessage('Limits')}</div>
        <div className="oppa-tutorial__descriptionBox-description">
          {formatMessage(
            "Number of transactions and amount of money you could redeem is limited. To read the details about it tap '?'",
          )}
        </div>
      </div>
      <ArrowButton customClass={'oppa-tutorial__button-next'} onClick={handleNextStep} />
    </div>
  );
};

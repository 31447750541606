import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { PlusIcon } from '../../../../icons/plus-icon';
import history from '../../../../helpers/history';

import styles from './ButtonExit.module.scss';

const ButtonExit = () => {
  const { formatMessage } = useInternationalization();
  return (
    <div className={styles.wrapper} onClick={() => history.goBack()}>
      <PlusIcon style={{ fill: 'white', marginRight: '5px' }} />
      {formatMessage('Exit')}
    </div>
  );
};

export default ButtonExit;

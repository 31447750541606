export const STEP_LIST_IDS = {
  authStepList: 'authStepList',
  checkInStepList: 'checkInStepList',
  startQuizStepList: 'startQuizStepList',
  specialQuizSpetList: 'specialQuizSpetList',
  eventModeStepList: 'eventModeStepList',
  soloModeStepList: 'soloModeStepList',
  singleModeStepList: 'singleModeStepList',
  dailyRewards: 'dailyRewardsStepList',
  surveyStepList: 'surveyStepList',
  multibrandQuizStepList: 'multibrandQuizStepList',
  transferTutorialStepList: 'transferTutorialStepList',
  digitalFrameStepList: 'digitalFrameStepList',
  achievementsTutorialStepList: 'achievementsTutorialStepList',
  lepStepList: 'lepStepList',
  oppaStepList: 'oppaStepList',
  oppaTutorialStepList: 'oppaTutorialStepList',
  m10StepList: 'm10tepList',
  perxStepList: 'perxStepList',
  interactiveArticleStepList: 'interactiveArticleStepList',
  acceptNewTermsStepsList: 'acceptNewTermsStepsList',
  unicardStepList: 'unicardStepList',
  idCardStepList: 'idCardStepList',
  mysteryShopperStepList: 'mysteryShopperStepList',
  epulStepsList: 'epulStepsList',
};

export const STEP_LIST_ACTION_TYPES = {
  INIT_STEP_LIST: 'steps/initStepList',
};

export const AUTH_STEPS = {
  ONBOARDING: 0,
  ENTER_PHONE: 1,
  VERIFY: 2,
  SIGNUP: 3,
  ESIGN: 4,
  SIGNUP_SECOND_STAGE: 5,
  CHECK_DATA: 6,
  PASSWORD_ENTER: 7,
};

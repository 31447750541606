import { createSelector } from 'reselect';

export const getBoostersForQuizSelector = createSelector(
  [(state) => state.boosters.currentQuizBoosters],
  (boosters) => boosters,
);

export const getActiveBoosterSelector = createSelector(
  [(state) => state.boosters.activeBooster],
  (activeBooster) => activeBooster,
);

export const getBoostersSelector = createSelector([(state) => state.boosters], (boosters) => boosters);

export const getBoostersShopCards = createSelector([(state) => state.boosters.shop?.boosters], (boosters) => boosters);

export const getLeafExchangeRate = createSelector(
  [(state) => state.boosters.shop?.leaf_exchange_rate],
  (leafExchangeRate) => leafExchangeRate,
);

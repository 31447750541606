import { APP_TYPES } from '../../const/appConstants';
import { ProfileIrelandLayout } from './profileIrelandLayout/profileIrelandLayout';
import { ProfileCaucasusLayoutWithoutCheckin } from './profileCaucasusLayout/profileCaucasusLayoutWithoutCheckin';
import { ProfileBelarusLayout } from './profileBelarusLayout/ProfileBelarusLayout';
import { DefaultProfileLayout } from './defaultProfileLayout/DefaultProfileLayout';

const getProfileLayout = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      return ProfileIrelandLayout;
    case APP_TYPES.HUNGARY:
      return ProfileIrelandLayout;
    case APP_TYPES.ARMENIA:
    case APP_TYPES.AZERBAIJAN:
    case APP_TYPES.GEORGIA:
      return ProfileCaucasusLayoutWithoutCheckin;
    case APP_TYPES.BELARUS:
      return ProfileBelarusLayout;
    default:
      return DefaultProfileLayout;
  }
};

export const ProfileLayout = getProfileLayout();

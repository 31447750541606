export const ConnectIcon = (props) => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 41C32.0457 41 41 32.0457 41 21C41 9.9543 32.0457 1 21 1C9.9543 1 1 9.9543 1 21C1 32.0457 9.9543 41 21 41Z"
        fill="white"
        stroke="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1276 28.6154L13.3816 19.3219V25.118L10 20.4211V13.3846H13.2525L24.3846 28.6154"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.8721 13.3846L28.6182 22.6781V16.8821L31.9998 21.5789V28.6154H28.7473L17.6152 13.3846"
        fill="black"
      />
    </svg>
  );
};

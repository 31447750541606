import { useState, useEffect } from 'react';
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';

import './esignPage.scss';

import { emailEsign } from '../../../helpers/formValidations';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Input } from '../../../components/common/Input';
import { Button } from '../../../components/common/Button';
import { authActions } from '../../../redux/auth/authSlice';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';

export const EsignComponent = (props) => {
  const { formatMessage } = useInternationalization();
  const [showUnderinputMessage, setShowMessage] = useState(true);
  const { formObj, handleSubmit, invalid, resetForm, prevStep, esign } = props;

  useEffect(() => {
    if (formObj.values.email && formObj.values.email.length !== 0) {
      setShowMessage(false);
    }
  }, [formObj.values.email]);

  return (
    <form onSubmit={handleSubmit} className="esign-page">
      <div className="esign-page__top-section">
        <Button
          withoutTheme
          type="button"
          className="verify__top-button"
          onClick={() => {
            resetForm();
            prevStep();
          }}
        >
          <ArrowIconRounded fillColor="#fff" className="verify__top-arrow" />
        </Button>

        <div className="esign-page__title">{formatMessage('Enter your email')}</div>

        <Input
          name="email"
          placeholder={formatMessage('Email')}
          validate={[emailEsign]}
          customClass="esign-page__input"
        />
        {(!invalid || showUnderinputMessage) && (
          <div className="esign-page__email-info">
            {formatMessage('In order to remotely sign JTI Trade Declaration form')}
          </div>
        )}
      </div>
      <div className="esign-page__bot-section">
        <ArrowButton withoutTheme disabled={invalid} onClick={() => esign({ email: formObj.values.email })} />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    formObj: form.esignForm || { values: {} },
  };
};

const mapDispatchToProps = {
  esign: authActions.esign,
  resetForm: () => reset('esignForm'),
  prevStep: () =>
    stepListActions.setStep({
      stepListId: STEP_LIST_IDS.authStepList,
      stepId: 1,
    }),
};

const form = reduxForm({
  form: 'esignForm',
  initialValues: { email: '' },
  destroyOnUnmount: false,
  onSubmit: () => {},
})(EsignComponent);

export const EsignPage = connect(mapStateToProps, mapDispatchToProps)(form);

import { ReactElement } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { LoaderContainer, Spinner } from '../../../../components/common';

import { MessageBlock, NavigationWrapper, SendMessageBlock } from '../../components';
import { chatConstants } from '../../../../redux/chat';
import { ChatMessage } from '../../../../redux/chat/chatTypes';
import styles from './Chat.module.scss';

type Props = {
  headerTitle?: string;
  children?: ReactElement | false | undefined;
  messagesByDate: [string, ChatMessage[]][];
  totalCount: number;
  hasMore: boolean;
  fetchMore: () => void;
  isLoading: boolean;
  canSendMessage?: boolean;
  onSendMessageClick: (text: string) => void;
  onImageUpload: (image: object) => void;
  onBackClick?: () => void;
};

export const Chat = ({
  headerTitle,
  messagesByDate,
  totalCount,
  hasMore,
  fetchMore,
  children,
  isLoading,
  canSendMessage = true,
  onSendMessageClick,
  onImageUpload,
  onBackClick,
}: Props) => (
  <NavigationWrapper title={headerTitle} isChat onBackClick={onBackClick}>
    <>
      {!children && canSendMessage && (
        <SendMessageBlock onSendMessageClick={onSendMessageClick} onImageUpload={onImageUpload} />
      )}
      <div id={chatConstants.SCROLL_CONTAINER_ID} className={styles.chat}>
        <LoaderContainer isLoading={isLoading} className={styles.loaderContainer}>
          <InfiniteScroll
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            scrollableTarget={chatConstants.SCROLL_CONTAINER_ID}
            inverse
            initialScrollY={2000}
            dataLength={totalCount}
            hasMore={hasMore}
            next={fetchMore}
            loader={<Spinner width={100} />}
          >
            {children}
            {messagesByDate.map(([date, messages]) => (
              <MessageBlock key={date} date={date} messages={messages} />
            ))}
          </InfiniteScroll>
        </LoaderContainer>
      </div>
    </>
  </NavigationWrapper>
);

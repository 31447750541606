import './DailyRewards.scss';

import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { DailyRewardsComponent } from './DailyRewardsComponent';
import { DAILY_REWARDS_STEPS, DAILY_REWARDS_STATUS } from '../../../redux/dailyRewards/dailyRewardsConstants';
import { getDailyRewardsProducts, getStatusDailyRewards } from '../../../redux/dailyRewards/dailyRewardsSelectors';
import { DailyRewardsResult } from './DailyRewardsResult';
import { DailyRewardsFinishPage } from './DailyRewardsFinishPage';

export const DailyRewardsPageComponent = (props) => {
  const { history } = props;
  const status = useSelector(getStatusDailyRewards);
  const products = useSelector(getDailyRewardsProducts);

  if (!products || products.length === 0 || status === DAILY_REWARDS_STATUS.UNAVAILABLE) {
    history.replace('/main');
  }

  const DailyRewardsStepList = createStepListContainer(STEP_LIST_IDS.dailyRewards, [
    {
      id: DAILY_REWARDS_STEPS.GAME,
      Component: () => <DailyRewardsComponent />,
    },
    {
      id: DAILY_REWARDS_STEPS.RESULT,
      Component: () => <DailyRewardsResult />,
    },
    {
      id: DAILY_REWARDS_STEPS.FINISH,
      Component: () => <DailyRewardsFinishPage history={history} />,
    },
  ]);

  return (
    <div className="daily-rewards">
      <DailyRewardsStepList />
    </div>
  );
};

export const DailyRewardsPage = withRouter(DailyRewardsPageComponent);

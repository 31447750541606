import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { singleChoice } from '../../../../helpers/formValidations';
import { RenderField } from '../../../../components/RenderField/RenderField';

import styles from './SingleChoise.module.scss';

type Props = {
  id?: string;
  value: string;
  label: string;
  disabled: boolean;
  isCorrect?: boolean;
};

export const SingleChoice = ({ id, label, value, isCorrect, disabled }: Props) => {
  const { formatMessage } = useInternationalization();

  return (
    <Field
      component={RenderField}
      type="radio"
      value={value}
      id={id}
      name="single"
      validate={[singleChoice(formatMessage)]}
      label={label}
      disabled={disabled}
      customInputClass={styles.answerInput}
      customLabelClass={classnames(styles.label, {
        [styles.correctAnswer]: isCorrect,
        [styles.incorrectAnswer]: isCorrect !== undefined && !isCorrect,
        [styles.showCorrectAnswer]: isCorrect,
      })}
    />
  );
};

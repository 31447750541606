import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chance: null,
  result: '',
  isPlaying: false,
  chances: [],
  isFetching: false,
};

const chanceSlice = createSlice({
  name: 'chance',
  initialState,
  reducers: {
    getChance() {},
    getChanceById(state, payload) {},
    setChance(state, { payload }) {
      state.chance = payload;
    },
    setChances(state, { payload }) {
      state.chances = payload;
    },
    setIsFetching(state, { payload }) {
      state.isFetching = payload;
    },
    participate(state) {},
    setPlaying(state, action) {
      const isPlaying = action.payload;
      return {
        ...state,
        isPlaying,
      };
    },
    setResult(state, action) {
      const { status } = action.payload;
      return {
        ...state,
        result: status ? 'win' : 'lose',
      };
    },
    resetResult(state) {
      return {
        ...state,
        result: null,
      };
    },
  },
});

export const chanceActions = chanceSlice.actions;
export const chanceReducer = chanceSlice.reducer;

import classNames from 'classnames';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { formatDate } from '../../../../helpers';
import { Button } from '../../../../components/common';

import { ChatMessage, MessagesStatus } from '../../../../redux/chat/chatTypes';

import { NewMessageBadge } from '../../components/newMessageBadge/NewMessageBadge';
import { useSelector } from 'react-redux';
import { getUserInfoSelector } from '../../../../redux/profile/profileSelectors';
import NotificationBlock from '../../../../components/NotificationBlock';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../../config';
import styles from './NotificationMessage.module.scss';

type Props = {
  message: ChatMessage;
  onButtonClick: () => void;
  isButtonShown: boolean;
  className?: string;
  date: string;
};

export const NotificationMessage = ({ message, className, onButtonClick, isButtonShown, date }: Props) => {
  const { formatMessage } = useInternationalization();
  const { name } = useSelector(getUserInfoSelector);

  const { text, caption, createdAt, image, status, buttonText } = message;
  const time = formatDate(new Date(createdAt), 'HH:mm');
  const isNewMessage = status === MessagesStatus.Unread;

  if (isFeatureEnabled(CONFIG_KEYS.FACEBOOK_STYLED_NOTIFICATIONS_ENABLED)) {
    return (
      <NotificationBlock
        onButtonClick={onButtonClick}
        isClickable={isButtonShown}
        time={time}
        name={name}
        text={text}
        caption={caption}
        image={image}
        status={isNewMessage}
        date={date}
      />
    );
  }
  return (
    <div className={classNames(styles.notificationMessage, className)}>
      {isNewMessage && <NewMessageBadge className={styles.newMessageBadge} />}

      <div className={styles.time}>{time}</div>
      {image && <img className={styles.image} alt="" src={image} />}
      {caption && <div className={styles.caption}>{caption}</div>}
      {text && <div className={styles.text}>{text.replace('[name]', name)}</div>}

      {isButtonShown && (
        <Button className={styles.button} onClick={onButtonClick}>
          {formatMessage(buttonText ? buttonText : 'Continue')}
        </Button>
      )}
    </div>
  );
};

export const compressImage = (file, quality = 0.5) => {
  return new Promise((resolve, reject) => {
    if (!file || !file.type.startsWith('image/')) {
      reject(new Error('Invalid file type. Please provide an image file.'));
      return;
    }
    if (file.type.includes('svg')) {
      resolve(file);
    }

    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.src = event?.target?.result;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0, img.width, img.height);

        const compressedDataUrl = canvas.toDataURL('image/jpeg', quality);

        const byteString = atob(compressedDataUrl.split(',')[1]);
        const mimeString = compressedDataUrl.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        const compressedBlob = new Blob([ab], { type: mimeString });
        const compressedFile = new File([compressedBlob], file.name, { type: mimeString });

        resolve(compressedFile);
      };

      img.onerror = function () {
        reject(new Error('Error loading the image.'));
      };
    };

    reader.onerror = function () {
      reject(new Error('Error reading the file.'));
    };
    reader.readAsDataURL(file);
  });
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { isIPhoneWithHomeBar } from '../../../../helpers/checkIsIphoneX';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { getUserBalanceSelector, getUserInfoSelector } from '../../../../redux/profile/profileSelectors';
import {
  getIsFetching,
  launchTimerOppaButton as launchEpulTimeButton,
  selectEpulTransfers,
} from '../../../../redux/bonusShop/bonusShopSelectors';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';
import useTimer from '../../../../hooks/useTimerHook';

import { Routes } from '../../../../const';
import { UserRoles } from '../../../../enums';
import { ContentSpinner } from '../../../../components/common/Spinner/ContentSpinner';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { CashoutHistory } from '../../cashoutHistory/cashoutHistory';
import { FAQ_ARTICLE_ID } from '../../../../redux/knowledgeBank/knowledgeBankConstants';
import { QuestionMarkIcon } from '../../../../icons/question-mark-icon';
import { Header } from '../../../../components/common/Header';
import { Balance } from '../../../../components/common/Balance';
import { Button } from '../../../../components/common/Button';
import { checkUserRole } from '../../../../helpers/checkUserRole';

import styles from './EpulMainPage.module.scss';

export const epulTimerValue = 180000;

enum Statuses {
  Success = 1,
  Fail = 2,
  Moderation = 3,
}

export const EpulMainPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const history = useHistory();
  const isFetching = useSelector(getIsFetching);
  const coins = useSelector(getUserBalanceSelector);
  const transfers = useSelector(selectEpulTransfers);
  const userInfo = useSelector(getUserInfoSelector);

  const firstLaunch = useRef(true);
  const launchTimer = useSelector(launchEpulTimeButton);
  const dateEnd: any = localStorage.getItem('endTimeDisableEpulButton');
  const [timerLeft, setTimerLeft] = useState(dateEnd - new Date().getTime());
  const { minutes, seconds } = useTimer(timerLeft, setTimerLeft, launchTimer);

  const isModalShown = checkUserRole(UserRoles.BA, UserRoles.JTI_TEAM, UserRoles.RSM);

  const rate = 1 / 100;
  const notVerifiedUserLimit = 100;

  const sumOfTransactions: number =
    transfers &&
    transfers.reduce((acc: number, curr: any) => {
      if (curr.status !== Statuses.Fail) {
        acc += curr.currency;
        return acc;
      } else {
        return;
      }
    }, 0);

  useEffect(() => {
    if (firstLaunch.current) {
      firstLaunch.current = false;
    } else {
      setTimerLeft(epulTimerValue);
    }
  }, [launchTimer]);

  const openHelpArticle = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://fra1.digitaloceanspaces.com/jti-club-az/bonus-shop/epul-articles/e-pul.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  const onBack = () => {
    history.push(Routes.BonusShopRoute);
  };

  const handlePay = (amount: number) => {
    dispatch(bonusShopActions.makeEpulTransfer({ amount }));
  };

  const openEpulLimitPopup = (amount: number) => {
    dispatch(
      modalActions.openPromptModal({
        title: 'You are close to the limit',
        description:
          "You can't transfer more than 100 AZN without verification in Epul service. Complete the verification in the Epul app. To know more about limits press '?'.",
        btnText: 'epul OK',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          openCashoutModal(handlePay, amount);
        },
      }),
    );
  };

  const openCashoutModal = (callback: (amount: number) => void, amount?: number) => {
    dispatch(
      modalActions.openCashoutModal({
        title: 'Choose amount',
        btnText: 'Proceed',
        symbol: '₼',
        specificCurrency: 'epul coins',
        moneyLimit: 30,
        rate: rate,
        step: 500,
        amountMoneyIcons: [5, 10, 20],
        minimumTransferMoney: 500,
        closedAmount: amount,
        makeTransfer: ({ amount }: { amount: number }) => {
          if (sumOfTransactions <= notVerifiedUserLimit && sumOfTransactions + amount * rate > notVerifiedUserLimit) {
            callback(amount);
          } else {
            handlePay(amount);
          }
        },
      }),
    );
  };

  const makeTransfer = () => {
    if (isModalShown) {
      dispatch(
        modalActions.openPromptModal({
          description: 'Not available for JTI representatives.',
          btnText: 'OK',
        }),
      );
    } else if (coins < 500) {
      dispatch(
        modalActions.openPromptModal({
          title: 'epul Ooops',
          description: 'You don’t have enough coins',
          btnText: 'epul OK',
        }),
      );
    } else {
      openCashoutModal(openEpulLimitPopup);
    }
  };

  return (
    <div>
      {isFetching ? (
        <ContentSpinner />
      ) : (
        <div className={styles.mainPage}>
          <Header hasBackButton customClass={styles.header} goBackCallback={onBack}>
            {formatMessage('Epul')}
          </Header>
          <Balance fixed={false} activePage={0} />

          <div className={styles.container}>
            <div className={styles.wallet}>
              <p className={styles.title}>{formatMessage('Your EPULWallet:')}</p>
              <p className={styles.walletNumber}>{userInfo.phone}</p>
            </div>
            <div className={styles.history}>
              <QuestionMarkIcon className={styles.faq} onClick={openHelpArticle} />
              <CashoutHistory
                symbol="₼"
                rate={rate}
                specificTransfers={transfers}
                specificEmptyPlug={'There are no epul transactions yet. Tap the button below to cashout.'}
              />
            </div>
          </div>
          <div style={{ bottom: isIPhoneWithHomeBar() ? '49px' : '15px' }} className={styles.buttonContainer}>
            {minutes || seconds ? (
              <Button disabled customClass={styles.transferButton}>
                {formatMessage('Next transaction in ')}
                {minutes ? ` ${minutes}m` : ''}
                {seconds ? ` ${seconds}s` : ''}
              </Button>
            ) : (
              <Button customClass={styles.transferButton} onClick={makeTransfer}>
                {formatMessage('epul Transfer')}
              </Button>
            )}
          </div>
          <DisclaimerFooter withoutNavigation />
        </div>
      )}
    </div>
  );
};

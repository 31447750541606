import { all } from '@redux-saga/core/effects';
import { call, put, takeLatest } from 'redux-saga/effects';
import { birthdayActions } from './birthdaySlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { birthdayApi } from '../../api/birthdayApi';

function* postBirthdayCoins() {
  try {
    let { data } = yield call(birthdayApi.sendBirthdayCoins);
    yield put(birthdayActions.fetchCoins(data));
    yield put(modalActions.openHappyBirthdayModal());
  } catch (e) {
    const { message } = e.response.data;
    yield put(
      modalActions.openPromptModal({
        title: 'Ooops',
        description: message,
        btnText: 'Okay',
      }),
    );
  }
}

export function* birthdaySagas() {
  yield all([takeLatest(birthdayActions.sendBirthdayCoins, postBirthdayCoins)]);
}

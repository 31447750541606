import { all, takeLatest, put, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import LogRocket from 'logrocket';
import { reset } from 'redux-form';
import axios from 'axios';

import { AUTH_ACTION_TYPES, ESIGN_STATUSES } from './authConstants';
import { authActions } from './authSlice';
import { profileActions } from '../profile/profileSlice';
import { authApi, initAuthorization } from '../../api/authApi';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { AUTH_STEPS, STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { store } from '../store';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { Socket } from '../../api/SocketHandler';
import { analyticHelper } from '../../helpers/analyticHelper';
import { knowledgeBankActions } from '../knowledgeBank/knowledgeBankSlice';
import { AUTH_METHODS, Routes } from '../../const';
import { brandsActions } from '../brands/brandsSlice';
import { APP_TYPES } from '../../const/appConstants';
import { verificationActions } from '../verification/verificationSlice';
import { chatActions } from '../chat';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import { CONFIG_KEYS, getConfigFor, isFeatureEnabled } from '../../config';
import { settingsActions } from '../settings/settingsSlice';
import { subscribePushEvents } from '../../helpers/subscribePushNotifications';

function* checkAuthStatusSaga() {
  try {
    initAuthorization();
    const savedToken = localStorage.getItem('authToken');

    if (savedToken) {
      const { userId } = jwtDecode(savedToken);

      yield put(authActions.setAuthData({ token: savedToken, userId: userId }));
      yield put(profileActions.getUserInfo());
      yield put(chatActions.fetchUnreadMessagesCount());
      yield put(knowledgeBankActions.getCategories());
      yield put(brandsActions.getBrandsGames());
      yield put(settingsActions.startFetchSettings());

      if (process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS) {
        yield put(verificationActions.getVerificationInfo());
      }

      analyticHelper.setUserId(userId);
      LogRocket.identify(userId);
      Socket.connect(store);
    }
  } catch (err) {
    console.error('checkAuthStatusSaga error', err);
  }
}

function* signupSuccessSaga() {
  const navigateToEnterPhone = () => {
    store.dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: AUTH_STEPS.ENTER_PHONE,
      }),
    );
  };

  yield put(
    modalActions.openPromptModal({
      title: 'Thanks for registration!',
      btnText: 'OK',
      description: 'Your profile will be activated after the verification. We will notify you by SMS',
      onClose: navigateToEnterPhone,
      onButtonClick: () => {
        store.dispatch(modalActions.closeModal());
        navigateToEnterPhone();
      },
    }),
  );
}

function* authByPhone({ payload: phone }) {
  try {
    if (getConfigFor(CONFIG_KEYS.AUTH_METHOD) !== AUTH_METHODS.NONE) {
      return yield put(
        modalActions.openPromptModal({
          description: 'Sign in by phone is not available',
          btnText: 'OK',
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
        }),
      );
    }

    const response = yield authApi.authByPhone(phone);

    if (!response?.data) {
      return;
    }

    const { status, token } = response.data;

    if (status === 0) {
      if (isFeatureEnabled(CONFIG_KEYS.SELF_REGISTRATION_ENABLED)) {
        yield put(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: AUTH_STEPS.SIGNUP,
          }),
        );
      } else {
        yield put(modalActions.openNotVerifiedUserModal());
      }
      return;
    }

    if (isFeatureEnabled(CONFIG_KEYS.PUSH_NOTIFICATIONS_ENABLED)) {
      subscribePushEvents();
    }

    yield put(authActions.authByToken(token));
    yield put(reset('enterPhoneForm'));
  } catch (err) {
    yield call(handleRateLimitError, err);
    console.error('phoneSigninSaga error', err);
  }
}

function* signIn({ payload: { phone, code, password } }) {
  try {
    const response = yield authApi.signIn({ phone, code, password });
    const { data } = response;
    const token = response.data.token;
    amplitudeHelper.initializeUser(data.id);

    if (response.status === 200) {
      if (data.status === 7) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        yield put(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: AUTH_STEPS.SIGNUP,
          }),
        );
      } else {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
        const firebaseToken = localStorage.getItem('firebaseToken');

        if (isFeatureEnabled(CONFIG_KEYS.PUSH_NOTIFICATIONS_ENABLED)) {
          subscribePushEvents();
        }

        yield authApi.token(firebaseToken);
      }
    } else {
      return;
    }

    if (
      !data.download_date &&
      (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND || process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO)
    ) {
      if (data.esign_status !== ESIGN_STATUSES.COMPLETED && process.env.REACT_APP_ENVIRONMENT === 'production') {
        yield put(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: AUTH_STEPS.ESIGN,
          }),
        );
        return;
      }
    }

    if (data.status !== 7) {
      localStorage.setItem('authToken', token);
      yield call(checkAuthStatusSaga);
      yield put(reset('enterPhoneForm'));
    }
  } catch (err) {
    if (err.response?.status === 400) {
      yield put(
        modalActions.openErrorModal({
          btnText: 'Try again',
          description: 'Your verification code is invalid. Please try again',
          onButtonClick: () => store.dispatch(modalActions.closeModal()),
        }),
      );
    }
    yield call(handleRateLimitError, err);
    console.error('authByTokenSaga error', err);
  }
}

function* authByTokenSaga({ payload: token }) {
  try {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem('authToken', token);

    yield call(checkAuthStatusSaga);
  } catch (err) {
    console.error('authByTokenSaga error', err);
  }
}

function* changePassword({ payload: { password } }) {
  try {
    yield authApi.changePassword({ password });

    yield put(push(Routes.EmptyRoute));

    yield put(
      modalActions.openPromptModal({
        btnText: 'OK',
        description: 'password_change_success_modal.description',
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
      }),
    );
  } catch (err) {
    console.error('authByTokenSaga error', err);
  }
}

function* resetPassword({ payload: { phone } }) {
  try {
    yield authApi.resetPassword({ phone });

    yield put(
      modalActions.openPromptModal({
        btnText: 'OK',
        description: 'password_reset_success_modal.description',
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
      }),
    );
  } catch (err) {
    yield call(handleRateLimitError, err);
    console.error('resetPassword error', err);
  }
}

function* sendPhone({ payload: phone }) {
  try {
    const response = yield authApi.sendPhone(phone);

    if (response && response.data.status === 0) {
      yield put(
        isFeatureEnabled(CONFIG_KEYS.SELF_REGISTRATION_ENABLED)
          ? stepListActions.setStep({ stepListId: STEP_LIST_IDS.authStepList, stepId: AUTH_STEPS.SIGNUP })
          : modalActions.openNotVerifiedUserModal(),
      );
    } else if (response) {
      const stepId =
        getConfigFor(CONFIG_KEYS.AUTH_METHOD) === AUTH_METHODS.PASSWORD ? AUTH_STEPS.PASSWORD_ENTER : AUTH_STEPS.VERIFY;

      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.authStepList,
          stepId,
        }),
      );
    }
  } catch (err) {
    yield call(handleRateLimitError, err);
    console.error('sendPhone error', err);
  }
}

function* esignSaga({ payload: { email } }) {
  yield put(authActions.disableButton(true));

  try {
    const response = yield authApi.esign(email);

    if (response.status === 200) {
      yield put(authActions.disableButton(false));
      yield put(modalActions.openEsignEmailModal({}));
    }
  } catch (err) {
    yield put(authActions.disableButton(false));
    console.error('esign error', err);
  }
}

function* esignLoginSaga({ payload: { envelopeId } }) {
  try {
    const response = yield authApi.esignLogin(envelopeId);
    const token = response.data.token;

    if (response.status === 200) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      const firebaseToken = localStorage.getItem('firebaseToken');
      yield authApi.token(firebaseToken);
      localStorage.setItem('authToken', token);
    }

    yield call(checkAuthStatusSaga);
  } catch (err) {
    console.error('esignLogin error', err);
  }
}

function* logoutSaga() {
  Socket.disconnect();

  yield put(profileActions.reset());
  yield put(push(Routes.EmptyRoute));
  setTimeout(() => {
    localStorage.clear();
  }, 50);
}

function* removeAccountSaga() {
  try {
    yield authApi.removeAccount();
    yield put(authActions.logout());
  } catch (err) {
    console.error('remove account saga error', err);
  }
}

function* validateAccountCodeSaga({ payload: { username, date_of_birth, account_code, invite_code } }) {
  try {
    const response = yield authApi.validateAccountCode(username, date_of_birth, invite_code, account_code);
    yield put(authActions.setSignupData(response.data));
  } catch (err) {
    console.error('validateAccountCodeSaga error', err);
  }
}

function* foundMistakeSaga() {
  try {
    const response = yield authApi.foundMistake();

    if (response.status === 200) {
      yield put(
        modalActions.openPromptModal({
          title: 'Thank you!',
          description: 'Your TM will connect with you',
          btnText: 'OK',
          onClose: () => {
            store.dispatch(modalActions.closeModal());
            store.dispatch(
              stepListActions.setStep({
                stepListId: STEP_LIST_IDS.authStepList,
                stepId: AUTH_STEPS.ENTER_PHONE,
              }),
            );
          },
          onButtonClick: () => {
            store.dispatch(modalActions.closeModal());
            store.dispatch(
              stepListActions.setStep({
                stepListId: STEP_LIST_IDS.authStepList,
                stepId: AUTH_STEPS.ENTER_PHONE,
              }),
            );
          },
        }),
      );
    }
  } catch (err) {
    console.error('foundMistakeSaga error', err);
  }
}

function* sendSignatureSaga({ payload }) {
  try {
    const response = yield authApi.sendSignature(payload);

    if (response.status === 200) {
      const token = localStorage.getItem('temporaryAuthToken');
      localStorage.removeItem('temporaryAuthToken');
      localStorage.setItem('authToken', token);
      yield call(checkAuthStatusSaga);
    }
  } catch (err) {
    console.error('sendSignatureSaga error', err);
  }
}

function* signupSaga({ payload: { link, signupForm } }) {
  try {
    const { data } = yield authApi.signup({
      ...signupForm,
      signature: link,
    });

    const token = data.token;
    localStorage.setItem('authToken', token);

    yield call(checkAuthStatusSaga);
    yield put(reset('signupForm'));
  } catch (e) {
    console.error(e);
  }
}

function* handleRateLimitError(error) {
  if (error.response?.status === 429) {
    yield put(
      modalActions.openPromptModal({
        title: 'Too many attempts',
        description: 'You have reached auth request limit try later',
        btnText: 'OK',
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
      }),
    );
  }
}

function* sagas() {
  yield all([
    call(checkAuthStatusSaga),
    takeLatest(AUTH_ACTION_TYPES.RESET_PASSWORD, resetPassword),
    takeLatest(AUTH_ACTION_TYPES.CHANGE_PASSWORD, changePassword),
    takeLatest(AUTH_ACTION_TYPES.AUTH_BY_PHONE, authByPhone),
    takeLatest(AUTH_ACTION_TYPES.SIGN_IN, signIn),
    takeLatest(AUTH_ACTION_TYPES.AUTH_BY_TOKEN, authByTokenSaga),
    takeLatest(AUTH_ACTION_TYPES.SEND_PHONE, sendPhone),
    takeLatest(AUTH_ACTION_TYPES.LOGOUT, logoutSaga),
    takeLatest(AUTH_ACTION_TYPES.REMOVE_ACCOUNT, removeAccountSaga),
    takeLatest(AUTH_ACTION_TYPES.SIGNUP_SUCCESS, signupSuccessSaga),
    takeLatest(authActions.esign, esignSaga),
    takeLatest(authActions.esignLogin, esignLoginSaga),
    takeLatest(authActions.validateAccountCode, validateAccountCodeSaga),
    takeLatest(authActions.foundMistake, foundMistakeSaga),
    takeLatest(authActions.sendSignature, sendSignatureSaga),
    takeLatest(authActions.signup, signupSaga),
  ]);
}

export const authSagas = sagas;

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';

import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../config';
import { LogoutDeleteAccount } from '../../../components/LogoutDeleteAccount/LogoutDeleteAccount';

import '../profileSettings.scss';

export const ProfileSettingsBottomArea = () => {
  const { formatMessage } = useInternationalization();

  const userInfo = useSelector((state) => state.profile.userInfo);

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const shareCode = () => {
    copy(userInfo.invite_code);
    analyticHelper.profileEditInviteCode();
  };

  return (
    <div className="profile-settings-page__content-invite-block">
      {isFeatureEnabled(CONFIG_KEYS.INVITE_CODE_ENABLED) && (
        <>
          <p className="profile-settings-page__content-invite-block-caption">{formatMessage('Invite code')}</p>
          <p className={classnames('profile-settings-page__content-invite-block-caption', 'text')}>
            {formatMessage('Share this invite code with your colleagues to get extra points.')}
          </p>
          <div className="profile-settings-page__content-invite-block-code" onClick={shareCode}>
            <p className="profile-settings-page__content-invite-block-code-text">{userInfo.invite_code}</p>
          </div>
        </>
      )}
      <LogoutDeleteAccount />
    </div>
  );
};

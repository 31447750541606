import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import Webcam from 'react-webcam';

import styles from './PromoCodeScan.module.scss';
import { findCodeInDetectionResponse } from '../../helpers/cloudVisionHelper';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ArrowIconRounded } from '../../icons/arrow-icon-rounded';
import { Button } from '../../components/common/Button/Button';
import { passportApi } from '../../api/passportApi';
import camera from './assets/camera.png';
import frame from './assets/frame.png';
import { Routes } from '../../const';
import Banner from './Banner';

interface ScreenshotDimensions {
  width: number;
  height: number;
}

const screenshotDimensions: ScreenshotDimensions = {
  width: 900,
  height: 1500,
};

const videoConstraints: MediaTrackConstraints = {
  facingMode: 'environment',
  aspectRatio: 5 / 3,
  width: { min: 500 },
  height: { min: 500 },
};

const PromoCodeScan = () => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();
  const history = useHistory();
  const webcamRef = useRef<Webcam>(null);
  const [isPending, setIsPending] = useState(false);

  const openErrorModal = useCallback(() => {
    dispatch(
      modalActions.openErrorModal({
        description: t('promo_code_scan.not_recognised_modal.description'),
        btnText: t('promo_code_scan.not_recognised_modal.button'),
        onButtonClick: () => dispatch(modalActions.closeModal()),
      }),
    );
  }, [dispatch]);

  const navigateToPromoCodePage = () => history.push(Routes.PromoCodeRoute);

  const navigateToMainPage = () => history.push(Routes.MainRoute);

  const handleDiscountCodeScan = async () => {
    try {
      const base64Image = webcamRef.current?.getScreenshot(screenshotDimensions);

      if (!base64Image) {
        throw new Error('Base64Image is missing');
      }

      const result = await passportApi.getTextFromPhotoBase64(base64Image);
      const code = findCodeInDetectionResponse(result);

      if (!code) {
        throw new Error('Code is missing');
      }

      return history.push(`${Routes.PromoCodeRoute}/?code=${code}`);
    } catch (error) {
      openErrorModal();
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div className={classNames(styles.root, 'themed-background-image')}>
      <img className="frame" src={frame} />
      <img className="camera" src={camera} />
      <Banner />
      <Button onClick={navigateToMainPage} customClass="back-button" withoutTheme>
        <ArrowIconRounded className="icon" />
      </Button>
      <Webcam ref={webcamRef} screenshotFormat="image/jpeg" className="webcam " videoConstraints={videoConstraints} />
      <div className="actions">
        <Button withoutTheme disabled={isPending} onClick={handleDiscountCodeScan}>
          {t('promo_code_scan.capture_button')}
        </Button>
        <Button withoutTheme disabled={isPending} onClick={navigateToPromoCodePage}>
          {t('promo_code.manual_button')}
        </Button>
      </div>
    </div>
  );
};

export default PromoCodeScan;

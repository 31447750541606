import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getWordsFormat } from '../../../helpers/getWordsFormat';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalSlice';
import { getUnexpectedRewardsSelector } from '../../../redux/unexpectedRewards/unexpectedRewardsSelectors';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { Button } from '../../common/Button';

import { MODALS } from '../../containers/Modal/modalConstants';
import bronzeRewardGif from './rewardsImgs/reward_bronze.gif';
import goldRewardGif from './rewardsImgs/reward_gold.gif';
import silverRewardGif from './rewardsImgs/reward_silver.gif';

import './UnexpectedRewardsModal.scss';

const styles = {
  width: 343,
};

const REWARDS_LEVELS = [
  {
    id: 1,
    image: goldRewardGif,
  },
  {
    id: 2,
    image: silverRewardGif,
  },
  {
    id: 3,
    image: bronzeRewardGif,
  },
];

export const UnexpectedRewardsModal = (props) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  let {
    data: { onClose, btnText, onButtonClick },
  } = useSelector(getModalDataSelector);

  const unexpectedReward = useSelector(getUnexpectedRewardsSelector);

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.unexprectedRewards} onClose={onClose} customStyles={styles}>
      <div className="unexpected-rewards">
        <div>
          <img
            className="unexpected-rewards__reward-image"
            src={REWARDS_LEVELS.find((i) => i.id === unexpectedReward.level.id).image}
            alt={unexpectedReward.level.name}
          />
        </div>
        <div className="unexpected-rewards__title">{formatMessage('You have a reward')}</div>
        <div className="unexpected-rewards__reward">
          {formatMessage(getWordsFormat(unexpectedReward.coins, `+{coins} points`), { coins: unexpectedReward.coins })}
        </div>
        <div className="unexpected-rewards__description">{unexpectedReward.description}</div>
        <Button onClick={handleButtonClick} customClass="unexpected-rewards__btn">
          {formatMessage(btnText)}
        </Button>
      </div>
    </ModalContainer>
  );
};

UnexpectedRewardsModal.propTypes = {
  onClose: PropTypes.func,
  description: PropTypes.node,
};
UnexpectedRewardsModal.defaultProps = {};

export const GAME_RESULT_TEXT = {
  lose: [
    // formatMessage('You lost this time, but remember that winners never quit! Have another go.')
    'You lost this time, but remember that winners never quit! Have another go.',
    // formatMessage('Winners never quit and quitters never win. Try again!')
    'Winners never quit and quitters never win. Try again!',
    // formatMessage('A narrow defeat. Better luck next time! Go again')
    'A narrow defeat. Better luck next time! Go again',
    // formatMessage('If at first you don’t succeed…Try again!')
    'If at first you don’t succeed…Try again!',
  ],
  win: [
    // formatMessage('Competition was fierce but you had the edge. Congratulations!')
    'Competition was fierce but you had the edge. Congratulations!',
    // formatMessage('Your opponent didn’t stand a chance. A comprehensive victory!')
    'Your opponent didn’t stand a chance. A comprehensive victory!',
    // formatMessage('Victory is yours! Well done')
    'Victory is yours! Well done',
    // formatMessage('The hallmark of a true winner. What a result!')
    'The hallmark of a true winner. What a result!',
    // formatMessage('Incredible effort to take the win! Well done!')
    'Incredible effort to take the win! Well done!',
  ],
  draw: [
    // formatMessage('It’s neck and neck at full time, but who will take the replay? Go again')
    'It’s neck and neck at full time, but who will take the replay? Go again',
    // formatMessage('There was no separating the two sides this time. Have another go')
    'There was no separating the two sides this time. Have another go',
    // formatMessage('That was too close to call! Try again to break the deadlock')
    'That was too close to call! Try again to break the deadlock',
    // formatMessage('All square! Try again')
    'All square! Try again',
    // formatMessage('A draw was the fair result. Have another go')
    'A draw was the fair result. Have another go',
    // formatMessage('Both sides are so evenly matched. Will you take the honours in a replay?')
    'Both sides are so evenly matched. Will you take the honours in a replay?',
  ],
};

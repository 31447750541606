import { HTMLAttributes } from 'react';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { CONFIG_KEYS, getConfigFor } from '../../config';

const PurchaseLimitWarning = (props: HTMLAttributes<HTMLElement>) => {
  const { t } = useInternationalization();

  const limit = getConfigFor(CONFIG_KEYS.BONUS_SHOP_PURCHASE_LIMIT);

  if (!limit) {
    return null;
  }

  return <b {...props}>{t('bonus_shop.purchase_limit_items_per_user', { limit })}</b>;
};

export default PurchaseLimitWarning;

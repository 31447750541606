import React from 'react';

import { PlusIcon } from '../../../icons/plus-icon';
import { useInternationalization } from '../../../hooks/useTranslationHook';

import styles from './ExitButton.module.scss';

export const ExitButton = ({ onClick }: { onClick: () => void }) => {
  const { formatMessage } = useInternationalization();
  return (
    <div className={styles.container} onClick={onClick}>
      <PlusIcon className={styles.icon} />
      <span className={styles.text}>{formatMessage('Exit')}</span>
    </div>
  );
};

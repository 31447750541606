import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './chooseAmountPage.scss';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { getUserBalanceSelector } from '../../../../redux/profile/profileSelectors';
import {
  getIsTransferPendingSelector,
  getPerxSegmentationCoefficient,
} from '../../../../redux/bonusShop/bonusShopSelectors';

import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { BackArrowButton } from '../../../../components/common/BackArrowButton/BackArrowButton';
import { MinusIcon } from '../../../../icons/minus-money-icon';
import { PlusIcon } from '../../../../icons/plus-money-icon';
import { Button } from '../../../../components/common/Button';
import { ArrowWithTailIcon } from '../../../../icons/arrow-with-tail';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';

export const ChooseAmountPage = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const coins = useSelector(getUserBalanceSelector);
  const isTransferPending = useSelector(getIsTransferPendingSelector);
  const coefficient = useSelector(getPerxSegmentationCoefficient);

  const increaseByValues = [10, 50, 100];
  const step = 1;
  const moneyLimit = 200;

  const [currentMoneyAmount, setCurrentMoneyAmount] = useState(20);

  const isIncreaseButtonDisabled = (amountMoney) => {
    return coins - getCoins() >= step * coefficient * amountMoney && currentMoneyAmount + amountMoney <= moneyLimit;
  };

  const getCoins = useCallback(() => {
    return currentMoneyAmount * coefficient;
  }, [coefficient, currentMoneyAmount]);

  const decreaseMoney = () => {
    if (!isTransferPending) {
      if (currentMoneyAmount > 20) {
        setCurrentMoneyAmount(currentMoneyAmount - step);
      }
    }
  };

  const increaseMoney = (amountMoney = 1) => {
    if (!isTransferPending) {
      if (isIncreaseButtonDisabled(amountMoney)) {
        setCurrentMoneyAmount(currentMoneyAmount + amountMoney);
      }
    }
  };

  const disabledMoneyButton = useCallback(
    (amountMoney = 1) => {
      return (
        coins - getCoins() < step * coefficient * amountMoney ||
        currentMoneyAmount + amountMoney > moneyLimit ||
        isTransferPending
      );
    },
    [currentMoneyAmount, coins, step, moneyLimit, isTransferPending, coefficient, getCoins],
  );

  const openTransferWindow = () => {
    dispatch(
      modalActions.openPromptModal({
        title: `Are you sure you want to exchange ${getCoins()} points (€${currentMoneyAmount})?`,
        description: 'It cannot be cancelled',
        btnText: 'Yes, exchange',
        onButtonClick: () => {
          dispatch(bonusShopActions.makePerxTransfer({ amount: currentMoneyAmount * coefficient }));
        },
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className="choose-amount-page">
      <div className="choose-amount-page__content">
        <BackArrowButton withoutTheme customClass="choose-amount-page__content-back-button" />
        <div className="choose-amount-page__content-title-container">
          <p className="choose-amount-page__content-title-container-title-bold">
            {formatMessage('Choose points amount')}
          </p>
          <p className="choose-amount-page__content-title-container-title">{formatMessage('Use +/- buttons')}</p>
        </div>
        <div className="choose-amount-page__content-counter">
          <p className="choose-amount-page__content-counter-rate-money">{`${getCoins()} ${formatMessage('points')}`}</p>
          <ArrowWithTailIcon fillColor="#01c1b6" />
          <div className="choose-amount-page__content-counter-increase-buttons">
            <MinusIcon disabled={currentMoneyAmount <= 20 || isTransferPending} onClick={decreaseMoney} />
            <div className="choose-amount-page__content-counter-rate">
              <p className="choose-amount-page__content-counter-rate-coins">{`€${currentMoneyAmount}`}</p>
            </div>
            <PlusIcon disabled={disabledMoneyButton(1)} onClick={() => increaseMoney(1)} />
          </div>
          <div className="choose-amount-page__content-counter-increase-by">
            {increaseByValues.map((el) => (
              <Button
                onClick={() => increaseMoney(el)}
                disabled={disabledMoneyButton(el)}
                key={el}
                type="button"
                className="choose-amount-page__content-counter-increase-by-button"
              >
                {'+€' + el}
              </Button>
            ))}
          </div>
        </div>

        <div className="choose-amount-page__content-button-container">
          <Button
            disabled={currentMoneyAmount === 0}
            customClass={'choose-amount-page__content-button-container-btn'}
            onClick={openTransferWindow}
          >
            {formatMessage('OK')}
          </Button>
        </div>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from '../../../const';

import './moduleComponent.scss';

import { questionOfTheDaySliceActions } from '../../../redux/questionOfTheDay/questionOfTheDaySlice';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { COMPONENT_TYPES, KB_SECTION_TYPES } from '../../../const/mainScreenConsts';
import { settingsActions } from '../../../redux/settings/settingsSlice';
import { GAME_MODES } from '../../../redux/settings/settingsConstants';
import { analyticHelper, MAIN_SCREEN_ACTIVITY_LABELS } from '../../../helpers/analyticHelper';
import { eventModeActions } from '../../../redux/eventMode/eventModeSlice';
import { singleModeActions } from '../../../redux/singleMode/singleModeSlice';
import { surveyActions } from '../../../redux/survey/surveySlice';
import { multibrandQuizActions } from '../../../redux/multibrandQuiz/multibrandQuizSlice';
import { dailyGamesActions } from '../../../redux/dailyGames/dailyGamesSlice';
import { getUserIdSelector } from '../../../redux/auth/authSelectors';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';
import { selectChances } from '../../../redux/chance/chanceSelectors';

const CATEGORY_TYPES = {
  DEFAULT: 1,
  SALES_TIPS: 3,
  SALES_SIMULATORS: 4,
};

export const ModuleComponent = (props) => {
  const { payload, name, image, type, history, background_color, text_color } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const userId = useSelector(getUserIdSelector);
  const chances = useSelector(selectChances);
  const chanceId = chances[0]?.id;

  const goToCategoryCallback = useCallback(() => {
    if (payload.should_choose_brand && payload.type === CATEGORY_TYPES.DEFAULT) {
      return history.push(`${Routes.KnowledgeBankRoute}/${payload.id}/brands`);
    }
    if (payload.type === CATEGORY_TYPES.SALES_TIPS) {
      return history.push(`${Routes.KnowledgeBankRoute}/sales-tips`);
    }
    if (payload.type === CATEGORY_TYPES.SALES_SIMULATORS) {
      return history.push(`${Routes.KnowledgeBankRoute}/sales-simulators`);
    }
    return history.push(`${Routes.KnowledgeBankRoute}/${payload.id}`);
  }, [history, payload.id, payload.type, payload.should_choose_brand]);

  const goToKBSectionCallback = () => {
    if (payload.section === KB_SECTION_TYPES.KNOWLEDGE_BANK) {
      return history.push(Routes.KnowledgeBankRoute);
    }
    if (payload.section === KB_SECTION_TYPES.BRANDING_BAN) {
      return history.push(Routes.BrandingBanRoute);
    }
  };

  const handleClick = () => {
    switch (type) {
      case COMPONENT_TYPES.SOLO_QUIZ:
        dispatch(settingsActions.setGameMode(GAME_MODES.SOLO));
        if (!payload.brand_id) {
          history.push(Routes.BrandsRoute);
          analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.SOLO);
        } else {
          history.push(`/solo-mode/${payload.brand_id}`);
          analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.DIRECTIONAL_SOLO, payload.brand_id);
        }
        amplitudeHelper.mainSolo();
        return;
      case COMPONENT_TYPES.QUIZ_WITH_USER:
        dispatch(settingsActions.setGameMode(GAME_MODES.HEAD_TO_HEAD));
        if (!payload.brand_id) {
          history.push(Routes.BrandsRoute);
          analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.H2H);
        } else {
          history.push(`/quiz/${payload.brand_id}`);
          analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.DIRECTIONAL_H2H, payload.brand_id);
        }
        amplitudeHelper.mainH2h();
        return;
      case COMPONENT_TYPES.CHECK_IN:
        history.push(Routes.CheckInStatusRoute);
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.CHECK_IN);
        return;
      case COMPONENT_TYPES.QUESTION_OF_THE_DAY:
        const { question, is_new, coins } = payload;
        dispatch(
          questionOfTheDaySliceActions.setQuestions({
            question,
            is_new,
            coins,
          }),
        );
        dispatch(questionOfTheDaySliceActions.startQuestionOfTheDay());
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.QOD);
        return;
      case COMPONENT_TYPES.SURVEY:
      case COMPONENT_TYPES.KB_ARTICLE:
        if (payload.survey_id) {
          if (payload.is_new) {
            dispatch(surveyActions.startSurvey({ survey_id: payload.survey_id, surveyStatus: payload.is_new }));
          } else {
            history.push(Routes.noBonusSurveyRoute);
          }
        } else {
          dispatch(knowledgeBankActions.openArticle({ article: payload }));
        }
        amplitudeHelper.mainArticle();
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_ARTICLE);
        return;
      case COMPONENT_TYPES.SPECIAL_QUIZ:
        amplitudeHelper.mainSpecialQuizzes();
        dispatch(
          specialQuizActions.startSpecialQuiz({
            special_quiz_id: payload.id,
            brand_id: payload.brand_id,
          }),
        );
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.SPECIAL_QUIZ);
        return;
      case COMPONENT_TYPES.KB_GAME:
        dispatch(knowledgeBankActions.openArticle({ article: payload }));
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_GAME);
        amplitudeHelper.mainKBGame();
        return;
      case COMPONENT_TYPES.BONUS_SHOP:
        history.push(Routes.BonusShopRoute);
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.BONUS_SHOP);
        return;
      case COMPONENT_TYPES.GENERAL_QUIZ:
        history.push(Routes.BrandsRoute);
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.GENERAL_QUIZZES);
        return;
      case COMPONENT_TYPES.LOTTERY:
        history.push(`${Routes.ChanceRoute}/${chanceId}`);
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.CHANCE);
        return;
      case COMPONENT_TYPES.SINGLE_MODE:
        if (payload.is_new) {
          dispatch(singleModeActions.setSingleModeStarted());
          history.push(Routes.SingleMode);
        } else {
          dispatch(
            modalActions.openPromptModal({
              title: formatMessage('Single mode is completed'),
              description: formatMessage('New quizzes will be available next wednesday'),
              btnText: formatMessage('Got it'),
              onButtonClick: () => {
                dispatch(modalActions.closeModal());
              },
            }),
          );
        }
        return;
      case COMPONENT_TYPES.EVENT_MODE:
        if (payload.is_new) {
          dispatch(eventModeActions.startEventMode());
        } else {
          dispatch(
            modalActions.openPromptModal({
              title: 'Good things come to those who wait',
              description: 'You can play with a new quiz tomorrow!(event)',
              btnText: 'OK',
              onButtonClick: () => {
                dispatch(modalActions.closeModal());
              },
            }),
          );
        }
        return;
      case COMPONENT_TYPES.KB_CATEGORY:
        goToCategoryCallback();
        return;
      case COMPONENT_TYPES.PACK_RECOGNITION:
        analyticHelper.onOpenAR(userId);
        history.push(Routes.Recognition);
        return;
      case COMPONENT_TYPES.MULTIBRAND_QUIZ:
        const { id } = payload;
        if (id) {
          dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id, is_new }));
        } else {
          dispatch(multibrandQuizActions.startMultibrandQuiz());
        }
        analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.MB_QUIZ);
        return;
      case COMPONENT_TYPES.LOYALTY_PROGRAM: {
        const { is_new } = payload;
        if (is_new) {
          history.push(Routes.LoyaltyProgramRoute);
        } else {
          dispatch(
            modalActions.openPromptModal({
              title: formatMessage('VIP Program is now finished'),
              description: formatMessage('We look forward to welcoming you back to the VIP Program again next time!'),
              btnText: formatMessage('Got it'),
              onButtonClick: () => {
                dispatch(modalActions.closeModal());
              },
            }),
          );
        }
        return;
      }
      case COMPONENT_TYPES.INVITE_CODE: {
        history.push(Routes.InviteCodeRoute);
        analyticHelper.mainscreenInviteCodeOpen();
        return;
      }
      case COMPONENT_TYPES.ACHIEVEMENTS: {
        history.push(Routes.Achievements);
        analyticHelper.mainscreenAchievementsOpen();
        return;
      }
      case COMPONENT_TYPES.ACHIEVEMENT: {
        const { achievement_id } = payload;
        history.push(`${Routes.SelectedAchievement}/${achievement_id}`);
        return;
      }
      case COMPONENT_TYPES.LEP: {
        history.push(Routes.LEP);
        return;
      }
      case COMPONENT_TYPES.DAILY_GAME:
        amplitudeHelper.mainDailyGame();
        payload.url && dispatch(dailyGamesActions.openDailyGame({ dailyGame: payload }));
        return;
      case COMPONENT_TYPES.KB:
        goToKBSectionCallback();
        return;
      case COMPONENT_TYPES.CUSTOM_ROUTE:
        history.push(payload.route);
        return;
      default:
        break;
    }
  };
  return (
    <div className="module-block" style={{ background: background_color }} onClick={handleClick}>
      <div className="module-block__module-name" style={{ color: text_color }}>
        {name}
      </div>
      <div
        className="module-block__module-image"
        style={{
          backgroundImage: `url(${image})`,
        }}
        alt="module-item"
      />
    </div>
  );
};

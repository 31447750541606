export const FooterBy = () => {
  return (
    <svg width="343" height="26" viewBox="0 0 343 27" fill="none">
      <path d="M0 0.371094H343V26.3711H0V0.371094Z" fill="url(#pattern0)" />
      <path d="M0 0.371094H343V26.3711H0V0.371094Z" fill="white" />
      <g clipPath="url(#clip0_6168_10758)">
        <path
          d="M22.0164 13.9354H21.011V22.0444H19V4.57901H21.011V12.6879L21.6813 12.2721L26.0383 4.37109H28.3844L24.3625 12.2721L23.3571 13.1038L24.3625 14.1434L29.3899 22.2523H26.7087L22.0164 13.9354Z"
          fill="black"
        />
        <path
          d="M35.0878 13.5197L35.7582 15.5989L36.0933 13.3118L38.7746 4.5791H41.1207L37.434 15.8068C35.7582 20.7969 35.0878 22.2524 33.0769 22.2524C32.4066 22.2524 31.7362 22.0444 31.4011 21.8365L32.0714 20.589C32.4066 20.7969 32.7417 21.0048 33.0769 21.0048C33.7472 21.0048 34.0824 19.7573 34.7527 17.6781L29.3901 4.5791H31.7362L35.0878 13.5197Z"
          fill="black"
        />
        <path
          d="M42.1255 4.78694C43.4661 4.57901 44.4716 4.37109 45.8122 4.37109C48.8287 4.37109 51.5099 5.4107 51.5099 9.56911C51.5099 13.7275 48.8287 15.183 46.1474 15.183C45.8122 15.183 44.8068 15.183 44.4716 15.183V22.0444H42.1255V4.78694ZM44.4716 5.82654V13.9354C44.8068 13.9354 45.4771 14.1434 45.8122 14.1434C47.8232 14.1434 49.1638 13.1038 49.1638 9.77703C49.1638 6.86614 48.1583 5.82654 45.8122 5.82654C45.4771 5.82654 44.8068 5.82654 44.4716 5.82654Z"
          fill="black"
        />
        <path
          d="M53.521 4.5791H61.5648V5.82663H55.8671V12.2722H61.2296V13.5197H55.8671V20.589H61.9V21.8365H53.8562V4.5791H53.521Z"
          fill="black"
        />
        <path
          d="M72.2899 13.7276H66.257V22.0444H64.2461V4.5791H66.257V12.4801H72.2899V4.5791H74.3009V22.0444H72.2899V13.7276Z"
          fill="black"
        />
        <path
          d="M86.0318 10.8167L86.367 8.11375L85.3615 10.8167L79.3286 22.0444H77.6528V4.5791H79.6638V16.2227V18.9256L80.6693 16.2227L86.7021 4.78702H88.3779V22.2524H86.0318V10.8167Z"
          fill="black"
        />
        <path
          d="M91.0591 4.5791H99.1029V5.82663H93.4052V12.2722H98.7677V13.5197H93.4052V20.589H99.438V21.8365H91.3942V4.5791H91.0591Z"
          fill="black"
        />
        <path
          d="M112.174 4.37109C116.866 4.37109 118.207 7.69783 118.207 13.3117C118.207 19.3414 116.866 22.2523 112.174 22.2523C107.482 22.2523 106.477 18.9255 106.477 13.3117C106.477 7.48991 107.817 4.37109 112.174 4.37109ZM112.174 21.2127C114.856 21.2127 115.861 18.7176 115.861 13.5196C115.861 9.56911 115.191 5.82654 112.174 5.82654C109.493 5.82654 108.488 8.32159 108.488 13.5196C108.488 17.4701 109.493 21.2127 112.174 21.2127Z"
          fill="black"
        />
        <path d="M128.597 6.03455H122.9V22.2524H120.889V4.5791H130.943V22.0444H128.932V6.03455H128.597Z" fill="black" />
        <path
          d="M140.328 16.8463H135.635L134.295 22.0444H132.284L137.311 4.37109H138.652L144.014 22.0444H141.668L140.328 16.8463ZM136.306 15.5988H139.992C139.992 15.5988 138.652 10.1929 138.652 9.98496L138.317 7.48991L137.981 10.1929L136.306 15.5988Z"
          fill="black"
        />
        <path
          d="M154.404 21.6286C153.399 22.2524 152.058 22.4603 150.717 22.4603C146.696 22.4603 144.685 20.1732 144.685 13.5197C144.685 6.86623 147.031 4.5791 150.717 4.5791C152.058 4.5791 153.399 4.78702 154.069 5.20286L153.399 6.45039C152.728 6.24247 151.723 5.82663 150.717 5.82663C148.036 5.82663 146.36 7.69791 146.36 13.5197C146.36 18.7177 147.701 21.2128 150.717 21.2128C151.723 21.2128 152.728 20.7969 153.399 20.3811L154.404 21.6286Z"
          fill="black"
        />
        <path
          d="M164.458 13.7276H158.425V22.0444H156.415V4.5791H158.425V12.4801H164.458V4.5791H166.469V22.0444H164.458V13.7276Z"
          fill="black"
        />
        <path
          d="M175.184 4.37109C179.876 4.37109 181.217 7.69783 181.217 13.3117C181.217 19.3414 179.876 22.2523 175.184 22.2523C170.492 22.2523 169.486 18.9255 169.486 13.3117C169.151 7.48991 170.492 4.37109 175.184 4.37109ZM175.184 21.2127C177.865 21.2127 178.871 18.7176 178.871 13.5196C178.871 9.56911 178.2 5.82654 175.184 5.82654C172.503 5.82654 171.497 8.32159 171.497 13.5196C171.497 17.4701 172.167 21.2127 175.184 21.2127Z"
          fill="black"
        />
        <path
          d="M199.65 25.3712H197.974L197.639 22.0444H188.255L187.92 25.3712H186.579V20.7969H187.92C188.59 20.1732 189.931 15.1831 189.931 4.5791H197.974V20.7969H199.65V25.3712ZM195.628 20.7969V6.03455H191.942C191.942 13.7276 190.936 18.5098 190.266 20.7969H195.628Z"
          fill="black"
        />
        <path
          d="M209.035 6.03463H205.348C205.013 12.6881 205.013 17.4703 204.008 19.9653C203.337 21.6287 202.667 22.2525 201.326 22.2525C200.656 22.2525 200.321 22.2525 199.986 22.0445L200.321 20.797H200.656C201.326 20.797 201.662 20.3812 201.997 19.5495C202.667 17.6782 203.337 13.3119 203.337 4.78711H211.046V22.2525H209.035V6.03463Z"
          fill="black"
        />
        <path
          d="M222.777 22.0444H220.766V14.1434L218.755 14.7671L215.403 22.0444H213.057L216.074 15.5988L217.414 14.5592C215.068 14.1434 214.063 12.48 214.063 9.56911C214.063 5.4107 216.744 4.37109 219.425 4.37109C220.431 4.37109 221.771 4.37109 222.777 4.78694V22.0444ZM216.074 9.56911C216.074 12.2721 217.079 13.3117 219.09 13.3117H220.431V5.82654C220.095 5.61862 219.76 5.61862 219.09 5.61862C217.414 5.82654 216.074 6.65822 216.074 9.56911Z"
          fill="black"
        />
        <path
          d="M232.161 13.7275V12.2721H232.831C233.501 12.2721 234.172 12.2721 234.507 12.0642C235.848 11.6483 236.853 10.4008 236.853 8.73743C236.853 6.4503 235.848 5.4107 233.837 5.4107C232.831 5.4107 231.826 5.61862 231.155 6.03446L230.485 4.99486C231.155 4.78694 232.496 4.37109 234.172 4.37109C236.853 4.37109 238.864 5.20278 238.864 8.73743C238.864 10.4008 238.194 12.0642 236.183 12.8958C237.859 13.3117 239.199 14.7671 239.199 17.0543C239.199 20.7968 237.188 22.4602 233.837 22.4602C232.161 22.4602 230.82 22.2523 230.15 21.8364L230.82 20.5889C231.491 21.0048 232.496 21.2127 233.837 21.2127C236.183 21.2127 237.188 19.7572 237.188 17.2622C237.188 14.9751 236.183 13.9354 233.837 13.9354C233.501 13.9354 233.166 13.9354 232.831 13.9354H232.161V13.7275Z"
          fill="black"
        />
        <path
          d="M253.276 25.3712H251.6L251.265 22.0444H241.881L241.546 25.3712H240.205V20.7969H241.546C242.216 20.1732 243.557 15.1831 243.557 4.5791H251.6V20.7969H253.276V25.3712ZM249.59 20.7969V6.03455H245.903C245.903 13.7276 244.897 18.5098 244.227 20.7969H249.59Z"
          fill="black"
        />
        <path
          d="M260.314 4.37109C265.006 4.37109 266.347 7.69783 266.347 13.3117C266.347 19.3414 265.006 22.2523 260.314 22.2523C255.622 22.2523 254.617 18.9255 254.617 13.3117C254.281 7.48991 255.622 4.37109 260.314 4.37109ZM260.314 21.2127C262.996 21.2127 264.001 18.7176 264.001 13.5196C264.001 9.56911 263.331 5.82654 260.314 5.82654C257.633 5.82654 256.628 8.32159 256.628 13.5196C256.628 17.4701 257.298 21.2127 260.314 21.2127Z"
          fill="black"
        />
        <path
          d="M268.693 4.78694C269.699 4.57901 271.039 4.37109 272.38 4.37109C275.397 4.37109 278.078 5.4107 278.078 9.56911C278.078 13.7275 275.397 15.183 272.715 15.183C272.38 15.183 271.375 15.183 271.039 15.183V22.0444H269.029V4.78694H268.693ZM270.704 5.82654V13.9354C271.039 13.9354 271.71 14.1434 272.045 14.1434C274.056 14.1434 275.732 13.1038 275.732 9.77703C275.732 6.86614 274.726 5.82654 272.38 5.82654C271.71 5.82654 271.039 5.82654 270.704 5.82654Z"
          fill="black"
        />
        <path
          d="M285.116 4.37109C289.808 4.37109 291.149 7.69783 291.149 13.3117C291.149 19.3414 289.808 22.2523 285.116 22.2523C280.424 22.2523 279.418 18.9255 279.418 13.3117C279.083 7.48991 280.424 4.37109 285.116 4.37109ZM285.116 21.2127C287.797 21.2127 288.803 18.7176 288.803 13.5196C288.803 9.56911 288.133 5.82654 285.116 5.82654C282.435 5.82654 281.429 8.32159 281.429 13.5196C281.429 17.4701 282.1 21.2127 285.116 21.2127Z"
          fill="black"
        />
        <path
          d="M299.528 12.8958C301.204 13.3117 302.545 14.3513 302.545 17.0543C302.545 20.5889 300.534 22.2523 297.182 22.2523C296.177 22.2523 294.501 22.2523 293.16 21.8364V4.78694C294.166 4.57901 295.841 4.37109 297.517 4.37109C300.198 4.37109 302.545 4.99486 302.545 8.52951C302.209 10.4008 301.539 12.2721 299.528 12.8958ZM296.512 12.6879C296.847 12.6879 297.517 12.6879 298.188 12.48C299.193 12.0642 300.198 10.6087 300.198 8.94535C300.198 6.65822 299.528 5.61862 297.517 5.61862C296.847 5.61862 295.841 5.61862 295.506 5.82654V12.6879H296.512ZM300.534 17.2622C300.534 14.3513 299.193 13.7275 297.182 13.7275H295.506V20.5889C295.841 21.0048 296.847 21.0048 297.517 21.0048C299.528 21.0048 300.534 19.5493 300.534 17.2622Z"
          fill="black"
        />
        <path
          d="M305.226 4.5791H307.237V11.4405C307.907 11.2326 308.913 11.2326 309.248 11.2326C312.264 11.2326 314.275 12.2722 314.275 16.4306C314.275 20.589 312.934 22.2524 308.577 22.2524C307.907 22.2524 306.231 22.2524 304.891 22.0444V4.5791H305.226ZM312.264 16.6385C312.264 13.5197 311.259 12.688 308.913 12.688C308.577 12.688 307.572 12.688 307.237 12.8959V20.7969C307.907 21.0048 308.577 21.0048 308.913 21.0048C311.594 21.0048 312.264 19.5494 312.264 16.6385Z"
          fill="black"
        />
        <path
          d="M325 22.0444H322.989V14.1434L321.313 14.7671L317.962 22.0444H315.616L318.632 15.5988L319.973 14.5592C317.627 14.1434 316.621 12.48 316.621 9.56911C316.621 5.4107 319.302 4.37109 321.984 4.37109C322.989 4.37109 324.33 4.37109 325.335 4.78694V22.0444H325ZM318.632 9.56911C318.632 12.2721 319.638 13.3117 321.649 13.3117H322.989V5.82654C322.654 5.61862 322.319 5.61862 321.649 5.61862C319.973 5.82654 318.632 6.65822 318.632 9.56911Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

import './PhoneNumberInput.scss';

import { Field } from 'redux-form';
import classnames from 'classnames';

import { APP_TYPES } from '../../../const/appConstants';
import { getPhoneCode } from '../../../redux/auth/authConstants';

const InnerInput = (props) => {
  const {
    input: { value },
    customClass,
    codeZero,
    containerClass,
    placeholder,
  } = props;

  return (
    <div className={classnames('phone-number-input__container', containerClass)}>
      <input className={classnames(`phone-number-input__code`, customClass)} value={getPhoneCode()} disabled={true} />
      {codeZero ? (
        <input className={classnames('phone-number-input__code-zero', customClass)} value="(0)" disabled={true} />
      ) : null}
      <input
        className={classnames('phone-number-input__number', customClass)}
        value={value}
        type="text"
        placeholder={placeholder}
        {...props.input}
      />
    </div>
  );
};

const checkInputLength = (e, newValue) => {
  const phoneLength = process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA ? 8 : 9;
  if (newValue.length > phoneLength || !/^[0-9]+$/.test(newValue)) {
    if (newValue) e.preventDefault();
  }
};

export const PhoneNumberInput = (props) => (
  <Field type="number" onChange={checkInputLength} component={InnerInput} {...props} />
);

import { useEffect, useState, useRef, useCallback } from 'react';

export const InfiniteScroll = (props) => {
  const { startPage = 0, loadMore, hasMore = true, loaderComponent, children, ...more } = props;
  const [page, setPage] = useState(startPage);
  const loader = useRef(null);

  const handleObserver = useCallback(
    (entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore) {
        setPage((page) => page + 1);
      }
    },
    [setPage, hasMore],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entities) => handleObserver(entities), options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, [handleObserver]);

  useEffect(() => loadMore(page), [page, loadMore]);

  return (
    <div className={more.className} style={more.style}>
      {children}
      <div className="loader" ref={loader}>
        {loaderComponent}
      </div>
    </div>
  );
};

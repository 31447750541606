import '../quizPage.scss';

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { SoloModeGameComponent } from './soloModeGameComponent';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { SoloModeFirstScreen } from './soloModeFirstScreen';
import { SOLO_MODE_STEPS } from '../../../redux/soloMode/soloModeContants';
import { soloModeActions } from '../../../redux/soloMode/soloModeSlice';
import { SoloModeResult } from './soloModeResult';
import { getBrandInfoSelector } from '../../../redux/brands/brandSelectors';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { APP_TYPES } from '../../../const/appConstants';

const SoloModePageComponent = (props) => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const brandId = Number(brand_id);
  const dispatch = useDispatch();

  const brand = useSelector((state) => getBrandInfoSelector(state, brandId));
  const theme = {
    color: brand ? brand.quiz_element_color : '#ffffff',
    triangleColor: brand ? brand.triangle_color : '#000',
  };

  useEffect(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.soloModeStepList,
        stepId: 1,
      }),
    );
    dispatch(soloModeActions.startSoloMode({ brandId }));
    return () => dispatch(soloModeActions.reset());
  }, [dispatch, brandId]);

  const onBackCallback = useCallback(() => {
    dispatch(soloModeActions.reset());
    history.goBack();
  }, [history, dispatch]);

  const SoloModeStepList = createStepListContainer(STEP_LIST_IDS.soloModeStepList, [
    {
      id: SOLO_MODE_STEPS.START,
      Component: () => (
        <SoloModeFirstScreen history={history} theme={theme} onBackCallback={onBackCallback} brand={brand} />
      ),
    },
    {
      id: SOLO_MODE_STEPS.GAME,
      Component: () => (
        <SoloModeGameComponent theme={theme} history={history} brandId={brandId} onLeaveCallback={onBackCallback} />
      ),
    },
    {
      id: SOLO_MODE_STEPS.RESULT,
      Component: () => <SoloModeResult theme={theme} history={history} brandId={brandId} />,
    },
  ]);

  return (
    <div
      className="quiz-page__with-background-image"
      style={{
        backgroundImage: brand && brand.background_image && `url(${brand.background_image})`,
        color: theme.color,
      }}
    >
      <SoloModeStepList />
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && <DisclaimerFooter withoutNavigation />}
    </div>
  );
};

export const SoloModePage = withRouter(SoloModePageComponent);

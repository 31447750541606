import React, { useCallback, useRef } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { Button } from '../../../components/common/Button';
import clip from '../../../images/clip.png';
import securedIcon from '../../../images/secured-icon.png';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { serviceActions } from '../../../redux/service/serviceSlice';
import { idCardActions } from '../../../redux/idCard/idCardSlice';
import { idCardSelectors } from '../../../redux/idCard/idCardSelectors';
import { WhiteSpinner } from '../../../components/common/Spinner/WhiteSpinner/WhiteSpinner';
import { s3FilePaths } from '../../../const/appConstants';

import styles from './AddPhoto.module.scss';

export const AddPhoto = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const filesInputRef = useRef<HTMLInputElement>(null);
  const isFetching = useSelector(idCardSelectors.selectIsFetching);
  const errorMessage = useSelector(idCardSelectors.selectErrorMessage);

  const onBackArrowClick = () => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
  };

  const uploadPhotoFromGallery = useCallback(() => {
    const uploadPhotoCallback = (fileKey: string) => {
      dispatch(idCardActions.setFileKey(fileKey));
      dispatch(idCardActions.sendIdCard());
    };
    const files = filesInputRef?.current?.files;
    const file = files && files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('file_path', s3FilePaths.idCard);
    dispatch(serviceActions.uploadPhoto({ formData, uploadPhotoCallback }));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor={'black'}
      />
      <div className={styles.content}>
        <div className={styles.textBlock}>
          <span className={styles.title}>{formatMessage('Add a photo of your ID card')}</span>
          <span className={styles.description}>
            {formatMessage(
              'Make sure that you take a picture of the front side ' +
                'of your ID card and your Personal Identification Number number is seeing ' +
                'fully and clearly in picture',
            )}
          </span>
        </div>

        <div className={styles.buttonWrapper}>
          <div className={styles.secured}>
            <img className={styles.securedIcon} src={securedIcon} alt="secured" />
            <span className={styles.text}>{formatMessage('All your data is secured')}</span>
          </div>
          <Button
            onClick={() => {
              filesInputRef.current?.click();
            }}
            disabled={isFetching}
            withoutTheme
            customClass={classnames(styles.button, { themedButton: true })}
          >
            {isFetching ? (
              <div className={styles.spinner}>
                <WhiteSpinner height={55} />
              </div>
            ) : (
              <>
                <img className={styles.clip} src={clip} alt="clip" />
                {formatMessage('Add a photo')}
              </>
            )}
            <input
              type="file"
              id={'challenge-page-file'}
              hidden={true}
              ref={filesInputRef}
              onChange={uploadPhotoFromGallery}
              accept="image/*"
            />
          </Button>
          {errorMessage && <span className={styles.errorMessage}>{formatMessage(errorMessage)}</span>}
        </div>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

import { useInternationalization } from './useTranslationHook';
import { getTimeLeft } from '../helpers/timesHelper';

export const useTimeLeft = (date: string) => {
  const { formatMessage } = useInternationalization();

  const time = getTimeLeft(date);

  let timeString = '';

  if (time.days) {
    timeString += time.days + formatMessage('d') + ' ';
  }
  if (time.hours || time.days) {
    timeString += time.hours + formatMessage('h') + ' ';
    timeString += time.minutes + formatMessage('m');
  } else {
    timeString += time.minutes + formatMessage('min');
  }

  return timeString;
};

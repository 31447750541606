import { all } from '@redux-saga/core/effects';
import { put, takeLatest } from 'redux-saga/effects';
import { moderationActions } from './moderationSlice';
import { moderationApi } from '../../api/moderationApi';

function* getUsersCountSaga() {
  yield put(moderationActions.setIsFetching(true));

  try {
    const {
      data: { moderation_users_count },
    } = yield moderationApi.getUsersCount();

    yield put(moderationActions.setUsersCount(moderation_users_count));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(moderationActions.setIsFetching(false));
  }
}

function* getUsersForModerationSaga() {
  yield put(moderationActions.setIsFetching(true));

  try {
    const { data } = yield moderationApi.getUsersForModeration();

    yield put(moderationActions.setUsersForModeration(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(moderationActions.setIsFetching(false));
  }
}

function* onDeleteUsersSaga({ payload }: { payload: number[] }) {
  yield put(moderationActions.setIsFetching(true));

  try {
    yield moderationApi.deleteUsers(payload);

    yield put(moderationActions.getUsersForModeration());
  } catch (e) {
    console.error(e);
  } finally {
    yield put(moderationActions.setIsFetching(false));
  }
}

function* onModerateUsersSaga({ payload }: { payload: number[] }) {
  yield put(moderationActions.setIsFetching(true));

  try {
    yield moderationApi.moderateUsers(payload);

    yield put(moderationActions.getUsersForModeration());
  } catch (e) {
    console.error(e);
  } finally {
    yield put(moderationActions.setIsFetching(false));
  }
}

export function* moderationSagas() {
  yield all([
    takeLatest(moderationActions.getUsersCount, getUsersCountSaga),
    takeLatest(moderationActions.getUsersForModeration, getUsersForModerationSaga),
    takeLatest(moderationActions.deleteUsers, onDeleteUsersSaga),
    takeLatest(moderationActions.moderateUsers, onModerateUsersSaga),
  ]);
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  previousStepScenario: {},
  currentScenario: {},
  scenarioChatMessages: [],
  isAnswerScreenActive: false,
  isAnswerButtonActive: false,
};

const scenarioSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    startScenario() {},
    setCurrentScenarioData(state, { payload }) {
      return {
        ...state,
        currentScenario: payload,
      };
    },
    setPreviousScenarioData(state, { payload }) {
      return {
        ...state,
        previousStepScenario: payload,
      };
    },
    addScenarioChatMessages(state, { payload }) {
      const currentMessages = state.scenarioChatMessages;
      const newMessages = [...currentMessages, payload];

      return {
        ...state,
        scenarioChatMessages: newMessages,
      };
    },
    setIsAnswerScreenActive(state, { payload }) {
      return {
        ...state,
        isAnswerScreenActive: payload,
      };
    },
    answerButtonClick() {},
    setSellerAnswer(state, { payload }) {},
    reactionModalViewed() {},
    setIsAnswerButtonActive(state, { payload }) {
      return {
        ...state,
        isAnswerButtonActive: payload,
      };
    },
  },
});

export const scenarioActions = scenarioSlice.actions;
export const scenarioReducer = scenarioSlice.reducer;

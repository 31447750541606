import { useInternationalization } from '../../../hooks/useTranslationHook';

export const AchievementsTutorialStep2 = () => {
  const { formatMessage } = useInternationalization();
  let startRightBlackout = window.innerWidth / 2 + 5;
  return (
    <>
      <div className="achievements-tutorial__blackout-firstItem-top" />
      <div className="achievements-tutorial__blackout-firstItem-left" />
      <div className="achievements-tutorial__blackout-firstItem-right" style={{ left: startRightBlackout }} />
      <div className="achievements-tutorial__blackout-firstItem-bottom" />
      <div className="achievements-tutorial__descriptionBox-top">
        <div className="achievements-tutorial__descriptionBox-step">2/4</div>
        <div className="achievements-tutorial__descriptionBox-header"> {formatMessage('Quizz mode')}</div>
        <div className="achievements-tutorial__descriptionBox-description">
          {formatMessage('Choose module Quiz Master to see the list of quiz-related tasks that are available for you')}
        </div>
      </div>
    </>
  );
};

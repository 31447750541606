const IPHONE_LEGACY_SCREEN_DIMENSIONS = {
  WIDTH: 750,
  HEIGHT: 1334,
};

export const isIPhoneWithHomeBar = () => {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const DPR = window.devicePixelRatio || 1;

  const screen = {
    width: window.screen.width * DPR,
    height: window.screen.height * DPR,
  };
  if (
    iOS &&
    screen.width > IPHONE_LEGACY_SCREEN_DIMENSIONS.WIDTH &&
    screen.height > IPHONE_LEGACY_SCREEN_DIMENSIONS.HEIGHT
  ) {
    return true;
  }
};

export const checkIsIphoneX = isIPhoneWithHomeBar;

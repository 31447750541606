import amplitude from 'amplitude-js';
import { amplitudeApiKey } from '../const/appConstants';
import jwtDecode from 'jwt-decode';
import { isIreland } from './appTypeHelper';
import { KNOWLEDGE_BANK_CATEGORIES_TYPES } from '../redux/knowledgeBank/knowledgeBankConstants';

function parseQuizFinishData(answers, data) {
  return answers.reduce(
    (acc, currentValue, index) => {
      acc[`answer-${index + 1}`] = currentValue.correct;
      return acc;
    },
    { ...data },
  );
}

export const amplitudeHelper = {
  initAmplitude() {
    amplitude.getInstance().init(amplitudeApiKey);
    const token = localStorage.getItem('authToken');
    if (token) {
      const { userId } = jwtDecode(token);
      this.initializeUser(userId);
    }
  },
  initializeUser: (userId) => {
    amplitude.getInstance().setUserId(userId);
  },
  openMainScreen() {
    isIreland() && amplitude.getInstance().logEvent('open_main_screen');
  },
  openMainBonusShop() {
    isIreland() && amplitude.getInstance().logEvent('open_main_bonus_shop');
  },
  openProgressBar() {
    isIreland() && amplitude.getInstance().logEvent('open_progress_bar');
  },
  openProfile() {
    isIreland() && amplitude.getInstance().logEvent('open_profile');
  },
  openProfileSettings() {
    isIreland() && amplitude.getInstance().logEvent('open_profile_settings');
  },
  mainBox() {
    isIreland() && amplitude.getInstance().logEvent('main_box');
  },
  mainFocus(type) {
    isIreland() && amplitude.getInstance().logEvent('main_focus', { type: type });
  },
  mainItemKBGame() {
    isIreland() && amplitude.getInstance().logEvent('main_item_kb_game');
  },
  mainItemArticle() {
    isIreland() && amplitude.getInstance().logEvent('main_item_article');
  },
  mainKBGame() {
    isIreland() && amplitude.getInstance().logEvent('main_kb_game');
  },
  mainArticle() {
    isIreland() && amplitude.getInstance().logEvent('main_article');
  },
  mainDailyGame() {
    isIreland() && amplitude.getInstance().logEvent('main_daily_game');
  },
  mainSolo() {
    isIreland() && amplitude.getInstance().logEvent('main_solo');
  },
  mainH2h() {
    isIreland() && amplitude.getInstance().logEvent('main_h2h');
  },
  mainSpecialQuizzes() {
    isIreland() && amplitude.getInstance().logEvent('main_special_quizzes');
  },
  openMainKB() {
    isIreland() && amplitude.getInstance().logEvent('open_main_kb');
  },
  openMainChat() {
    isIreland() && amplitude.getInstance().logEvent('open_main_chat');
  },
  openMainProfile() {
    isIreland() && amplitude.getInstance().logEvent('open_main_profile');
  },
  openBalance() {
    isIreland() && amplitude.getInstance().logEvent('open_balance');
  },
  openPrizes() {
    isIreland() && amplitude.getInstance().logEvent('open_prizes');
  },
  articleChoose(article) {
    isIreland() &&
      amplitude.getInstance().logEvent('article_choose', {
        article_id: article.id,
        article_name: article.name,
        brand_id: article.brand_id,
      });
  },
  articleFinish() {
    isIreland() && amplitude.getInstance().logEvent('article_finish');
  },
  articleExit() {
    isIreland() && amplitude.getInstance().logEvent('article_exit');
  },
  soloQuizChooseBrand(brand) {
    isIreland() &&
      amplitude.getInstance().logEvent('solo_quiz_choose_brand', {
        description: brand.description,
        brand_id: brand.id,
      });
  },
  soloQuizFinish(answers, pack_id, coins) {
    const eventValues = parseQuizFinishData(answers, { pack_id, coins });

    isIreland() && amplitude.getInstance().logEvent('solo_quiz_finish', eventValues);
  },
  soloQuizExit() {
    isIreland() && amplitude.getInstance().logEvent('solo_quiz_exit');
  },
  soloQuizBackToMain() {
    isIreland() && amplitude.getInstance().logEvent('solo_quiz_back_to_main');
  },
  soloQuizNewGame() {
    isIreland() && amplitude.getInstance().logEvent('solo_quiz_new_game');
  },
  h2hQuizChooseBrand(brand) {
    isIreland() &&
      amplitude.getInstance().logEvent('h2h_quiz_choose_brand', {
        description: brand.description,
        brand_id: brand.id,
      });
  },
  h2hQuizFinish(answers, pack_id, coins) {
    const eventValues = parseQuizFinishData(answers, { pack_id, coins });

    isIreland() && amplitude.getInstance().logEvent('h2h_quiz_finish', eventValues);
  },
  h2hQuizExit() {
    isIreland() && amplitude.getInstance().logEvent('h2h_quiz_exit');
  },
  h2hQuizBackToMain() {
    isIreland() && amplitude.getInstance().logEvent('h2h_quiz_back_to_main');
  },
  h2hQuizNewGame() {
    isIreland() && amplitude.getInstance().logEvent('h2h_quiz_new_game');
  },
  openSupport() {
    isIreland() && amplitude.getInstance().logEvent('open_support');
  },
  supportChooseProblem(problem) {
    isIreland() &&
      amplitude.getInstance().logEvent('support_choose_problem', {
        problem_id: problem.id,
        problem_name: problem.name,
      });
  },
  supportOther() {
    isIreland() && amplitude.getInstance().logEvent('support_other');
  },
  chatContinue() {
    isIreland() && amplitude.getInstance().logEvent('chat_continue');
  },
  bonusPerx() {
    isIreland() && amplitude.getInstance().logEvent('bonus_perx');
  },
  bonusRetail() {
    isIreland() && amplitude.getInstance().logEvent('bonus_retail');
  },
  bonusFood() {
    isIreland() && amplitude.getInstance().logEvent('bonus_food');
  },
  bonusBeauty() {
    isIreland() && amplitude.getInstance().logEvent('bonus_beauty');
  },
  bonusHotel() {
    isIreland() && amplitude.getInstance().logEvent('bonus_hotel');
  },
  bonusEntertaiment() {
    isIreland() && amplitude.getInstance().logEvent('bonus_entertaiment');
  },
  openPromoCode() {
    isIreland() && amplitude.getInstance().logEvent('open_promo_code');
  },
  promoCodeActivate(code) {
    isIreland() && amplitude.getInstance().logEvent('promo_code_activate', { code });
  },
  openKBGeninfo() {
    isIreland() && amplitude.getInstance().logEvent('open_kb_geninfo');
  },
  openKbTipsTricks() {
    isIreland() && amplitude.getInstance().logEvent('open_kb_tips&tricks');
  },
  openKBArticle() {
    isIreland() && amplitude.getInstance().logEvent('open_kb_article');
  },
  openKBSpecialQuiz() {
    isIreland() && amplitude.getInstance().logEvent('open_kb_special_quiz');
  },
  openKBGame() {
    isIreland() && amplitude.getInstance().logEvent('open_kb_game');
  },
  geninfoChoose(selectedKBBrand) {
    isIreland() && amplitude.getInstance().logEvent('geninfo_choose', { brand_id: selectedKBBrand });
  },
  tipsTricksChoose(article) {
    isIreland() &&
      amplitude.getInstance().logEvent('tips&tricks_choose', {
        article_id: article.id,
        article_name: article.name,
      });
  },
  h2hInvite(opponentId, packId) {
    isIreland() && amplitude.getInstance().logEvent('h2h_invite', { opponent_id: opponentId, pack_id: packId });
  },
  h2hNewRequest() {
    isIreland() && amplitude.getInstance().logEvent('h2h_new_request');
  },
  specialQuizChoose(article) {
    isIreland() &&
      amplitude.getInstance().logEvent('special_quiz_choose', {
        special_quiz_id: article.special_quiz_id,
        special_quiz_name: article.name,
        brand_id: article.brand_id,
      });
  },
  specialQuizFinish(answers, pack_id, coins, article_id) {
    const eventValues = parseQuizFinishData(answers, { pack_id, coins, article_id });

    isIreland() && amplitude.getInstance().logEvent('special_quiz_finish', eventValues);
  },
  specialQuizExit() {
    isIreland() && amplitude.getInstance().logEvent('special_quiz_exit');
  },
  kbGameChoose(article) {
    isIreland() &&
      amplitude.getInstance().logEvent('kb_game_choose', {
        game_name: article.name,
        brand_id: article.brand_id,
      });
  },
  profileQuizzes() {
    isIreland() && amplitude.getInstance().logEvent('profile_quizzes');
  },
  profileChallenge() {
    isIreland() && amplitude.getInstance().logEvent('profile_challenge');
  },
  profilePromocode() {
    isIreland() && amplitude.getInstance().logEvent('profile_promocode');
  },
  profileQod() {
    isIreland() && amplitude.getInstance().logEvent('profile_qod');
  },
};

export const amplitudeBonusShopResolver = {
  1: amplitudeHelper.bonusFood,
  3: amplitudeHelper.bonusBeauty,
  6: amplitudeHelper.bonusEntertaiment,
  7: amplitudeHelper.bonusRetail,
  8: amplitudeHelper.bonusHotel,
};

export const amplitudeKBCategoryResolver = {
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.NEWS]: amplitudeHelper.openKBArticle,
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.SPECIAL_QUIZ]: amplitudeHelper.openKBSpecialQuiz,
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.SALES_TIPS]: amplitudeHelper.openKBGeninfo,
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.TIPS_AND_TRICKS]: amplitudeHelper.openKbTipsTricks,
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.MINI_GAMES]: amplitudeHelper.openKBGame,
};

export const amplitudeKBArticleResolver = {
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.NEWS]: amplitudeHelper.articleChoose,
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.TIPS_AND_TRICKS]: amplitudeHelper.tipsTricksChoose,
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.SPECIAL_QUIZ]: amplitudeHelper.specialQuizChoose,
  [KNOWLEDGE_BANK_CATEGORIES_TYPES.MINI_GAMES]: amplitudeHelper.kbGameChoose,
};

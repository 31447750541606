export const InviteCodeIcon = (props) => {
  return (
    <svg {...props} width="300" height="300" viewBox="0 0 300 300" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="300" height="300" fill="white" />
      <circle cx="149.5" cy="150.5" r="81" fill="url(#paint0_linear)" stroke="#01C1B6" strokeWidth="3" />
      <path
        d="M110.5 130.5C110.5 150.91 127.936 167.5 149.5 167.5C171.064 167.5 188.5 150.91 188.5 130.5C188.5 110.09 171.064 93.5 149.5 93.5C127.936 93.5 110.5 110.09 110.5 130.5Z"
        stroke="#01C1B6"
      />
      <path d="M208 199C195.111 180.279 173.493 168 149 168C124.507 168 102.889 180.279 90 199" stroke="#01C1B6" />
      <path
        d="M234.692 49.6917H218.994C216.672 49.6917 215 50.8992 215 53.5C215 56.1008 216.672 57.3083 218.994 57.3083H234.692V73.0059C234.692 75.3281 235.899 77 238.5 77C241.101 77 242.308 75.3281 242.308 73.0059V57.3083H258.006C260.328 57.3083 262 56.1008 262 53.5C262 50.8992 260.328 49.6917 258.006 49.6917H242.308V33.9941C242.308 31.6719 241.101 30 238.5 30C235.899 30 234.692 31.6719 234.692 33.9941V49.6917Z"
        fill="#01C1B6"
      />
      <path
        d="M31.6917 187.692H15.9941C13.6719 187.692 12 188.899 12 191.5C12 194.101 13.6719 195.308 15.9941 195.308H31.6917V211.006C31.6917 213.328 32.8992 215 35.5 215C38.1008 215 39.3083 213.328 39.3083 211.006V195.308H55.0059C57.3281 195.308 59 194.101 59 191.5C59 188.899 57.3281 187.692 55.0059 187.692H39.3083V171.994C39.3083 169.672 38.1008 168 35.5 168C32.8992 168 31.6917 169.672 31.6917 171.994V187.692Z"
        fill="#01C1B6"
      />
      <path
        d="M64.8933 61.8933H56.2095C54.9249 61.8933 54 62.5613 54 64C54 65.4387 54.9249 66.1067 56.2095 66.1067H64.8933V74.7905C64.8933 76.0751 65.5613 77 67 77C68.4387 77 69.1067 76.0751 69.1067 74.7905V66.1067H77.7905C79.0751 66.1067 80 65.4387 80 64C80 62.5613 79.0751 61.8933 77.7905 61.8933H69.1067V53.2095C69.1067 51.9249 68.4387 51 67 51C65.5613 51 64.8933 51.9249 64.8933 53.2095V61.8933Z"
        fill="#01C1B6"
      />
      <path
        d="M263.893 222.893H255.209C253.925 222.893 253 223.561 253 225C253 226.439 253.925 227.107 255.209 227.107H263.893V235.791C263.893 237.075 264.561 238 266 238C267.439 238 268.107 237.075 268.107 235.791V227.107H276.791C278.075 227.107 279 226.439 279 225C279 223.561 278.075 222.893 276.791 222.893H268.107V214.209C268.107 212.925 267.439 212 266 212C264.561 212 263.893 212.925 263.893 214.209V222.893Z"
        fill="#01C1B6"
      />
      <defs>
        <linearGradient id="paint0_linear" x1="149.5" y1="68" x2="149.5" y2="233" gradientUnits="userSpaceOnUse">
          <stop offset="0.494792" stop-color="white" />
          <stop offset="1" stop-color="#E6FFFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { Button } from '../../components/common/Button';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { LottieWrapper } from '../../components/lootieWrapper/lootieWrapper';
import animatedStar from '../../images/animations/animatedStar.json';
import { History, LocationState } from 'history';

import styles from './SuccessPage.module.scss';

type Props = {
  history: History<RouterState>;
};

interface RouterState<S = LocationState> {
  title: string;
  description: string;
  btnText: string;
  image?: string;
  animated?: boolean;
}

export const SuccessPage = ({ history }: Props) => {
  const { formatMessage } = useInternationalization();
  const { location }: History<RouterState> = history;

  const handleButtonClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          {location.state.animated ? (
            <LottieWrapper
              options={{ ...options, animationData: animatedStar }}
              style={{
                height: '390px',
              }}
            />
          ) : (
            <img className={styles.image} src={location.state.image} alt="picture" />
          )}
        </div>
        <div className={styles.labels}>
          <h1 className={styles.title}>{formatMessage(location.state.title)}</h1>
          <h1 className={styles.description}>{formatMessage(location.state.description)}</h1>
        </div>
        <Button customClass={styles.button} onClick={handleButtonClick}>
          {formatMessage(location.state.btnText)}
        </Button>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

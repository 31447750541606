import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { getOpponentSelector } from '../../../redux/game/gameSelectors';
import { getUserInfoSelector } from '../../../redux/profile/profileSelectors';
import { getUnverificationInfoForBelarus } from '../../../redux/verification/verificationSelectors';
import { ConnexLogo } from '../../../icons/connex-logo';
import { ConnectLogo } from '../../../icons/connect-logo';
import { Be2getherSimpleIcon } from '../../../icons/Be2getherSimpleIcon';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { APP_TYPES } from '../../../const/appConstants';
import UserInfo from '../UserInfo/UserInfo';

import styles from './InviteAcceptedStep.module.scss';

const VersusLogo = (props) => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.HUNGARY:
      return <ConnectLogo {...props} />;
    case APP_TYPES.BELARUS:
      return <Be2getherSimpleIcon {...props} />;
    default:
      return <ConnexLogo {...props} />;
  }
};

export const InviteAccepted = ({ theme: { color }, brand }) => {
  const { formatMessage } = useInternationalization();

  const { opponent } = useSelector(getOpponentSelector);
  const userInfo = useSelector(getUserInfoSelector);
  const isUnverified = useSelector(getUnverificationInfoForBelarus);

  return (
    <div className={styles.inviteAcceptedContainer}>
      {!isUnverified && <img className={styles.logo} src={brand.logo} alt="logo" />}
      <div className={styles.title}>{formatMessage('Good Luck')}!</div>
      <div className={styles.opponentsContainer}>
        <UserInfo userAvatar={userInfo.photo} theme={color} username={userInfo.username} />
        <VersusLogo fill={color} />
        <UserInfo userAvatar={opponent.photo} theme={color} username={opponent.username} />
      </div>
    </div>
  );
};

InviteAccepted.propTypes = {
  theme: PropTypes.object.isRequired,
};
InviteAccepted.defaultProps = {};

export const EVENT_MODE_STEPS = {
  GAME: 1,
  RESULT: 2,
  GIFTS: 3,
  FINAL: 4,
};

export const EVENT_MODE_ACTION_TYPES = {
  START_EVENT_MODE: 'eventMode/startEventMode',
  FINISH_EVENT_MODE: 'eventMode/finishEventMode',
};

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import useTimer from '../../../hooks/useTimerHook';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';

import { Routes } from '../../../const';

import './perxTransferMoney.scss';

import { Balance } from '../../../components/common/Balance';
import { Header } from '../../../components/common/Header';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { Button } from '../../../components/common/Button';
import { LoadingPage } from '../../../components/common/Spinner/LoadingPage';
import { PerxCashoutHistory } from '../cashoutHistory/perxCashoutHistory/perxCashoutHistory';
import { ExclamationPointIcon } from '../../../icons/exclamation-point-icon';
import backgroundImage from './perx-background-image.jpg';

import {
  launchTimerOppaButton as launchUmicoTimerButton,
  getPERXHeaderImageSelector,
  getPerxSegmentationCoefficient,
} from '../../../redux/bonusShop/bonusShopSelectors';
import {
  getIsFetching as getIsFetchingProfileInfo,
  getUserBalanceSelector,
} from '../../../redux/profile/profileSelectors';
import { FAQ_ARTICLE_ID } from '../../../redux/knowledgeBank/knowledgeBankConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { perxSteps } from './perxTrensferMoneyPage';

export const perxTimerValue = 300000;

export const PERXMainPage = () => {
  const { formatMessage } = useInternationalization();
  const coins = useSelector(getUserBalanceSelector);
  const headerImage = useSelector(getPERXHeaderImageSelector) || backgroundImage;
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetchingProfileInfo);
  const history = useHistory();

  const firstLaunch = useRef(true);
  const launchTimer = useSelector(launchUmicoTimerButton);
  const coefficient = useSelector(getPerxSegmentationCoefficient);
  const dateEnd = localStorage.getItem('endTimeDisablePerxButton');
  const [timerLeft, setTimerLeft] = useState(dateEnd - new Date().getTime());
  const { minutes, seconds } = useTimer(timerLeft, setTimerLeft, launchTimer);

  const minimumTransferValue = 20;

  const handlePreviousStep = () => {
    history.push(Routes.BonusShopRoute);
  };

  useEffect(() => {
    if (firstLaunch.current) {
      firstLaunch.current = false;
    } else {
      setTimerLeft(perxTimerValue);
    }
  }, [launchTimer]);

  const openHelpArticle = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jticonnex.com/Perx_info_.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  const openTransferWindow = () => {
    if (coins < minimumTransferValue * coefficient) {
      dispatch(
        modalActions.openPromptModal({
          title: 'Ooops',
          description: "You have not enough points. You can't redeem less than {replacement} points",
          descriptionReplacement: `${minimumTransferValue * coefficient}`,
          btnText: 'OK',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else {
      dispatch(stepListActions.setStep({ stepListId: STEP_LIST_IDS.perxStepList, stepId: perxSteps.enterUserInfo }));
    }
  };

  return (
    <div className="relative-container">
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div className="perx-money-for-the-card-page">
          <Header goBackCallback={handlePreviousStep} hasBackButton customClass="perx-money-for-the-card-page__header">
            {formatMessage('PERX')}
          </Header>
          <Balance />
          <div className="perx-money-for-the-card-page__content">
            <div
              className="perx-money-for-the-card-page__content-image-container"
              style={{
                background: `url(${headerImage})`,
                backgroundSize: 'cover',
              }}
            >
              <div className="perx-money-for-the-card-page__content-image-container-wallet-container">
                <p className="perx-money-for-the-card-page__content-image-container-wallet-container-caption">
                  {formatMessage('Here is you transaction history')}
                </p>
                <p className="perx-money-for-the-card-page__content-image-container-wallet-container-label">
                  {formatMessage('Tap the button below to redeem points')}
                </p>
              </div>
            </div>
            <ExclamationPointIcon className="perx-money-for-the-card-page__content-faq" onClick={openHelpArticle} />
            <PerxCashoutHistory />
          </div>
          <div
            style={{ bottom: isIPhoneWithHomeBar() ? '49px' : '15px' }}
            className="perx-money-for-the-card-page__content-btn-container"
          >
            {minutes || seconds ? (
              <Button
                disabled
                customClass={'perx-money-for-the-card-page__content-btn-container-button'}
                onClick={openTransferWindow}
              >
                {formatMessage('Next transaction in ')}
                {minutes ? ` ${minutes}m` : ''}
                {seconds ? ` ${seconds}s` : ''}
              </Button>
            ) : (
              <Button
                customClass={'perx-money-for-the-card-page__content-btn-container-button'}
                onClick={openTransferWindow}
              >
                {formatMessage('Transfer')}
              </Button>
            )}
          </div>
          <DisclaimerFooter withoutNavigation />
        </div>
      )}
    </div>
  );
};

import React from 'react';
import { Header } from '../../../../components/common/Header';
import { Signature } from '../../../../components/common/Signature';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { isBelarus } from '../../../../helpers';
import { BackArrowButton } from '../../../../components/common/BackArrowButton/BackArrowButton';
import progressBarImageLastStep from '../../../../images/Frame4.png';
import './signingPage.scss';

export const SigningPage = ({ onDoneClick, goBackCallback }) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className="signing-page">
      {isBelarus() && <img src={progressBarImageLastStep} className="signing-page__progressbar" alt="progress bar" />}
      {isBelarus() ? (
        <BackArrowButton
          withoutTheme
          onHistoryReplaceCallback={goBackCallback}
          customClass="signing-page__back-button"
          buttonColor={'black'}
        />
      ) : (
        <Header hasBackButton customClass="signing-page__header" goBackCallback={goBackCallback}>
          <p className="signing-page__header-text">{formatMessage('Signing')}</p>
        </Header>
      )}
      <Signature customClass={'signing-page__content'} onDoneClick={onDoneClick} />
    </div>
  );
};

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useInternationalization } from '../../../hooks/useTranslationHook';

import { Button } from '../../../components/common/Button';
import clocksAnimation from '../clocks-animation.json';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { getUnverificationInfoForBelarus } from '../../../redux/verification/verificationSelectors';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';
import { APP_TYPES } from '../../../const/appConstants';
import SearchAnimation from '../../../components/SearchAnimation/SearchAnimation';

import styles from './LoadingOpponent.module.scss';

export const LoadingOpponent = ({ onBack, history, brand, shuffledImagesArray }) => {
  const { formatMessage } = useInternationalization();
  const isUnVerifiedBelarus = useSelector(getUnverificationInfoForBelarus);

  const isHungary = process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;

  const onBackCallback = useCallback(() => {
    onBack();
    history.goBack();
  }, [onBack, history]);

  return (
    <div className={!isHungary ? 'quiz-page__loading' : styles.loading}>
      <Button className="quiz-page__back-button" onClick={onBackCallback} withoutTheme>
        <ArrowIconRounded className="quiz-page__back-icon" style={{ fill: 'white' }} />
      </Button>

      <div className="quiz-page__loading-main">
        <div>
          {!isUnVerifiedBelarus && !isHungary ? (
            <div className="quiz-page__brand-logo" style={{ backgroundImage: `url(${brand.logo})` }} />
          ) : null}
        </div>
        {!isHungary ? (
          <>
            <div className="quiz-page__clocks-animation-container">
              <LottieWrapper
                isStopped={false}
                isPaused={false}
                options={clocksAnimationOptions}
                height={200}
                width={200}
              />
            </div>
            <div className="quiz-page__quiz-description" style={{ marginBottom: checkIsIphoneX() ? '25px' : '0' }}>
              {brand.quiz_tagline}
            </div>
          </>
        ) : (
          <>
            <div className={styles.animationContainer}>
              <SearchAnimation shuffledImagesArray={shuffledImagesArray} />
            </div>
            <div className={styles.description} style={{ marginBottom: checkIsIphoneX() ? '25px' : '0' }}>
              {formatMessage('Looking for an opponent')}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const clocksAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: clocksAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

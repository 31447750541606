import { APP_TYPES } from '../const/appConstants';

export const setAppSettings = () => {
  setManifest();
  setIosIcon();
  setFavicon();
  setSplashScreens();
};

export const setManifest = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      document.querySelector('#manifest').setAttribute('href', './manifest_ireland.json');
      break;
    case APP_TYPES.HUNGARY:
      document.querySelector('#manifest').setAttribute('href', './manifest_hungary.json');
      break;
    case APP_TYPES.GEORGIA:
      document.querySelector('#manifest').setAttribute('href', './club_ge_manifest.json');
      break;
    case APP_TYPES.ARMENIA:
      document.querySelector('#manifest').setAttribute('href', './club_am_manifest.json');
      break;
    case APP_TYPES.AZERBAIJAN:
      document.querySelector('#manifest').setAttribute('href', './club_az_manifest.json');
      break;
    case APP_TYPES.BELARUS:
      document.querySelector('#manifest').setAttribute('href', './manifest_belarus.json');
      break;
    case APP_TYPES.DEMO:
      document.querySelector('#manifest').setAttribute('href', './manifest_demo.json');
      break;
    case APP_TYPES.TAIWAN:
      document.querySelector('#manifest').setAttribute('href', './manifest_taiwan.json');
      break;
    case APP_TYPES.TABATERRA_AZ:
      document.querySelector('#manifest').setAttribute('href', './manifest_tabaterra_az.json');
      break;
    case APP_TYPES.MYBRAND_AZ:
      document.querySelector('#manifest').setAttribute('href', './manifest_mybrand_az.json');
      break;
    default:
      document.querySelector('#manifest').setAttribute('href', './manifest_ireland.json');
      break;
  }
};

export const setIosIcon = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      document.querySelector('#ios-icon').setAttribute('href', './connex_ios_icon.png');
      break;
    case APP_TYPES.HUNGARY:
      document.querySelector('#ios-icon').setAttribute('href', './connect_ios_icon.png');
      break;
    case APP_TYPES.GEORGIA:
      document.querySelector('#ios-icon').setAttribute('href', './club_ge_ios_icon.png');
      break;
    case APP_TYPES.ARMENIA:
      document.querySelector('#ios-icon').setAttribute('href', './club_am_ios_icon.png');
      break;
    case APP_TYPES.AZERBAIJAN:
      document.querySelector('#ios-icon').setAttribute('href', './club_az_ios_icon.png');
      break;
    case APP_TYPES.BELARUS:
      document.querySelector('#ios-icon').setAttribute('href', './be2gether_ios_icon.png');
      break;
    case APP_TYPES.TAIWAN:
      document.querySelector('#ios-icon').setAttribute('href', './tw_ios_icon.png');
      break;
    case APP_TYPES.TABATERRA_AZ:
      document.querySelector('#ios-icon').setAttribute('href', './tabaterra_az/icon.png');
      break;
    case APP_TYPES.MYBRAND_AZ:
      document.querySelector('#ios-icon').setAttribute('href', './mybrand_az/icon.png');
      break;
    default:
      document.querySelector('#ios-icon').setAttribute('href', './connex_ios_icon.png');
      break;
  }
};

export const setFavicon = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      document.querySelector('#shortcut-icon').setAttribute('href', './connex_favicon.png');
      break;
    case APP_TYPES.HUNGARY:
      document.querySelector('#shortcut-icon').setAttribute('href', './connect_favicon.png');
      break;
    case APP_TYPES.GEORGIA:
      document.querySelector('#shortcut-icon').setAttribute('href', './club_ge_favicon.png');
      break;
    case APP_TYPES.ARMENIA:
      document.querySelector('#shortcut-icon').setAttribute('href', './club_am_favicon.png');
      break;
    case APP_TYPES.AZERBAIJAN:
      document.querySelector('#shortcut-icon').setAttribute('href', './club_az_favicon.png');
      break;
    case APP_TYPES.BELARUS:
      document.querySelector('#shortcut-icon').setAttribute('href', './be2gether_favicon.png');
      break;
    case APP_TYPES.TAIWAN:
      document.querySelector('#shortcut-icon').setAttribute('href', './tw_favicon.png');
      break;
    case APP_TYPES.TABATERRA_AZ:
      document.querySelector('#shortcut-icon').setAttribute('href', './tabaterra_az/icon.png');
      break;
    case APP_TYPES.MYBRAND_AZ:
      document.querySelector('#shortcut-icon').setAttribute('href', './mybrand_az/icon.png');
      break;
    case APP_TYPES.DEMO:
      document.querySelector('#shortcut-icon').setAttribute('href', './demo_1024.png');
      break;
    default:
      document.querySelector('#shortcut-icon').setAttribute('href', './connex_favicon.png');
      break;
  }
};

export const setSplashScreens = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      document.querySelector('#ios-splash-1125').setAttribute('href', './connex_splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './connex_splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './connex_splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './connex_splash_ios_640.png');
      break;
    case APP_TYPES.HUNGARY:
      document.querySelector('#ios-splash-1125').setAttribute('href', './connect_splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './connect_splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './connect_splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './connect_splash_ios_640.png');
      break;
    case APP_TYPES.GEORGIA:
      document.querySelector('#ios-splash-1125').setAttribute('href', './club_splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './club_splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './club_splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './club_splash_ios_640.png');
      break;
    case APP_TYPES.ARMENIA:
      document.querySelector('#ios-splash-1125').setAttribute('href', './club_splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './club_splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './club_splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './club_splash_ios_640.png');
      break;
    case APP_TYPES.AZERBAIJAN:
      document.querySelector('#ios-splash-1125').setAttribute('href', './club_splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './club_splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './club_splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './club_splash_ios_640.png');
      break;
    case APP_TYPES.BELARUS:
      document.querySelector('#ios-splash-1125').setAttribute('href', './splash-creen_1242x2208.jpg');
      document.querySelector('#ios-splash-1242').setAttribute('href', './splash-creen_1125x2436.jpg');
      document.querySelector('#ios-splash-750').setAttribute('href', './splash-creen_750x1334.jpg');
      document.querySelector('#ios-splash-640').setAttribute('href', './splash-creen_640x1136.jpg');
      break;
    case APP_TYPES.DEMO:
      document.querySelector('#ios-splash-1125').setAttribute('href', './club_splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './club_splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './club_splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './club_splash_ios_640.png');
      break;
    case APP_TYPES.TAIWAN:
      document.querySelector('#ios-splash-1125').setAttribute('href', './taiwan/splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './taiwan/splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './taiwan/splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './taiwan/splash_ios_640.png');
      break;
    case APP_TYPES.TABATERRA_AZ:
      document.querySelector('#ios-splash-1125').setAttribute('href', './tabaterra_az/splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './tabaterra_az/splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './tabaterra_az/splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './tabaterra_az/splash_ios_640.png');
      break;
    case APP_TYPES.MYBRAND_AZ:
      document.querySelector('#ios-splash-1125').setAttribute('href', './mybrand_az/splash_ios_1125.png');
      document.querySelector('#ios-splash-1242').setAttribute('href', './mybrand_az/splash_ios_1242.png');
      document.querySelector('#ios-splash-750').setAttribute('href', './mybrand_az/splash_ios_750.png');
      document.querySelector('#ios-splash-640').setAttribute('href', './mybrand_az/splash_ios_640.png');
      break;
    default:
      document.querySelector('#ios-splash-1125').setAttribute('href', './splash-creen_1125x2436_demo.jpg');
      document.querySelector('#ios-splash-1242').setAttribute('href', './splash-creen_1125x2436_demo.jpg');
      document.querySelector('#ios-splash-750').setAttribute('href', './splash-creen_750x1334_demo.jpg');
      document.querySelector('#ios-splash-640').setAttribute('href', './splash-creen_640x1136_demo.jpg');
      break;
  }
};

export const getFirebaseConfig = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.HUNGARY:
      return {
        apiKey: 'AIzaSyCkUvy-8NTNJLK8z9eH6SYRoLn-ZYcVTZg',
        authDomain: 'jti-connect-d9023.firebaseapp.com',
        databaseURL: 'https://jti-connect-d9023.firebaseio.com',
        projectId: 'jti-connect-d9023',
        storageBucket: 'jti-connect-d9023.appspot.com',
        messagingSenderId: '569872026074',
        appId: '1:569872026074:web:db6a04966bc6200df098e3',
      };
    case APP_TYPES.GEORGIA:
      return {
        apiKey: 'AIzaSyCiTm84zsgvef2bhSGgBz0Tk-Sm6eWg3xU',
        authDomain: 'jti-club-georgia.firebaseapp.com',
        databaseURL: 'https://jti-club-georgia.firebaseio.com',
        projectId: 'jti-club-georgia',
        storageBucket: 'jti-club-georgia.appspot.com',
        messagingSenderId: '796683640429',
        appId: '1:796683640429:web:11ad946548a77d10622964',
        measurementId: 'G-4DGQSBF9GZ',
      };
    case APP_TYPES.ARMENIA:
      return {
        apiKey: 'AIzaSyDKr_wxjIOZ44m7VDLr4Je9NKUlaLEvKPw',
        authDomain: 'jti-club-armenia.firebaseapp.com',
        databaseURL: 'https://jti-club-armenia.firebaseio.com',
        projectId: 'jti-club-armenia',
        storageBucket: 'jti-club-armenia.appspot.com',
        messagingSenderId: '681684545956',
        appId: '1:681684545956:web:24a1d0dda496d2e8381131',
        measurementId: 'G-4BR1R6YNTG',
      };
    case APP_TYPES.AZERBAIJAN:
      return {
        apiKey: 'AIzaSyArbwJMBFrenlxi86bznu83S8KJ1xk-LR4',
        authDomain: 'jti-club-azerbaijan.firebaseapp.com',
        databaseURL: 'https://jti-club-azerbaijan.firebaseio.com',
        projectId: 'jti-club-azerbaijan',
        storageBucket: 'jti-club-azerbaijan.appspot.com',
        messagingSenderId: '625274567538',
        appId: '1:625274567538:web:acbd4fcca411c362227beb',
        measurementId: 'G-D4GS5NNHEX',
      };
    case APP_TYPES.BELARUS:
      return {
        apiKey: 'AIzaSyA-fgCgwXzdV3cPQPupumFYLeJc9VbIX_k',
        authDomain: 'jti-vmeste.firebaseapp.com',
        databaseURL: 'https://jti-vmeste.firebaseio.com',
        projectId: 'jti-vmeste',
        storageBucket: 'jti-vmeste.appspot.com',
        messagingSenderId: '629995248952',
        appId: '1:629995248952:web:20c475ae2ef0108b2f75cd',
        measurementId: 'G-R8MR5K1K5K',
      };
    case APP_TYPES.IRELAND:
    case APP_TYPES.DEMO:
    default:
      return {
        apiKey: 'AIzaSyD-DBnOFNW4F3z1Qvol6zWq-m-Az7ASe2E',
        authDomain: 'jti-connex.firebaseapp.com',
        databaseURL: 'https://jti-connex.firebaseio.com',
        projectId: 'jti-connex',
        storageBucket: 'jti-connex.appspot.com',
        messagingSenderId: '119985675991',
        appId: '1:119985675991:web:5e9890c13ede17dc84da92',
      };
  }
};

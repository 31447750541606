import { all, takeLatest, put } from 'redux-saga/effects';
import { BRANDS_ACTION_TYPES } from './brandsConstants';
import { brandsActions } from './brandsSlice';
import { brandsApi } from '../../api/brandsApi';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../const/brandsConstants';
import { APP_TYPES } from '../../const/appConstants';

function* getBrandsGamesSaga() {
  try {
    yield put(brandsActions.setIsFetching({ isFetching: true }));
    const response = yield brandsApi.getBrandsGames();
    if (response.data) {
      const availableCustomGameTypes = {
        staging: [
          QUIZ_WITHOUT_BRAND_IDS.EVENT_MODE,
          QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID,
          QUIZ_WITHOUT_BRAND_IDS.SINGLE_MODE,
          QUIZ_WITHOUT_BRAND_IDS.MULTIBRAND_QUIZ,
        ],
        production: [
          QUIZ_WITHOUT_BRAND_IDS.EVENT_MODE,
          QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID,
          QUIZ_WITHOUT_BRAND_IDS.SINGLE_MODE,
          QUIZ_WITHOUT_BRAND_IDS.MULTIBRAND_QUIZ,
        ],
      };
      if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND || process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO) {
        const brands = response.data.brands.filter((x) => x.id > 0);
        yield put(brandsActions.setBrandsGames({ brands }));
      } else {
        const brands = response.data.brands.filter(
          (x) => x.id > 0 || availableCustomGameTypes[process.env.REACT_APP_ENVIRONMENT].includes(x.id),
        );
        yield put(brandsActions.setBrandsGames({ brands }));
      }
    }
  } catch (err) {
    console.error('getBrandsGames error', err);
  } finally {
    yield put(brandsActions.setIsFetching({ isFetching: false }));
  }
}

function* sagas() {
  yield all([takeLatest(BRANDS_ACTION_TYPES.GET_BRANDS_GAMES, getBrandsGamesSaga)]);
}

export const brandsSagas = sagas;

import { useSelector } from 'react-redux';

import './cashoutHistory.scss';
import colors from '../../../styles/consts.scss';

import { getTransfersSelector } from '../../../redux/bonusShop/bonusShopSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { formatDate } from '../../../helpers';

import { AcceptedIcon } from '../../../icons/accepted-icon';
import { ModerationIcon } from '../../../icons/moderation-icon';
import { FailedIcon } from '../../../icons/failed-icon';

export const CashoutHistory = (props) => {
  const { showTutorial, symbol, rate, specificTransfers, specificEmptyPlug } = props;
  const { formatMessage } = useInternationalization();
  const transfersSelector = useSelector(getTransfersSelector);
  const transfers = specificTransfers || transfersSelector;

  const getIcon = (status) => {
    switch (status) {
      case 1:
        return <AcceptedIcon className="cashout-history-list-item-status-icon" />;
      case 2:
        return <FailedIcon className="cashout-history-list-item-status-icon" />;
      case 3:
        return <ModerationIcon className="cashout-history-list-item-status-icon" />;
      default:
        return <AcceptedIcon className="cashout-history-list-item-status-icon" />;
    }
  };

  const getColor = (status) => {
    switch (status) {
      case 1:
        return colors.successColor;
      case 2:
        return colors.failedColor;
      case 3:
        return colors.moderationColor;
      default:
        return colors.failedColor;
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return 'm10.success_status';
      case 2:
        return 'm10.failed_status';
      case 3:
        return 'm10.pending_status';
      default:
        return 'm10.pending_status';
    }
  };

  const renderTransfer = (transfer) => {
    return (
      <div key={transfer.id}>
        <div className="cashout-history-list-item">
          <div className="cashout-history-list-item-info">
            <p className="cashout-history-list-item-info-amount">{`${symbol} ${
              transfer.currency || (transfer.amount * rate).toFixed(1)
            }`}</p>
            <p className="cashout-history-list-item-info-date">
              {formatDate(new Date(transfer.created_at), 'dd.MM.yy')}
            </p>
          </div>
          <div className="cashout-history-list-item-status" style={{ color: getColor(transfer.status) }}>
            {formatMessage(getStatusText(transfer.status))} {getIcon(transfer.status)}
          </div>
        </div>
        <div className="cashout-history-list-divider" />
      </div>
    );
  };

  return (
    <div
      className="cashout-history"
      style={{
        alignItems: transfers && !transfers.length ? 'center' : 'baseline',
        justifyContent: transfers && !transfers.length ? 'center' : 'end',
        paddingBottom: showTutorial ? '0px' : '190px',
      }}
    >
      {transfers && !transfers.length ? (
        <p className="cashout-history-empty">
          {formatMessage(specificEmptyPlug ? specificEmptyPlug : 'm10.no_transactions')}
        </p>
      ) : (
        <>
          <p className="cashout-history-caption">{formatMessage('m10.transactions')}</p>
          <div className="cashout-history-list">
            {transfers && transfers.map((transfer) => renderTransfer(transfer))}
          </div>
        </>
      )}
    </div>
  );
};

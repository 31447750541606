import { all, takeLatest, call } from '@redux-saga/core/effects';

import { promoCodeApi } from '../../api/promoCodeApi';
import { PROMOCODE_ACTION_TYPES } from './promoCodeConstants';
import { getUserInfoSaga } from '../profile/profileSagas';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';

function* submitPromoCode(action) {
  const { code, onSuccess = () => {}, onError = () => {} } = action.payload;
  try {
    yield promoCodeApi.postPromoCode(code);
    yield call(getUserInfoSaga);
    amplitudeHelper.promoCodeActivate(code);
    onSuccess();
  } catch (e) {
    console.error(e);
    onError(e);
  }
}

function* sagas() {
  yield all([takeLatest(PROMOCODE_ACTION_TYPES.POST_PROMO_CODE, submitPromoCode)]);
}

export const promoCodeSagas = sagas;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IChallenge } from './challengeTypes';

interface IChallengeState {
  isFetching: boolean;
  challenges: IChallenge[];
  currentChallenge: IChallenge | undefined;
  photoWithFrame: string | null;
}

const initialState: IChallengeState = {
  isFetching: false,
  challenges: [],
  photoWithFrame: null,
  currentChallenge: undefined,
};

const challengeSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    getChallenges: (): void => {},
    getChallenge: (): void => {},
    getChallengeById: (state, action: PayloadAction<{ id: number }>): void => {},
    uploadChallengePhoto: (
      state: IChallengeState,
      action: PayloadAction<{ formData: object; id?: number; taskId?: number; taskType?: string }>,
    ): void => {},
    sendAttachmentsForCheck: (
      state: IChallengeState,
      action: PayloadAction<{ taskId?: number; taskType: string; quest_id?: number; answer?: string; status?: number }>,
    ): void => {},
    setChallenges: (state: IChallengeState, action: PayloadAction<IChallenge[]>): void => {
      state.challenges = action.payload;
    },
    setChallenge: (state: IChallengeState, action: PayloadAction<IChallenge>): void => {
      state.currentChallenge = action.payload;
    },
    takePhoto: (): void => {},
    setPhotoWithFrame: (state: IChallengeState, action: PayloadAction<string>): void => {
      state.photoWithFrame = action.payload;
    },
    submit: (
      state: IChallengeState,
      action: PayloadAction<{ url?: string; id?: number; answer?: string; taskId?: number; taskType?: string }>,
    ): void => {},
    setIsFetching: (state: IChallengeState, action: PayloadAction<boolean>): void => {
      state.isFetching = action.payload;
    },
  },
});
export const challengeActions = challengeSlice.actions;
export const challengeReducer = challengeSlice.reducer;

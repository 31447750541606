import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Routes } from '../../../const';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { selectChances } from '../../../redux/chance/chanceSelectors';
import { getWordsFormat } from '../../../helpers/getWordsFormat';
import useChallengeTimeLeft from '../../../hooks/useChallengeTimeLeftHook';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { analyticHelper, MAIN_SCREEN_ACTIVITY_LABELS } from '../../../helpers/analyticHelper';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';
import { COMPONENT_TYPES } from '../../../const/mainScreenConsts';
import { BoxRightArrow } from '../../../icons';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../config';
import './boxComponent.scss';

export const BoxComponent = (props) => {
  const {
    name,
    description,
    payload,
    background_color,
    text_color,
    type,
    has_more_than_one_quest: hasMoreThanOneQuest,
  } = props;
  const dispatch = useDispatch();
  const challengeTime = useChallengeTimeLeft(payload);
  const { formatMessage } = useInternationalization();
  const hasTeamChallenge = isFeatureEnabled(CONFIG_KEYS.TEAM_CHALLENGES_ENABLED);
  const chances = useSelector(selectChances);
  const chanceId = chances[0]?.id;

  const handleCompletedTasks = (tasks) => {
    switch (tasks) {
      case 0:
        return formatMessage('Well done!');
      case 1:
        return formatMessage('1 task to complete');
      default:
        return formatMessage(getWordsFormat(tasks, '{tasks} tasks to complete'), { tasks });
    }
  };

  const getBoxComponentLink = () => {
    if (hasTeamChallenge) {
      if (hasMoreThanOneQuest) {
        return Routes.ChallengesRoute;
      }
      return `${Routes.ChallengeRoute}/${payload.quest_id}`;
    }
    return Routes.ChallengeRoute;
  };

  const handleBoxComponentClick = () => {
    analyticHelper.onMainScreenBoxClick(MAIN_SCREEN_ACTIVITY_LABELS.CHALLENGE);
    amplitudeHelper.mainBox();
  };

  switch (type) {
    case COMPONENT_TYPES.QUEST:
      const { tasks_to_complete } = payload;
      return (
        <Link to={getBoxComponentLink()} onClick={handleBoxComponentClick}>
          <div
            className="box-container"
            style={{
              background: background_color,
              color: text_color,
            }}
          >
            <div className="box-container__info">
              <div className="box-container__name">{name}</div>
              {!hasMoreThanOneQuest && (
                <div className="box-container__description">{handleCompletedTasks(tasks_to_complete)}</div>
              )}
            </div>
            <div className="box-container__expiration-time">
              <div>
                {!hasMoreThanOneQuest &&
                  `${challengeTime.days}${formatMessage('d')} ${challengeTime.hours}${formatMessage('h')} ${
                    challengeTime.minutes
                  }${formatMessage('m')}`}
              </div>
              <BoxRightArrow />
            </div>
          </div>
        </Link>
      );
    case COMPONENT_TYPES.LOTTERY: {
      return (
        <Link
          to={`${Routes.ChanceRoute}/${chanceId}`}
          onClick={() => {
            analyticHelper.onMainScreenBoxClick(MAIN_SCREEN_ACTIVITY_LABELS.CHANCE);
            amplitudeHelper.mainBox();
          }}
        >
          <div
            className="box-container"
            style={{
              background: background_color,
              color: text_color,
            }}
          >
            <div className="box-container__info">
              <div className="box-container__name">{name}</div>
              <div className="box-container__description">{description}</div>
            </div>
            <div className="box-container__picture-block">
              <BoxRightArrow fillColor={text_color} />
            </div>
          </div>
        </Link>
      );
    }
    case COMPONENT_TYPES.KB_ARTICLE: {
      return (
        <div
          className="box-container"
          style={{
            background: background_color,
            color: text_color,
          }}
          onClick={() => {
            dispatch(knowledgeBankActions.openArticle({ article: payload }));
            analyticHelper.onMainScreenBoxClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_ARTICLE);
            amplitudeHelper.mainBox();
          }}
        >
          <div className="box-container__info">
            <div className="box-container__name">{name}</div>
          </div>
          <div className="box-container__picture-block">
            <BoxRightArrow fillColor={text_color} />
          </div>
        </div>
      );
    }
    case COMPONENT_TYPES.SPECIAL_QUIZ: {
      return (
        <div
          className="box-container"
          style={{
            background: background_color,
            color: text_color,
          }}
          onClick={() => {
            dispatch(
              specialQuizActions.startSpecialQuiz({
                special_quiz_id: payload.id,
                brand_id: payload.brand_id,
              }),
            );
            analyticHelper.onMainScreenBoxClick(MAIN_SCREEN_ACTIVITY_LABELS.SPECIAL_QUIZ);
            amplitudeHelper.mainBox();
          }}
        >
          <div className="box-container__info">
            <div className="box-container__name">{name}</div>
          </div>
          <div className="box-container__picture-block">
            <BoxRightArrow fillColor={text_color} />
          </div>
        </div>
      );
    }
    default:
      return null;
  }
};

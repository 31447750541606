import { useDispatch } from 'react-redux';

import './oppaTutorial.scss';

import { Button } from '../../../../components/common/Button';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { ArrowBig } from '../../../../icons/arrow-icon-big';
import { oppaTutorialSteps } from './oppaTutorial';
import { SkipIcon } from '../../../../icons/skip-icon';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';

export const OppaTutorialStep6 = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const handleFinish = () => {
    localStorage.setItem('oppaCompletedTutorial', true);
    dispatch(
      stepListActions.setStep({ stepListId: STEP_LIST_IDS.oppaTutorialStepList, stepId: oppaTutorialSteps.stepNull }),
    );
  };

  const handleSkip = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure you want to finish?',
        btnText: 'Yes, exit',
        onButtonClick: () => {
          localStorage.setItem('oppaCompletedTutorial', true);
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.oppaTutorialStepList,
              stepId: oppaTutorialSteps.stepNull,
            }),
          );
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className="oppa-tutorial__blackout-step6">
      <div className="oppa-tutorial__skip" onClick={() => handleSkip()}>
        {formatMessage('Skip')}
        <SkipIcon className="oppa-tutorial__skip-icon" />
      </div>
      <ArrowBig right className="oppa-tutorial__arrow-faq" />
      <div className="oppa-tutorial__descriptionBox-bottom">
        <div className="achievements-tutorial__descriptionBox-step">5/5</div>
        <div className="oppa-tutorial__descriptionBox-header">{formatMessage('Questions')}</div>
        <div className="oppa-tutorial__descriptionBox-description">
          {formatMessage(
            "More information about coins redemption you could find pressing the '?' button. Hope you find this tutorial helpful!",
          )}
        </div>
      </div>
      <div className="oppa-tutorial__button-box">
        <Button customClass="oppa-tutorial__button" withoutTheme={true} onClick={handleFinish}>
          {formatMessage('Start tutorial')}
        </Button>
      </div>
    </div>
  );
};

import { useState, useCallback, useEffect } from 'react';

import './surveyPage.scss';

import { getSurveyQuestionsSelector } from '../../../redux/survey/surveySelectors';
import { PlusIcon } from '../../../icons/plus-icon';
import { RenderGame } from '../Game/renderQuestionComponent';
import { surveyActions } from '../../../redux/survey/surveySlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { Button } from '../../../components/common/Button';

export const SurveyGameComponent = (props) => {
  const { theme, onLeaveCallback, history } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const questions = useSelector(getSurveyQuestionsSelector);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [multipleAnswers, setMultipleAnswers] = useState([]);
  const [isMultiple, setIsMultiple] = useState([]);

  useEffect(() => {
    if (questions) {
      setIsMultiple(questions[currentQuestion].is_multiple);
    }
  }, [currentQuestion, questions]);

  const nextQuestionCallback = useCallback(() => {
    if (currentQuestion + 1 === Number(questions.length)) {
      dispatch(surveyActions.finishSurvey());
    } else {
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
      }, 2000);
    }
  }, [currentQuestion, questions, dispatch]);

  const nextQuestionMultipleCallback = useCallback(() => {
    if (multipleAnswers.length === 0) {
      return;
    }
    dispatch(surveyActions.setMultipleAnswers({ multipleAnswers }));
    if (currentQuestion + 1 === Number(questions.length)) {
      dispatch(surveyActions.finishSurvey());
    } else {
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
        const currentAnswers = answers;
        currentAnswers.push('');
        setAnswers(currentAnswers);
        setMultipleAnswers([]);
      }, 500);
    }
  }, [multipleAnswers, dispatch, currentQuestion, questions, answers]);

  const onAnswerClicked = useCallback(
    (answer) => {
      if (answers[currentQuestion]) {
        return;
      }
      const currentAnswers = [...answers];
      currentAnswers.push({ answer, correctAnswer: answers, isCorrect: true });
      setAnswers(currentAnswers);
      dispatch(surveyActions.setAnswer({ answer }));
      nextQuestionCallback();
    },
    [answers, nextQuestionCallback, dispatch, currentQuestion],
  );

  const onAnswerClickedMultiple = useCallback(
    (answer) => {
      const currentAnswers = [...multipleAnswers];
      const indexToRemove = currentAnswers.findIndex((i) => i === answer);
      if (indexToRemove !== -1) {
        currentAnswers.splice(indexToRemove, 1);
        setMultipleAnswers(currentAnswers);
      } else {
        currentAnswers.push(answer);
        setMultipleAnswers(currentAnswers);
      }
    },
    [multipleAnswers],
  );

  const noDataBackCallback = useCallback(() => {
    history.goBack();
  }, [history]);

  const renderNextQuestionButton = () => {
    if (isMultiple) {
      return multipleAnswers.length === 0 ? (
        <Button customClass="survey-page__next-button__disabled" withoutTheme>
          <ArrowIconRounded className="survey-page__next-icon__disabled" />
        </Button>
      ) : (
        <Button customClass="survey-page__next-button" onClick={nextQuestionMultipleCallback} withoutTheme>
          {formatMessage('Next-multiple')} <ArrowIconRounded className="survey-page__next-icon" />
        </Button>
      );
    }
    return null;
  };

  if (!questions) {
    noDataBackCallback();
    return null;
  }
  return (
    <div className="survey-page__game">
      <div className="survey-page__leave-game" onClick={onLeaveCallback}>
        <PlusIcon className="survey-page__leave-game-icon" /> {formatMessage('Tap to exit')}
      </div>

      <div className="survey-page__questions-count">
        {`${currentQuestion + 1}/${questions.length}`}
        {renderNextQuestionButton()}
      </div>

      <RenderGame
        currentQuestion={questions[currentQuestion]}
        questions={questions}
        currentQuestionNumber={currentQuestion}
        theme={theme}
        onAnswerClicked={isMultiple ? onAnswerClickedMultiple : onAnswerClicked}
        checkedAnswers={{ me: isMultiple ? multipleAnswers : answers }}
        withoutOpponent={true}
        is_multiple={isMultiple}
      />
    </div>
  );
};

import React from 'react';

import { mysteryShopperColors } from '../../../../redux/mysteryShopper/mysteryShopperConstants';

import styles from './Criteria.module.scss';

type Props = {
  name: string;
  percentOfCorrectAnswers: number;
  recommendation: string;
};

export const Criteria = ({ name, percentOfCorrectAnswers, recommendation }: Props) => {
  const getColor = () => {
    if (percentOfCorrectAnswers >= 71) {
      return mysteryShopperColors.green;
    } else if (percentOfCorrectAnswers >= 31) {
      return mysteryShopperColors.orange;
    } else {
      return mysteryShopperColors.red;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.percentsWrapper}>
        <span className={styles.name}>{name}</span>
        <span style={{ color: getColor() }} className={styles.percent}>{`${percentOfCorrectAnswers}%`}</span>
      </div>
      <span className={styles.recommendation}>{recommendation}</span>
    </div>
  );
};

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import { ArrowButton } from '../../components/common/ArrowButton/ArrowButton';
import { passwordChangeSchema } from '../../validation/passwordChangeSchema';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ArrowIconRounded } from '../../icons/arrow-icon-rounded';
import { CommonInput } from '../../components/common/Input';
import { authActions } from '../../redux/auth/authSlice';
import { Button } from '../../components/common/Button';

interface PasswordChangeFormData {
  password: string;
  passwordConfirm: string;
}

const PasswordChange = () => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm<PasswordChangeFormData>({
    // @ts-ignore https://github.com/react-hook-form/resolvers/issues/643
    resolver: yupResolver<PasswordChangeFormData>(passwordChangeSchema),
    defaultValues: {
      password: '',
      passwordConfirm: '',
    },
  });

  const onSubmit = async ({ password }: PasswordChangeFormData) => {
    try {
      dispatch(authActions.changePassword({ password }));
    } catch (error: any) {
      console.warn(error);
    }
  };

  const onBackButtonClick = () => {
    dispatch(goBack());
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="signup-page themed-background-image">
      <div className="signup-page__top-section">
        <Button withoutTheme type="button" className="signup-page__back-button" onClick={onBackButtonClick}>
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{t('password_change_page.title')}</div>
        {/* @ts-ignore https://github.com/react-hook-form/react-hook-form/issues/6679 */}
        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              type="password"
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('common.password_new')}
              customClass="signup-page__input"
            />
          )}
        />
        <Controller
          control={control}
          name="passwordConfirm"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              type="password"
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('common.password_new_confirm')}
              customClass="signup-page__input"
            />
          )}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme customClass={undefined} />
      </div>
    </form>
  );
};

export default PasswordChange;

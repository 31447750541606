import { isProduction } from '../helpers/environmentHelper';

export const EDENRED_VOUCHER_UPDATE_STATUS = {
  REDEMPTION: '1',
  REVERSE_REDEMPTION: '2',
};

export const EDENRED_VIEW_VOUCHER_URL = isProduction()
  ? 'https://txp.rs/viewvoucher.aspx?voucherguid='
  : 'https://txpuat.tixpress.tw/viewvoucher.aspx?voucherguid=';

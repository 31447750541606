import React from 'react';
import { useDispatch } from 'react-redux';
import { createStepListContainer } from '../containers/StepList/StepListContainer';
import { stepListActions } from '../containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../containers/StepList/stepListConstants';
import { personalDataButtonText, textForPersonalData } from '../../pages/auth/authPageConstants';
import TermsStep1 from './TermsStep1/TermsStep1';
import TermsStep3 from './TermsStep3/TermsStep3';
import TermsAcceptance from '../common/TermsAcceptance/TermsAcceptance';
import { profileActions } from '../../redux/profile/profileSlice';

const NewTermsSteps = () => {
  const dispatch = useDispatch();

  const onArrowButtonClick = () => {
    //TODO: add after approval
    // dispatch(
    //   stepListActions.nextStep({
    //     stepListId: STEP_LIST_IDS.acceptNewTermsStepsList,
    //   }),
    // );
    dispatch(profileActions.getUserInfo({}));
  };
  const onBackArrowClick = () => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.acceptNewTermsStepsList }));
  };

  const StepsList = createStepListContainer(STEP_LIST_IDS.acceptNewTermsStepsList, [
    {
      id: 1,
      Component: () => <TermsStep1 />,
    },
    {
      id: 2,
      Component: () => (
        <TermsAcceptance
          buttonText={personalDataButtonText}
          text={textForPersonalData}
          onArrowButtonClick={onArrowButtonClick}
          onBackArrowClick={onBackArrowClick}
        />
      ),
    },
    //TODO: add after approval
    // {
    //   id: 3,
    //   Component: () => <TermsStep3 />,
    // },
  ]);

  return <StepsList />;
};

export default NewTermsSteps;

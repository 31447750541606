import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { formatDate } from '../../../../helpers';
import { selectPathname } from '../../../../redux/router/routerSelectors';
import { getUserInfoSelector } from '../../../../redux/profile/profileSelectors';

import { ChatRoutes } from '../../../../const';
import { APP_TYPES } from '../../../../const/appConstants';
import { ChatMessage as ChatMessageModel } from '../../../../redux/chat/chatTypes';
import { Button } from '../../../../components/common';

import { ConnectIcon } from '../../../../icons/Connect-icon';
import { JTIIcon } from '../../../../icons/JTI-icon';
import { SupportChatIcon } from '../../../../icons/Support-icon';

import styles from './ChatMessage.module.scss';

type Props = {
  message: ChatMessageModel;
  className?: string;
  onButtonClick: () => void;
  isButtonShown: boolean;
};

export const ChatMessage = ({ message, className, onButtonClick, isButtonShown }: Props) => {
  const { formatMessage } = useInternationalization();
  const pathname = useSelector(selectPathname);
  const { name } = useSelector(getUserInfoSelector);

  const getSenderName = () => {
    switch (pathname) {
      case ChatRoutes.JTI:
        return formatMessage('JTI');
      default:
        return formatMessage('Support manager');
    }
  };

  const getSenderIcon = () => {
    switch (process.env.REACT_APP_APP_TYPE) {
      case APP_TYPES.HUNGARY:
        return pathname === ChatRoutes.JTI ? JTIIcon : ConnectIcon;
      default:
        return SupportChatIcon;
    }
  };

  const IconComponent = getSenderIcon();

  const { text, createdAt, image, isAdmin, link } = message;
  const time = formatDate(new Date(createdAt), 'HH:mm');

  return (
    <div className={classNames(styles.chatMessage, isAdmin ? styles.opponent : styles.myself, className)}>
      {isAdmin && <IconComponent className={styles.avatar} />}

      <div className={styles.content}>
        <div className={styles.upperBlock}>
          {isAdmin && <div className={styles.senderName}>{getSenderName()}</div>}
          <div className={styles.time}>{time}</div>
        </div>
        <div className={classNames(styles.textBlock, { themedMessage: true })}>
          <div className={styles.text}>{text.replace('[name]', name)}</div>
          {link && (
            <a className={styles.link} href={link} target="_blank">
              {link}
            </a>
          )}
          {image && <img className={styles.image} alt="" src={image} />}
          {isButtonShown && (
            <Button className={styles.button} onClick={onButtonClick}>
              {message.type === 20 ? formatMessage('Get reward!') : formatMessage('Read')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './boostersPage.scss';

import { Header } from '../../components/common/Header';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { ExchangePlusIcon } from '../../icons/plus-exchange-icon';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getBoostersShopCards, getLeafExchangeRate } from '../../redux/boosters/boostersSelectors';
import { getUserLeafSelector, getUserBalanceSelector } from '../../redux/profile/profileSelectors';
import { getWordsFormat } from '../../helpers/getWordsFormat';
import { boostersActions } from '../../redux/boosters/boostersSlice';
import { profileActions } from '../../redux/profile/profileSlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { BoostersSuccessPage } from './boostersSuccessPage';
import { analyticHelper } from '../../helpers/analyticHelper';

export const BoostersPage = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const [successfulPurchse, setSuccessfulPurchase] = useState(false);

  useEffect(() => {
    dispatch(boostersActions.getBoostersShop());
    dispatch(profileActions.getUserInfo());
    analyticHelper.boostersBonusShopOpen();
  }, [dispatch]);

  const cards = useSelector(getBoostersShopCards);
  const leaf = useSelector(getUserLeafSelector);
  const coins = useSelector(getUserBalanceSelector);
  const leafExchangeRate = useSelector(getLeafExchangeRate);
  const step = 1;

  const makeTransfer = (params) => {
    dispatch(boostersActions.exchangeLeaf(params));
    dispatch(
      modalActions.openPromptModal({
        title: 'Success',
        description: ' ',
        btnText: 'OK',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const openErrorModal = (message) => {
    dispatch(
      modalActions.openErrorModal({
        description: message,
        btnText: 'OK',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const openTransferWindow = () => {
    analyticHelper.boostersExchangeOpen();
    if (coins < leafExchangeRate * step) {
      openErrorModal('Not enough credits');
    } else {
      dispatch(
        modalActions.openExchangeModal({
          title: 'Choose amount',
          btnText: 'Proceed',
          rate: leafExchangeRate,
          step: step,
          makeTransfer: makeTransfer,
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  };

  const buyBooster = (booster) => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure that you want to order this booster?',
        description: 'It cannot be cancelled or exchanged once ordered',
        btnText: 'Order (BoostersShop)',
        onButtonClick: () => {
          if (leaf < booster.price) {
            openErrorModal('Not enough Leaf to get this booster');
          } else {
            dispatch(boostersActions.buyBooster({ booster_id: booster.id, amount: 1 }));
            dispatch(modalActions.closeModal());
            setSuccessfulPurchase(true);
          }
        },
      }),
    );
  };

  const renderBoosterCard = (card, i) => {
    const price = card.price;
    return (
      <div
        key={i}
        className="boosters-page__content-wrapper-boosters-card"
        style={{ backgroundImage: `url(${card.banner})` }}
        onClick={() => buyBooster(card)}
      >
        <div className="boosters-page__content-wrapper-boosters-card-amount" style={{ borderColor: card.main_color }}>
          {card.amount}
        </div>
        <div className="boosters-page__content-wrapper-boosters-card-name" style={{ color: card.text_color }}>
          {card.name}
        </div>
        <div className="boosters-page__content-wrapper-boosters-card-description" style={{ color: card.text_color }}>
          {card.description}
        </div>
        <div className="boosters-page__content-wrapper-boosters-card-price">
          {formatMessage(`Buy for {price} ${getWordsFormat(card.price, 'leaf')}`, { price })}
        </div>
      </div>
    );
  };

  return (
    <div className="boosters-page">
      <BoostersSuccessPage
        customClass={successfulPurchse ? 'boosters-page__success' : 'boosters-page__success-hide'}
        onButtonClick={() => setSuccessfulPurchase(false)}
      />
      <ScrollBlock fromTop>
        <Header hasBackButton customClass="boosters-page__header">
          <p className="boosters-page__header-text">{formatMessage('Boosters')}</p>
        </Header>
        <div className="boosters-page__balance">
          <p>{formatMessage('Your balance:')}</p>
          <p>{formatMessage('{leaf} ' + getWordsFormat(leaf ?? 0, 'Leaf'), { leaf })}</p>
        </div>
        <div className="boosters-page__content-wrapper">
          <div className="boosters-page__content-wrapper-header">
            <p className="boosters-page__content-wrapper-header-headline">
              {formatMessage('Boost up your daily routine!')}
            </p>
            <p className="boosters-page__content-wrapper-header-description">
              {formatMessage('Exchage your Leaf to boosters')}
            </p>
          </div>
          <div className="boosters-page__content-wrapper-exchange">
            <p className="boosters-page__content-wrapper-exchange-text">{formatMessage('Exchange credits to Leaf')}</p>
            <ExchangePlusIcon className="boosters-page__content-wrapper-exchange-icon" onClick={openTransferWindow} />
          </div>
          <div className="boosters-page__content-wrapper-boosters">
            {cards?.map((card, i) => renderBoosterCard(card, i))}
          </div>
        </div>
      </ScrollBlock>
      )
    </div>
  );
};

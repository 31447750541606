import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getCoinsCount } from '../../../helpers/coinFormatter';
import { getUserBalanceSelector } from '../../../redux/profile/profileSelectors';

import './Balance.scss';

export const Balance = ({ fixed, activePage }) => {
  const { formatMessage } = useInternationalization();

  const coins = useSelector(getUserBalanceSelector);

  const getPosition = () => {
    if (activePage > 1) {
      return `${(activePage - 1) * window.screen.width}px`;
    }
    return '0px';
  };

  return (
    <div
      className={classnames('balance-block', fixed ? 'fixed-balance' : 'sticky-balance')}
      style={{ right: getPosition() }}
    >
      <p style={{ fontSize: '14px' }}>{formatMessage('Your balance:')}</p>

      <p>
        {getCoinsCount(coins)} {formatMessage('points')}
      </p>
    </div>
  );
};

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { disableBodyScroll } from 'body-scroll-lock';
import copy from 'copy-to-clipboard';

import './inviteCode.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { SuccessIcon } from '../../../icons/success-icon';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { getCurrentComponentSelector } from '../../../redux/mainPage/mainPageSelectors';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { isIosDevice } from '../../../helpers/isIosDeviceHelper';

export const InviteCodePageIreland = () => {
  const { formatMessage } = useInternationalization();
  const currentComponent = useSelector(getCurrentComponentSelector);

  const { invite_code, background, reward } = currentComponent.payload;
  const [codeCopied, setCodeCopied] = useState(false);
  const [slideIn, setSlideIn] = useState(0);

  const shareCode = () => {
    copy(`${window.location.origin}/?inviteCode=${invite_code}`);
    setCodeCopied(true);
    setSlideIn(1);
    analyticHelper.mainscreenInviteCodeCopied();
  };

  useEffect(() => {
    const container = document.getElementsByClassName('invite-code-page');

    if (!isIosDevice) {
      disableBodyScroll(container);
    }
  });

  return (
    <div style={{ backgroundImage: `url(${background})` }} className="invite-code-page">
      <div className="invite__top-section">
        <BackArrowButton customClass="invite__top-section-back-button" buttonColor="black" />
      </div>
      <div className="invite__container">
        <div className="invite__card">
          <div className="invite__card-info">
            <div className="invite__card-info-caption">
              {formatMessage('Invite your colleagues and get +{reward} points').replace('{reward}', reward)}
            </div>
            <div className="invite__card-info-text">
              {formatMessage('Share this invite code with your colleagues and get points for each registered user')}
            </div>
          </div>
          <div className={codeCopied ? 'invite__card-code-copied' : 'invite__card-code'}>
            <div
              className={codeCopied ? 'invite__card-code-copy-status-copied' : 'invite__card-code-copy-status'}
              onAnimationEnd={() => setSlideIn(0)}
              slide_in={slideIn}
            >
              {formatMessage('Copied')} <SuccessIcon className="invite__card-code-copy-status-img" />
            </div>
            <div className="invite__card-code-text">{invite_code}</div>
          </div>
          <Button onClick={shareCode} customClass="invite__card-button">
            {formatMessage('Copy')}
          </Button>
        </div>
      </div>
    </div>
  );
};

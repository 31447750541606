import { useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import history from '../../../helpers/history';
import './GameResultStep.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkAnswersSelector,
  getMyQuizPointsSelector,
  getOpponentQuizPointsSelector,
  getOpponentSelector,
  getQuizResultsSelector,
  getIsBonusGameFinishedSelector,
  getCoinsForGameSelector,
} from '../../../redux/game/gameSelectors';
import { getUserInfoSelector } from '../../../redux/profile/profileSelectors';
import { getDailyRewardsProducts, getStatusDailyRewards } from '../../../redux/dailyRewards/dailyRewardsSelectors';
import { AnswerRows } from '../AnswersRow/AnswersRow';
import { Button } from '../../../components/common/Button';
import { gameActions } from '../../../redux/game/gameSlice';
import { brandsActions } from '../../../redux/brands/brandsSlice';
import { goToGameStepAction } from '../../../redux/game/gameSagas';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { getWordsFormat } from '../../../helpers/getWordsFormat';
import { GAME_STEPS } from '../../../redux/game/gameConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Routes } from '../../../const';
import { getBonusGamesAmountSelector } from '../../../redux/brands/brandSelectors';
import { APP_TYPES } from '../../../const/appConstants';
import { GAME_RESULT_TEXT } from '../../../const/gameResultsTexts';
import { DAILY_REWARDS_STATUS } from '../../../redux/dailyRewards/dailyRewardsConstants';
import { getRandomInt } from '../../../helpers/mathHelper';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { quizRateSelectors } from '../../../redux/quizRate/quizRateSelector';
import UserInfo from '../UserInfo/UserInfo';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';

const COLORS = {
  COLOR_RED: '#DE4D4D',
  COLOR_GREEN: '#4CBF8F',
};

const RESULT_TEXT = {
  lose: GAME_RESULT_TEXT.lose,
  win: GAME_RESULT_TEXT.win,
  draw: GAME_RESULT_TEXT.draw,
};

if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) {
  // formatMessage('Close but no cigar! Try again.')
  RESULT_TEXT.lose.push('Close but no cigar! Try again.');
}
export const GameResultStep = (props) => {
  const { formatMessage } = useInternationalization();

  const { theme, onBack, brandId } = props;

  const { opponent } = useSelector(getOpponentSelector);
  const isBonusGameFinished = useSelector(getIsBonusGameFinishedSelector);
  const userInfo = useSelector(getUserInfoSelector);
  const checkedAnswers = useSelector(checkAnswersSelector);
  const opponentCudos = useSelector(getOpponentQuizPointsSelector);
  const myCudos = useSelector(getMyQuizPointsSelector);
  const status = useSelector(getQuizResultsSelector);
  const points = useSelector(getCoinsForGameSelector);
  const dailyStatus = useSelector(getStatusDailyRewards);
  const bonusGames = useSelector((state) => getBonusGamesAmountSelector(state, brandId));
  const products = useSelector(getDailyRewardsProducts);
  const dispatch = useDispatch();
  const isRateModalShown = useSelector(quizRateSelectors.selectIsRateModalShown);
  const isHungary = process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;

  const goToDailyRewards = useCallback(() => {
    if (dailyStatus && dailyStatus !== DAILY_REWARDS_STATUS.UNAVAILABLE) {
      history.replace(Routes.DailyRewardsRoute);
    }
  }, [dailyStatus]);

  useEffect(() => {
    if (isRateModalShown && isHungary) dispatch(modalActions.openQuizRateModal());
    if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
      setTimeout(() => goToDailyRewards(), 3000);
    }
    analyticHelper.quizResultPage();
  }, [goToDailyRewards]);

  useEffect(() => {
    return function cleanup() {
      dispatch(brandsActions.getBrandsGames());
    };
  }, [dispatch]);

  const backToMain = useCallback(() => {
    onBack();
    dispatch(gameActions.reset());
    amplitudeHelper.h2hQuizBackToMain();
    analyticHelper.quizBackToMain();
    if (dailyStatus !== DAILY_REWARDS_STATUS.UNAVAILABLE && products && products.length !== 0) {
      history.replace(Routes.DailyRewardsRoute);
    } else {
      if (process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS) {
        history.replace(Routes.BonusShopRoute);
      } else {
        history.replace(Routes.MainRoute);
      }
    }
  }, [onBack, dispatch, dailyStatus, products]);

  const newGameCallBack = useCallback(() => {
    onBack();
    amplitudeHelper.h2hQuizNewGame();
    dispatch(goToGameStepAction(GAME_STEPS.LOADING));
    if (bonusGames === 0 || bonusGames === 1) {
      history.push(`/no-bonus-games-info/${brandId}`);
    } else {
      dispatch(gameActions.run({ brand_id: brandId }));
      analyticHelper.quizNewGame();
    }
  }, [dispatch, brandId, onBack, bonusGames]);

  const getColor = (isUser) => {
    if (status === 'win') {
      return isUser ? COLORS.COLOR_GREEN : COLORS.COLOR_RED;
    } else if (status === 'lose') {
      return isUser ? COLORS.COLOR_RED : COLORS.COLOR_GREEN;
    } else return theme.color;
  };

  const [random] = useState(getRandomInt(0, RESULT_TEXT[status].length));
  const capture = RESULT_TEXT[status][random];

  return (
    <div className={'game-results'} style={{ color: theme.color }}>
      <div className={'game-results-info'}>
        <div className={'game-results-capture'}>{formatMessage(capture)}</div>
        <div
          className={classnames('game-results-points-count', {
            [`game-results-points-count-red`]: points < 1,
          })}
          style={{ Color: getColor(true) }}
        >
          {isBonusGameFinished ? `${points} ${formatMessage(getWordsFormat(points, 'points'))}` : ''}
        </div>
        <div className={'game-results-players'}>
          <div className={'game-results-player'}>
            <div>
              <UserInfo userAvatar={userInfo.photo} username={userInfo.username} color={getColor(true)} />
              <div className={'game-results-player-row-answers'}>
                <AnswerRows answers={checkedAnswers.me.map((el) => el.isCorrect)} count={checkedAnswers.me.length} />
              </div>
            </div>
            <div className={classnames('game-results-player-kudos')} style={{ color: getColor(true) }}>
              <div className={'game-results-player-kudos-count'}>{myCudos}</div>
              <div className={'game-results-player-kudos-capture'}>{formatMessage('kudos')}</div>
            </div>
          </div>
          <div className={'game-results-vertical-line'} style={{ borderColor: theme.color }} />
          <div className={'game-results-player'}>
            <div className={'game-results-player-kudos'} style={{ color: getColor(false) }}>
              <div className={'game-results-player-kudos-count'}>{opponentCudos}</div>
              <div className={'game-results-player-kudos-capture'}>{formatMessage('kudos')}</div>
            </div>
            <div>
              <UserInfo userAvatar={opponent.photo} username={opponent.username} color={getColor(false)} />
              <div className={'game-results-player-row-answers'}>
                <AnswerRows
                  answers={checkedAnswers.opponent.map((el) => el.isCorrect)}
                  count={checkedAnswers.opponent.length}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'game-results-buttons'}>
        <Button
          withoutTheme={true}
          customClass={classnames('game-results-button-go-to-main', 'game-results-button')}
          onClick={backToMain}
        >
          {formatMessage('Back to main')}
        </Button>
        <Button
          withoutTheme={true}
          customClass={classnames('game-results-button-new-game', 'game-results-button')}
          onClick={newGameCallBack}
        >
          {formatMessage('New game')}
        </Button>
      </div>
    </div>
  );
};

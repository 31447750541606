import { memo, useState } from 'react';

import { VoucherTransactionWithDetails } from '../../types/Voucher';
import MyVouchersListItem from '../../pages/MyVouchers/MyVouchersListItem';
import SwitchCategoryButton from './SwitchCategoryButton';
import styles from './VouchersByCategory.module.scss';
import { isVoucherExpired, isVoucherRedeemed } from '../../helpers/edenred';

const categories = {
  EXPIRED: 'bonus_shop.button.expired',
  USED: 'bonus_shop.button.used',
  ACTIVE: 'bonus_shop.button.active',
} as const;

interface IVouchersByCategory {
  vouchers: VoucherTransactionWithDetails[];
  onClick: (voucher: VoucherTransactionWithDetails) => void;
}

export type VoucherCategory = keyof typeof categories;

export type CategoriesValue = typeof categories[keyof typeof categories];

type IVouchersCategories = {
  [key in VoucherCategory]: VoucherTransactionWithDetails[];
};

const VouchersByCategory = ({ vouchers, onClick }: IVouchersByCategory) => {
  const [activeCategory, setActiveCategory] = useState<VoucherCategory>('EXPIRED');

  const vouchersByCategory: IVouchersCategories = {
    EXPIRED: [],
    USED: [],
    ACTIVE: [],
  };

  const getVoucherLabel = (label: VoucherCategory): CategoriesValue =>
    (categories as Record<VoucherCategory, CategoriesValue>)[label];

  const setActiveCategoryHandler = (category: VoucherCategory) => () => setActiveCategory(category);

  vouchers.forEach((voucher: VoucherTransactionWithDetails) => {
    if (isVoucherExpired(voucher)) {
      return vouchersByCategory.EXPIRED.push(voucher);
    }

    if (isVoucherRedeemed(voucher)) {
      return vouchersByCategory.USED.push(voucher);
    }

    vouchersByCategory.ACTIVE.push(voucher);
  });

  return (
    <>
      <div className={styles.buttonsContainer}>
        {Object.keys(categories).map((categoryStatus: string) => (
          <SwitchCategoryButton
            key={categoryStatus}
            id={categoryStatus}
            text={getVoucherLabel(categoryStatus as VoucherCategory)}
            onClick={setActiveCategoryHandler(categoryStatus as VoucherCategory)}
            isActive={activeCategory}
          />
        ))}
      </div>
      {vouchersByCategory[activeCategory as VoucherCategory].map((item) => (
        <MyVouchersListItem key={item.id} redeemedVoucher={item} onClick={onClick} />
      ))}
    </>
  );
};

export default memo(VouchersByCategory);

import { useState, useEffect } from 'react';
import classnames from 'classnames';

import { LogicVapesDisclaimer } from '../../../icons/logic-vapes-disclaimer';
import { AnswerComponent } from './answerComponent';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { usePreloadImagesHook } from '../../../hooks/usePreloadImagesHook';
import { CAUCASUS, APP_TYPES } from '../../../const/appConstants';
import { FooterBy } from '../../../icons/footer-by-text';
import { getBrandInfoSelector } from '../../../redux/brands/brandSelectors';
import { useSelector } from 'react-redux';
import { isBelarus } from '../../../helpers';

const QUIZ_TYPES_CONSTANTS = {
  CLASSIC_QUIZ: 1,
  TRUE_OR_FALSE: 2,
  PICK_PICKTURE: 3,
};

const imageMaxWidth = 343;

const getDisclaimer = (brandId, formatMessage, image, type, width = imageMaxWidth) => {
  let disclaimer;
  if (image) {
    disclaimer = <img src={image} alt="disclaimer" className="quiz-page__disclaimer-image" />;
  } else if (process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS) {
    disclaimer = <FooterBy />;
  } else {
    disclaimer = (
      <>
        {formatMessage('Result of smoking - death')}. {formatMessage('Smoking kills')}.
      </>
    );
  }

  return brandId === 5 && process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND ? (
    <div className={classnames('quiz-page__disclaimer-logic-container', type)} style={{ maxWidth: `${width}px` }}>
      <LogicVapesDisclaimer className="quiz-page__disclaimer-logic" />
    </div>
  ) : (
    <div
      className={
        CAUCASUS.includes(process.env.REACT_APP_APP_TYPE)
          ? classnames(image ? 'quiz-page__disclaimer-caucasus' : 'quiz-page__disclaimer-caucasus-with-border', type)
          : classnames(image ? 'quiz-page__disclaimer' : 'quiz-page__disclaimer-with-border', type, {
              'quiz-page__disclaimer_ru-region': process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS,
            })
      }
      style={{ maxWidth: `${width}px` }}
    >
      {disclaimer}
    </div>
  );
};

export const RenderGame = (props) => {
  const {
    currentQuestion,
    questions = [],
    theme,
    myVariant,
    onAnswerClicked,
    opponentsVariant,
    brandId,
    checkedAnswers,
    currentQuestionNumber,
    withoutOpponent,
    is_multiple,
    withBoosters,
  } = props;

  const {
    price_disclaimer,
    show_disclaimer,
    product_question,
    double_points,
    type,
    text,
    id,
    image,
    answers,
  } = currentQuestion;

  const { formatMessage } = useInternationalization();
  const [answerWidth, setAnswerWidth] = useState(140);
  const [answerHeight, setAnswerHeight] = useState(70);
  const brand = useSelector((state) => getBrandInfoSelector(state, brandId));

  usePreloadImagesHook(questions.filter((x) => x.type.id !== QUIZ_TYPES_CONSTANTS.PICK_PICKTURE).map((i) => i.image));

  const pickPictureQuestions = questions.filter((i) => i.type.id === QUIZ_TYPES_CONSTANTS.PICK_PICKTURE);
  let picturesToLoad = [];
  pickPictureQuestions.forEach((question) => {
    picturesToLoad = [...picturesToLoad, ...question.answers];
  });

  usePreloadImagesHook(picturesToLoad);

  useEffect(() => {
    const handleResize = () => {
      if (window.screen.width >= 375) {
        setAnswerWidth((imageMaxWidth - 5) / 2);
      } else {
        setAnswerWidth((window.screen.width - 37) / 2);
      }
    };
    const windowHeight = window.screen.height;

    if (windowHeight <= 568) {
      if (withBoosters) {
        setAnswerHeight(53);
      } else {
        setAnswerHeight(71);
      }
    } else if (568 <= windowHeight <= 667) {
      if (withBoosters) {
        setAnswerHeight(66);
      } else {
        setAnswerHeight(104);
      }
    } else {
      setAnswerHeight(127);
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [withBoosters]);

  const getAnswerColor = (answer) => {
    if (is_multiple) {
      if (checkedAnswers.me.some((i) => i === answer)) {
        return 'correct';
      }
      return '';
    }
    if (withoutOpponent) {
      if (checkedAnswers.me[currentQuestionNumber]) {
        if (checkedAnswers.me[currentQuestionNumber].answer === answer) {
          return checkedAnswers.me[currentQuestionNumber].isCorrect ? 'correct' : 'wrong';
        }

        if (checkedAnswers.me[currentQuestionNumber].correctAnswer === answer) {
          return 'correct';
        }
      }

      return '';
    }

    if (checkedAnswers.me[currentQuestionNumber] || checkedAnswers.opponent[currentQuestionNumber]) {
      if (checkedAnswers.me[currentQuestionNumber].answer === answer) {
        return checkedAnswers.me[currentQuestionNumber].isCorrect ? 'correct' : 'wrong';
      }

      if (checkedAnswers.opponent[currentQuestionNumber].answer === answer) {
        return checkedAnswers.opponent[currentQuestionNumber].isCorrect ? 'correct' : '';
      }

      if (checkedAnswers.me[currentQuestionNumber].correctAnswer === answer) {
        return 'correct';
      }
    }

    return '';
  };

  let priceDisclaimer = price_disclaimer && process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
    <>
      <div className={`quiz-page__disclaimers-price-disclaimer${show_disclaimer ? '-with-disclaimer' : ''}`}>
        {price_disclaimer}
      </div>
      {show_disclaimer && <hr className="quiz-page__disclaimers-line-between-disclaimers" />}
    </>
  );

  const doublePointsDisclaimer = (
    <>
      <div className="quiz-page__image-large-container__double-points-title-container">
        <span className="quiz-page__image-large-container__double-points-title">{formatMessage('X2 kudos')}</span>
      </div>
    </>
  );

  const showDisclaimerCondition = () => {
    switch (true) {
      case isBelarus():
        return product_question;
      default:
        return show_disclaimer;
    }
  };

  switch (type.id) {
    case QUIZ_TYPES_CONSTANTS.CLASSIC_QUIZ: {
      return (
        <div>
          <div className="quiz-page__game-question-text" style={{ color: theme.color }}>
            {text}
          </div>
          <div className={classnames('quiz-page__image-large-container')}>
            {double_points && doublePointsDisclaimer}
            <img
              key={id}
              src={image}
              className={classnames(
                `quiz-page__image-large${showDisclaimerCondition() ? '-with-disclaimer' : ''}`,
                withBoosters ? 'quiz-page__image-large__with-boosters' : null,
                double_points ? 'quiz-page__image-large__double-points' : null,
              )}
              alt="question_image"
              style={{ maxWidth: `${imageMaxWidth}px` }}
            />
            <div
              className={classnames(
                'quiz-page__disclaimers',
                double_points ? 'quiz-page__disclaimers__double-points' : null,
              )}
              style={{ maxWidth: `${imageMaxWidth}px` }}
            >
              {priceDisclaimer}
              {showDisclaimerCondition() && getDisclaimer(brandId, formatMessage, brand?.disclaimer)}
            </div>
          </div>
          <div className="quiz-page__answers" style={{ maxWidth: `${imageMaxWidth}px` }}>
            {answers.map((i, index) => {
              let answerColor = getAnswerColor(index + 1);

              return (
                <div
                  className={classnames(`quiz-page__answer`, {
                    disabled: myVariant && myVariant !== index + 1,
                  })}
                  onClick={() => onAnswerClicked(index + 1)}
                  style={{ height: answerHeight + 4, width: answerWidth }}
                  key={`${i}${index}`}
                >
                  <AnswerComponent
                    className={`quiz-page__answer-svg ${answerColor}`}
                    width={answerWidth}
                    height={answerHeight}
                    pathDeep={5}
                    pathWidth={14}
                    answerColor={answerColor}
                    myAnswer={myVariant === index + 1}
                    opponentAnswer={opponentsVariant === index + 1}
                    text={i}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    case QUIZ_TYPES_CONSTANTS.TRUE_OR_FALSE: {
      return (
        <div>
          <div className="quiz-page__game-question-text" style={{ color: theme.color }}>
            {text}
          </div>
          <div className={classnames('quiz-page__image-large-container')}>
            {double_points && doublePointsDisclaimer}
            <img
              key={id}
              src={image}
              className={classnames(
                `quiz-page__image-large-max${showDisclaimerCondition() ? '-with-disclaimer' : ''}`,
                withBoosters ? 'quiz-page__image-large-max__with-boosters' : null,
                double_points ? 'quiz-page__image-large-max__double-points' : null,
              )}
              alt="question_image"
              style={{ maxWidth: `${imageMaxWidth}px` }}
            />
            <div
              className={classnames(
                'quiz-page__disclaimers',
                double_points ? 'quiz-page__disclaimers__double-points' : null,
              )}
              style={{ maxWidth: `${imageMaxWidth}px` }}
            >
              {priceDisclaimer}
              {showDisclaimerCondition() && getDisclaimer(brandId, formatMessage, brand?.disclaimer)}
            </div>
          </div>
          <div className="quiz-page__answers" style={{ maxWidth: `${imageMaxWidth}px` }}>
            {answers.map((i, index) => {
              let answerColor = getAnswerColor(index + 1);

              return (
                <div
                  className={classnames(`quiz-page__answer`, {
                    disabled: myVariant && myVariant !== index + 1,
                  })}
                  onClick={() => onAnswerClicked(index + 1)}
                  style={{ height: answerHeight + 3, width: answerWidth }}
                  key={i}
                >
                  <AnswerComponent
                    className={`quiz-page__answer-svg ${answerColor}`}
                    width={answerWidth}
                    height={answerHeight}
                    pathDeep={5}
                    pathWidth={14}
                    answerColor={answerColor}
                    myAnswer={myVariant === index + 1}
                    opponentAnswer={opponentsVariant === index + 1}
                    text={i}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
    case QUIZ_TYPES_CONSTANTS.PICK_PICKTURE: {
      return (
        <div>
          <div className="quiz-page__game-question-text" style={{ color: theme.color }}>
            {text}
          </div>
          <div style={{ display: 'block' }} className="quiz-page__image-large-container">
            {doublePointsDisclaimer && double_points}
            <div
              key={id}
              className={classnames(
                'quiz-page__answers-pictures',
                double_points ? 'quiz-page__answers-pictures__double-points' : null,
              )}
            >
              {answers.map((i, index) => {
                let answerColor = getAnswerColor(index + 1);

                return (
                  <div className="quiz-page__image-answer-container" key={i}>
                    <div
                      className="quiz-page__image-answer-container_my-answer"
                      style={{ borderLeft: myVariant === index + 1 ? `5px solid ${theme.triangleColor}` : '' }}
                    />
                    <div
                      className={`quiz-page__answered-image${'-' + answerColor}`}
                      onClick={() => onAnswerClicked(index + 1)}
                    />
                    <div
                      style={{ backgroundImage: `url(${i})` }}
                      className={classnames(
                        `quiz-page__image-answer`,
                        answerColor,
                        showDisclaimerCondition() ? 'with-disclaimer' : '',
                      )}
                    />
                    <div
                      className="quiz-page__image-answer-container_opponent-answer"
                      style={{
                        borderRight: opponentsVariant === index + 1 ? `5px solid ${theme.triangleColor}` : '',
                      }}
                    />
                    {showDisclaimerCondition() &&
                      getDisclaimer(brandId, formatMessage, brand?.disclaimer, 'quiz-page__answer-disclaimer')}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    default:
      return 'default';
  }
};

import React from 'react';
import classNames from 'classnames';

import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { IUser, UserStatus } from '../../../../redux/moderation/moderationTypes';

import styles from './UserCard.module.scss';

const StatusBadge: Record<UserStatus, { className: string; statusLabel: string }> = {
  [UserStatus.Active]: {
    className: styles.activeStatus,
    statusLabel: 'Active',
  },
  [UserStatus.WaitingModeration]: {
    className: styles.waitingModerationStatus,
    statusLabel: 'Waiting moderation',
  },
  [UserStatus.Blocked]: {
    className: styles.blockedStatus,
    statusLabel: 'Blocked',
  },
};

type Props = {
  user: IUser;
  handleUser: (id: number) => void;
  isChecked: boolean;
};

export const UserCard = ({ user, handleUser, isChecked }: Props) => {
  const { formatMessage } = useInternationalization();
  const { id, name, status, outlet_code, surname } = user;

  const { className: statusClass, statusLabel } = StatusBadge[status];

  const isWaitingModeration = status === UserStatus.WaitingModeration;

  const handleChange = (id: number) => {
    handleUser(id);
  };

  return (
    <div className={styles.card} style={{ border: isWaitingModeration ? '0.5px solid #ffac30' : 'none' }}>
      <div className={styles.infoWithCheckbox}>
        {isWaitingModeration && (
          <div className={styles.checkboxContainer}>
            <label className={styles.label}>
              <input
                className={styles.input}
                type="checkbox"
                value={id}
                checked={isChecked}
                onChange={() => handleChange(id)}
              />
              <span className={styles.checkmark} />
            </label>
          </div>
        )}
        <div className={styles.userInfo}>
          <div className={styles.name}>
            {name} <br /> {surname}
          </div>
          <div className={styles.outlet}>{outlet_code}</div>
        </div>
      </div>
      <div className={classNames(styles.status, statusClass)}>{formatMessage(statusLabel)}</div>
    </div>
  );
};

import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import './NonAuthorizedRoute.scss';

import { Routes } from '../../const';
import { DisclaimerFooter } from '../disclaimerFooter/disclaimerFooter';
import { ScrollBlock } from '../common/ScrollBlock';

export const NonAuthorizedRoute = (props) => {
  const { component: Component, isAuthorized, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isAuthorized) {
          return (
            <div className="non-auth-route themed-background-image">
              <ScrollBlock fromTop>
                <Component {...routeProps} />
                <DisclaimerFooter withoutNavigation />
              </ScrollBlock>
            </div>
          );
        }
        return <Redirect to={Routes.MainRoute} />;
      }}
    />
  );
};

NonAuthorizedRoute.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

import React from 'react';
import styles from './SearchAnimation.module.scss';

type PropsType = {
  shuffledImagesArray: ShuffledImagesArray[];
};
type ShuffledImagesArray = {
  id: number;
  imageUrl: string;
};

const SearchAnimation = ({ shuffledImagesArray }: PropsType) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {shuffledImagesArray.map((image) => (
          <img key={image.id} className={styles.opponentImage} src={image.imageUrl} alt="opponent image" />
        ))}
      </div>
    </div>
  );
};

export default SearchAnimation;

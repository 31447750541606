import axios from 'axios';
import { API_URL } from './baseApi';

export const achievementsApi = {
  getAchievements: async () => {
    return await axios.get(`${API_URL}/v1/achievements`);
  },
  getAchievementById: async (id) => {
    return await axios.get(`${API_URL}/v1/achievements/${id}`);
  },
  claimReward: async (id) => {
    return await axios.post(`${API_URL}/v1/achievements/tasks/${id}/reward/claim`);
  },
};

import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Routes } from '../../../const';
import { LoaderContainer } from '../../../components/common';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { getIsFetching, selectMysteryShoppers } from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import { mysteryShopperActions } from '../../../redux/mysteryShopper/mysteryShopperSlice';
import { IMysteryShopper } from '../../../redux/mysteryShopper/mysteryShopperTypes';
import { MysteryShopperCard } from '../MysteryShopperCard/MysteryShopperCard';

import styles from './MysteryShopperList.module.scss';

export const MysteryShopperList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const mysteryShoppers = useSelector(selectMysteryShoppers);
  const isFetching = useSelector(getIsFetching);

  const onCardClick = (id: number) => {
    dispatch(mysteryShopperActions.getMysteryShopperQuestions({ mysteryShopperId: id }));
    dispatch(mysteryShopperActions.setMysteryShopperId({ mysteryShopperId: id }));
  };

  const onBackArrowClick = () => {
    history.push(Routes.MainRoute);
  };

  useEffect(() => {
    dispatch(mysteryShopperActions.getMysteryShoppers());
  }, []);

  return (
    <LoaderContainer isLoading={isFetching}>
      <div className={styles.container}>
        <BackArrowButton
          withoutTheme
          onHistoryReplaceCallback={onBackArrowClick}
          customClass={styles.backButton}
          buttonColor="black"
        />
        {mysteryShoppers &&
          mysteryShoppers.map(({ end_date, id, background_image, name }: IMysteryShopper) => (
            <MysteryShopperCard
              key={id}
              id={id}
              name={name}
              onCardClick={onCardClick}
              background_image={background_image}
              end_date={end_date}
            />
          ))}
      </div>
    </LoaderContainer>
  );
};

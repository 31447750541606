import React from 'react';

import { LinearProgressBar } from '../../../../components';
import { ComponentLike } from '../../../../types';

import styles from './TaskCard.module.scss';

type Props = {
  description: string;
  StatusIcon?: ComponentLike;
  onTaskClick: () => void;
  completedTasksCountWithoutProgress: number;
};

const SalesTipsTask = ({ description, StatusIcon, onTaskClick, completedTasksCountWithoutProgress }: Props) => (
  <div className={styles.task} onClick={onTaskClick}>
    <div className={styles.taskTitle}>{description}</div>
    {StatusIcon && <StatusIcon className={styles.statusIcon} />}
    <LinearProgressBar allTasksCount={1} completedTasksCount={completedTasksCountWithoutProgress} />
  </div>
);

export default SalesTipsTask;

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { getGameModeSelector } from '../../redux/settings/settingsSelector';
import { analyticHelper } from '../../helpers/analyticHelper';

import { Button } from '../../components/common/Button';
import { Header } from '../../components/common/Header';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { LottieWrapper } from '../../components/lootieWrapper/lootieWrapper';

import { GAME_MODES } from '../../redux/settings/settingsConstants';
import noBonusGame from './noBonusGame.json';

import './noBonusGamesInfo.scss';

export const NoBonusGamesInfo = (props) => {
  const { match, history } = props;
  const { brandId } = match.params;
  const { formatMessage } = useInternationalization();
  const gameMode = useSelector(getGameModeSelector);

  const setNoBonusGamesMessageRead = useCallback(() => {
    if (gameMode === GAME_MODES.SOLO) {
      history.replace(`/solo-mode/${brandId}`);
      analyticHelper.soloModeFunGame();
    } else {
      history.replace(`/quiz/${brandId}`);
      analyticHelper.funGame();
    }
  }, [brandId, history, gameMode]);
  const goBackCallback = useCallback(() => history.replace('/main'), [history]);

  return (
    <div className="no-bonus-games-info-page">
      <ScrollBlock fromTop>
        <Header customClass="no-bonus-games-info-page__header" goBackCallback={goBackCallback} />
        <div
          className="no-bonus-games-info-page__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
        >
          <LottieWrapper
            options={options}
            style={{
              height: '340px',
            }}
          />
          <div className={'no-bonus-games-info-page__bottom-text'}>
            <p className="no-bonus-games-info-page__content-area-caption">
              {formatMessage('No bonus games available')}
            </p>
            {gameMode === GAME_MODES.HEAD_TO_HEAD && (
              <p
                className={classnames(
                  'no-bonus-games-info-page__content-area-caption',
                  'no-bonus-games-info-page__content-area-description',
                )}
              >
                {formatMessage('But you still can play and earn points to improve your position in leaderboards')}
              </p>
            )}

            <div className={'no-bonus-games-info-page__button-container'}>
              <Button onClick={() => setNoBonusGamesMessageRead()}>{formatMessage('Got it')}</Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: noBonusGame,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

import axios from 'axios';
import { API_URL } from './baseApi';

export const mysteryShopperApi = {
  getMysteryShopper: async () => {
    return await axios.get(`${API_URL}/v3/mystery-shopper`);
  },
  getMysteryShopperQuestions: async (mysteryShopperId: number) => {
    return await axios.get(`${API_URL}/v3/mystery-shopper/${mysteryShopperId}`);
  },
  sendAnswer: async (question_id: number, criteria_id: number, user_answers: string[], user_images: string[]) => {
    return await axios.post(`${API_URL}/v3/mystery-shopper`, { question_id, criteria_id, user_answers, user_images });
  },
  getMysteryShopperResult: async (mysteryShopperId: number) => {
    return await axios.get(`${API_URL}/v3/mystery-shopper/result/${mysteryShopperId}`);
  },
};

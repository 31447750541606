import { all, put, takeLatest } from '@redux-saga/core/effects';
import { select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { chatApi } from '../../../api/chatApi';
import { MESSAGE_LIMIT } from '../chatConstants';
import { ChatRole } from '../chatTypes';
import { mapMessages } from '../chatServices';
import { notificationsActions } from './notificationsSlice';
import { notificationsSelectors } from './notificationsSelectors';

function* fetchMessages(action: PayloadAction<{ offset: number }>) {
  const { offset } = action.payload;

  yield put(notificationsActions.setIsFetching(true));

  try {
    const { data } = yield chatApi.getMessages({
      limit: MESSAGE_LIMIT,
      offset,
      role: ChatRole.Administrator,
      isNotification: true,
    });

    const messages = mapMessages(data.messages);

    yield put(notificationsActions.messagesReceived(messages));

    const total: number = yield select(notificationsSelectors.selectTotal);

    yield put(notificationsActions.setHasMore(total < data.total));
  } finally {
    yield put(notificationsActions.setIsFetching(false));
  }
}

export function* notificationsSagas() {
  yield all([takeLatest(notificationsActions.fetchMessages.type, fetchMessages)]);
}

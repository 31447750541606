import { RootState } from '../../store';
import { groupBy, formatDate } from '../../../helpers';

import { messageAdaptor } from './supportChatSlice';
import { ChatMessage } from '../chatTypes';
import { descDateComparer } from '../chatServices';

const messageSelectors = messageAdaptor.getSelectors((state: RootState) => state.chat.supportChat.messages);

const selectHasMore = (state: RootState): boolean => state.chat.supportChat.hasMore;
const selectIsFetching = (state: RootState): boolean => state.chat.supportChat.isFetching;
const selectIsImageUploading = (state: RootState): boolean => state.chat.supportChat.isImageUploading;

const selectMessagesByDate = (state: RootState) =>
  Object.entries(
    groupBy<ChatMessage>(messageSelectors.selectAll(state), (message) =>
      formatDate(new Date(message.createdAt), 'dd MMM yyyy'),
    ),
  ).sort(([aDate, [aMessage]], [bDate, [bMessage]]) => descDateComparer(aMessage.createdAt, bMessage.createdAt));

export const supportChatSelectors = {
  selectHasMore,
  selectIsFetching,
  selectIsImageUploading,
  selectMessagesByDate,
  ...messageSelectors,
};

import { Button } from '../Button';

import './ArrowButton.scss';
import { NextButton } from '../../../icons/next-button';

export const ArrowButton = ({ customClass, withoutTheme, onClick = () => {}, ...otherProps }) => {
  return (
    <Button withoutTheme customClass={customClass ? customClass : 'arrow-button'} {...otherProps} onClick={onClick}>
      <NextButton {...otherProps} />
    </Button>
  );
};

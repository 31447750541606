import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { profileActions } from '../../../redux/profile/profileSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getCoinsCount } from '../../../helpers/coinFormatter';
import { getUserBalanceSelector, getUserInfoSelector } from '../../../redux/profile/profileSelectors';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';
import { SettingsIcon } from '../../../icons';
import { ProfilePhoto } from '../ProfilePhoto/ProfilePhoto';

import { renderUserInfoBlock } from './userInfoBlock';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../config';
import '../profile.scss';

export const UserProfileBaseComponent = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const coins = useSelector(getUserBalanceSelector);
  const userInfo = useSelector(getUserInfoSelector);
  const userInfoData = renderUserInfoBlock(userInfo);
  const username = isFeatureEnabled(CONFIG_KEYS.SHOW_USERNAME_INSTEAD_OF_FIRST_NAME_ENABLED)
    ? userInfo.name
    : userInfo.username;

  return (
    <div className="profile-page__content-info-block">
      {isFeatureEnabled(CONFIG_KEYS.SHOW_PROFILE_CONFIG_ICON) && (
        <div
          className="profile-page__content-info-block-settings-button"
          onClick={() => {
            amplitudeHelper.openProfileSettings();
            dispatch(profileActions.goToSettings());
          }}
        >
          <SettingsIcon />
        </div>
      )}
      <ProfilePhoto />
      <p className="profile-page__content-info-block-name">{username}</p>
      {isFeatureEnabled(CONFIG_KEYS.PROFILE_QUIZ_STATS_ENABLED) ? (
        <div className="profile-page__content-info-block-games-results-block-info">
          <p className="profile-page__content-info-block-games-results-block-info-caption">{formatMessage('points')}</p>
          <p
            className={classnames(
              'profile-page__content-info-block-games-results-block-info-caption',
              'profile-page__content-info-block-games-results-block-info-amount',
            )}
          >
            {getCoinsCount(coins)}
          </p>
        </div>
      ) : (
        <div className="profile-page__content-info-block-games-results-block">
          {userInfoData.map(({ text, count }) => (
            <div key={text} className="profile-page__content-info-block-games-results-block-info">
              <p className="profile-page__content-info-block-games-results-block-info-caption">{formatMessage(text)}</p>
              <p
                className={classnames(
                  'profile-page__content-info-block-games-results-block-info-caption',
                  'profile-page__content-info-block-games-results-block-info-amount',
                )}
              >
                {count}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

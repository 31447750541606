import { useSpring, animated } from 'react-spring';

import './transferTutorial.scss';

import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { TutorialFirstStep } from './tutorialFirstStep/tutorialFirstStep';
import { TutorialSecondStep } from './tutorialSecondStep/tutorialSecondStep';
import { TutorialThirdStep } from './tutorialThirdStep/tutorialThirdStep';
import { TutorialFourthStep } from './tutorialFourthStep/tutorialFourthStep';
import { TutorialFifthStep } from './tutorialFifthStep/tutorialFifthStep';
import { TutorialSixthStep } from './tutorialSixthStep/tutorialSixthStep';

const StepList = createStepListContainer(STEP_LIST_IDS.transferTutorialStepList, [
  {
    id: 0,
    Component: () => <TutorialFirstStep />,
  },
  {
    id: 1,
    Component: () => <TutorialSecondStep />,
  },
  {
    id: 2,
    Component: () => <TutorialThirdStep />,
  },
  {
    id: 3,
    Component: () => <></>,
  },
  {
    id: 4,
    Component: () => <TutorialFourthStep />,
  },
  {
    id: 5,
    Component: () => <TutorialFifthStep />,
  },
  {
    id: 6,
    Component: () => <TutorialSixthStep />,
  },
]);

export const TransferTutorial = () => {
  const contentAnimation = useSpring({
    opacity: '0.5',
    from: { opacity: '0' },
    config: { duration: 300 },
  });

  return (
    <>
      <animated.div style={contentAnimation} className="transfer-tutorial" />
      <StepList />
    </>
  );
};

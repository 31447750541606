import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { isIPhoneWithHomeBar } from '../../../../helpers/checkIsIphoneX';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getCoinsForInteractiveArticle } from '../../../../redux/knowledgeBank/knowledgeBankSelectors';
import { Routes } from '../../../../const';
import { Button } from '../../../../components/common/Button';

import styles from './InteractiveArticleResultPage.module.scss';

export const InteractiveArticleResultPage = () => {
  const { formatMessage } = useInternationalization();

  const history = useHistory();

  const manual = useSelector((state) => state.steps.interactiveArticleStepList.manual);
  const isCorrect = useSelector((state) => state.steps.interactiveArticleStepList.correct);
  const backgroundColor = useSelector((state) => state.steps.interactiveArticleStepList.backgroundColor);
  const coinsForInteractiveArticle = useSelector(getCoinsForInteractiveArticle);

  const handleButtonClick = () => {
    if (!isCorrect) {
      history.push(Routes.ArticleRoute);
    } else {
      history.push(Routes.KnowledgeBankRoute);
    }
  };

  const showResult = () => {
    if (manual) {
      return formatMessage('interactive_article.result.moderation');
    } else {
      if (isCorrect) {
        if (coinsForInteractiveArticle) {
          return formatMessage('interactive_article.result.credits');
        } else {
          return formatMessage('interactive_article.result.credits_received');
        }
      } else {
        return formatMessage('interactive_article.result.try_again');
      }
    }
  };

  return (
    <div className={styles.interactiveArticleResultPage}>
      <div className={styles.interactiveArticleResultPageContainer} style={{ background: backgroundColor }}>
        <div className={styles.content}>
          <div className={styles.title}>{formatMessage('interactive_article.result.accepted')}</div>
          <div className={styles.description}>{showResult()}</div>
        </div>
        <div className={styles.btnContainer} style={{ paddingBottom: isIPhoneWithHomeBar() ? '34px' : '' }}>
          <Button className={styles.nextButton} onClick={handleButtonClick}>
            {formatMessage('interactive_article.result.finish')}
          </Button>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { modalActions } from '../../containers/Modal/modalSlice';
import { quizRatActions } from '../../../redux/quizRate/quizRateSlice';

import like from '../../../images/like.svg';
import likePressed from '../../../images/likePressed.svg';
import dislike from '../../../images/dislike.svg';
import dislikePressed from '../../../images/dislikePressed.svg';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import styles from './QuizRateModal.module.scss';
import { quizRateSelectors } from '../../../redux/quizRate/quizRateSelector';

const QuizRateModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const [pressedButtonType, setPressedButtonType] = useState(0);

  const packIdHeadToHead = useSelector(quizRateSelectors.selectPackIdHeadToHead);
  const packIdSoloMode = useSelector(quizRateSelectors.selectPackIdSoloMode);
  const packId = packIdHeadToHead || packIdSoloMode;

  const onClick = (quizMark: number) => {
    if (pressedButtonType) {
      return;
    }
    dispatch(quizRatActions.setQuizRate({ packId, quizMark }));
    setPressedButtonType(quizMark);
    setTimeout(() => {
      dispatch(modalActions.closeModal());
    }, 300);
  };

  const customStyles = {
    position: 'absolute',
    width: '100%',
    borderRadius: '16px 16px 0 0',
    transform: 'none',
    left: '0',
    bottom: '0',
    top: 'none',
  };

  return (
    <ModalContainer modal={MODALS.quizRate} customStyles={customStyles} onClose={() => onClick(0)}>
      <div className={styles.container}>
        <span className={styles.title}>{formatMessage('Please, rate this quiz')}</span>
        <span className={styles.description}>{formatMessage('Your opinion will help us to become better')}</span>
        <div className={styles.imgContainer}>
          <img
            onClick={() => onClick(1)}
            className={styles.imgLike}
            src={pressedButtonType === 1 ? likePressed : like}
            alt="like"
          />
          <img
            onClick={() => onClick(2)}
            className={styles.imgDislike}
            src={pressedButtonType === 2 ? dislikePressed : dislike}
            alt="dislike"
          />
        </div>
      </div>
    </ModalContainer>
  );
};

export default QuizRateModal;

import { isIPhoneWithHomeBar } from '../../helpers/checkIsIphoneX';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { APP_TYPE, APP_TYPES } from '../../const/appConstants';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';

import './disclaimerFooter.scss';

export const DisclaimerFooter = ({ withoutNavigation = false, customStyle = {} }) => {
  const { formatMessage } = useInternationalization();

  const getPosition = () => {
    if (isIPhoneWithHomeBar() && withoutNavigation) {
      return '34px';
    } else if (isIPhoneWithHomeBar()) {
      return '87px';
    } else {
      return '0';
    }
  };

  const getDisclaimerStyle = () => {
    switch (process.env.REACT_APP_APP_TYPE) {
      case APP_TYPES.ARMENIA:
        return 'disclaimer-footer-armenia';
      case APP_TYPES.GEORGIA:
        return 'disclaimer-footer-georgia ';
      default:
        return `disclaimer-footer ${APP_TYPE.toLowerCase()}`;
    }
  };

  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
    if (withoutNavigation) {
      return null;
    }

    if (isIPhoneWithHomeBar()) {
      return (
        <div
          style={{
            bottom: getPosition(),
          }}
        />
      );
    }

    return (
      <div
        className="disclaimer-footer-empty"
        style={{
          bottom: getPosition(),
        }}
      />
    );
  }

  return (
    <>
      {isFeatureEnabled(CONFIG_KEYS.SHOW_FOOTER_DISCLAIMER) && (
        <>
          <div
            className={getDisclaimerStyle()}
            style={{
              ...customStyle,
              bottom: getPosition(),
            }}
          >
            {formatMessage('THIS APPLICATION IS FOR PROFESSIONAL TOBACCO RETAILERS ONLY.')}
          </div>
          {withoutNavigation && isIPhoneWithHomeBar() && (
            <div className={`disclaimer-footer-iphoneX ${APP_TYPE.toLowerCase()}`} />
          )}
        </>
      )}
    </>
  );
};

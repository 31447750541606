import { all, take, fork, call, cancel, put, delay, takeEvery, select, race } from 'redux-saga/effects';

import { GAME_ACTION_TYPES, GAME_STEPS, ANSWER_SOURCES, NOT_ANSWERED, GAME_STEPS_HUNGARY } from './gameConstants';
import { quizApi } from '../../api/quizApi';
import { gameActions } from './gameSlice';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { push } from 'connected-react-router';
import { Routes } from '../../const';
import { STEP_LIST_IDS, STEP_LIST_ACTION_TYPES } from '../../components/containers/StepList/stepListConstants';
import {
  getPackIdSelector,
  isOpponentBotSelector,
  getBotAnswerDataSelector,
  isBotAnsweredAllQuestionsSelector,
  getOpponentQuizPointsSelector,
  getMyQuizPointsSelector,
  getOpponentSelector,
  getCurrentQuestionSelector,
  getMyCurrentPointsSelector,
  getQuestionsSelector,
  getCurrentQuestionNumberSelector,
  getRoundTimeSelector,
  getAnswersSelector,
  checkCorrectAnswers,
} from './gameSelectors';
import { getBrandIdFromRouteSelector, getBonusGamesAmountSelector } from '../brands/brandSelectors';
import { getUserInfoSaga } from '../profile/profileSagas';
import { inviteActions } from '../invite/inviteSlice';
import { profileActions } from '../profile/profileSlice';
import { getActiveBoosterSelector } from '../boosters/boostersSelectors';
import { BOOSTERS_TYPES } from '../boosters/boostersConstants';
import { boostersActions } from '../boosters/boostersSlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { APP_TYPES } from '../../const/appConstants';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import { decryptAnswer } from '../../helpers/cryptCorrectAnswers';

export const noInvitePaths = [
  Routes.QuizRoute,
  Routes.SpecialQuizRoute,
  Routes.EventModeRoute,
  Routes.QuestionOfTheDayRoute,
  Routes.SoloMode,
  Routes.SingleMode,
  Routes.DailyRewardsRoute,
  Routes.ArticleRoute,
  Routes.MultibrandQuizRoute,
  Routes.GetPassportPhoto,
  Routes.AddressRegistration,
  Routes.PassportStatement,
  Routes.PassportSigning,
  Routes.PassportSuccess,
  Routes.ScenarioRoute,
];

const isHungary = process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;
export const goToGameStepAction = (stepId) =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.startQuizStepList,
    stepId,
  });

export const resetGameStepList = () => {
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.startQuizStepList,
    stepId: 1,
  });
};

function* onError() {
  yield put(push(Routes.EmptyRoute));
  yield put(modalActions.openQuizGameErrorModal());
  yield put(gameActions.reset());
}

function* runQuizGameSaga(brand_id, withBot) {
  try {
    let gameFound;

    const bonusGamesAmount = yield select(getBonusGamesAmountSelector, brand_id);

    if (!withBot && bonusGamesAmount !== 0) {
      gameFound = yield call(searchOpponentSaga, brand_id);
    }

    if (!gameFound) {
      gameFound = yield call(searchBotSaga, brand_id);
    }

    if (!gameFound) {
      console.error('Bot not found');
      return yield call(onError);
    }

    yield put(gameActions.setGameFound(gameFound));
    if (isHungary)
      yield put(
        gameActions.inviteOpponent({
          onSuccess: yield put(goToGameStepAction(GAME_STEPS_HUNGARY.LOADING)),
        }),
      );

    if (!isHungary) {
      yield put(goToGameStepAction(GAME_STEPS.OPPONENT));
      yield take(GAME_ACTION_TYPES.OPPONENT_INVITED_SUCCESS);
      yield put(goToGameStepAction(GAME_STEPS.INVITE_ACCEPTED));
    } else {
      yield take(GAME_ACTION_TYPES.OPPONENT_INVITED_SUCCESS);
      yield put(goToGameStepAction(GAME_STEPS_HUNGARY.INVITE_ACCEPTED));
    }
    yield delay(3000);

    try {
      yield call(startQuizSaga);
    } catch (err) {
      console.error('startQuiz error', err);
      return yield call(onError);
    }

    yield delay(0);

    if (!isHungary) {
      yield put(goToGameStepAction(GAME_STEPS.GAME));
    } else {
      yield put(goToGameStepAction(GAME_STEPS_HUNGARY.GAME));
    }

    yield call(gameAnswersRegistrationSaga);
    yield call(finishQuizSaga);

    if (!isHungary) {
      yield put(goToGameStepAction(GAME_STEPS.RESULT));
    } else {
      yield put(goToGameStepAction(GAME_STEPS_HUNGARY.RESULT));
    }

    yield put(profileActions.getUserInfo());

    return true;
  } catch (err) {
    console.error('playQuizSaga: ', err);
  }
}

function* leaveGameSaga() {
  try {
    yield call(finishQuizSaga, true);
    yield put(gameActions.setGamePoints());
    yield put(goToGameStepAction(GAME_STEPS.RESULT));
    if (isHungary) yield put(goToGameStepAction(GAME_STEPS_HUNGARY.RESULT));

    yield put(profileActions.getUserInfo());
  } catch (err) {
    console.error('leaveGameSaga: ', err);
  }
}

function* searchOpponentSaga(brand_id) {
  const delayTime = Math.floor(Math.random() * 2000) + 1000;
  yield delay(delayTime);

  try {
    const { data } = yield call(quizApi.searchUser, brand_id);

    return { bot: false, opponent: data.user, pack_id: data.pack_id };
  } catch (err) {
    console.log(err);
    return null;
  }
}

function* searchBotSaga(brand_id) {
  try {
    const delayTime = Math.floor(Math.random() * 500) + 500;
    yield delay(delayTime);
    const brandId = yield select(getBrandIdFromRouteSelector);
    const bonusGameAmount = yield select(getBonusGamesAmountSelector, brandId);
    const { data } = yield call(quizApi.searchBot, brand_id, !!bonusGameAmount);

    return { bot: true, opponent: data.bot, pack_id: data.pack_id };
  } catch (err) {
    console.log(err);
    return null;
  }
}

function* inviteOpponentSaga({ payload: { onFailure } }) {
  try {
    const isOpponentBot = yield select(isOpponentBotSelector);
    if (isOpponentBot) {
      yield delay(1000);
      yield put(gameActions.opponentInvitedSuccess());
    } else {
      const pack_id = yield select(getPackIdSelector);
      const brand_id = yield select(getBrandIdFromRouteSelector);
      const { opponent } = yield select(getOpponentSelector);
      yield call(quizApi.inviteOpponent, opponent.id, pack_id, brand_id);
      amplitudeHelper.h2hInvite(opponent.id, pack_id);
      const [, rejected, noResponse] = yield race([
        take(GAME_ACTION_TYPES.OPPONENT_INVITED_SUCCESS),
        take(GAME_ACTION_TYPES.OPPONENT_INVITED_FAILED),
        delay(5000),
      ]);

      if (rejected || noResponse) {
        if (isHungary) {
          yield put(gameActions.reset());
          yield call(searchBotSaga, brand_id);
          yield put(gameActions.run({ brand_id, withBot: true }));
          yield call(quizApi.startQuizWithBot, pack_id);
        } else {
          onFailure();
        }
      }
    }
  } catch (err) {
    console.error('inviteOpponentSaga: ', err);
  }
}

function* startQuizSaga() {
  const isOpponentBot = yield select(isOpponentBotSelector);
  const packId = yield select(getPackIdSelector);

  let quiz;
  if (isOpponentBot) {
    const { data } = yield call(quizApi.startQuizWithBot, packId);
    quiz = data;
  } else {
    const { data } = yield call(quizApi.startQuiz, packId);
    quiz = data;
  }

  yield put(gameActions.setQuestions(quiz));
}

function* finishQuizSaga(hasUserLeft) {
  const isOpponentBot = yield select(isOpponentBotSelector);
  const pack_id = yield select(getPackIdSelector);
  const brand_id = yield select(getBrandIdFromRouteSelector);
  const user_points = yield select(getMyQuizPointsSelector);
  const opponent_points = yield select(getOpponentQuizPointsSelector);
  const answers = yield select(getAnswersSelector);
  const questions = yield select(getQuestionsSelector);
  const { opponent } = yield select(getOpponentSelector);
  const bonusGamesAmount = yield select(getBonusGamesAmountSelector, brand_id);

  const checkedAnswers = checkCorrectAnswers(answers.me, questions);
  let response;
  const answersStats = [];

  checkedAnswers.forEach((answer) => {
    answersStats.push({ answer_id: answer.answer - 1, correct: answer.isCorrect });
  });

  yield put(gameActions.setIsFetching(true));

  try {
    if (isOpponentBot) {
      response = yield call(quizApi.finishQuizWithBot, {
        pack_id,
        type: brand_id,
        user_points: user_points,
        bot_id: opponent.id,
        bot_points: opponent_points,
        answers: answersStats,
        has_user_left: hasUserLeft,
      });
    } else {
      response = yield call(quizApi.finishQuiz, {
        pack_id,
        type: brand_id,
        user_points: user_points,
        user2_id: opponent.id,
        user2_points: opponent_points,
        answers: answersStats,
        has_user_left: hasUserLeft,
      });
    }

    yield amplitudeHelper.h2hQuizFinish(answersStats, pack_id, response.data.coins);
    yield put(gameActions.quizRatesOpenOrCloseModal(response.data.is_estimation_window_shown));

    yield put(gameActions.setFinishedNotBonusGame(bonusGamesAmount));
    yield put(gameActions.setCoinsForGame(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(gameActions.setIsFetching(false));
  }
}

function* gameAnswersRegistrationSaga() {
  try {
    const questions = yield select(getQuestionsSelector);
    const rounds = getQuizRounds(questions);
    const roundTime = yield select(getRoundTimeSelector);
    for (let roundNumber = 0; roundNumber < rounds.length; roundNumber++) {
      const [{ payload: answer1 }, [opponentAnswerAction, notAnswered]] = yield all(rounds[roundNumber](roundTime));
      const opponentAnswer = notAnswered
        ? { source: ANSWER_SOURCES.OPPONENT, answer: NOT_ANSWERED }
        : opponentAnswerAction.payload;
      yield put(gameActions.setAnswers([answer1, opponentAnswer]));
      yield put(gameActions.setTimerPaused(true));
      yield put(gameActions.setGamePoints());
      yield delay(2500);
      if (roundNumber !== rounds.length - 1) {
        yield put(gameActions.setTimerStoped(true));
        yield put(gameActions.nextQuestion());
        yield put(gameActions.setTimerPaused(false));
        yield put(gameActions.setTimerStoped(false));
      }
    }
  } catch (err) {
    console.error('gameAnswersSaga: ', err);
  }
}

function* subscribeToOpponentAnswersSaga() {
  try {
    const isOpponentBot = yield select(isOpponentBotSelector);

    if (isOpponentBot) {
      const bgSyncTask = yield fork(botAnswerThreadSaga);
      yield take(GAME_ACTION_TYPES.RESET);
      yield cancel(bgSyncTask);
    } else {
      // subscription to opponent's answers
    }
  } catch (err) {
    console.error('subscribeToOpponentAnswersSaga: ', err);
  }
}

function* botAnswerThreadSaga() {
  do {
    const isBotAnsweredAllQuestions = yield select(isBotAnsweredAllQuestionsSelector);

    if (isBotAnsweredAllQuestions) {
      break;
    }

    const { bot_answer, bot_timer } = yield select(getBotAnswerDataSelector);
    yield delay(bot_timer * 1000);
    yield put(
      gameActions.setAnswer({
        source: ANSWER_SOURCES.OPPONENT,
        answer: decryptAnswer(bot_answer),
      }),
    );
    yield put(
      gameActions.setPointsForThisQuestion({ source: ANSWER_SOURCES.OPPONENT, time: new Date(), answer: bot_answer }),
    );

    yield take(GAME_ACTION_TYPES.NEXT_QUESTION);
  } while (true);
}

function* gameMainThreadSaga() {
  // const action = yield take(GAME_ACTION_TYPES.RUN);

  while (true) {
    const {
      payload: { brand_id, withBot },
    } = yield take(GAME_ACTION_TYPES.RUN);

    // starts the task in the background
    const bgSyncTask = yield fork(runQuizGameSaga, brand_id, withBot);

    // wait for the user stop action
    yield take(GAME_ACTION_TYPES.RESET);

    // this will cause the forked bgSync task
    yield cancel(bgSyncTask);
  }
}

function* onSetMyAnswerSaga({ payload }) {
  const { id, points } = payload;
  const currentQuestion = yield select(getCurrentQuestionSelector);
  const opponent = yield select(getOpponentSelector);
  const activeBooster = yield select(getActiveBoosterSelector);

  if (activeBooster === BOOSTERS_TYPES.SECOND_CHANCE) {
    const isCorrect = currentQuestion.correct_answers.indexOf(id);
    if (isCorrect === -1) {
      return yield put(boostersActions.secondChanceUsed(id));
    }
  }

  yield put(gameActions.setPointsForThisQuestion({ source: ANSWER_SOURCES.ME, time: new Date(), answer: id, points }));
  yield put(gameActions.setAnswer({ source: ANSWER_SOURCES.ME, answer: id }));

  const myCurrentPoints = yield select(getMyCurrentPointsSelector);
  yield put(
    inviteActions.sendAnswer({
      answer: id,
      questionInfo: currentQuestion,
      opponent_id: opponent.opponent.id,
      points: myCurrentPoints,
    }),
  );
}

function* onSetOpponentAnswerSaga({ payload }) {
  const { answer } = payload;
  const questions = yield select(getQuestionsSelector);
  const currentQuestion = yield select(getCurrentQuestionNumberSelector);

  if (questions && Number.isInteger(currentQuestion)) {
    if (answer.question_id === questions[currentQuestion].id) {
      yield put(
        gameActions.setPointsForThisQuestion({
          source: ANSWER_SOURCES.OPPONENT,
          points: answer.points,
          answer: answer.answer,
        }),
      );
      yield put(gameActions.setAnswer({ answer: answer.answer, source: ANSWER_SOURCES.OPPONENT }));
    }
  }
}

const locationSelector = (state) => state.router.location.pathname;

function* onInviteSaga({ payload }) {
  const { user, pack_id, brand_id, request_id } = payload;
  const path = yield select(locationSelector);

  const notAvailablePath = noInvitePaths.find((element) => path.includes(element));
  if (notAvailablePath) {
    return;
  }

  yield put(gameActions.setInviteData({ user, pack_id, brand_id, request_id }));
  yield put(push(`/invitation/${brand_id}`));

  const {
    payload: { isAccepted },
  } = yield take(GAME_ACTION_TYPES.ON_INVITE_ANSWER);

  if (isAccepted) {
    yield put(inviteActions.acceptInvite({ user, request_id }));
    yield put(gameActions.setGameFound({ opponent: user, pack_id }));

    yield put(push(`/quiz/${brand_id}`));
    yield take(STEP_LIST_ACTION_TYPES.INIT_STEP_LIST);
    if (!isHungary) {
      yield put(goToGameStepAction(GAME_STEPS.INVITE_ACCEPTED));
    } else {
      yield put(goToGameStepAction(GAME_STEPS_HUNGARY.INVITE_ACCEPTED));
    }
    yield delay(3000);

    try {
      yield call(startQuizSaga);
    } catch (err) {
      console.error('startQuiz error', err);
      return yield call(onError);
    }

    yield delay(0);

    if (!isHungary) {
      yield put(goToGameStepAction(GAME_STEPS.GAME));
    } else {
      yield put(goToGameStepAction(GAME_STEPS_HUNGARY.GAME));
    }

    yield call(gameAnswersRegistrationSaga);
    yield call(finishQuizSaga);

    if (!isHungary) {
      yield put(goToGameStepAction(GAME_STEPS.RESULT));
    } else {
      yield put(goToGameStepAction(GAME_STEPS_HUNGARY.RESULT));
    }

    yield call(getUserInfoSaga);
  } else {
    yield put(inviteActions.declineInvite({ user, request_id }));
    yield put(gameActions.reset());
  }
}

function* sagas() {
  yield fork(gameMainThreadSaga);
  yield all([
    takeEvery(GAME_ACTION_TYPES.INVITE_OPPONENT, inviteOpponentSaga),
    takeEvery(GAME_ACTION_TYPES.SUBSCRIBE_TO_OPPONENT_ANSWERS, subscribeToOpponentAnswersSaga),
    takeEvery(GAME_ACTION_TYPES.ON_INVITE, onInviteSaga),
    takeEvery(GAME_ACTION_TYPES.SET_MY_ANSWER, onSetMyAnswerSaga),
    takeEvery(GAME_ACTION_TYPES.SET_OPPONENT_ANSWER, onSetOpponentAnswerSaga),
    takeEvery(GAME_ACTION_TYPES.LEAVE_GAME, leaveGameSaga),
  ]);
}

export const gameSagas = sagas;

function getQuizRounds(questions) {
  const amIAnswering = ({ type, payload }) => {
    if (type !== GAME_ACTION_TYPES.SET_ANSWER) return;
    return payload.source === ANSWER_SOURCES.ME;
  };

  const isOpponentAnswering = ({ type, payload }) => {
    if (type !== GAME_ACTION_TYPES.SET_ANSWER) return;
    return payload.source === ANSWER_SOURCES.OPPONENT;
  };

  const questionsThread = [];

  for (let i = 0; i < questions.length; i++) {
    const round = (roundTime) => {
      return [take(amIAnswering), race([take(isOpponentAnswering), delay(roundTime * 1000)])];
    };
    questionsThread.push(round);
  }

  return questionsThread;
}

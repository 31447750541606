export const analyticHelper = {
  setUserId: (userId) => {
    window.gtag('set', { user_id: userId });
  },
  firstLaunch: () => {
    window.gtag('event', 'first_launch');
  },
  sessionLaunch: () => {
    window.gtag('event', 'session_launch');
  },
  mainPageOpen: () => {
    window.gtag('event', 'main_open');
  },
  mainPageChallenge: () => {
    window.gtag('event', 'click', {
      event_category: 'main_challenge',
    });
  },
  topicSelect: (topicId) => {
    window.gtag('event', 'click', {
      event_category: 'topic_select',
      event_label: topicId,
    });
  },
  inviteToQuiz: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_invite',
    });
  },
  waitingOpponent: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_opponent',
    });
  },
  quizNewRequest: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_new_request',
    });
  },
  quizResultPage: () => {
    window.gtag('event', 'quiz_result');
  },
  quizQuestionOpen: (questionNumber) => {
    window.gtag('event', 'quiz_question', {
      event_category: 'quiz_question',
      event_label: questionNumber,
    });
  },
  quizNewGame: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_new_game',
    });
  },
  quizBackToMain: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_back_to_main',
    });
  },
  quizTapToExit: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_tap_to_exit',
    });
  },
  invitePageOpened: () => {
    window.gtag('event', 'quiz_invitation');
  },
  acceptInvite: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_invitation_accept',
    });
  },
  declineInvite: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_invitation_decline',
    });
  },
  funGame: () => {
    window.gtag('event', 'click', {
      event_category: 'quiz_fun_game',
    });
  },
  openBonusShop: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_open',
    });
  },
  bonusCategorySelect: (categoryId) => {
    window.gtag('event', 'click', {
      event_category: 'category_select',
      event_label: categoryId,
    });
  },
  bonusProviderSelect: (provider, points) => {
    window.gtag('event', 'click', {
      event_category: 'provider_select',
      event_label: provider,
      value: points,
    });
  },
  bonusOrder: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_order',
    });
  },
  goToPrizes: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_order_prizes',
    });
  },
  backToBonusShop: () => {
    window.gtag('event', 'click', {
      event_category: 'bonus_shop_order_bonus_shop',
    });
  },
  challengeOpen: () => {
    window.gtag('event', 'challenge_open');
  },
  challengePhoto: () => {
    window.gtag('event', 'challenge_photo');
  },
  chanceOpen: () => {
    window.gtag('event', 'chance_open');
  },
  chanceButton: () => {
    window.gtag('event', 'click', {
      event_category: 'chance_button',
    });
  },
  chancePic: () => {
    window.gtag('event', 'click', {
      event_category: 'chance_pic',
    });
  },
  profileOpen: () => {
    window.gtag('event', 'profile_open');
  },
  profileRating: (ratingType) => {
    window.gtag('event', 'click', {
      event_category: 'profile_rating_city',
      event_label: ratingType,
    });
  },
  profileUserGuide: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_guide',
    });
  },
  profileGameRules: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_rules',
    });
  },
  profilePrivacyPolicy: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_policy',
    });
  },
  profileTnC: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_terms',
    });
  },
  editProfileOpen: () => {
    window.gtag('event', 'profile_edit');
  },
  themeSelect: (themeId) => {
    window.gtag('event', 'click', {
      event_category: 'color_select',
      event_label: themeId,
    });
  },
  profileEditSave: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_save',
    });
  },
  profileEditInviteCode: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_invite_code',
    });
  },
  profileEditLogout: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_logout',
    });
  },
  profileEditLogoutOK: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_logout_ok',
    });
  },
  profileLogoutCancel: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_logout_cancel',
    });
  },
  profileEditDelete: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_delete',
    });
  },
  profileEditDeleteOK: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_delete_ok',
    });
  },
  profileEditDeleteCancel: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_delete_cancel',
    });
  },
  profileTMCard: () => {
    window.gtag('event', 'click', {
      event_category: 'profile_edit_tm_card',
    });
  },
  balanceOpen: () => {
    window.gtag('event', 'balance_open');
  },
  balancePromocode: () => {
    window.gtag('event', 'balance_promocode');
  },
  promocodeActivate: () => {
    window.gtag('event', 'click', {
      event_category: 'promocode_activate',
    });
  },
  promocodeTryAgain: () => {
    window.gtag('event', 'click', {
      event_category: 'promocode_error',
    });
  },
  promocodeBackToBalance: () => {
    window.gtag('event', 'click', {
      event_category: 'promocode_success_balance',
    });
  },
  prizesOpen: () => {
    window.gtag('event', 'prizes_open');
  },
  chatOpen: () => {
    window.gtag('event', 'chat_open');
  },
  chatMessageClick: () => {
    window.gtag('event', 'click', {
      event_category: 'chat_text',
    });
  },
  chatArticleOpen: () => {
    window.gtag('event', 'chat_link');
  },
  chatPicOpen: () => {
    window.gtag('event', 'chat_pic');
  },
  chatSpecialQuizOpen: () => {
    window.gtag('event', 'chat_special_quiz');
  },
  specQuizQuestion: (questionNumber) => {
    window.gtag('event', 'special_quiz_question', {
      event_category: 'special_quiz_question',
      event_label: questionNumber,
    });
  },
  specialQuizBonusShop: () => {
    window.gtag('event', 'click', {
      event_category: 'special_quiz_bonus_shop',
    });
  },
  onMainScreenOpenPage: () => {
    window.gtag('event', 'main_screen_open');
  },
  onMainScreenBoxClick: (activity, id) => {
    window.gtag('event', 'click', {
      event_category: 'main_box',
      event_label: activity,
      value: id,
    });
  },
  onMainScreenFocusClick: (activity, id) => {
    window.gtag('event', 'click', {
      event_category: 'main_focus',
      event_label: activity,
      value: id,
    });
  },
  onMainScreenItemClick: (activity, id) => {
    window.gtag('event', 'click', {
      event_category: 'main_item',
      event_label: activity,
      value: id,
    });
  },
  onMainScreenModuleClick: (activity, id) => {
    window.gtag('event', 'click', {
      event_category: 'main_modules',
      event_label: activity,
      value: id,
    });
  },
  fromMainScreenH2H: (id) => {
    window.gtag('event', 'click', {
      event_category: 'main_with_user_quiz',
      value: id,
    });
  },
  fromMainScreenSolo: (id) => {
    window.gtag('event', 'click', {
      event_category: 'main_solo_quiz',
      value: id,
    });
  },
  progressPageOpen: () => {
    window.gtag('event', 'progress_page_open');
  },
  progressPageFAQ: () => {
    window.gtag('event', 'click', {
      event_category: 'progress_page_help',
    });
  },
  progressPageActivity: (activity, state) => {
    window.gtag('event', 'click', {
      event_category: 'progress_page_help',
      event_label: activity,
      value: state,
    });
  },
  selectKBCategory: (id) => {
    window.gtag('event', 'click', {
      event_category: 'knowledge_bank_category',
      value: id,
    });
  },
  multibrandQuestion: (number) => {
    window.gtag('event', 'multibrand_question', {
      event_category: 'multibrand_question',
      value: number,
    });
  },
  multibrandQuizResult: () => {
    window.gtag('event', 'multibrand_result');
  },
  multibrandQuestFinishBonus: () => {
    window.gtag('event', 'multibrand_bonus_game');
  },
  multibrandQuestFinishNonBonus: () => {
    window.gtag('event', 'multibrand_non_bonus_game');
  },
  startMultibrandQuiz: (id) => {
    window.gtag('event', 'click', {
      event_category: 'multibrand_quiz_start',
      value: id,
    });
  },
  startSurvey: (id) => {
    window.gtag('event', 'click', {
      event_category: 'knowledge_bank_survey',
      value: id,
    });
  },
  surveyResult: () => {
    window.gtag('event', 'survey_result');
  },
  soloModeQuestions: (number) => {
    window.gtag('event', 'solo_question', {
      event_category: 'solo_question',
      value: number,
    });
  },
  soloModeResult: () => {
    window.gtag('event', 'solo_result');
  },
  soloModeNewGame: () => {
    window.gtag('event', 'click', {
      event_category: 'solo_new_game',
    });
  },
  soloModeBackToMain: () => {
    window.gtag('event', 'click', {
      event_category: 'solo_main',
    });
  },
  soloModeFunGame: () => {
    window.gtag('event', 'click', {
      event_category: 'solo_fun_game',
    });
  },
  mainscreenInviteCodeOpen: () => {
    window.gtag('event', 'click', {
      event_category: 'mainscreen_invitecode_open',
    });
  },
  mainscreenInviteCodeCopied: () => {
    window.gtag('event', 'click', {
      event_category: 'mainscreen_invitecode_copied ',
    });
  },
  mainscreenAchievementsOpen: () => {
    window.gtag('event', 'click', {
      event_category: 'achievments_main_open',
    });
  },
  achievementProfileOpen: () => {
    window.gtag('event', 'click', {
      event_category: 'achievments_profile_open',
    });
  },
  achievementTasksOpen: (id) => {
    window.gtag('event', 'achievment_tasks_open', {
      value: id,
    });
  },
  achievementTutorialStart: () => {
    window.gtag('event', 'click', {
      event_category: 'achievment_start_tutorial',
    });
  },
  achievementTutorialSkip: () => {
    window.gtag('event', 'click', {
      event_category: 'achievment_skip_tutorial',
    });
  },
  achievementTutorialFinish: () => {
    window.gtag('event', 'click', {
      event_category: 'achievment_finish_tutorial',
    });
  },
  achievementNewLevel: (number) => {
    window.gtag('event', 'click', {
      event_category: 'achievment_new_level_ok ',
      value: number,
    });
  },
  boostersBonusShopOpen: () => {
    window.gtag('event', 'boosters_bonus_shop_open');
  },
  boostersExchangeOpen: () => {
    window.gtag('event', 'click', {
      event_category: 'boosters_exchange_open',
    });
  },
  useBooster: (booster_id, quiz_id) => {
    window.gtag('event', 'click', {
      event_category: 'booster_used',
      booster_id,
      quiz_id,
    });
  },

  lepJoinCompetition: () => {
    window.gtag('event', 'click', {
      event_category: 'lep_join_competition',
    });
  },
  lepAllAgreementCheckbox: () => {
    window.gtag('event', 'click', {
      event_category: 'lep_all_agreement_checkbox',
    });
  },
  lepSuccessfulValidationCode: () => {
    window.gtag('event', 'click', {
      event_category: 'lep_successful_validation_code',
    });
  },
  lepProceed: () => {
    window.gtag('event', 'click', {
      event_category: 'lep_proceed',
    });
  },
  lepDataUserPart: () => {
    window.gtag('event', 'click', {
      event_category: 'lep_data_user_part',
    });
  },
  openArticle: () => {
    window.gtag('event', 'click', {
      event_category: 'open_article',
    });
  },
  openBannerPage: () => {
    window.gtag('event', 'click', {
      event_category: 'open_banner_page',
    });
  },
  readArticle: () => {
    window.gtag('event', 'click', {
      event_category: 'read_article',
    });
  },
  openInteractiveArticle: () => {
    window.gtag('event', 'click', {
      event_category: 'open_interactive_article',
    });
  },
  passInteractiveArticle: () => {
    window.gtag('event', 'click', {
      event_category: 'pass_interactive_article',
    });
  },
  notBonusReadingInteractiveArticle: () => {
    window.gtag('event', 'click', {
      event_category: 'not_bonus_reading_interactive_article',
    });
  },
  onOpenChallenge: (questName) => {
    window.gtag('event', 'click', {
      event_category: 'quest_open',
      value: questName,
    });
  },
  onTaskClick: (taskDescription) => {
    window.gtag('event', 'click', {
      event_category: 'task_open',
      value: taskDescription,
    });
  },
  addUnicardNumber: () => {
    window.gtag('event', 'click', {
      event_category: 'unicard_Start',
    });
  },
  unicardSuccessTransfer: () => {
    window.gtag('event', 'click', {
      event_category: 'Unicard_success',
    });
  },
  onQuickTipAnswerClick: () => {
    window.gtag('event', 'click', {
      event_category: 'quick_tips',
    });
  },
  onOpenAR: (userId) => {
    window.gtag('event', 'click', {
      event_category: 'ar_open_module',
      userId,
    });
  },
  onOpenProductInfoPage: (userId) => {
    window.gtag('event', 'click', {
      event_category: 'ar_open_product_info_event',
      userId,
    });
  },
  onOpenBarcodePage: (userId, barcode) => {
    window.gtag('event', 'click', {
      event_category: 'ar_barcode_sent_event',
      userId,
      barcode,
    });
  },
  onARPermissionDecline: (userId) => {
    window.gtag('event', 'click', {
      event_category: 'ar_camera_permission_decline_screen',
      userId,
    });
  },
  onArAnimationScreen: (userId) => {
    window.gtag('event', 'click', {
      event_category: 'ar_set_animation_screen',
      userId,
    });
  },
  onArNotSupportedScreen: (userId) => {
    window.gtag('event', 'click', {
      event_category: 'ar_not_supported_device_screen',
      userId,
    });
  },
  onTakePackPhotoScreen: (userId) => {
    window.gtag('event', 'click', {
      event_category: 'ar_take_not_supported_pack_photo_screen',
      userId,
    });
  },
  afterPurchasing: (path) => {
    window.gtag('event', 'click', {
      event_category: path,
    });
  },
};

export const MAIN_SCREEN_ACTIVITY_LABELS = {
  CHALLENGE: 'Challenge',
  CHANCE: 'Chance',
  KB_ARTICLE: 'KB Article',
  KB_CATEGORY: 'KB Category',
  SPECIAL_QUIZ: 'Special quiz',
  SOLO: 'Solo',
  DIRECTIONAL_SOLO: 'Directional Solo',
  H2H: 'Head2Head',
  DIRECTIONAL_H2H: 'Directional Head2Head',
  KB_GAME: 'KB Game',
  SURVEY: 'Survey',
  MB_QUIZ: 'Multibrand Quiz',
  BONUS_SHOP: 'Bonus Shop',
  GENERAL_QUIZZES: 'General Quizzes',
  QOD: 'QoD',
  PROMO_CODE: 'Promo code',
  CHECK_IN: 'Check-in',
  SALES_TIPS: 'Sales Tips',
  SINGLE_MODE: 'Single Mode',
  QUICK_TIPS: 'Quick Tips',
};

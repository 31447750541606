import { connect, useDispatch } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import classnames from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';

import { RootState } from '../../../redux/store';
import { finCode, lengthMinMax, required, validateDateFormat } from '../../../helpers/formValidations';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { idCardActions } from '../../../redux/idCard/idCardSlice';
import { appTimeZone } from '../../../const/appConstants';

import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { Input } from '../../../components/common/Input';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';

import styles from './VerifyIdCard.module.scss';
import { isGeorgia } from '../../../helpers';

type FormValues = {
  date_of_birth: string;
  first_name: string;
  last_name: string;
  number: string;
};

type Props = {
  formValues: FormValues;
};

const VerifyIdCardComponent = ({
  invalid,
  handleSubmit,
  formValues,
  initialValues,
}: InjectedFormProps<FormValues, Props> & any) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const finNumberLength = isGeorgia() ? 11 : 7;

  const onBackArrowClick = () => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
  };

  const onButtonClick = () => {
    if (_.isEqual(formValues, initialValues)) {
      dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
    } else {
      dispatch(idCardActions.sendVerifiedIdCard(formValues));
    }
  };

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor={'black'}
      />
      <div className={styles.header}>
        <div className={styles.title}>{formatMessage('Check your data')}</div>
        <div className={styles.description}>{formatMessage('Use characters or digits')}</div>
      </div>
      <form onSubmit={handleSubmit(onButtonClick)} className={styles.form}>
        <div className={styles.content}>
          <span className={styles.label}>{formatMessage('PIN code:')}</span>
          <Input
            name="number"
            customClass={classnames(styles.input, 'enter-user-info-page__content-input')}
            validate={[required, finCode(finNumberLength, formatMessage)]}
            placeholder={formatMessage('PIN code:')}
          />
          <span className={styles.label}>{formatMessage('Name')}</span>
          <Input
            name="first_name"
            customClass={classnames(styles.input, 'enter-user-info-page__content-input')}
            validate={[required, lengthMinMax(2, 30, formatMessage)]}
            placeholder={formatMessage('Name')}
          />
          <span className={styles.label}>{formatMessage('Surname')}</span>
          <Input
            name="last_name"
            customClass={classnames(styles.input, 'enter-user-info-page__content-input')}
            validate={[required, lengthMinMax(2, 30, formatMessage)]}
            placeholder={formatMessage('Surname')}
          />
          <span className={styles.label}>{formatMessage('Date of birth')}</span>
          <Input
            name="date_of_birth"
            customClass={classnames(styles.input, 'enter-user-info-page__content-input')}
            validate={[required, validateDateFormat]}
            placeholder={formatMessage('Date of birth')}
          />
        </div>
        <ArrowButton withoutTheme customClass={''} fillcolor={'#01C1B6'} disabled={invalid} />
      </form>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { idCard } = state;

  return {
    formValues: state.form.idCardData?.values,
    initialValues: {
      first_name: idCard?.idCardData?.first_name,
      last_name: idCard?.idCardData?.last_name,
      number: idCard?.idCardData?.number,
      date_of_birth:
        idCard?.idCardData?.date_of_birth &&
        moment(idCard?.idCardData?.date_of_birth).tz(appTimeZone).format('DD.MM.YYYY'),
    },
  };
};

export const form = reduxForm({
  form: 'idCardData',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(VerifyIdCardComponent);

export const VerifyIdCard = connect(mapStateToProps)(form);

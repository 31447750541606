import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

import './managerPage.scss';

import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { getManagerInfoSelector } from '../../../redux/profile/profileSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import defaultPhoto from './default_photo.png';
import { Button } from '../../../components/common/Button';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { CAUCASUS } from '../../../const/appConstants';

export const ManagerPage = () => {
  const managerInfo = useSelector(getManagerInfoSelector);
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  if (!managerInfo) {
    return null;
  }

  return (
    <div className="manager-page">
      <div className="manager-page__top">
        <BackArrowButton customClass="manager-page__back-button" buttonColor="white" />
        <div className="manager-page__border-photo">
          <img className="manager-page__photo" src={managerInfo.photo || defaultPhoto} alt="manager_photo" />
        </div>
        <div className="manager-page__manager-name">
          {managerInfo.name} {managerInfo.surname}
        </div>

        {CAUCASUS.includes(process.env.REACT_APP_APP_TYPE) && managerInfo.account_code ? (
          <div className="manager-page__account-code-container">
            {formatMessage('Account code:')}
            <br /> <div className="manager-page__account-code">{managerInfo.account_code}</div>
          </div>
        ) : (
          managerInfo.phone && <div className="manager-page__phone-number-container">{managerInfo.phone}</div>
        )}
      </div>

      <div className="manager-page__info">
        {formatMessage('An expert who will advise on JTI products')}

        <Button
          customClass="manager-page__button"
          withoutTheme
          onClick={() => {
            dispatch(goBack());
          }}
        >
          {formatMessage('OK')}
        </Button>
      </div>

      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

import { Link } from 'react-router-dom';

import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { Header } from '../../components/common/Header';
import { Button } from '../../components/common/Button';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { LottieWrapper } from '../../components/lootieWrapper/lootieWrapper';
import successfulProductOrder from '../../images/animations/animatedStar.json';
import { Routes } from '../../const';
import { CONFIG_KEYS, isFeatureEnabled, getConfigFor } from '../../config';

import './successfulProductOrder.scss';

export const SuccessfulProductOrder = () => {
  const { formatMessage } = useInternationalization();

  const goToPrizesCallback = () => {
    analyticHelper.goToPrizes();
  };

  const analyticHelperCallback = (path) => {
    analyticHelper.afterPurchasing(path);
  };

  return (
    <div className="successful-product-order">
      <ScrollBlock fromTop>
        <Header />
        <div
          className="successful-product-order__content themed-svg-stroke"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 185px)' : 'unset' }}
        >
          <LottieWrapper
            options={options}
            style={{
              height: '340px',
            }}
          />
          <div className="successful-product-order__content-info-block">
            <p className="successful-product-order__content-info-block-text">
              {formatMessage('Hey, congratulations!')}
            </p>
            <p className="successful-product-order__content-info-block-text">{formatMessage('The gift is yours!')}</p>
          </div>
          <div className="successful-product-order__content-buttons">
            {isFeatureEnabled(CONFIG_KEYS.BONUS_SHOP_PRIZES_TAB_ENABLED) && (
              <div className="successful-product-order__content-buttons-prizes">
                <Link to={Routes.PrizesRoute} replace>
                  <Button onClick={() => goToPrizesCallback()} withoutTheme>
                    {formatMessage('Go to Prizes')}
                  </Button>
                </Link>
              </div>
            )}
            <div className="successful-product-order__content-buttons-bonus-shop">
              <Link to={getConfigFor(CONFIG_KEYS.REDIRECT_ROUTE_AFTER_PURCHASE)} replace>
                <Button onClick={() => analyticHelperCallback(getConfigFor(CONFIG_KEYS.REDIRECT_ROUTE_AFTER_PURCHASE))}>
                  {formatMessage('successfull_order_button_message')}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: successfulProductOrder,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

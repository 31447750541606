export const CheckIcon = (props) => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.81693 0.220043L3.37774 6.17621L1.17779 3.7695C0.908731 3.47516 0.470852 3.47516 0.201794 3.7695C-0.0672646 4.06385 -0.0672646 4.54288 0.201794 4.83723L2.89238 7.78068C3.02954 7.93074 3.20364 8 3.38301 8C3.56238 8 3.73648 7.92497 3.87365 7.78068L9.79821 1.29354C10.0673 0.999193 10.0673 0.52016 9.79821 0.225814C9.52387 -0.0743026 9.08599 -0.0743026 8.81693 0.220043Z"
        fill="#01C1B6"
      />
    </svg>
  );
};

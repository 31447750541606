import { useState, useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import './Lep.scss';
import lottieLepCode from './lottie_lepCode.json';

import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { CodeInput } from '../../../components/common/CodeInput';
import {
  getLepInputCodeSelector,
  getLepDataUserSelector,
  getLepSelectedCompetitionsSelector,
  getLepValidateErrorCodeSelector,
} from '../../../redux/LEP/lepSelector';
import { length, codeLep } from '../../../helpers/formValidations';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { Button } from '../../../components/common/Button';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';
import { lepApi } from '../../../api/lepAPI';
import { lepActions } from '../../../redux/LEP/lepSlice';
import { isAuthorizedSelector } from '../../../redux/auth/authSelectors';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';

const options = {
  loop: true,
  autoplay: true,
  animationData: lottieLepCode,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export let LepEnterCode = (props) => {
  const { invalid, handleSubmit, pristine } = props;
  const dispatch = useDispatch();
  const [sucsessCode, setSucsessCode] = useState(false);
  const [asyncValidateError, setAsyncValidateError] = useState(true);
  const value = useSelector(getLepInputCodeSelector);
  const syncValidateError = useSelector(getLepValidateErrorCodeSelector);
  const dataUser = useSelector(getLepDataUserSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const { id, input_background_image, accent_color, background_color, text_color, button_text_color } = useSelector(
    getLepSelectedCompetitionsSelector,
  );

  useEffect(() => {
    const fetchData = async () => {
      setAsyncValidateError(true);
      const { data } = await lepApi.validationCode(value.lepCode, id);
      setAsyncValidateError(data?.code);
    };

    if (!invalid && value) {
      fetchData();
    } else {
      setAsyncValidateError(false);
    }
  }, [invalid, id, value]);

  const handleNextStep = () => {
    dispatch(lepActions.setCode());
  };

  const prevStep = useSelector((state) => state?.steps[STEP_LIST_IDS.lepStepList]?.prevStep);

  const previosStep = () => {
    if (prevStep) {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.lepStepList,
          stepId: prevStep,
        }),
      );
    } else {
      dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.lepStepList }));
    }
  };

  const handleShowDescription = () => {
    analyticHelper.lepSuccessfulValidationCode();
    setSucsessCode(true);
  };

  return (
    <div
      className="lep-page__enter-code"
      style={{
        backgroundColor: background_color,
        backgroundImage: `url(${input_background_image})`,
      }}
    >
      <BackArrowButton
        buttonColor={text_color}
        onHistoryReplaceCallback={previosStep}
        customClass="lep-page__back-button"
      />
      <div style={{ bottom: isIPhoneWithHomeBar() ? '49px' : '15px' }} className="lep-page__enter-code-content">
        {!sucsessCode ? (
          <>
            <div className="lep-page__enter-code-content-header">Enter your code</div>
            <div className="lep-page__enter-code-content-description">Сode contains 8 uppercase letters and digits</div>

            <form onSubmit={handleSubmit} className="lep-page__enter-code-content-form">
              {pristine ? (
                <span className="lep-page__enter-code-content-form-placeholder">tap to enter the code</span>
              ) : null}
              <CodeInput
                name="lepCode"
                customClass="lep-page__code-input"
                inputContainerStyle="code-input__container"
                numberOfInputs={8}
                maxLength={1}
                typeInput="text"
                validate={[length(8), codeLep]}
                style={{ borderColor: accent_color }}
              />
              <div className="lep-page__enter-code-content-form-error">{syncValidateError || asyncValidateError}</div>
            </form>
            <Button
              customClass="lep-page__enter-code-button"
              disabled={invalid || asyncValidateError}
              withoutTheme={true}
              onClick={handleShowDescription}
              style={{
                color: button_text_color,
                backgroundColor: accent_color,
              }}
            >
              Activate
            </Button>
          </>
        ) : (
          <div className="lep-page__enter-code-content-finally">
            <div className="lep-page__enter-code-content-lottie">
              <LottieWrapper isClickToPauseDisabled options={options} width={'200px'} height={'200px'} />
            </div>
            <div className="lep-page__enter-code-content-header">
              {isAuthorized || dataUser ? 'Success! You’ve activated your code' : 'You are so close to success!'}
            </div>
            <div className="lep-page__enter-code-content-description">
              {isAuthorized || dataUser
                ? `You've now entered into this Friday's Rolling Rewards prize draw! Good Luck!`
                : 'To finally activate your first code press ‘Proceed’ to complete your entry'}
            </div>
            <Button
              customClass="lep-page__enter-code-button"
              withoutTheme={true}
              onClick={handleNextStep}
              style={{
                color: button_text_color,
                backgroundColor: accent_color,
              }}
            >
              {isAuthorized || dataUser ? 'OK' : 'Proceed'}
            </Button>
          </div>
        )}
      </div>
      {/* need refactoring? */}
      <style
        dangerouslySetInnerHTML={{
          __html: [
            '.lep-page__enter-code-content-lottie path {',
            `stroke: ${accent_color};`,
            `fill: ${accent_color};`,
            '}',
          ].join('\n'),
        }}
      />
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

LepEnterCode = reduxForm({
  form: 'lepCode',
  destroyOnUnmount: false,
  onSubmit: () => {},
})(LepEnterCode);

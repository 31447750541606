import { authActions } from '../redux/auth/authSlice';
import { store } from '../redux/store';

export async function subscribePushEvents() {
  try {
    navigator.serviceWorker.getRegistration().then(async (registration) => {
      const VAPID_PUBLIC_KEY = process.env.REACT_APP_VAPID_PUBLIC_KEY;
      if (!(registration && VAPID_PUBLIC_KEY)) {
        return;
      }
      const result = await window.Notification.requestPermission();
      if (result === 'granted') {
        console.log('Notification permission granted');

        const subscription = await registration.pushManager.subscribe({
          applicationServerKey: VAPID_PUBLIC_KEY,
          userVisibleOnly: true,
        });

        console.log('User is subscribed to push notifications');

        store.dispatch(authActions.setSubscription(subscription));
      }
    });
  } catch (error) {
    console.warn(`Push subscription error: ${(error as Error).message}.`);
  }
}

import { GoalStatus, IGoalTracker, IGoal } from '../components/GoalTracker/GoalTrackerTypes';

const sortGoalsById = (prevGoal: IGoal, nextGoal: IGoal) => (prevGoal.id < nextGoal.id ? -1 : 1);

export const getUpdatedGoalTracker = (goalTrackers: IGoalTracker[]): IGoalTracker[] => {
  return goalTrackers?.reduce((acc: IGoalTracker[], achievement: IGoalTracker) => {
    const { goals, progress } = achievement;
    const sortedGoals = goals.sort(sortGoalsById);
    let minDifference = Infinity;
    const updatedGoals = sortedGoals.map((goal: IGoal, goalIndex: number) => {
      let currentGoal = goal;
      let isActive: boolean = false;
      const nextGoal = sortedGoals[goalIndex + 1];
      const nextGoalReward = nextGoal ? nextGoal.reward : null;

      if (goal.amount >= progress) {
        const difference = goal.amount - progress;
        if (difference < minDifference) {
          isActive = true;
          minDifference = difference;
        }
      }

      currentGoal = {
        ...currentGoal,
        nextGoalReward,
        isActive,
        isLastGoal: goalIndex === achievement.goals.length - 1,
      };

      return currentGoal;
    });

    const currentGoalTracker = {
      ...achievement,
      goals: updatedGoals,
    };

    acc.push(currentGoalTracker);

    return acc;
  }, []);
};

export const getProgressWidth = (goal: IGoal, progress: number): string => {
  if (!goal.isActive && progress < goal.amount) {
    return '100%';
  } else if (!goal.isActive && progress > goal.amount) {
    return '0';
  } else {
    return `${100 - (progress / goal.amount) * 100}%`;
  }
};

export const getLastRewardedGoal = (goals: IGoal[]): IGoal | null => {
  const reversedGoals = [...goals].reverse();
  let currentGoal: IGoal | null = null;
  for (let goal of reversedGoals) {
    if (goal.status === GoalStatus.REWARDED) {
      currentGoal = goal;
      break;
    }
  }
  return currentGoal;
};

interface ModalContent {
  description: string;
  modalHeader: string;
  btnText: string;
}
export const getRewardedModalContent = (goal: IGoal): ModalContent => {
  let modalContent: ModalContent = {
    description: '',
    modalHeader: '',
    btnText: '',
  };
  modalContent.modalHeader = 'goal_tracker.modal.modal_header';
  modalContent.btnText = 'goal_tracker.modal.thanks';
  if (goal?.isLastGoal) {
    modalContent.description = 'goal_tracker.modal.final_goal_description';
  } else {
    modalContent.description = 'goal_tracker.modal.we_shared_coins';
  }
  return modalContent;
};

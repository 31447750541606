import { APP_TYPES } from '../../../const/appConstants';

const themesByAppTypes = {
  [APP_TYPES.IRELAND]: {
    1: {
      name: 'Silk Cut',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#512788',
      specialQuizBorderColor: '#B7BFC5',
    },
    2: {
      name: 'Benson & Hedges',
      color: '#000000',
      backIconColor: ' #000000',
      triangleColor: '#E2B987',
      specialQuizBorderColor: '#E30613',
    },
    3: {
      name: 'Mayfair',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#173575',
      specialQuizBorderColor: '#E30613',
    },
    4: {
      name: 'Amber Leaf',
      color: '#006633',
      backIconColor: '#000000',
      triangleColor: '#FFD400',
      specialQuizBorderColor: '#006633',
    },
    5: {
      name: 'Logic',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#6B88A0',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
  [APP_TYPES.HUNGARY]: {
    1: {
      name: 'Winston',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    2: {
      name: 'Camel',
      color: '#003C71',
      backIconColor: '#000000',
      triangleColor: '#FEDD00',
      specialQuizBorderColor: '#003C71',
    },
    3: {
      name: 'LD',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#A62024',
      specialQuizBorderColor: '#FFFFFF',
    },
    99: {
      name: 'HBJTI',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#A62024',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
    saleTip: {
      name: 'saleTip',
      background: '#01C1B6',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#01C1B6',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
  [APP_TYPES.GEORGIA]: {
    1: {
      name: 'Winston',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    2: {
      name: 'Camel',
      color: '#003C71',
      backIconColor: '#000000',
      triangleColor: '#FEDD00',
      specialQuizBorderColor: '#003C71',
    },
    4: {
      name: 'Sobranie',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#252525',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
    saleTip: {
      name: 'saleTip',
      background: '',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#01C1B6',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
  [APP_TYPES.ARMENIA]: {
    1: {
      name: 'Winston',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    2: {
      name: 'Camel',
      color: '#003C71',
      backIconColor: '#000000',
      triangleColor: '#FEDD00',
      specialQuizBorderColor: '#003C71',
    },
    3: {
      name: 'LD',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#A62024',
      specialQuizBorderColor: '#FFFFFF',
    },
    4: {
      name: 'Sobranie',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#252525',
      specialQuizBorderColor: '#FFFFFF',
    },
    5: {
      name: 'Monte Carlo',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
    saleTip: {
      name: 'saleTip',
      background: '',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#01C1B6',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
  [APP_TYPES.AZERBAIJAN]: {
    1: {
      name: 'Winston',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    2: {
      name: 'Camel',
      color: '#003C71',
      backIconColor: '#000000',
      triangleColor: '#FEDD00',
      specialQuizBorderColor: '#003C71',
    },
    3: {
      name: 'LD',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#A62024',
      specialQuizBorderColor: '#FFFFFF',
    },
    4: {
      name: 'Sobranie',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#252525',
      specialQuizBorderColor: '#FFFFFF',
    },
    5: {
      name: 'Winchester',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#A62024',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
    saleTip: {
      name: 'saleTip',
      background: '',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#01C1B6',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
  [APP_TYPES.BELARUS]: {
    1: {
      name: 'Camel',
      color: '#003C71',
      backIconColor: '#000000',
      triangleColor: '#FEDD00',
      specialQuizBorderColor: '#003C71',
    },
    2: {
      name: 'Winston',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    3: {
      name: 'Monte Carlo',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#105EB3',
      specialQuizBorderColor: '#FFFFFF',
    },
    4: {
      name: 'Sobranie',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#252525',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
    saleTip: {
      name: 'saleTip',
      background: '',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#01C1B6',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
  [APP_TYPES.DEMO]: {
    1: {
      name: 'Silk Cut',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#512788',
      specialQuizBorderColor: '#B7BFC5',
    },
    2: {
      name: 'Benson & Hedges',
      color: '#000000',
      backIconColor: ' #000000',
      triangleColor: '#E2B987',
      specialQuizBorderColor: '#E30613',
    },
    3: {
      name: 'Mayfair',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#173575',
      specialQuizBorderColor: '#E30613',
    },
    4: {
      name: 'Amber Leaf',
      color: '#006633',
      backIconColor: '#000000',
      triangleColor: '#FFD400',
      specialQuizBorderColor: '#006633',
    },
    5: {
      name: 'Logic',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#6B88A0',
      specialQuizBorderColor: '#FFFFFF',
    },
    10: {
      name: 'Health',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#FFFFFF',
      specialQuizBorderColor: '#FFFFFF',
    },
    11: {
      name: 'Lifestyle',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#FFFFFF',
      specialQuizBorderColor: '#FFFFFF',
    },
    12: {
      name: 'Fun',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#FFFFFF',
      specialQuizBorderColor: '#FFFFFF',
    },
    13: {
      name: 'Care',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#FFFFFF',
      specialQuizBorderColor: '#FFFFFF',
    },
    14: {
      name: 'Dairy',
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#FFFFFF',
      specialQuizBorderColor: '#FFFFFF',
    },
    saleTip: {
      name: 'saleTip',
      background: '',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#01C1B6',
      specialQuizBorderColor: '#FFFFFF',
    },
    default: {
      name: 'Default',
      background: '#000',
      logo: null,
      color: '#FFFFFF',
      backIconColor: '#FFFFFF',
      triangleColor: '#000',
      specialQuizBorderColor: '#FFFFFF',
    },
  },
};

export const BRAND_THEMES = themesByAppTypes[process.env.REACT_APP_APP_TYPE];

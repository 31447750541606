import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  answers: {
    me: [],
  },
  currentStage: 0,
  finishedStages: 0,
  gameData: null,
  isStarted: false,
};

const singleModeSlice = createSlice({
  name: 'singleMode',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setSingleModeStarted(state) {
      return {
        ...state,
        isStarted: true,
      };
    },
    setAnswer(state, { payload: { source, answer } }) {
      const newAnswers = { ...state.answers };
      newAnswers[source] = newAnswers[source].concat(answer);
      return {
        ...state,
        answers: newAnswers,
      };
    },
    resetAnswers(state) {
      return {
        ...state,
        answers: { ...initialState.answers },
      };
    },
    setSingleGameModeData(state, { payload }) {
      return {
        ...state,
        gameData: { ...payload },
      };
    },
    startSingleMode() {},
    finishSingleMode() {},
    finishStage() {},
    nextStage() {},
    setStage(state, { payload: { stage } }) {
      return {
        ...state,
        currentStage: stage,
      };
    },
    setFinishedStages(state, { payload: { stage } }) {
      return {
        ...state,
        finishedStages: stage,
      };
    },
  },
});

export const singleModeActions = singleModeSlice.actions;
export const singleModeReducer = singleModeSlice.reducer;

import { useSelector, useDispatch } from 'react-redux';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getModalDataSelector } from '../../../containers/Modal/modalSelectors';
import { modalActions } from '../../../containers/Modal/modalSlice';

import { ModalContainer } from '../../../containers/Modal/ModalContainer';
import { Button } from '../../../common/Button';
import { LottieWrapper } from '../../../lootieWrapper/lootieWrapper';

import { MODALS } from '../../../containers/Modal/modalConstants';
import verificationError from './verificationError.json';
import verificationErrorGeo from './verificationErrorGeo.json';

import './verificationErrorModal.scss';

const styles = {
  width: 343,
};

export const VeririficationErrorModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const {
    data: { onClose, onButtonClick, btnText, title, description, withGeo },
  } = useSelector(getModalDataSelector);

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.verificationError} onClose={onClose} customStyles={styles}>
      <div className="verification-error">
        <div>
          <LottieWrapper
            options={{ ...options, animationData: withGeo ? verificationErrorGeo : verificationError }}
            style={{
              height: '340px',
            }}
          />
        </div>
        <div className="verification-error__title">{formatMessage(title)}</div>
        <div className="verification-error__description">{formatMessage(description)}</div>
        <Button onClick={handleButtonClick} customClass="verification-error__btn">
          {formatMessage(btnText)}
        </Button>
      </div>
    </ModalContainer>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: verificationError,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const AcceptedIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="7.5" stroke="#4CBF8F" />
      <path
        d="M11.5238 5.07159L6.88234 10.0889L5.00505 8.06153C4.77545 7.81358 4.40179 7.81358 4.1722 8.06153C3.9426 8.30947 3.9426 8.71299 4.1722 8.96094L6.46816 11.4404C6.58521 11.5668 6.73377 11.6251 6.88684 11.6251C7.0399 11.6251 7.18847 11.5619 7.30551 11.4404L12.3611 5.97586C12.5907 5.72792 12.5907 5.32439 12.3611 5.07645C12.127 4.82364 11.7534 4.82364 11.5238 5.07159Z"
        fill="#4CBF8F"
      />
    </svg>
  );
};

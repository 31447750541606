import { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import './tutorialFifthStep.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';

export const TutorialFifthStep = memo(() => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    let timer;
    if (isButtonClicked) {
      timer = setTimeout(
        () =>
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.transferTutorialStepList,
              stepId: 6,
            }),
          ),
        150,
      );
    }
    if (timer) {
      return () => clearTimeout(timer);
    }
  }, [isButtonClicked, dispatch]);

  const contentAnimation = useSpring({
    right: isButtonClicked ? '600px' : '0px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      right: isButtonClicked ? '0px' : '-600px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const onArrowClick = () => {
    setIsButtonClicked(true);
  };

  return (
    <div className="transfer-tutorial-fifth-step">
      <animated.div style={contentAnimation} className="transfer-tutorial-fifth-step__content">
        <div className="transfer-tutorial-fifth-step__description">
          <p className="transfer-tutorial-fifth-step__description-step-number">4/5</p>
          <p className="transfer-tutorial-fifth-step__description-title">{formatMessage('Limits')}</p>
          <p className="transfer-tutorial-fifth-step__description-text">
            {formatMessage(
              'Number of transactions and amount of money you could cash out are limited. Tap “?” to read the details about it.',
            )}
          </p>
        </div>

        <ArrowButton customClass="transfer-tutorial-second-step__arrow-button" withoutTheme onClick={onArrowClick} />
      </animated.div>
    </div>
  );
});

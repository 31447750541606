import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Routes } from '../../../const';
import { Button } from '../../../components/common/Button';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import image from '../../../images/start-mystery-shopper.png';

import styles from './Start.module.scss';
import { selectEndDate } from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import { useTimeLeft } from '../../../hooks/useTimeLeft';

export const Start = () => {
  const history = useHistory();
  const { formatMessage } = useInternationalization();

  const endDate = useSelector(selectEndDate);

  const time = useTimeLeft(endDate);

  const onBackArrowClick = () => {
    history.goBack();
  };

  const onButtonClick = () => {
    history.push(Routes.MysteryShopperQuizPage);
  };

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor="white"
      />
      <div className={styles.wrapper}>
        <span className={styles.title}>
          {formatMessage('Mystery Shopper will be finished in')} {time}
        </span>
        <img className={styles.image} src={image} alt="Mystery Shopper" />
        <span className={styles.description}>
          {formatMessage(
            'You are being checked by a mystery shopper for a short period. Answer the questions, be great and get rewards! Tap “Start” to continue',
          )}
        </span>
      </div>
      <Button onClick={onButtonClick} withoutTheme customClass={styles.button}>
        {formatMessage('Start')}
      </Button>
    </div>
  );
};

import imgStartScreen from '../images/img-start-screen.png';
import imgPermission from '../images/img-permission.png';
import imgQuestion from '../images/img-question.png';
import { PageType } from '../types/general';
import { Routes } from '../../../const';

export const DEFAULT_NAME_UNKNOWN_DEVICES_IN_BROWSER = 'Video device';

export const STEP_START = 'STEP_START';
export const STEP_SCAN_BARCODE = 'STEP_SCAN_BARCODE';
export const STEP_TAKE_PHOTO = 'STEP_TAKE_PHOTO';
export const STEP_END = 'STEP_END';
export const START_COLOR = '#fff';
export const FOUND_COLOR = '#67F6BA';
export const WRONG_COLOR = '#FFAC30';

export const BARCODE_PAGE = 'BARCODE_PAGE';
export const ANIMATION_PAGE = 'ANIMATION_PAGE';

export const STEPS = [
  {
    id: STEP_START,
    pageType: {
      [PageType.Success]: {
        title: 'Pack recognition',
        description: 'Use your camera to learn more about the products you choose',
        centerScreen: imgStartScreen,
        button: { text: 'Continue', path: '' },
        focusFrameStatus: true,
      },
      [PageType.Error]: {},
    },
  },
  {
    id: STEP_SCAN_BARCODE,
    pageType: {
      [PageType.Success]: {
        title: 'Scan the barcode',
        description: `Please, point your camera at the barcode of the pack`,
        focusFrameStatus: true,
      },
      [PageType.Error]: {
        title: 'O-oh',
        description: `You didn't allow us to use your camera. Please, give us a permission`,
        centerScreen: imgPermission,
        button: {
          text: 'Read tutorial',
          path: Routes.RecognitionFaq,
        },
        focusFrameStatus: false,
      },
    },
  },
  {
    id: STEP_TAKE_PHOTO,
    pageType: {
      [PageType.Success]: {},
      [PageType.Error]: {
        title: '2/2 Take a photo',
        description: `Please, point your camera at the front side of the pack`,
        button: {
          text: '2/2 Take a photo',
          path: Routes.RecognitionFaq,
        },
        focusFrameStatus: false,
      },
    },
  },
  {
    id: STEP_END,
    pageType: {
      [PageType.Success]: {},
      [PageType.Error]: {
        title: `We can't recognize this pack`,
        description: `We are searching for information. Please, try to scan another pack`,
        centerScreen: imgQuestion,
        button: {
          text: 'Scan another pack',
          path: '',
        },
        focusFrameStatus: true,
      },
    },
  },
];

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty-typed';

import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { isAuthorizedSelector } from '../../../redux/auth/authSelectors';
import { getLepCodesSelector, getLepCompetitionsSelector } from '../../../redux/LEP/lepSelector';
import { lepActions } from '../../../redux/LEP/lepSlice';
import { LepChooseCompetition } from './LepChooseCompetition';
import { LepCheckboxPage } from './LepCheckboxPage';
import { LepDescription } from './LepDescription';
import { LepEnterCode } from './LepEnterCode';
import { LepEnterFields } from './LepForm';
import { LepResult } from './LepResult';
import { LepCompleteRegistration } from './LepCompleteRegistration';

import './Lep.scss';

export const lepSteps = {
  chooseCompetition: 1,
  checkboxPage: 2,
  description: 3,
  result: 4,
  enterFields: 5,
  enterCode: 6,
  completeRegistration: 7,
};
export const LepStart = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(lepActions.getCompetitions());
  }, [dispatch]);

  let steps = [
    { id: lepSteps.chooseCompetition, Component: () => <LepChooseCompetition /> },
    { id: lepSteps.checkboxPage, Component: () => <LepCheckboxPage /> },
    { id: lepSteps.description, Component: () => <LepDescription /> },
    { id: lepSteps.result, Component: () => <LepResult /> },
    { id: lepSteps.enterCode, Component: () => <LepEnterCode /> },
    { id: lepSteps.enterFields, Component: () => <LepEnterFields /> },
    { id: lepSteps.completeRegistration, Component: () => <LepCompleteRegistration /> },
  ];

  const isAuthorized = useSelector(isAuthorizedSelector);
  const competitions = useSelector(getLepCompetitionsSelector);
  const activatedCodes = useSelector(getLepCodesSelector);
  const existUserSteps = [lepSteps.chooseCompetition, lepSteps.description, lepSteps.enterCode, lepSteps.result];
  const haveActivatedCodes = [lepSteps.result, lepSteps.enterCode];

  if (competitions?.length === 1) {
    dispatch(lepActions.setSelectedCompetition({ selectedCompetition: competitions[0] }));
    steps = steps.filter((el) => lepSteps.chooseCompetition !== el.id);
  }

  if (isAuthorized) {
    steps = steps.filter((el) => existUserSteps.includes(el.id));
  }

  if (!isEmpty(activatedCodes)) {
    steps = steps.filter((step) => haveActivatedCodes.includes(step.id));
  }

  const StepList = createStepListContainer(STEP_LIST_IDS.lepStepList, steps);

  return (
    <div className="lep" style={{ lineHeight: '120%', display: 'flex' }}>
      <ScrollBlock fromTop>
        <StepList />
      </ScrollBlock>
    </div>
  );
};

import { all, takeLatest, put, delay } from '@redux-saga/core/effects';
import { goBack } from 'connected-react-router';
import { chanceApi } from '../../api/chanceApi';
import { CHANCE_ACTION_TYPES } from './chanceConstants.js';
import { chanceActions } from './chanceSlice';
import { profileActions } from '../profile/profileSlice';

function* getChanceSaga() {
  yield put(chanceActions.setIsFetching(true));
  try {
    const { data } = yield chanceApi.getChance();

    yield put(chanceActions.setChances(data.chances));
  } catch (err) {
    console.error('getChance error', err);
  } finally {
    yield put(chanceActions.setIsFetching(false));
  }
}

function* getChanceByIdSaga({ payload }) {
  yield put(chanceActions.setIsFetching(true));

  try {
    const { data } = yield chanceApi.getChanceById(payload);

    yield put(chanceActions.setChance(data));
  } catch (err) {
    console.error('getChance error', err);
  } finally {
    yield put(chanceActions.setIsFetching(false));
  }
}

function* participateSaga({ payload: { id } }) {
  try {
    yield put(chanceActions.setPlaying(true));
    const response = yield chanceApi.participate(id);

    if (response && response.status === 200 && response.data) {
      yield delay(3500);
      yield put(chanceActions.setPlaying(false));

      const { status } = response.data;
      yield put(chanceActions.setResult({ status }));
      yield put(profileActions.getUserInfo());
      if (status) {
        yield delay(4000);
      } else {
        yield delay(2900);
        yield put(goBack());
      }
      yield put(chanceActions.resetResult());
    }
  } catch (err) {
    console.error('participateSaga error', err);
  }
}

function* sagas() {
  yield all([takeLatest(CHANCE_ACTION_TYPES.GET_CHANCE, getChanceSaga)]);
  yield all([takeLatest(CHANCE_ACTION_TYPES.PARTICIPATE, participateSaga)]);
  yield all([takeLatest(chanceActions.getChanceById, getChanceByIdSaga)]);
}

export const chanceSagas = sagas;

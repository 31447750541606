import '../quizPage.scss';

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BRAND_THEMES } from '../brandThemes/brandThemes';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { SPECIAL_QUIZ_STEPS, SPECIAL_QUIZ_MODS } from '../../../redux/specialQuizes/specialQuizesConstants';
import { SpecialQuizComponent } from './specialQuizComponent';
import { SpecialQuizFinalComponent } from './specialQuizFinalComponent';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { getSpecialQuizModeSelector } from '../../../redux/specialQuizes/specialQuizSelectors';
import { getBrandInfoSelector } from '../../../redux/brands/brandSelectors';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { Routes } from '../../../const';

export const SpecialQuizPageComponent = (props) => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const specialQuizMode = useSelector(getSpecialQuizModeSelector);
  const brandId = Number(brand_id) || undefined;
  const dispatch = useDispatch();

  const brand = useSelector((state) => getBrandInfoSelector(state, brandId));

  const theme =
    specialQuizMode === SPECIAL_QUIZ_MODS.SALES_TIP
      ? BRAND_THEMES.saleTip
      : brand
      ? {
          color: brand.quiz_element_color,
          specialQuizBorderColor: brand.special_quiz_border_color,
          triangleColor: brand.triangle_color,
        }
      : BRAND_THEMES.default;

  useEffect(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.specialQuizSpetList,
        stepId: 1,
      }),
    );

    return () => {
      dispatch(
        specialQuizActions.finishSpecialQuiz({
          type: brandId,
        }),
      );
      dispatch(specialQuizActions.reset());
    };
  }, [dispatch]);

  const onLeaveCallback = useCallback(() => {
    if (brand_id === 'sale-tip') {
      history.goBack();
      return;
    }
    amplitudeHelper.specialQuizExit();
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure you want to exit?',
        description: 'Unanswered questions will be marked as incorrect',
        btnText: 'Yes, exit',
        onButtonClick: () => {
          dispatch(
            specialQuizActions.finishSpecialQuiz({
              type: brandId,
            }),
          );
        },
      }),
    );
  }, [history, brand_id]);

  const SpecialQuizStepList = createStepListContainer(STEP_LIST_IDS.specialQuizSpetList, [
    {
      id: SPECIAL_QUIZ_STEPS.GAME,
      Component: () => (
        <SpecialQuizComponent theme={theme} history={history} brandId={brandId} onLeaveCallback={onLeaveCallback} />
      ),
    },
    {
      id: SPECIAL_QUIZ_STEPS.RESULT,
      Component: () => <SpecialQuizFinalComponent history={history} theme={theme} brand={brand} />,
    },
  ]);

  return (
    <div
      className={brand ? 'quiz-page__with-background-image' : 'quiz-page'}
      style={{
        background: !brand && theme.background,
        backgroundImage: brand && brand.background_image && `url(${brand.background_image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: brand ? brand.text_color : theme.color,
      }}
    >
      <SpecialQuizStepList />
    </div>
  );
};

export const SpecialQuizPage = withRouter(SpecialQuizPageComponent);

import React from 'react';

import { useDispatch } from 'react-redux';

import { Button } from '../../common/Button';
import { stepListActions } from '../../containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../containers/StepList/stepListConstants';
import { profileActions } from '../../../redux/profile/profileSlice';
import { CONSENT_STATUSES } from '../termsConstants';

import styles from './TermsStep1.module.scss';

const TermsStep1 = () => {
  const dispatch = useDispatch();

  const onContinueClick = () => {
    dispatch(
      stepListActions.nextStep({
        stepListId: STEP_LIST_IDS.acceptNewTermsStepsList,
      }),
    );
    dispatch(profileActions.updateConsentProcessingData({ consent_processing_data: CONSENT_STATUSES.AGREE }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.textWrapper}>
        <span className={styles.title}>Ознакомьтесь с новыми условиями использования приложения</span>
        <p className={styles.description}>
          Условия использования приложения были изменены. Чтобы продолжать использовать приложение, вам нужно до
          15.11.2021 ознакомиться с новыми условиями и принять их
        </p>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button customClass={styles.continueButton} onClick={onContinueClick}>
          Продолжить
        </Button>
      </div>
    </div>
  );
};

export default TermsStep1;

import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getRandomInt } from '../../../helpers/mathHelper';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalSlice';
import { Button } from '../../common/Button';
import { Routes } from '../../../const';

import './achievementModal.scss';

const SUCCESS_TEXT = [
  'You find your rhythm, keep it to succeed',
  'You are on your way to success, keep going!',
  'You are on your way to success, keep going!',
  'Wow! It seems like nothing can stop you from another win',
  'Another win is in your pocket, congratulations!',
];

const AchievementModalComponent = ({ history }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const random = getRandomInt(0, SUCCESS_TEXT.length);
  const {
    data: { achievement_name, icon_image, level, id },
  } = useSelector(getModalDataSelector);

  const onButtonClick = () => {
    history.replace(`${Routes.SelectedAchievement}/${id}`);
    dispatch(modalActions.closeModal());
  };

  return (
    <ModalContainer modal={MODALS.achievement}>
      <div className="achievement-modal">
        <img className="achievement-modal__image" src={icon_image} alt="achievement" />
        <div className="achievement-modal__title">
          {formatMessage('{achievement_name} {level} unlocked!', { achievement_name, level })}
        </div>
        <div className="achievement-modal__sub-title">{formatMessage(SUCCESS_TEXT[random])}</div>
        <Button className="achievement-modal__btn" onClick={onButtonClick}>
          {formatMessage('Get reward')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export const AchievementModal = withRouter(AchievementModalComponent);

import { get } from 'lodash';

import { MESSAGE_TYPES } from '../const/appConstants';

export const isMultibrandQuizMessage = (type, message) => {
  return isArticleMessage(type) && get(message, 'article.is_multibrand_quiz', false);
};

export const isPromoCodeMessage = (type) => {
  return type === MESSAGE_TYPES.PROMOCODE_MESSAGE;
};

export const isArticleMessage = (type) => {
  return type === MESSAGE_TYPES.KB_ARTICLE;
};

export const isCategoryMessage = (type) => {
  return type === MESSAGE_TYPES.KB_CATEGORY;
};

export const isHappyBirthdayMessage = (type) => {
  return type === MESSAGE_TYPES.HAPPY_BIRTHDAY_MESSAGE;
};

export const isNotificationMessage = (type) => {
  return type === MESSAGE_TYPES.NOTIFICATION;
};

export const isDailyGameMessage = (type) => {
  return type === MESSAGE_TYPES.DAILY_GAME;
};

export const isVerificationMessage = (type) => {
  return type === MESSAGE_TYPES.VERIFICATION;
};

export const isChallengeMessage = (type) => {
  return type === MESSAGE_TYPES.LINK_TO_CHAT;
};

export const isPassportMessage = (type) => {
  return type === MESSAGE_TYPES.PASSPORT;
};

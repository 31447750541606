import { APP_TYPES } from './appConstants';
import WinstonLogo from '../images/winston-label.png';
import CamelLogo from '../images/camel-label.png';
import LDLogo from '../images/ld-label.png';

const IrelandRatingFilters = [
  {
    title: 'Region',
    value: 'region',
  },
  {
    title: 'Week',
    value: 'week',
  },
  {
    title: 'All',
    value: 'all',
  },
];
const HungaryRatingFilters = [
  {
    // formatMessage('City')
    title: 'City profile',
    value: 'city',
  },
  {
    // formatMessage('Week')
    title: 'Week',
    value: 'week',
  },
  {
    // formatMessage('All')
    title: 'All',
    value: 'all',
  },
];
const BelarusRatingFilters = [
  {
    // formatMessage('City')
    title: 'City profile',
    value: 'city',
  },
  {
    // formatMessage('Week')
    title: 'Week',
    value: 'week',
  },
  {
    // formatMessage('All')
    title: 'All',
    value: 'all',
  },
];
const GeorgiaRatingFilters = [
  {
    // formatMessage('Week')
    title: 'Week',
    value: 'week',
  },
  {
    // formatMessage('All')
    title: 'All',
    value: 'all',
  },
];
const ArmeniaRatingFilters = [
  {
    // formatMessage('Week')
    title: 'Week',
    value: 'week',
  },
  {
    // formatMessage('All')
    title: 'All',
    value: 'all',
  },
];
const AzerbaijanRatingFilters = [
  {
    // formatMessage('Week')
    title: 'Week',
    value: 'week',
  },
  {
    // formatMessage('All')
    title: 'All',
    value: 'all',
  },
];

export const brandRatingOptions = [
  {
    id: 0,
    // formatMessage('All games')
    title: 'All games',
    color: '#01C1B6',
    width: '170px',
  },
  {
    id: 1,
    // formatMessage('Travel & music')
    title: 'Travel & music',
    color: '#004497',
    logo: WinstonLogo,
    width: '180px',
  },
  {
    id: 2,
    // formatMessage('Art & entertainment')
    title: 'Art & entertainment',
    color: '#FEDD00',
    logo: CamelLogo,
    width: '285px',
  },
  {
    id: 3,
    // formatMessage('General knowledge')
    title: 'General knowledge',
    color: '#C8102E',
    logo: LDLogo,
    width: '250px',
  },
];

const takeRatingFilters = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      return IrelandRatingFilters;
    case APP_TYPES.HUNGARY:
      return HungaryRatingFilters;
    case APP_TYPES.GEORGIA:
      return GeorgiaRatingFilters;
    case APP_TYPES.ARMENIA:
      return ArmeniaRatingFilters;
    case APP_TYPES.AZERBAIJAN:
      return AzerbaijanRatingFilters;
    case APP_TYPES.BELARUS:
      return BelarusRatingFilters;
    default:
      return IrelandRatingFilters;
  }
};

export const RatingFilters = takeRatingFilters();

import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import './multibrandQuizPage.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import {
  getMultibrandQuizAnswersSelector,
  getMultibrandQuizQuestionsSelector,
  getMultibrandQuizResultSelector,
  isMultibrandQuizNewSelector,
} from '../../../redux/multibrandQuiz/multibrandQuizSelectors';
import { Button } from '../../../components/common/Button';
import { Routes } from '../../../const';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { getWordsFormat } from '../../../helpers/getWordsFormat';

const RESULT_TEXT = {
  // formatMessage('Amazing')
  total_win: ['Amazing(mb)'],
  // formatMessage('Good job!(mb)')
  win: ['Good job!(mb)'],
  // formatMessage('Refresh your memory and try again')
  lose: ['Refresh your memory and try again(mb)'],
};

export const MultibrandQuizFinal = (props) => {
  const { history } = props;
  const answers = useSelector(getMultibrandQuizAnswersSelector);
  const questions = useSelector(getMultibrandQuizQuestionsSelector);
  const result = useSelector(getMultibrandQuizResultSelector);
  const is_new = useSelector(isMultibrandQuizNewSelector);
  const { formatMessage } = useInternationalization();
  const checkedAnswers = checkCorrectAnswers(answers.me, questions);

  const correctAnswers = checkedAnswers.filter((i) => i.isCorrect).length;

  useEffect(() => {
    analyticHelper.multibrandQuizResult();
    if (is_new) {
      analyticHelper.multibrandQuestFinishBonus();
    } else {
      analyticHelper.multibrandQuestFinishNonBonus();
    }
  }, [is_new]);

  const onBtnClickCallback = useCallback(() => {
    if (correctAnswers > 0) {
      history.replace(Routes.BonusShopRoute);
    } else {
      history.replace(Routes.KnowledgeBankRoute);
    }
  }, [correctAnswers, history]);

  let finishButtonText = null;

  finishButtonText = correctAnswers > 0 ? formatMessage('Bonus shop(MB)') : formatMessage('Knowledge Base');

  const getResultText = () => {
    switch (correctAnswers) {
      case 0: {
        return RESULT_TEXT.lose[0];
      }
      case questions.length: {
        return RESULT_TEXT.total_win[0];
      }
      default: {
        return RESULT_TEXT.win[0];
      }
    }
  };

  return (
    <div className="multibrand-quiz-page__final">
      <div className="multibrand-quiz-page__final__body">
        <div className="multibrand-quiz-page__final__title">{formatMessage('Multibrand quiz')}</div>
        {is_new && (
          <div className="multibrand-quiz-page__final__result">
            {result.coins + ' ' + formatMessage(getWordsFormat(result.coins, 'points'))}
          </div>
        )}
      </div>

      <div className="multibrand-quiz-page__final__bottom">
        <div className="multibrand-quiz-page__final__result-text">{formatMessage(getResultText())}</div>
        <Button customClass="quiz-page__spec-quiz-result-btn" withoutTheme={true} onClick={() => onBtnClickCallback()}>
          {finishButtonText}
        </Button>
      </div>
    </div>
  );
};

import { all, call } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, takeLatest } from 'redux-saga/effects';
import { goalTrackerActions } from './goalTrackerSlice';
import { goalTrackerApi } from '../../api/goalTrackerApi';
import { getUserInfoSaga } from '../profile/profileSagas';

function* confirmReward(action: PayloadAction<number>) {
  yield put(goalTrackerActions.setIsFetching(true));
  try {
    yield goalTrackerApi.confirmReward(action.payload);
    yield call(getUserInfoSaga);
  } catch (e) {
    yield put(goalTrackerActions.setErrorMessage('Sorry we can not handle this request'));
    console.error(e);
  } finally {
    yield put(goalTrackerActions.setIsFetching(false));
  }
}

export function* watchConfirmRewardSagas() {
  yield all([takeLatest(goalTrackerActions.confirmReward, confirmReward)]);
}

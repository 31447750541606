import { createSelector } from 'reselect';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../const/brandsConstants';
import { checkCorrectAnswers } from '../game/gameSelectors';

export const getEventModeAnswersSelector = createSelector(
  [state => state.eventMode.answers],
  answers => answers,
);

export const getEventModePackIdSelector = createSelector(
  [state => state.eventMode.eventModeGame.pack.id],
  pack_id => pack_id,
);

export const getEventModeQuizData = createSelector(
  [state => state.brands.brandsInfo],
  brands => {
    return brands.find(i => i.id === QUIZ_WITHOUT_BRAND_IDS.EVENT_MODE);
  },
);

export const getEventModeGameData = createSelector(
  [state => state.eventMode.eventModeGame],
  gameData => gameData,
);

export const getEventModeQuestions = createSelector(
  [state => state.eventMode.eventModeGame],
  gameData => {
    if (gameData && gameData.pack) {
      return gameData.pack.questions;
    } else return null;
  },
);

export const getCorrectAnswersEventMode = createSelector(
  [getEventModeAnswersSelector, getEventModeQuestions],
  (answers, questions) => {
    const checkedAnwers = checkCorrectAnswers(answers.me, questions);
    return checkedAnwers.filter(i => i.isCorrect);
  },
);

export const getEventModeRewardsSelector = createSelector(
  [state => state.eventMode.eventModeGame],
  gameData => {
    if (gameData && gameData.rewards) {
      return gameData.rewards;
    } else return null;
  },
);

export const getEventModeRewardsToOpenSelector = createSelector(
  [state => state.eventMode.result],
  result => {
    if (result && result.rewards) {
      return result.rewards;
    } else return [];
  },
);

export const getEventModeKeys = createSelector(
  [state => state.eventMode.result],
  result => {
    if (result) {
      return { keys: result.keys, extra_keys: result.extra_keys };
    } else return null;
  },
);

import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { ChatMessage } from '../chatTypes';
import { ascDateComparer } from '../chatServices';

interface BAChatState {
  isFetching: boolean;
  isImageUploading: boolean;
  messages: EntityState<ChatMessage>;
  hasMore: boolean;
}

export const messageAdaptor = createEntityAdapter<ChatMessage>({
  selectId: (message) => message.id,
  sortComparer: (a, b) => ascDateComparer(a.createdAt, b.createdAt),
});

const initialState: BAChatState = {
  isFetching: true,
  isImageUploading: false,
  messages: messageAdaptor.getInitialState(),
  hasMore: false,
};

const baChatSlice = createSlice({
  name: 'baChat',
  initialState,
  reducers: {
    fetchMessages: (state, action: PayloadAction<{ offset: number }>): void => {},
    sendMessage: (state, action: PayloadAction<{ text: string; image?: object }>): void => {},
    uploadImage: (state, action: PayloadAction<{ image: object }>): void => {},
    setIsFetching: (state, action: PayloadAction<boolean>): BAChatState => ({
      ...state,
      isFetching: action.payload,
    }),
    setIsImageUploading: (state, action: PayloadAction<boolean>): BAChatState => ({
      ...state,
      isImageUploading: action.payload,
    }),
    setHasMore: (state, action: PayloadAction<boolean>): BAChatState => ({
      ...state,
      hasMore: action.payload,
    }),
    messagesReceived: (state, action: PayloadAction<ChatMessage[]>): void => {
      messageAdaptor.upsertMany(state.messages, action);
    },
    messageReceived: (state, action: PayloadAction<ChatMessage>): void => {
      messageAdaptor.upsertOne(state.messages, action);
    },
    reset: (): BAChatState => ({
      ...initialState,
    }),
  },
});

export const baChatActions = baChatSlice.actions;
export const baChatReducer = baChatSlice.reducer;

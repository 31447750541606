import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import '../profileSettings.scss';

import { Input } from '../../../components/common/Input';
import { Routes } from '../../../const';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { InfoIcon } from '../../../icons/info-icon';
import { lengthMinMax, required, username } from '../../../helpers/formValidations';
import { SetThemeComponent } from './setThemeComponent';
import { BoxRightArrow } from '../../../icons';
import { isAzerbaijan, isGeorgia } from '../../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { idCardSelectors } from '../../../redux/idCard/idCardSelectors';
import { ID_CARD_STATUS } from '../../../redux/idCard/idCardConstants';
import { useEffect } from 'react';
import { idCardActions } from '../../../redux/idCard/idCardSlice';
import { ProfilePhoto } from '../../profile/ProfilePhoto/ProfilePhoto';

const UserInfo = ({ handleSubmit, trafficCode, history, initialValues }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const idCardData = useSelector(idCardSelectors.selectIdCardData);

  const onCardInfoClick = () => {
    history.push(Routes.IdCardInfo);
  };

  useEffect(() => {
    dispatch(idCardActions.getIdCard());
  }, []);

  const isEditInputShown =
    idCardData?.status === ID_CARD_STATUS.NO_DATA ||
    idCardData?.status === ID_CARD_STATUS.MODERATION ||
    idCardData?.status === ID_CARD_STATUS.DUPLICATE_CARD;

  return (
    <form onSubmit={handleSubmit} className="edit-profile-form">
      <div className="edit-profile-form__photo">
        <ProfilePhoto />
      </div>
      <div className="profile-settings-page__content-fields">
        <p className={classnames('profile-settings-page__content-color-themes-caption', 'your-data-caption')}>
          {formatMessage('Your data:')}
        </p>
        <>
          <Input
            name="username"
            validate={[required, username, lengthMinMax(2, 15, formatMessage)]}
            customClass="profile-settings-page__input"
          />
          {trafficCode && <Input name="traffic_code" customClass="profile-settings-page__input" disabled />}
          <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
            {formatMessage('JTI representative:')}
          </p>
          <Input
            name="manager_name"
            customClass="profile-settings-page__input"
            id="managers"
            InputButton={
              <InfoIcon
                className="profile-settings-page__info-icon"
                onClick={() => {
                  analyticHelper.profileTMCard();
                  history.push(Routes.ManagerPageRoute);
                }}
              />
            }
            disabled
          />
          <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
            {formatMessage('Trade Point Info:')}
          </p>
          <Input name="city" customClass="profile-settings-page__input" disabled />
          <Input name="legal_entity" customClass="profile-settings-page__input" disabled />
          <Input name="address" customClass="profile-settings-page__input" disabled />
          {(isAzerbaijan() || isGeorgia()) && !isEditInputShown && (
            <>
              <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
                {formatMessage('Your PIN code:')}
              </p>
              <Input
                name="open"
                customClass="profile-settings-page__input"
                id="open"
                placeholder={formatMessage('Open')}
                InputButton={<BoxRightArrow onClick={onCardInfoClick} className="profile-settings-page__info-icon" />}
                disabled
              />
            </>
          )}
        </>
      </div>
      <SetThemeComponent initialValues={initialValues} />
    </form>
  );
};

const form = reduxForm({
  form: 'userInfoForm',
  destroyOnUnmount: false,
})(UserInfo);

export const UserInfoForm = withRouter(form);

import { useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, reset } from 'redux-form';
import { useSelector } from 'react-redux';

import './signupPage.scss';

import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { Button } from '../../../components/common/Button';
import { Input } from '../../../components/common/Input';
import { Select } from '../../../components/common/Select';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import {
  required,
  lettersLatin,
  lengthMinMax,
  olderThen18,
  username,
  inviteCode,
} from '../../../helpers/formValidations';

import { getPositionsSelectors } from '../../../redux/service/serviceSelectors';

const genderOptions = [
  //formatMessage('Male')
  { id: 'Male', name: 'Male' },
  //formatMessage('Female')
  { id: 'Female', name: 'Female' },
  //formatMessage('Decline to answer')
  { id: 'Decline to answer', name: 'Decline to answer' },
];

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

const SignupPageComponent = (props) => {
  const { handleSubmit, formObj, invalid } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const positions = useSelector(getPositionsSelectors);

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 1,
      }),
    );
  }, [dispatch]);

  const submit = () => {
    return dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 5,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="signup-page signup-page__caucasus">
      <div className="signup-page__top-section">
        <Button
          withoutTheme
          type="button"
          className="signup-page__back-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{formatMessage('Fill in all the fields')}</div>
        <Input
          name="name"
          placeholder={formatMessage('Name')}
          validate={[required, lettersLatin, lengthMinMax(2, 15, formatMessage)]}
          customClass="signup-page__input"
        />
        <Input
          name="surname"
          placeholder={formatMessage('Surname')}
          validate={[required, lettersLatin, lengthMinMax(2, 15, formatMessage)]}
          customClass="signup-page__input"
        />
        <Input
          name="username"
          placeholder={formatMessage('Profile name')}
          validate={[required, username, lengthMinMax(2, 15, formatMessage)]}
          customClass="signup-page__input"
        />
        <Select
          name="gender_identity"
          placeholder={formatMessage('Gender identity')}
          options={genderOptions}
          withTranslations={true}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'gender_identity')}`}
        />
        <Input
          name="date_of_birth"
          placeholder={formatMessage('Date of birth')}
          type="date"
          validate={[required, olderThen18]}
          customClass="signup-page__input"
        />
        <Select
          name="position_id"
          placeholder={formatMessage('Position')}
          options={positions}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'position_id')}`}
        />
        <Input
          name="invite_code"
          placeholder={formatMessage('Invite code (optional)')}
          customClass="signup-page__input"
          validate={[inviteCode]}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme disabled={invalid}></ArrowButton>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    formObj: form.signupForm || { values: {} },
    phone: form.enterPhoneForm.values.phone,
  };
};

const form = reduxForm({
  form: 'signupForm',
  initialValues: {},
  destroyOnUnmount: false,
  onSubmit: () => {},
})(SignupPageComponent);

export const SignupPageCaucasus = connect(mapStateToProps, null)(form);

export const LepStar = (props) => {
  return (
    <svg {...props} width="252" height="245" viewBox="0 0 252 245" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M122.503 66.6402C124.025 63.8888 127.981 63.8888 129.503 66.6401L145.053 94.7447C145.98 96.4202 147.651 97.5526 149.551 97.7926L183.534 102.087C186.964 102.52 188.266 106.807 185.656 109.075L161.661 129.92C160.043 131.326 159.298 133.488 159.706 135.593L165.458 165.221C166.081 168.43 162.8 170.99 159.838 169.607L128.542 154.989C126.933 154.237 125.073 154.237 123.464 154.989L92.168 169.607C89.2061 170.99 85.9255 168.43 86.5485 165.221L92.3047 135.57C92.7104 133.48 91.9783 131.332 90.3809 129.924L66.6943 109.057C64.1116 106.782 65.4177 102.524 68.8319 102.088L102.461 97.7952C104.358 97.553 106.026 96.4213 106.952 94.7482L122.503 66.6402Z"
        stroke="#01C1B6"
        strokeWidth="2"
      />
      <path
        d="M126 205.599V243.832"
        stroke="#01C1B6"
        strokeWidth="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M126 1.97168V26.4584"
        stroke="#01C1B6"
        strokeWidth="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M224.076 129.131H250.999"
        stroke="#01C1B6"
        strokeWidth="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 129.131H27.9229"
        stroke="#01C1B6"
        strokeWidth="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M202.922 193.999L242.826 229.225"
        stroke="#01C1B6"
        strokeWidth="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.16992 24.7402L56.285 67.2698"
        stroke="#01C1B6"
        strokeWidth="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M48.5928 194.429L9.16992 229.226"
        stroke="#01C1B6"
        strokeWidth="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M242.825 24.7402L193.787 68.9882"
        stroke="#01C1B6"
        strokeWidth="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

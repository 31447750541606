export const QUESTION_OF_THE_DAY_ACTION_TYPES = {
  START_QUESTION_OF_THE_DAY: 'questionOfTheDay/startQuestionOfTheDay',
  GO_TO_RESULT: 'questionOfTheDay/goToResult',
  SET_ANSWER: 'questionOfTheDay/setAnswer',
};

export const QUESTION_OF_THE_DAY_STEPS = {
  GAME: 1,
  RESULT: 2,
};

export const ANSWER_SOURCES = {
  ME: 'me',
};

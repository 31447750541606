import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IIdCardState {
  fileKey: string | null;
  idCardData: IIdCardData | null;
  isFetching: boolean;
  ErrorMessage: string;
}

const initialState: IIdCardState = {
  fileKey: null,
  idCardData: null,
  isFetching: false,
  ErrorMessage: '',
};

export interface IIdCardData {
  user_id: number;
  first_name: string;
  last_name: string;
  number: string;
  image: string;
  status: number;
  date_of_birth: string;
}

const idCardSlice = createSlice({
  name: 'id-card',
  initialState,
  reducers: {
    setFileKey(state, action: PayloadAction<string>) {
      state.fileKey = action.payload;
    },
    sendIdCard() {},
    sendVerifiedIdCard(state, action: PayloadAction<any>) {},
    getIdCard() {},
    setIdCardData(state, action: PayloadAction<IIdCardData>) {
      state.idCardData = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.ErrorMessage = action.payload;
    },
  },
});

export const idCardActions = idCardSlice.actions;
export const idCardReducer = idCardSlice.reducer;

import './achievementsTutorial.scss';

import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { AchievementsTutorialStart } from './achievementsTutorialStart';
import { AchievementsTutorialStep1 } from './achievementsTutorialStep1';
import { AchievementsTutorialStep2 } from './achievementsTutorialStep2';
import { AchievementsTutorialStep3 } from './achievementsTutorialStep3';
import { Routes } from '../../../const';
import { AchievementsTutorialStep4 } from './achievementsTutorialStep4';

const StepList = createStepListContainer(STEP_LIST_IDS.achievementsTutorialStepList, [
  { id: 0, Component: () => <AchievementsTutorialStart /> },
  { id: 1, Component: () => <AchievementsTutorialStep1 /> },
  { id: 2, Component: () => <AchievementsTutorialStep2 /> },
  { id: 3, Component: () => <AchievementsTutorialStep3 /> },
  { id: 4, Component: () => <AchievementsTutorialStep4 /> },
  { id: 5, Component: () => null },
]);

export const AchievementTutorial = (props) => {
  const { location } = props;
  const path = new RegExp(`${Routes.SelectedAchievement}`);

  if (localStorage.getItem('achievementsTutorialCompleted') !== 'true') {
    if (!location) {
      return null;
    } else if (location.pathname.match(path)) {
      return (
        <div>
          <StepList />
        </div>
      );
    }
  }

  return null;
};

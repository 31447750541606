import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import './Lep.scss';

import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { getLepCompetitionsSelector, getLepSelectedCompetitionsSelector } from '../../../redux/LEP/lepSelector';
import useDailyRewardsTimeLeft from '../../../hooks/useDailyRewardsTimeLeftHook';
import { formatDate, formatTime } from '../../../helpers';
import { Button } from '../../../components/common/Button';
import { Routes } from '../../../const';
import { lepSteps } from './LepStart';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';

export const LepDescription = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { draw_end_timer, end_date, rules_link, help_link, main_image, background_color, text_color } = useSelector(
    getLepSelectedCompetitionsSelector,
  );

  const competitions = useSelector(getLepCompetitionsSelector);
  // timer end draw
  const { days, hours, minutes } = useDailyRewardsTimeLeft(draw_end_timer);

  // time end competition
  const date = end_date && formatDate(new Date(end_date), 'dd:MM:y');
  const time = end_date && formatTime(new Date(end_date), { hour: 'numeric', minute: 'numeric', hour12: true });

  const previosStep = () => {
    if (competitions.length === 1) {
      history.push(Routes.MainRoute);
    } else {
      dispatch(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.lepStepList,
          stepId: lepSteps.chooseCompetition,
        }),
      );
    }
  };

  const handleSubmit = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.lepStepList,
        stepId: lepSteps.enterCode,
      }),
    );
  };

  return (
    <div
      className="lep-page__start-competition"
      style={{
        backgroundColor: background_color,
        backgroundImage: `url(${main_image})`,
      }}
    >
      <BackArrowButton
        buttonColor={text_color}
        onHistoryReplaceCallback={previosStep}
        customClass="lep-page__back-button"
      />

      <div className="lep-page__start-competition-container">
        {days || hours || minutes ? (
          <div className="lep-page__start-competition-timeLeft">
            Game closes in
            {days ? ` ${days}d` : ''}
            {hours ? ` ${hours}h` : ''}
            {minutes ? ` ${minutes}m` : ''}
          </div>
        ) : (
          ''
        )}
        <div className="lep-page__start-competition-header" style={{ color: text_color }}>
          Amber Leaf Rolling Rewards competition
        </div>
        <div className="lep-page__start-competition-description" style={{ color: text_color }}>
          Find the unique code printed on the inside of your limited edition Amber Leaf bundle
        </div>
        <Button customClass="lep-page__start-competition-button" withoutTheme={true} onClick={handleSubmit}>
          Enter the code
        </Button>
        <div className="lep-page__start-competition-competitionEnd" style={{ color: text_color }}>
          competition ends {date} at {time}
        </div>
      </div>

      <div
        style={{
          bottom: isIPhoneWithHomeBar() ? '49px' : '15px',
        }}
        className="lep-page__start-competition-links"
      >
        <a
          href={rules_link}
          rel="noopener noreferrer"
          className="lep-page__start-competition-links-rules"
          target="_blank"
        >
          Game rules
        </a>
        <a
          href={help_link}
          rel="noopener noreferrer"
          className="lep-page__start-competition-links-help"
          target="_blank"
        >
          Need Help?
        </a>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

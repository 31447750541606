import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pushNotificationApi } from '../api/pushNotificationApi';
import { authActions } from '../redux/auth/authSlice';
import { RootState } from '../redux/store';
import { isAuthorizedSelector } from '../redux/auth/authSelectors';

const usePushSubscription = () => {
  const isAuthorized = useSelector(isAuthorizedSelector);

  const subscription = useSelector((state: RootState) => state.auth.subscription as PushSubscription | null);
  const dispatch = useDispatch();

  const saveSubscription = useCallback(async () => {
    if (!subscription || !isAuthorized) {
      return;
    }

    try {
      await pushNotificationApi.save(subscription);

      dispatch(authActions.setSubscription(null));
      console.log('Push subscription was saved');
    } catch (error) {
      console.warn(`Push subscription save error: ${(error as Error).message}.`);
    }
  }, [subscription, isAuthorized]);

  useEffect(() => {
    saveSubscription();
  }, [subscription, isAuthorized]);
};

export default usePushSubscription;

import { MessageType } from './chatTypes';

export const MESSAGE_LIMIT = 15;
export const SCROLL_CONTAINER_ID = 'scroll-container';

export const MESSAGE_TYPES_WITH_MENU = [
  MessageType.Confirmation,
  MessageType.FirstLevel,
  MessageType.SecondLevel,
  MessageType.ThirdLevel,
  MessageType.CustomProblem,
];

export const CHAT_MESSAGE_TYPES = [MessageType.Chat, ...MESSAGE_TYPES_WITH_MENU];

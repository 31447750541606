import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated, useTransition } from 'react-spring';
import { withRouter } from 'react-router';

import './scenarioPage.scss';
import {
  getCurrentScenarioSelector,
  getScenarioChatMessages,
  isAnswerScreenActiveSelector,
  getPreviousStepScenarioData,
  isAnswerButtonActiveSelector,
} from '../../redux/scenario/scenarioSelectors';
import { Button } from '../../components/common/Button';
import { scenarioActions } from '../../redux/scenario/scenarioSlice';
import { SCENARIO_MESSAGE_TYPES } from '../../redux/scenario/scenarioConstants';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getWordsFormat } from '../../helpers/getWordsFormat';
import { goBack } from 'connected-react-router';

const ScenarioPageComponent = () => {
  const { formatMessage } = useInternationalization();
  const [messages, setMessages] = useState([]);

  const [selectedAnswer, setSelectedAnswer] = useState();
  const scenario = useSelector(getCurrentScenarioSelector);
  const previousStepScenario = useSelector(getPreviousStepScenarioData);
  const chatMessages = useSelector(getScenarioChatMessages);
  const isAnswerScreenActive = useSelector(isAnswerScreenActiveSelector);
  const isAnswerButtonActive = useSelector(isAnswerButtonActiveSelector);
  const [answerScreenArray, setAnswerScreenArray] = useState([]);
  const [answerButtonArray, setAnswerButtonArray] = useState([]);
  const [reactionsArray, setReactionsArray] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(scenarioActions.reset());
  }, [dispatch]);

  useEffect(() => {
    setMessages([...chatMessages]);

    return () => setMessages([]);
  }, [chatMessages]);

  useEffect(() => {
    if (isAnswerScreenActive) {
      setSelectedAnswer(null);
      setAnswerScreenArray([{ key: `${scenario.step} answer screen` }]);
    } else {
      setAnswerScreenArray([]);
    }
  }, [isAnswerScreenActive, scenario.step]);

  useEffect(() => {
    if (isAnswerButtonActive) {
      setAnswerButtonArray([{ key: `${scenario.step} answer button` }]);
    } else {
      setAnswerButtonArray([]);
    }
  }, [isAnswerButtonActive, scenario.step]);

  useEffect(() => {
    if (previousStepScenario.reaction) {
      setReactionsArray([]);
    }
  }, [previousStepScenario]);

  useEffect(() => {
    if (scenario.reaction) {
      setReactionsArray([scenario.reaction]);
    }
  }, [scenario]);

  const backCallback = useCallback(() => {
    dispatch(goBack());
    return null;
  }, [dispatch]);

  const animatedMessages = useTransition(messages, (item) => item.key, {
    from: { marginLeft: -500 },
    enter: { marginLeft: 16, paddingBottom: 1 },
    update: ({ key }) => {
      const additionalMargin = messages.length - key > 2 && messages.length > 3 ? (messages.length - key) * 25 : 0;

      if (messages[key + 1]) {
        if (messages[key + 1].text.length <= 45) {
          if (messages[key + 1].title) {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 34 + additionalMargin,
            };
          } else {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 10 + additionalMargin,
            };
          }
        }

        if (messages[key + 1].text.length <= 90) {
          if (messages[key + 1].title) {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 62 + additionalMargin,
            };
          } else {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 38 + additionalMargin,
            };
          }
        }

        if (messages[key + 1].text.length <= 135) {
          if (messages[key + 1].title) {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 80 + additionalMargin,
            };
          } else {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 56 + additionalMargin,
            };
          }
        }

        if (messages[key + 1].text.length <= 180) {
          if (messages[key + 1].title) {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 106 + additionalMargin,
            };
          } else {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 82 + additionalMargin,
            };
          }
        }

        if (messages[key + 1].text.length <= 225) {
          if (messages[key + 1].title) {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 106 + additionalMargin,
            };
          } else {
            return {
              paddingBottom: (messages.length - 1) * 50 - key * 50 + 82 + additionalMargin,
            };
          }
        }
      } else {
        return { paddingBottom: (messages.length - 1) * 50 - key * 50 };
      }

      /* if(type === SCENARIO_MESSAGE_TYPES.SELLER) {
        if(messages.length > 5) {
          return  {paddingBottom:  messages.length* 80 - key*80 - key*80}
        }
        if(key > 5) {
          return {paddingBottom:messages.length* 80 - key*80 + 15 - key*80 }
        }  else {
          return {paddingBottom:messages.length* 60 + 10 - key*60 }
        }
      } else if(type === SCENARIO_MESSAGE_TYPES.STEP) {
        if(messages.length > 4 && key < 5) {
          return {paddingBottom:  messages.length* 80 - key*80 + 25 - key*80}
        }
      if(key >= 5 && key <= 7)  {
        return  {paddingBottom:  messages.length* 80 - key*80 + 20 - key*80}
      } else if(key > 7)  {
        return  {paddingBottom:  messages.length* 80 - key*80 + 50 - key*80}
      } else {
        return  {paddingBottom:  messages.length* 60 + 10 - key*60}
      }
    } else {

      if(messages.length > 5 && key < 5) {
        return {paddingBottom:  messages.length* 80 - key*80 + 30 - key*80}
      }
        if(key >= 5 && key <= 7)  {
          return  {paddingBottom:  messages.length* 80 - key*80 + 30 - key*80}
        } else if(key > 7)  {
          return  {paddingBottom:  messages.length* 80 - key*80 + 50 - key*80}
        } else {
          return  {paddingBottom:  messages.length* 60 - key*60}
        }
      }*/
    },
    trail: 0,
    config: { mass: 1, tension: 300, friction: 50, precision: 0.01, velocity: 0 },
  });

  const animatedReactions = useTransition(
    reactionsArray,
    (item) => item,
    {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { mass: 1, tension: 110, friction: 20, precision: 0.01, velocity: 0 },
    },
    [answerButtonArray],
  );

  const answerScreenAnimation = useTransition(
    answerScreenArray,
    (item) => item,
    {
      from: { paddingBottom: -2000, opacity: 0 },
      enter: { paddingBottom: 0, opacity: 1 },
      leave: { paddingBottom: -2000, opacity: 0 },
      config: { mass: 1, tension: 300, friction: 50, precision: 0.01, velocity: 0 },
      duration: 500,
    },
    [answerScreenArray],
  );

  const answerButtonAnimation = useTransition(
    answerButtonArray,
    (item) => item,
    {
      from: { bottom: -200 },
      enter: { bottom: 30 },
      leave: { bottom: -200 },
      config: { mass: 1, tension: 300, friction: 50, precision: 0.01, velocity: 0 },
      duration: 500,
    },
    [answerButtonArray],
  );

  const reactionAnimation = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
    duration: 500,
  });

  const onAnswerClickCallback = useCallback(
    (answer, index) => {
      if (Number.isInteger(selectedAnswer)) {
        return;
      }
      setSelectedAnswer(index);
      dispatch(scenarioActions.setSellerAnswer({ answer }));
    },
    [selectedAnswer, dispatch],
  );

  const renderChatMessage = (message) => {
    if (message.item.type === SCENARIO_MESSAGE_TYPES.CUSTOMER) {
      if (message.item.title) {
        return (
          <animated.div key={message.key} style={message.props} className="scenario-page__customer-message-container">
            <div className="scenario-page__customer-messages-title">{formatMessage('Customer')}</div>
            <div className="scenario-page__customer-message">{message.item.text}</div>
          </animated.div>
        );
      } else {
        return (
          <animated.div key={message.key} style={message.props} className="scenario-page__customer-message-container">
            <div className="scenario-page__customer-message">{message.item.text}</div>
          </animated.div>
        );
      }
    }

    if (message.item.type === SCENARIO_MESSAGE_TYPES.SELLER) {
      if (message.item.title) {
        return (
          <animated.div
            key={message.key}
            style={{ ...message.props }}
            className="scenario-page__seller-message-container"
          >
            <div className="scenario-page__seller-messages-title">{formatMessage('Seller')}</div>
            <div className="scenario-page__seller-message">{message.item.text}</div>
          </animated.div>
        );
      } else {
        return (
          <animated.div
            key={message.key}
            style={{ ...message.props }}
            className="scenario-page__seller-message-container"
          >
            <div className="scenario-page__seller-message">{message.item.text}</div>
          </animated.div>
        );
      }
    }

    if (message.item.type === SCENARIO_MESSAGE_TYPES.STEP) {
      return (
        <animated.div
          key={message.key}
          style={{ ...message.props, marginBottom: 10, marginLeft: 0 }}
          className="scenario-page__step-line-container"
        >
          <div className="scenario-page__step-line" />
          <div className="scenario-page__step-text">{message.item.text}</div>
        </animated.div>
      );
    }
  };

  const renderAnswerScreen = () => {
    if (scenario.answers[0].next_question_id) {
      return (
        <>
          <div className="scenario-page__answer-screen__customer-messages">
            {scenario.text.map((item, index) =>
              index === 0 ? (
                <div key={item} className="scenario-page__answer-screen__customer-message-container">
                  <div className="scenario-page__answer-screen__customer-messages-title">
                    {formatMessage('Customer')}
                  </div>
                  <div className="scenario-page__answer-screen__customer-message">{item}</div>
                </div>
              ) : (
                <div key={item} className="scenario-page__answer-screen__customer-message-container">
                  <div className="scenario-page__answer-screen__customer-message">{item}</div>
                </div>
              ),
            )}
          </div>
          <div className="scenario-page__answer-screen__answers">
            <div className="scenario-page__answer-screen__answers-title">{formatMessage('Choose answer:')}</div>
            {scenario.answers.map((item, index) => (
              <div
                key={item.text}
                className={
                  index === selectedAnswer
                    ? `scenario-page__answer-screen__answer${item.correct ? '__correct' : '__wrong'}`
                    : 'scenario-page__answer-screen__answer'
                }
                onClick={() => onAnswerClickCallback(item, index)}
              >
                {item.text}
              </div>
            ))}
          </div>
        </>
      );
    } else {
      const finalAnswer = scenario.answers[0];
      return (
        <div className="scenario-page__answer-screen__final">
          <div>
            <div className="scenario-page__answer-screen__reaction" />

            {finalAnswer.coins > 0 && (
              <div className="scenario-page__answer-screen__coins">
                {`${finalAnswer.coins} ${formatMessage(getWordsFormat(finalAnswer.coins, 'points'))}`}
              </div>
            )}
          </div>

          <div>
            <div className="scenario-page__answer-screen__title">{finalAnswer.comment.title}</div>
            <div className="scenario-page__answer-screen__text">{finalAnswer.comment.text}</div>
            <Button className="scenario-page__answer-screen__button" onClick={backCallback}>
              {formatMessage('OK')}
            </Button>
          </div>
        </div>
      );
    }
  };

  if (!scenario || !scenario.id) {
    backCallback();
  }

  return (
    <div className="scenario-page">
      <div className="scenario-page__top">
        <div className="scenario-page__scenario-chat-container">
          {animatedMessages.map((message) => renderChatMessage(message))}
        </div>
      </div>

      <animated.div className="scenario-page__reaction-container" style={reactionAnimation}>
        {animatedReactions.map(({ item, props }) => (
          <animated.div
            key={item}
            className="scenario-page__reaction"
            style={{
              ...props,
              backgroundImage: `url(${item})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              width: window.innerHeight - 418,
            }}
          />
        ))}
      </animated.div>

      {isAnswerButtonActive &&
        answerButtonAnimation.map(({ item, props }) => (
          <animated.div key={item.key} style={props} className="scenario-page__answer-button-container">
            <div>
              <Button
                className="scenario-page__answer-button"
                onClick={() => dispatch(scenarioActions.answerButtonClick())}
              >
                {scenario.answers[0].next_question_id ? formatMessage('Answer') : formatMessage('Next')}
              </Button>
            </div>
          </animated.div>
        ))}

      {isAnswerScreenActive &&
        answerScreenAnimation.map(({ item, props }) => (
          <animated.div key={item.key} style={props} className="scenario-page__answer-screen">
            {renderAnswerScreen()}
          </animated.div>
        ))}
    </div>
  );
};

export const ScenarioPage = withRouter(ScenarioPageComponent);

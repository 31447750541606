export const ArrowIconRounded = (props) => (
  <svg width="13" height="21" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8766 0.85854C11.345 0.37245 10.5302 0.37245 9.99855 0.85854L0 10L9.99855 19.1415C10.5302 19.6276 11.345 19.6276 11.8766 19.1415C12.4801 18.5897 12.4801 17.6391 11.8766 17.0873L4.12482 10L11.8766 2.9127C12.4801 2.36095 12.4801 1.41029 11.8766 0.85854Z"
      fill={props.fill}
    />
  </svg>
);

import { memo, SyntheticEvent } from 'react';
import classnames from 'classnames';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { IAnswer } from '../../../../redux/mysteryShopper/mysteryShopperTypes';
import { selectMultipleChoiceValues } from '../../../../redux/mysteryShopper/mysteryShopperSelectors';
import { multipleChoice } from '../../../../helpers/formValidations';
import { ErrorText } from '../../../../components/common/ErrorText/ErrorText';

import styles from './MultipleChoice.module.scss';

type MultiSelectProps = {
  values: IAnswer[];
  onSelect: (value: SyntheticEvent<HTMLInputElement>) => void;
  currents: string;
  input: any;
  isCorrect: boolean;
  disabled: boolean;
  meta: Meta;
};

type Meta = {
  touched: boolean;
  error: string;
};

type InputProps = {
  values: IAnswer[];
  input: any;
  meta: Meta;
  disabled: boolean;
};

const MultiSelect = ({ values, onSelect, currents, disabled, meta: { touched, error } }: MultiSelectProps) => {
  const fieldValues = useSelector(selectMultipleChoiceValues);

  const addToMultiSelect = (selected: string[], newItem: string, callback: any) => {
    if (newItem && selected) {
      if (selected.includes(newItem)) {
        return callback(selected.filter((item: string) => item !== newItem));
      }
      selected.push(newItem);
      return callback(selected);
    }
    return false;
  };

  return (
    <>
      {values.map(({ id, answer_text, is_correct }: IAnswer) => {
        return (
          <div key={id} className={styles.container}>
            <input
              type="checkbox"
              placeholder={answer_text}
              id={`${id}`}
              onClick={() => addToMultiSelect(JSON.parse(currents), answer_text, onSelect)}
              className={styles.answerInput}
              disabled={disabled}
              checked={fieldValues?.includes(answer_text)}
            />
            <label
              className={classnames(styles.label, {
                [styles.correctAnswer]: is_correct,
                [styles.incorrectAnswer]: is_correct !== undefined && !is_correct,
                [styles.showCorrectAnswer]: is_correct,
              })}
              htmlFor={`${id}`}
            >
              {answer_text}
            </label>
          </div>
        );
      })}
      {touched && error && <ErrorText text={error} />}
    </>
  );
};

const InputComponent = ({ values, input, disabled, meta }: InputProps) => {
  const currentsValue = input.value === '' ? JSON.stringify([]) : JSON.stringify(input.value);
  const handleSelect = (selectedValue: SyntheticEvent<HTMLInputElement>) => input.onChange(selectedValue);

  return (
    <>
      <MultiSelect
        values={values}
        input={input}
        disabled={disabled}
        onSelect={handleSelect}
        currents={currentsValue}
        meta={meta}
        {...input}
      />
    </>
  );
};

export const MultipleChoice = memo(({ answers, disabled }: { answers: IAnswer[]; disabled: boolean }) => {
  const { formatMessage } = useInternationalization();

  return (
    <Field
      multi={true}
      name="multiFields"
      disabled={disabled}
      values={answers}
      component={InputComponent}
      validate={[multipleChoice(formatMessage)]}
    />
  );
});

import axios from 'axios';
import { API_URL } from './baseApi';

export const loyaltyProgramApi = {
  getLoyaltyProgramData: async () => {
    return await axios.get(`${API_URL}/v1/loyalty-programs`);
  },
  sendReceipt: async (id, photo) => {
    return await axios.post(`${API_URL}/v1/loyalty-programs`, { id, photo });
  },
  getLoyaltyProgramReward: async id => {
    return await axios.post(`${API_URL}/v1/loyalty-programs/redeem`, { id });
  },
};

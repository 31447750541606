import { useEffect, useState, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import './loyaltyProgramPage.scss';

import { getLoyaltyProgramDataSelector } from '../../redux/loyaltyProgram/loyaltyProgramSelectors';
import { loyaltyProgramActions } from '../../redux/loyaltyProgram/loyaltyProgramSlice';
import { LOYALTY_ATTACHMENTS_STATUSES } from '../../redux/loyaltyProgram/loyaltyProgramConstants';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { Header } from '../../components/common/Header';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { ExclamationPointIcon } from '../../icons/exclamation-point-icon';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { formatTimeToString, getTimeLeft } from '../../helpers/timesHelper';
import { Button } from '../../components/common/Button';
import { LoyaltyPlusIcon } from '../../icons/loyalty-plus-icon';
import { formatDate } from '../../helpers';
import { AcceptedStatusIcon } from './statusIcons/accepted';
import { FailedStatusIcon } from './statusIcons/failed';
import { OnModerationStatusIcon } from './statusIcons/moderation';
import { Routes } from '../../const';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { FAQ_ARTICLE_ID } from '../../redux/knowledgeBank/knowledgeBankConstants';

const CONTENT_MARGIN = 32;

const LoyaltyProgramPageComponent = (props) => {
  const dispatch = useDispatch();
  const { history } = props;
  const { formatMessage } = useInternationalization();
  const [productImageHeight, setProductImageHeight] = useState((window.innerWidth * 2) / 3);
  const filesInputRef = useRef(null);

  useEffect(() => {
    dispatch(loyaltyProgramActions.getLoyaltyProgramData());
  }, [dispatch]);

  useEffect(() => {
    setProductImageHeight(((window.innerWidth - CONTENT_MARGIN) * 2) / 3);
  }, []);

  const loyaltyData = useSelector(getLoyaltyProgramDataSelector);
  const {
    button_color,
    background_image,
    user_attachments,
    cover_images,
    product_image,
    end_date,
    button_text_color,
    button_plus_color,
    can_collect_reward,
    sold_products,
    sales_requirement,
    faq_link,
  } = loyaltyData;
  const text_color = button_color;

  const onFAQClick = useCallback(() => {
    dispatch(
      knowledgeBankActions.openArticle({
        article: { url: faq_link, id: FAQ_ARTICLE_ID },
      }),
    );
  }, [dispatch, faq_link]);

  const time = getTimeLeft(end_date);

  const onButtonClickCallback = useCallback(() => {
    if (can_collect_reward) {
      dispatch(loyaltyProgramActions.getReward());
    } else {
      history.replace(Routes.MainRoute);
    }
  }, [can_collect_reward, dispatch, history]);

  const handleSetNewPhoto = async () => {
    const files = filesInputRef.current.files;
    const file = files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('photo', file, file.name);
    dispatch(loyaltyProgramActions.sendReceipt(formData));
  };

  if (!cover_images) {
    return null;
  }

  const getCovers = () => {
    let coversToSkip = sold_products;
    coversToSkip += cover_images.length - sales_requirement;
    if (isTimeLeft() && can_collect_reward) {
      return;
    }
    return cover_images.map((image, index) => {
      if (!image) {
        return (
          <div
            key={`cover_${index}`}
            className="loyalty-program__cover-image"
            style={{ width: (window.innerWidth - CONTENT_MARGIN) / 3 }}
          />
        );
      }
      if (coversToSkip !== 0) {
        coversToSkip--;
        return (
          <div
            key={`cover_${index}`}
            className="loyalty-program__cover-image"
            style={{ width: (window.innerWidth - CONTENT_MARGIN) / 3 }}
          />
        );
      }
      return (
        <img
          className="loyalty-program__cover-image"
          key={`cover_${index}`}
          src={image}
          alt={`cover_${index}`}
          style={{ width: (window.innerWidth - CONTENT_MARGIN) / 3 }}
        />
      );
    });
  };

  const getAttachmentStatus = (attachment) => {
    switch (attachment.status) {
      case LOYALTY_ATTACHMENTS_STATUSES.ACCEPTED_AUTO.value:
      case LOYALTY_ATTACHMENTS_STATUSES.ACCEPTED_MANUAL.value:
        return (
          <>
            <div className="loyalty-program__attachment-status-accepted">
              {formatMessage(LOYALTY_ATTACHMENTS_STATUSES.ACCEPTED_AUTO.name)}
            </div>
            <div className="loyalty-program__attachment-icon">
              <AcceptedStatusIcon />
            </div>
          </>
        );
      case LOYALTY_ATTACHMENTS_STATUSES.FAILED_MANUAL.value:
        return (
          <>
            <div className="loyalty-program__attachment-status-failed">
              {formatMessage(LOYALTY_ATTACHMENTS_STATUSES.FAILED_MANUAL.name)}
            </div>
            <div className="loyalty-program__attachment-icon">
              <FailedStatusIcon />
            </div>
          </>
        );
      case LOYALTY_ATTACHMENTS_STATUSES.SENT.value:
      case LOYALTY_ATTACHMENTS_STATUSES.SENT_TO_MANUAL.value:
        return (
          <>
            <div className="loyalty-program__attachment-status-on-moderation">
              {formatMessage(LOYALTY_ATTACHMENTS_STATUSES.SENT.name)}
            </div>
            <div className="loyalty-program__attachment-icon">
              <OnModerationStatusIcon />
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  const isTimeLeft = () => {
    return new Date(end_date) < new Date();
  };

  const getPageTitle = () => {
    if (
      isTimeLeft() &&
      user_attachments.some(
        (i) =>
          i.status === LOYALTY_ATTACHMENTS_STATUSES.SENT.value ||
          i.status === LOYALTY_ATTACHMENTS_STATUSES.SENT_TO_MANUAL.value,
      )
    ) {
      return (
        <>
          <div style={{ color: text_color }} className="loyalty-program__title">
            {formatMessage('The time is over. We are checking the last receipts')}
          </div>
        </>
      );
    }
    if (isTimeLeft() && !can_collect_reward) {
      return (
        <>
          <div className="loyalty-program__title" style={{ color: text_color }}>
            {formatMessage('Oops!')}
          </div>
          <div className="loyalty-program__sub-title" style={{ color: text_color }}>
            {formatMessage('The time is over. Close but no cigar! Try again next time')}
          </div>
        </>
      );
    } else if (can_collect_reward) {
      return (
        <>
          <div className="loyalty-program__title" style={{ color: text_color }}>
            {formatMessage('Great!')}
          </div>
          <div className="loyalty-program__sub-title" style={{ color: text_color }}>
            {formatMessage('You’ve revealed the image. Make sure to tap “Claim Reward” to see what you’ve won!')}
          </div>
        </>
      );
    } else {
      return (
        <div className="loyalty-program__title" style={{ color: text_color }}>
          {formatMessage('Upload receipts to remove the tiles')}
        </div>
      );
    }
  };

  const renderBottomArea = () => {
    if (can_collect_reward) {
      return (
        <Button customClass="loyalty-program__final-button" onClick={onButtonClickCallback} withoutTheme>
          {can_collect_reward ? formatMessage('Claim reward') : formatMessage('Got it')}
        </Button>
      );
    } else {
      return (
        <>
          {!isTimeLeft() && (
            <div className="loyalty-program__time" style={{ color: text_color }}>
              {formatTimeToString(time)}
            </div>
          )}

          <div
            className={
              user_attachments.length > 0
                ? 'loyalty-program__receipts-uploading'
                : 'loyalty-program__receipts-uploading-hidden'
            }
          >
            {!isTimeLeft() && (
              <Button
                customClass="loyalty-program__upload-button"
                withoutTheme
                style={{ background: button_color, color: button_text_color }}
                onClick={(e) => {
                  filesInputRef.current.click();
                }}
              >
                <input
                  type="file"
                  id={'challenge-page-file'}
                  hidden={true}
                  ref={filesInputRef}
                  onChange={handleSetNewPhoto}
                  accept="image/*"
                />
                <LoyaltyPlusIcon className="loyalty-program__plus-icon" style={{ fill: button_plus_color }} />
                {formatMessage('Tap to upload receipt')}
              </Button>
            )}

            {user_attachments.length > 0 && (
              <div className="loyalty-program__attachments-container">
                {user_attachments.map((i, index) => {
                  return (
                    <div className="loyalty-program__attachment" key={`attachment_${index}`}>
                      <div className="loyalty-program__attachment__left">
                        <div className="loyalty-program__attachment-name">{i.name}</div>
                        <div className="loyalty-program__attachment-time">
                          {formatDate(new Date(i.created_at), 'dd MMM yyyy')}
                        </div>
                      </div>

                      <div className="loyalty-program__attachment__right">{getAttachmentStatus(i)}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      );
    }
  };

  return (
    <div className="loyalty-program" style={{ backgroundImage: `url(${background_image})` }}>
      <ScrollBlock fromTop>
        <Header hasBackButton buttonColor={button_color} />

        <div className="loyalty-program__top">
          <div className="loyalty-program__progress" style={{ color: text_color, borderColor: button_color }}>{`${
            sold_products > sales_requirement ? sales_requirement : sold_products
          } / ${sales_requirement}`}</div>
          <ExclamationPointIcon className="loyalty-program__faq" onClick={onFAQClick} iconColor={button_color} />
        </div>
        {getPageTitle()}
        <div
          className={
            isTimeLeft() &&
            !user_attachments.some(
              (i) =>
                i.status === LOYALTY_ATTACHMENTS_STATUSES.SENT.value ||
                i.status === LOYALTY_ATTACHMENTS_STATUSES.SENT_TO_MANUAL.value,
            )
              ? 'loyalty-program__product-container'
              : 'loyalty-program__product-container__with-border'
          }
          style={{ height: productImageHeight, borderColor: button_color }}
        >
          <img className="loyalty-program__product-image" alt="product" src={product_image} />
          <div className="loyalty-program__covers-container" style={{ height: productImageHeight }}>
            {getCovers()}
          </div>
        </div>
        {renderBottomArea()}
        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};

export const LoyaltyProgramPage = withRouter(LoyaltyProgramPageComponent);

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';

import { handleKeyUp } from '../../../helpers/inputHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ErrorText } from '../ErrorText/ErrorText';

import './CodeInput.scss';

const InnerInput = (props) => {
  const {
    customClass,
    maxLength = 1,
    typeInput = 'number',
    inputContainerStyle,
    numberOfInputs,
    meta: { touched, error },
    priceValue,
    snackbar,
    ...otherProps
  } = props;
  const [values, setValues] = useState(Array(numberOfInputs).fill(''));
  const { formatMessage } = useInternationalization();

  const handleChange = (e, index) => {
    const target = e.target;
    const value = target.value;
    let newValues = values.slice();

    if (value.length <= maxLength) {
      newValues[index] = value;
      setNewValues(newValues);
    } else if (value.length > maxLength && index < numberOfInputs - 1) {
      newValues[index + 1] = value.substr(value.length - 1, 1);
      setNewValues(newValues);
    }
  };

  const setNewValues = (newValues) => {
    setValues(newValues);
    props.input.onChange(newValues.join(''));
  };

  const inputs = values.map((value, index) => (
    <input
      type={typeInput}
      key={index}
      className={classnames('code-input_input', customClass)}
      onKeyUp={(e) => handleKeyUp(e, maxLength)}
      onChange={(e) => handleChange(e, index)}
      value={priceValue ? priceValue[index] : values[index]}
      autoComplete="off"
      {...otherProps}
    />
  ));

  return (
    <>
      <div className={inputContainerStyle}>{inputs}</div>
      {touched &&
        error &&
        (snackbar ? (
          <ErrorText text={error} />
        ) : (
          <span className={'code-input__container__error-message'}>{formatMessage(error)}</span>
        ))}
    </>
  );
};

export const CodeInput = (props) => <Field component={InnerInput} {...props} />;

CodeInput.defaultProps = {
  maxLength: 1,
};

CodeInput.propTypes = {
  name: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  numberOfInputs: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
  validate: PropTypes.any,
  inputContainerStyle: PropTypes.string,
  snackbar: PropTypes.bool,
  disabled: PropTypes.bool,
  priceValue: PropTypes.string,
};

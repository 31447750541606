export const RightArrow = (props) => {
  const { withoutTheme, customClass } = props;
  return (
    <svg
      className={withoutTheme ? customClass : 'right-arrow'}
      width="12"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M1 1L10 8.52941L1 17" strokeWidth="2" />
    </svg>
  );
};

import React from 'react';

import { useDispatch } from 'react-redux';

import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { multibrandQuizActions } from '../../../redux/multibrandQuiz/multibrandQuizSlice';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { surveyActions } from '../../../redux/survey/surveySlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { IArticle, statusType } from '../../../pages/KBBrands/KBBrandsTypes';
import { useInternationalization } from '../../../hooks/useTranslationHook';

import styles from './KBArticleCardHungary.module.scss';

type Props = {
  article: IArticle;
};

const KbArticleCardHungary = ({ article }: Props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const { is_new, brand, url, special_quiz_id, survey_id, is_multibrand_quiz, product_status, preview_image } = article;

  const onArticleClick = () => {
    dispatch(knowledgeBankActions.setCurrentArticle(article));
    if (url) {
      dispatch(knowledgeBankActions.openArticle({ article }));
      analyticHelper.openArticle();
    } else if (special_quiz_id) {
      if (is_multibrand_quiz) {
        dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id: special_quiz_id, is_new }));
      }
      dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id, brand_id: brand?.id }));
    } else if (survey_id) {
      dispatch(surveyActions.startSurvey({ survey_id, surveyStatus: is_new }));
      analyticHelper.startSurvey(survey_id);
    }
  };

  const statusMap: statusType = {
    0: { color: '', text: '' },
    1: { color: '#01C1B6', text: 'New product' },
    2: { color: '#2A7BE6', text: 'Update' },
    3: { color: '#6B1893', text: 'Change name' },
  };

  const articleStatus = statusMap[product_status];

  return (
    <div className={styles.container} onClick={onArticleClick}>
      <div className={styles.imageWrapper}>
        <img className={styles.image} src={preview_image} alt="img" />
        <span style={{ backgroundColor: articleStatus?.color }} className={styles.statusInfo}>
          {articleStatus?.text && formatMessage(`${articleStatus.text}`)}
        </span>
      </div>
      <span className={styles.text}>{article.name}</span>
    </div>
  );
};

export default KbArticleCardHungary;

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './oppaMainPage.scss';
import backgroundMainPage from './backgroundMainPage.svg';

import { Balance } from '../../../../components/common/Balance';
import { Button } from '../../../../components/common/Button';
import { Header } from '../../../../components/common/Header';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { getOppaWalletSelector, getUserBalanceSelector } from '../../../../redux/profile/profileSelectors';
import { CashoutHistory } from '../../cashoutHistory/cashoutHistory';
import { launchTimerOppaButton, getOppaTransfersSelector } from '../../../../redux/bonusShop/bonusShopSelectors';
import useTimer from '../../../../hooks/useTimerHook';
import { checkIsIphoneX } from '../../../../helpers/checkIsIphoneX';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { oppaTutorialSteps } from '../oppaTutorial/oppaTutorial';
import { Routes } from '../../../../const';
import { useHistory } from 'react-router';
import { QuestionMarkIcon } from '../../../../icons/question-mark-icon';
import { SettingsIcon } from '../../../../icons';
import { oppaSteps } from '../OPPA';
import { FAQ_ARTICLE_ID } from '../../../../redux/knowledgeBank/knowledgeBankConstants';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';
import { checkUserRole } from '../../../../helpers/checkUserRole';
import { UserRoles } from '../../../../enums';

export const linksOppa = {
  faq: 'https://api.jti-club.ge/OPPA/OPPA.html',
};

export const amountTimerOppaButton = 300000;

export const OppaMainPage = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const coins = useSelector(getUserBalanceSelector);
  const oppaWalletNumber = useSelector(getOppaWalletSelector);
  const transfers = useSelector(getOppaTransfersSelector);
  const history = useHistory();

  const firstLaunch = useRef(true);
  const launchTimer = useSelector(launchTimerOppaButton);
  const dateEnd = localStorage.getItem('endTimeDisableOppaButton');
  const [timeLeft, setTimeLeft] = useState(dateEnd - new Date().getTime());
  const { minutes, seconds } = useTimer(timeLeft, setTimeLeft, launchTimer);

  const rate = 1 / 70;
  const isModalShown = checkUserRole(UserRoles.BA, UserRoles.JTI_TEAM, UserRoles.RSM);

  const [isTutorial, setIsTutorial] = useState(!localStorage.getItem('oppaCompletedTutorial'));

  useEffect(() => {
    if (firstLaunch.current) {
      firstLaunch.current = false;
    } else {
      setTimeLeft(amountTimerOppaButton);
    }
  }, [launchTimer]);

  const closeModalOppa = (onCloseModal) => {
    return (
      isTutorial &&
      dispatch(
        modalActions.openPromptModal({
          title: 'Are you sure you want to finish?(oppa)',
          btnText: 'Yes, exit',
          onButtonClick: () => {
            localStorage.setItem('oppaCompletedTutorial', true);
            setIsTutorial(false);
            dispatch(modalActions.closeModal());
          },
          onClose: () => onCloseModal(),
        }),
      )
    );
  };

  const openSmsModal = () =>
    dispatch(modalActions.openSMSCodeInputModal({ onClose: () => closeModalOppa(openSmsModal) }));

  const openSmsSaga = (amount) => {
    dispatch(
      bonusShopActions.requestOppaSms({
        currentCoinsReserved: amount,
        onClose: () => closeModalOppa(openSmsModal),
      }),
    );
  };

  const openCashoutModal = () => {
    dispatch(
      modalActions.openCashoutModal({
        title: 'Choose amount',
        btnText: 'Proceed',
        symbol: '₾',
        moneyLimit: 150,
        rate: rate,
        step: 70,
        amountMoneyIcons: [10, 50, 100],
        minimumTransferMoney: 490,
        makeTransfer: ({ amount }) => openSmsSaga(amount),
        onClose: () => closeModalOppa(openCashoutModal),
      }),
    );
  };

  const onTransferClick = () => {
    if (isModalShown) {
      dispatch(
        modalActions.openPromptModal({
          description: 'Not available for JTI representatives.',
          btnText: 'OK',
        }),
      );
    } else if (coins < 490) {
      dispatch(
        modalActions.openPromptModal({
          title: 'Ooops',
          description: "You don't have enough сoins for money transfer",
          btnText: 'OK',
        }),
      );
    } else {
      if (isTutorial) {
        dispatch(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.oppaTutorialStepList,
            stepId: oppaTutorialSteps.stepNull,
          }),
        );
      }
      openCashoutModal();
    }
  };

  const goBackCallback = () => {
    history.push(Routes.BonusShopRoute);
  };

  const onClickSettingsButton = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.oppaStepList,
        stepId: oppaSteps.walletSettings,
      }),
    );
  };

  const openHelpArticle = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jti-club.ge/OPPA/OPPA.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  return (
    <div className="oppa__mainPage">
      <Header hasBackButton customClass="oppa__mainPage-headerMenu" fixed goBackCallback={goBackCallback}>
        {formatMessage('Coins transfering')}
      </Header>
      <Balance fixed />

      <div className="oppa__mainPage-container" style={{ backgroundImage: `url(${backgroundMainPage})` }}>
        <SettingsIcon className="oppa__mainPage-container-settings" onClick={onClickSettingsButton} />
        <div className="oppa__mainPage-wallet">
          <div className="oppa__mainPage-wallet-info">
            <div className="oppa__mainPage-wallet-text">{formatMessage('Your e-wallet:')}</div>
            <div className="oppa__mainPage-wallet-number">{oppaWalletNumber}</div>
          </div>
        </div>
        <div className="oppa__mainPage-history">
          <QuestionMarkIcon className="oppa__mainPage-history-faq" onClick={openHelpArticle} />
          <CashoutHistory showTutorial symbol="₾" rate={rate} specificTransfers={transfers} />
        </div>
        <div className="oppa__mainPage-container-button" style={{ bottom: checkIsIphoneX() ? '47px' : '15px' }}>
          {minutes || seconds ? (
            <Button
              customClass="oppa__button oppa__mainPage-button"
              onClick={onTransferClick}
              disabled={minutes || seconds}
            >
              {' '}
              {formatMessage('Next transaction')}
              {minutes ? ` ${minutes}m` : ''}
              {seconds ? ` ${seconds}s ` : ''}
              {formatMessage('in')}
            </Button>
          ) : (
            <Button customClass="oppa__button oppa__mainPage-button" onClick={onTransferClick}>
              {formatMessage('Transfer')}
            </Button>
          )}
        </div>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

import './demoCashoutHistory.scss';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { formatDate } from '../../../../helpers';

import { AcceptedIcon } from '../../../../icons/accepted-icon';
import { FailedIcon } from '../../../../icons/failed-icon';
import { ModerationIcon } from '../../../../icons/moderation-icon';

export const DemoCashoutHistory = () => {
  const { formatMessage } = useInternationalization();

  const transfers = [
    { id: 1, currency: 10, created_at: '2023-01-19T13:42:42.646Z', status: 1 },
    { id: 2, currency: 49, created_at: '2023-01-18T13:42:42.646Z', status: 2 },
    { id: 3, currency: 51, created_at: '2023-01-17T13:42:42.646Z', status: 3 },
    { id: 4, currency: 32, created_at: '2023-01-16T13:42:42.646Z', status: 1 },
    { id: 5, currency: 12, created_at: '2023-01-14T13:42:42.646Z', status: 2 },
    { id: 6, currency: 1000, created_at: '2023-01-14T13:42:42.646Z', status: 1 },
    { id: 7, currency: 2000, created_at: '2023-01-12T13:42:42.646Z', status: 1 },
  ];

  const getIcon = (status) => {
    switch (status) {
      case 1:
        return <AcceptedIcon className="demo-cashout-history-list-item-info-status-icon" />;
      case 2:
        return <FailedIcon className="demo-cashout-history-list-item-info-status-icon" />;
      case 3:
        return <ModerationIcon className="cashout-history-list-item-status-icon" />;
      default:
        return <AcceptedIcon className="demo-cashout-history-list-item-info-status-icon" />;
    }
  };

  const getColor = (status) => {
    switch (status) {
      case 1:
        return '#4cbf8f';
      case 2:
        return '#ff6666';
      case 3:
        return '#ffac30';
    }
  };

  const getStatusTitle = (status) => {
    switch (status) {
      case 1:
        return 'Success';
      case 2:
        return 'Fail';
      case 3:
        return 'On moderation';
    }
  };

  const renderTransfer = (transfer) => {
    return (
      <div key={transfer.id}>
        <div className="demo-cashout-history-list-item">
          <div className="demo-cashout-history-list-item-info">
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <p className="demo-cashout-history-list-item-info-amount">{`$${transfer.currency}`}</p>
              <p className="demo-cashout-history-list-item-info-date">
                {formatDate(new Date(transfer.created_at), 'dd.MM.yy')}
              </p>
            </div>

            <div className="demo-cashout-history-list-item-info-status" style={{ color: getColor(transfer.status) }}>
              {getStatusTitle(transfer.status)} {getIcon(transfer.status)}
            </div>
          </div>
        </div>
        <div className="demo-cashout-history-list-divider" />
      </div>
    );
  };

  return (
    <div
      className="demo-cashout-history"
      style={{
        alignItems: transfers && !transfers.length ? 'center' : 'baseline',
        justifyContent: transfers && !transfers.length ? 'center' : 'end',
      }}
    >
      {transfers && !transfers.length ? (
        <p className="demo-cashout-history-empty">
          {formatMessage('There are no transactions yet. Tap the button below to cashout.')}
        </p>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <p className="demo-cashout-history-caption">{formatMessage('Transactions:')}</p>
          </div>

          <div className="demo-cashout-history-list">
            {transfers && transfers.map((transfer) => renderTransfer(transfer))}
          </div>
        </>
      )}
    </div>
  );
};

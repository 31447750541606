import { PreloaderIcon } from '../../../icons/preloader-icon';

export const Spinner = (props) => {
  const { height, width = 75, style } = props;

  return (
    <div className="lottie-spinner" style={style}>
      <PreloaderIcon width={width} height={height} />
    </div>
  );
};

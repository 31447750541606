import { useHistory, useLocation } from 'react-router';

import PageContainer from '../../components/common/PageContainer';
import { SelfRegistrationForm } from './selfRegistrationForm/selfRegistrationForm';

import styles from './Registration.module.scss';

export const Registration = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const inviteCode = queryParams.get('invite_code');

  const goBackHanlder = () => history.push('/');

  const headerProps = {
    children: null,
    hasBackButton: false,
    customClass: styles.registrationPageHeader,
  };

  return (
    <PageContainer
      headerProps={headerProps}
      showDisclaimer={false}
      showFooter={false}
      customClass={styles.selfRegistrationMain}
    >
      <SelfRegistrationForm inviteCode={inviteCode} goBackHanlder={goBackHanlder} />
    </PageContainer>
  );
};

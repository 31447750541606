import { all, put, takeEvery, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { EVENT_MODE_STEPS, EVENT_MODE_ACTION_TYPES } from './eventModeConstants';
import { eventModeActions } from './eventModeSlice';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { quizApi } from '../../api/quizApi';
import { Routes } from '../../const';
import { getEventModePackIdSelector, getCorrectAnswersEventMode } from './eventModeSelectors';

const locationSelector = state => state.router.location.pathname;

export const goToEventModeStepAction = stepId =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.eventModeStepList,
    stepId,
  });

export const resetEventModeStepList = () => {
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.eventModeStepList,
    stepId: 1,
  });
};

function* onFinishEventModeSaga() {
  try {
    const pack_id = yield select(getEventModePackIdSelector);
    const correct_answers = yield select(getCorrectAnswersEventMode);

    const { data } = yield quizApi.finishEventMode(pack_id, correct_answers.length);
    yield put(eventModeActions.setEventModeResult(data));
    yield delay(2000);
    const path = yield select(locationSelector);
    if (path.includes('event-mode')) {
      yield put(goToEventModeStepAction(EVENT_MODE_STEPS.RESULT));
    }
  } catch (error) {}
}

function* onEventModeStartSaga() {
  try {
    const { data } = yield quizApi.startEventMode();
    yield put(eventModeActions.setEventModeGameInfo(data));
    yield put(push(Routes.EventModeRoute));
  } catch (error) {}
}

/*
function* onResetSaga() {
  yield put(goToSpecialQuizStepAction(SPECIAL_QUIZ_STEPS.GAME));
}

function* specialQuizThreadSaga() {
  while (true) {
    const { payload } = yield take(SPECIAL_QUIZES_ACTION_TYPES.FINISH_SPEC_QUIZ);
    const bgSyncTask = yield fork(onFinishSpecialQuizSaga, { payload });
    yield take(SPECIAL_QUIZES_ACTION_TYPES.RESET);
    yield cancel(bgSyncTask);
  }
}
*/

function* sagas() {
  yield all([
    takeEvery(EVENT_MODE_ACTION_TYPES.START_EVENT_MODE, onEventModeStartSaga),
    takeEvery(EVENT_MODE_ACTION_TYPES.FINISH_EVENT_MODE, onFinishEventModeSaga),
  ]);
}

export const eventModeSagas = sagas;

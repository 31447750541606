import { BrandCard } from './brandCard';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../const/brandsConstants';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';

export const MainScreenCard = ({ brand, history }) => {
  if (Object.values(QUIZ_WITHOUT_BRAND_IDS).some((i) => i === brand.id)) {
    return null;
  }
  return <BrandCard brand={brand} history={history} />;
};

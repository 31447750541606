import { all, put, takeLatest, select, call } from 'redux-saga/effects';

import { loyaltyProgramActions } from './loyaltyProgramSlice';
import { loyaltyProgramApi } from '../../api/loyaltyProgramApi';
import { serviceApi } from '../../api/serviceApi';
import { getLoyaltyProgramDataSelector } from './loyaltyProgramSelectors';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';

function* getLoyaltyProgramDataSaga() {
  try {
    const { data } = yield loyaltyProgramApi.getLoyaltyProgramData();
    yield put(loyaltyProgramActions.setLoyaltyProgramData({ data }));
  } catch (error) {
    console.error(error);
  }
}

function* sendReceiptSaga({ payload }) {
  try {
    const response = yield serviceApi.uploadPhoto(payload);

    if (response.status === 200 && response.data) {
      const { id } = yield select(getLoyaltyProgramDataSelector);
      const { data } = yield loyaltyProgramApi.sendReceipt(id, response.data.url);
      if (data) {
        yield put(loyaltyProgramActions.setLoyaltyProgramData({ data }));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* getRewardSaga() {
  try {
    const { id } = yield select(getLoyaltyProgramDataSelector);
    const { data } = yield loyaltyProgramApi.getLoyaltyProgramReward(id);
    if (data) {
      yield call(getLoyaltyProgramDataSaga)
      yield put(
        modalActions.openLoyaltyRewardModal({
          // formatMessage('OK')
          btnText: 'OK',
          coins: data.coins,
          onButtonClick: () => {
            store.dispatch(modalActions.closeModal());
          },
          onClose: () => {
            store.dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  } catch (error) {
    console.error(error);
  }
}

function* sagas() {
  yield all([
    takeLatest(loyaltyProgramActions.getLoyaltyProgramData, getLoyaltyProgramDataSaga),
    takeLatest(loyaltyProgramActions.sendReceipt, sendReceiptSaga),
    takeLatest(loyaltyProgramActions.getReward, getRewardSaga),
  ]);
}

export const loyaltyProgramSagas = sagas;

import moment from 'moment-timezone';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { appTimeZone } from '../../const/appConstants';

import './profileSettings.scss';

import { Header } from '../../components/common/Header';
import { profileActions } from '../../redux/profile/profileSlice';
import { UserInfoForm } from './userInfoForms/userInfoFormHungary';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { formatDate } from '../../helpers';
import { ProfileSettingsBottomArea } from './profileSettingsBottomArea/profileSettingsBottomArea';
import {
  getManagerInfoSelector,
  getUserOutletInfoSelector,
  getUserInfoSelector,
} from '../../redux/profile/profileSelectors';

export const ProfileSettingsHungary = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const userInfo = useSelector(getUserInfoSelector);
  const positions = useSelector((state) => state.service.positions);
  const managersCities = useSelector((state) => state.service.managersCities);
  const theme = useSelector((state) => state.service.theme);
  const manager = useSelector(getManagerInfoSelector);
  const outlet = useSelector(getUserOutletInfoSelector);

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const handleSaveChanges = (values) => {
    const changedUserInfo = {
      username: values.username,
      date_of_birth: moment(values.date_of_birth).tz(appTimeZone, true).startOf('day').toISOString(),
    };

    localStorage.setItem('themeId', theme.id);
    dispatch(profileActions.changeUserInfo({ userInfo: changedUserInfo }));
  };

  return (
    <div className="profile-settings-page">
      <ScrollBlock fromTop>
        <Header hasBackButton>
          <p className="profile-settings-page__header-text">{formatMessage('Edit profile')}</p>
        </Header>
        <div
          className="profile-settings-page__content"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 60px)' : 'unset' }}
        >
          {userInfo.id && (
            <UserInfoForm
              positions={positions}
              managersCities={managersCities}
              onSubmit={handleSaveChanges}
              initialValues={{
                username: userInfo.username,
                date_of_birth: formatDate(new Date(userInfo.date_of_birth), 'yyyy-MM-dd'),
                manager_name: `${manager.name} ${manager.surname}`,
                city: `${outlet.city.name}`,
                legal_entity: `${outlet.legal_entity.name}`,
                address: `${outlet.outlet_address.name}`,
                tier_discount: userInfo?.tier_discount,
              }}
            />
          )}
          <ProfileSettingsBottomArea />
        </div>
        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};

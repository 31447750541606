import emptyAvatar from '../../../../images/challengeUserEmptyAvatar.png';

import styles from './TeamChallenge.module.scss';

type Props = {
  description: string;
  name: string;
  avatars?: Array<string>;
};

export const TeamChallenge = ({ description, name, avatars }: Props) => {
  return (
    <div className={styles.teamChallenge}>
      <div className={styles.avatarsBlock}>
        {avatars &&
          avatars.map((avatar, index) => {
            return <img key={avatar + index} src={avatar || emptyAvatar} alt="avatar" className={styles.avatar} />;
          })}
      </div>
      <div className={styles.titleBlock}>
        <div className={styles.teamChallengeTitle}>{description}</div>
        <div className={styles.teamChallengeDescription}>{name}</div>
      </div>
    </div>
  );
};

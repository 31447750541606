import { all, put, select, takeLatest } from '@redux-saga/core/effects';
import { lepApi } from '../../api/lepAPI';
import { isAuthorizedSelector } from '../auth/authSelectors';
import { getLepInputCodeSelector, getLepDataUserSelector, getLepSelectedCompetitionsSelector } from './lepSelector';
import { LEP_ACTIONS } from './lepConstants';
import { lepActions } from './lepSlice';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { lepSteps } from '../../pages/LEP/Steps/LepStart';
import { analyticHelper } from '../../helpers/analyticHelper';

function* setSelectedCompetitionSaga({ payload: { selectedCompetition } }) {
  try {
    yield lepActions.setSelectedCompetition({ selectedCompetition });
  } catch (err) {
    console.error(err);
  }
}

function* getCompetitionsSaga() {
  try {
    const isAuthorized = yield select(isAuthorizedSelector);

    const response = yield lepApi.getCompetitions();
    if (isAuthorized && response.data.length === 1) {
      yield put(lepActions.getCodes({ competitionId: response.data[0].id }));
    }
    yield put(lepActions.setCompetitions({ competitions: response.data }));
  } catch (err) {
    console.error(err);
  }
}

function* getCodesSaga({ payload }) {
  const { competitionId } = payload;
  try {
    const { data } = yield lepApi.getCodes(competitionId);
    if (data && data.codes.length) {
      yield put(lepActions.setActivatedCodes(data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* setCodeSaga() {
  try {
    const isAuthorized = yield select(isAuthorizedSelector);
    const { lepCode: code } = yield select(getLepInputCodeSelector);
    const { id = null } = yield select(getLepSelectedCompetitionsSelector);
    let dataUser = yield select(getLepDataUserSelector);
    if (isAuthorized) {
      const { data = [] } = yield lepApi.setCode(code, id);
      yield put(lepActions.setActivatedCodes(data));
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.lepStepList,
          stepId: lepSteps.result,
          props: { prevStep: 0 },
        }),
      );
    } else if (dataUser) {
      dataUser.phone = dataUser.phone.replace(/[(0)]/g, '').replace(/\s+(?![^\d\s])/g, '');
      const { data = [] } = yield lepApi.setCodeUnregistered({ code, competition_id: id, ...dataUser });
      if (dataUser?.username && dataUser?.position_id) {
        analyticHelper.lepDataUserPart();
      }
      yield put(lepActions.setActivatedCodes(data));
      yield put(
        stepListActions.setStep({
          stepListId: STEP_LIST_IDS.lepStepList,
          stepId: lepSteps.result,
          props: { prevStep: 0 },
        }),
      );
    } else {
      analyticHelper.lepProceed();
      yield put(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.lepStepList, props: { prevStep: 0 } }));
    }
  } catch (err) {
    console.error(err);
  }
}

function* signUpSaga() {
  try {
    let userData = yield select(getLepDataUserSelector);
    const { position_id, username } = userData;
    if (position_id && username) {
      const response = yield lepApi.singup(userData);
      if (response.status === 200) {
        yield put(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.lepStepList,
            stepId: lepSteps.completeRegistration,
          }),
        );
      }
    }
  } catch (err) {
    console.error(err);
  }
}

function* sagas() {
  yield all([
    takeLatest(LEP_ACTIONS.SET_SELECTED_COMPETITION, setSelectedCompetitionSaga),
    takeLatest(LEP_ACTIONS.GET_COMPETITIONS, getCompetitionsSaga),
    takeLatest(LEP_ACTIONS.SET_CODE, setCodeSaga),
    takeLatest(LEP_ACTIONS.SIGN_UP, signUpSaga),
    takeLatest(LEP_ACTIONS.GET_CODES, getCodesSaga),
  ]);
}
export const lepSagas = sagas;

import { v1 } from 'uuid';

export const textForPersonalData = [
  {
    id: v1(),
    title: 'Согласие на обработку персональных данных',
    description:
      'Настоящим даю согласие ИООО «Джапан Тобакко Интернэшнл ЭсЭндДи» (далее – Оператор) на обработку своих персональных данных в соответствии с условиями Политики конфиденциальности мобильного приложения “be2gether” (далее – Политика конфиденциальности).\n' +
      '\n' +
      'Настоящим подтверждаю, что я ознакомлен и согласен с условиями Политики конфиденциальности.\n' +
      '\n' +
      'Перечень персональных данных, обрабатываемых Оператором, изложен в разделе 2 Политики конфиденциальности.\n' +
      '\n' +
      'Оператор осуществляет обработку персональных данных с использованием средств автоматизации или без использования таких средств путем совершения любых действий или совокупности действий, совершаемых с персональными данными, включая сбор, систематизацию, хранение, изменение, использование, обезличивание, блокирование, распространение, предоставление, удаление персональных данных.\n' +
      '\n' +
      'Согласие дается для целей, изложенных в разделе 2 Политики конфиденциальности.\n' +
      '\n' +
      'Информация об уполномоченных лицах закреплена в разделе 3 Политики конфиденциальности.\n' +
      '\n' +
      'Настоящее согласие действует до момента отпадения необходимости в достижении целей, указанных в разделе 2 Политики конфиденциальности, либо до момента его отзыва.\n' +
      '\n' +
      'Права, связанные с обработкой персональных данных и механизм реализации таких прав указаны в разделе 7 Политики конфиденциальности.\n' +
      '\n' +
      'Согласие может быть отозвано в любое время на основании заявления, составленного в соответствии с разделом 7 Политики конфиденциальности.\n' +
      '\n' +
      'В случае дачи согласия на обработку персональных данных, такие данные будут обрабатываться Оператором в порядке и на условиях, определенных Политикой конфиденциальности. В случае отказа в даче такого согласия персональные данные обрабатываться Оператором не будут, за исключением обработки персональных данных, которые Оператор обрабатывает, имея иные законные обоснования.\n ',
  },
];

export const textForDataRetrieval = [
  {
    id: v1(),
    title: 'Условия получения информации',
    description:
      'Настоящим Вы выражаете согласие на участие через мобильное приложение “be2gether” в опросах и/или исследованиях, в том числе оценивающих уровень осведомлённости и уровень знаний о табачной продукции потребителей табачных изделий и их предпочтениях; а также на получение вознаграждений за потраченное время и предоставленные данные, при выполнении условий опроса/исследований. \n' +
      '\n' +
      'Настоящим Вы подтверждаете согласие на получение через мобильное приложение “be2gether” информации о табачной продукции, производимой под контролем компании JTI для того, чтобы вы могли более свободно ориентироваться в ассортименте табачной продукции, а также для выявления мнения потребителей табачной продукции о качестве выпускаемых товаров и приемлемости их потребительских характеристик для непосредственных покупателей.\n' +
      '\n' +
      'Вы можете отказаться от участия в опросах и/или исследованиях, удалив аккаунт в своём Профиле мобильного приложения “be2gether”, либо удалив само мобильное приложение',
  },
];

export const personalDataButtonText =
  'I hereby confirm that I have read and agree with the terms of personal data processing';
export const dataRetrievalButtonText = 'I accept the terms of receiving information';

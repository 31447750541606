import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { challengeActions } from '../../redux/challenge/challengeSlice';
import { challengeSelectors } from '../../redux/challenge/challengeSelectors';

import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { TakePhotoPage } from './TakePhotoPage';
import { ResultPhotoPage } from './ResultPhotoPage';

import './getDigitalFramePhoto.scss';

export const DigitalFrameComponent = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const challenge = useSelector(challengeSelectors.selectCurrentChallenge);

  useEffect(() => {
    if (!challenge) {
      history.goBack();
    }
  }, [dispatch]);

  const StepList = createStepListContainer('digitalFrameStepList', [
    {
      id: 0,
      Component: () => <TakePhotoPage history={history} challenge={challenge} />,
    },
    {
      id: 1,
      Component: () => <ResultPhotoPage history={history} challenge={challenge} />,
    },
  ]);

  return <StepList />;
};

export const DigitalFramePhoto = withRouter(DigitalFrameComponent);

export const DownloadIcon = (props) => {
  return (
    <svg {...props} width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="19" cy="19" r="18" stroke="black" />
      <rect x="11" y="26" width="16" height="1" rx="0.5" fill="black" />
      <path
        d="M19.3662 22.8545C19.2661 22.9486 19.1342 23 18.9977 23C18.8613 23 18.7248 22.9486 18.6293 22.8545L14.1535 18.6428C13.9488 18.4502 13.9488 18.1377 14.1535 17.9451C14.3582 17.7525 14.6902 17.7525 14.8949 17.9451L18.4792 21.3179V12.4922C18.4746 12.2183 18.7066 12 18.9977 12C19.2888 12 19.5208 12.2183 19.5208 12.4922V21.3179L23.1051 17.9494C23.3098 17.7568 23.6418 17.7568 23.8465 17.9494C24.0512 18.142 24.0512 18.4545 23.8465 18.6471L19.3662 22.8545Z"
        fill="black"
      />
    </svg>
  );
};

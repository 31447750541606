import { API_URL } from './baseApi';
import axios from 'axios';

export const dailyRewardsApi = {
  getDailyRewards: async () => {
    return await axios.get(`${API_URL}/v3/daily-rewards`);
  },
  postDailyRewards: async () => {
    return await axios.post(`${API_URL}/v3/daily-rewards/pre-roll`);
  },
  postDailyRewardsResult: async () => {
    return await axios.post(`${API_URL}/v3/daily-rewards/withdraw`);
  },
};

export const SOLO_MODE_STEPS = {
  START: 1,
  GAME: 2,
  RESULT: 3,
};

export const SOLO_MODE_ACTION_TYPES = {
  START_SOLO_MODE: 'soloMode/startSoloMode',
  FINISH_SOLO_MODE: 'soloMode/finishSoloMode',
  RESET: 'soloMode/reset',
  START_GAME: 'soloMode/startGame',
};

import { all, put, takeLatest, select, call } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { chatApi } from '../../../api/chatApi';
import { MESSAGE_LIMIT } from '../chatConstants';
import { mapMessage, mapMessages, mapProblems, mapTicket } from '../chatServices';
import { ticketChatActions } from './ticketChatSlice';
import { ticketChatSelectors } from './ticketChatSelectors';
import { ChatRole, MessageType } from '../chatTypes';
import { serviceApi } from '../../../api/serviceApi';

function* fetchProblems(action: PayloadAction<{ ticketId: string; problemId?: number }>) {
  const { problemId, ticketId } = action.payload;

  yield put(ticketChatActions.setIsFetching(true));

  try {
    const { data } = yield chatApi.getProblems(ticketId, problemId);
    const problems = mapProblems(data.problems);

    yield put(ticketChatActions.problemsReceived(problems));
  } finally {
    yield put(ticketChatActions.setIsFetching(false));
  }
}

function* fetchTicket(action: PayloadAction<{ ticketId: string }>) {
  const { ticketId } = action.payload;

  yield put(ticketChatActions.setIsFetching(true));
  try {
    const { data } = yield chatApi.getTicket(ticketId);
    const ticket = mapTicket(data);

    yield put(ticketChatActions.ticketReceived(ticket));
  } finally {
    yield put(ticketChatActions.setIsFetching(false));
  }
}

function* fetchMessages(action: PayloadAction<{ offset: number; ticketId: string }>) {
  const { offset, ticketId } = action.payload;

  yield put(ticketChatActions.setIsFetching(true));

  try {
    const { data } = yield chatApi.getMessages({
      limit: MESSAGE_LIMIT,
      offset,
      role: ChatRole.Administrator,
      ticketId,
    });

    const messages = mapMessages(data.messages);

    yield put(ticketChatActions.messagesReceived(messages));

    const total: number = yield select(ticketChatSelectors.selectTotal);

    yield put(ticketChatActions.setHasMore(total < data.total));
  } finally {
    yield put(ticketChatActions.setIsFetching(false));
  }
}

function* sendMessage(action: PayloadAction<{ text: string; image?: object; ticketId: string; type?: number }>) {
  const { text, image, ticketId, type } = action.payload;
  const { data } = yield chatApi.sendMessage({ text, image }, ChatRole.Administrator, ticketId, type);

  const message = mapMessage(data);

  yield put(ticketChatActions.messageReceived(message));
}

function* uploadImage(action: PayloadAction<{ formData: object; ticketId: string }>) {
  const { formData, ticketId } = action.payload;
  yield put(ticketChatActions.setIsImageUploading(true));

  try {
    const { data } = yield serviceApi.uploadPhoto(formData);

    yield put(ticketChatActions.sendMessage({ text: '', image: data.file_key, ticketId: ticketId }));
  } finally {
    yield put(ticketChatActions.setIsImageUploading(false));
  }
}

function* chooseOther(action: PayloadAction<{ ticketId: string; text: string; type?: MessageType }>) {
  const { ticketId, text, type } = action.payload;

  yield chatApi.chooseOther(ticketId, text, type);
}

function* chooseProblem(action: PayloadAction<{ problemId: number; ticketId: number }>) {
  const { problemId, ticketId } = action.payload;

  yield chatApi.chooseProblem(problemId, ticketId);
}

function* closeTicket(action: PayloadAction<{ ticketId: string }>) {
  const { ticketId } = action.payload;
  const payload = { payload: { text: 'Yes', ticketId: ticketId } };

  yield call<any>(sendMessage, payload);

  yield chatApi.closeTicket(ticketId);
  yield put(ticketChatActions.setTicketStatusResolved({ status: 1 }));
}

export function* ticketChatSagas() {
  yield all([
    takeLatest(ticketChatActions.fetchTicket.type, fetchTicket),
    takeLatest(ticketChatActions.closeTicket.type, closeTicket),
    takeLatest(ticketChatActions.fetchProblems.type, fetchProblems),
    takeLatest(ticketChatActions.chooseProblem.type, chooseProblem),
    takeLatest(ticketChatActions.chooseOther.type, chooseOther),
    takeLatest(ticketChatActions.fetchMessages.type, fetchMessages),
    takeLatest(ticketChatActions.sendMessage.type, sendMessage),
    takeLatest(ticketChatActions.uploadImage.type, uploadImage),
  ]);
}

import { all, put, takeEvery, take, cancel, select, delay, fork } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { SPECIAL_QUIZES_ACTION_TYPES, SPECIAL_QUIZ_STEPS } from './specialQuizesConstants';
import { quizApi } from '../../api/quizApi';
import { specialQuizActions } from './specialQuizesSlice';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { getPackIdSelector, getAnswersSelector, getQuestionsSelector } from './specialQuizSelectors';
import { checkCorrectAnswers } from '../game/gameSelectors';
import { getSelectedArticleSelector } from '../knowledgeBank/knowledgeBankSelectors';
import { Routes } from '../../const';
import { profileActions } from '../profile/profileSlice';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';

const locationSelector = (state) => state.router.location.pathname;

export const goToSpecialQuizStepAction = (stepId) =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.specialQuizSpetList,
    stepId,
  });

export const resetSpecialQuizStepList = () => {
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.specialQuizSpetList,
    stepId: 1,
  });
};

function* onFinishSpecialQuizSaga({ payload }) {
  try {
    const { type } = payload;
    const pack_id = yield select(getPackIdSelector);
    const answers = yield select(getAnswersSelector);
    const questions = yield select(getQuestionsSelector);
    const article = yield select(getSelectedArticleSelector);
    const checkedAnswers = checkCorrectAnswers(answers.me, questions);

    const answersStats = [];

    checkedAnswers.forEach((answer) => {
      answersStats.push({ answer_id: answer.answer - 1 });
    });

    const { data } = yield quizApi.finishSpecialQuiz(pack_id, type, answersStats, article.id);
    yield amplitudeHelper.specialQuizFinish(answersStats, pack_id, data.coins, article.id);

    yield put(
      specialQuizActions.setSpecialQuizResultData({
        coins: data.coins,
        extraCoins: data.extra_coins,
      }),
    );

    yield put(profileActions.getUserInfo());
    yield delay(2000);
    const path = yield select(locationSelector);

    yield put(modalActions.closeModal());

    if (path.includes('special-quiz')) {
      yield put(goToSpecialQuizStepAction(SPECIAL_QUIZ_STEPS.RESULT));
    }
  } catch (error) {}
}

function* onStartSpecialQuizSaga({ payload }) {
  try {
    const { special_quiz_id, brand_id } = payload;
    const { data } = yield quizApi.startSpecialQuiz(special_quiz_id);
    yield put(specialQuizActions.setPackId({ pack_id: special_quiz_id }));
    yield put(specialQuizActions.setQuestions(data));
    yield put(specialQuizActions.setQuizStatus(data.is_new));

    const route =
      !data.is_new && isFeatureEnabled(CONFIG_KEYS.NON_BONUS_VIEW_ENABLED)
        ? Routes.NoBonusSpecialQuizRoute
        : Routes.SpecialQuizRoute;

    yield put(push(`${route}/${brand_id}`));
  } catch (error) {}
}

function* onSeasonQuizStartSaga() {
  try {
    const { data } = yield quizApi.startEventMode();
    yield put(specialQuizActions.setSeasonQuizGame(data));
    yield put(push(Routes.SeasonQuizRoute));
  } catch (error) {}
}

function* onResetSaga() {
  yield put(goToSpecialQuizStepAction(SPECIAL_QUIZ_STEPS.GAME));
}

function* specialQuizThreadSaga() {
  while (true) {
    const { payload } = yield take(SPECIAL_QUIZES_ACTION_TYPES.FINISH_SPEC_QUIZ);
    const bgSyncTask = yield fork(onFinishSpecialQuizSaga, { payload });
    yield take(SPECIAL_QUIZES_ACTION_TYPES.RESET);
    yield cancel(bgSyncTask);
  }
}

function* sagas() {
  yield fork(specialQuizThreadSaga);
  yield all([
    takeEvery(SPECIAL_QUIZES_ACTION_TYPES.START_SPEC_QUIZ, onStartSpecialQuizSaga),
    takeEvery(SPECIAL_QUIZES_ACTION_TYPES.RESET, onResetSaga),
    takeEvery(SPECIAL_QUIZES_ACTION_TYPES.START_SEASON_QUIZ, onSeasonQuizStartSaga),
  ]);
}

export const specialQuizSagas = sagas;

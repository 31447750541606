import { takeLatest, put, all } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { IRefferalUser } from './selfRegistrationTypes';
import { selfRegistrationActions } from './selfRegistrationSlice';
import { selfRegistrationApi } from '../../api/selfRegistrationApi';

interface IResponseUser {
  data: IRefferalUser;
  status: number;
}

interface IResponseReward {
  data: number;
  status: number;
}

function* selfRegistrationSaga(action: PayloadAction<IRefferalUser>) {
  yield put(selfRegistrationActions.setFetching(true));
  try {
    const response: IResponseUser = yield selfRegistrationApi.createUser(action.payload);
    if (response.status === 201) {
      yield put(selfRegistrationActions.setIsSuccess(true));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(selfRegistrationActions.setFetching(false));
  }
}

function* getSelfRegistrationRewardSaga() {
  yield put(selfRegistrationActions.setFetching(true));
  try {
    const response: IResponseReward = yield selfRegistrationApi.getReward();
    if (response.status === 200) {
      yield put(selfRegistrationActions.setReward(response.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(selfRegistrationActions.setFetching(false));
  }
}

export function* watchSelfRegistrationSaga() {
  yield all([
    takeLatest(selfRegistrationActions.initUserCreate, selfRegistrationSaga),
    takeLatest(selfRegistrationActions.initGetReward, getSelfRegistrationRewardSaga),
  ]);
}

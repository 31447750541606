import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getIsFetchingSelector } from '../../../../redux/service/serviceSelectors';
import { serviceActions } from '../../../../redux/service/serviceSlice';
import { mysteryShopperActions } from '../../../../redux/mysteryShopper/mysteryShopperSlice';
import { getFileKeys } from '../../../../redux/mysteryShopper/mysteryShopperSelectors';
import { WhiteSpinner } from '../../../../components/common/Spinner/WhiteSpinner/WhiteSpinner';
import { s3FilePaths } from '../../../../const/appConstants';

import styles from './AddPhotoQuestion.module.scss';

export const AddPhotoQuestion = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const filesInputRef = useRef<HTMLInputElement>(null);
  const isFetching = useSelector(getIsFetchingSelector);
  const fileKeys = useSelector(getFileKeys);

  useEffect(() => {
    return () => {
      dispatch(mysteryShopperActions.setErrorText(''));
    };
  }, [dispatch]);

  const handlerClick = () => {
    if (fileKeys.length > 9) {
      dispatch(mysteryShopperActions.setErrorText('10 photos maximum'));
      return;
    }

    const uploadPhotoCallback = (fileKey: string) => {
      dispatch(mysteryShopperActions.setFileKeys(fileKey));
      dispatch(mysteryShopperActions.setErrorText(''));
    };

    const files = filesInputRef?.current?.files;
    const file = files && files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('file_path', s3FilePaths.mysteryShopperPhotoQuestion);
    dispatch(serviceActions.uploadPhoto({ formData, uploadPhotoCallback }));
  };

  return (
    <div className={styles.container}>
      <div className={styles.block} onClick={() => filesInputRef.current?.click()}>
        {isFetching ? (
          <WhiteSpinner />
        ) : (
          <>
            <div className={styles.plus} />
            <div className={styles.textBlock}>
              <span className={styles.title}>{formatMessage('Add photo')}</span>
              <span className={styles.description}>{formatMessage('(10 photos maximum)')}</span>
              <input
                type="file"
                id="photo-question"
                hidden
                ref={filesInputRef}
                onChange={handlerClick}
                accept="image/*"
              />
            </div>
          </>
        )}
      </div>
      <div className={styles.successfulWrapper}>
        {fileKeys &&
          fileKeys.map((file) => (
            <span key={file} className={styles.successful}>
              {formatMessage('successfully uploaded')}
            </span>
          ))}
      </div>
    </div>
  );
};

import isEmpty from 'is-empty-typed';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { chanceActions } from '../../redux/chance/chanceSlice';
import {
  getIsPlayingSelector,
  getResultSelector,
  selectChance,
  selectIsFetching,
} from '../../redux/chance/chanceSelectors';
import { getUserBalanceSelector } from '../../redux/profile/profileSelectors';
import { APP_TYPES } from '../../const/appConstants';
import { isIreland } from '../../helpers';
import { NavigationMenu } from '../../components/';
import { Balance } from '../../components/common/Balance';
import { Header } from '../../components/common/Header';
import { Button } from '../../components/common/Button';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { LottieWrapper } from '../../components/lootieWrapper/lootieWrapper';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { LoaderContainer } from '../../components/common';
import lotteryAnimation from './lottery_animation.json';
import lotteryAnimationHungary from './lottery_animation_hungary.json';
import winAnimation from './win_animation.json';
import loseAnimation from './lose_animation.json';

import './chancePage.scss';

export const ChancePage = (props) => {
  const { location } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const { id } = useParams();

  const chance = useSelector(selectChance);
  const isPlaying = useSelector(getIsPlayingSelector);
  const result = useSelector(getResultSelector);
  const coins = useSelector(getUserBalanceSelector);
  const isFetching = useSelector(selectIsFetching);

  const consolationPoints = isEmpty(chance?.consolation_points) ? 2 : chance?.consolation_points;

  useEffect(() => {
    dispatch(chanceActions.getChanceById(id));
  }, [dispatch]);

  const participate = useCallback(
    (id) => {
      if (coins < chance.cost) {
        dispatch(
          modalActions.openPromptModal({
            description: 'Not enough enough credits',
            btnText: 'OK',
            onButtonClick: () => {
              dispatch(modalActions.closeModal());
            },
          }),
        );
      } else {
        dispatch(chanceActions.participate({ id }));
      }
    },
    [coins, chance],
  );

  const renderStartLotteryContent = () => {
    return (
      <LoaderContainer isLoading={isFetching}>
        <div className="chance-page">
          <ScrollBlock fromTop>
            <Header hasBackButton>
              <p className="chance-page__header-text">{formatMessage('Chance')}</p>
            </Header>
            <Balance />
            {isEmpty(chance) ? (
              <div className="chance-page__empty">{formatMessage('There are no active chances at the moment')}</div>
            ) : (
              <div className="chance-page__content">
                <p className="chance-page__content-description">{chance.description}</p>
                <img className="chance-page__content-photo" src={chance.photo} alt="" />
                <div className="chance-page__content-button-block">
                  <p className="chance-page__content-button-block-name">{chance.name}</p>
                  <Button
                    onClick={() => participate(chance.id)}
                    customClass={'chance-page__content-button-block-button'}
                  >
                    {formatMessage('Try for {cost} points', { cost: chance.cost })}
                  </Button>
                </div>
              </div>
            )}

            <NavigationMenu location={location} />
            <DisclaimerFooter />
          </ScrollBlock>
        </div>
      </LoaderContainer>
    );
  };

  const renderPlayAnimation = () => {
    return (
      <div className="chance-page__is-playing">
        <div className="chance-page__is-playing-header">
          <p className="chance-page__is-playing-caption">{formatMessage('Drop a coin and try your luck')}</p>
        </div>
        <LottieWrapper
          className="chance-page__is-playing-animation"
          isClickToPauseDisabled
          width={280}
          options={playLottery}
          style={{
            height: 'auto',
            paddingBottom: '50px',
          }}
        />
      </div>
    );
  };

  const renderResultAnimation = () => {
    return (
      <div className="chance-page__is-playing">
        {result === 'win' ? (
          <>
            <div className="chance-page__is-playing-header">
              <p className="chance-page__is-playing-caption">{formatMessage('You won!')}</p>
              <p className="chance-page__is-playing-caption-second">
                {isIreland()
                  ? formatMessage('Yes – you won the Chance! Nice one')
                  : formatMessage('Check your chat to get more details')}
              </p>
            </div>

            <LottieWrapper
              className="chance-page__is-playing-animation"
              isClickToPauseDisabled
              width={280}
              options={winLottery}
              style={{
                height: 'auto',
                paddingBottom: '50px',
              }}
            />
          </>
        ) : (
          <>
            <div className="chance-page__is-playing-header">
              <p className="chance-page__is-playing-caption">{formatMessage('Better luck Next time!')}</p>
              <p className="chance-page__is-playing-caption-second">
                {chance.consolation_points !== 0 &&
                  formatMessage('+{coins} points for taking a chance!', { coins: consolationPoints })}
              </p>
            </div>
            <LottieWrapper
              className="chance-page__is-playing-animation"
              isClickToPauseDisabled
              width={260}
              options={loseLottery}
              style={{
                height: 'auto',
              }}
            />
          </>
        )}
      </div>
    );
  };

  const renderContent = () => {
    if (isPlaying) {
      return renderPlayAnimation();
    } else if (result) {
      return renderResultAnimation();
    } else if (chance) {
      return renderStartLotteryContent();
    } else {
      return null;
    }
  };

  return renderContent();
};

const takeLotteryAnimation = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      return lotteryAnimation;
    case APP_TYPES.HUNGARY:
      return lotteryAnimationHungary;
    default:
      return lotteryAnimation;
  }
};

const playLottery = {
  loop: true,
  autoplay: true,
  animationData: takeLotteryAnimation(),
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const winLottery = {
  loop: true,
  autoplay: true,
  animationData: winAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const loseLottery = {
  loop: true,
  autoplay: true,
  animationData: loseAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

import React from 'react';
import { useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { selectEndDate } from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import moderationIcon from '../../../images/moderationIcon.png';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';

import styles from './Moderation.module.scss';
import { useTimeLeft } from '../../../hooks/useTimeLeft';
import { useHistory } from 'react-router';
import { Routes } from '../../../const';

export const Moderation = () => {
  const history = useHistory();
  const { formatMessage } = useInternationalization();
  const endDate = useSelector(selectEndDate);

  const time = useTimeLeft(endDate);

  const onBackArrowClick = () => {
    history.push(Routes.MainRoute);
  };

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor="white"
      />
      <span className={styles.title}>
        {formatMessage(`Mystery Shopper will be finished in`)} {time}
      </span>
      <div className={styles.iconWrapper}>
        <img className={styles.icon} src={moderationIcon} alt="Moderation" />
      </div>
      <span className={styles.text}>{formatMessage('We are checking your answers. Please wait')}</span>
    </div>
  );
};

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { getSelectedBrandSelector } from '../../../redux/knowledgeBank/knowledgeBankSelectors';

import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';
import winstonLoader from '../animations/winston_loader_animation.json';
import camelLoader from '../animations/camel_loader_animation.json';
import ldLoader from '../animations/ld_loader_animation.json';
import jtiLoader from '../animations/jti_loader_animation.json';
import bensonLoader from '../animations/benson_loader_animation.json';
import { RootState } from '../../../redux/store';
import { AnimationType, IBrand } from '../KBBrandsTypes';
import { Routes } from '../../../const';

import styles from './KBBrandsHungary.module.scss';

type Props = {
  brands: IBrand[];
};

const KBBrandsHungary = ({ brands }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const [isAnimationShown, setIsAnimationShown] = useState(false);
  const selectedBrand = useSelector<RootState, IBrand>(getSelectedBrandSelector);

  let animation;

  const selectKBBrand = (brand: IBrand) => {
    dispatch(knowledgeBankActions.setSelectedBrand(brand));
    setIsAnimationShown(true);
  };

  const animationMap: AnimationType = {
    1: winstonLoader,
    2: camelLoader,
    3: ldLoader,
    4: jtiLoader,
    5: bensonLoader,
  };

  if (selectedBrand && !selectedBrand.is_jti) {
    animation = animationMap[selectedBrand.id];
  } else {
    animation = animationMap[4];
  }

  const AnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const onAnimationFinish = () => {
    history.push(`${Routes.BrandInformation}/${id}`);
  };

  const animationDuration = 1000;

  const AnimationComponent = (
    <LottieWrapper
      isStopped={false}
      isPaused={false}
      options={AnimationOptions}
      duration={animationDuration}
      speed={0.3}
      width={'100%'}
      onAnimationFinish={() => onAnimationFinish()}
    />
  );

  return (
    <div className={styles.container}>
      {isAnimationShown && <div className={styles.animationWrapper}>{isAnimationShown && AnimationComponent}</div>}
      {!isAnimationShown &&
        brands &&
        brands.map((brand: IBrand) => (
          <div
            className={styles.brandBlock}
            style={{
              backgroundImage: `url(${brand.background})`,
            }}
            key={brand.brand_description}
            onClick={() => selectKBBrand(brand)}
          >
            <img src={brand.logo} alt="logo" className={styles.icon} />
            <span className={styles.title}>{brand.brand_description}</span>
            <ArrowButton withoutTheme={false} customClass={styles.arrowButton} />
          </div>
        ))}
    </div>
  );
};

export default KBBrandsHungary;

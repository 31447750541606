import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { isIreland } from '../../../helpers';
import { Button } from '../../../components/common';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { ButtonType } from '../../../components/common/CommonButton/types';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../config';
import '../profile.scss';

export const ProfileRulesLinks = () => {
  const links = useSelector((state) => state.service.links);
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const reloadOneTrustBanner = () => {
    const otConsentSdk = document.getElementById('script');
    if (otConsentSdk) {
      otConsentSdk.remove();
    }

    if (window.OneTrust) {
      OneTrust.Init();
      OneTrust.LoadBanner();

      const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = (event) => {
          event.stopImmediatePropagation();
          window.OneTrust.ToggleInfoDisplay();
        };
      }
    }
  };

  const clearCacheData = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Do you want to clear cache?',
        description: 'To do that, you need to reload the app',
        btnText: 'Reload',
        isUpdateModal: true,
        onButtonClick: () => {
          caches
            .keys()
            .then(function (keys) {
              keys.forEach(function (key) {
                caches.delete(key).then(function (response) {
                  console.log(response);
                });
              });
            })
            .then(function () {
              window.location.reload();
            })
            .catch(function (error) {
              console.log(error);
            });
        },
      }),
    );
  };

  useEffect(() => {
    if (isIreland()) {
      reloadOneTrustBanner();
    }
  }, []);

  return (
    <div className="profile-page__content-links-block">
      <div className="profile-page__content-links-block__clear-cache">
        <div className="profile-page__content-links-block__clear-cache-title">
          {formatMessage('Clear your cache to optimise this app')}
        </div>
        <Button
          type={ButtonType.Transparent}
          className="profile-page__content-links-block__clear-cache-button"
          onClick={clearCacheData}
        >
          {formatMessage('Clear cache')}
        </Button>
      </div>
      {isFeatureEnabled(CONFIG_KEYS.GAME_RULES_LINK_ENABLED) && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="profile-page__content-links-block-link"
          href={links.game_rules}
          onClick={() => {
            analyticHelper.profileGameRules();
          }}
        >
          {formatMessage('Game rules')}
        </a>
      )}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="profile-page__content-links-block-link"
        href={links.privacy_policy}
        onClick={() => {
          analyticHelper.profilePrivacyPolicy();
        }}
      >
        {formatMessage('Privacy policy profile')}
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="profile-page__content-links-block-link"
        href={links.general_terms_and_conditions}
        onClick={() => {
          analyticHelper.profileTnC();
        }}
      >
        {formatMessage('General terms and conditions profile')}
      </a>
    </div>
  );
};

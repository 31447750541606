export const getTimeLeft = (toDate) => {
  if (!toDate) {
    return {
      hoursDays: 0,
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
    };
  }
  const countDown = new Date(toDate);
  let now = new Date().getTime();
  let distance = countDown - now;
  let days = Math.floor(distance / (1000 * 60 * 60 * 24));
  let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((distance % (1000 * 60)) / 1000);
  let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let hoursDays = days * 24 + hours;
  return {
    hoursDays,
    days,
    minutes,
    hours,
    seconds,
  };
};

export const formatTimeToDateObject = (time) => {
  if (!time) {
    return {
      hoursDays: 0,
      days: 0,
      minutes: 0,
      hours: 0,
      seconds: 0,
    };
  }
  let days = Math.floor(time / (1000 * 60 * 60 * 24));
  let hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  let minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
  let seconds = Math.floor((time % (1000 * 60)) / 1000);

  let hoursDays = days * 24 + hours;
  return {
    hoursDays,
    days,
    minutes,
    hours,
    seconds,
  };
};

export const formatTimeToString = (time) => {
  let timeString = '';
  const timeUnitsNames = TimeUnitsNames[process.env.REACT_APP_APP_TYPE];

  if (time.days) {
    timeString += time.days + timeUnitsNames.days;
  }
  if (time.hours || time.days) {
    timeString += time.hours + timeUnitsNames.hours;
  }
  if (time.hours || time.days) {
    timeString += time.minutes + timeUnitsNames.minutes;
  } else {
    timeString += time.minutes + timeUnitsNames.onlyMinutes;
  }

  return timeString;
};

const TimeUnitsNames = {
  IRELAND: {
    days: 'd ',
    hours: 'h ',
    minutes: 'm',
    onlyMinutes: 'min',
  },
  HUNGARY: {
    days: 'n ',
    hours: 'ó ',
    minutes: 'p',
    onlyMinutes: 'perc',
  },
};

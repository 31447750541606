import './ScrollBlock.scss';

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { BlockHeights } from '../../../const';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { useWindowInnerHeight } from '../../../hooks/useWindowInnerHeight';

export const ScrollBlock = ({
  children,
  withHeader,
  withNavigation,
  withDisclaimer,
  withBalance,
  fromTop,
  withChat,
}) => {
  const {
    HeaderHeight,
    DisclaimerHeight,
    DisclaimerHeightIphoneX,
    NavigationMenuHeight,
    BalanceHeight,
    ChatEnterMessageBlock,
  } = BlockHeights;

  const height = useWindowInnerHeight();

  const getHeightOfBlocks = () => {
    let blocksHeight = 0;
    if (withHeader) {
      blocksHeight += HeaderHeight;
    }
    if (withNavigation) {
      blocksHeight += NavigationMenuHeight;
    }
    if (withBalance) {
      blocksHeight += BalanceHeight;
    }
    if (withChat) {
      blocksHeight += ChatEnterMessageBlock;
    }
    if (withDisclaimer && !withNavigation && checkIsIphoneX()) {
      blocksHeight += DisclaimerHeightIphoneX;
    } else if (withDisclaimer) {
      blocksHeight += DisclaimerHeight;
    }

    return blocksHeight;
  };

  return (
    <div
      className="scroll-block"
      id="scrollable"
      style={{
        height: fromTop ? `${height}px` : `${height - getHeightOfBlocks()}px`,
        top: `${fromTop ? 0 : withHeader && withBalance ? HeaderHeight + BalanceHeight : HeaderHeight}px`,
        position: fromTop ? 'sticky' : 'fixed',
      }}
    >
      {children}
    </div>
  );
};

ScrollBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  withHeader: PropTypes.bool,
  withNavigation: PropTypes.bool,
  withDisclaimer: PropTypes.bool,
  withBalance: PropTypes.bool,
  fromTop: PropTypes.bool,
  withChat: PropTypes.bool,
};

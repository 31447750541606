import { useState, useEffect } from 'react';
import { formatTimeToDateObject } from '../helpers/timesHelper';

export default function useDailyRewardsTimeLeft(timeLeftProp) {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timeLeft, setTimeLeft] = useState(timeLeftProp);

  useEffect(() => {
    function formatTime() {
      const { minutes, hours, days, seconds } = formatTimeToDateObject(timeLeft);
      setTime({ minutes, hours, days, seconds });
    }

    if (!timeLeft && timeLeftProp) {
      setTimeLeft(timeLeftProp);
    }

    let timer;
    if (timeLeftProp && timeLeft > 1000) {
      formatTime();
      timer = setInterval(() => {
        setTimeLeft(timeLeft - 1000);
        formatTime();
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [timeLeft, setTimeLeft, timeLeftProp]);

  return time;
}

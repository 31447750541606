import React, { useState, useRef } from 'react';
import CanvasDraw from 'react-canvas-draw';

import { useSelector } from 'react-redux';

import { Button } from '../Button';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { dataURItoBlob } from '../../../helpers/dataURItoBlob';
import { serviceApi } from '../../../api/serviceApi';
import { isBelarus } from '../../../helpers';
import { ArrowButton } from '../ArrowButton/ArrowButton';

import './Signature.scss';
import { s3FilePaths } from '../../../const/appConstants';

export const Signature = ({ customClass, onDoneClick }) => {
  const { formatMessage } = useInternationalization();
  const [isSigningEmpty, setIsSigningEmpty] = useState(true);
  const signingRef = useRef(null);

  const signupForm = useSelector((state) => state.form.signupForm);

  const checkIsSigningEmpty = () => {
    if (
      signingRef &&
      signingRef.current &&
      signingRef.current.getSaveData() &&
      JSON.parse(signingRef.current.getSaveData()) &&
      JSON.parse(signingRef.current.getSaveData()).lines &&
      JSON.parse(signingRef.current.getSaveData()).lines.length !== 0
    ) {
      setIsSigningEmpty(false);
    }
  };

  const clearSigning = () => {
    signingRef.current && signingRef.current.clear();
    setIsSigningEmpty(true);
  };

  const onSaveSigning = () => {
    const image = signingRef.current.canvasContainer.children[1].toDataURL('image/webp');
    const file = dataURItoBlob(image);
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('file_path', s3FilePaths.signature);
    serviceApi.uploadPhoto(formData).then((response) => {
      if (isBelarus()) {
        onDoneClick(response.data.file_key, signupForm?.values);
      } else {
        onDoneClick(response.data.file_key);
      }
    });
  };

  return (
    <>
      <div className={customClass}>
        <CanvasDraw
          onChange={checkIsSigningEmpty}
          ref={signingRef}
          canvasWidth={'100%'}
          canvasHeight={'100%'}
          hideInterface={true}
          hideGrid={true}
          brushColor={'black'}
          brushRadius={3}
          lazyRadius={3}
        />
      </div>
      <div className={isBelarus() ? 'buttons-wrapper' : ''}>
        <Button withoutTheme customClass={isBelarus() ? 'clear-button-belarus' : 'clear-button'} onClick={clearSigning}>
          {formatMessage('Clear')}
        </Button>
        {!isBelarus() ? (
          <Button
            withoutTheme
            disabled={isSigningEmpty}
            customClass={`done-button${isSigningEmpty ? '-disabled' : ''}`}
            onClick={onSaveSigning}
          >
            {formatMessage('Done')}
          </Button>
        ) : (
          <ArrowButton
            fillcolor={'#01C1B6'}
            customClass="next-arrow-button"
            withoutTheme
            disabled={isSigningEmpty}
            onClick={onSaveSigning}
          />
        )}
      </div>
    </>
  );
};

import classnames from 'classnames';

import { ButtonViewProps } from '../../types';
import styles from './Primary.module.scss';

export const Primary = ({ isActive, isDisabled, children }: ButtonViewProps) => (
  <div
    className={classnames(styles.primary, {
      [styles.disabled]: isDisabled,
      [styles.active]: isActive,
      themedButton: true,
    })}
  >
    {children}
  </div>
);

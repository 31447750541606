import { createSelector } from 'reselect';

export const getMultibrandQuizIdSelector = createSelector(
  [state => state.multibrandQuiz.quiz_id],
  quiz_id => quiz_id,
);

export const isMultibrandQuizNewSelector = createSelector(
  [state => state.multibrandQuiz.is_new],
  is_new => is_new,
);

export const getMultibrandQuizQuestionsSelector = createSelector(
  [state => state.multibrandQuiz.questions],
  questions => questions,
);

export const getMultibrandQuizAnswersSelector = createSelector(
  [state => state.multibrandQuiz.answers],
  answers => answers,
);

export const getMultibrandQuizResultSelector = createSelector(
  [state => state.multibrandQuiz.result],
  result => result,
);

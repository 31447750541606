import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAnswer, IMysteryShopper, IPassedMysteryShopper, IQuestion, IRecommendations } from './mysteryShopperTypes';

export interface MysteryShopperState {
  mysteryShoppers: IMysteryShopper[];
  passedMysteryShoppers: IPassedMysteryShopper[];
  isFetching: boolean;
  questions: IQuestion[];
  total: number;
  criteriaId: number | null;
  questionId: number | null;
  fileKeys: any[];
  passed: number;
  isCorrect: boolean;
  mysteryShopperId: number | null;
  recommendations: IRecommendations | null;
  endDate: string;
  isSubmitted: boolean;
  errorText: string;
  brandId: number | null;
}

const initialState: MysteryShopperState = {
  mysteryShoppers: [],
  passedMysteryShoppers: [],
  isFetching: false,
  questions: [],
  total: 0,
  criteriaId: null,
  questionId: null,
  fileKeys: [],
  passed: 1,
  isCorrect: false,
  mysteryShopperId: null,
  recommendations: null,
  endDate: '',
  isSubmitted: false,
  errorText: '',
  brandId: null,
};

const mysteryShopperSlice = createSlice({
  name: 'mysteryShopper',
  initialState,
  reducers: {
    setIsFetching(state: MysteryShopperState, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    getMysteryShoppers() {},
    getRecommendations(state, action: PayloadAction<{ mysteryShopperId: number | null }>) {},
    setRecommendations(state, action: PayloadAction<IRecommendations>) {
      state.recommendations = action.payload;
    },
    getMysteryShopperQuestions(state, action: PayloadAction<{ mysteryShopperId: number | null }>) {},
    setMysteryShoppers(state: MysteryShopperState, action: PayloadAction<IMysteryShopper[]>) {
      state.mysteryShoppers = action.payload;
    },
    setPassedMysteryShoppers(state, action: PayloadAction<IPassedMysteryShopper[]>) {
      state.passedMysteryShoppers = action.payload;
    },
    setMysteryShopperId(state, action: PayloadAction<{ mysteryShopperId: number }>) {
      state.mysteryShopperId = action.payload.mysteryShopperId;
    },
    setMysteryShopperQuestions(state, action: PayloadAction<IQuestion[]>) {
      state.questions = action.payload;
    },
    setNumberOfQuestions(state, action: PayloadAction<{ total: number }>) {
      state.total = action.payload.total;
    },
    setNumberOfPassedQuestions(state, action) {
      state.passed = action.payload;
    },
    setIds(state, action: PayloadAction<{ criteriaId: number; questionId: number }>) {
      state.criteriaId = action.payload.criteriaId;
      state.questionId = action.payload.questionId;
    },
    setFileKeys(state, action: PayloadAction<string>) {
      state.fileKeys.push(action.payload);
    },
    resetFileKeys(state) {
      state.fileKeys = [];
    },
    sendMysteryShopperAnswers() {},
    setIsCorrect(state, action: PayloadAction<boolean>) {
      state.isCorrect = action.payload;
    },
    setEndDate(state, action: PayloadAction<string>) {
      state.endDate = action.payload;
    },
    setIsSubmitted(state, action: PayloadAction<boolean>) {
      state.isSubmitted = action.payload;
    },
    setCorrectAnswers(state, action: PayloadAction<IAnswer[]>) {
      state.questions[0].answers = action.payload;
    },
    setErrorText(state, action: PayloadAction<string>) {
      state.errorText = action.payload;
    },
    setBrandId(state, action: PayloadAction<number>) {
      state.brandId = action.payload;
    },
  },
});

export const mysteryShopperActions = mysteryShopperSlice.actions;
export const mysteryShopperReducer = mysteryShopperSlice.reducer;

export const PROFILE_ACTION_TYPES = {
  GET_USER_INFO: 'profile/getUserInfo',
  GET_RATING: 'profile/getRating',
  GET_EARNED: 'profile/getEarned',
  GO_TO_SETTINGS: 'profile/goToSettings',
  CHANGE_USER_INFO: 'profile/changeUserInfo',
  CONFIRM_LEGAL_DOCUMENTS: 'profile/configrmLegalDocuments',
};

export const PASSPORT_PAGE_STATES = {
  EMPTY: 'EMPTY',
  FILLING: 'FILLING',
  READ_ONLY: 'READ_ONLY',
};

export const PASSPORT_PAGES = {
  INFO_PAGE: 'INFO_PAGE',
  PHOTO_PAGE: 'PHOTO_PAGE',
  REGISTRATION_PAGE: 'REGISTRATION_PAGE',
};

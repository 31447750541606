import { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, SubmissionError, reset } from 'redux-form';
import { useSelector } from 'react-redux';

import './signupPage.scss';

import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { Button } from '../../../components/common/Button';
import { Input } from '../../../components/common/Input';
import { Select } from '../../../components/common/Select';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getPhoneCode } from '../../../redux/auth/authConstants';
import { required, letters, characters, lengthMinMax, inviteCodeLatin } from '../../../helpers/formValidations';
import { modalActions } from '../../../components/containers/Modal/modalSlice';

import { authActions } from '../../../redux/auth/authSlice';
import { getPositionsSelectors } from '../../../redux/service/serviceSelectors';
import { authApi } from '../../../api/authApi';
import { InfoIcon } from '../../../icons/info-icon';

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

const asyncValidate = (values) => {
  const { traffic_code } = values;

  if (/[^0-9]/g.test(traffic_code) || traffic_code.length !== 7) {
    return Promise.resolve().then((res) => {
      //eslint-disable-next-line no-throw-literal
      throw {
        traffic_code: 'Account Number consists of 7 numerals',
      };
    });
  }

  return authApi.verifyCode(traffic_code).then((response) => {
    const { data } = response;
    if (data.traffic_code) {
      //eslint-disable-next-line no-throw-literal
      throw {
        traffic_code: data.traffic_code,
      };
    }
  });
};

const SignupPageComponent = (props) => {
  const { handleSubmit, formObj, invalid, phone } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const positions = useSelector(getPositionsSelectors);

  useEffect(() => {
    if (document.getElementsByClassName('auth-page') && document.getElementsByClassName('auth-page').length) {
      document.getElementsByClassName('auth-page')[0].style.height = 'unset';
      return () => {
        document.getElementsByClassName('auth-page')[0].style.height = '100%';
      };
    }
  }, []);

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 1,
      }),
    );
  }, [dispatch]);

  const openAccountCodeInfoModal = () => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('JTI Account Number'),
        description: [formatMessage('Please ask your JTI representative or Store Manager for your JTI account number')],
        btnText: formatMessage('OK'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const submit = () => {
    if (formObj.values.invite_code) {
      return authApi.signup({ ...formObj.values, phone: getPhoneCode() + phone }).then(
        (response) => {
          dispatch(authActions.signupSuccess());
        },
        (error) => {
          if (error.message === 'This username is already taken')
            throw new SubmissionError({
              username: error.message,
            });
          if (error.response) {
            const { type } = error.response.data;
            if (type === 'INVITE_CODE_DOES_NOT_EXIST') {
              dispatch(modalActions.openWrongInviteCodeModal());
              throw new SubmissionError({
                invite_code: ' ',
              });
            }
          }
        },
      );
    } else {
      const { name, surname, username, traffic_code, position_id } = formObj.values;
      return authApi.signup({ name, surname, username, traffic_code, position_id, phone: getPhoneCode() + phone }).then(
        (response) => {
          dispatch(authActions.signupSuccess());
        },
        (error) => {
          if (error.message === 'This username is already taken')
            throw new SubmissionError({
              username: error.message,
            });
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="signup-page">
      <div className="signup-page__top-section">
        <Button
          withoutTheme
          type="button"
          className="signup-page__back-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{formatMessage('Fill in all the fields')}</div>
        <Input
          name="name"
          placeholder={formatMessage('Name')}
          validate={[required, letters, lengthMinMax(2, 15, formatMessage)]}
          customClass="signup-page__input"
        />
        <Input
          name="surname"
          placeholder={formatMessage('Surname')}
          validate={[required, letters, lengthMinMax(2, 30, formatMessage)]}
          customClass="signup-page__input"
        />
        <Input
          name="username"
          placeholder={formatMessage('Profile name')}
          validate={[required, characters, lengthMinMax(2, 15, formatMessage)]}
          customClass="signup-page__input"
        />
        <div className="signup-page__account-code-container">
          <Input
            name="traffic_code"
            placeholder={formatMessage('JTI Account Number')}
            validate={[required]}
            customClass="signup-page__input"
            InputButton={<InfoIcon className="signup-page__info-icon" onClick={openAccountCodeInfoModal}></InfoIcon>}
          />
        </div>
        <Select
          name="position_id"
          placeholder={formatMessage('Job title')}
          options={positions}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'position_id')}`}
        />
        <Input
          name="invite_code"
          placeholder={formatMessage('Invite code (optional)')}
          customClass="signup-page__input"
          validate={[inviteCodeLatin]}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme disabled={invalid}></ArrowButton>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;
  const inviteCode = state.router.location.query.inviteCode;

  return {
    formObj: form.signupForm || { values: {} },
    phone: form.enterPhoneForm.values.phone,
    initialValues: { traffic_code: '', invite_code: inviteCode || '' },
  };
};

const form = reduxForm({
  form: 'signupForm',
  destroyOnUnmount: false,
  asyncValidate,
  asyncChangeFields: ['traffic_code'],
  onSubmit: () => {},
})(SignupPageComponent);

export const SignupPageIreland = connect(mapStateToProps, null)(form);

import { RefObject } from 'react';

import { Button } from '../../../../components/common';
import { ComponentLike } from '../../../../types';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import styles from './TaskCard.module.scss';

type Props = {
  description: string;
  StatusIcon?: ComponentLike;
  photoFrame?: string;
  fileInputRef: RefObject<HTMLInputElement>;
  status: number;
  onCustomImageUploadTaskClick: (fileInputRef: RefObject<HTMLInputElement>) => void;
  attachmentsButtonEnabled: boolean;
};
const CustomTask = ({
  description,
  StatusIcon,
  photoFrame,
  fileInputRef,
  status,
  onCustomImageUploadTaskClick,
  attachmentsButtonEnabled,
}: Props) => {
  const { formatMessage } = useInternationalization();
  return (
    <div className={styles.task}>
      <div className={styles.taskTitle}>{description}</div>
      {StatusIcon && <StatusIcon className={styles.statusIcon} />}
      {attachmentsButtonEnabled && (
        <Button
          className={styles.taskButton}
          disabled={status !== 0}
          onClick={() => onCustomImageUploadTaskClick(fileInputRef)}
        >
          {formatMessage('Send a photo')}
        </Button>
      )}

      {!photoFrame && (
        <input
          type="file"
          hidden={true}
          ref={fileInputRef}
          onChange={() => onCustomImageUploadTaskClick(fileInputRef)}
          accept="image/*"
        />
      )}
      {status === 2 && (
        <span className={styles.moderationTitle}>
          {formatMessage('Your photo is uploaded and currently on moderation')}
        </span>
      )}
    </div>
  );
};

export default CustomTask;

import { ReactElement } from 'react';
import classnames from 'classnames';

import { BackArrowButton } from '../BackArrowButton/BackArrowButton';

import styles from './Header.module.scss';

export type HeaderProps = {
  children: ReactElement | string | null;
  customClass?: string;
  hasBackButton?: boolean;
  goBackCallback?: () => void;
  fixed?: boolean;
  buttonColor?: string;
};
export const Header = ({
  children,
  customClass,
  hasBackButton,
  goBackCallback,
  fixed,
  buttonColor = 'white',
  ...otherProps
}: HeaderProps) => {
  return (
    <div
      className={classnames('header-component', styles.header, hasBackButton ? '' : styles.withoutBack, customClass)}
      {...otherProps}
      style={{ position: fixed ? 'fixed' : 'sticky' }}
    >
      {hasBackButton && (
        <BackArrowButton
          withoutTheme
          onHistoryReplaceCallback={goBackCallback}
          customClass={styles.backButton}
          buttonColor={buttonColor}
        />
      )}
      {children}

      {hasBackButton && <div style={{ width: '21px', marginRight: '10px' }} />}
    </div>
  );
};

import { call, put, all, takeEvery, takeLatest } from 'redux-saga/effects';

import { settingsApi } from '../../api/settingsApi';
import { settingsActions } from './settingsSlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';

function* fetchSettingsSaga() {
  try {
    const { data } = yield call(settingsApi.getSettings);
    yield put(settingsActions.setSettings(data));
  } catch (err) {
    console.error('fetchSettingsSaga error', err);
  }
}

function* updateAppVersion({ payload: registration }) {
  try {
    yield put(
      modalActions.openPromptModal({
        title: 'New update is available',
        description: 'Reload app to update',
        btnText: 'Reload',
        isUpdateModal: true,
        onButtonClick: () => {
          if (registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          }
          window.location.reload(true);
        },
      }),
    );
  } catch (error) {
    console.error(error);
  }
}

function* sagas() {
  yield takeLatest(settingsActions.startFetchSettings, fetchSettingsSaga);
  yield all([takeEvery(settingsActions.updateApp, updateAppVersion)]);
}

export const settingsSagas = sagas;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { M10MainPage } from './M10MainPage';
import { profileActions } from '../../../redux/profile/profileSlice';

export const m10Steps = {
  moneyForTheCard: 1,
};

export const M10 = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileActions.getUserInfo());
  }, [dispatch]);

  let steps = [{ id: m10Steps.moneyForTheCard, Component: () => <M10MainPage /> }];

  const StepList = createStepListContainer(STEP_LIST_IDS.m10StepList, steps);

  return <StepList />;
};

export const BonusShopIcon = (props) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 11.0003H2V19.5C2 19.776 2.224 20 2.5 20H21.5C21.776 20 22 19.776 22 19.5V11.0003H23.5C23.776 11.0003 24 10.7763 24 10.5004V6.50045C24 6.22446 23.776 6.00047 23.5 6.00047H14.532C15.338 5.67248 16.704 4.99149 17.468 3.89452C17.968 3.17554 18.118 2.38056 17.904 1.59458C17.629 0.598608 16.636 -0.0853742 15.571 0.00862341C13.674 0.172619 12.182 1.94257 11.465 4.02052C10.812 2.43156 9.612 1.13659 8.116 1.0076C7.185 0.9246 6.322 1.52258 6.084 2.38956C5.896 3.06954 6.026 3.75653 6.459 4.37551C7.001 5.15449 7.899 5.68448 8.577 6.00047H0.5C0.224 6.00047 0 6.22446 0 6.50045V10.5004C0 10.7763 0.224 11.0003 0.5 11.0003ZM3 11.0003H10V19H3V11.0003ZM13 11.0003V19H11V11.0003H13ZM21 19H14V11.0003H21V19ZM15.755 1.0006C16.211 1.0006 16.776 1.26959 16.939 1.85958C17.078 2.36356 16.982 2.84155 16.648 3.32254C15.939 4.33951 14.408 5.02849 13.614 5.27449C12.938 5.48348 12.436 5.63948 12.065 5.76247C12.383 3.50253 13.808 1.16559 15.755 1.0006ZM7.279 3.80452C7.014 3.42653 6.939 3.05054 7.049 2.65555C7.166 2.22857 7.588 2.00357 8.02 2.00357C8.024 2.00357 8.027 2.00357 8.03 2.00357C9.504 2.13157 10.635 3.95852 10.928 5.76747C10.63 5.66948 10.25 5.55148 9.764 5.39948C9.112 5.19749 7.856 4.6335 7.279 3.80452ZM1 7.00044H23V10.0004H1V7.00044Z"
        fill="black"
      />
    </svg>
  );
};

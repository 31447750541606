import React from 'react';

import { Button } from '../../../../components/common';
import { ComponentLike } from '../../../../types';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import styles from './TaskCard.module.scss';

type Props = {
  description: string;
  StatusIcon?: ComponentLike;
  onTaskClick: () => void;
  attachmentsButtonEnabled: boolean;
  status: number;
};

const CustomTextAuto = ({ description, StatusIcon, status, onTaskClick, attachmentsButtonEnabled }: Props) => {
  const { formatMessage } = useInternationalization();
  return (
    <div className={styles.task}>
      <div className={styles.taskTitle}>{description}</div>
      {StatusIcon && <StatusIcon className={styles.statusIcon} />}
      {attachmentsButtonEnabled && (
        <Button className={styles.taskButton} disabled={status !== 0} onClick={onTaskClick}>
          {formatMessage('Enter the answer')}
        </Button>
      )}
    </div>
  );
};

export default CustomTextAuto;

import { useDispatch, useSelector } from 'react-redux';

import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { FAQ_ARTICLE_ID } from '../../../redux/knowledgeBank/knowledgeBankConstants';
import { getIsFetching, getUnicardAccountNumber } from '../../../redux/profile/profileSelectors';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { ContentSpinner } from '../../../components/common/Spinner/ContentSpinner';
import backgroundCreateWallet from '../../../images/background-images/unicard-bg-image.png';
import { AddCardPage } from './unicardAddCard';
import { UnicardMainPage } from './unicardMainPage';
import { ChooseCardNumberForm } from './unicardChooseCardNumber';
import { Settings } from './unicardSettingsPage';

import styles from './Unicard.module.scss';

export const unicardSteps = {
  addCardPage: 1,
  chooseCardNumber: 2,
  mainPage: 3,
  settings: 4,
};

export const Unicard = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetching);
  const unicardAccount = useSelector(getUnicardAccountNumber);

  const openHelpArticle = () => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jti-club.ge/How_to_use_Unicard.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  };

  const onButtonClick = () => {
    analyticHelper.addUnicardNumber();
    dispatch(bonusShopActions.getUnicardUserAccounts({}));
  };

  let steps = [
    {
      id: unicardSteps.addCardPage,
      Component: () => (
        <AddCardPage
          openHelpArticle={openHelpArticle}
          onButtonClick={onButtonClick}
          walletLink="https://www.unicard.ge/"
          backgroundImage={backgroundCreateWallet}
          description="We are syncing UNIWallet with your phone number linked to the app"
          title="Unicard"
          walletLinkText="How to create UNIWallet?"
        />
      ),
    },
    {
      id: unicardSteps.chooseCardNumber,
      Component: () => <ChooseCardNumberForm />,
    },
    { id: unicardSteps.mainPage, Component: () => <UnicardMainPage /> },
    { id: unicardSteps.settings, Component: () => <Settings /> },
  ];

  const transferSteps = [unicardSteps.mainPage, unicardSteps.settings];

  if (unicardAccount?.current_account) {
    dispatch(bonusShopActions.setUnicardUserAccount(unicardAccount.current_account));

    steps = steps.filter((step) => transferSteps.includes(step.id));
  }

  const StepList = createStepListContainer(STEP_LIST_IDS.unicardStepList, steps);

  return <div className={styles.container}>{isFetching ? <ContentSpinner /> : <StepList />}</div>;
};

import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

import './bonusShopLimitPage.scss';

import { Button } from '../../components/common/Button/Button';
import { Routes } from '../../const';

const BonusShopLimitComponent = ({ history }) => {
  const dispatch = useDispatch();
  return (
    <div className="bs-limit">
      <div className="bs-limit__top">
        <div className="bs-limit__header"></div>
        <div className="bs-limit__passport"></div>
        <div className="bs-limit__title">Так много было сделано, сколько еще предстоит вместе!</div>
        <div className="bs-limit__text">Для совершения заказа необходимо заполнить ваши паспортные данные</div>
      </div>

      <div className="bs-limit__bot">
        <Button className="bs-limit__later" onClick={() => dispatch(goBack())}>
          Позже
        </Button>
        <Button className="bs-limit__fill-now" onClick={() => history.replace(Routes.PassportSettings)}>
          Заполнить сейчас
        </Button>
      </div>
    </div>
  );
};

export const BonusShopLimitPage = withRouter(BonusShopLimitComponent);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BarCode, BarCodeState } from './recognitionTypes';

const initialState: BarCodeState = {
  barcode: {
    id: null,
    barcode: 0,
    pwa_animation: '',
    android_animation: '',
    background_image: '',
    model: '',
    brand: '',
    ndn_code: '',
    tar_content: 0,
    nicotine_content: 0,
    format: '',
    filter_type: '',
    price: 0,
    article_url: '',
  },
  isFetching: false,
  error: '',
};

const recognitionSlice = createSlice({
  name: 'recognition',
  initialState,
  reducers: {
    fetchBarcodeInfo: (
      state: BarCodeState,
      action: PayloadAction<{ barcode: number; callBack?: (status: boolean) => void }>,
    ): void => {},
    barcodeInfoReceived: (state: BarCodeState, action: PayloadAction<BarCode>) => {
      state.barcode = action.payload;
    },
    uploadUnknownBarcodePhoto: (
      state: BarCodeState,
      action: PayloadAction<{ barcode: string; formData: object; callBack?: (status: boolean) => void }>,
    ): void => {},
    sendUnknownBarcode: (state: BarCodeState, action: PayloadAction<{ barcode: string; photo: string }>): void => {},
    setIsFetching: (state: BarCodeState, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setError: (state: BarCodeState, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const recognitionActions = recognitionSlice.actions;
export const recognitionReducer = recognitionSlice.reducer;

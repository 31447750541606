import { useInternationalization } from '../../hooks/useTranslationHook';
import { IOrderDetails } from '../../types/IOrderDetails';

interface OrderPickupPointDetailsProps {
  className?: string;
  orderDetails?: Partial<IOrderDetails>;
}

const OrderPickupPointDetails = ({ className, orderDetails }: OrderPickupPointDetailsProps) => {
  const { t } = useInternationalization();

  if (!orderDetails?.pickup_point_url) {
    return null;
  }

  const { pickup_point_url, pickup_point_phone, pickup_point_contact, city } = orderDetails;

  return (
    <div className={className}>
      <p>{t('order_pickup_point_details.title')}</p>
      <div className="link">
        <a href={pickup_point_url} target="_blank">
          {t('order_pickup_point_details.location', { name: city })}
        </a>
      </div>
      <div className="link">
        <a href={`tel:${pickup_point_phone}`}>
          {t('order_pickup_point_details.contact', { name: pickup_point_contact })}
        </a>
      </div>
    </div>
  );
};

export default OrderPickupPointDetails;

import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { profileActions } from '../../redux/profile/profileSlice';
import { isAccepted } from '../../helpers/formValidations';
import { RootState } from '../../redux/store';

import { RadioButton } from '../../components/common/RadioButton';
import { ArrowButton } from '../../components/common/ArrowButton/ArrowButton';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';

import styles from './MonthlyConfirmation.module.scss';

const MonthlyConfirmation = (props: InjectedFormProps) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const formValues = useSelector((state: RootState) => state.form.monthlyConfirmation);
  const { invalid } = props;

  const handleAccept = () => {
    if (formValues.values) {
      const { age, tradeId } = formValues.values;

      const isValidationPassed = age && tradeId;

      dispatch(
        profileActions.updateMonthlyValidation({ userInfo: { is_monthly_validation_passed: isValidationPassed } }),
      );
    }
  };
  return (
    <>
      <div className={styles.page}>
        <div className={styles.title}>{formatMessage('Monthly Confirmation')}</div>
        <div className={styles.description}>
          {formatMessage(
            'To continue using our platform, please confirm the following details at the beginning of each month. This ensures we maintain a secure and compliant environment for all users.',
          )}
        </div>
        <form className={styles.checkboxBlock}>
          <div className={styles.point}>
            {/* @ts-ignore */}
            <RadioButton name="tradeId" validate={[isAccepted]} customclass={styles.radioInput} />
            <p className={styles.pointLabel}>{formatMessage('I confirm that I possess a valid Trade ID.')}</p>
          </div>
          <div className={styles.point}>
            {/* @ts-ignore */}

            <RadioButton name="age" validate={[isAccepted]} customclass={styles.radioInput} />
            <p className={styles.pointLabel}>{formatMessage('I confirm that I am over 20 years old.')}</p>
          </div>
        </form>
        {/* @ts-ignore */}
        <ArrowButton withoutTheme disabled={invalid} onClick={handleAccept} />
      </div>
      <DisclaimerFooter withoutNavigation customStyle={{ background: '#545F71' }} />
    </>
  );
};

export const MonthlyConfirmationPage = reduxForm({ form: 'monthlyConfirmation' })(MonthlyConfirmation);

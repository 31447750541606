import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';

import './dailyGame.scss';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { PostMessageEvents } from '../../enums';
import { selectCurrentDailyGame } from '../../redux/dailyGames/dailyGamesSelectors';
import { API_URL } from '../../api/baseApi';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';

export const DailyGame = () => {
  const dispatch = useDispatch();
  const dailyGame = useSelector(selectCurrentDailyGame);
  const [dailyGameUrl, setDailyGameUrl] = useState('');

  const handleMessageEvent = (event: MessageEvent) => {
    try {
      const { data } = event;
      if (data === PostMessageEvents.CloseIframe) {
        dispatch(goBack());
      } else if (data.action === PostMessageEvents.OpenRelatedArticle) {
        dailyGame?.article?.url && dispatch(knowledgeBankActions.openArticle({ article: dailyGame?.article }));
      } else if (data.payload && data.action === PostMessageEvents.QuestTaskClick) {
        dispatch(goBack());
      }
    } catch (error) {
      console.log('Handle message event error:', error);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessageEvent);

    try {
      if (dailyGame?.id && dailyGame?.url) {
        const { id, url } = dailyGame;
        const dailyGameUrl = new URL(url);
        const { searchParams } = dailyGameUrl;
        const authToken = localStorage.getItem('authToken');

        searchParams.set('api_url', `${API_URL}`);
        searchParams.set('daily_game_id', `${id}`);
        searchParams.set('token', `${authToken}`);

        setDailyGameUrl(dailyGameUrl.href);
      }
    } catch (error) {
      console.warn(error);
    }

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  return (
    <ScrollBlock fromTop>
      <iframe
        title="Daily Game"
        className="daily-game__content"
        frameBorder="no"
        height={window.innerHeight}
        width={window.innerWidth}
        src={dailyGameUrl}
      />
    </ScrollBlock>
  );
};

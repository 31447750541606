import { all, put, takeEvery, select, delay, takeLeading, takeLatest } from 'redux-saga/effects';

import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { SOLO_MODE_STEPS, SOLO_MODE_ACTION_TYPES } from './soloModeContants';
import { soloModeActions } from './soloModeSlice';
import { quizApi } from '../../api/quizApi';
import { checkCorrectAnswers } from '../game/gameSelectors';
import { getSoloModeQuestionsSelector, getAnswersSelector, getPackIdSelector } from './soloModeSelectors';
import { getBrandIdFromRouteSelector, getBonusGamesAmountSelector } from '../brands/brandSelectors';
import { Routes } from '../../const';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { push } from 'connected-react-router';
import { gameActions } from '../game/gameSlice';
import { APP_TYPES } from '../../const/appConstants';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import { CONFIG_KEYS, getConfigFor } from '../../config';

const locationSelector = (state) => state.router.location.pathname;

const isHungary = process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;

export const goToSoloModeStepAction = (stepId) =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.soloModeStepList,
    stepId,
  });

export const resetSoloModeStepList = () => {
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.soloModeStepList,
    stepId: 1,
  });
};

function* onFinishSoloModeSaga() {
  try {
    const pack_id = yield select(getPackIdSelector);
    const answers = yield select(getAnswersSelector);
    const questions = yield select(getSoloModeQuestionsSelector);
    const brand_id = yield select(getBrandIdFromRouteSelector);
    const checkedAnswers = checkCorrectAnswers(answers.me, questions);
    const correct_answers = checkedAnswers.filter((i) => i.isCorrect);
    const answersStats = [];

    checkedAnswers.forEach((answer) => {
      answersStats.push({ answer_id: answer.answer - 1, correct: answer.isCorrect });
    });

    const { data } = yield quizApi.finishSoloMode(correct_answers.length, pack_id, answersStats);
    yield amplitudeHelper.soloQuizFinish(answersStats, pack_id, data.coins);
    if (isHungary) yield put(gameActions.quizRatesOpenOrCloseModal(data.is_estimation_window_shown));
    yield put(soloModeActions.setCoinsForGame({ coins: data.coins }));
    const bonusGamesAmount = yield select(getBonusGamesAmountSelector, brand_id);
    yield put(soloModeActions.setFinishedNotBonusGame(bonusGamesAmount));
    const path = yield select(locationSelector);
    if (path.includes(Routes.SoloMode)) {
      yield delay(1500);
      yield put(goToSoloModeStepAction(SOLO_MODE_STEPS.RESULT));
    }
  } catch (error) {
    console.error(error);
  }
}

function* onStartSoloModeSaga({ payload }) {
  try {
    const { brandId } = payload;
    const { data } = yield quizApi.startSoloMode(brandId);
    yield put(
      soloModeActions.setSoloModeData({
        ...data,
        game_time: getConfigFor(CONFIG_KEYS.SOLO_QUIZ_GAME_TIME_IN_SECONDS),
      }),
    );
  } catch (error) {
    yield put(push(Routes.EmptyRoute));
    yield put(modalActions.openQuizGameErrorModal());
    console.error(error);
  }
}

function* startSoloModeGameSaga() {
  yield delay(3000);
  const questions = yield select(getSoloModeQuestionsSelector);
  if (questions) {
    yield put(goToSoloModeStepAction(SOLO_MODE_STEPS.GAME));
  }
}

function* onResetSaga() {
  yield put(stepListActions.resetStepList());
}

function* sagas() {
  yield all([
    takeEvery(SOLO_MODE_ACTION_TYPES.START_SOLO_MODE, onStartSoloModeSaga),
    takeLeading(SOLO_MODE_ACTION_TYPES.FINISH_SOLO_MODE, onFinishSoloModeSaga),
    takeEvery(SOLO_MODE_ACTION_TYPES.RESET, onResetSaga),
    takeLatest(SOLO_MODE_ACTION_TYPES.START_GAME, startSoloModeGameSaga),
  ]);
}

export const soloModeSagas = sagas;

import { createEntityAdapter, createSlice, EntityState, PayloadAction, Update } from '@reduxjs/toolkit';
import { Ticket } from '../chatTypes';

interface SupportState {
  isFetching: boolean;
  tickets: EntityState<Ticket>;
}

export const ticketAdapter = createEntityAdapter<Ticket>();

const initialState: SupportState = {
  isFetching: true,
  tickets: ticketAdapter.getInitialState(),
};

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    fetchTickets: (): void => {},
    createTicket: (): void => {},
    updateTicket: (state, action: PayloadAction<Update<Ticket>>) => {
      ticketAdapter.updateOne(state.tickets, action);
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isFetching: action.payload,
    }),
    ticketsReceived: (state, action: PayloadAction<Ticket[]>): void => {
      ticketAdapter.upsertMany(state.tickets, action);
    },
    reset: () => ({
      ...initialState,
    }),
  },
});

export const supportActions = supportSlice.actions;
export const supportReducer = supportSlice.reducer;

import { ArrowIconRounded } from '../../../../../icons/arrow-icon-rounded';

import { ButtonViewProps } from '../../types';
import styles from './Arrow.module.scss';

export const Arrow = ({ children }: ButtonViewProps) => (
  <div className={styles.arrow}>
    <ArrowIconRounded className={styles.arrow} />
  </div>
);

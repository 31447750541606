import { all } from '@redux-saga/core/effects';
import { push } from 'react-router-redux';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { Routes } from '../../const';
import { IDailyGame } from './dailyGamesTypes';
import { dailyGamesActions } from './dailyGamesSlice';

function* openDailyGame(action: PayloadAction<{ dailyGame: IDailyGame }>) {
  try {
    yield put(dailyGamesActions.setCurrentDailyGame(action.payload.dailyGame));
    yield put(push(Routes.DailyGame));
  } catch (err) {
    console.error('openDailyGame error', err);
  }
}

export function* dailyGamesSagas() {
  yield all([takeLatest(dailyGamesActions.openDailyGame.type, openDailyGame)]);
}

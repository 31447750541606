import { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { length, required } from '../../../helpers/formValidations';
import { isAzerbaijan } from '../../../helpers';

import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import {
  getCurrentCoinsReserved,
  getIsFetching,
  selectErrorMessage,
} from '../../../redux/bonusShop/bonusShopSelectors';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';

import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { Button } from '../../common/Button';
import { CodeInput } from '../../common/CodeInput';

import './SMSCodeInputModal.scss';

export let SMSCodeInputModal = (props) => {
  const { invalid, handleSubmit, reset } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const currentCoinsReserved = useSelector(getCurrentCoinsReserved);
  const smsCode = useSelector((s) => s.form?.smsCode?.values?.smsCode);
  const isFetching = useSelector(getIsFetching);
  const errorMessage = useSelector(selectErrorMessage);

  const [asyncError, setAsyncError] = useState(false);

  const error = (errorMessage && smsCode) || asyncError;

  let {
    data: { onClose, makeTransfer },
  } = useSelector(getModalDataSelector);

  const onButtonClick = () => {
    if (makeTransfer) {
      makeTransfer(currentCoinsReserved, smsCode, setAsyncError);
      return;
    }
    dispatch(bonusShopActions.makeOppaTransfer({ amount: currentCoinsReserved, code: smsCode }));
  };

  useEffect(() => {
    return reset();
  }, [dispatch]);

  if (isAzerbaijan()) {
    useEffect(() => {
      dispatch(bonusShopActions.setErrorMessage(''));
    }, [smsCode]);
  }

  return (
    <ModalContainer modal={MODALS.SMSCodeInput} onClose={onClose}>
      <div className="OPPA-SMS-modal">
        <div className="OPPA-SMS-modal__title">{formatMessage('Enter the code we sent to your mobile number')}</div>
        <form onSubmit={handleSubmit} className="OPPA-SMS-modal__form">
          <CodeInput
            name="smsCode"
            customClass={error ? 'OPPA-SMS-modal__form-input-error' : 'OPPA-SMS-modal__codeInput input-border-theme'}
            numberOfInputs={4}
            maxLength={1}
            typeInput="number"
            validate={[required, length(4)]}
          />
          {error && <div className={'OPPA-SMS-modal__form-error'}>{formatMessage(asyncError || errorMessage)}</div>}
        </form>
        <Button customClass="OPPA-SMS-modal__btn" onClick={onButtonClick} disabled={invalid || isFetching}>
          {formatMessage('Proceed')}
        </Button>
      </div>
    </ModalContainer>
  );
};

SMSCodeInputModal = reduxForm({
  form: 'smsCode',
  destroyOnUnmount: false,
  onSubmit: () => {},
})(SMSCodeInputModal);

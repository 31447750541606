import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'is-empty-typed';

import {
  isArticleMessage,
  isCategoryMessage,
  isHappyBirthdayMessage,
  isNotificationMessage,
  isPromoCodeMessage,
  isDailyGameMessage,
  isVerificationMessage,
  isChallengeMessage,
  isPassportMessage,
} from '../../../../helpers/checkMessageType';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';
import { promoCodeActions } from '../../../../redux/promoCode/promoCodeSlice';
import { specialQuizActions } from '../../../../redux/specialQuizes/specialQuizesSlice';
import { multibrandQuizActions } from '../../../../redux/multibrandQuiz/multibrandQuizSlice';
import { surveyActions } from '../../../../redux/survey/surveySlice';
import { birthdayActions } from '../../../../redux/birthday/birthdaySlice';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { dailyGamesActions } from '../../../../redux/dailyGames/dailyGamesSlice';
import { verificationActions } from '../../../../redux/verification/verificationSlice';
import { getPosition } from '../../../../helpers/getPosition';
import { getVerificationInfo } from '../../../../redux/verification/verificationSelectors';
import { challengeActions } from '../../../../redux/challenge/challengeSlice';

import { ChatMessage as ChatMessageModel } from '../../../../redux/chat/chatTypes';
import { NotificationMessage, ChatMessage } from '../../views';
import { Routes } from '../../../../const';
import { VERIFICATION_MODE } from '../../../../const/verificationConstants';
import { amplitudeHelper } from '../../../../helpers/ampitudeHelper';

type Props = {
  message: ChatMessageModel;
  className?: string;
  isNotification?: boolean;
  date: string;
};

export const Message = ({ message, className, isNotification = false, date }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { verification_mode: verificationMode } = useSelector(getVerificationInfo);

  const { link, type, article, code, categoryId, brandId, dailyGame, challengeId, taskId } = message;
  const specialQuizId = article?.special_quiz_id;
  const isMultibrandQuiz = specialQuizId && article?.is_multibrand_quiz;

  const isButtonShown =
    !!specialQuizId ||
    isArticleMessage(type) ||
    isPromoCodeMessage(type) ||
    isCategoryMessage(type) ||
    isHappyBirthdayMessage(type) ||
    isDailyGameMessage(type) ||
    isVerificationMessage(type) ||
    (isNotificationMessage(type) && !!link) ||
    isChallengeMessage(type) ||
    isPassportMessage(type);

  const handleInactiveKBInstance = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Oops!',
        description: 'Seems like this publication is no longer available. You can try more recent one',
        btnText: 'Got it',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const handleArticleMessage = () => {
    if (isEmpty(article)) {
      return;
    }

    const { is_multibrand_quiz, is_new, survey_id, special_quiz_id } = article;
    dispatch(knowledgeBankActions.setCurrentArticle(article));

    if (article.status === 0) {
      handleInactiveKBInstance();
    } else if (is_multibrand_quiz) {
      dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id: special_quiz_id, is_new })); // TODO: check on a region other than Ireland
    } else if (survey_id) {
      dispatch(surveyActions.startCertainSurvey({ survey_id }));
    } else {
      dispatch(knowledgeBankActions.openArticle({ article }));
    }
  };

  const handleSpecialQuizMessage = () => {
    if (!isEmpty(article)) {
      dispatch(knowledgeBankActions.setCurrentArticle(article));

      if (article.status === 0) {
        handleInactiveKBInstance();
      } else {
        dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id: article.special_quiz_id, brand_id: brandId }));
      }
    }
  };

  const handlePromoCodeMessage = () => {
    dispatch(promoCodeActions.postPromoCode({ code })); // TODO: check on a region other than Ireland
  };

  const handleMessageWithLink = () => {
    history.push(link as string);
  };

  const handleCategoryMessage = () => {
    history.replace(`${Routes.KnowledgeBankRoute}/${categoryId}`);
  };

  const handleHappyBirthdayMessage = () => {
    dispatch(birthdayActions.sendBirthdayCoins());
  };

  const handleDailyGameMessage = () => {
    dailyGame?.url && dispatch(dailyGamesActions.openDailyGame({ dailyGame }));
  };

  const handleVerificationMessage = () => {
    if (verificationMode && navigator.geolocation) {
      getPosition()
        .then((position) => {
          const coordinates = position.coords;
          dispatch(
            verificationActions.setVerificationMode({
              mode: VERIFICATION_MODE.VERIFICATION_AUTO,
              geo_allowed: true,
              latitude: coordinates.latitude,
              longitude: coordinates.longitude,
            }),
          );
        })
        .catch(() => {
          dispatch(
            verificationActions.setVerificationMode({
              mode: VERIFICATION_MODE.VERIFICATION_AUTO,
              geo_allowed: false,
            }),
          );
        });
    }
    if (!verificationMode) {
      history.push('/check-in-info');
    }
  };

  const handleChallengeMessage = () => {
    dispatch(
      challengeActions.sendAttachmentsForCheck({
        taskId,
        taskType: 'LINK_TO_CHAT',
        quest_id: challengeId,
        status: 1,
      }),
    );
  };

  const handlePassportMessage = () => {
    history.push(Routes.ProfileSettingsRoute);
  };

  const handleButtonClick = (): void => {
    isNotification && amplitudeHelper.chatContinue();

    if (isArticleMessage(type) || isMultibrandQuiz) {
      handleArticleMessage();
    } else if (specialQuizId) {
      handleSpecialQuizMessage();
    } else if (isPromoCodeMessage(type)) {
      handlePromoCodeMessage();
    } else if (isCategoryMessage(type)) {
      handleCategoryMessage();
    } else if (link) {
      if (isChallengeMessage(type)) {
        handleChallengeMessage();
      }
      handleMessageWithLink();
    } else if (isHappyBirthdayMessage(type)) {
      handleHappyBirthdayMessage();
    } else if (isDailyGameMessage(type)) {
      handleDailyGameMessage();
    } else if (isVerificationMessage(type)) {
      handleVerificationMessage();
    } else if (isPassportMessage(type)) {
      handlePassportMessage();
    }
  };

  const Component = isNotification ? NotificationMessage : ChatMessage;

  return (
    <Component
      message={message}
      onButtonClick={handleButtonClick}
      isButtonShown={isButtonShown}
      className={className}
      date={date}
    />
  );
};

import { Link } from 'react-router-dom';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { analyticHelper } from '../../../../helpers/analyticHelper';
import { IChallenge as ChallengeModel } from '../../../../redux/challenge/challengeTypes';
import useChallengeTimeLeft from '../../../../hooks/useChallengeTimeLeftHook';

import { ArrowIcon } from '../../../../icons/arrow-icon';
import { Routes } from '../../../../const';

import styles from './ChallengeCard.module.scss';

type Props = {
  challenge: ChallengeModel;
  defaultBackground: string;
};

export const ChallengeCard = ({ challenge, defaultBackground }: Props) => {
  const { formatMessage } = useInternationalization();
  const { id, name, backgroundImage } = challenge;
  const challengeTimeRemain = useChallengeTimeLeft(challenge);

  const background = backgroundImage || defaultBackground;

  const openChallenge = () => {
    analyticHelper.onOpenChallenge(name);
  };

  return (
    <div className={styles.challengeCard} style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
      <Link to={`${Routes.ChallengeRoute}/${id}`} className={styles.link} onClick={openChallenge}>
        <div className={styles.challenge}>
          <div className={styles.challengeName}>{name}</div>
          <div className={styles.timeRemains}>
            {`${challengeTimeRemain.days}${formatMessage('d')} ` +
              `${challengeTimeRemain.hours}${formatMessage('h')} ` +
              `${challengeTimeRemain.minutes}${formatMessage('m')} `}
          </div>
        </div>
        <ArrowIcon style={{ fill: 'white' }} />
      </Link>
    </div>
  );
};

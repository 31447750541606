import { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import isEmpty from 'is-empty-typed';
import { useHistory } from 'react-router';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { isIPhoneWithHomeBar } from '../../../../helpers/checkIsIphoneX';
import { required } from '../../../../helpers/formValidations';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { getIsFetching, getUserAccountsSelector } from '../../../../redux/bonusShop/bonusShopSelectors';

import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { BackButton } from '../../../../components/common';
import { RadioButtonInput } from '../../../../components/common/CommonRadioButton';
import { ArrowButton } from '../../../../components/common/ArrowButton/ArrowButton';
import { ContentSpinner } from '../../../../components/common/Spinner/ContentSpinner';
import unicardLogo from '../../../../images/unicard-logo.png';
import { Routes } from '../../../../const';

import styles from './ChooseCardNumber.module.scss';

export const ChooseCardNumber = ({ handleSubmit, invalid, reset }: InjectedFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useInternationalization();

  const isFetching = useSelector(getIsFetching);
  const userAccounts = useSelector(getUserAccountsSelector);

  const onSubmit = (event: any) => {
    const { cardNumber } = event;
    dispatch(bonusShopActions.setUnicardWallet({ wallet_number: cardNumber.answer, unicardLogo }));
  };

  const onBack = () => {
    history.push(Routes.Unicard);
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, [dispatch]);

  return (
    <div className={styles.chooseCardNumberPage}>
      <BackButton className={styles.backButton} onClick={onBack} />
      {isFetching || isEmpty(userAccounts) ? (
        <ContentSpinner />
      ) : (
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <p className={styles.title}>{formatMessage('Select your UNIWallet')}</p>
            <p className={styles.description}>{formatMessage('You can change the wallet in Settings in any time')}</p>
          </div>
          <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingBottom: isIPhoneWithHomeBar() ? '49px' : '15px' }}
          >
            <RadioButtonInput
              name={'cardNumber'}
              options={userAccounts}
              validate={required}
              customClass={styles.radioButton}
            />
            <ArrowButton withoutTheme customClass={styles.button} fillcolor="#01c1b6" disabled={invalid} />
          </form>
        </div>
      )}
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

export const ChooseCardNumberForm = reduxForm({ form: 'unicard', destroyOnUnmount: false, onSubmit: () => {} })(
  ChooseCardNumber,
);

import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';

import './faqCheckIn.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { ScrollBlock } from '../../../components/common/ScrollBlock/ScrollBlock';
import faqCheckInImage from './faqCheckInImage.png';

export const CheckInFAQ = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  return (
    <div className="check-in-page">
      <ScrollBlock fromTop>
        <div className="faq-check-in__component">
          <div className="check-in__top-section">
            <Button
              withoutTheme
              type="button"
              className="check-in__top-button"
              onClick={() => {
                dispatch(goBack());
              }}
            >
              <ArrowIconRounded fillColor="#fff" className="check-in__top-arrow" />
            </Button>
            <div className="check-in__img-section">
              <img alt="img" src={faqCheckInImage} className="check-in__image" />
            </div>
            <h1 className="check-in__title">{formatMessage("Don't forget")}</h1>
            <div className="check-in__description-section">
              <p className="check-in__text">
                {formatMessage(
                  'Go into the application and go through the stages of the check-in as soon as you start the working day.',
                )}
              </p>
              <p className="check-in__text">
                {formatMessage('For the passage of the check-in you can choose any one week in two months period.')}
              </p>
              <p className="check-in__text">
                {formatMessage(
                  'The check-in is important to take place every two months in order to continue using the application.',
                )}
              </p>
              <p className="check-in__text">
                {formatMessage(
                  "If you completed less than 4 stages per week the check-in doesn't count. In this case, it must be restarted again the next week.",
                )}
              </p>
              <p className="check-in__text">
                {formatMessage(
                  'A check-in is only necessary to confirm that you are working at a point of sale specified in your Profile. JTI-Club takes care of the inviolability of your personal data and guarantees their reliable protection against contact with third parties.',
                )}
              </p>
              <p className="check-in__text">{formatMessage('Good luck!')}</p>
            </div>
            <div className="faq-check-in__bot-section">
              <Button
                withoutTheme
                customClass={'faq-check-in__submit'}
                onClick={() => {
                  dispatch(goBack());
                }}
              >
                {formatMessage('Remember')}
              </Button>
            </div>
          </div>
        </div>
      </ScrollBlock>
    </div>
  );
};

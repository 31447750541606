import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { reset } from 'redux-form';

import styles from './PasswordEnterPage.module.scss';
import { AUTH_STEPS, STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { getPhoneCode } from '../../../redux/auth/authConstants';
import { CommonInput } from '../../../components/common/Input';
import { authActions } from '../../../redux/auth/authSlice';
import { Button } from '../../../components/common/Button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordEnterSchema } from '../../../validation/PasswordEnterSchema';
import { modalActions } from '../../../components/containers/Modal/modalSlice';

interface PasswordEnterFormData {
  password: string;
}

const PasswordEnterPage = () => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();

  const phone = useSelector(({ form }: any) => form?.enterPhoneForm?.values?.phone);

  const formattedPhone = useMemo(() => `${getPhoneCode()}${phone}`, [phone]);

  const { control, handleSubmit } = useForm<PasswordEnterFormData>({
    // @ts-ignore https://github.com/react-hook-form/resolvers/issues/643
    resolver: yupResolver<PasswordEnterFormData>(passwordEnterSchema),
    defaultValues: {
      password: '',
    },
  });

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: AUTH_STEPS.ENTER_PHONE,
      }),
    );
  }, [dispatch]);

  const onSubmit = async ({ password }: PasswordEnterFormData) => {
    try {
      const data = {
        password,
        phone: formattedPhone,
      };

      dispatch(authActions.signIn(data));
    } catch (error: any) {
      console.warn(error);
    }
  };

  const displayPasswordResetModal = () => {
    dispatch(
      modalActions.openPromptModal({
        title: t('password_reset_modal.title'),
        description: t('password_reset_modal.description', { phone: formattedPhone }),
        btnText: t('OK'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.resetPassword({ phone: formattedPhone }));
        },
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="signup-page">
      <div className="signup-page__top-section">
        <Button withoutTheme type="button" className="signup-page__back-button" onClick={onBackCallback}>
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">
          {t('password_enter_page.title')}
          <br />
          {formattedPhone}
        </div>
        {/* @ts-ignore https://github.com/react-hook-form/react-hook-form/issues/6679 */}

        <Controller
          control={control}
          name="password"
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <CommonInput
              type="password"
              meta={{ error: error && t(error?.message), touched: true }}
              input={{ value, onChange }}
              placeholder={t('common.password')}
              customClass="signup-page__input"
            />
          )}
        />

        <div onClick={displayPasswordResetModal} className={styles.forgotPassword}>
          {t('password_enter_page.forgot_password')}
        </div>
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme customClass={undefined} />
      </div>
    </form>
  );
};

export default PasswordEnterPage;

import axios from 'axios';
import { API_URL } from './baseApi';

export const moderationApi = {
  getUsersCount: async () => {
    return await axios.get(`${API_URL}/v3/users-moderation/count`);
  },
  getUsersForModeration: async () => {
    return await axios.get(`${API_URL}/v3/users-moderation`);
  },
  moderateUsers: async (userIds: number[]) => {
    return await axios.put(`${API_URL}/v3/users-moderation/moderate`, { user_ids: userIds });
  },
  deleteUsers: async (userIds: number[]) => {
    return await axios.put(`${API_URL}/v3/users-moderation/delete`, { user_ids: userIds });
  },
};

import { useSelector } from 'react-redux';

import GoalTrackerInfo from './GoalTrackerInfo';
import { IGoalTracker } from './GoalTrackerTypes';
import { getGoalTrackers } from '../../redux/profile/profileSelectors';

import styles from './GoalTracker.module.scss';

const GoalTracker = () => {
  const goalTrackers = useSelector(getGoalTrackers);
  return (
    <>
      {!!goalTrackers && !!goalTrackers.length && (
        <div className={styles.goalTrackerContainer}>
          {goalTrackers?.map((goalTracker: IGoalTracker) => (
            <GoalTrackerInfo key={goalTracker.id} goalTracker={goalTracker} />
          ))}
        </div>
      )}
    </>
  );
};
export default GoalTracker;

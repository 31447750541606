export enum UserStatus {
  Active = 1,
  WaitingModeration = 2,
  Blocked = 4,
}

export interface IModerationState {
  isFetching: boolean;
  count: number;
  users: IUser[];
}

export interface IUser {
  id: number;
  name: string;
  surname: string;
  status: UserStatus;
  outlet_code: number;
}

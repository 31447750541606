import { RootState } from '../store';
import { IQuickTip, ResultType } from './quickTipsTypes';

const getQuickTip = (state: RootState): IQuickTip | null => state.quickTips.quickTip;
const getIsFetching = (state: RootState): boolean => state.quickTips.isFetching;
const getCurrentAnswer = (state: RootState): string => state.form.quickTipsForm?.values?.value;
const getResult = (state: RootState): ResultType | null => state.quickTips.result;
const getErrorText = (state: any): any => state.form?.quickTipsForm?.syncErrors?.value;
const getIsTouched = (state: RootState): boolean | undefined => state.form?.quickTipsForm?.fields?.value?.touched;

export const quickTipsSelectors = {
  getQuickTip,
  getIsFetching,
  getCurrentAnswer,
  getResult,
  getErrorText,
  getIsTouched,
};

import React from 'react';
import { IKBCategory } from '../../knowledgeBankTypes';
import { useSelector } from 'react-redux';
import { getCategoriesSelector } from '../../../../redux/knowledgeBank/knowledgeBankSelectors';
import { KnowledgeBankCategory } from '../../../../components';
import { KBSection } from '../../knowledgeBankConstants';

type Props = {
  chooseCategory: (categoryId: number) => void;
};

export const KnowledgeBankSection = ({ chooseCategory }: Props) => {
  const categories = useSelector(getCategoriesSelector);

  return (
    <>
      {categories.length &&
        categories
          .filter((category: IKBCategory) => category.section === KBSection.KnowledgeBankHungary && category)
          .map((category: IKBCategory) => (
            <KnowledgeBankCategory key={category.id} category={category} onChoose={() => chooseCategory(category.id)} />
          ))}
    </>
  );
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { challengeActions } from '../../../../redux/challenge/challengeSlice';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { challengeSelectors } from '../../../../redux/challenge/challengeSelectors';

import { Header } from '../../../../components/common/Header';
import { NavigationMenu } from '../../../../components';
import { ChallengeCard } from '../../components';
import { LoaderContainer } from '../../../../components/common';
import bg1 from '../../../../images/background-images/quest-bg-image-1.png';
import bg2 from '../../../../images/background-images/quest-bg-image-2.png';
import bg3 from '../../../../images/background-images/quest-bg-image-3.png';
import bg4 from '../../../../images/background-images/quest-bg-image-4.png';

import styles from './Challenges.module.scss';

type Props = {
  location: RouteComponentProps;
};

export const Challenges = ({ location }: Props) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const challenges = useSelector(challengeSelectors.selectChallenges);
  const isFetching = useSelector(challengeSelectors.selectIsFetching);

  const defaultBackgrounds = [bg1, bg2, bg3, bg4];

  const getDefaultBackground = (index: number) => {
    return defaultBackgrounds[index % defaultBackgrounds.length];
  };

  useEffect(() => {
    dispatch(challengeActions.getChallenges());
  }, [dispatch]);

  return (
    <div className={styles.challengesPage}>
      <Header hasBackButton buttonColor="white">
        <p className={styles.headerTitle}>{formatMessage('Active challenges')}</p>
      </Header>
      <LoaderContainer isLoading={isFetching} className={styles.loaderContainer}>
        <div className={styles.challengeCards}>
          {challenges &&
            challenges.map((challenge, index) => (
              <ChallengeCard key={challenge.id} challenge={challenge} defaultBackground={getDefaultBackground(index)} />
            ))}
        </div>
      </LoaderContainer>
      <NavigationMenu location={location} />
    </div>
  );
};

import styles from './PersonalChallenge.module.scss';

type Props = {
  description: string;
  name: string;
  photo: string;
};

export const PersonalChallenge = ({ description, name, photo }: Props) => {
  return (
    <div className={styles.personalChallenge}>
      <div className={styles.titleBlock}>
        <div className={styles.personalChallengeTitle}>{description}</div>
        <div className={styles.personalChallengeDescription}>{name}</div>
      </div>
      <img src={photo} alt="challenge photo" className={styles.challengePhoto} />
    </div>
  );
};

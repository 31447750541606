import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import { MODALS } from '../../containers/Modal/modalConstants';
import { modalActions } from '../../containers/Modal/modalSlice';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { Button } from '../../common';

import styles from './DailyBonusModal.module.scss';
import dailyBonusImage from '../../../images/dailyBonusImage.png';
import { getSettingsSelector } from '../../../redux/settings/settingsSelector';
const customStyles = {
  maxWidth: '350px',
};

const DailyBonusModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const settingsSelector = useSelector(getSettingsSelector);

  const onClick = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <ModalContainer modal={MODALS.dailyBonus} customStyles={customStyles}>
      <div className={styles.container}>
        <span className={styles.title}>{formatMessage('Daily Bonus Received!')}</span>
        <div className={styles.imgContainer}>
          <img className={styles.image} src={dailyBonusImage} alt="like" />
        </div>
        <span className={styles.description}>
          {formatMessage("Congratulations! You've earned {coins} points for checking in today.", {
            coins: settingsSelector?.daily_bonus,
          })}
        </span>
        <Button className={styles.acceptButton} onClick={onClick}>
          <div className={styles.buttonComponent}>{formatMessage('Got it!')}</div>
        </Button>
      </div>
    </ModalContainer>
  );
};

export default DailyBonusModal;

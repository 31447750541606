import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { profileActions } from '../../redux/profile/profileSlice';
import {
  getManagerInfoSelector,
  getUserInfoSelector,
  getUserOutletInfoSelector,
} from '../../redux/profile/profileSelectors';
import { analyticHelper } from '../../helpers/analyticHelper';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { formatDate } from '../../helpers';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { UserInfoForm } from './userInfoForms/userInfoFormBelarus';
import { ProfileSettingsBottomAreaBelarus } from './profileSettingsBottomArea/profileSettingsBottomAreaBelarus';
import { ProfileRulesLinks } from '../profile/profileRulesLinks/profileRulesLinks';

import './profileSettings.scss';

export const ProfileSettingsBelarus = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const userInfo = useSelector(getUserInfoSelector);
  const positions = useSelector((state) => state.service.positions);
  const managersCities = useSelector((state) => state.service.managersCities);
  const theme = useSelector((state) => state.service.theme);
  const manager = useSelector(getManagerInfoSelector);
  const outlet = useSelector(getUserOutletInfoSelector);

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const handleSaveChanges = (values) => {
    const changedUserInfo = {
      username: values.username,
      date_of_birth: values.date_of_birth,
    };

    localStorage.setItem('themeId', theme.id);
    dispatch(profileActions.changeUserInfo({ userInfo: changedUserInfo }));
  };

  return (
    <div className="profile-settings-page">
      <div
        className="profile-settings-page__content"
        style={{ minHeight: isMobileSafari() ? 'calc(100% - 60px)' : 'unset' }}
      >
        {userInfo.id && (
          <UserInfoForm
            positions={positions}
            managersCities={managersCities}
            onSubmit={handleSaveChanges}
            show_passport_menu={userInfo.show_passport_menu}
            initialValues={{
              username: userInfo.username,
              traffic_code: userInfo.traffic_code,
              manager_name: `${manager.name} ${manager.surname}`,
              city: `${outlet.city.name}`,
              legal_entity: `${outlet.legal_entity.name}`,
              address: `${outlet.outlet_address.name}`,
              passport: formatMessage('View'),
            }}
          />
        )}
        <ProfileRulesLinks />
        <ProfileSettingsBottomAreaBelarus />
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

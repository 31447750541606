import { useDispatch } from 'react-redux';

import './oppaTutorial.scss';

import { checkIsIphoneX } from '../../../../helpers/checkIsIphoneX';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { oppaTutorialSteps } from './oppaTutorial';
import { SkipIcon } from '../../../../icons/skip-icon';

export const OppaTutorialStep3 = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const handleSkip = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure you want to finish?',
        btnText: 'Yes, exit',
        onButtonClick: () => {
          localStorage.setItem('oppaCompletedTutorial', true);
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.oppaTutorialStepList,
              stepId: oppaTutorialSteps.stepNull,
            }),
          );
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className={`oppa-tutorial__blackout-step3${checkIsIphoneX() ? '-iphoneX' : ''}`}>
      <div className="oppa-tutorial__skip" onClick={() => handleSkip()}>
        {formatMessage('Skip')}
        <SkipIcon className="oppa-tutorial__skip-icon" />
      </div>
      <div className="oppa-tutorial__descriptionBox-top">
        <div className="achievements-tutorial__descriptionBox-step">2/5</div>
        <div className="oppa-tutorial__descriptionBox-header">{formatMessage('Coins redemption')}</div>
        <div className="oppa-tutorial__descriptionBox-description">
          {formatMessage('Tap the button below to start. Choose the amount of money using buttons “+” and “–”')}
        </div>
      </div>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { NavigationMenu } from '../../../components';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { KnowledgeBankSection } from './components/KnowledgeBankSection';
import { BrandingBanSection } from './components/BrandingBanSection';
import { getCategoriesSelector } from '../../../redux/knowledgeBank/knowledgeBankSelectors';
import { IKBCategory } from '../knowledgeBankTypes';
import { Routes } from '../../../const';
import { KBSection } from '../knowledgeBankConstants';
import { KnowledgeBank } from '../KnowledgeBank/KnowledgeBank';

import styles from './KnowledgeBankHungary.module.scss';

export const KnowledgeBankHungary = React.memo(() => {
  const [activePage, setActivePage] = useState<number>(0);
  const { formatMessage } = useInternationalization();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const categories = useSelector(getCategoriesSelector);

  const brandingBan = categories.filter((category: IKBCategory) => category.section === KBSection.BrandingBan);
  const isShownBrandingBan = brandingBan.length !== 0;

  const setCurrentLocation = (index: number) => {
    switch (index) {
      case 0:
      default:
        history.push(Routes.KnowledgeBankRoute);
        return;
      case 1:
        history.push(Routes.BrandingBanRoute);
        return;
    }
  };

  const currentIndexOfPage = (location: string) => {
    switch (location) {
      case Routes.KnowledgeBankRoute:
      default:
        return 0;
      case Routes.BrandingBanRoute:
        return 1;
    }
  };

  const chooseCategory = useCallback(
    (selectedCategoryId: number) => {
      dispatch(knowledgeBankActions.chooseCategory({ selectedCategoryId }));
      analyticHelper.selectKBCategory(selectedCategoryId);
    },
    [dispatch],
  );

  useEffect(() => {
    setActivePage(currentIndexOfPage(location.pathname));
  }, [location]);

  useEffect(() => {
    dispatch(knowledgeBankActions.getCategories());
  }, []);

  return (
    <div>
      <ScrollBlock fromTop>
        {isShownBrandingBan && (
          <div className="profile-page__swipeable-header">
            <div
              onClick={() => setCurrentLocation(0)}
              className={activePage === 0 ? 'profile-page__active-tab' : 'profile-page__tab'}
            >
              {formatMessage('Knowledge Bank')}
            </div>
            <div
              onClick={() => setCurrentLocation(1)}
              className={activePage === 1 ? 'profile-page__active-tab' : 'profile-page__tab'}
            >
              {formatMessage('Branding ban')}
            </div>
          </div>
        )}
        {isShownBrandingBan ? (
          <div className={styles.swiperContainer}>
            <SwipeableViews
              index={activePage}
              id="swipable-views"
              onSwitching={(index) => setCurrentLocation(index)}
              className="profile-page__views"
            >
              <KnowledgeBankSection chooseCategory={chooseCategory} />
              <BrandingBanSection chooseCategory={chooseCategory} />
            </SwipeableViews>
          </div>
        ) : (
          <KnowledgeBank />
        )}
        <NavigationMenu location={location} />
      </ScrollBlock>
    </div>
  );
});

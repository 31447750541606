import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { Button } from '../../../../components/common';

import styles from './ModerationBlock.module.scss';

type Props = {
  usersForModerationCount: number;
  onDeleteUser: () => void;
  onModerateUser: () => void;
};

export const ModerationBlock = ({ usersForModerationCount, onModerateUser, onDeleteUser }: Props) => {
  const { formatMessage } = useInternationalization();

  const hasUsersForModeration = usersForModerationCount > 0;

  return (
    <div className={styles.block}>
      <div className={styles.titleBlock}>
        <span className={styles.title}>
          {hasUsersForModeration
            ? formatMessage(`You’ve select {count} users`, { count: usersForModerationCount })
            : formatMessage(`Select users for moderation or delete`)}
        </span>
        <span className={styles.subTitle}>
          {formatMessage('You can change the status of a user in the admin panel')}
        </span>
      </div>

      <div className={styles.buttonsBlock}>
        <Button className={styles.moderateButton} disabled={!hasUsersForModeration} onClick={onModerateUser}>
          {formatMessage('Moderate')}
        </Button>
        <Button className={styles.deleteButton} type={0} disabled={!hasUsersForModeration} onClick={onDeleteUser}>
          {formatMessage('Delete')}
        </Button>
      </div>
    </div>
  );
};

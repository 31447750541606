import { APP_TYPES } from '../../../../const/appConstants';
import { SignupSecondStageHungary } from './signUpSecondStageHungary';
import { SignupSecondStageCaucasus } from './signUpSecondStageCaucasus';

const getSignUpPageLayout = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.HUNGARY:
      return SignupSecondStageHungary;
    case APP_TYPES.ARMENIA:
    case APP_TYPES.AZERBAIJAN:
    case APP_TYPES.GEORGIA:
      return SignupSecondStageCaucasus;
    default:
      return null;
  }
};

export const SignUpSecondStage = getSignUpPageLayout();

import React, { useEffect, useState } from 'react';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { Button } from '../../components/common/Button';
import QuizQuestion from './questQuestion/QuizQuestion';
import PriceQuestion from './priceQuestion/PriceQuestion';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { QUESTION_TYPE } from '../../redux/quickTips/quickTipsConstants';
import { quickTipsSelectors } from '../../redux/quickTips/quickTipsSelectors';
import { quickTipsActions } from '../../redux/quickTips/quickTipsSlice';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';
import { analyticHelper } from '../../helpers/analyticHelper';

import styles from './QuickTips.module.scss';

const QuickTipsComponent = ({ handleSubmit }: InjectedFormProps) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const quickTip = useSelector(quickTipsSelectors.getQuickTip);
  const isFetching = useSelector(quickTipsSelectors.getIsFetching);
  const [isButtonShown, setIsButtonShown] = useState(true);

  const quickTipResult = useSelector(quickTipsSelectors.getResult);

  const correctAnswer = quickTipResult?.quick_tip?.answers[0].answer;
  const isAnswerCorrect = quickTipResult?.correct;
  const isHintShown = !isAnswerCorrect && !!correctAnswer;

  const onButtonClick = () => {
    setIsButtonShown(false);
    analyticHelper.onQuickTipAnswerClick();
    dispatch(quickTipsActions.passQuickTip());
  };

  useEffect(() => {
    dispatch(quickTipsActions.getQuickTip());

    return () => {
      dispatch(reset('quickTipsForm'));
    };
  }, []);

  const getQuestion = () => {
    switch (quickTip?.type) {
      case QUESTION_TYPE.QUIZ_QUESTION:
        return <QuizQuestion answers={quickTip?.answers} />;
      case QUESTION_TYPE.PRICE_QUESTION:
        return (
          <PriceQuestion
            correctAnswer={correctAnswer}
            isAnswerCorrect={isAnswerCorrect}
            isHintShown={isHintShown}
            dotColor="black"
            numberOfInputs={quickTip.price_question_answer_length}
          />
        );
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onButtonClick)} className={styles.form}>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div className={styles.container}>
            <img src={quickTip?.image} className={styles.image} alt="question" />
            <span className={styles.title}>{quickTip?.question}</span>
            {getQuestion()}
            {isButtonShown && <Button customClass={styles.button}>{formatMessage('Answer')}</Button>}
          </div>
        )}
      </form>
      <DisclaimerFooter withoutNavigation />
    </>
  );
};

export const QuickTips = reduxForm({
  form: 'quickTipsForm',
  destroyOnUnmount: false,
  shouldValidate: () => true,
})(QuickTipsComponent);

export interface IGoal {
  id: number;
  amount: number;
  reward: number;
  user_id: number;
  goal_tracker_id: number;
  created_at: string;
  status: number;
  metadata: null;
  isActive?: boolean;
  nextGoalReward?: number | null;
  isLastGoal?: boolean;
}

export interface IGoalTracker {
  id: number;
  user_id: number;
  progress: number;
  date_start: null;
  date_end: string;
  created_at: string;
  status: number;
  metadata: null;
  goals: IGoal[];
}

export enum GoalStatus {
  ACTIVE = 1,
  REWARDED = 2,
  COMPLETED = 3,
}

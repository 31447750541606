import differenceInYears from 'date-fns/differenceInYears';
import isEmpty from 'is-empty-typed';

export const required = (value) => (value ? undefined : ' ');

export const letters = (value) => (/[^A-Za-zА-Яа-я\u4E00-\u9FFF\s]+/g.test(value) ? 'use_only_letters' : undefined);

export const lettersLatin = (value) => (/[^A-Za-z]+/g.test(value) ? 'Please use letters only' : undefined);

export const lettersCyrillic = (value) => (/[^А-Яа-я]+/g.test(value) ? 'Please use letters only' : undefined);

export const streetName = (value) => (/[^А-Яа-я0-9-.]+/g.test(value) ? 'Please use letters only' : undefined);

export const characters = (value) => (/[^A-Za-zА-Яа-я0-9]/g.test(value) ? 'Please use characters only' : undefined);

export const usernameBelarus = (value) => (/[^A-Za-zА-Яа-я0-9-_.,]/g.test(value) ? 'Incorrect username' : undefined);

export const username = (value) => (/[^A-Za-z0-9]/g.test(value) ? 'Please use characters only' : undefined);

export const numbers = (value) => (value && /[^0-9]/g.test(value) ? 'Please use number only' : undefined);

export const accountNumber = (value) =>
  /[^0-9]/g.test(value) || value.length !== 7 ? 'Account Number consists of 7 numerals' : undefined;

export const email = (value) => (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'This email is invalid' : undefined);

export const lepEmail = (value) => (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Invalid email' : undefined);

export const emailEsign = (value) => (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Use the correct email' : undefined);

export const isAccepted = (value) => (value === true ? undefined : 'You should accept it');

export const length = (max) => (value) => {
  return value && value.length !== max ? `Must be ${max} characters` : undefined;
};

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min, formatMessage) => (value) =>
  value && value.length < min ? formatMessage(`Must be more then {min} characters`, { min }) : undefined;

export const lengthMinMax = (min, max, formatMessage) => (value) =>
  value && (value.length < min || value.length > max)
    ? formatMessage('You should use {min} to {max} characters', { min, max })
    : undefined;

export const olderThen18 = (value) => {
  if (!value) {
    return undefined;
  }
  const birthday = new Date(value);
  if (differenceInYears(Date.now(), birthday) >= 18) {
    return undefined;
  } else {
    return 'You must be at least 18 years of age';
  }
};

export const isValidDate = (value) => {
  const date = new Date(value);

  if (date.toDateString() !== 'Invalid Date' || value === '') {
    return undefined;
  } else {
    return 'Date formate is invalid. Please pick up a date';
  }
};

export const code = (value) => (/[^A-Za-zА-Яа-я0-9]/g.test(value) ? 'Please use characters only' : undefined);

export const inviteCode = (value) => {
  const checkVoid = value === '' || value === undefined;
  if ((value && value.length === 8 && !/[^0-9]/g.test(value)) || checkVoid) {
    return undefined;
  }
  return 'Invite code consists of 8 digits';
};

export const inviteCodeLatin = (value) => {
  const checkVoid = value === '' || value === undefined;
  if ((value && value.length === 8 && !/[^A-Za-z0-9]/g.test(value)) || checkVoid) {
    return undefined;
  }
  return 'Invite code consists of 8 latin characters or digits';
};

export const inviteCodeBelarus = (value) => (/[^А-Яа-я0-9]/g.test(value) && value ? 'Incorrect code' : undefined);

export const codeLep = (value) => {
  if (value && value.length === 8 && !/[^A-Z0-9]/g.test(value)) {
    return undefined;
  }
  return 'You should use latin uppercase letters or digits';
};

export const lengthPhone = (max, countryCode) => (value) => {
  return value && value.slice(countryCode.length).length !== max ? `Must be ${max} numerals` : undefined;
};

export const oppaPersonalNumber = (value) => {
  if (value && value.length === 11) {
    return undefined;
  }
  return 'Personal number consist of 11 digits';
};

export const oppaAccountNumber = (min, max, formatMessage) => (value) =>
  value && (value.length < min || value.length > max)
    ? formatMessage('Account number consist of {min} to {max} digits', { min, max })
    : undefined;

export const interactiveArticleRequiredInput = (value) => (value ? undefined : 'interactive_article.error.input');

export const interactiveArticleRequiredRadio = (value) => (value ? undefined : 'interactive_article.error.radio');

export const interactiveArticleRequiredSelect = (value) => (value ? undefined : 'interactive_article.error.select');

export const interactiveArticleRequiredCheckbox = (value) =>
  isEmpty(value) ? 'interactive_article.error.checkbox' : undefined;

export const passportNumber = (numbers, formatMessage) => (value) => {
  return value && value.length !== numbers ? formatMessage('Number entered incorrectly') : undefined;
};

export const inputModal = (value) =>
  /[^A-Za-zÀ-žА-Яа-я0-9 ]/g.test(value) ? 'You should use latin characters, umlauts or digits' : undefined;

export const priceQuestion = (formatMessage) => (value) => (value ? undefined : formatMessage('Type the answer'));
export const quizQuestion = (value) => (value ? undefined : 'You need to enter the answer to proceed');

export const quickTipsInputLength = (numbers) => (value) => {
  return value && value.length !== numbers ? 'You need to enter the answer to proceed' : undefined;
};

export const finCode = (finCodeLength, formatMessage) => (value) => {
  return value && value.length === finCodeLength
    ? undefined
    : formatMessage('PIN code consists of {finCodeLength} digits', { finCodeLength });
};

export const singleChoice = (formatMessage) => (value) => (value ? undefined : formatMessage('Choose the answer'));

export const multipleChoice = (formatMessage) => (value) => {
  return value?.length > 1 ? undefined : formatMessage('Choose at least two answer');
};

export const openQuestion = (formatMessage) => (value) => {
  return value?.length > 3 ? undefined : formatMessage('Use at least 3 characters');
};

export const openQuestionMaxValue = (formatMessage) => (value) => {
  return value?.length <= 255 ? undefined : formatMessage('250 characters maximum');
};

export const validateDateFormat = (value) => {
  // validation for dd/mm/yyyy, dd-mm-yyyy or dd.mm.yyyy format
  return !/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
    value,
  )
    ? 'Enter your date of birth in the format DD.MM.YYYY'
    : undefined;
};

import { createSlice } from '@reduxjs/toolkit';
import { MODALS } from './modalConstants';
import { stepListActions } from '../StepList/stepListSlice';
import { STEP_LIST_IDS } from '../StepList/stepListConstants';
import { isBelarus } from '../../../helpers';

const initialState = {
  modal: null,
  data: {},
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal(state, { payload }) {
      const { modal, data } = payload;
      return { ...state, modal, data };
    },
    closeModal() {
      return initialState;
    },
  },
});

export const modalActions = {
  ...modalSlice.actions,
  closeModal: modalSlice.actions.closeModal,
  openModal(modal, modalData = {}) {
    return modalSlice.actions.openModal({ modal, data: modalData });
  },
  openErrorModal(modalData) {
    return modalActions.openModal(MODALS.error, modalData);
  },
  openOrientationModal() {
    return modalActions.openModal(MODALS.orientation);
  },
  openPromptModal(modalData) {
    return modalActions.openModal(MODALS.prompt, modalData);
  },
  openPromptWithTwoButtonsModal(modalData) {
    return modalActions.openModal(MODALS.promptWithTwoButtons, modalData);
  },
  openCashoutModal(modalData) {
    return modalActions.openModal(MODALS.cashout, modalData);
  },
  openUnexprectedRewardsModal(modalData) {
    return modalActions.openModal(MODALS.unexprectedRewards, modalData);
  },
  openLoyaltyRewardModal(modalData) {
    return modalActions.openModal(MODALS.loyaltyReward, modalData);
  },
  openVerificationErrorModal(modalData) {
    return modalActions.openModal(MODALS.verificationError, modalData);
  },
  openVerificationStepModal(modalData) {
    return modalActions.openModal(MODALS.verificationStep, modalData);
  },
  openEsignEmailModal(modalData) {
    return modalActions.openModal(MODALS.esignEmail, modalData);
  },
  openScenarioReactionModal(modalData) {
    return modalActions.openModal(MODALS.scenarioReaction, modalData);
  },
  openExchangeModal(modalData) {
    return modalActions.openModal(MODALS.exchange, modalData);
  },
  openAchievementRewardModal(modalData) {
    return modalActions.openModal(MODALS.achievementReward, modalData);
  },
  openAchievementLevelCompletedModal(modalData) {
    return modalActions.openModal(MODALS.achievementLevelCompleted, modalData);
  },
  openAchievementModal(modalData) {
    return modalActions.openModal(MODALS.achievement, modalData);
  },
  openAchievementTutorialModal(modalData) {
    return modalActions.openModal(MODALS.achievementTutorial, modalData);
  },
  openQuizGameErrorModal(modalData) {
    return modalActions.openModal(MODALS.quizGameError, modalData);
  },
  openWrongInviteCodeModal(modalData) {
    return modalActions.openModal(MODALS.wrongInviteCode, modalData);
  },
  openSMSCodeInputModal(modalData) {
    return modalActions.openModal(MODALS.SMSCodeInput, modalData);
  },
  openQuizRateModal(modalData) {
    return modalActions.openModal(MODALS.quizRate, modalData);
  },
  openHappyBirthdayModal(modalData) {
    return modalActions.openModal(MODALS.happyBirthday, modalData);
  },
  openNotVerifiedUserModal(
    title = 'Sorry',
    btnText = 'Got it',
    description = 'Login is not available for this number. If this is a mistake, contact your JTI representative.',
  ) {
    return modalActions.openModal(MODALS.prompt, {
      title,
      btnText,
      description,
    });
  },
  openInputModal(modalData) {
    return modalActions.openModal(MODALS.inputModal, modalData);
  },
  openDailyBonusModal(modalData) {
    return modalActions.openModal(MODALS.dailyBonus, modalData);
  },
};

export const modalReducer = modalSlice.reducer;

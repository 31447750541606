import { RootState } from '../../store';
import { groupBy, formatDate } from '../../../helpers';

import { messageAdaptor } from './BaChatSlice';
import { ChatMessage } from '../chatTypes';
import { descDateComparer } from '../chatServices';

const messageSelectors = messageAdaptor.getSelectors((state: RootState) => state.chat.baChat.messages);

const selectHasMore = (state: RootState): boolean => state.chat.baChat.hasMore;
const selectIsFetching = (state: RootState): boolean => state.chat.baChat.isFetching;
const selectIsImageUploading = (state: RootState): boolean => state.chat.baChat.isImageUploading;

const selectMessagesByDate = (state: RootState) =>
  Object.entries(
    groupBy<ChatMessage>(messageSelectors.selectAll(state), (message) =>
      formatDate(new Date(message.createdAt), 'dd MMM yyyy'),
    ),
  ).sort(([aDate, [aMessage]], [bDate, [bMessage]]) => descDateComparer(aMessage.createdAt, bMessage.createdAt));

export const baChatSelectors = {
  selectHasMore,
  selectIsFetching,
  selectIsImageUploading,
  selectMessagesByDate,
  ...messageSelectors,
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { moderationActions } from '../../../../redux/moderation/moderationSlice';

import { ModerationPage } from '../../components';

export const ModerationPageContainer = () => {
  const dispatch = useDispatch();

  const [usersForModeration, setUsersForModeration] = useState<number[]>([]);

  const usersForModerationCount = usersForModeration.length;

  const addUserForModeration = (id: number) => {
    setUsersForModeration((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((userId) => userId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const onDeleteUsers = () => {
    dispatch(moderationActions.deleteUsers(usersForModeration));
    setUsersForModeration([]);
  };

  const onModerateUsers = () => {
    dispatch(moderationActions.moderateUsers(usersForModeration));
    setUsersForModeration([]);
  };

  useEffect(() => {
    dispatch(moderationActions.getUsersForModeration());
  }, [dispatch]);

  return (
    <ModerationPage
      usersForModeration={usersForModeration}
      usersForModerationCount={usersForModerationCount}
      onDeleteUser={onDeleteUsers}
      onModerateUser={onModerateUsers}
      handleUser={addUserForModeration}
    />
  );
};

import React from 'react';

import { PreloaderIcon } from '../../../../icons/preloader-icon';
import styles from './WhiteSpinner.module.scss';

type Props = {
  height?: number;
  width?: number;
};

export const WhiteSpinner = ({ height, width = 75 }: Props) => {
  return (
    <div className={styles.lottieWrapper}>
      <PreloaderIcon width={width} height={height} />
    </div>
  );
};

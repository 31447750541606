import { all, put, takeLatest, select } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { chatApi } from '../../../api/chatApi';
import { MESSAGE_LIMIT } from '../chatConstants';
import { mapMessage, mapMessages } from '../chatServices';
import { baChatActions } from './BaChatSlice';
import { baChatSelectors } from './BaChatSelectors';
import { serviceApi } from '../../../api/serviceApi';
import { ChatRole } from '../chatTypes';

function* sendMessage(action: PayloadAction<{ text: string; image?: object }>) {
  const { text, image } = action.payload;

  try {
    const { data } = yield chatApi.sendMessage({ text, image }, ChatRole.BA);

    const message = mapMessage(data);

    yield put(baChatActions.messageReceived(message));
  } catch (e) {}
}

function* uploadImage(action: PayloadAction<{ image: object }>) {
  const { image } = action.payload;
  yield put(baChatActions.setIsImageUploading(true));

  try {
    const { data } = yield serviceApi.uploadPhoto(image);

    yield put(baChatActions.sendMessage({ text: '', image: data.file_key }));
  } catch (e) {
  } finally {
    yield put(baChatActions.setIsImageUploading(false));
  }
}

function* fetchMessages(action: PayloadAction<{ offset: number }>) {
  const { offset } = action.payload;

  yield put(baChatActions.setIsFetching(true));

  try {
    const { data } = yield chatApi.getMessages({ limit: MESSAGE_LIMIT, offset, role: ChatRole.BA });

    const messages = mapMessages(data.messages);

    yield put(baChatActions.messagesReceived(messages));

    const total: number = yield select(baChatSelectors.selectTotal);

    yield put(baChatActions.setHasMore(total < data.total));
  } catch (e) {
  } finally {
    yield put(baChatActions.setIsFetching(false));
  }
}

export function* baChatSagas() {
  yield all([
    takeLatest(baChatActions.fetchMessages.type, fetchMessages),
    takeLatest(baChatActions.sendMessage.type, sendMessage),
    takeLatest(baChatActions.uploadImage.type, uploadImage),
  ]);
}

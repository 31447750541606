import { Swiper, SwiperSlide } from 'swiper/react';

import { ComponentPayload, Handlers } from './container/ItemComponentContainer';

import styles from './ItemComponent.module.scss';

type ItemProps = {
  components: Component[];
  handlers: Handlers;
};

export type Component = {
  description: string;
  image: string;
  name: string;
  payload: ComponentPayload;
  type: string;
};

const ItemComponent = ({ components, handlers }: ItemProps) => (
  <div className={styles.container}>
    <Swiper spaceBetween={-20} slidesPerView={'auto'} wrapperClass={styles.swiperWrapper}>
      {components.map((component: Component) => (
        <SwiperSlide className={styles.swiperItemContainer} key={component.payload.id}>
          <div
            className={styles.swiperItem}
            style={{
              backgroundImage: `url("${component.image}")`,
              ...(!component.payload.is_new && { opacity: '60%' }),
            }}
            onClick={() => handlers[component.type](component.payload)}
          >
            <div className={styles.itemName}>{component.name}</div>
            <div className={styles.itemDescription}>{component.description}</div>
            {component.payload.is_new && <div className={styles.unread} />}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

export default ItemComponent;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { Routes } from '../../../const';
import resultImage from '../../../images/id-card-result.png';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';

import styles from './IdCardResult.module.scss';

export const IdCardResult = () => {
  const history = useHistory();
  const { formatMessage } = useInternationalization();

  const onButtonClick = () => {
    history.push(Routes.MainRoute);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img className={styles.image} src={resultImage} alt="result" />
        <div className={styles.textWrapper}>
          <span className={styles.title}>{formatMessage('Moderation')}</span>
          <span className={styles.description}>
            {formatMessage('We need a couple of days to check your data. You’ll receive notification via chat')}
          </span>
        </div>
      </div>
      <Button customClass={styles.button} onClick={onButtonClick}>
        {formatMessage('OK')}
      </Button>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

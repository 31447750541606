import { useState, useCallback } from 'react';
import { get } from 'lodash';
import { connect, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

import './checkDataPage.scss';

import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { formatDate } from '../../../helpers/dateFormattingWithLocalization';
import { Button } from '../../../components/common/Button';
import { Input } from '../../../components/common/Input';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { SigningPage, SigningDisclaimer } from '../signupPage/signing';
import { authActions } from '../../../redux/auth/authSlice';
import { ScrollBlock } from '../../../components/common/ScrollBlock/ScrollBlock';

const CheckDataPageComponent = (props) => {
  const { initialValues } = props;
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);
  const [isSigningOpened, setIsSigningOpened] = useState(false);

  const onBackCallback = useCallback(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 1,
      }),
    );
  }, [dispatch]);

  const onFoundErrorClick = () => {
    dispatch(authActions.foundMistake());
  };

  const signingClickHandler = () => {
    setIsDisclaimerOpened(true);
  };

  const onCancelClick = () => {
    setIsDisclaimerOpened(false);
  };

  const onConfirmClick = () => {
    setIsDisclaimerOpened(false);
    setIsSigningOpened(true);
  };

  const goBackCallback = () => {
    setIsDisclaimerOpened(true);
    setIsSigningOpened(false);
  };

  const onDoneClick = (link) => {
    dispatch(authActions.sendSignature(link));
  };

  if (isSigningOpened) {
    return <SigningPage onDoneClick={onDoneClick} goBackCallback={goBackCallback} />;
  } else if (isDisclaimerOpened) {
    return <SigningDisclaimer onCancelClick={onCancelClick} onConfirmClick={onConfirmClick} />;
  } else {
    return (
      <ScrollBlock fromTop>
        <form className="check-data-page">
          <div className="check-data-page__top-section">
            <Button
              withoutTheme
              type="button"
              className="check-data-page__back-button"
              onClick={() => {
                onBackCallback();
              }}
            >
              <ArrowIconRounded className="check-data-page__top-arrow" />
            </Button>
            <div className="check-data-page__title">{formatMessage('Check your data')}</div>
            <p className="check-data-page__caption">{formatMessage('Name')}</p>
            <Input name="name" placeholder={formatMessage('Name')} disabled customClass="check-data-page__input" />
            <p className="check-data-page__caption">{formatMessage('Surname')}</p>
            <Input
              name="surname"
              placeholder={formatMessage('Surname')}
              disabled
              customClass="check-data-page__input"
            />
            <p className="check-data-page__caption">{formatMessage('Patronymic')}</p>
            <Input
              name="patronymic"
              placeholder={formatMessage('Patronymic')}
              disabled
              customClass="check-data-page__input"
            />
            {initialValues.email && (
              <>
                <p className="check-data-page__caption">{formatMessage('Email')}</p>
                <Input
                  name="email"
                  placeholder={formatMessage('Email')}
                  disabled
                  customClass="check-data-page__input"
                />
              </>
            )}
            <p className="check-data-page__caption">{formatMessage('Mobile phone number')}</p>
            <Input
              name="phoneNumber"
              placeholder={formatMessage('Mobile phone number')}
              disabled
              customClass="check-data-page__input"
            />
            <p className="check-data-page__caption">{formatMessage('Job title')}</p>
            <Input
              name="position"
              placeholder={formatMessage('Job title')}
              disabled
              customClass="check-data-page__input"
            />
            <p className="check-data-page__caption">{formatMessage('Date of birth (optional)')}</p>
            <Input
              name="dateOfBirth"
              placeholder={formatMessage('Date of birth (optional)')}
              disabled
              customClass="check-data-page__input"
            />
            <p className="check-data-page__caption">{formatMessage('City of shop')}</p>
            <Input
              name="city"
              placeholder={formatMessage('City of shop')}
              disabled
              customClass="check-data-page__input"
            />
            <p className="check-data-page__caption">{formatMessage('Retailer of shop')}</p>
            <Input
              name="retailer"
              placeholder={formatMessage('Retailer of shop')}
              disabled
              customClass="check-data-page__input"
            />
            <p className="check-data-page__caption">{formatMessage('Address of shop')}</p>
            <Input
              name="address"
              placeholder={formatMessage('Address of shop')}
              disabled
              customClass="check-data-page__input"
            />

            {initialValues.code && (
              <>
                <p className="check-data-page__caption">{formatMessage('TO Code')}</p>
                <Input
                  name="code"
                  placeholder={formatMessage('TO Code')}
                  disabled
                  customClass="check-data-page__input"
                />
              </>
            )}
            <p className="check-data-page__caption">{formatMessage('TM')}</p>
            <Input name="manager" placeholder={formatMessage('TM')} disabled customClass="check-data-page__input" />
          </div>
        </form>
        <Button customClass="found-error-button" withoutTheme onClick={() => onFoundErrorClick()}>
          {formatMessage('Error here')}
        </Button>
        <Button customClass="all-correct-button" withoutTheme onClick={() => signingClickHandler()}>
          {formatMessage('All correct')}
        </Button>
      </ScrollBlock>
    );
  }
};

const mapStateToProps = (state) => {
  const { form, profile } = state;
  const { userInfo } = profile;

  return {
    formObj: form.checkDataForm || { values: {} },
    initialValues: {
      name: userInfo.name,
      surname: userInfo.surname,
      patronymic: userInfo.middle_name,
      email: userInfo.email,
      phoneNumber: userInfo.phone,
      position: get(userInfo, 'position.name', ''),
      dateOfBirth: userInfo.date_of_birth ? formatDate(new Date(userInfo.date_of_birth), 'dd.MM.yy') : '',
      city: get(userInfo, 'outlet.city.name', ''),
      retailer: get(userInfo, 'outlet.legal_entity.name', ''),
      address: get(userInfo, 'outlet.outlet_address.name', ''),
      code: get(userInfo, 'outlet.manager.account_code', ''),
      manager:
        `${get(userInfo, 'manager.name', '')} ${get(userInfo, 'manager.surname', '')}` !== ' '
          ? `${get(userInfo, 'manager.name', '')} ${get(userInfo, 'manager.surname', '')}`
          : '',
    },
  };
};

const form = reduxForm({
  form: 'checkDataForm',
  initialValues: {},
  destroyOnUnmount: false,
  onSubmit: () => {},
  enableReinitialize: true,
})(CheckDataPageComponent);

export const CheckDataPage = connect(mapStateToProps, null)(form);

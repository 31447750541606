import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PlusIcon } from '../../../icons/plus-icon';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { AnswerRows } from '../AnswersRow/AnswersRow';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { RenderGame } from '../Game/renderQuestionComponent';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { ANSWER_SOURCES } from '../../../redux/specialQuizes/specialQuizesConstants';
import { getPackIdSelector, getQuestionsSelector } from '../../../redux/specialQuizes/specialQuizSelectors';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { getActiveBoosterSelector } from '../../../redux/boosters/boostersSelectors';
import { Boosters } from '../Boosters/Boosters';
import { BOOSTERS_TYPES } from '../../../redux/boosters/boostersConstants';
import { boostersActions } from '../../../redux/boosters/boostersSlice';
import { APP_TYPES } from '../../../const/appConstants';

export const SpecialQuizComponent = (props) => {
  const { theme, brandId, onLeaveCallback } = props;

  const { formatMessage } = useInternationalization();

  const questions = useSelector(getQuestionsSelector);
  const dispatch = useDispatch();

  const [myVariant, setMyVariant] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [checkedAnswers, setCheckedAnswers] = useState({ me: [] });
  const [answers, setAnswers] = useState([]);
  const activeBooster = useSelector(getActiveBoosterSelector);
  const pack_id = useSelector(getPackIdSelector);

  const nextQuestionCallback = useCallback(() => {
    if (currentQuestion + 1 === Number(questions.length)) {
      dispatch(
        specialQuizActions.finishSpecialQuiz({
          type: brandId,
        }),
      );
    } else {
      setTimeout(() => setCurrentQuestion(currentQuestion + 1), 2500);
    }
  }, [currentQuestion, dispatch, questions, brandId]);

  const onAnswerClicked = useCallback(
    (answer) => {
      if (!myVariant) {
        setMyVariant(answer);
        const currentAnswers = [...answers];
        currentAnswers.push(answer);
        const answersToCheck = checkCorrectAnswers(currentAnswers, questions);
        if (answersToCheck[currentQuestion].isCorrect) {
        } else if (activeBooster === BOOSTERS_TYPES.SECOND_CHANCE) {
          dispatch(boostersActions.secondChanceUsed(answer));
          return;
        }
        setAnswers(currentAnswers);
        setCheckedAnswers({ me: checkCorrectAnswers(currentAnswers, questions) });
        dispatch(specialQuizActions.setAnswer({ source: ANSWER_SOURCES.ME, answer }));
        nextQuestionCallback();
      }
    },
    [myVariant, answers, dispatch, nextQuestionCallback, questions, activeBooster, currentQuestion],
  );

  useEffect(() => {
    setMyVariant(null);
  }, [currentQuestion]);

  useEffect(() => {
    setMyVariant(null);
  }, [activeBooster]);

  useEffect(() => {
    analyticHelper.specQuizQuestion(currentQuestion + 1);
  }, [currentQuestion]);

  if (!questions) {
    onLeaveCallback();
    return null;
  }

  return (
    <div>
      <div className="quiz-page__game">
        <div className="quiz-page__leave-game" onClick={onLeaveCallback}>
          <PlusIcon className="quiz-page__leave-game-icon" style={{ fill: theme.color }} />{' '}
          {formatMessage('Tap to exit')}
        </div>

        <div className="quiz-page__game-progress">
          <div className="quiz-page__user-progress">
            <div className="quiz-page__user-progress-points-solo">
              <AnswerRows answers={checkedAnswers.me.map((el) => el.isCorrect)} count={questions.length} gamer="user" />
            </div>
          </div>
        </div>

        <RenderGame
          currentQuestion={questions[currentQuestion]}
          questions={questions}
          currentQuestionNumber={currentQuestion}
          theme={theme}
          myVariant={myVariant}
          onAnswerClicked={onAnswerClicked}
          brandId={brandId}
          checkedAnswers={checkedAnswers}
          withoutOpponent={true}
          withBoosters={process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY}
        />

        {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
          <Boosters
            pack_id={pack_id}
            solo_mode
            currentQuestion={questions[currentQuestion]}
            myVariant={myVariant}
            setMyAnswerCallback={onAnswerClicked}
          />
        )}
      </div>
    </div>
  );
};

import { useCallback } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { isMobileSafari } from '../../../helpers/checkIsSafariBrowser';
import { goToMultibrandQuizStepAction } from '../../../redux/multibrandQuiz/multibrandQuizSagas';

import { Routes } from '../../../const';

import { Button } from '../../../components/common/Button';
import { Header } from '../../../components/common/Header';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { MULTIBRAND_QUIZ_STEPS } from '../../../redux/multibrandQuiz/multibrandQuizConstants';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';

import noBonusGame from '../../noBonusGamesInfo/noBonusGame.json';

export const MultibrandQuizNoBonusGame = (props) => {
  const { history } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const setNoBonusGamesMessageRead = useCallback(() => {
    dispatch(goToMultibrandQuizStepAction(MULTIBRAND_QUIZ_STEPS.GAME));
  }, [dispatch]);
  const goBackCallback = useCallback(() => history.replace(Routes.MainRoute), [history]);

  return (
    <div className="multibrand-quiz-page__no-bonus-game">
      <ScrollBlock fromTop>
        <Header customClass="multibrand-quiz-page__no-bonus-game__header" goBackCallback={goBackCallback} />
        <div
          className="multibrand-quiz-page__no-bonus-game__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
        >
          <LottieWrapper
            options={options}
            style={{
              height: '340px',
            }}
          />
          <div className={'multibrand-quiz-page__no-bonus-game__bottom-text'}>
            <p className="multibrand-quiz-page__no-bonus-game__content-area-caption">
              {formatMessage('No bonus games available')}
            </p>
            <p
              className={classnames(
                'multibrand-quiz-page__no-bonus-game__content-area-caption',
                'multibrand-quiz-page__no-bonus-game__content-area-description',
              )}
            >
              {formatMessage('But you still can play and earn points to improve your position in leaderboards')}
            </p>
            <div className={'multibrand-quiz-page__no-bonus-game__button-container'}>
              <Button onClick={() => setNoBonusGamesMessageRead()}>{formatMessage('Got it')}</Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: noBonusGame,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

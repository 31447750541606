import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import './progress.scss';
import progressAnimation from './progressAnimations/progress_animation.json';
import pulseAnimation from './progressAnimations/pulse_animation.json';
import fullAnimation from './progressAnimations/full_animation.json';

import { serviceActions } from '../../../redux/service/serviceSlice';
import { getMonthlyLimitationSelector } from '../../../redux/service/serviceSelectors';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';
import { IconicItemComponent } from '../../mainPage/itemComponent/iconicItemComponent';
import { mainPageActions } from '../../../redux/mainPage/mainPageSlice';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { ExclamationPointIcon } from '../../../icons/exclamation-point-icon';
import { formatNumber } from '../../../helpers/numberFormatter';
import { useTimeLeft } from '../../../hooks/useTimeLeft';

const progressAnimationData = {
  loop: false,
  autoplay: false,
  animationData: progressAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const pulseAnimationData = {
  loop: true,
  autoplay: true,
  animationData: pulseAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const fullAnimationData = {
  loop: true,
  autoplay: true,
  animationData: fullAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const FAQ_ARTICLE_ID = 'FAQ_ARTICLE_ID';

const ProgressComponent = ({ history }) => {
  const dispatch = useDispatch();

  const [isPaused, setIsPaused] = useState(true);
  const [isStopped, setIsStopped] = useState(true);

  const limitation = useSelector(getMonthlyLimitationSelector);
  const {
    user_earned,
    limit,
    month_end_date,
    completion_rate,
    hint_text,
    activities_header,
    activities,
    faq_link,
  } = limitation;
  const time = useTimeLeft(month_end_date);

  useEffect(() => {
    dispatch(serviceActions.getMonthlyLimitation());
    analyticHelper.progressPageOpen();
  }, [dispatch]);

  const onComponentClick = useCallback(
    (component) => {
      dispatch(mainPageActions.setCurrentComponent({ componentData: component }));
    },
    [dispatch],
  );

  const onFAQClick = useCallback(() => {
    dispatch(
      knowledgeBankActions.openArticle({
        article: { url: faq_link, id: FAQ_ARTICLE_ID },
      }),
    );
    analyticHelper.progressPageFAQ();
  }, [dispatch, faq_link]);

  useEffect(() => {
    if (completion_rate) {
      setIsPaused(false);
      setIsStopped(false);
    }
  }, [completion_rate]);

  if (Object.keys(limitation).length === 0) {
    return <div className="progress-page" />;
  }

  return (
    <div className="progress-page">
      <div className="progress-page__time">{time}</div>
      <div className="progress-page__faq">
        <ExclamationPointIcon onClick={onFAQClick} />
      </div>
      <div className="progress-page__animation-container">
        <div className="progress-page__progress">{`${formatNumber(user_earned)} / ${formatNumber(limit)}`}</div>
        <div className="progress-page__current-points">{formatNumber(user_earned)}</div>
        {completion_rate < 100 ? (
          <LottieWrapper
            options={progressAnimationData}
            width={200}
            isStopped={isStopped}
            isPaused={isPaused}
            height={200}
            maxProgress={completion_rate + 1}
            style={{ position: 'absolute', marginTop: '20px' }}
          />
        ) : (
          <LottieWrapper
            options={fullAnimationData}
            width={200}
            isStopped={isStopped}
            isPaused={isPaused}
            height={200}
            style={{ position: 'absolute', marginTop: '20px' }}
          />
        )}

        <LottieWrapper
          options={pulseAnimationData}
          width={240}
          height={240}
          style={{ position: 'absolute', margin: 'auto' }}
        />
      </div>
      <div className="progress-page__hints">{hint_text}</div>

      <div className="progress-page__devider" />

      <div className="progress-page__activity-header"> {activities_header}</div>
      <div className="progress-page__activities">
        {activities.map((i, index) => (
          <div
            key={`activity_${index}`}
            className="progress-page__activity-container"
            onClick={() => onComponentClick(i)}
          >
            <IconicItemComponent {...i} history={history} isProgressPage />
          </div>
        ))}
      </div>
    </div>
  );
};

export const Progress = withRouter(ProgressComponent);

import './signingDisclaimer.scss';

import { useEffect, useRef } from 'react';

import { Button } from '../../../../components/common/Button';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

export const SigningDisclaimer = ({ onCancelClick, onConfirmClick }) => {
  const { formatMessage } = useInternationalization();
  const disclaimerRef = useRef(null);

  useEffect(() => {
    if (disclaimerRef.current) {
      disclaimerRef.current.scrollTo(0, 1);
    }
  }, []);

  return (
    <div className="signing-disclaimer-page" ref={disclaimerRef}>
      <div className="signing-disclaimer-page__content">
        <p className="signing-disclaimer-page__content-title">
          {formatMessage('Conditions for obtaining information')}
        </p>
        <div className="signing-disclaimer-page__content-line" />
        <div className="signing-disclaimer-page__content-description">
          <p className="signing-disclaimer-page__content-description-block">
            {formatMessage('You hereby confirm your consent')}
          </p>
          <p className="signing-disclaimer-page__content-description-block">{formatMessage('You hereby agree')}</p>
          <p className="signing-disclaimer-page__content-description-block">{formatMessage('You can opt out')}</p>
          <p className="signing-disclaimer-page__content-description-block">{formatMessage('By your signature')}</p>
          <p className="signing-disclaimer-page__content-description-block">{formatMessage('Also you agree')}</p>
        </div>
        <Button withoutTheme customClass={'cancel-button'} onClick={() => onCancelClick()}>
          {formatMessage('Decline')}
        </Button>
        <Button withoutTheme customClass={'confirm-button'} onClick={() => onConfirmClick()}>
          {formatMessage('Accept')}
        </Button>
      </div>
    </div>
  );
};

import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './salesSimulatorsPage.scss';

import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { Header } from '../../components/common/Header';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Routes } from '../../const';
import { getIsFetching, getSalesSimulatorsSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';

export const SalesSimulatorsPageComponent = (props) => {
  const { history, location } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  useEffect(() => {
    dispatch(knowledgeBankActions.getSalesSimulators());
  }, [dispatch]);

  const salesSimulators = useSelector(getSalesSimulatorsSelector);
  const isFetching = useSelector(getIsFetching);

  const onBackCallback = useCallback(() => {
    history.replace(Routes.KnowledgeBankRoute);
  }, [history]);

  const onSalesSimulatorClickCallback = useCallback(
    (simulator_id) => {
      history.push(`${Routes.KnowledgeBankRoute + Routes.SalesSimulators}/${simulator_id}`);
    },
    [history],
  );

  return (
    <div className="sales-simulator">
      <ScrollBlock fromTop>
        <Header hasBackButton goBackCallback={onBackCallback}>
          <p className="knowledge-bank-page__header-text">{formatMessage('Sales Simulators')}</p>
        </Header>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div className="sales-simulator__body">
            <div className="sales-simulator__sales-simulator-cards">
              {salesSimulators.map((i) =>
                i.is_new ? (
                  <div className="sales-simulator__sales-simulator-card" key={i.id}>
                    <div className="sales-simulator__sales-simulator-card-chain" />
                    <img
                      className="sales-simulator__sales-simulator-card-incompleted"
                      src={i.incomplete_image}
                      alt="completed-sales-tip"
                      onClick={() => onSalesSimulatorClickCallback(i.id)}
                    />
                  </div>
                ) : (
                  <div className="sales-simulator__sales-simulator-card" key={i.id}>
                    <div className="sales-simulator__sales-simulator-card-chain" />
                    <img
                      className="sales-simulator__sales-simulator-card-completed"
                      src={i.complete_image}
                      alt="incompleted-sales-tip"
                      onClick={() => onSalesSimulatorClickCallback(i.id)}
                    />
                  </div>
                ),
              )}
            </div>
          </div>
        )}
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const SalesSimulatorsPage = withRouter(SalesSimulatorsPageComponent);

import { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import './addressRegistration.scss';

import { Button } from '../../components/common/Button';
import { Input } from '../../components/common/Input';
import { Header } from '../../components/common/Header';
import { Select } from '../../components/common/Select';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { required } from '../../helpers/formValidations';
import { Routes } from '../../const';
import { goBack } from 'connected-react-router';
import { getPassportPageStateSelector } from '../../redux/profile/profileSelectors';
import { PASSPORT_PAGE_STATES } from '../../redux/profile/profileConstants';
import { profileActions } from '../../redux/profile/profileSlice';

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

const streetTypes = [
  { id: 'ул.', name: 'Улица' },
  { id: 'бульвар', name: 'Бульвар' },
  { id: 'вал', name: 'Вал' },
  { id: 'въезд', name: 'Въезд' },
  { id: 'наб.', name: 'Набережная' },
  { id: 'пер.', name: 'Переулок' },
  { id: 'пл.', name: 'Площадь' },
  { id: 'пр.', name: 'Проезд' },
  { id: 'пр-т.', name: 'Проспект' },
  { id: 'разъезд', name: 'Разъезд' },
  { id: 'тракт', name: 'Тракт' },
  { id: 'тупик', name: 'Тупик' },
  { id: 'шоссе', name: 'Шоссе' },
];

const localityType = [
  { id: 'аг.', name: 'Агрогородок' },
  { id: 'г.', name: 'Город' },
  { id: 'г.п.', name: 'Городской поселок' },
  { id: 'д.', name: 'Деревня' },
  { id: 'п.', name: 'Поселок' },
  { id: 'с.', name: 'Село' },
  { id: 'х.', name: 'Хутор' },
];

const AddressRegistrationComponent = (props) => {
  const { handleSubmit, formObj, invalid, history } = props;
  const { formatMessage } = useInternationalization();
  const passportPageState = useSelector(getPassportPageStateSelector);
  const dispatch = useDispatch();

  const onBackCallback = useCallback(() => {
    dispatch(goBack());
  }, [dispatch]);

  const submit = () => {
    dispatch(profileActions.uploadPassportData());
  };

  if (passportPageState !== PASSPORT_PAGE_STATES.FILLING) {
    history.replace(Routes.PassportSettings);
    return null;
  }

  return (
    <div className="address-registration-page">
      <Header hasBackButton customClass="address-registration-page__header" goBackCallback={onBackCallback}>
        <p className="address-registration-page__header-text">{formatMessage('Address registration')}</p>
      </Header>
      <form onSubmit={handleSubmit(submit)} className="address-registration-page__content">
        <div className="address-registration-page__top-section">
          <div className="address-registration-page__title">{formatMessage('Fill your registration address:')}</div>
          <Select
            name="cityType"
            placeholder={formatMessage('City type')}
            options={localityType}
            disabled={!localityType.length}
            validate={[required]}
            customClass={`address-registration-page__input${getSelectFontColor(formObj.values, 'cityType')}`}
          />
          <Input
            name="city"
            placeholder={formatMessage('City')}
            validate={[required]}
            customClass="address-registration-page__input"
          />
          <Select
            name="streetType"
            placeholder={formatMessage('Street type')}
            options={streetTypes}
            disabled={!streetTypes.length}
            validate={[required]}
            customClass={`address-registration-page__input${getSelectFontColor(formObj.values, 'streetType')}`}
          />
          <Input
            name="street"
            placeholder={formatMessage('Street')}
            validate={[required]}
            customClass="address-registration-page__input"
          />
          <Input
            name="building"
            placeholder={formatMessage('House number')}
            validate={[required]}
            customClass="address-registration-page__input"
          />
          <Input name="corps" placeholder={formatMessage('Block')} customClass="address-registration-page__input" />
          <Input
            name="apartment"
            placeholder={formatMessage('Room number')}
            customClass="address-registration-page__input"
          />
        </div>
        <div className="address-registration-page__bottom-section">
          <Button
            customClass={`address-registration-page__bottom-section-button${invalid ? '-disabled' : ''}`}
            withoutTheme
            disabled={invalid}
          >
            {formatMessage('Proceed address')}
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    formObj: form.signupForm || { values: {} },
  };
};

const form = reduxForm({
  form: 'addressRegistrationForm',
  initialValues: {
    invite_code: '',
  },
  destroyOnUnmount: false,
  onSubmit: () => {},
  enableReinitialize: true,
})(AddressRegistrationComponent);

export const AddressRegistration = connect(mapStateToProps, null)(form);

import './passportSuccess.scss';

import { Header } from '../../components/common/Header';
import PassportImage from '../../images/passport-image.png';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Routes } from '../../const';
import { Button } from '../../components/common/Button';

export const PassportSuccess = ({ history }) => {
  const { formatMessage } = useInternationalization();

  const onGotItClick = () => {
    history.replace(Routes.MainRoute);
  };

  return (
    <div className="passport-success-page">
      <Header customClass="passport-success-page__header" />
      <div
        className="passport-success-page__content-area"
        style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
      >
        <div
          className="passport-success-page__content-area-schedule-container"
          style={{
            backgroundImage: `url('${PassportImage}')`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          }}
        />
        <div className="passport-success-page__content-area-description">
          <p className="passport-success-page__content-area-description-title">
            {formatMessage('Passport successful')}
          </p>
          <p className="passport-success-page__content-area-description-desc">{formatMessage('In 10 days')}</p>
        </div>
        <div className="passport-success-page__content-area-button-container">
          <Button customClass={'got-it-button'} onClick={onGotItClick}>
            {formatMessage('Got it')}
          </Button>
        </div>
      </div>
    </div>
  );
};

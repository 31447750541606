import React from 'react';
import { Field } from 'redux-form';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { openQuestion, openQuestionMaxValue } from '../../../../helpers/formValidations';
import { ErrorText } from '../../../../components/common/ErrorText/ErrorText';

import styles from './OpenQuestion.module.scss';

const renderField = ({ input, meta: { touched, error } }: any) => {
  return (
    <div className={styles.container}>
      <textarea {...input} className={styles.textArea} placeholder="Type text..." />;
      {touched && error && <ErrorText text={error} />}
    </div>
  );
};

export const OpenQuestion = () => {
  const { formatMessage } = useInternationalization();
  return (
    <Field
      name="openQuestion"
      component={renderField}
      validate={[openQuestion(formatMessage), openQuestionMaxValue(formatMessage)]}
    />
  );
};

import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { Header } from '../../../components/common/Header';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { getCategoriesSelector } from '../../../redux/knowledgeBank/knowledgeBankSelectors';
import { getUnverificationInfoForBelarus } from '../../../redux/verification/verificationSelectors';
import { KnowledgeBankCategory } from '../../../components';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { NavigationMenu } from '../../../components/navigationMenu';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { IKBCategory } from '../knowledgeBankTypes';
import { amplitudeKBCategoryResolver } from '../../../helpers/ampitudeHelper';
import { contentClasses } from '../knowledgeBankConstants';

import styles from './KnowledgeBank.module.scss';

export const KnowledgeBank = () => {
  const location = useLocation<Location>();
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const categories = useSelector(getCategoriesSelector);
  const isUnVerifiedBelarus = useSelector(getUnverificationInfoForBelarus);

  const chooseCategory = useCallback(
    (selectedCategoryId: number) => {
      dispatch(knowledgeBankActions.chooseCategory({ selectedCategoryId }));
      amplitudeKBCategoryResolver.hasOwnProperty(selectedCategoryId) &&
        amplitudeKBCategoryResolver[selectedCategoryId]();
      analyticHelper.selectKBCategory(selectedCategoryId);
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(knowledgeBankActions.getCategories());
  }, []);

  return (
    <div className={styles.container}>
      <ScrollBlock fromTop>
        <Header>
          <p className={styles.headerText}>{formatMessage('Knowledge Bank')}</p>
        </Header>
        {isUnVerifiedBelarus ? (
          <div className={styles.unavailableContent} style={contentClasses}>
            <p className={styles.description}>
              {formatMessage('Knowledge Bank will be available after passing Check-in')}
            </p>
          </div>
        ) : (
          <div className={styles.content} style={contentClasses}>
            {categories.length &&
              categories.map((category: IKBCategory) => (
                <KnowledgeBankCategory
                  key={category.id}
                  category={category}
                  onChoose={() => chooseCategory(category.id)}
                />
              ))}
          </div>
        )}
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

import { all, takeLatest, put, take, delay } from 'redux-saga/effects';
import { boostersApi } from '../../api/boostersApi';
import { BOOSTERS_TYPES } from './boostersConstants';
import { boostersActions } from './boostersSlice';
import { profileActions } from '../profile/profileSlice';
import { analyticHelper } from '../../helpers/analyticHelper';

const getAnswersToHide = (answersQuantity, correctAnswer) => {
  const hiddenAnswers = [];
  for (let i = 1; i <= answersQuantity / 2; i++) {
    let answerToHide = correctAnswer;
    while (answerToHide === correctAnswer || hiddenAnswers.indexOf(answerToHide) !== -1) {
      answerToHide = getRandomIntInclusive(1, answersQuantity);
    }
    hiddenAnswers.push(answerToHide);
  }
  return hiddenAnswers;
};

const getRandomIntInclusive = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

function* getBoostersForQuizSaga({ payload }) {
  const { pack_id, solo_mode } = payload;
  const { data } = yield boostersApi.getBoostersForQuiz(pack_id, solo_mode);
  yield put(boostersActions.setBoostersForQuiz({ boosters: data }));
}

function* useBoosterSaga({ payload }) {
  const { booster, question, setMyAnswerCallback, roundTime, addBonusTimeCallback, pack_id } = payload;
  yield put(boostersActions.setActiveBooster(booster.type));

  const { data } = yield boostersApi.useBooster(booster.id, pack_id, question.number);
  analyticHelper.useBooster(booster.type, question.id);
  yield put(boostersActions.updateBoostersAmount({ type: booster.type, amount: data.amount }));

  switch (booster.type) {
    case BOOSTERS_TYPES.FIFTY_FIFTY: {
      const hiddenAnswers = getAnswersToHide(question.answers.length, question.correct_answers[0]);
      let answersElements;
      if (question.type.id === 3) {
        answersElements = document.getElementsByClassName('quiz-page__image-answer-container');
      } else {
        answersElements = document.getElementsByClassName('quiz-page__answer');
      }
      for (let i = 0; i < hiddenAnswers.length; i++) {
        answersElements[hiddenAnswers[i] - 1].style.opacity = 0.2;
      }
      break;
    }
    case BOOSTERS_TYPES.HINT: {
      let answersElements;
      if (question.type.id === 3) {
        answersElements = document.getElementsByClassName('quiz-page__image-answer-container');
      } else {
        answersElements = document.getElementsByClassName('quiz-page__answer-svg');
      }
      const correctAnswerIndex = question.correct_answers[0] - 1;
      answersElements[correctAnswerIndex].style.border = '3px solid #4CBF8F';
      answersElements[correctAnswerIndex].style.boxSizing = 'border-box';
      answersElements[correctAnswerIndex].style.borderRadius = '6px';
      break;
    }
    case BOOSTERS_TYPES.SKIP: {
      yield setMyAnswerCallback(question.correct_answers[0], roundTime);
      break;
    }
    case BOOSTERS_TYPES.PLUS_FIVE_SECS: {
      yield addBonusTimeCallback();
      break;
    }
    case BOOSTERS_TYPES.SECOND_CHANCE: {
      yield put(boostersActions.setActiveBooster(BOOSTERS_TYPES.SECOND_CHANCE));
      const { payload } = yield take(boostersActions.secondChanceUsed);
      let answersElements;
      if (question.type.id === 3) {
        answersElements = document.getElementsByClassName('quiz-page__answered-image-');
        answersElements[payload - 1].style.background = '#FFAC30';
        yield delay(2000);
        answersElements[payload - 1].style.background = null;
      } else {
        answersElements = document.getElementsByClassName('quiz-page__answer-svg');
        const answerTextsArray = document.getElementsByClassName('quiz-page__answer-text ');
        answerTextsArray[payload - 1].style.color = '#fff';
        const pathesArray = answersElements[payload - 1].children;
        for (let i = 0; i < pathesArray.length; i++) {
          pathesArray[i].style.fill = '#FFAC30';
          pathesArray[i].style.stroke = '#FFAC30';
        }

        yield delay(2000);
        answerTextsArray[payload - 1].style.color = null;
        for (let i = 0; i < pathesArray.length; i++) {
          pathesArray[i].style.fill = null;
          pathesArray[i].style.stroke = null;
        }
      }

      yield put(boostersActions.setActiveBooster(null));
      break;
    }
    default:
      break;
  }
}

function* getBoostersShopSaga() {
  try {
    const response = yield boostersApi.getBoostersShop();

    if (response.data) {
      yield put(boostersActions.setBoostersShop({ boostersShop: response.data }));
    }
  } catch (err) {
    console.error('getBoostersShop error', err);
  }
}

function* buyBoosterSaga({ payload }) {
  const { booster_id, amount } = payload;
  yield boostersApi.buyBooster(booster_id, amount);
  yield put(boostersActions.getBoostersShop());
  yield put(profileActions.getUserInfo());
}

function* exchangeLeafSaga({ payload }) {
  const { leaf } = payload;
  yield boostersApi.exchangeLeaf(leaf);
  yield put(profileActions.getUserInfo());
}

function* sagas() {
  yield all([
    takeLatest(boostersActions.getBoostersForQuiz, getBoostersForQuizSaga),
    takeLatest(boostersActions.useBooster, useBoosterSaga),
    takeLatest(boostersActions.getBoostersShop, getBoostersShopSaga),
    takeLatest(boostersActions.buyBooster, buyBoosterSaga),
    takeLatest(boostersActions.exchangeLeaf, exchangeLeafSaga),
  ]);
}

export const boostersSagas = sagas;

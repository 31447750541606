import './SupportStub.scss';
import { NavigationWrapper } from '../../components';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import classNames from 'classnames';
import { APP_TYPE } from '../../../../const/appConstants';

export const SupportStub = () => {
  const { formatMessage } = useInternationalization();

  return (
    <NavigationWrapper>
      <div className={classNames('support-stub foo', APP_TYPE?.toLowerCase())}>
        <h3>{formatMessage('support_stub.message')}</h3>
        <h1>{formatMessage('support_stub.phone')}</h1>
      </div>
    </NavigationWrapper>
  );
};

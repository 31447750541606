import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { verificationActions } from './verificationSlice';
import { verificationApi } from '../../api/verificationApi';
import { Routes } from '../../const';
import { VERIFICATION_MODE } from '../../const/verificationConstants';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';
import { getPosition } from '../../helpers/getPosition';

function* setVerificationModeSaga({ payload }) {
  const { mode, geo_allowed, longitude, latitude } = payload;
  try {
    const response = yield verificationApi.setVerificationMode(mode, geo_allowed);

    if (response.status === 200) {
      if (geo_allowed === true) {
        yield put(verificationActions.sendCoordinates({ longitude, latitude }));
      }
      yield put(push(Routes.CheckInStatusRoute));
    }
  } catch (error) {
    console.error('setVerification mode error', error);
  }
}

function* getVerificationInfoSaga() {
  try {
    yield put(verificationActions.setVerificationIsFetching({ isFetching: true }));
    const response = yield verificationApi.getVerificationInfo();
    const { is_last_verification_week } = response.data;

    yield put(verificationActions.setVerificationInfo({ verificationInfo: response.data }));
    if (response.data && is_last_verification_week) {
      yield put(
        modalActions.openVerificationErrorModal({
          title: 'Didn’t pass the check-in?',
          description: 'Then do it now, otherwise you will go into restricted mode.',
          btnText: 'Pass the check-in',
          withGeo: true,
          onButtonClick: () => {
            if (navigator.geolocation) {
              getPosition()
                .then((position) => {
                  const crd = position.coords;
                  store.dispatch(
                    verificationActions.setVerificationMode({
                      mode: VERIFICATION_MODE.VERIFICATION_AUTO,
                      geo_allowed: true,
                      latitude: crd.latitude,
                      longitude: crd.longitude,
                    }),
                  );
                })
                .catch(() => {
                  store.dispatch(
                    verificationActions.setVerificationMode({
                      mode: VERIFICATION_MODE.VERIFICATION_AUTO,
                      geo_allowed: false,
                    }),
                  );
                });
            }
            store.dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  } catch (err) {
    console.error('getVerificationInfo error', err);
  } finally {
    yield put(verificationActions.setVerificationIsFetching({ isFetching: false }));
  }
}

function* sendCoordinatesSaga({ payload }) {
  const { longitude, latitude } = payload;
  try {
    yield put(verificationActions.setVerificationIsFetching({ isFetching: true }));
    const response = yield verificationApi.sendCoordinates(longitude, latitude);

    if (response.status === 200) {
      const { steps } = response.data;
      yield put(verificationActions.setVerificationInfo({ verificationInfo: response.data }));
      if (steps.length) {
        const completedSteps = steps.filter((step) => step.completed_at !== null);
        const lastCompletedStep = completedSteps[completedSteps.length - 1];
        yield put(
          modalActions.openVerificationStepModal({
            coins: lastCompletedStep.coins,
            description: lastCompletedStep.description,
            btnText: 'Get coins',
            onButtonClick: () => {
              store.dispatch(modalActions.closeModal());
            },
            onClose: () => {
              store.dispatch(modalActions.closeModal());
            },
          }),
        );
      }
    }
  } catch (error) {
    console.error('setVerification mode error', error);
    yield put(
      modalActions.openVerificationErrorModal({
        title: 'Something go wrong',
        description: 'This stage of the check-in hasn`t been completed. Try again at the workplace.',
        btnText: 'OK',
        onButtonClick: () => {
          store.dispatch(modalActions.closeModal());
        },
        onClose: () => {
          store.dispatch(modalActions.closeModal());
        },
      }),
    );
  } finally {
    yield put(verificationActions.setVerificationIsFetching({ isFetching: false }));
  }
}

function* sagas() {
  yield all([
    takeLatest(verificationActions.setVerificationMode, setVerificationModeSaga),
    takeLatest(verificationActions.getVerificationInfo, getVerificationInfoSaga),
    takeLatest(verificationActions.sendCoordinates, sendCoordinatesSaga),
  ]);
}

export const verificationSagas = sagas;

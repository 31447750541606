import { ChangeEvent, useRef, useState, FormEvent } from 'react';

import { Button } from '../../../../components/common';
import { ButtonType } from '../../../../components/common/CommonButton/types';

import styles from './SendMessageBlock.module.scss';
import { SendMessageIcon } from '../../../../icons/send-message-icon';
import { AddIcon } from '../../../../icons/add-icon';
import { isIPhoneWithHomeBar } from '../../../../helpers/checkIsIphoneX';
import { s3FilePaths } from '../../../../const/appConstants';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

type Props = {
  onSendMessageClick: (text: string) => void;
  onImageUpload: (image: object) => void;
};

export const SendMessageBlock = ({ onSendMessageClick, onImageUpload }: Props) => {
  const [value, setValue] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { formatMessage } = useInternationalization();

  const handleSendMessageClick = () => {
    const trimmedValue = value.trim();

    if (trimmedValue) {
      onSendMessageClick(trimmedValue);
      setValue('');
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const handleImageUpload = async () => {
    const files = fileInputRef && fileInputRef.current && fileInputRef.current?.files;
    if (files) {
      const image = files[0];
      if (!image || !image.type.match(/image.*/)) return;

      const formData = new FormData();
      formData.append('file', image);
      formData.append('file_path', s3FilePaths.chat);

      onImageUpload(formData);
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSendMessageClick();
  };

  return (
    <form
      onSubmit={onSubmit}
      className={styles.sendMessageBlock}
      style={{
        paddingBottom: isIPhoneWithHomeBar() ? '49px' : '',
      }}
    >
      <Button
        type={ButtonType.Transparent}
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        <input type={'file'} ref={fileInputRef} hidden accept="image/*" onChange={handleImageUpload} />
        <AddIcon className={styles.addImageIcon} />
      </Button>
      <input value={value} onChange={handleInputChange} placeholder={formatMessage('chat.type_question')} />
      <Button type={ButtonType.Transparent} onClick={handleSendMessageClick}>
        <SendMessageIcon className={styles.sendMessageIcon} />
      </Button>
    </form>
  );
};

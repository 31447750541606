import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import isEmpty from 'is-empty-typed';

import { PERXMainPage } from './PERXMainPage';
import { CreateCard } from './createPerxCard/createCard';
import { EnterUserInfoContainer } from './createPerxCard/enterUserInfo/enterUserInfoContainer';
import { ChooseAmountPage } from './chooseAmountPage/chooseAmountPage';
import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import { PerxSuccessPage } from './successPage/successPage';
import { getIsFetching, getTransfersSelector } from '../../../redux/bonusShop/bonusShopSelectors';
import { ContentSpinner } from '../../../components/common/Spinner/ContentSpinner';
import { PerxErrorPage } from './errorPage/errorPage';

export const perxSteps = {
  transfer: 1,
  createCard: 2,
  enterUserInfo: 3,
  chooseAmount: 4,
  transferSuccess: 5,
  error: 6,
};

export const PerxTransferMoneyPage = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetching);
  const transfers = useSelector(getTransfersSelector)?.transfers;

  useEffect(() => {
    dispatch(bonusShopActions.getAddress());
  }, [dispatch]);

  let steps = [
    { id: perxSteps.transfer, Component: () => <PERXMainPage /> },
    { id: perxSteps.createCard, Component: () => <CreateCard /> },
    { id: perxSteps.enterUserInfo, Component: () => <EnterUserInfoContainer /> },
    { id: perxSteps.chooseAmount, Component: () => <ChooseAmountPage /> },
    { id: perxSteps.transferSuccess, Component: () => <PerxSuccessPage /> },
    { id: perxSteps.error, Component: () => <PerxErrorPage /> },
  ];

  const cardCreateSteps = [
    perxSteps.createCard,
    perxSteps.enterUserInfo,
    perxSteps.chooseAmount,
    perxSteps.transferSuccess,
    perxSteps.error,
  ];

  if (isEmpty(transfers)) {
    steps = steps.filter((step) => cardCreateSteps.includes(step.id));
  }

  const StepList = createStepListContainer(STEP_LIST_IDS.perxStepList, steps);

  return <>{isFetching ? <ContentSpinner /> : <StepList />}</>;
};

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getSingleModeCurrentStageSelector,
  isStageSuccessSelector,
  getSingleModeCoinsByStageSelector,
  getSingleModeTotalCoinsSelector,
} from '../../../redux/singleMode/singleModeSelectors';
import { singleModeActions } from '../../../redux/singleMode/singleModeSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { CloseIcon } from '../../../icons/close-icon';
import { APP_TYPES } from '../../../const/appConstants';

const STAGE_NUMBER_ENDINGS = ['st', 'nd', 'rd'];

export const SingleModeStages = (props) => {
  const { goBackCallback } = props;
  const currentStage = useSelector(getSingleModeCurrentStageSelector);
  const isSuccess = useSelector(isStageSuccessSelector);
  const stageResult = useSelector(getSingleModeCoinsByStageSelector);
  const totalResult = useSelector(getSingleModeTotalCoinsSelector);
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const onNextStageCallback = useCallback(() => {
    dispatch(singleModeActions.nextStage());
  }, [dispatch]);

  const onLeaveCallback = useCallback(() => {
    dispatch(singleModeActions.finishSingleMode());
    goBackCallback();
  }, [dispatch, goBackCallback]);

  const getStageStatusInfo = () => {
    let info = (currentStage + 1).toString();

    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND || process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO) {
      info += STAGE_NUMBER_ENDINGS[currentStage];
    }

    if (isSuccess) {
      return formatMessage(`{stage} stage is completed`, { stage: info });
    } else {
      return formatMessage(`{stage} stage is failed :(`, { stage: info });
    }
  };

  const getStageInfo = () => {
    if (isSuccess) {
      if (currentStage === 2) {
        return formatMessage('Great! All stages completed!');
      }
      return formatMessage('Continue playing for bigger reward or left the game and collect credits');
    } else {
      return formatMessage('It aint the end of world. Come back next wednesday and try again');
    }
  };

  const renderStageStatusIcon = (stageNumber) => {
    if (stageNumber === currentStage) {
      if (isSuccess) {
        return (
          <div className="single-mode__stage-success-icon">
            <div className="single-mode__stage-status-icon-success" />
          </div>
        );
      } else {
        return (
          <div className="single-mode__stage-failure-icon">
            <CloseIcon className="single-mode__stage-status-icon-failure" />
          </div>
        );
      }
    } else if (stageNumber < currentStage) {
      return (
        <div className="single-mode__stage-success-icon">
          <div className="single-mode__stage-status-icon-success" />
        </div>
      );
    } else {
      return <div className="single-mode__stage-unstarted-icon" />;
    }
  };

  return (
    <div className="single-mode__stage">
      <div className="single-mode__stage-top">
        <div className="single-mode__stage-status">{getStageStatusInfo()}</div>
        <div className="single-mode__stage-status-icons">
          {renderStageStatusIcon(0)}
          <div className="single-mode__stage-icon-devider" />
          {renderStageStatusIcon(1)}
          <div className="single-mode__stage-icon-devider" />
          {renderStageStatusIcon(2)}
        </div>
        <div className="single-mode__stage-credits">
          {totalResult} {formatMessage('credits')}
        </div>
        {stageResult.extraPoints && isSuccess && (
          <div className="single-mode__stage-extra-credits">
            +{stageResult.extraPoints} {formatMessage('extra points')}
          </div>
        )}
      </div>

      <div className="single-mode__stage-bottom">
        <div className="single-mode__stage-info">{getStageInfo()}</div>
        <div className="single-mode__stage-status-buttons">
          {isSuccess && currentStage !== 2 ? (
            <>
              <Button customClass="single-mode__stage-button-transparent" onClick={onLeaveCallback} withoutTheme>
                {formatMessage('Left the game')}
              </Button>
              <Button customClass="single-mode__stage-button-colored" onClick={onNextStageCallback} withoutTheme>
                {formatMessage('Next stage')}
              </Button>
            </>
          ) : (
            <Button customClass="single-mode__stage-button-colored" onClick={onLeaveCallback} withoutTheme>
              {formatMessage('Got it')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

import { RootState } from '../store';

import { createSelector } from 'reselect';

export const selectMysteryShoppers = createSelector(
  [(state: RootState) => state.mysteryShopper.mysteryShoppers],
  (mysteryShoppers) => mysteryShoppers,
);
export const selectQuestions = createSelector(
  [(state: RootState) => state.mysteryShopper.questions],
  (questions) => questions,
);
export const selectNumberOfQuestions = createSelector(
  [(state: RootState) => state.mysteryShopper.total],
  (total) => total,
);
export const getIsFetching = createSelector(
  [(state: RootState) => state.mysteryShopper.isFetching],
  (isFetching) => isFetching,
);
export const selectIsCorrect = createSelector(
  [(state: RootState) => state.mysteryShopper.isCorrect],
  (isCorrect) => isCorrect,
);
export const getNumberOfPassedQuestions = createSelector(
  [(state: RootState) => state.mysteryShopper.passed],
  (passed) => passed,
);
export const selectMysteryShopperId = createSelector(
  [(state: RootState) => state.mysteryShopper.mysteryShopperId],
  (mysteryShopperId) => mysteryShopperId,
);
export const selectEndDate = createSelector([(state: RootState) => state.mysteryShopper.endDate], (endDate) => endDate);

export const selectBrandId = createSelector([(state: RootState) => state.mysteryShopper.brandId], (brandId) => brandId);

export const selectMysteryShopperRecommendations = createSelector(
  [(state: RootState) => state.mysteryShopper.recommendations],
  (recommendations) => recommendations,
);
export const selectIsSubmitted = createSelector(
  [(state: RootState) => state.mysteryShopper.isSubmitted],
  (isSubmitted) => isSubmitted,
);
export const getCriteriaId = createSelector(
  [(state: RootState) => state.mysteryShopper.criteriaId],
  (criteriaId) => criteriaId,
);
export const getQuestionId = createSelector(
  [(state: RootState) => state.mysteryShopper.questionId],
  (questionId) => questionId,
);
export const getFileKeys = createSelector(
  [(state: RootState) => state.mysteryShopper.fileKeys],
  (fileKeys) => fileKeys,
);
export const getUserAnswers = createSelector([(state: RootState) => state.form?.quizForm?.values], (values) => values);

export const selectPassedMysteryShoppers = createSelector(
  [(state: RootState) => state.mysteryShopper.passedMysteryShoppers],
  (passedMysteryShoppers) => passedMysteryShoppers,
);

export const selectSingleChoiceErrorText = createSelector(
  [(state: any) => state.form?.quizForm?.syncErrors?.single],
  (single) => single,
);

export const selectIsSingleChoiceFieldTouched = createSelector(
  [(state: RootState) => state.form?.quizForm?.fields?.single?.touched],
  (touched) => touched,
);

export const selectErrorText = createSelector(
  [(state: RootState) => state.mysteryShopper.errorText],
  (errorText) => errorText,
);

export const selectPriceQuestionValue = createSelector(
  [(state: RootState) => state.form?.quizForm?.values?.value],
  (value) => value,
);

export const selectMultipleChoiceValues = createSelector(
  [(state: RootState) => state.form?.quizForm?.values?.multiFields],
  (multiFields) => multiFields,
);

import { all, takeLatest, put } from 'redux-saga/effects';
import { achievementsApi } from '../../api/achievementsApi';
import { achievementsActions } from './achievementsSlice';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';

function* getAchievementsSaga() {
  const { data } = yield achievementsApi.getAchievements();
  yield put(achievementsActions.setAchievements(data));
}

function* getAchievementByIdSaga({ payload }) {
  yield put(achievementsActions.setIsFetching({ isFetching: true }));

  const { data } = yield achievementsApi.getAchievementById(payload);
  yield put(achievementsActions.setSelectedAchievement(data));

  yield put(achievementsActions.setIsFetching({ isFetching: false }));
}

function* claimRewardSaga({ payload }) {
  try {
    const { achievementId, taskId, achievementName } = payload;
    const { data } = yield achievementsApi.claimReward(taskId);
    const openModalOnLevelCompleted = () => {
      store.dispatch(
        modalActions.openAchievementLevelCompletedModal({
          achievementName: achievementName,
          level: data.next_level,
          onButtonClick: () => {
            store.dispatch(modalActions.closeModal());
          },
          onClose: () => {
            store.dispatch(modalActions.closeModal());
          },
        }),
      );
    };

    yield put(achievementsActions.getAchievementById(achievementId));
    yield put(
      modalActions.openAchievementRewardModal({
        btnText: 'OK',
        leaf: data.leaf,
        onButtonClick: () => {
          if (data.is_level_completed) {
            openModalOnLevelCompleted();
          } else {
            store.dispatch(modalActions.closeModal());
          }
        },
        onClose: () => {
          if (data.is_level_completed) {
            openModalOnLevelCompleted();
          } else {
            store.dispatch(modalActions.closeModal());
          }
        },
      }),
    );
  } catch (err) {
    console.error('Claim reward saga', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(achievementsActions.getAchievements, getAchievementsSaga),
    takeLatest(achievementsActions.getAchievementById, getAchievementByIdSaga),
    takeLatest(achievementsActions.claimReward, claimRewardSaga),
  ]);
}

export const achievementsSagas = sagas;

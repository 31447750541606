import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  is_new: null,
  quiz_id: null,
  questions: [],
  result: {},
  answers: {
    me: [],
  },
};
const multibrandQuizSlice = createSlice({
  name: 'multibrandQuiz',
  initialState,
  reducers: {
    reset() {
      return { ...initialState };
    },
    startMultibrandQuiz() {},
    startDefinedMultibrandQuiz(state, { payload }) {},
    finishMultibrandQuiz() {},
    setMultibrandQuizData(state, { payload }) {
      const { id, is_new, questions } = payload;
      return {
        ...state,
        is_new,
        quiz_id: id,
        questions,
      };
    },
    setAnswer(state, { payload: { source, answer } }) {
      const newAnswers = { ...state.answers };
      newAnswers[source] = newAnswers[source].concat(answer);
      return {
        ...state,
        answers: newAnswers,
      };
    },
    setMultibrandQuizResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
});

export const multibrandQuizActions = multibrandQuizSlice.actions;
export const multibrandQuizReducer = multibrandQuizSlice.reducer;

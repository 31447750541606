import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './Lep.scss';

import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { getLepSelectedCompetitionsSelector } from '../../../redux/LEP/lepSelector';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';

export const LepCheckboxPage = () => {
  const dispatch = useDispatch();
  const [validation, setValidation] = useState(false);
  const { help_link } = useSelector(getLepSelectedCompetitionsSelector);
  const [checkBoxList, setCheckBoxList] = useState([
    { name: 'name1', checked: false, description: 'I am 18+ years old' },
    { name: 'name2', checked: false, description: 'I am a member of tobacco retailers' },
    { name: 'name3', checked: false, description: 'I agree with Game rules' },
  ]);

  useEffect(() => {
    const checkBoxTrue = checkBoxList.filter((el) => el.checked === true);
    checkBoxTrue.length === checkBoxList.length ? setValidation(true) : setValidation(false);
  }, [checkBoxList]);

  const handleInputChange = ({ target }) => {
    setCheckBoxList((prev) =>
      prev.map((el) => {
        if (el.name === target.name) {
          el.checked = target.checked;
        }
        return el;
      }),
    );
  };

  const handleNextStep = () => {
    analyticHelper.lepAllAgreementCheckbox();
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.lepStepList }));
  };

  return (
    <div className="lep-page__confirm-terms">
      <div className="lep-page__confirm-terms-withoutButton">
        <div className="lep-page__confirm-terms-withoutButton-text">
          <div className="lep-page__confirm-terms-header">Game rules</div>
          <div className="lep-page__confirm-terms-description">
            Confirm that you agree with the terms to join the competition
          </div>
          <a href={help_link} rel="noopener noreferrer" className="lep-page__confirm-terms-link" target="_blank">
            Game rules
          </a>
        </div>
        <form id="lep-page__confirm-terms">
          <div className="lep-page__confirm-terms-checkboxes">
            {checkBoxList.map((checkbox, ind) => (
              <label key={ind} className={`lep-page__confirm-terms-container${checkbox.checked ? '-checked' : ''}`}>
                <input
                  type="checkbox"
                  name={checkbox.name}
                  className="lep-page__confirm-terms-container-checkbox"
                  onChange={handleInputChange}
                  required
                />
                <span className="lep-page__confirm-terms-container-checkmark"></span>
                <div className="lep-page__confirm-terms-container-description">{checkbox.description}</div>
              </label>
            ))}
          </div>
        </form>
      </div>
      <div
        style={{ marginBottom: isIPhoneWithHomeBar() ? '64px' : '30px' }}
        className="lep-page__confirm-terms-button-box"
      >
        <ArrowButton
          customClass={`lep-page__confirm-terms-button${validation ? '' : '-disabled'}`}
          withoutTheme
          disabled={!validation}
          onClick={handleNextStep}
        />
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

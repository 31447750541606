import { all, takeLatest, put, select, call, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { KNOWLEDGE_BANK_ACTION_TYPES, KNOWLEDGE_BANK_CATEGORIES_TYPES } from './knowledgeBankConstants';
import { knowledgeBankActions } from './knowledgeBankSlice';
import { knowledgeBankApi } from '../../api/knowledgeBankApi';
import {
  getCurrentCategorySelector,
  getSelectedArticleSelector,
  getCurrentGameProgressSelector,
  getAttachedInteractiveArticleSelector,
  getCurrentArticleSelector,
} from './knowledgeBankSelectors';
import { getUserIdSelector } from '../auth/authSelectors';
import { Routes } from '../../const';
import { profileActions } from '../profile/profileSlice';
import isEmpty from 'is-empty-typed';
import { analyticHelper } from '../../helpers/analyticHelper';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';
import { MAX_ATTEMPTS_TO_PASS_INTERACTIVE_ARTICLE } from '../../const/articlesConstants';

function* getCategoriesSaga() {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));

    const response = yield knowledgeBankApi.getCategories();

    if (response.data) {
      const categories = response.data;
      yield put(knowledgeBankActions.setCategories({ categories }));
    }
  } catch (err) {
    console.error('getCategories error', err);
  } finally {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

const locationSelector = (state) => state.router.location.pathname;

function* socketHandle(data) {
  const path = yield select(locationSelector);
  if (path === Routes.KnowledgeBankRoute) {
    yield call(getCategoriesSaga);
  } else {
    yield put(knowledgeBankActions.incUnreadTopics(data.payload));
  }
}

function* chooseCategorySaga({ payload: { selectedCategoryId } }) {
  try {
    const selectedCategory = yield select(getCurrentCategorySelector, selectedCategoryId);

    if (selectedCategory && selectedCategory.should_choose_brand) {
      yield put(push(`${Routes.KnowledgeBankRoute}/${selectedCategoryId}/brands`));
    } else {
      if (selectedCategory.type === KNOWLEDGE_BANK_CATEGORIES_TYPES.SALES_TIPS) {
        yield put(push(Routes.KnowledgeBankRoute + Routes.SalesTips));
        return;
      }
      if (selectedCategory.type === KNOWLEDGE_BANK_CATEGORIES_TYPES.SALES_SIMULATORS) {
        yield put(push(Routes.KnowledgeBankRoute + Routes.SalesSimulators));
        return;
      }
      if (selectedCategory.type === KNOWLEDGE_BANK_CATEGORIES_TYPES.GENERAL_QUIZES) {
        yield put(push(Routes.BrandsRoute));
        return;
      }
      yield put(push(`${Routes.KnowledgeBankRoute}/${selectedCategoryId}`));
    }
  } catch (err) {
    console.error('chooseCategorySaga error', err);
  }
}

function* getArticlesSaga({ payload: { selectedCategoryId, selectedKBBrandId } }) {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));
    const response = yield knowledgeBankApi.getArticles({ selectedCategoryId, selectedKBBrandId });
    if (response.data) {
      yield put(knowledgeBankActions.setArticles({ articles: response.data }));
      yield put(knowledgeBankActions.resetInteractiveArticles());
    }
  } catch (err) {
    console.error('getArticlesSaga error', err);
  } finally {
    yield delay(1000);
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

function* getInteractiveArticleSaga({ payload }) {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));
    const response = yield knowledgeBankApi.getInteractiveArticle(payload);
    if (response.data) {
      yield put(knowledgeBankActions.setInteractiveArticle({ interactiveArticle: response.data }));
    }
  } catch (err) {
    console.error('getInteractiveArticlesSaga error', err);
  } finally {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

function* passInteractiveArticleSaga({ payload }) {
  const { id, isCorrect, background_color, answersBySections } = payload;

  try {
    yield knowledgeBankApi.passInteractiveArticle(answersBySections);
    if (isCorrect) {
      yield put(
        knowledgeBankActions.passCorrectInteractiveArticle({
          id,
          isCorrect,
          background_color,
        }),
      );
    } else {
      yield put(
        stepListActions.nextStep({
          stepListId: STEP_LIST_IDS.interactiveArticleStepList,
          props: { manual: false, correct: isCorrect, backgroundColor: background_color },
        }),
      );
    }
  } catch (err) {
    console.error('passInteractiveArticlesSaga error', err);
  }
}
function* passCorrectInteractiveArticleSaga({ payload }) {
  try {
    const response = yield knowledgeBankApi.passCorrectInteractiveArticle(payload.id);

    yield put(knowledgeBankActions.setCoinsForInteractiveArticle(response.data));

    yield put(
      stepListActions.nextStep({
        stepListId: STEP_LIST_IDS.interactiveArticleStepList,
        props: { manual: false, correct: payload.isCorrect, backgroundColor: payload.background_color },
      }),
    );

    if (!response.data) {
      analyticHelper.notBonusReadingInteractiveArticle();
    }
  } catch (err) {
    console.error('passCorrectInteractiveArticlesSaga error', err);
  }
}

function* getKBBrandsSaga() {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));
    const response = yield knowledgeBankApi.getBrands();
    if (response && response.data) {
      yield put(knowledgeBankActions.setKBBrands({ brands: response.data }));
    }
  } catch (err) {
    console.error('getKBBrandsSaga error', err);
  } finally {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

function* chooseKBBrandSaga({ payload: { selectedCategoryId, selectedKBBrand } }) {
  try {
    yield put(push(`${Routes.KnowledgeBankRoute}/${selectedCategoryId}/brands/${selectedKBBrand}`));
  } catch (err) {
    console.error('chooseKBBrandSaga error', err);
  }
}

function* openArticleSaga({ payload: { article } }) {
  try {
    yield put(knowledgeBankActions.setCurrentArticle(article));
    yield put(push(Routes.ArticleRoute));
  } catch (err) {
    console.error('openArticleSaga error', err);
  }
}

function* getCurrentArticleSaga({ payload: { categoryId, entityId } }) {
  try {
    yield call(getArticlesSaga, { payload: { selectedCategoryId: categoryId } });
    const article = yield select(getCurrentArticleSelector, entityId);
    yield call(openArticleSaga, { payload: { article } });
  } catch (err) {
    console.error('Error to get articles and open current', err);
  }
}

function* readArticleSaga({ payload }) {
  try {
    const { articleId, articleType, isSalesTip, type, withProgress } = payload;
    if (withProgress) {
      const userId = yield select(getUserIdSelector);
      const article = yield select(getSelectedArticleSelector);
      const currentGameProgress = yield select(getCurrentGameProgressSelector);
      const progressData = currentGameProgress?.[userId]?.[article.id]?.progress;

      yield knowledgeBankApi.readArticle(articleId, articleType, isSalesTip, type, progressData);
    } else {
      const interactiveArticle = yield select(getAttachedInteractiveArticleSelector);
      yield knowledgeBankApi.readArticle(articleId, articleType, isSalesTip, type);
      yield put(profileActions.getUserInfo());
      const shouldDisplayInteractiveArticle = interactiveArticle?.attempts < MAX_ATTEMPTS_TO_PASS_INTERACTIVE_ARTICLE;
      if (!articleType) {
        if (
          isFeatureEnabled(CONFIG_KEYS.INTERACTIVE_ARTICLE_ENABLED) &&
          !isEmpty(interactiveArticle) &&
          shouldDisplayInteractiveArticle
        ) {
          analyticHelper.openInteractiveArticle();
          yield put(push(Routes.InteractiveArticleRoute));
        } else {
          yield put(push(Routes.KnowledgeBankRoute));
        }
      } else {
        console.log('Article is read with type');
      }
    }
  } catch (err) {
    console.error('readArticleSaga error', err);
  }
}

function* getSalesTipsSaga() {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));
    const { data } = yield knowledgeBankApi.getSalesTips();
    yield put(knowledgeBankActions.setSalesTips(data));
  } finally {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

function* getSalesTipStepsSaga({ payload }) {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));
    const { selectedTipId } = payload;
    const { data } = yield knowledgeBankApi.getSalesTipsSteps(Number(selectedTipId));
    yield put(knowledgeBankActions.setSalesTipSteps(data));
  } finally {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

function* getSalesSimualatorsSaga() {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));
    const { data } = yield knowledgeBankApi.getSalesSimulators();
    yield put(knowledgeBankActions.setSalesSimulators(data));
  } finally {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

function* getSalesSimualatorScenarios({ payload }) {
  try {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: true }));
    const { selectedSimulatorId } = payload;
    const { data } = yield knowledgeBankApi.getSalesSimulatorScenarios(Number(selectedSimulatorId));
    yield put(knowledgeBankActions.setSalesSimulatorScenarios(data));
  } finally {
    yield put(knowledgeBankActions.setIsFetching({ isFetching: false }));
  }
}

function* loseGameSaga({ payload: { articleId } }) {
  try {
    yield knowledgeBankApi.loseGame(articleId);
  } catch (err) {
    console.error('loseGameSaga error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.GET_CATEGORIES, getCategoriesSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.GET_KBBRANDS, getKBBrandsSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.SOCKET_HANDLE, socketHandle),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.CHOOSE_CATEGORY, chooseCategorySaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.CHOOSE_KBBRAND, chooseKBBrandSaga),
    takeLatest(knowledgeBankActions.getArticles, getArticlesSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.GET_INTERACTIVE_ARTICLE, getInteractiveArticleSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.PASS_INTERACTIVE_ARTICLE, passInteractiveArticleSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.PASS_CORRECT_INTERACTIVE_ARTICLE, passCorrectInteractiveArticleSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.OPEN_ARTICLE, openArticleSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.READ_ARTICLE, readArticleSaga),
    takeLatest(knowledgeBankActions.getSalesTips, getSalesTipsSaga),
    takeLatest(KNOWLEDGE_BANK_ACTION_TYPES.GET_SALES_TIP_STEPS, getSalesTipStepsSaga),
    takeLatest(knowledgeBankActions.loseGame, loseGameSaga),
    takeLatest(knowledgeBankActions.getSalesSimulators, getSalesSimualatorsSaga),
    takeLatest(knowledgeBankActions.getSalesSimulatorScenarios, getSalesSimualatorScenarios),
    takeLatest(knowledgeBankActions.getCurrentArticle, getCurrentArticleSaga),
  ]);
}

export const knowledgeBankSagas = sagas;

export const ModerationIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="8" cy="8" r="7.5" stroke="#FFAC30" />
      <path
        d="M5.64697 6.1554H6.97402C7.0034 5.5091 7.37556 5.09157 7.9387 5.09157C8.49695 5.09157 8.86911 5.48622 8.86911 6.02957C8.86911 6.57293 8.67813 6.85318 8.04643 7.29358C7.37067 7.75686 7.10134 8.27162 7.165 9.16958L7.17479 9.50131H8.47246V9.19818C8.47246 8.63767 8.65364 8.35741 9.30493 7.91701C9.99538 7.44229 10.3529 6.84174 10.3529 5.9781C10.3529 4.67405 9.42735 3.76465 8.02685 3.76465C6.52351 3.76465 5.67146 4.7484 5.64697 6.1554ZM7.84077 12.2352C8.28638 12.2352 8.64385 11.8406 8.64385 11.3373C8.64385 10.834 8.28638 10.4393 7.84077 10.4393C7.40005 10.4393 7.04258 10.834 7.04258 11.3373C7.04258 11.8406 7.40005 12.2352 7.84077 12.2352Z"
        fill="#FFAC30"
      />
    </svg>
  );
};

export const BONUS_SHOP_ACTION_TYPES = {
  GET_CATEGORIES: 'bonusShop/getCategories',
  CHOOSE_CATEGORY: 'bonusShop/chooseCategory',
  GET_PROVIDERS: 'bonusShop/getProviders',
  GET_PRODUCTS: 'bonusShop/getProducts',
  CHOOSE_PRODUCT: 'bonusShop/chooseProduct',
  MAKE_ORDER: 'bonusShop/makeOrder',
  CHECK_AVAILABILITY: 'bonusShop/checkAvailability',
  GET_PRIZES: 'bonusShop/getPrizes',
  GET_TRANSFERS: 'bonusShop/getTransfers',
  MAKE_TRANSFER: 'bonusShop/makeTransfer',
  CLOSE_TUTORIAL: 'bonusShop/closeTutorial',
  SET_OPPA_WALLET: 'bonusShop/setOppaWallet',
  REQUEST_OPPA_SMS: 'bonusShop/requestOppaSms',
  MAKE_OPPA_TRANSFER: 'bonusShop/makeOppaTransfer',
  SET_UMICO_WALLET: 'bonusShop/setUmicoWallet',
  MAKE_UMICO_TRANSFER: 'bonusShop/makeUmicoTransfer',
  MAKE_PERX_TRANSFER: 'bonusShop/makePerxTransfer',
  GET_ADDRESS: 'bonusShop/getAddress',
  SET_ADDRESS: 'bonusShop/setAddress',
  SET_PERX_USER_INFO: 'bonusShop/setPerxUserInfo',
  GET_PERX_CARD_INVOICE: 'bonusShop/getPerxCardInvoice',
  GET_PERX_SEGMENTATION_COEFFICIENT: 'bonusShop/getPerxSegmentationCoefficient',
};

export const BONUS_SHOP_CATEGORY_TYPES = {
  PHYSICAL: 1,
  DIGITAL: 2,
  TRANSFER: 3,
  BOOSTER: 4,
  UMICO: 5,
  OPPA: 6,
  UNICARD: 8,
  EPUL: 9,
  EDENRED_VOUCHER: 10,
};

export const EPUL_SMS_STATUSES = {
  OK: 0,
  RETRY_LIMIT: 1,
  ERROR_LIMIT: 2,
  INCORRECT_STATUS: 3,
  INCORRECT_CODE: 4,
  ERROR: 100,
};

export const EPUL_PAY_STATUSES = {
  QUEUED: 0,
  ERROR: 1,
};

import classnames from 'classnames';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { getBonusGamesAmountSelector } from '../../../redux/brands/brandSelectors';
import { quizRateSelectors } from '../../../redux/quizRate/quizRateSelector';
import { getDailyRewardsProducts, getStatusDailyRewards } from '../../../redux/dailyRewards/dailyRewardsSelectors';
import {
  getSoloModeCoinsSelector,
  getSoloModeQuestionsSelector,
  getAnswersSelector,
  getIsBonusGameFinishedSelector,
} from '../../../redux/soloMode/soloModeSelectors';
import { soloModeActions } from '../../../redux/soloMode/soloModeSlice';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { goToSoloModeStepAction } from '../../../redux/soloMode/soloModeSagas';
import history from '../../../helpers/history';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { getWordsFormat } from '../../../helpers/getWordsFormat';
import { isHungary } from '../../../helpers';

import { Button } from '../../../components/common/Button';
import { Routes } from '../../../const';
import { SOLO_MODE_STEPS } from '../../../redux/soloMode/soloModeContants';
import { GAME_RESULT_TEXT } from '../../../const/gameResultsTexts';
import { APP_TYPES } from '../../../const/appConstants.js';
import { DAILY_REWARDS_STATUS } from '../../../redux/dailyRewards/dailyRewardsConstants';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../config';
import '../GameResultStep/GameResultStep.scss';

const COLORS = {
  COLOR_RED: '#DE4D4D',
  COLOR_GREEN: '#4CBF8F',
};

const RESULT_TEXT = {
  lose: GAME_RESULT_TEXT.lose,
  win: GAME_RESULT_TEXT.win,
};

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

export const SoloModeResult = (props) => {
  const { formatMessage } = useInternationalization();

  const { theme, brandId } = props;

  const coins = useSelector(getSoloModeCoinsSelector);
  const isBonusGameFinished = useSelector(getIsBonusGameFinishedSelector);
  const questions = useSelector(getSoloModeQuestionsSelector);
  const answers = useSelector(getAnswersSelector);
  const checkedAnswers = checkCorrectAnswers(answers.me, questions);
  const correct_answers = checkedAnswers.filter((i) => i.isCorrect);
  const dailyStatus = useSelector(getStatusDailyRewards);
  const products = useSelector(getDailyRewardsProducts);
  const isRateModalShown = useSelector(quizRateSelectors.selectIsRateModalShown);

  const bonusGames = useSelector((state) => getBonusGamesAmountSelector(state, brandId));
  const dispatch = useDispatch();

  useEffect(() => {
    analyticHelper.soloModeResult();
    if (isRateModalShown && isHungary) {
      dispatch(modalActions.openQuizRateModal());
    }
  }, []);

  const backToMain = useCallback(() => {
    dispatch(soloModeActions.reset());
    amplitudeHelper.soloQuizBackToMain();
    analyticHelper.soloModeBackToMain();
    if (dailyStatus !== DAILY_REWARDS_STATUS.UNAVAILABLE && products && products.length !== 0) {
      history.replace(Routes.DailyRewardsRoute);
    } else {
      if (process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS) {
        history.replace(Routes.BonusShopRoute);
      } else {
        history.replace(Routes.MainRoute);
      }
    }
  }, [dispatch, dailyStatus, products]);

  const newGameCallBack = useCallback(() => {
    dispatch(goToSoloModeStepAction(SOLO_MODE_STEPS.START));
    amplitudeHelper.soloQuizNewGame();

    if (isFeatureEnabled(CONFIG_KEYS.NON_BONUS_VIEW_ENABLED) && bonusGames === 0) {
      history.replace(`${Routes.NoBonusGamesRoutes}/${brandId}`);
    } else {
      dispatch(soloModeActions.reset());
      dispatch(soloModeActions.startSoloMode({ brandId }));
      history.replace(`${Routes.SoloMode}/${brandId}`);
      analyticHelper.soloModeNewGame();
    }
  }, [dispatch, brandId, bonusGames]);

  const capture =
    correct_answers.length > 0
      ? RESULT_TEXT.win[getRandomInt(0, RESULT_TEXT.win.length)]
      : RESULT_TEXT.lose[getRandomInt(0, RESULT_TEXT.lose.length)];

  const isShowBonusMessage = isFeatureEnabled(CONFIG_KEYS.SHOW_ZERO_BONUS_BLOCK_ENABLED) || coins > 0;

  return (
    <div className={'game-results'} style={{ color: theme.color }}>
      <div className={'solo-mode-result__info'}>
        <div>
          <div className={'game-results-capture'}>{formatMessage(capture)}</div>
          {isShowBonusMessage && (
            <div
              className="solo-mode-result__points-count"
              style={{ color: coins > 0 ? COLORS.COLOR_GREEN : COLORS.COLOR_RED }}
            >
              {isBonusGameFinished ? `+${coins} ${formatMessage(getWordsFormat(coins, 'points'))}` : ''}
            </div>
          )}
        </div>
        <div className="solo-mode-result__corect_answers">
          {formatMessage(`{correct_answers} correct answer${correct_answers.length === 1 ? '' : 's'}`, {
            correct_answers: correct_answers.length,
          })}
        </div>
      </div>
      <div className={'game-results-buttons'}>
        <Button
          withoutTheme={true}
          customClass={classnames('game-results-button-go-to-main', 'game-results-button')}
          onClick={backToMain}
        >
          {formatMessage('Back to main')}
        </Button>
        <Button
          withoutTheme={true}
          customClass={classnames('game-results-button-new-game', 'game-results-button')}
          onClick={newGameCallBack}
        >
          {formatMessage('New game')}
        </Button>
      </div>
    </div>
  );
};

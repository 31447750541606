import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { Routes } from '../../../const';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const AchievementsTutorialStep4 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useInternationalization();

  const handleSetStepTutorial = () => {
    localStorage.setItem('achievementsTutorialCompleted', true);
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.achievementsTutorialStepList }));
    history.push(Routes.Achievements);
    analyticHelper.achievementTutorialFinish();
  };

  return (
    <>
      <div className="achievements-tutorial__full-blackout" />
      <div className="achievements-tutorial__descriptionBox-bottom">
        <div className="achievements-tutorial__descriptionBox-step">4/4</div>
        <div className="achievements-tutorial__descriptionBox-header">{formatMessage('Reward')}</div>
        <div className="achievements-tutorial__descriptionBox-description">
          {formatMessage("After completing each task don't forget to take your reward. Have fun!")}
        </div>
      </div>
      <ArrowButton customClass={'achievements-tutorial__button-next'} onClick={handleSetStepTutorial} />
    </>
  );
};

import { RootState } from '../../store';
import { ticketAdapter } from './supportSlice';

const ticketSelectors = ticketAdapter.getSelectors((state: RootState) => state.chat.support.tickets);

const selectIsFetching = (state: RootState) => state.chat.support.isFetching;

export const supportSelectors = {
  selectIsFetching,
  ...ticketSelectors,
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loyaltyData: {},
};

const loyaltyProgramSlice = createSlice({
  name: 'loyaltyProgram',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    getLoyaltyProgramData() {},
    setLoyaltyProgramData(state, action) {
      const { data } = action.payload;
      return {
        ...state,
        loyaltyData: data,
      };
    },
    sendReceipt(formData) {},
    getReward() {},
  },
});

export const loyaltyProgramActions = loyaltyProgramSlice.actions;
export const loyaltyProgramReducer = loyaltyProgramSlice.reducer;

import { ReactElement } from 'react';
import { ButtonExit } from '../../views';
import styles from './Layout.module.scss';

type Props = {
  children?: ReactElement | null;
};

const Layout = ({ children }: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.streamWrapper}>
      <video id="stream-block" autoPlay muted />
    </div>
    <div className={styles.contentWrapper}>
      <div className={styles.topWrapper}>
        <ButtonExit />
      </div>
      <div className={styles.middleWrapper}>{children}</div>
      <div className={styles.bottomWrapper}>
        <div className={styles.lineFooter} />
      </div>
    </div>
  </div>
);

export default Layout;

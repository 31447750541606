import axios from 'axios';

import { API_URL } from './baseApi';

export const verificationApi = {
  getVerificationInfo: async () => {
    return await axios.get(`${API_URL}/v8/verification`);
  },
  setVerificationMode: async (mode, geo_allowed) => {
    const response = await axios.post(`${API_URL}/v8/verification/mode`, {
      mode,
      geo_allowed,
    });

    return response;
  },
  sendCoordinates: async (longitude, latitude) => {
    const response = await axios.post(`${API_URL}/v8/verification`, {
      longitude,
      latitude,
    });

    return response;
  },
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { singleModeActions } from '../../../redux/singleMode/singleModeSlice';

import { Header } from '../../../components/common/Header';
import { Button } from '../../../components/common/Button';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';

import singleModeRules from './singleModeRules.json';

export const SingleModeRules = (props) => {
  const { goBackCallback } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const onStartCallback = useCallback(() => {
    dispatch(singleModeActions.startSingleMode());
  }, [dispatch]);

  return (
    <div className="single-mode__rules">
      <Header hasBackButton goBackCallback={goBackCallback}>
        <p className="single-mode__rules-header-text">{formatMessage('Single mode rules')}</p>
      </Header>
      <LottieWrapper
        options={options}
        style={{
          height: '340px',
        }}
      />
      <div>
        <div className="single-mode__rules-title">{formatMessage('Are you ready?')}</div>
        <div className="single-mode__rules-info">
          {formatMessage(
            'Every wednesday you will get 3 stages with 5 questions in each. Completion of each stage will be rewarded with credits. Be careful, you have no right for mistake',
          )}
        </div>
      </div>
      <Button customClass="single-mode__rules-button" onClick={onStartCallback}>
        {formatMessage('Start')}
      </Button>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: singleModeRules,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

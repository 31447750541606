import { RootState } from '../store';
import { IIdCardData } from './idCardSlice';

const selectFileKey = (state: RootState): string | null => state.idCard.fileKey;
const selectIdCardData = (state: RootState): IIdCardData | null => state.idCard.idCardData;
const selectIsFetching = (state: RootState): boolean => state.idCard.isFetching;
const selectErrorMessage = (state: RootState): string => state.idCard.ErrorMessage;

const selectIdCardType = (state: any): any => state.form?.idCardType?.values?.type;

export const idCardSelectors = {
  selectFileKey,
  selectIdCardData,
  selectIdCardType,
  selectIsFetching,
  selectErrorMessage,
};

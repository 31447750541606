import React from 'react';

type FieldProps = {
  input?: any;
  label?: string;
  type?: string;
  onClick?: () => void;
  id?: string;
  customInputClass: string;
  customLabelClass?: string;
  placeholder?: string;
  disabled: boolean;
};

export const RenderField = ({
  label,
  input,
  disabled,
  type,
  onClick,
  customInputClass,
  customLabelClass,
}: FieldProps) => {
  return (
    <>
      <input
        type={type}
        placeholder={label}
        id={label}
        onClick={onClick}
        disabled={disabled}
        className={customInputClass}
        {...input}
      />
      <label className={customLabelClass} htmlFor={label}>
        {label}
      </label>
    </>
  );
};

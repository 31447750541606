import { createSlice } from '@reduxjs/toolkit';
import { PASSPORT_PAGES, PASSPORT_PAGE_STATES } from './profileConstants';

const initialState = {
  userInfo: { outlet: {} },
  rating: {},
  isFetchingRating: false,
  earned: [],
  allEarnedLoaded: false,
  coins: Number(localStorage.getItem('balance')),
  passportPageState: PASSPORT_PAGE_STATES.EMPTY,
  currentPassportPage: PASSPORT_PAGES.INFO_PAGE,
  passportData: {},
  statementData: {},
  passportPhotos: {},
  baStatistics: {},
  isMonthlyValidationPassed: false,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    reset() {
      localStorage.setItem('balance', '0');
      return initialState;
    },
    getUserInfo() {},
    setUserInfo(state, action) {
      const { userInfo } = action.payload;
      return {
        ...state,
        userInfo,
        coins: userInfo.coins,
        coinsInCurrency: userInfo.coins_in_currency,
      };
    },

    setLinks(state, action) {
      const { links } = action.payload;
      return {
        ...state,
        links,
      };
    },
    getRating(filterName) {},
    setRating(state, action) {
      const { rating } = action.payload;
      return {
        ...state,
        rating,
      };
    },
    setIsFeinchigRating(state, { payload }) {
      return {
        ...state,
        isFetchingRating: payload.isFetching,
      };
    },
    getEarned(limit, offset) {},
    setEarned(state, action) {
      const { coins, ...history } = action.payload.earned;
      return {
        ...state,
        earned: [...state.earned, ...history.transactions],
        coins,
        allEarnedLoaded: history.transactions.length === 0,
      };
    },
    resetEarner(state) {
      return {
        ...state,
        earned: [],
        allEarnedLoaded: false,
      };
    },
    goToSettings() {},
    changeUserInfo(userInfo) {},
    updateMonthlyValidation() {},
    redirectToBSLimit() {},
    setPassportPageState(state, action) {
      const { passportPageState } = action.payload;
      return {
        ...state,
        passportPageState,
      };
    },
    setPassportData(state, action) {
      const { passportData } = action.payload;

      return {
        ...state,
        passportData,
      };
    },
    setCurrentPassportPage(state, action) {
      const { passportPage } = action.payload;

      return {
        ...state,
        currentPassportPage: passportPage,
      };
    },
    uploadPassportPhoto(state, action) {},
    uploadPassportData() {},
    setStatementData(state, action) {
      const { statementData } = action.payload;
      return {
        ...state,
        statementData,
      };
    },
    setPassportPhotos(state, action) {
      const { passportPhotos } = action.payload;

      return {
        ...state,
        passportPhotos,
      };
    },
    getPassportData() {},
    confirmLegalDocuments() {},
    setIsFetching(state, { payload }) {
      state.isFetching = payload;
    },
    updateConsentProcessingData() {},
    getBAStatistics() {},
    setBAStatistics(state, action) {
      const baStatistics = action.payload;

      return {
        ...state,
        baStatistics,
      };
    },
  },
});

export const profileActions = profileSlice.actions;

export default profileSlice.reducer;

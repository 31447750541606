import { useState } from 'react';
import QRCode from 'qrcode.react';
import copy from 'copy-to-clipboard';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { Button } from '../../components/common/CommonButton/Button';

import styles from './QRCodeGenerator.module.scss';
import { notificationMessageActions } from '../../redux/notificationMessage/notificationMessageSlice';

interface IQRCode {
  value: string;
  size?: number;
}

const QRCodeGenerator = ({ value, size = 220 }: IQRCode) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const copyInviteLink = () => {
    setIsCopied(true);
    dispatch(notificationMessageActions.showNotificationMessage(formatMessage('invite_page.clipboard_message')));
    copy(value);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className={styles.qrcodeContainer}>
      <div className={styles.qrCodeBlock}>
        <QRCode value={value} size={size} className={styles.qrCode} />
      </div>

      <Button
        className={classnames(styles.copyButton, {
          [styles.copied]: isCopied,
        })}
        onClick={copyInviteLink}
      >
        <div className={styles.copyButtonComponent}>{formatMessage('colleagues_invitation.copy_button')}</div>
      </Button>
    </div>
  );
};

export default QRCodeGenerator;

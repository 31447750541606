import { createSelector } from 'reselect';

export const getSoloModeQuestionsSelector = createSelector(
  [state => state.soloMode.questions],
  questions => questions,
);

export const getAnswersSelector = createSelector(
  [state => state.soloMode.answers],
  answers => answers,
);

export const getPackIdSelector = createSelector(
  [state => state.soloMode.pack_id],
  pack_id => pack_id,
);

export const getGameTimeSelector = createSelector(
  [state => state.soloMode.game_time],
  game_time => game_time,
);

export const getSoloModeCoinsSelector = createSelector(
  [state => state.soloMode.coinsForGame],
  coins => coins,
);

export const getIsBonusGameFinishedSelector = createSelector(
  [state => state.soloMode.isBonusGameFinished],
  isBonusGameFinished => isBonusGameFinished,
);

export const getCoinsForQuestionSelector = createSelector(
  [state => state.soloMode.coins],
  coins => coins,
);

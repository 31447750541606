import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import '../profileSettings.scss';

import { Input } from '../../../components/common/Input';
import { Routes } from '../../../const';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { InfoIcon } from '../../../icons/info-icon';
import { olderThen18, username, lengthMinMax } from '../../../helpers/formValidations';
import { SetThemeComponent } from './setThemeComponent';

const UserInfo = ({ handleSubmit, history, initialValues }) => {
  const { formatMessage } = useInternationalization();

  const onIconClick = () => history.push(Routes.ManagerPageRoute);

  return (
    <form onSubmit={handleSubmit} className="edit-profile-form">
      <div className="profile-settings-page__content-fields">
        <p className={classnames('profile-settings-page__content-color-themes-caption', 'your-data-caption')}>
          {formatMessage('Your data:')}
        </p>
        <>
          <Input
            name="username"
            customClass="profile-settings-page__input"
            validate={[username, lengthMinMax(2, 15, formatMessage)]}
          />
          <Input name="date_of_birth" type="date" validate={[olderThen18]} customClass="profile-settings-page__input" />
          <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
            {formatMessage('JTI account tier:')}
          </p>
          <Input name="tier_discount" customClass="profile-settings-page__input" disabled />
          <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
            {formatMessage('JTI representative:')}
          </p>
          <Input
            name="manager_name"
            customClass="profile-settings-page__input"
            id="managers"
            disabled
            InputButton={<InfoIcon className="profile-settings-page__info-icon" onClick={onIconClick} />}
          />
          <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
            {formatMessage('Outlet data:')}
          </p>
          <Input name="city" customClass="profile-settings-page__input" disabled />
          <Input name="legal_entity" customClass="profile-settings-page__input" disabled />
          <Input name="address" customClass="profile-settings-page__input" disabled />
        </>
      </div>
      <SetThemeComponent initialValues={initialValues} />
    </form>
  );
};

const form = reduxForm({
  form: 'userInfoForm',
  initialValues: {},
  enableReinitialize: true,
  destroyOnUnmount: false,
})(UserInfo);

export const UserInfoForm = withRouter(form);

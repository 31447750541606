import { useInternationalization } from '../../../hooks/useTranslationHook';
import { BoxRightArrow } from '../../../icons';

import styles from './ChanceCardIreland.module.scss';

type Props = {
  onClick: () => void;
};

export const ChanceCardIreland = ({ onClick }: Props) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className={styles.container} onClick={onClick}>
      <span className={styles.title}>{formatMessage('Chance')}</span>
      <span className={styles.description}>{formatMessage('Not enough points? Try your luck!')}</span>
      <BoxRightArrow className={styles.arrow} fillColor="white" />
    </div>
  );
};

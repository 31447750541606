import '../quizPage.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { QUESTION_OF_THE_DAY_STEPS } from '../../../redux/questionOfTheDay/questionOfTheDayConstants';
import { QuestionOfTheDayComponent } from './questionOfTheDayComponent';
import { QuestionOfTheDayFinalComponent } from './questionOfTheDayFinalComponent';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { QuestionIcon } from '../../../icons/question-icon';
import { getCurrentComponentSelector } from '../../../redux/mainPage/mainPageSelectors';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../../const/brandsConstants';
import { APP_TYPES } from '../../../const/appConstants';
import { questionOfTheDaySliceActions } from '../../../redux/questionOfTheDay/questionOfTheDaySlice';

export const QuestionOfTheDayPageComponent = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const [theme, setTheme] = useState();

  const qotdHungary = useSelector((state) =>
    state.brands.brandsInfo.find((x) => x.id === QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID),
  );

  const qotdIreland = useSelector(getCurrentComponentSelector);

  const getBarndId = () => {
    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) {
      return qotdIreland.payload.question.brand_id;
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY) {
      const themeDailyQuestion = {
        name: qotdHungary.name,
        background: `url(${qotdHungary.background_image})`,
        color: qotdHungary.text_color,
        dailyQuestionBorderColor: '#FFFFFF',
        logo: <QuestionIcon />,
      };
      setTheme(themeDailyQuestion);
    } else {
      const brandSettings = qotdIreland.payload.brand;
      const themeDailyQuestion = {
        name: brandSettings.name,
        background: `url(${brandSettings.background_image})`,
        color: brandSettings.text_color,
        dailyQuestionBorderColor: '#FFFFFF',
        logo: <QuestionIcon />,
      };
      setTheme(themeDailyQuestion);
    }
  }, [qotdHungary, qotdIreland]);

  useEffect(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.questionOfTheDay,
        stepId: 1,
      }),
    );

    return () => dispatch(questionOfTheDaySliceActions.reset());
  }, [dispatch]);

  const QuestionOfTheDayStepList = createStepListContainer(STEP_LIST_IDS.questionOfTheDay, [
    {
      id: QUESTION_OF_THE_DAY_STEPS.GAME,
      Component: () => <QuestionOfTheDayComponent theme={theme} history={history} brandId={getBarndId()} />,
    },
    {
      id: QUESTION_OF_THE_DAY_STEPS.RESULT,
      Component: () => <QuestionOfTheDayFinalComponent history={history} theme={theme} />,
    },
  ]);

  if (!theme) {
    return null;
  }

  return (
    <div
      style={{
        background: theme.background,
        color: theme.color,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
      className="quiz-page quiz-page__daily-question"
    >
      <QuestionOfTheDayStepList />
    </div>
  );
};

export const QuestionOfTheDayPage = withRouter(QuestionOfTheDayPageComponent);

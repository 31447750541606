import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { analyticHelper } from '../../../helpers/analyticHelper';
import { profileActions } from '../../../redux/profile/profileSlice';
import { NavigationMenu } from '../../../components';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { ProfileSettingsCaucasus } from '../../profileSettings/profileSettingsCaucasus';
import { Header } from '../../../components/common/Header';
import { useInternationalization } from '../../../hooks/useTranslationHook';

import '../profilePage.scss';

export const ProfileCaucasusLayoutWithoutCheckin = ({ location }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  useEffect(() => {
    dispatch(
      profileActions.getRating({
        filterName: 'week',
        brand_id: 0,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    analyticHelper.profileOpen();
  }, []);

  return (
    <div className="profile-page">
      <Header hasBackButton>
        <p className="profile-settings-page__header-text">{formatMessage('Edit profile')}</p>
      </Header>
      <div className="profile-page__views">
        <div>
          <div style={{ overflow: 'auto' }}>
            <ProfileSettingsCaucasus />
          </div>
        </div>
      </div>
      <NavigationMenu location={location} />
      <DisclaimerFooter />
    </div>
  );
};

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import LogRocket from 'logrocket';

import './styles/index.scss';

import App from './App';
import packageJson from '../package.json';
import { store } from './redux/store';
import history from './helpers/history';
import { APP_TRANSLATIONS, handleI18Error } from './translations/internationalization';
import { addErrorInterceptor } from './api/ErrorsHandler';
import { setAppSettings } from './helpers/appSettingsHelpers';
import { initFirebaseMessaging } from './helpers/firebaseHelper';
import { amplitudeHelper } from './helpers/ampitudeHelper';
import { APP_LOCALE } from './const/appConstants';
import { initGoogleAnalytics } from './helpers/googleAnalytics';
import { registerServiceWorker } from './helpers/serviceWorker';

addErrorInterceptor(store);
setAppSettings();
initFirebaseMessaging();
initGoogleAnalytics();
registerServiceWorker();

if (process.env.REACT_APP_LOG_ROCKET_APP_ID) {
  LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
}

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  amplitudeHelper.initAmplitude();
}

console.log('App version:', packageJson.version);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <IntlProvider locale={APP_LOCALE} messages={APP_TRANSLATIONS} defaultLocale={'en'} onError={handleI18Error}>
        <Router history={history}>
          <App />
        </Router>
      </IntlProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

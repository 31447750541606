import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import isEmpty from 'is-empty-typed';

import { LoaderContainer, Spinner } from '../../../../components/common';

import { notificationsSelectors, notificationsActions, chatConstants, chatActions } from '../../../../redux/chat';
import { MessageBlock, NavigationWrapper } from '../../components';
import styles from './Notifications.module.scss';

export const Notifications = () => {
  const dispatch = useDispatch();

  const messagesByDate = useSelector(notificationsSelectors.selectMessagesByDate);
  const hasMore = useSelector(notificationsSelectors.selectHasMore);
  const isFetching = useSelector(notificationsSelectors.selectIsFetching);
  const totalCount = useSelector(notificationsSelectors.selectTotal);
  const isInitialLoading = isFetching && !totalCount;

  const fetchMore = () => {
    dispatch(notificationsActions.fetchMessages({ offset: totalCount }));
  };

  useEffect(() => {
    dispatch(notificationsActions.fetchMessages({ offset: 0 }));

    return () => {
      dispatch(notificationsActions.reset());
      dispatch(chatActions.fetchUnreadMessagesCount());
    };
  }, []);

  return (
    <NavigationWrapper>
      <div id={chatConstants.SCROLL_CONTAINER_ID} className={styles.notifications}>
        <LoaderContainer isLoading={isInitialLoading} className={styles.loaderContainer}>
          {isEmpty(messagesByDate) ? (
            <div className={styles.noItemsLabel}>There are no notifications yet</div>
          ) : (
            <InfiniteScroll
              scrollableTarget={chatConstants.SCROLL_CONTAINER_ID}
              dataLength={totalCount}
              hasMore={hasMore}
              next={fetchMore}
              loader={<Spinner width={100} />}
            >
              {messagesByDate.map(([date, messages]) => (
                <MessageBlock key={date} date={date} messages={messages} isNotifications />
              ))}
            </InfiniteScroll>
          )}
        </LoaderContainer>
      </div>
    </NavigationWrapper>
  );
};

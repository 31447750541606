import { useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { InfiniteScroll } from '../infiniteScroll/infiniteScroll';
import { Link } from 'react-router-dom';

import { Button } from '../common/Button';
import { Routes } from '../../const';
import { AvatarIconDefault } from '../../icons';
import { profileActions } from '../../redux/profile/profileSlice';
import { formatDate, isCaucasus } from '../../helpers';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getCoinsCount } from '../../helpers/coinFormatter';
import { analyticHelper } from '../../helpers/analyticHelper';
import { isBelarus } from '../../helpers';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';

import './balanceTransactions.scss';

export const BalanceTransactions = ({ activePage }) => {
  const limit = 20;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const earned = useSelector((state) => state.profile.earned);
  const allEarnedLoaded = useSelector((state) => state.profile.allEarnedLoaded);
  const getEarned = useCallback(
    (page) => {
      if (allEarnedLoaded) {
        return;
      }
      dispatch(profileActions.getEarned({ limit: limit, offset: page * limit }));
    },
    [dispatch, allEarnedLoaded],
  );

  useEffect(() => {
    if (activePage === 1) {
      amplitudeHelper.openBalance();
      analyticHelper.balanceOpen();
    }
  }, [activePage]);

  const getTitleByStatus = (status) => {
    switch (status) {
      case 1:
        return formatMessage('Win');
      case 2:
        return formatMessage('Lose');
      case 3:
        return formatMessage('Draw');
      default:
        return;
    }
  };

  const getIcon = (transaction) => {
    const GAME_TYPE = 1;
    const { icon, opponent } = transaction;
    if (transaction.type === GAME_TYPE) {
      if (opponent) {
        if (opponent.photo) {
          return (
            <div
              className={'balanceboard__block-event-photo'}
              style={{
                background: `url(${opponent.photo})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: '50% 50%',
              }}
            />
          );
        } else {
          return <AvatarIconDefault />;
        }
      } else {
        return (
          <div
            className={'balanceboard__block-event-photo'}
            style={{
              background: `url(${icon})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
            }}
          />
        );
      }
    }

    return (
      <div
        className={'balanceboard__block-event-photo'}
        style={{
          background: `url(${icon})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '50% 50%',
        }}
      />
    );
  };

  const renderTransaction = (transaction) => {
    const { id, opponent, created_at, coins, title, status } = transaction;
    const coinsStr = getCoinsCount(coins);
    return (
      <div key={id} className={'balanceboard__block'}>
        <div className="balanceboard__block-event">
          {getIcon(transaction)}
          <div className="balanceboard__block-event-info">
            <div className="balanceboard__block-event-info-block">
              <p className="balanceboard__block-event-info-block-title">
                {opponent ? getTitleByStatus(status) : title}
              </p>
            </div>
            <p className="balanceboard__block-event-info-name">{opponent ? opponent.name : ''}</p>
            <p className="balanceboard__block-event-info-name">{formatDate(new Date(created_at), 'd MMMM')}</p>
          </div>
        </div>
        <div className="balanceboard__block-coins">
          <p
            className={classnames(
              coins > 0 ? 'balanceboard__block-coins-positive' : 'balanceboard__block-coins-negative',
            )}
          >
            {`${coins > 0 ? '+' + coinsStr : coinsStr}`}
          </p>
        </div>
      </div>
    );
  };

  return (
    <InfiniteScroll
      startPage={0}
      loadMore={getEarned}
      hasMore={earned.length > 0}
      className={'balanceboard__block-scroll'}
    >
      {!isBelarus() ||
        (!isCaucasus && (
          <Link
            to={Routes.PromoCodeRoute}
            onClick={() => amplitudeHelper.openPromoCode()}
            className={'balance-history-page__got-promo-code-link'}
          >
            <Button customClass={'balance-history-page__got-promo-code-button'}>
              {formatMessage('Got a promo code?')}
            </Button>
          </Link>
        ))}
      {earned && earned.map((transactionInfo) => renderTransaction(transactionInfo))}
    </InfiniteScroll>
  );
};

import { createSelector } from 'reselect';
import { COMPONENT_TYPES } from '../../const/mainScreenConsts';

export const getMainScreenData = createSelector(
  [(state) => state.mainPage.mainScreenData],
  (mainScreenData) => mainScreenData,
);

export const getCurrentComponentSelector = createSelector(
  [(state) => state.mainPage.currentComponent],
  (currentComponent) => currentComponent,
);

export const isGeneralQuizActiveSelector = createSelector([(state) => state.mainPage.currentComponent], (data) => {
  if (!data) {
    return false;
  }
  return data.type === COMPONENT_TYPES.GENERAL_QUIZ;
});

export const getMainScreenIsFetching = createSelector(
  [(state) => state.mainPage.isFetching],
  (isFetching) => isFetching,
);

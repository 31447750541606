import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { IGoal } from '../GoalTrackerTypes';
import RewardIcon from '../../../images/reward.svg';
import GoalIcon from '../../../images/goal.svg';

import styles from './GoalDetails.module.scss';

interface IGoalDetails {
  goal: IGoal;
  number: number;
}

const GoalDetails = ({ goal, number }: IGoalDetails) => {
  const { formatMessage } = useInternationalization();
  return (
    <div className={styles.goalDetailsContainer}>
      <div className={styles.goalWrapper}>
        <img className={classnames(styles.icon, styles.goalIcon)} src={GoalIcon} />
        <span className={styles.goalNumber}>{number}:</span>
        <b className={styles.goalAmount}>{goal.amount}</b>
        <span>{formatMessage('goal_tracker.packs')}</span>
      </div>

      <div className={styles.rewardWrapper}>
        <img className={classnames(styles.icon, styles.rewardIcon)} src={RewardIcon} />
        <span className={styles.goalReward}>{goal.reward}</span>
        <span>{formatMessage('coins')}</span>
      </div>
    </div>
  );
};
export default GoalDetails;

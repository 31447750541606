import { createSlice } from '@reduxjs/toolkit';

import { getIsDoublePointsQuestion, getQuestionsSelector } from './gameSelectors';
import { decryptAnswer } from '../../helpers/cryptCorrectAnswers';

const initialState = {
  answers: {
    me: [],
    opponent: [],
  },
  inviteData: null,
  currentQuestion: 0,
  pack_id: null,
  opponent: null,
  round_time: 15,
  questions: null,
  isBonusGameFinished: false,
  coinsForGame: 0,
  gamePoints: {
    me: 0,
    opponent: 0,
  },
  gameTimer: {
    questionStartTime: 0,
    questionTimer: 0,
    me: 0,
    opponent: 0,
    isStopped: false,
    isPaused: false,
  },
  rateQuiz: null,
  isFetching: false,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    run() {},
    startQuiz() {},
    reset(state, { payload }) {
      let withInviteData = false;
      const { inviteData } = state;
      const initialStateWithInviteData = {
        ...initialState,
        inviteData: !inviteData ? null : { ...inviteData },
      };

      if (payload) {
        withInviteData = payload.withInviteData;
      }

      if (withInviteData) {
        return initialStateWithInviteData;
      }
      return initialState;
    },
    setInviteData(state, { payload }) {
      return {
        ...state,
        inviteData: payload,
      };
    },
    setMyAnswer(state, { payload }) {},
    setOpponentAnswer(state, { payload }) {},
    onInvite({ payload }) {},
    onInviteAnswer({ payload }) {},
    inviteOpponent(state, { payload: { onSuccess, onFailure } }) {},
    opponentInvitedSuccess() {},
    opponentInvitedFailed() {},
    setGameFound(state, { payload }) {
      const { opponent, bot, pack_id } = payload;
      return {
        ...state,
        pack_id,
        opponent: {
          opponent,
          bot,
        },
      };
    },
    setQuestions(state, { payload }) {
      const { round_time, questions } = payload;
      return { ...state, round_time, questions };
    },
    setAnswer(state, { payload: { source, answer } }) {},
    setAnswers(state, { payload: currentQuestionAnswers }) {
      const newAnswers = { ...state.answers };

      currentQuestionAnswers.forEach((currentQuestionAnswer) => {
        const { source, answer } = currentQuestionAnswer;
        newAnswers[source] = newAnswers[source].concat(answer);
      });

      return {
        ...state,
        answers: newAnswers,
      };
    },
    nextQuestion(state) {
      let currentQuestion = state.currentQuestion;
      if (currentQuestion < state.questions.length - 1) {
        currentQuestion += 1;
      }

      return {
        ...state,
        currentQuestion,
      };
    },
    subscribeToOpponentAnswers() {},
    setGamePoints(state) {
      const { gamePoints, gameTimer } = state;
      const newPoints = { ...gameTimer };

      return {
        ...state,
        gamePoints: {
          me: gamePoints.me + newPoints.me,
          opponent: gamePoints.opponent + newPoints.opponent,
        },
      };
    },
    setQuestionStartTime(state, { payload }) {
      const { time, questionTimer } = payload;

      const newTime = +time;

      return {
        ...state,
        gameTimer: {
          ...state.gameTimer,
          questionStartTime: newTime,
          questionTimer: questionTimer * 1000,
          me: 0,
          opponent: 0,
        },
      };
    },
    setPointsForThisQuestion(state, { payload }) {
      const { time, source, points, answer } = payload;
      const { gameTimer, currentQuestion } = state;

      const questions = getQuestionsSelector({ game: state });
      const isDoublePointsQuestion = getIsDoublePointsQuestion({ game: state });

      let pointsToSet = 0;

      const decryptedAnswer = Number.isInteger(answer) ? answer : decryptAnswer(answer);
      const decryptedCorrectAnswers = questions[currentQuestion].correct_answers.map((answer) => decryptAnswer(answer));

      const isCorrect = decryptedCorrectAnswers.indexOf(decryptedAnswer);

      if (points && isCorrect !== -1) {
        pointsToSet = points;
      } else if (time && isCorrect !== -1) {
        pointsToSet = Math.trunc(Math.round((gameTimer.questionTimer + +gameTimer.questionStartTime - +time) / 1000));
        if (isDoublePointsQuestion) {
          pointsToSet *= 2;
        }
      }

      const answerPoints = {};
      answerPoints[source] = pointsToSet;

      return {
        ...state,
        gameTimer: {
          ...state.gameTimer,
          ...answerPoints,
        },
      };
    },
    setTimerStoped(state, { payload }) {
      const status = payload;

      return {
        ...state,
        gameTimer: { ...state.gameTimer, isStopped: status },
      };
    },
    setTimerPaused(state, { payload }) {
      const status = payload;

      return {
        ...state,
        gameTimer: { ...state.gameTimer, isPaused: status },
      };
    },
    setFinishedNotBonusGame(state, { payload }) {
      const bonusGamesAmount = payload;

      return {
        ...state,
        isBonusGameFinished: !!bonusGamesAmount,
      };
    },
    setCoinsForGame(state, { payload }) {
      const { coins } = payload;

      return {
        ...state,
        coinsForGame: coins,
      };
    },
    leaveGame() {},
    quizRatesOpenOrCloseModal(state, action) {
      state.rateQuiz = action.payload;
    },
    setIsFetching(state, { payload }) {
      state.isFetching = payload;
    },
  },
});

export const gameActions = gameSlice.actions;
export const gameReducer = gameSlice.reducer;

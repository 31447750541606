import { useSelector, useDispatch } from 'react-redux';
import './AchievementRewardModal.scss';

import { ModalContainer } from '../../../containers/Modal/ModalContainer';
import { MODALS } from '../../../containers/Modal/modalConstants';
import { getWordsFormat } from '../../../../helpers/getWordsFormat';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { Button } from '../../../common/Button';
import { getModalDataSelector } from '../../../containers/Modal/modalSelectors';
import { modalActions } from '../../../containers/Modal/modalSlice';
import rewardLeafImage from './leaf-coin.png';

const styles = {
  width: 343,
};

export const AchievementRewardModal = (props) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const {
    data: { onClose, onButtonClick, leaf },
  } = useSelector(getModalDataSelector);

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.achievementReward} onClose={onClose} customStyles={styles}>
      <div className="achievement-reward">
        <div>
          <img className="achievement-reward__reward-image" src={rewardLeafImage} alt="reward" />
        </div>
        <div className="achievement-reward__reward">
          {formatMessage(getWordsFormat(leaf, `+{leaf} Leaf`), { leaf })}
        </div>
        <div className="achievement-reward__description">{formatMessage('Keep it up! New day, new goal!')}</div>
        <Button onClick={handleButtonClick} customClass="achievement-reward__btn">
          {formatMessage('OK')}
        </Button>
      </div>
    </ModalContainer>
  );
};

import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import './Lep.scss';

import { getLepCompetitionsSelector } from '../../../redux/LEP/lepSelector';
import { Button } from '../../../components/common/Button';
import { lepActions } from '../../../redux/LEP/lepSlice';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { Routes } from '../../../const';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { isAuthorizedSelector } from '../../../redux/auth/authSelectors';

export const LepChooseCompetition = () => {
  const history = useHistory();
  const competitions = useSelector(getLepCompetitionsSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const dispatch = useDispatch();

  const handleNextStep = (card) => {
    dispatch(lepActions.setSelectedCompetition({ selectedCompetition: card }));
    if (isAuthorized) {
      dispatch(lepActions.getCodes({ competitionId: card.id }));
    }
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.lepStepList }));
  };

  const previousStep = () => {
    history.push(Routes.MainRoute);
  };

  return (
    <div className="lep-page__choose-competition">
      <BackArrowButton
        onHistoryReplaceCallback={previousStep}
        buttonColor={'black'}
        customClass="lep-page__back-button"
      />
      <div className="lep-page__choose-competition-header">Choose the {'\n'} competition to join</div>
      <div className="lep-page__choose-competition-cards">
        {competitions.map((card, ind) => (
          <div
            className="lep-page__choose-competition-card"
            key={ind}
            style={{
              backgroundImage: `url(${card?.background_image})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: '50% 50%',
            }}
            onClick={() => handleNextStep(card)}
          >
            <div className="lep-page__choose-competition-card-description" style={{ color: card?.background_color }}>
              <div className="lep-page__choose-competition-card-manual">Tap to join</div>
              <div className="lep-page__choose-competition-card-description-text">{card.name}</div>
            </div>
            <Button customClass="lep-page__choose-competition-card-button" withoutTheme={true}>
              Join
            </Button>
          </div>
        ))}
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

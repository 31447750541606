import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import './achievementsSummary.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { profileActions } from '../../../redux/profile/profileSlice';
import { getAchievementsStats } from '../../../redux/profile/profileSelectors';

export const AchievementsSummary = ({ customClass, onClick }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileActions.getUserInfo());
  }, [dispatch]);

  const stats = useSelector(getAchievementsStats);

  return (
    <div className={classnames('achievements-summary', customClass)} onClick={onClick}>
      <div className="achievements-summary-info">
        <p className="achievements-summary-info-caption">{formatMessage('Available')}</p>
        <p className="achievements-summary-info-amount">{stats?.available}</p>
      </div>
      <div className="achievements-summary-info">
        <p className="achievements-summary-info-caption">{formatMessage('Completed')}</p>
        <p className="achievements-summary-info-amount">{stats?.completed}</p>
      </div>
      <div className="achievements-summary-info">
        <p className="achievements-summary-info-caption">{formatMessage('Leaf')}</p>
        <p className="achievements-summary-info-amount">{stats?.leaf}</p>
      </div>
    </div>
  );
};

export const OkIcon = (props) => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.0476 1.14415L5.76467 11.1787L2.01009 7.12403C1.5509 6.62814 0.803588 6.62814 0.344395 7.12403C-0.114798 7.61992 -0.114798 8.42696 0.344395 8.92286L4.93633 13.8818C5.17042 14.1346 5.46755 14.2513 5.77368 14.2513C6.07981 14.2513 6.37693 14.1249 6.61103 13.8818L16.7223 2.9527C17.1815 2.45681 17.1815 1.64977 16.7223 1.15387C16.2541 0.648258 15.5068 0.648258 15.0476 1.14415Z"
        fill="white"
      />
    </svg>
  );
};

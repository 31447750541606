import PropTypes from 'prop-types';
import './PromptWithTwoButtons.scss';
import { ModalContainer } from '../containers/Modal/ModalContainer';
import { MODALS } from '../containers/Modal/modalConstants';
import { ModalTitle } from '../containers/Modal/ModalTitle';
import { useInternationalization } from '../../hooks/useTranslationHook';

import './ErrorModal.scss';
import { Button } from '../common/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getModalDataSelector } from '../containers/Modal/modalSelectors';
import { modalActions } from '../containers/Modal/modalSlice';
import classnames from 'classnames';
const styles = {
  width: 343,
};

export const TwoButtonsPromptModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  let {
    data: {
      onClose,
      description,
      btnText,
      anotherBtnText,
      onButtonClick,
      onAnotherButtonClick,
      title,
      link,
      linkText,
      image: titleImage,
      descriptionImage,
      modalHeader,
    },
  } = useSelector(getModalDataSelector);
  if (description && description.map) {
    description = description.map((el, i) => <div key={i}>{formatMessage(el)}</div>);
  } else if (description) {
    description = formatMessage(description);
  }

  const handleButtonClick = (onButtonClick) => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.promptWithTwoButtons} onClose={onClose} customStyles={styles}>
      <div className="two-buttons-prompt-modal">
        {titleImage}
        {modalHeader && <div className="two-buttons-prompt-modal__header">{modalHeader}</div>}

        {title && <ModalTitle>{formatMessage(title)}</ModalTitle>}

        {descriptionImage && <img className="two-buttons-prompt-modal__description__image" src={descriptionImage} />}

        {description && <div className="two-buttons-prompt-modal__description">{description}</div>}
        {linkText && link && (
          <a href={link} target="_blank" rel="noopener noreferrer" className="two-buttons-prompt-modal__link">
            {linkText}
          </a>
        )}
        <div className="two-buttons-prompt-modal__btn-container">
          {btnText && (
            <Button
              onClick={() => handleButtonClick(onButtonClick)}
              customClass="two-buttons-prompt-modal__btn-container__btn"
            >
              {formatMessage(btnText)}
            </Button>
          )}
          {anotherBtnText && (
            <Button
              withoutTheme
              onClick={() => handleButtonClick(onAnotherButtonClick)}
              className={classnames('two-buttons-prompt-modal__btn-container__second-btn', { themedModalButton: true })}
            >
              {formatMessage(anotherBtnText)}
            </Button>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

TwoButtonsPromptModal.propTypes = {
  onClose: PropTypes.func,
  description: PropTypes.node,
};
TwoButtonsPromptModal.defaultProps = {};

import { useDispatch } from 'react-redux';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const AchievementsTutorialStep1 = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const handleSetStepTutorial = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.achievementsTutorialStepList }));
  };
  return (
    <>
      <div className="achievements-tutorial__blackout-statistik-top" />
      <div className="achievements-tutorial__blackout-statistik-left" />
      <div className="achievements-tutorial__blackout-statistik-right" />
      <div className="achievements-tutorial__blackout-statistik-bottom" />
      <div className="achievements-tutorial__descriptionBox-bottom">
        <div className="achievements-tutorial__descriptionBox-step">1/4</div>
        <div className="achievements-tutorial__descriptionBox-header">{formatMessage('Progress counter')}</div>
        <div className="achievements-tutorial__descriptionBox-description">
          {formatMessage('On the top of your screen you could see your progress in numbers')}
        </div>
      </div>
      <ArrowButton customClass={'achievements-tutorial__button-next'} onClick={() => handleSetStepTutorial(2)} />
    </>
  );
};

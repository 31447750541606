export const findCodeInDetectionResponse = (detectionResponse: any) => {
  const textAnnotations = detectionResponse?.responses[0]?.textAnnotations;

  if (!textAnnotations) {
    return null;
  }

  for (let i = 1; i < textAnnotations.length; i++) {
    const element = textAnnotations[i];

    if (textAnnotations[i - 1]?.description === '&') {
      return element.description as string;
    }
  }

  return null;
};

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { getUserInfoSelector } from '../../../../redux/profile/profileSelectors';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { ArrowButton } from '../../../../components/common/ArrowButton/ArrowButton';
import { BackButton } from '../../../../components/common';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { FAQ_ARTICLE_ID } from '../../../../redux/knowledgeBank/knowledgeBankConstants';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';

import styles from './PhoneNumber.module.scss';

export const PhoneNumber = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfoSelector);
  const [isTimerOn, setIsTimerOn] = useState(false);

  const openArticle = () => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://fra1.digitaloceanspaces.com/jti-club-az/bonus-shop/epul-articles/e-pul.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  };

  const makeTransfer = () => {
    dispatch(bonusShopActions.sendEpulSms({}));
  };

  const onClick = () => {
    dispatch(bonusShopActions.checkEpulNumber({ setIsTimerOn }));
  };

  const onBackClick = () => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.epulStepsList }));
  };

  const showTimeoutPopup = () => {
    setIsTimerOn(false);

    dispatch(
      modalActions.openPromptModal({
        title: 'Something go wrong',
        description: "If you haven't received a verification code via SMS, press 'Send again'",
        btnText: 'Send again',
        onButtonClick: () => {
          dispatch(bonusShopActions.resendEpulSms({ setIsTimerOn }));
          dispatch(modalActions.closeModal());
          dispatch(modalActions.openSMSCodeInputModal({ makeTransfer }));
        },
        onClose: () => {
          setIsTimerOn(true);
          dispatch(modalActions.openSMSCodeInputModal({ makeTransfer }));
        },
      }),
    );
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isTimerOn) {
      timer = setTimeout(showTimeoutPopup, 30000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, isTimerOn]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <BackButton className={styles.backButton} onClick={onBackClick} />

        <span className={styles.title}>{formatMessage('Check your telephone number')}</span>
        <span className={styles.description}>
          {formatMessage('On this number will be sent SMS-code for a wallet-registration in JTI Club')}
        </span>

        <span className={styles.phoneInfo}>{userInfo.phone}</span>

        <span onClick={openArticle} className={styles.link}>
          {formatMessage('EPUL wallet is attached to another number')}
        </span>
      </div>

      <ArrowButton withoutTheme customClass={styles.button} fillcolor="#01c1b6" disabled={false} onClick={onClick} />
    </div>
  );
};

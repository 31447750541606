import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './oppa.scss';

import { OppaMainPage } from './oppaWallet/oppaMainPage';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { OppaStartCreateWallet } from './oppaCreateWallet/oppaStartCreateWallet';
import { OppaInputDate } from './oppaCreateWallet/oppaInputDate';
import { OppaCreateWalletSuccess } from './oppaCreateWallet/oppaCreateWalletSuccess';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { profileActions } from '../../../redux/profile/profileSlice';
import { getOppaWalletSelector } from '../../../redux/profile/profileSelectors';
import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import { OppaWalletSettings } from './oppaWalletSettings/oppaWalletSettings';

export const oppaSteps = {
  mainPage: 1,
  addWallet: 2,
  inputWalletDate: 3,
  successCreateWallet: 4,
  walletSettings: 5,
};

export const OPPA = () => {
  const dispatch = useDispatch();
  const oppaWalletNumber = useSelector(getOppaWalletSelector);

  useEffect(() => {
    dispatch(profileActions.getUserInfo());
    dispatch(bonusShopActions.getTransfers());
  }, [dispatch]);

  let steps = [
    { id: oppaSteps.mainPage, Component: () => <OppaMainPage /> },
    { id: oppaSteps.addWallet, Component: () => <OppaStartCreateWallet /> },
    { id: oppaSteps.inputWalletDate, Component: () => <OppaInputDate /> },
    { id: oppaSteps.successCreateWallet, Component: () => <OppaCreateWalletSuccess /> },
    { id: oppaSteps.walletSettings, Component: () => <OppaWalletSettings /> },
  ];

  if (!oppaWalletNumber) {
    steps = steps.filter((step) => step.id !== oppaSteps.mainPage);
  }

  const StepList = createStepListContainer(STEP_LIST_IDS.oppaStepList, steps);

  return (
    <div className="oppa__mainPage">
      <StepList />
    </div>
  );
};

import { useState, useRef, useEffect, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserMultiFormatReader, BarcodeFormat } from '@zxing/browser';
import { DecodeHintType } from '@zxing/library';
import { useHistory } from 'react-router-dom';

import { recognitionActions } from '../../../../redux/recognition';
import { ScreenTemplate } from '../../views';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import {
  ANIMATION_PAGE,
  STEP_START,
  STEP_SCAN_BARCODE,
  STEP_TAKE_PHOTO,
  STEP_END,
  START_COLOR,
  WRONG_COLOR,
  STEPS,
  DEFAULT_NAME_UNKNOWN_DEVICES_IN_BROWSER,
} from '../../constants/general';
import { PageType } from '../../types/general';
import { Routes } from '../../../../const';
import { isMobileSafari } from '../../../../helpers/checkIsSafariBrowser';
import { analyticHelper } from '../../../../helpers/analyticHelper';
import { getUserIdSelector } from '../../../../redux/auth/authSelectors';

import styles from './Screen.module.scss';
import { s3FilePaths } from '../../../../const/appConstants';

const TIMEOUT_BARCODE = 500;
const DISTANCE_FROM_FOCUS_FRAME = 75;
const RATE = 1.3;

type Props = {
  setChangeComponent: React.Dispatch<React.SetStateAction<string>>;
};

const Screen = ({ setChangeComponent }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userId = useSelector(getUserIdSelector);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const fetchBarcodeInfo = (barcode: number, callBack?: (status: boolean) => void) => {
    dispatch(recognitionActions.fetchBarcodeInfo({ barcode, callBack }));
  };

  const uploadUnknownBarcodePhoto = ({
    barcode,
    formData,
    callBack,
  }: {
    barcode: string;
    formData: object;
    callBack?: (status: boolean) => void;
  }) => {
    dispatch(recognitionActions.uploadUnknownBarcodePhoto({ barcode, formData, callBack }));
  };

  const checkForNewDevices = useCallback(async () => {
    const statusPermission = sessionStorage.getItem('deviceIdState');
    const videoDevices = await getVideoDevices();
    const hasNewDevices = videoDevices.some(
      ({ label }) => label === '' || label.includes(DEFAULT_NAME_UNKNOWN_DEVICES_IN_BROWSER),
    );

    switch (true) {
      case !hasNewDevices || statusPermission === PageType.Success:
        setCurrDataScreenState({ step: STEP_SCAN_BARCODE, type: PageType.Success });
        turnOnUserDevices();
        break;
      case statusPermission === PageType.Error:
        analyticHelper.onARPermissionDecline(userId);
        setCurrDataScreenState({ step: STEP_SCAN_BARCODE, type: PageType.Error });
        break;
      default:
        setCurrDataScreenState({ step: STEP_START, type: PageType.Success });
        break;
    }
  }, []);

  useEffect(() => {
    checkForNewDevices();
    videoRef.current = document.querySelector<HTMLVideoElement>('video#stream-block');
    audioRef.current = new Audio('./audio/beep.mp3');
    return () => {
      intervalRef.current && clearInterval(intervalRef.current);

      clearVideoStream();
    };
  }, []);

  const [spinnerStatusState, setSpinnerStatusState] = useState(false);
  const [focusFrameColorState, setFocusFrameColorState] = useState(START_COLOR);
  const [currDataScreenState, setCurrDataScreenState] = useState({ step: STEP_START, type: PageType.Error });
  const [barcodeState, setBarcodeState] = useState<number | null>(null);
  const { step, type } = currDataScreenState;

  const checkOldVersionIphone = () => {
    const permissibleDevicePixelRatio = window.devicePixelRatio <= 2;
    const permissibleAspectRatio = window.screen.height / window.screen.width <= 667 / 375;
    return isMobileSafari() && permissibleDevicePixelRatio && permissibleAspectRatio;
  };

  const getVideoDevices = async () => {
    try {
      return await BrowserMultiFormatReader.listVideoInputDevices();
    } catch (error) {
      dispatch(
        modalActions.openPromptModal({
          title: 'Oops',
          description: `Something went wrong while look for a device`,
          btnText: 'OK',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
            history.push(Routes.MainRoute);
          },
        }),
      );
      return [];
    }
  };

  const getDeviceId = async () => {
    const videoDevices = await getVideoDevices();
    if (videoDevices.length) {
      const lastIndex = videoDevices.length - 1;
      const selectedDeviceId = videoDevices[lastIndex]?.deviceId;
      return selectedDeviceId;
    }
  };

  const clearVideoStream = () => {
    videoRef.current && BrowserMultiFormatReader.cleanVideoSource(videoRef.current);

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getVideoTracks().forEach((x) => x.stop());
      mediaStreamRef.current = null;
    }
  };

  const hasGetUserMedia = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  const startDecodeFromStream = () => {
    intervalRef.current = setInterval(() => {
      if (videoRef.current) {
        const canvas = BrowserMultiFormatReader.createCaptureCanvas(videoRef.current);

        const width = videoRef.current.videoWidth - DISTANCE_FROM_FOCUS_FRAME;
        const height = videoRef.current.videoHeight;

        const newWidth = width * RATE;
        const newHeight = height * RATE;

        canvas
          .getContext('2d')
          ?.drawImage(videoRef.current, DISTANCE_FROM_FOCUS_FRAME, 0, width, height, 0, 0, newWidth, newHeight);

        const hints = new Map();
        const formats = [BarcodeFormat.EAN_13];
        hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
        hints.set(DecodeHintType.TRY_HARDER, true);

        const codeReader = new BrowserMultiFormatReader(hints);

        codeReader
          .decodeFromImageUrl(canvas.toDataURL('image/webp'))
          .then((result) => {
            intervalRef.current && clearInterval(intervalRef.current);

            const barcode = Number(result.getText());
            setSpinnerStatusState(true);

            if (audioRef.current && audioRef.current.readyState >= audioRef.current.HAVE_ENOUGH_DATA) {
              audioRef.current.play().catch(() => {});
            }

            fetchBarcodeInfo(barcode, (status) => {
              setSpinnerStatusState(false);
              analyticHelper.onOpenBarcodePage(userId, barcode);

              if (status) {
                setChangeComponent(ANIMATION_PAGE);
              } else {
                analyticHelper.onTakePackPhotoScreen(userId);
                setBarcodeState(barcode);
                setCurrDataScreenState({ step: STEP_TAKE_PHOTO, type: PageType.Error });
              }
            });
          })
          .catch(() => {
            setFocusFrameColorState(START_COLOR);
          });
      }
    }, TIMEOUT_BARCODE);
  };

  const turnOnUserDevices = async () => {
    try {
      if (hasGetUserMedia()) {
        const selectedDeviceId = await getDeviceId();

        const videoConstraints =
          selectedDeviceId && !checkOldVersionIphone()
            ? { deviceId: { exact: selectedDeviceId } }
            : { facingMode: 'environment' };

        const constraints = {
          video: {
            aspectRatio: 5 / 3,
            frameRate: { min: 1.0, max: 100 },
            resizeMode: 'crop-and-scale',
            ...videoConstraints,
          },
        };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);

        if (videoRef.current && stream) {
          const videoElement = BrowserMultiFormatReader.prepareVideoElement(videoRef.current);

          mediaStreamRef.current = stream;
          videoElement.srcObject = stream;
          startDecodeFromStream();
        }

        sessionStorage.setItem('deviceIdState', PageType.Success);
        setCurrDataScreenState({ step: STEP_SCAN_BARCODE, type: PageType.Success });
      } else {
        dispatch(
          modalActions.openPromptModal({
            title: 'Error',
            description: `Sorry, it seems like your device doesn't support AR technology`,
            btnText: 'OK',
            onButtonClick: () => {
              dispatch(modalActions.closeModal());
              history.push(Routes.MainRoute);
            },
          }),
        );
      }
    } catch (err) {
      sessionStorage.setItem('deviceIdState', PageType.Error);
      setCurrDataScreenState({ step: STEP_SCAN_BARCODE, type: PageType.Error });
    }
  };

  const getCurrStepData = STEPS.find(({ id }) => id === step)?.pageType[type];

  const redirectToTutorialPage = () => {
    window.location.href = getCurrStepData?.button?.path || Routes.MainRoute;
  };

  const takePhoto = async () => {
    if (videoRef.current) {
      const currCanvas = await BrowserMultiFormatReader.createCanvasFromMediaElement(videoRef.current);

      currCanvas.toBlob(function (blob) {
        const formData = new FormData();
        if (blob) {
          formData.append('file', blob, `${uuid()}.png`);
          formData.append('file_path', s3FilePaths.recognition);

          uploadUnknownBarcodePhoto({
            barcode: String(barcodeState),
            formData: formData,
            callBack: (status) => {
              if (status) {
                clearVideoStream();
                setFocusFrameColorState(WRONG_COLOR);
                setCurrDataScreenState({ step: STEP_END, type: PageType.Error });
              }
            },
          });
        }
      }, 'image/png');
    }
  };

  const setCurrDataScreen = () => {
    const { step, type } = currDataScreenState;

    switch (true) {
      case step === STEP_START:
        turnOnUserDevices();
        break;
      case step === STEP_SCAN_BARCODE && type === PageType.Success:
        break;
      case step === STEP_SCAN_BARCODE && type === PageType.Error:
        redirectToTutorialPage();
        break;
      case step === STEP_TAKE_PHOTO && type === PageType.Error:
        takePhoto();
        break;
      case step === STEP_END && type === PageType.Error:
        turnOnUserDevices();
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.wrapper}>
      <ScreenTemplate
        currStepData={getCurrStepData}
        focusFrameColor={focusFrameColorState}
        spinnerStatus={spinnerStatusState}
        setCurrDataScreen={setCurrDataScreen}
      />
    </div>
  );
};

export default Screen;

import { useDispatch, useSelector } from 'react-redux';

import './perxCashoutHistory.scss';
import colors from '../../../../styles/consts.scss';

import { getTransfersSelector } from '../../../../redux/bonusShop/bonusShopSelectors';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { formatDate } from '../../../../helpers/dateFormattingWithLocalization';

import { AcceptedIcon } from '../../../../icons/accepted-icon';
import { FailedIcon } from '../../../../icons/failed-icon';
import { Button } from '../../../../components/common/Button';
import { PerxInvoiceCard } from '../../../../icons/perx-invoice-card';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';

export const PerxCashoutHistory = () => {
  const { formatMessage } = useInternationalization();
  const transfersSelector = useSelector(getTransfersSelector)?.transfers;
  const transfers = transfersSelector;
  const dispatch = useDispatch();

  const getIcon = (status) => {
    switch (status) {
      case 1:
        return <AcceptedIcon className="perx-cashout-history-list-item-info-status-icon" />;
      case 2:
        return <FailedIcon className="perx-cashout-history-list-item-info-status-icon" />;
      default:
        return <AcceptedIcon className="perx-cashout-history-list-item-info-status-icon" />;
    }
  };

  const getColor = (status) => {
    switch (status) {
      case 1:
        return colors.successColor;
      case 2:
        return colors.failedColor;
      default:
        return colors.failedColor;
    }
  };

  const handleGetInvoice = (cardId) => {
    dispatch(bonusShopActions.getPerxCardInvoice({ cardId: cardId }));
  };

  const renderTransfer = (transfer) => {
    return (
      <div key={transfer.id}>
        <div className="perx-cashout-history-list-item">
          <div className="perx-cashout-history-list-item-info">
            <p className="perx-cashout-history-list-item-info-amount">{`€${transfer.currency}`}</p>
            <p className="perx-cashout-history-list-item-info-date">
              {formatDate(new Date(transfer.created_at), 'dd.MM.yy')}
            </p>
            <div className="perx-cashout-history-list-item-info-status" style={{ color: getColor(transfer.status) }}>
              {getIcon(transfer.status)} {formatMessage(`${transfer.status === 1 ? 'Success' : 'Fail'}`)}
            </div>
          </div>
          {transfer.status === 1 && (
            <div className="perx-cashout-history-list-item-link">
              <Button
                withoutTheme
                customClass="perx-cashout-history-list-item-link-button"
                onClick={() => handleGetInvoice(transfer.transaction_id)}
              >
                <PerxInvoiceCard />
                Card
              </Button>
            </div>
          )}
        </div>
        <div className="perx-cashout-history-list-divider" />
      </div>
    );
  };

  return (
    <div
      className="perx-cashout-history"
      style={{
        alignItems: transfers && !transfers.length ? 'center' : 'baseline',
        justifyContent: transfers && !transfers.length ? 'center' : 'end',
      }}
    >
      {transfers && !transfers.length ? (
        <p className="perx-cashout-history-empty">
          {formatMessage('There are no transactions yet. Tap the button below to cashout.')}
        </p>
      ) : (
        <>
          <p className="perx-cashout-history-caption">{formatMessage('Transactions:')}</p>
          <div className="perx-cashout-history-list">
            {transfers && transfers.map((transfer) => renderTransfer(transfer))}
          </div>
        </>
      )}
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  step_number: 0,
  status: null,
  product_id: 0,
  time_left: 0,
  products: [],
};

const dailyRewardsSlice = createSlice({
  name: 'dailyRewards',
  initialState,
  reducers: {
    startDailyRewards(state, { payload }) {},
    setDailyRewardsData(state, { payload }) {
      const { step_number, status, time_left, products } = payload;
      return { ...state, step_number, status, time_left, products };
    },
    setDailyRewardsRolledProduct(state, { payload }) {
      const { product_id } = payload;
      return {
        ...state,
        product_id,
      };
    },
    resultDailyRewards(state, { payload }) {},
    setDailyRewardsResult(state, { payload }) {
      const { product_id } = payload;

      return {
        ...state,
        product_id,
      };
    },
    finalDailyRewards() {},
    finishDailyRewards() {},
  },
});

export const dailyRewardsActions = dailyRewardsSlice.actions;
export const dailyRewardsReducer = dailyRewardsSlice.reducer;

export const NextButton = (props) => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ overflow: 'scroll' }}
    {...props}
  >
    <path
      d="M25.5 0C11.4 0 0 11.4 0 25.5C0 39.6 11.4 51 25.5 51C39.6 51 51 39.6 51 25.5C51 11.4 39.6 0 25.5 0ZM21.7 34.7L20.3 33.3L28.5 25.1L20.3 17.8L21.6 16.3L31.4 25L21.7 34.7Z"
      fill={props.fillcolor}
    />
  </svg>
);

NextButton.defaultProps = {
  fillcolor: 'white',
};

import { useDispatch } from 'react-redux';

import './oppaTutorial.scss';

import { Button } from '../../../../components/common/Button';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { SkipIcon } from '../../../../icons/skip-icon';
import { oppaTutorialSteps } from './oppaTutorial';

export const OppaTutorialStep1 = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const handleNextStep = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.oppaTutorialStepList }));
  };

  const handleSkip = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure you want to finish?',
        btnText: 'Yes, exit',
        onButtonClick: () => {
          localStorage.setItem('oppaCompletedTutorial', true);
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.oppaTutorialStepList,
              stepId: oppaTutorialSteps.stepNull,
            }),
          );
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className="oppa-tutorial__blackout">
      <div className="oppa-tutorial__skip" onClick={() => handleSkip()}>
        {formatMessage('Skip')}
        <SkipIcon className="oppa-tutorial__skip-icon" />
      </div>
      <div className="oppa-tutorial__descriptionBox-bottom">
        <div className="oppa-tutorial__descriptionBox-header">{formatMessage('Redeeming coins to OPPA e-wallet')}</div>
        <div className="oppa-tutorial__descriptionBox-description">
          {formatMessage(
            'You could exchange your coins to the money. Before you start make sure you have enough coins on your balance',
          )}
        </div>
        <div className="oppa-tutorial__descriptionBox-skip">
          {formatMessage('Tap the button below to start tutorial or “skip” to proceed')}
        </div>
      </div>
      <div className="oppa-tutorial__button-box">
        <Button customClass="oppa-tutorial__button" withoutTheme={true} onClick={handleNextStep}>
          {formatMessage('Start tutorial')}
        </Button>
      </div>
    </div>
  );
};

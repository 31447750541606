import { memo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import './tutorialSecondStep.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { getUserInfoSelector } from '../../../redux/profile/profileSelectors';
import { FullArrowIcon } from '../../../icons/full-arrow-icon';

export const TutorialSecondStep = memo(() => {
  const dispatch = useDispatch();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { formatMessage } = useInternationalization();

  useEffect(() => {
    let timer;
    if (isButtonClicked) {
      timer = setTimeout(
        () =>
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.transferTutorialStepList,
              stepId: 2,
            }),
          ),
        150,
      );
    }
    if (timer) {
      return () => clearTimeout(timer);
    }
  }, [isButtonClicked, dispatch]);

  const { phone } = useSelector(getUserInfoSelector);

  const onArrowClick = () => {
    setIsButtonClicked(true);
  };

  const arrowAnimation = useSpring({
    top: isButtonClicked ? '150px' : '85px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      top: isButtonClicked ? '85px' : '150px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const phoneAnimation = useSpring({
    opacity: isButtonClicked ? '0' : '1',
    from: {
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const contentAnimation = useSpring({
    right: isButtonClicked ? '600px' : '0px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      right: isButtonClicked ? '0px' : '-600px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  return (
    <div className="transfer-tutorial-second-step">
      <animated.div style={phoneAnimation} className="transfer-tutorial-second-step__phone-block">
        <p className="transfer-tutorial-second-step__phone-block-caption">{formatMessage('Your phone number:')}</p>
        <p className="transfer-tutorial-second-step__phone-block-phone">{phone}</p>
        <animated.div style={arrowAnimation} className="arrow">
          <FullArrowIcon />
        </animated.div>
      </animated.div>
      <animated.div style={contentAnimation} className="transfer-tutorial-second-step__content">
        <div className="transfer-tutorial-second-step__description">
          <p className="transfer-tutorial-second-step__description-step-number">1/5</p>
          <p className="transfer-tutorial-second-step__description-title">{formatMessage('Your phone number')}</p>
          <p className="transfer-tutorial-second-step__description-text">
            {formatMessage(
              'Here is the phone number you used for registration. It automatically attaches to your profile and will be used for cashout.',
            )}
          </p>
        </div>

        <ArrowButton customClass="transfer-tutorial-second-step__arrow-button" withoutTheme onClick={onArrowClick} />
      </animated.div>
    </div>
  );
});

import { goBack } from 'connected-react-router';
import { format } from 'date-fns';
import { useCallback, useEffect } from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { reduxForm, reset } from 'redux-form';

import { Button } from '../../../components/common/Button';
import { Header } from '../../../components/common/Header';
import { Input } from '../../../components/common/Input';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { Routes } from '../../../const';
import { isValidDate, required } from '../../../helpers/formValidations';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { CameraIcon } from '../../../icons/camera-icon';
import { PASSPORT_PAGES, PASSPORT_PAGE_STATES } from '../../../redux/profile/profileConstants';
import { getPassportPageStateSelector } from '../../../redux/profile/profileSelectors';
import { profileActions } from '../../../redux/profile/profileSlice';

import './passportSettings.scss';

const PASSPORT_PAGE_TEXTS = {
  EMPTY: {
    title: 'Take a photo of your passport to fill in the details:',
    button: 'Take a photo',
    icon: <CameraIcon className="passport-settings__camera-icon" />,
  },
  FILLING: {
    title: 'Check your data:',
    button: 'Correct',
  },
  READ_ONLY: {
    title: 'Your data:',
    button: 'Call',
    info_title: 'Found a mistake?',
    info_description: 'To change your passport data, call the hotline number (working hours from 9:00 to 19:00).',
  },
};

const SUPPORT_NUMBER = '+375296602133';

export const PassportSettingsForm = ({ handleSubmit, history, formObj, invalid }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const pageState = useSelector(getPassportPageStateSelector);

  const disabled = pageState !== PASSPORT_PAGE_STATES.FILLING;
  const validationArray = pageState !== PASSPORT_PAGE_STATES.FILLING ? [] : [required];

  useEffect(() => {
    dispatch(profileActions.getPassportData());
  }, [dispatch]);

  const goBackCallback = useCallback(() => {
    if (pageState === PASSPORT_PAGE_STATES.FILLING) {
      dispatch(profileActions.setCurrentPassportPage({ passportPage: PASSPORT_PAGES.INFO_PAGE }));
      history.replace(Routes.GetPassportPhoto);
      dispatch(reset('passportSettingsForm'));
    } else {
      dispatch(goBack());
    }
  }, [dispatch, pageState, history]);

  if (!pageState) {
    return null;
  }

  const submit = () => {
    if (pageState === PASSPORT_PAGE_STATES.FILLING) {
      dispatch(profileActions.setPassportData({ passportData: formObj.values }));
      dispatch(profileActions.setCurrentPassportPage({ passportPage: PASSPORT_PAGES.REGISTRATION_PAGE }));
      return history.push(Routes.GetPassportPhoto);
    } else if (pageState === PASSPORT_PAGE_STATES.EMPTY) {
      return history.push(Routes.GetPassportPhoto);
    }
  };

  return (
    <div className="passport-settings">
      <ScrollBlock fromTop>
        <Header hasBackButton goBackCallback={goBackCallback}>
          <p className="profile-settings-page__header-text">{formatMessage('Passport data')}</p>
        </Header>
        <form onSubmit={handleSubmit(submit)} className="passport-settings__form">
          <div className="passport-settings__top">
            <div className="passport-settings__title">{formatMessage(PASSPORT_PAGE_TEXTS[pageState].title)}</div>
            <div className="passport-settings__input-container">
              {formObj.values.last_name && <div className="passport-settings__input-title">Фамилия:</div>}
              <Input
                name="last_name"
                placeholder={formatMessage('Surname')}
                customClass="passport-settings__input-form"
                disabled={disabled}
                validate={validationArray}
              />
            </div>
            <div className="passport-settings__input-container">
              {formObj.values.first_name && <div className="passport-settings__input-title">Имя:</div>}
              <Input
                name="first_name"
                placeholder={formatMessage('Name')}
                customClass="passport-settings__input-form"
                disabled={disabled}
                validate={validationArray}
              />
            </div>
            <div className="passport-settings__input-container">
              {formObj.values.middle_name && <div className="passport-settings__input-title">Отчество:</div>}
              <Input
                name="middle_name"
                placeholder={formatMessage('Отчество')}
                customClass="passport-settings__input-form"
                disabled={disabled}
                validate={validationArray}
              />
            </div>
            <div className="passport-settings__input-container">
              {formObj.values.number && <div className="passport-settings__input-title">Серия паспорта:</div>}
              <Input
                name="number"
                placeholder={formatMessage('Серия паспорта')}
                customClass="passport-settings__input-form"
                disabled={disabled}
                validate={validationArray}
              />
            </div>
            <div className="passport-settings__input-container">
              {formObj.values.authority && <div className="passport-settings__input-title">Кем выдан:</div>}
              <Input
                name="authority"
                placeholder={'Кем выдан'}
                customClass="passport-settings__input-form"
                disabled={disabled}
                validate={validationArray}
              />
            </div>
            <div className="passport-settings__input-container">
              {formObj.values.date_of_issue && <div className="passport-settings__input-title">Когда выдан:</div>}
              <Input
                name="date_of_issue"
                type="date"
                placeholder={'Когда выдан'}
                customClass="passport-settings__input-form"
                disabled={disabled}
                validate={[...validationArray, isValidDate]}
              />
            </div>
            {pageState === PASSPORT_PAGE_STATES.READ_ONLY && (
              <div className="passport-settings__input-container">
                <div className="passport-settings__input-title">Адрес прописки:</div>
                <input
                  name="registration"
                  value={`${formObj.values.city},${formObj.values.street},${'д.' + formObj.values.building}${
                    formObj.values.corps ? ',' + formObj.values.corps : ''
                  }${formObj.values.apartment ? ',кв.' + formObj.values.apartment : ''}`}
                  className="passport-settings__input"
                  disabled={disabled}
                />
              </div>
            )}
          </div>
          <div className="passport-settings__bottom">
            {PASSPORT_PAGE_TEXTS[pageState].info_title && (
              <div className="passport-settings__info-title">
                {formatMessage(PASSPORT_PAGE_TEXTS[pageState].info_title)}
              </div>
            )}

            {PASSPORT_PAGE_TEXTS[pageState].info_description && (
              <div className="passport-settings__info-description">
                {formatMessage(PASSPORT_PAGE_TEXTS[pageState].info_description)}
              </div>
            )}

            {pageState === PASSPORT_PAGE_STATES.READ_ONLY ? (
              <a className="passport-settings__button" href={`tel:${SUPPORT_NUMBER}`}>
                {formatMessage(PASSPORT_PAGE_TEXTS[pageState].button)}
              </a>
            ) : (
              <Button className="passport-settings__button" disabled={invalid}>
                {PASSPORT_PAGE_TEXTS[pageState].icon}
                {formatMessage(PASSPORT_PAGE_TEXTS[pageState].button)}
              </Button>
            )}
          </div>
        </form>
      </ScrollBlock>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { form, profile } = state;
  let date_of_issue;
  if (profile.passportPageState === PASSPORT_PAGE_STATES.FILLING) {
    date_of_issue = profile.passportData.date_of_issue;
  } else {
    date_of_issue = profile.passportData.date_of_issue
      ? format(new Date(profile.passportData.date_of_issue), 'yyyy-MM-dd')
      : '';
  }
  return {
    formObj: form.passportSettingsForm || { values: {} },
    initialValues: {
      ...profile.passportData,
      date_of_issue,
    },
  };
};

const form = reduxForm({
  form: 'passportSettingsForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
})(PassportSettingsForm);

export const PassportSettings = withRouter(connect(mapStateToProps, null)(form));

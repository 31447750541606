import { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import {
  selectedProvidersSelectorFactory,
  getCurrentCatergoryType,
  getIsFetching,
} from '../../redux/bonusShop/bonusShopSelectors';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';

import { Header } from '../../components/common/Header';
import { Balance } from '../../components/common/Balance';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { PhysicalProvidersCarousel } from '../../components/providersCarousel/physicalProvidersCarousel';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';
import VouchersCarousel from '../../components/VouchersCarousel/VouchersCarousel';
import { BONUS_SHOP_CATEGORY_TYPES } from '../../redux/bonusShop/bonusShopConstants';

import './bonusShopCategory.scss';

export const BonusShopCategory = ({ match, location }) => {
  const dispatch = useDispatch();

  const selectedCategoryId = match.params.id;

  useEffect(() => {
    dispatch(bonusShopActions.getProviders({ selectedCategoryId }));
  }, [dispatch, selectedCategoryId]);

  const selectCurrentProviders = useMemo(selectedProvidersSelectorFactory, []);
  const isFetching = useSelector(getIsFetching);
  const providers = useSelector((state) => selectCurrentProviders(state, selectedCategoryId));
  const selectedCategoryType = useSelector(getCurrentCatergoryType);

  // TODO Refactor. Possibly remove
  if (selectedCategoryType === BONUS_SHOP_CATEGORY_TYPES.EDENRED_VOUCHER) {
    return <VouchersCarousel selectedCategoryId={selectedCategoryId} />;
  }

  return (
    <div className="bonus-shop-category-page">
      <ScrollBlock fromTop>
        <Header hasBackButton>
          <p className="bonus-shop-category-page__header-text">{providers[0] && providers[0].category_name}</p>
        </Header>

        {isFetching ? (
          <ContentSpinner />
        ) : (
          <>
            <Balance />
            <div
              className="bonus-shop-category-page__content"
              style={{ minHeight: isMobileSafari() ? 'calc(100% - 115px)' : 'calc(100% - 125px)' }}
            >
              {!!providers.length ? (
                <PhysicalProvidersCarousel providers={providers} currentProviderIndex={location.state} />
              ) : (
                <div className="bonus-shop-category-page__content-carousel-place" />
              )}
            </div>
          </>
        )}

        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};

import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import './tutorialThirdStep.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { FullArrowIcon } from '../../../icons/full-arrow-icon';
import { Button } from '../../../components/common/Button';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';

export const TutorialThirdStep = memo(() => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const arrowAnimation = useSpring({
    bottom: '90px',
    opacity: '1',
    from: {
      bottom: '170px',
      opacity: '0',
    },
    config: { duration: 150 },
  });

  const contentAnimation = useSpring({
    right: '0px',
    opacity: '1',
    from: {
      right: '-600px',
      opacity: '0',
    },
    config: { duration: 150 },
  });

  const buttonAnimation = useSpring({
    opacity: '1',
    from: {
      opacity: '0',
    },
    config: { duration: 150 },
  });

  const onButtonClick = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.transferTutorialStepList,
        stepId: 3,
      }),
    );
    dispatch(
      modalActions.openCashoutModal({
        title: 'Select the amount',
        btnText: 'Move forward',
        symbol: '₼',
        moneyLimit: 100,
        rate: 1 / 110,
        step: 550,
        isTutorial: true,
        makeTransfer: (params) => {
          dispatch(bonusShopActions.makeTransfer(params));
          dispatch(
            modalActions.openPromptModal({
              btnText: 'OK transfer',
              title: 'Success',
              description: 'Payment on moderation',
              onButtonClick: () => {
                dispatch(
                  stepListActions.setStep({
                    stepListId: STEP_LIST_IDS.transferTutorialStepList,
                    stepId: 4,
                  }),
                );
                dispatch(modalActions.closeModal());
              },
              onClose: () => {
                dispatch(
                  stepListActions.setStep({
                    stepListId: STEP_LIST_IDS.transferTutorialStepList,
                    stepId: 4,
                  }),
                );
                dispatch(modalActions.closeModal());
              },
            }),
          );
        },
        onClose: () => {
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.transferTutorialStepList,
              stepId: 2,
            }),
          );
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  return (
    <div className="transfer-tutorial-third-step">
      <animated.div style={contentAnimation} className="transfer-tutorial-third-step__content">
        <div className="transfer-tutorial-third-step__description">
          <p className="transfer-tutorial-third-step__description-step-number">2/5</p>
          <p className="transfer-tutorial-third-step__description-title">{formatMessage('Cash out')}</p>
          <p className="transfer-tutorial-third-step__description-text">
            {formatMessage(
              'Tap the button below to start. Choose the amount of money using buttons “+” and “-”. One tap — 100 coins (1 AZN). Press “proceed” then.',
            )}
          </p>
        </div>
      </animated.div>
      <animated.div
        className="transfer-tutorial-third-step__btn-container"
        style={{ ...buttonAnimation, bottom: checkIsIphoneX() ? '85px' : '78px' }}
      >
        <animated.div style={arrowAnimation} className="arrow">
          <FullArrowIcon />
        </animated.div>
        <Button customClass={'transfer-tutorial-third-step__btn-container-button'} onClick={onButtonClick}>
          {formatMessage('Cashout')}
        </Button>
      </animated.div>
    </div>
  );
});

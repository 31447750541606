import { interactiveArticleRequiredSelect } from '../../../../helpers/formValidations';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { Select } from '../../../../components/common/Select';

import styles from '../InteractiveArticlePage.module.scss';

export const SelectTypeQuestion = ({ title, textarea, answers, name, id }) => {
  const { formatMessage } = useInternationalization();

  const groupedAnswersByDropdownIndex = Object.values(
    answers.reduce((answerGroups, answer) => {
      const group = answerGroups[answer.dropdown_index] || [];
      group.push(answer);
      answerGroups[answer.dropdown_index] = group;
      return answerGroups;
    }, {}),
  );

  const titleElement = document.querySelector('#answerElement');
  const replaceString = (string) => {
    return string.replace(/\[(.+?)]/g, `<span class='${styles.plug}'>$1</span>`);
  };

  if (titleElement) {
    titleElement.innerHTML = replaceString(textarea);
  }

  return (
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{formatMessage('interactive_article.select_question.title')}</div>
      <div id="answerElement" className={styles.description}>
        ''
      </div>
      {groupedAnswersByDropdownIndex.map((answers, index) => (
        <div key={index} className={styles.selectContainer}>
          <Select
            customClass={styles.selectInput}
            placeholder={formatMessage('interactive_article.select_question.placeholder') + (index + 1)}
            name={name + index}
            options={answers}
            validate={interactiveArticleRequiredSelect}
            withObjectValueOption
            sectionId={id}
          />
        </div>
      ))}
    </div>
  );
};

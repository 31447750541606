import { all, fork } from 'redux-saga/effects';

import { authSagas } from './auth/authSagas';
import { brandsSagas } from './brands/brandsSagas';
import { bonusShopSagas } from './bonusShop/bonusShopSagas';
import { profileSagas } from './profile/profileSagas';
import { serviceSagas } from './service/serviceSagas';
import { promoCodeSagas } from './promoCode/promoCodeSagas';
import { gameSagas } from './game/gameSagas';
import { settingsSagas } from './settings/settingsSagas';
import { challengeSagas } from './challenge/challengeSagas';
import { inviteSagas } from './invite/inviteSagas';
import { chanceSagas } from './chance/chanceSagas';
import { knowledgeBankSagas } from './knowledgeBank/knowledgeBankSagas';
import { specialQuizSagas } from './specialQuizes/specialQuizesSagas';
import { questionOfTheDaySagas } from './questionOfTheDay/questionOfTheDaySagas';
import { eventModeSagas } from './eventMode/eventModeSagas';
import { soloModeSagas } from './soloMode/soloModeSagas';
import { singleModeSagas } from './singleMode/singleModeSagas';
import { dailyRewardsSagas } from './dailyRewards/dailyRewardsSagas';
import { unexpectedRewardsSagas } from './unexpectedRewards/unexpectedRewardsSagas';
import { mainPageSagas } from './mainPage/mainPageSagas';
import { surveySagas } from './survey/surveySagas';
import { multibrandSagas } from './multibrandQuiz/multibrandQuizSagas';
import { loyaltyProgramSagas } from './loyaltyProgram/loyaltyProgramSagas';
import { verificationSagas } from './verification/verificationSagas';
import { scenarioSagas } from './scenario/scenarioSagas';
import { boostersSagas } from './boosters/boostersSagas';
import { achievementsSagas } from './achievements/achievementsSagas';
import { lepSagas } from './LEP/lepSagas';
import { chatSagas as newChatSagas } from './chat';
import { recognitionSagas } from './recognition';
import { quizRateSagas } from './quizRate/quizRateSagas';
import { birthdaySagas } from './birthday/birthdaySagas';
import { bannersSagas } from './banners/bannersSagas';
import { dailyGamesSagas } from './dailyGames/dailyGamesSagas';
import { quickTipsSagas } from './quickTips/QuickTipsSaga';
import { idCardSagas } from './idCard/idCardSagas';
import { mysteryShopperSagas } from './mysteryShopper/mysteryShopperSagas';
import { moderationSagas } from './moderation/moderationSagas';
import { watchSelfRegistrationSaga } from './selfRegistration/selfRegistrationSagas';
import { watchM10Saga } from './m10Payment/m10Sagas';
import { watchConfirmRewardSagas } from './goalTracker/goalTrackerSagas';

export const rootSaga = function* rootSaga() {
  yield all([
    fork(challengeSagas),
    fork(authSagas),
    fork(brandsSagas),
    fork(bonusShopSagas),
    fork(profileSagas),
    fork(serviceSagas),
    fork(promoCodeSagas),
    fork(gameSagas),
    fork(settingsSagas),
    fork(newChatSagas),
    fork(recognitionSagas),
    fork(inviteSagas),
    fork(knowledgeBankSagas),
    fork(chanceSagas),
    fork(specialQuizSagas),
    fork(questionOfTheDaySagas),
    fork(eventModeSagas),
    fork(soloModeSagas),
    fork(singleModeSagas),
    fork(dailyRewardsSagas),
    fork(unexpectedRewardsSagas),
    fork(mainPageSagas),
    fork(surveySagas),
    fork(multibrandSagas),
    fork(loyaltyProgramSagas),
    fork(verificationSagas),
    fork(scenarioSagas),
    fork(boostersSagas),
    fork(achievementsSagas),
    fork(lepSagas),
    fork(quizRateSagas),
    fork(birthdaySagas),
    fork(bannersSagas),
    fork(dailyGamesSagas),
    fork(quickTipsSagas),
    fork(idCardSagas),
    fork(mysteryShopperSagas),
    fork(moderationSagas),
    fork(watchSelfRegistrationSaga),
    fork(watchM10Saga),
    fork(watchConfirmRewardSagas),
  ]);
};

import { all } from '@redux-saga/core/effects';
import { push } from 'react-router-redux';
import { put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { bannersApi } from '../../api/bannersApi';
import { bannersActions } from './bannersSlice';
import { IBanner } from './bannersTypes';
import { Routes } from '../../const';

function* getBannersForCategory(action: PayloadAction<{ categoryId: number }>) {
  try {
    const { data } = yield bannersApi.getBannersForCategory(action.payload.categoryId);
    yield put(bannersActions.setBanners(data));
  } catch (err) {
    console.error('getBannersForCategory error', err);
  }
}

function* openBannerPage(action: PayloadAction<{ banner: IBanner }>) {
  try {
    yield put(bannersActions.setCurrentBanner(action.payload.banner));
    yield put(push(Routes.BannerRoute));
  } catch (err) {
    console.error('openBannerPage error', err);
  }
}

export function* bannersSagas() {
  yield all([takeLatest(bannersActions.getBannersForCategory.type, getBannersForCategory)]);
  yield all([takeLatest(bannersActions.openBannerPage.type, openBannerPage)]);
}

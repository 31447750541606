import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getSelectedBrandSelector } from '../../../redux/knowledgeBank/knowledgeBankSelectors';

import { RootState } from '../../../redux/store';
import { Routes } from '../../../const';
import { buttonColorType, IBrand, SliderImages } from '../KBBrandsTypes';
import { Header } from '../../../components/common/Header';
import { BoxRightArrow } from '../../../icons';

import styles from './BrandInformationSlider.module.scss';

const BrandInformationSlider = () => {
  const history = useHistory();
  const { formatMessage } = useInternationalization();
  const selectedBrand = useSelector<RootState, IBrand>(getSelectedBrandSelector);
  const { id } = useParams<{ id?: string }>();

  let buttonColor;

  const goBack = () => {
    history.push(`${Routes.KnowledgeBankRoute}/${id}/brands`);
  };

  const onButtonClick = () => {
    history.push(`${Routes.KnowledgeBankRoute}/${id}/brands/${selectedBrand.id}`);
  };

  const buttonColorMap: buttonColorType = {
    1: '#004494',
    2: '#FFEB00',
    3: '#CD071E',
    4: '#01C1B6',
    5: '#C6C6C6',
  };

  if (selectedBrand && !selectedBrand.is_jti) {
    buttonColor = buttonColorMap[selectedBrand.id];
  } else {
    buttonColor = buttonColorMap[4];
  }

  return (
    <div className={styles.container}>
      <Header goBackCallback={goBack} customClass={styles.header} hasBackButton buttonColor={'black'}>
        <p className={styles.text}>{formatMessage('Brand information')}</p>
      </Header>
      <div className={styles.sliderWrapper}>
        <Swiper slidesPerView={1} spaceBetween={15}>
          {selectedBrand?.slider_images?.map((image: SliderImages) => (
            <SwiperSlide>
              <img key={image.image} className={styles.swiperImage} src={image.image} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.buttonWrapper}>
        <button style={{ backgroundColor: buttonColor }} className={styles.button} onClick={onButtonClick}>
          {formatMessage('Go to product')}
        </button>
        <BoxRightArrow className={styles.arrow} fillColor={'white'} />
      </div>
    </div>
  );
};

export default BrandInformationSlider;

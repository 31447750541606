import { useEffect } from 'react';
import styles from './Snowflakes.module.scss';

const Snowfall = () => {
  useEffect(() => {
    const snowflakesCount = 200;
    let bodyHeightPx = 0;
    let pageHeightVh = 0;

    const setHeightVariables = () => {
      bodyHeightPx = document.body.offsetHeight;
      pageHeightVh = (100 * bodyHeightPx) / window.innerHeight;
    };

    const generateSnow = (snowDensity: number = 200) => {
      snowDensity -= 1;
      const snowWrapper = document.getElementById(styles.snow) as HTMLElement;

      if (!snowWrapper) return;

      snowWrapper.innerHTML = '';
      for (let i = 0; i < snowDensity; i++) {
        let board = document.createElement('div');
        board.className = styles.snowflake;
        snowWrapper.appendChild(board);
      }
    };

    const getOrCreateCSSElement = () => {
      let cssElement = document.getElementById('psjs-css');
      if (cssElement) return cssElement;

      cssElement = document.createElement('style');
      cssElement.id = 'psjs-css';
      document.head.appendChild(cssElement);
      return cssElement;
    };

    const addCSS = (rule: string) => {
      const cssElement = getOrCreateCSSElement();
      cssElement.innerHTML = rule;
      document.head.appendChild(cssElement);
    };

    const randomInt = (value: number = 100) => {
      return Math.floor(Math.random() * value) + 1;
    };

    const randomIntRange = (min: number, max: number) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const getRandomArbitrary = (min: number, max: number) => {
      return Math.random() * (max - min) + min;
    };

    const generateSnowCSS = (snowDensity: number = 200) => {
      const snowflakeName = styles.snowflake;
      let rule = '';

      for (let i = 1; i < snowDensity; i++) {
        const randomX = Math.random() * 100;
        const randomOffset = Math.random() * 10;
        const randomXEnd = randomX + randomOffset;
        const randomXEndYoyo = randomX + randomOffset / 2;
        const randomYoyoTime = getRandomArbitrary(0.3, 0.8);
        const randomYoyoY = randomYoyoTime * pageHeightVh;
        const randomScale = Math.random();
        const fallDuration = randomIntRange(10, (pageHeightVh / 10) * 3);
        const fallDelay = randomInt((pageHeightVh / 10) * 3) * -1;
        const opacity = Math.random();

        rule += `
      .${snowflakeName}:nth-child(${i}) {
        opacity: ${opacity};
        transform: translate(${randomX}vw, -10px) scale(${randomScale});
        animation: fall-${i} ${fallDuration}s ${fallDelay}s linear infinite;
      }
      @keyframes fall-${i} {
        ${randomYoyoTime * 100}% {
          transform: translate(${randomXEnd}vw, ${randomYoyoY}vh) scale(${randomScale});
        }
        to {
          transform: translate(${randomXEndYoyo}vw, ${pageHeightVh}vh) scale(${randomScale});
        }
      }
    `;
      }
      addCSS(rule);
    };

    const createSnow = () => {
      setHeightVariables();
      generateSnowCSS(snowflakesCount);
      generateSnow(snowflakesCount);
    };
    createSnow();
    window.addEventListener('resize', createSnow);
  }, []);

  return <div id={styles.snow} />;
};

export default Snowfall;

export const CameraIcon = (props) => {
  return (
    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.7898 2.16667H12.6C13.3721 2.16667 14 2.8145 14 3.61111V11.5556C14 12.3522 13.3721 13 12.6 13H1.4C0.6279 13 0 12.3522 0 11.5556V3.61111C0 2.8145 0.6279 2.16667 1.4 2.16667H3.2102L5.1051 0.211613C5.16999 0.144415 5.24713 0.091124 5.33206 0.0548069C5.417 0.0184897 5.50806 -0.000136076 5.6 7.48383e-07H8.4C8.49194 -0.000136076 8.583 0.0184897 8.66794 0.0548069C8.75287 0.091124 8.83001 0.144415 8.8949 0.211613L10.7898 2.16667ZM7 11C9.20914 11 11 9.20914 11 7C11 4.79086 9.20914 3 7 3C4.79086 3 3 4.79086 3 7C3 9.20914 4.79086 11 7 11Z"
        fill="#01C1B6"
      />
      <path
        d="M7.66667 5.2C7.66667 5.08954 7.57712 5 7.46667 5H6.53333C6.42288 5 6.33333 5.08954 6.33333 5.2V6.13333C6.33333 6.24379 6.24379 6.33333 6.13333 6.33333H5.2C5.08954 6.33333 5 6.42288 5 6.53333V7.46667C5 7.57712 5.08954 7.66667 5.2 7.66667H6.13333C6.24379 7.66667 6.33333 7.75621 6.33333 7.86667V8.8C6.33333 8.91046 6.42288 9 6.53333 9H7.46667C7.57712 9 7.66667 8.91046 7.66667 8.8V7.86667C7.66667 7.75621 7.75621 7.66667 7.86667 7.66667H8.8C8.91046 7.66667 9 7.57712 9 7.46667V6.53333C9 6.42288 8.91046 6.33333 8.8 6.33333H7.86667C7.75621 6.33333 7.66667 6.24379 7.66667 6.13333V5.2Z"
        fill="#01C1B6"
      />
    </svg>
  );
};

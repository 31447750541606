import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { profileActions } from '../../../redux/profile/profileSlice';
import { serviceActions } from '../../../redux/service/serviceSlice';
import { getUserInfoSelector } from '../../../redux/profile/profileSelectors';
import { s3FilePaths } from '../../../const/appConstants';
import { AvatarIcon } from '../../../icons';

export const ProfilePhoto = () => {
  const dispatch = useDispatch();
  const [photo, setPhoto] = useState('');
  const [isPhotoLoading, setIsPhotoLoading] = useState(true);
  const filesInputRef = useRef<HTMLInputElement>(null);
  const userInfo = useSelector(getUserInfoSelector);

  const handleLoadPhoto = () => {
    const fileInput = document.getElementById('file');
    fileInput?.click();
  };

  const handleSetNewPhoto = async () => {
    const uploadPhotoCallback = (data: string) => {
      dispatch(
        profileActions.changeUserInfo({
          userInfo: { photo: data },
        }),
      );
    };
    const files = filesInputRef?.current?.files;
    const file = files && files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('file_path', s3FilePaths.profileImage);
    dispatch(serviceActions.uploadPhoto({ formData, uploadPhotoCallback }));
  };

  useEffect(() => {
    if (userInfo.id && userInfo.photo) {
      setIsPhotoLoading(false);
      setPhoto(userInfo.photo);
    } else if (userInfo.id && !userInfo.photo) {
      setIsPhotoLoading(false);
      setPhoto('');
    }
  }, [userInfo]);

  return (
    <>
      <input type="file" hidden={true} id="file" ref={filesInputRef} onChange={handleSetNewPhoto} accept="image/*" />
      {photo && !isPhotoLoading ? (
        <div
          className={classnames('profile-page__content-info-block-photo', 'with-user-photo')}
          style={{
            backgroundImage: `url(${photo})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% 50%',
          }}
          onClick={handleLoadPhoto}
        />
      ) : (
        <div
          className="profile-page__content-info-block-photo"
          onClick={handleLoadPhoto}
          style={{ background: isPhotoLoading ? 'white' : '' }}
        >
          {isPhotoLoading ? null : <AvatarIcon />}
        </div>
      )}
    </>
  );
};

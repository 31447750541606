import React from 'react';
import classnames from 'classnames';

import styles from './LinearProgresssBar.module.scss';
import { useInternationalization } from '../../hooks/useTranslationHook';

type Props = {
  allTasksCount: number;
  completedTasksCount: number;
  className?: string;
};

export const LinearProgressBar = ({ allTasksCount = 1, completedTasksCount, className }: Props) => {
  const { formatMessage } = useInternationalization();
  const containerClasses = classnames(styles.container, className);

  const getProgressBarCompletedWidth = () => {
    return (completedTasksCount / allTasksCount) * 100;
  };

  return (
    <div className={containerClasses}>
      <div className={styles.taskCounter}>
        {completedTasksCount}/{allTasksCount}
        {formatMessage(` completed`)}
      </div>
      <div className={styles.progressBar}>
        <div className={styles.background} />
        <div className={styles.progress} style={{ width: `${getProgressBarCompletedWidth()}%` }} />
      </div>
    </div>
  );
};

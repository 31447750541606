import { PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router';
import classnames from 'classnames';

import { Header, HeaderProps } from '../Header';
import { NavigationMenu } from '../../navigationMenu';
import { DisclaimerFooter } from '../../disclaimerFooter/disclaimerFooter';

import styles from './PageContainer.module.scss';

interface IPageContainer {
  headerProps?: HeaderProps;
  location?: RouteComponentProps;
  showDisclaimer?: boolean;
  showFooter?: boolean;
  customClass?: string;
}

const PageContainer = ({
  children,
  location,
  headerProps,
  showDisclaimer = true,
  showFooter = true,
  customClass,
}: PropsWithChildren<IPageContainer>) => (
  <div className={styles.pageContainer}>
    {headerProps && (
      <div className={styles.contentHeader}>
        <Header {...headerProps} />
      </div>
    )}
    <main className={classnames(styles.mainContent, customClass)}>{children}</main>
    {showFooter && (
      <footer className={styles.contentFooter}>
        <NavigationMenu location={location} />
      </footer>
    )}
    {showDisclaimer && <DisclaimerFooter />}
  </div>
);

export default PageContainer;

import React from 'react';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { WarningIcon } from '../../../icons/warning-icon';

import styles from './ErrorText.module.scss';

type Props = {
  text: string;
  iconWidth?: string;
  iconHeight?: string;
};
export const ErrorText = ({ text, iconWidth = '16', iconHeight = '16' }: Props) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className={styles.container}>
      <WarningIcon fillcolor="white" width={iconWidth} height={iconHeight} />
      <span className={styles.text}>{formatMessage(text)}</span>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';
const initialState = {};

const promoCodeSlice = createSlice({
  name: 'promoCode',
  initialState,
  reducers: {
    postPromoCode(state, { code, onSuccess = () => {}, onError }) {},
  },
});
export const promoCodeActions = promoCodeSlice.actions;

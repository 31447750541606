export const ChatIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_122_1139)">
        <rect width="40" height="40" rx="8" fill="#ECECEC" />
        <path
          d="M31.5 13.3274C31.5 12.0473 30.465 11 29.2 11H10.8C9.535 11 8.5 12.0473 8.5 13.3274V27.2917C8.5 28.5717 9.535 29.619 10.8 29.619H29.2C30.465 29.619 31.5 28.5717 31.5 27.2917V13.3274ZM29.2 13.3274L20 19.1458L10.8 13.3274H29.2ZM29.2 27.2917H10.8V15.6548L20 21.4732L29.2 15.6548V27.2917Z"
          fill="#252525"
        />
      </g>
      <defs>
        <clipPath id="clip0_122_1139">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

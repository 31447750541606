import { APP_TYPES } from '../../const/appConstants';
import { ProfileSettingsIreland } from './profileSettingsIreland';
import { ProfileSettingsHungary } from './profileSettingsHungary';
import { ProfileSettingsCaucasus } from './profileSettingsCaucasus';
import { ProfileSettingsBelarus } from './profileSettingsBelarus';

const getProfileSettingsPage = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      return ProfileSettingsIreland;
    case APP_TYPES.HUNGARY:
      return ProfileSettingsHungary;
    case APP_TYPES.BELARUS:
      return ProfileSettingsBelarus;
    case APP_TYPES.ARMENIA:
    case APP_TYPES.AZERBAIJAN:
    case APP_TYPES.GEORGIA:
      return ProfileSettingsCaucasus;
    default:
      return ProfileSettingsIreland;
  }
};

export const ProfileSettings = getProfileSettingsPage();

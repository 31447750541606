import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/common/Button';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { SkipIcon } from '../../../icons/skip-icon';

export const AchievementsTutorialStart = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const handleSetStepTutorial = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.achievementsTutorialStepList }));
    analyticHelper.achievementTutorialStart();
  };

  const handleSkip = () => {
    dispatch(modalActions.openAchievementTutorialModal());
    analyticHelper.achievementTutorialSkip();
  };

  useEffect(() => {
    let lockScroll = document.getElementById('scrollable');
    if (lockScroll) {
      lockScroll.style.overflow = 'hidden';
    }
  }, []);

  return (
    <>
      <div className="achievements-tutorial__full-blackout" />
      <div className="achievements-tutorial__skip" onClick={() => handleSkip()}>
        {formatMessage('Skip')}
        <SkipIcon className="achievements-tutorial__skip-icon" />
      </div>
      <div className="achievements-tutorial__descriptionBox-bottom">
        <div className="achievements-tutorial__descriptionBox-header">{formatMessage("Let's get started!")}</div>
        <div className="achievements-tutorial__descriptionBox-description">
          {formatMessage('Here you can see a brief tutorial on how you could collect achievements step by step')}
        </div>
        <div className="achievements-tutorial__descriptionBox-skip">
          {formatMessage('Tap the button below to start tutorial or “skip” to proceed')}
        </div>
      </div>
      <div className="achievements-tutorial__button-box">
        <Button customClass="achievements-tutorial__button" withoutTheme={true} onClick={() => handleSetStepTutorial()}>
          {formatMessage('Start tutorial')}
        </Button>
      </div>
    </>
  );
};

import { createSelector } from 'reselect';

export const getCategoriesSelector = createSelector(
  [(state) => state.knowledgeBank.categories],
  (categories) => categories,
);

export const getSalesTipsSelector = createSelector(
  [(state) => state.knowledgeBank.salesTips],
  (salesTips) => salesTips || [],
);

export const getSaleTipByIdSelector = createSelector(
  [getSalesTipsSelector, (state, tip_id) => tip_id],
  (salesTips, tip_id) => {
    const tip = salesTips.find((i) => i.id === Number(tip_id));

    return tip;
  },
);

export const getSalesSimulatorsSelector = createSelector(
  [(state) => state.knowledgeBank.salesSimulators],
  (salesSimulators) => salesSimulators || [],
);

export const getSelectedSimulatorScenariosSelector = createSelector(
  [(state) => state.knowledgeBank.selectedSimulatorScenarios],
  (selectedSimulatorScenarios) => selectedSimulatorScenarios || [],
);

export const getSelectedSimulatorByIdSelector = createSelector(
  [getSalesSimulatorsSelector, (state, simulator_id) => simulator_id],
  (salesSimulators, simulator_id) => {
    const simulator = salesSimulators.find((i) => i.id === Number(simulator_id));

    return simulator;
  },
);

export const getSelectedTipStepsStepsSelector = createSelector(
  [(state) => state.knowledgeBank.selectedTipSteps],
  (tipSteps) => tipSteps || [],
);

export const getKBBrandsSelector = createSelector([(state) => state.knowledgeBank.brands], (brands) => brands);

export const getSelectedBrandSelector = createSelector(
  [(state) => state.knowledgeBank.selectedBrand],
  (brand) => brand,
);

export const getUnreadTopicsSelector = createSelector(
  [(state) => state.knowledgeBank.allUnread],
  (allUnread) => allUnread,
);

export const getArticlesSelector = createSelector([(state) => state.knowledgeBank.articles], (articles) => articles);

export const getSelectedArticleSelector = createSelector(
  [(state) => state.knowledgeBank.selectedArticle],
  (selectedArticle) => selectedArticle,
);

export const getAttachedInteractiveArticleSelector = createSelector(
  [(state) => state.knowledgeBank.interactiveArticle],
  (interactiveArticle) => interactiveArticle,
);

export const getCurrentCategorySelector = createSelector(
  [getCategoriesSelector, (state, category_id) => category_id],
  (categories, category_id) => {
    return categories.find((category) => category.id === Number(category_id));
  },
);

export const getCurrentArticleSelector = createSelector([getArticlesSelector, (state, id) => id], (articles, id) => {
  return articles.find((article) => article.id === Number(id));
});

export const getCurrentGameProgressSelector = createSelector(
  [(state) => state.knowledgeBank.gameProgress],
  (gameProgress) => gameProgress,
);

export const getIsFetching = createSelector([(state) => state.knowledgeBank.isFetching], (isFetching) => isFetching);

export const getCoinsForInteractiveArticle = createSelector(
  [(state) => state.knowledgeBank.coinsForInteractiveArticle],
  (coinsForInteractiveArticle) => coinsForInteractiveArticle,
);

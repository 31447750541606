import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

import { BackArrowButton } from '../../common/BackArrowButton/BackArrowButton';
import { Routes } from '../../../const';

import styles from './ProductPhoto.module.scss';

type Props = {
  location: { photo: string; selectedProviderIndex: number; selectedCategoryId: string };
};

export const ProductImage = ({ location }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onBackButtonClick = () => {
    dispatch(goBack());
  };

  useEffect(() => {
    return () => {
      history.replace(`${Routes.BonusShopRoute}/${location.selectedCategoryId}`, location.selectedProviderIndex);
    };
  });

  return (
    <div className={styles.container}>
      <BackArrowButton
        customClass={styles.backButton}
        withoutTheme
        onHistoryReplaceCallback={onBackButtonClick}
        buttonColor="white"
      />
      <img className={styles.productImage} src={location.photo} alt="Product image" />
    </div>
  );
};

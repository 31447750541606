import { API_URL } from './baseApi';
import axios from 'axios';

export const recognitionApi = {
  postUnknownBarcode: async ({ barcode, photo }: { barcode: string; photo: string }) => {
    return await axios.post(`${API_URL}/v3/pack-recognition`, {
      barcode,
      photo,
    });
  },
  getBarcodeInfo: async (barcode?: number) => {
    return await axios.get(`${API_URL}/v3/pack-recognition`, {
      params: {
        barcode,
      },
    });
  },
};

import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { reduxForm } from 'redux-form';
import { InjectedFormProps } from 'redux-form/lib/reduxForm';
import isEmpty from 'is-empty-typed';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { isIPhoneWithHomeBar } from '../../../../helpers/checkIsIphoneX';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { required } from '../../../../helpers/formValidations';
import { getIsFetching, getUserAccountsSelector } from '../../../../redux/bonusShop/bonusShopSelectors';

import { Routes } from '../../../../const';
import { Button } from '../../../../components/common/Button';
import { BackButton } from '../../../../components/common';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { RadioButtonInput } from '../../../../components/common/CommonRadioButton';
import unicardLogo from '../../../../images/unicard-logo.png';
import { ContentSpinner } from '../../../../components/common/Spinner/ContentSpinner';

import styles from './SettingsPage.module.scss';

export const SettingsPage = ({ handleSubmit, reset }: InjectedFormProps<any>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useInternationalization();
  const isFetching = useSelector(getIsFetching);
  const userAccounts = useSelector(getUserAccountsSelector);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const onBack = () => {
    history.push(Routes.Unicard);
  };

  const onSubmit = ({ cardNumber }: { cardNumber: { answer: string } }) => {
    dispatch(bonusShopActions.setUnicardWallet({ wallet_number: cardNumber.answer, unicardLogo }));
  };

  return (
    <div className={styles.chooseCardNumberPage}>
      <BackButton className={styles.backButton} onClick={onBack} />
      {isFetching || isEmpty(userAccounts) ? (
        <ContentSpinner />
      ) : (
        <div className={styles.content}>
          <div className={styles.textContainer}>
            <p className={styles.title}>{formatMessage('Select your UNIWallet')}</p>
            <p className={styles.description}>{formatMessage('You can choose only one wallet')}</p>
          </div>
          <form
            className={styles.form}
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingBottom: isIPhoneWithHomeBar() ? '49px' : '15px' }}
          >
            <RadioButtonInput
              name={'cardNumber'}
              options={userAccounts}
              validate={required}
              customClass={styles.radioButton}
            />

            <Button customClass={styles.button}>{formatMessage('Transfer')}</Button>
          </form>
        </div>
      )}
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

const SettingsForm = reduxForm({ form: 'unicard', destroyOnUnmount: true })(SettingsPage);

export const Settings = connect((state: any) => ({
  initialValues: { cardNumber: { answer: state.bonusShop.unicardAccount } },
}))(SettingsForm);

import React from 'react';

import checkInLogo from './images/Rectangle.png';

import styles from './PlugCheckIn.module.scss';

const PlugCheckIn = () => {
  return (
    <div className={styles.container}>
      <img className={styles.image} src={checkInLogo} alt="log" />
      <span className={styles.title}>Чек-ин приостановлен</span>
      <span className={styles.description}>
        Проходить чек-ин на данный момент не нужно. Мы сообщим вам, когда он снова будет запущен, вы можете продолжать
        пользоваться приложением без ограничений
      </span>
    </div>
  );
};

export default PlugCheckIn;

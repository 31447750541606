import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { chanceActions } from '../../../redux/chance/chanceSlice';
import { selectChances, selectIsFetching } from '../../../redux/chance/chanceSelectors';
import { Routes } from '../../../const';
import { BoxRightArrow } from '../../../icons';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { Header } from '../../../components/common/Header';
import { LoaderContainer } from '../../../components/common';
import { NavigationMenu } from '../../../components';

import styles from './ChanceList.module.scss';

interface IChance {
  id: number;
  background_image: string;
  name: string;
  cost: number;
}

export const ChanceList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { formatMessage } = useInternationalization();
  const isFetching = useSelector(selectIsFetching);

  const chances = useSelector(selectChances);

  useEffect(() => {
    dispatch(chanceActions.getChance({}));
  }, []);

  const onCardClick = (id: number) => {
    history.push(`${Routes.ChanceRoute}/${id}`);
  };

  const onBackArrowClick = () => {
    history.goBack();
  };

  return (
    <LoaderContainer isLoading={isFetching}>
      <div className={styles.container}>
        <BackArrowButton
          withoutTheme
          onHistoryReplaceCallback={onBackArrowClick}
          customClass={styles.backButton}
          buttonColor="white"
        />
        <Header customClass={styles.header}>
          <p className={styles.headerText}>{formatMessage('Active chance')}</p>
        </Header>
        {chances?.map(({ id, background_image, name, cost }: IChance) => (
          <div
            key={id}
            className={styles.cardWrapper}
            onClick={() => onCardClick(id)}
            style={{ backgroundImage: `url(${background_image})` }}
          >
            <span className={styles.title}>{name}</span>
            <span className={styles.participation}>{formatMessage('Participation: { cost } points', { cost })}</span>
            <BoxRightArrow className={styles.arrow} fillColor="white" />
          </div>
        ))}
        <NavigationMenu location={location} />
      </div>
    </LoaderContainer>
  );
};

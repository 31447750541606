import { createSelector } from 'reselect';

export const getDailyRewardsData = createSelector(
  [state => state.dailyRewards],
  dailyRewards => dailyRewards,
);

export const getStatusDailyRewards = createSelector(
  [state => state.dailyRewards.status],
  status => status,
);

export const getStepDailyRewards = createSelector(
  [state => state.dailyRewards.step_number],
  step => step,
);

export const getDailyRewardsProducts = createSelector(
  [state => state.dailyRewards.products],
  products => products,
);

export const getDailyRewardsWithdrawProduct = createSelector(
  [state => state.dailyRewards.product_id],
  id => id,
);

import { IDailyGame } from '../dailyGames/dailyGamesTypes';

export enum MessageType {
  Chat = 4,
  KnowledgeBank = 11,
  FirstLevel = 15,
  SecondLevel = 16,
  ThirdLevel = 17,
  Confirmation = 18,
  Notification = 19,
  AskForDetails = 21,
  CustomProblem = 24,
  SolveMyProblem = 25,
}

export enum MessagesStatus {
  Unread,
  Read,
}

export enum ChatTab {
  Notifications = 'Notifications',
  JTI = 'JTI',
  Support = 'Support',
}

export enum ChatRole {
  Administrator = 'Administrator',
  BA = 'BA',
}

export enum TicketStatus {
  Checking,
  Completed,
  NoAnswer,
}

export interface ChatMessage {
  id: number;
  type: number;
  ticketId?: number;
  caption?: string;
  link?: string;
  image?: string;
  text: string;
  createdAt: string;
  code?: string;
  brand_id?: number; // TODO: toCamelCase when legacy slice is gone
  article?: RawChatArticle; // TODO: replace with normalized interface when the legacy chat and article page are gone
  isAdmin: boolean;
  status: MessagesStatus;
  categoryId?: number;
  brandId?: number;
  dailyGame?: IDailyGame;
  buttonText?: string;
  taskId?: number;
  challengeId?: number;
}

export interface Category {
  id: number;
  name: string;
  type: number;
  icon: string;
}

export interface User {
  id: number;
  name: string;
  surname: string;
  phone: string;
}

export interface Problem {
  id: number;
  image?: string;
  level: number;
  name: string;
  shouldStartChat: boolean;
  parentId?: number;
  url?: string;
}

export interface Ticket {
  id: number;
  name: string;
  problemId?: number;
  status: TicketStatus;
  createdAt: string;
  lastMessageDate: string;
  user: User;
  hasNewMessages: boolean;
}

export interface RawTicket {
  id: number;
  problem: Problem;
  status: TicketStatus;
  created_at: string;
  last_message_date: string;
  other: number;
  user: User;
  ticket_step: number;
  has_new_messages: boolean;
}

export interface RawChatMessage {
  id: number;
  chat_id: string;
  ticket_id?: number;
  user_id: number;
  type: number;
  created_at: string;
  message: {
    caption?: string;
    text: string;
    image?: string;
    code?: string;
    link?: string;
    bottom_text?: string;
    brand_id?: number;
    admin?: number;
    article?: RawChatArticle;
    category?: Category;
    daily_game?: IDailyGame;
    button_text?: string;
    task_id?: number;
    challenge_id?: number;
  };
  status: number;
}

export interface RawChatArticle {
  id: number;
  name: string;
  url: string;
  minutes_to_read: number;
  special_quiz_id?: number;
  is_multibrand_quiz: boolean;
  created_at: string;
  published_at: string;
  retail_price: number;
  brand_id?: number;
  survey_id: number;
  brand: {
    id: number;
    name: string;
  };
  is_new: boolean;
  game_progress: string;
  status: number;
}

export interface RawProblem {
  id: number;
  image?: string;
  level: number;
  name: string;
  parent_id?: number;
  should_start_chat: boolean;
  url?: string;
}

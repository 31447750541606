import classnames from 'classnames';

import { ButtonViewProps } from '../../types';
import styles from './ChatTab.module.scss';

export const ChatTab = ({ isActive, isDisabled, children }: ButtonViewProps) => (
  <div
    className={classnames(styles.chatTab, {
      [styles.disabled]: isDisabled,
      [styles.active]: isActive,
      themedButton: isActive,
    })}
  >
    {children}
  </div>
);

import { createSelector } from 'reselect';

export const getQuestionsSelector = createSelector(
  [state => state.specialQuizes.questions],
  questions => questions,
);

export const getSpecialQuizModeSelector = createSelector(
  [state => state.specialQuizes.specQuizMode],
  specQuizMode => specQuizMode,
);

export const getSpecialQuizResultSelector = createSelector(
  [state => state.specialQuizes.specialQuizResults],
  specialQuizResults => specialQuizResults,
);

export const getAnswersSelector = createSelector(
  [state => state.specialQuizes.answers],
  answers => answers,
);

export const getPackIdSelector = createSelector(
  [state => state.specialQuizes.pack_id],
  pack_id => pack_id,
);

export const getSeasonQuizData = createSelector(
  [state => state.specialQuizes.seasonQuizData],
  seasonQuizData => seasonQuizData,
);

export const getSeasonQuizGameSelector = createSelector(
  [state => state.specialQuizes.seasonQuizGame],
  seasonQuizGame => seasonQuizGame,
);

export const getQuizStatusSelector = createSelector(
  [state => state.specialQuizes.is_new],
  is_new => is_new,
);

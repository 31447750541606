import './KBBrands.scss';

export const KBBrands = ({ brands, selectKBBrand }) => {
  return (
    <div className="KB-choose-brand__brands">
      {brands &&
        brands.length &&
        brands.map((brand) => (
          <div key={brand.id} className="KB-choose-brand__brand-container">
            <div className="KB-choose-brand__brands-brand" onClick={() => selectKBBrand(brand.id)}>
              <div
                className="KB-choose-brand__brands-brand-icon"
                style={{
                  background: `url(${brand.icon})`,
                  backgroundSize: 'contain',
                }}
              />
            </div>
            <div className="KB-choose-brand__brands-brand-name">{brand.name}</div>
          </div>
        ))}
    </div>
  );
};

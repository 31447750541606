import { useDispatch } from 'react-redux';

import './successPage.scss';
import Success from '../../../../images/cashout-success.png';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { Button } from '../../../../components/common/Button';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { perxSteps } from '../perxTrensferMoneyPage';

export const PerxSuccessPage = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const handleOnClickButton = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.perxStepList,
        stepId: perxSteps.transfer,
      }),
    );
  };

  return (
    <div className="success-page">
      <div className="success-page__content">
        <div className="success-page__content-image-container">
          <img className="success-page__content-image-container-image" src={Success} alt="card" />
        </div>
        <div className="success-page__content-title-container">
          <h1 className="success-page__content-title-container-label">{formatMessage('Success')}</h1>
          <h1 className="success-page__content-title-container-title">
            {formatMessage('Your transaction is completed')}
          </h1>
        </div>
        <div className={'success-page__content-button-container'}>
          <Button customClass={'success-page__content-button-container-btn'} onClick={handleOnClickButton}>
            {formatMessage('Ok')}
          </Button>
        </div>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

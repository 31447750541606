import { RootState } from '../../store';
import { groupBy, formatDate } from '../../../helpers';

import { messageAdaptor } from './notificationsSlice';
import { descDateComparer } from '../chatServices';

const messageSelectors = messageAdaptor.getSelectors((state: RootState) => state.chat.notifications.messages);

const selectHasMore = (state: RootState) => state.chat.notifications.hasMore;
const selectIsFetching = (state: RootState) => state.chat.notifications.isFetching;
const selectMessagesByDate = (state: RootState) =>
  Object.entries(
    groupBy(messageSelectors.selectAll(state), (message) => formatDate(new Date(message.createdAt), 'dd MMM yyyy')),
  ).sort(([aDate, [aMessage]], [bDate, [bMessage]]) => descDateComparer(aMessage.createdAt, bMessage.createdAt));

export const notificationsSelectors = {
  selectHasMore,
  selectIsFetching,
  selectMessagesByDate,
  ...messageSelectors,
};

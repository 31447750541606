import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';
import copy from 'copy-to-clipboard';

import './inviteCode.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { Header } from '../../../components/common/Header';
import { InviteCodeIcon } from '../../../icons/invite-code-icon';

export const InviteCodePageBelarus = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.profile.userInfo);

  let [pressToCopyText, setPressToCopyText] = useState(formatMessage('Click on the code to copy'));

  const shareCode = () => {
    copy(userInfo.invite_code);
    setPressToCopyText(formatMessage('Successfully copied'));
  };

  return (
    <div className="invite-code-page">
      <Header fixed hasBackButton />
      <div className="invite__container">
        <div className="invite__code-section">
          <InviteCodeIcon className="invite__code-section-image" />
          <div className="invite__code-section-text" onClick={shareCode}>
            {userInfo.invite_code}
          </div>
          <div className="invite__code-section-copy-text">{pressToCopyText}</div>
        </div>
        <div className="invite__bottom-section">
          <div className="invite__bottom-section-caption">{formatMessage('Invite code')}</div>
          <div className="invite__bottom-section-description">
            {formatMessage('Invite a tobacco retailer to the app and get reward points.')}
          </div>
          <Button
            withoutTheme
            className="invite__bottom-section-button"
            onClick={() => {
              dispatch(goBack());
            }}
          >
            {formatMessage('OK')}
          </Button>
        </div>
      </div>
    </div>
  );
};

import { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, reset, SubmissionError } from 'redux-form';

import { useSelector } from 'react-redux';

import './signupPage.scss';

import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { Button } from '../../../components/common/Button';
import { Input } from '../../../components/common/Input';
import { Select } from '../../../components/common/Select';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import {
  required,
  email,
  lengthMinMax,
  inviteCodeLatin,
  olderThen18,
  lettersLatin,
  username,
} from '../../../helpers/formValidations';
import { getPositionsSelectors } from '../../../redux/service/serviceSelectors';
import { authApi } from '../../../api/authApi';
import { modalActions } from '../../../components/containers/Modal/modalSlice';

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

const SignupPageComponent = (props) => {
  const { handleSubmit, formObj, invalid } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const positions = useSelector(getPositionsSelectors);

  useEffect(() => {
    if (document.getElementsByClassName('auth-page') && document.getElementsByClassName('auth-page').length) {
      document.getElementsByClassName('auth-page')[0].style.height = 'unset';
      return () => {
        document.getElementsByClassName('auth-page')[0].style.height = '100%';
      };
    }
  }, []);

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 1,
      }),
    );
  }, [dispatch]);

  const submit = () => {
    return authApi
      .validateSignUpForm({
        username: formObj.values.username,
        email: formObj.values.email,
        invite_code: formObj.values.invite_code,
      })
      .then(
        (response) => {
          const errorObject = {};

          for (const [key, value] of Object.entries(response.data)) {
            if (value) {
              errorObject[key] = value;
              if (key === 'invite_code') {
                dispatch(modalActions.openWrongInviteCodeModal());
              }
            }
          }

          if (Object.keys(errorObject).length !== 0) {
            throw new SubmissionError(errorObject);
          }

          return dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.authStepList,
              stepId: 5,
            }),
          );
        },
        (error) => {},
      );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="signup-page signup-page__hungary">
      <div className="signup-page__top-section">
        <Button
          withoutTheme
          type="button"
          className="signup-page__back-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{formatMessage('Fill in all the fields')}</div>
        <Input
          name="name"
          placeholder={formatMessage('Name')}
          validate={[required, lettersLatin, lengthMinMax(2, 15, formatMessage)]}
          customClass="signup-page__input"
        />
        <Input
          name="surname"
          placeholder={formatMessage('Surname')}
          validate={[required, lettersLatin, lengthMinMax(2, 30, formatMessage)]}
          customClass="signup-page__input"
        />
        <Input
          name="username"
          placeholder={formatMessage('Profile name')}
          validate={[required, username, lengthMinMax(2, 15, formatMessage)]}
          customClass="signup-page__input"
        />
        <Input
          name="date_of_birth"
          placeholder={formatMessage('Date of birth (optional)')}
          type="date"
          validate={[olderThen18]}
          customClass="signup-page__input"
        />
        <Input
          name="email"
          placeholder={formatMessage('Email')}
          validate={[required, email]}
          customClass="signup-page__input"
        />
        <Select
          name="position_id"
          placeholder={formatMessage('Job title')}
          options={positions}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'position_id')}`}
        />
        <Input
          name="invite_code"
          placeholder={formatMessage('Invite code (optional)')}
          customClass="signup-page__input"
          validate={[inviteCodeLatin]}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme disabled={invalid}></ArrowButton>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    formObj: form.signupForm || { values: {} },
    phone: form.enterPhoneForm.values.phone,
  };
};

const form = reduxForm({
  form: 'signupForm',
  initialValues: {},
  destroyOnUnmount: false,
  onSubmit: () => {},
})(SignupPageComponent);

export const SignupPageHungary = connect(mapStateToProps, null)(form);

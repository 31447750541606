export const APP_TYPES = {
  IRELAND: 'IRELAND',
  HUNGARY: 'HUNGARY',
  GEORGIA: 'GEORGIA',
  ARMENIA: 'ARMENIA',
  AZERBAIJAN: 'AZERBAIJAN',
  BELARUS: 'BELARUS',
  DEMO: 'DEMO',
  TAIWAN: 'TAIWAN',
  TABATERRA_AZ: 'TABATERRA_AZ',
  MYBRAND_AZ: 'MYBRAND_AZ',
};

export const APP_TYPE = process.env.REACT_APP_APP_TYPE;

export const APP_ENVIRONMENT = {
  STAGING: 'staging',
  PRODUCTION: 'production',
};

export const CAUCASUS = [APP_TYPES.GEORGIA, APP_TYPES.ARMENIA, APP_TYPES.AZERBAIJAN];

const appConstantsByAppType = {
  [APP_TYPES.IRELAND]: {
    APP_NAME: 'Connex',
    APP_PHONE_CODE: '+353',
  },
  [APP_TYPES.HUNGARY]: {
    APP_NAME: 'JTI Connect',
    APP_PHONE_CODE: '+36',
  },
  [APP_TYPES.GEORGIA]: {
    APP_NAME: 'JTI Club',
    APP_PHONE_CODE: '+995',
  },
  [APP_TYPES.ARMENIA]: {
    APP_NAME: 'JTI Club',
    APP_PHONE_CODE: '+374',
  },
  [APP_TYPES.AZERBAIJAN]: {
    APP_NAME: 'JTI Club',
    APP_PHONE_CODE: '+994',
  },
  [APP_TYPES.BELARUS]: {
    APP_NAME: 'be2gether',
    APP_PHONE_CODE: '+375',
  },
  [APP_TYPES.DEMO]: {
    APP_NAME: 'Connect Demo',
    APP_PHONE_CODE: '+353',
  },
  [APP_TYPES.TAIWAN]: {
    APP_NAME: 'Dawe Expert',
    APP_PHONE_CODE: '+886',
  },
  [APP_TYPES.TABATERRA_AZ]: {
    APP_NAME: 'Tabaterra Club',
    APP_PHONE_CODE: '+994',
  },
  [APP_TYPES.MYBRAND_AZ]: {
    APP_NAME: 'Mybrand Space',
    APP_PHONE_CODE: '+994',
  },
};

export const MESSAGE_TYPES = {
  WINSTON_TYPE: 1,
  CAMEL_TYPE: 2,
  LD_TYPE: 3,
  TUTORIAL: 7,
  SPECIAL_QUIZ_TYPE: 8,
  VERIFICATION: 9,
  PROMOCODE_MESSAGE: 10,
  KB_ARTICLE: 11,
  PASSPORT: 14,
  KB_CATEGORY: 12,
  NOTIFICATION: 19,
  HAPPY_BIRTHDAY_MESSAGE: 20,
  DAILY_GAME: 22,
  LINK_TO_CHAT: 23,
};

export const KB_CATEGORY_TYPES = {
  GENERAL_CATEGORY: 1,
  GAMES_AND_QUIZZES_CATEGORY: 2,
  SALES_TIPS: 3,
  JTI_PRODUCTS_FOR_RU_REGION: 3,
  BRANDS_INFO_CATEGORY: 4,
};

export const appConstants = appConstantsByAppType[process.env.REACT_APP_APP_TYPE];

export const timeZones = {
  [APP_TYPES.HUNGARY]: 'Europe/Budapest',
  [APP_TYPES.IRELAND]: 'Europe/Dublin',
  [APP_TYPES.BELARUS]: 'Europe/Minsk',
  [APP_TYPES.GEORGIA]: 'Asia/Tbilisi',
  [APP_TYPES.ARMENIA]: 'Asia/Yerevan',
  [APP_TYPES.AZERBAIJAN]: 'Asia/Baku',
  [APP_TYPES.TAIWAN]: 'Asia/Taipei',
  [APP_TYPES.TABATERRA_AZ]: 'Asia/Baku',
  [APP_TYPES.MYBRAND_AZ]: 'Asia/Baku',
  [APP_TYPES.DEMO]: 'Europe/Dublin',
};

export const appTimeZone = timeZones[APP_TYPE];

export const LOCALES = {
  [APP_TYPES.IRELAND]: 'en-gb',
  [APP_TYPES.TAIWAN]: 'zh-tw',
  [APP_TYPES.HUNGARY]: 'hu',
  [APP_TYPES.GEORGIA]: 'ka',
  [APP_TYPES.ARMENIA]: 'hy-am',
  [APP_TYPES.AZERBAIJAN]: 'az',
  [APP_TYPES.TABATERRA_AZ]: 'az',
  [APP_TYPES.MYBRAND_AZ]: 'az',
  [APP_TYPES.BELARUS]: 'ru',
  [APP_TYPES.DEMO]: 'en-gb',
};

export const APP_LOCALE = LOCALES[APP_TYPE];

const amplitudeApiKeyByAppType = {
  [APP_TYPES.IRELAND]: 'ff06af2fe0fd56cfef2118a0a8b65ee5',
  [APP_TYPES.HUNGARY]: '02fdd1ac9372efe59f9dd5883dd486ba',
  [APP_TYPES.GEORGIA]: '289f26e16e513308da170b5d579b7283',
  [APP_TYPES.ARMENIA]: '1b5324cc2ebbbb8106b6037ba8c9ed09',
  [APP_TYPES.AZERBAIJAN]: 'cd08a4766c6ec219017d369007f1107c',
  [APP_TYPES.BELARUS]: '3e0c6d318336b9754044298b41f8d358',
  [APP_TYPES.TAIWAN]: '', // TODO
  [APP_TYPES.TABATERRA_AZ]: '', // TODO
  [APP_TYPES.MYBRAND_AZ]: '', // TODO
};

export const reCAPTCHAsiteKey = '6LdkB8gnAAAAAMpwisBEL2UKcwIxONittUhLK0_T';

export const amplitudeApiKey = amplitudeApiKeyByAppType[APP_TYPE];

export const s3FilePaths = {
  passport: 'passports/user/',
  signature: 'signing/user/',
  challenge: 'challenge/user/',
  chat: 'chat/user/',
  idCard: 'id-card/user/',
  profileImage: 'profile/user/',
  recognition: 'recognition/user/',
  mysteryShopperPhotoQuestion: 'main-screen/module/mystery-shopper/photo-question/',
};

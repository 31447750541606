import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './profileSettings.scss';

import { Header } from '../../components/common/Header';
import { profileActions } from '../../redux/profile/profileSlice';
import { analyticHelper } from '../../helpers/analyticHelper';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { ProfileSettingsBottomArea } from './profileSettingsBottomArea/profileSettingsBottomArea';
import { UserInfoIreland } from './userInfoForms/userInfoFormIreland';
import { getManagerInfoSelector } from '../../redux/profile/profileSelectors';

export const ProfileSettingsIreland = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.profile.userInfo);
  const theme = useSelector((state) => state.service.theme);
  const manager = useSelector(getManagerInfoSelector);

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const handleSaveChanges = (values) => {
    const changedUserInfo = {
      username: values.username,
    };
    localStorage.setItem('themeId', theme.id);
    dispatch(profileActions.changeUserInfo({ userInfo: changedUserInfo }));
  };

  return (
    <div className="profile-settings-page">
      <ScrollBlock fromTop>
        <Header hasBackButton>
          <p className="profile-settings-page__header-text">{formatMessage('Edit profile')}</p>
        </Header>
        <div
          className="profile-settings-page__content"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 60px)' : 'unset' }}
        >
          {userInfo.id && (
            <UserInfoIreland
              trafficCode={userInfo.traffic_code}
              onSubmit={handleSaveChanges}
              initialValues={{
                username: userInfo.username,
                traffic_code: userInfo.traffic_code,
                manager_name: `${manager.name} ${manager.surname}`,
              }}
            />
          )}
          <ProfileSettingsBottomArea />
        </div>
        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};

export const MinusIcon = (props) => {
  const { disabled } = props;
  return (
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity={disabled ? '0.2' : '1'}>
        <circle cx="25" cy="25" r="24.5" stroke="#01C1B6" />
        <rect x="35" y="23.8896" width="2.22222" height="20" transform="rotate(90 35 23.8896)" fill="#01C1B6" />
      </g>
    </svg>
  );
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { goBack } from 'connected-react-router';

import { useInternationalization } from '../../hooks/useTranslationHook';
import PageContainer from '../../components/common/PageContainer';
import QRCodeGenerator from '../../components/QRCodeGenerator';
import { getInviteCode, getInviteCodesAmount } from '../../redux/profile/profileSelectors';
import { Routes } from '../../const';
import { HeaderProps } from '../../components/common/Header';
import { selfRegistrationActions } from '../../redux/selfRegistration/selfRegistrationSlice';
import { selfRegistrationReward } from '../../redux/selfRegistration/selfRegistrationSelectors';

import styles from './ColleaguesInvitation.module.scss';

interface IColleaguesInvitation {
  location: RouteComponentProps;
}

export const ColleaguesInvitation = ({ location }: IColleaguesInvitation) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const inviteCode = useSelector(getInviteCode);
  const inviteCodesAmount = useSelector(getInviteCodesAmount);
  const reward = useSelector(selfRegistrationReward);

  const getRefferalLink = (inviteCode: string): string =>
    `${window.location.origin}${Routes.SelfRegistration}?invite_code=${inviteCode}`;

  const headerProps: HeaderProps = {
    goBackCallback: () => dispatch(goBack()),
    hasBackButton: true,
    buttonColor: '#fff',
    children: formatMessage('colleagues_invitation.title'),
    customClass: styles.headerCustomClass,
  };

  useEffect(() => {
    dispatch(selfRegistrationActions.initGetReward());
  }, []);

  return (
    <PageContainer location={location} headerProps={headerProps}>
      <h2 className={styles.invitationTitle}>{formatMessage('colleagues_invitation.main_title')}</h2>
      <div className={styles.invitationSubTitle}>
        {formatMessage('colleagues_invitation.share_your_link', { points: reward })}
      </div>
      <div className={styles.invitationAvailableCodes}>
        {formatMessage('colleagues_invitation.available_codes')} <b>{inviteCodesAmount}</b>
      </div>
      <div className={styles.qrCodeBlock}>
        <QRCodeGenerator value={getRefferalLink(inviteCode)} />
      </div>
      <div></div>
    </PageContainer>
  );
};

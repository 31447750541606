import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChatTab } from './chatTypes';

export interface CommonChatState {
  unreadNotificationsCount: number;
  unreadSupportMessagesCount: number;
}

const initialState: CommonChatState = {
  unreadNotificationsCount: 0,
  unreadSupportMessagesCount: 0,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    handleSocket: (): void => {},
    fetchUnreadMessagesCount: (): void => {},
    setUnreadMessagesCount: (
      state: CommonChatState,
      action: PayloadAction<{ notification: number; support: number }>,
    ): void => {
      const { notification, support } = action.payload;

      state.unreadNotificationsCount = notification;
      state.unreadSupportMessagesCount = support;
    },
    incrementUnreadMessagesCount: (state: CommonChatState, action: PayloadAction<ChatTab>): void => {
      action.payload === ChatTab.Notifications ? ++state.unreadNotificationsCount : ++state.unreadSupportMessagesCount;
    },
    updateLastCheckedTicketMessage: (state: CommonChatState, action: PayloadAction<{ ticketId: string }>): void => {},
  },
});

export const chatActions = chatSlice.actions;
export const chatReducer = chatSlice.reducer;

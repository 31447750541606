import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './CashoutModal.scss';
import { ModalContainer } from '../containers/Modal/ModalContainer';
import { MODALS } from '../containers/Modal/modalConstants';
import { ModalTitle } from '../containers/Modal/ModalTitle';
import { useInternationalization } from '../../hooks/useTranslationHook';

import { Button } from '../common/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getModalDataSelector } from '../containers/Modal/modalSelectors';
import { modalActions } from '../containers/Modal/modalSlice';
import { getUserBalanceSelector } from '../../redux/profile/profileSelectors';
import { getIsTransferPendingSelector } from '../../redux/bonusShop/bonusShopSelectors';
import { MinusIcon } from '../../icons/minus-money-icon';
import { PlusIcon } from '../../icons/plus-money-icon';

const styles = {
  width: 343,
};
const Loader = () => {
  const [text, setText] = useState('.');

  useEffect(() => {
    if (text.length === 5) {
      setTimeout(() => setText('.'), 500);
    } else {
      setTimeout(() => setText(`${text}.`), 500);
    }
  }, [text]);

  return <>{text}</>;
};

export const CashoutModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const coins = useSelector(getUserBalanceSelector);
  const isTransferPending = useSelector(getIsTransferPendingSelector);

  let {
    data: {
      onClose,
      btnText,
      title,
      symbol,
      specificCurrency,
      rate,
      step,
      moneyLimit,
      makeTransfer,
      isTutorial,
      amountMoneyIcons,
      minimumTransferMoney = 0,
      closedAmount,
    },
  } = useSelector(getModalDataSelector);

  const stepButtonValue = rate * step;

  const [currentCoinsAmount, setCurrentCoinsAmount] = useState(closedAmount || minimumTransferMoney);

  const isButtonDisabled = isTransferPending || currentCoinsAmount <= minimumTransferMoney;

  const handleButtonClick = () => {
    if (makeTransfer) {
      makeTransfer({ amount: currentCoinsAmount, isTutorial });
      return;
    }

    dispatch(modalActions.closeModal());
  };

  const getMoney = useCallback(() => {
    return rate * currentCoinsAmount;
  }, [rate, currentCoinsAmount]);

  const decreaseCoins = () => {
    if (!isButtonDisabled) {
      setCurrentCoinsAmount(currentCoinsAmount - step);
    }
  };

  const increaseCoins = (amountMoney = 1) => {
    if (!isTransferPending) {
      if (coins - currentCoinsAmount >= step * amountMoney && getMoney() + amountMoney <= moneyLimit) {
        setCurrentCoinsAmount(currentCoinsAmount + step * amountMoney);
      }
    }
  };

  const increaseBy = (moneyAmount) => {
    if (!isTransferPending) {
      if (
        coins - currentCoinsAmount >= moneyAmount * (step / (step * rate)) &&
        getMoney() + moneyAmount <= moneyLimit
      ) {
        setCurrentCoinsAmount(currentCoinsAmount + moneyAmount * (step / (step * rate)));
      }
    }
  };

  const disabledMoneyButton = useCallback(
    (amountMoney) => {
      return (
        coins - currentCoinsAmount < amountMoney / rate || getMoney() + amountMoney > moneyLimit || isTransferPending
      );
    },
    [currentCoinsAmount, coins, step, moneyLimit, getMoney, isTransferPending],
  );

  const amountIcon = (
    <div className="transfer-to-phone-modal__amountIcons">
      {amountMoneyIcons &&
        amountMoneyIcons.map((el, index) => (
          <Button
            className="transfer-to-phone-modal__amountIcons-icon"
            onClick={() => increaseBy(el)}
            disabled={disabledMoneyButton(el)}
            key={index}
          >
            {`+${symbol}${el}`}
          </Button>
        ))}
    </div>
  );

  return (
    <ModalContainer modal={MODALS.cashout} onClose={onClose} customStyles={styles}>
      <div className="transfer-to-phone-modal">
        {title && <ModalTitle>{formatMessage(title)}</ModalTitle>}
        <div className="transfer-to-phone-modal__counter">
          <MinusIcon disabled={isButtonDisabled} onClick={decreaseCoins} />
          <div className="transfer-to-phone-modal__counter-rate">
            <p className="transfer-to-phone-modal__counter-rate-coins">
              {`${currentCoinsAmount} ${formatMessage('m10.bank_account')}`}
            </p>
            <p className="transfer-to-phone-modal__counter-rate-money">{`(${symbol}${
              Math.round(getMoney() * 100) / 100
            })`}</p>
          </div>
          <PlusIcon disabled={disabledMoneyButton(stepButtonValue)} onClick={() => increaseCoins(1)} />
        </div>
        {amountMoneyIcons && amountIcon}
        {btnText && (
          <Button
            disabled={currentCoinsAmount === 0 || isTransferPending}
            onClick={handleButtonClick}
            customClass={`transfer-to-phone-modal__btn${
              currentCoinsAmount === 0 || isTransferPending ? '-disabled' : ''
            }`}
          >
            {isTransferPending ? <Loader /> : formatMessage(btnText)}
          </Button>
        )}
      </div>
    </ModalContainer>
  );
};

CashoutModal.propTypes = {
  onClose: PropTypes.func,
};
CashoutModal.defaultProps = {};

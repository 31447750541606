import moment from 'moment-timezone';

import { VoucherTransactionWithDetails } from '../../types/Voucher';
import styles from './MyVouchers.module.scss';

interface VouchersListItemProps {
  redeemedVoucher: VoucherTransactionWithDetails;
  onClick: (voucher: VoucherTransactionWithDetails) => void;
}

const MyVouchersListItem = ({ onClick, redeemedVoucher }: VouchersListItemProps) => {
  return (
    <div className={styles.list_item}>
      <img src={redeemedVoucher.voucher?.image} onClick={() => onClick(redeemedVoucher)} />
      <div className={styles.date}>{moment(redeemedVoucher.created_at).format('DD.MM.YYYY HH:mm')}</div>
      <div className={styles.title}>{redeemedVoucher.voucher?.name}</div>
    </div>
  );
};

export default MyVouchersListItem;

import { createSelector } from 'reselect';

export const getThemeSelector = createSelector([(state) => state.service.theme], (theme) => theme);

export const getLinksSelector = createSelector([(state) => state.service.links], (links) => links);

export const getPositionsSelectors = createSelector([(state) => state.service.positions], (positions) => positions);

export const getManagersCitiesSelectors = createSelector([(state) => state.service.managersCities], (cities) => cities);

export const getCitiesSelectors = createSelector([(state) => state.service.cities], (cities) => cities);

export const getManagersSelectors = createSelector([(state) => state.service.managers], (managers) => managers);

export const getLegalEntitiesSelector = createSelector(
  [(state) => state.service.legal_entities],
  (legal_entities) => legal_entities,
);

export const getAddressesSelector = createSelector([(state) => state.service.addresses], (addresses) => addresses);

export const getAvailableManager = createSelector(
  [(state) => state.service.availableManager],
  (availableManager) => availableManager,
);

export const getMonthlyLimitationSelector = createSelector(
  [(state) => state.service.monthlyLimitation],
  (limitations) => limitations,
);

export const getOutletsSelector = createSelector([(state) => state.service.outlets], (outlets) => outlets);

export const getIsFetchingSelector = createSelector([(state) => state.service.isFetching], (isFetching) => isFetching);

import * as ChallengeTaskTypesConstants from '../redux/challenge/challengeConstants';

export const isBrandTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.BrandTypeTasks.includes(type);
};

export const isBonusShopTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.BonusShopTypeTasks.includes(type);
};

export const isProfileTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.ProfileTypeTasks.includes(type);
};

export const isLotteryTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.LotteryTypeTasks.includes(type);
};

export const isKnowledgeBankTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.KnowledgeBankTypeTask.includes(type);
};

export const isSpecialQuizTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.SpecialQuizTypeTask.includes(type);
};

export const isBrandQuizTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.BrandQuizTypeTasks.includes(type);
};

export const isQuestionOfTheDayTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.QuestionOfTheDayTypeTasks.includes(type);
};

export const isGameTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.GamesTypeTasks.includes(type);
};

export const isSingleModeTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.SingleModeTypeTasks.includes(type);
};

export const IsSalesTipsTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.SalesTipsTypeTasks.includes(type);
};

export const isCustomTextTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.CustomTextTypeTask.includes(type);
};

export const isLinkToChatTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.LinkToChatTypeTask.includes(type);
};

export const isQuickTipsTypeTask = (type: number) => {
  return ChallengeTaskTypesConstants.QuickTipsTypeTask.includes(type);
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IBanner } from './bannersTypes';

export interface IBannersState {
  categoryBanners: IBanner[];
  currentBanner: IBanner | null;
}

const initialState: IBannersState = {
  categoryBanners: [],
  currentBanner: null,
};

const bannersSlice = createSlice({
  name: 'banners',
  initialState,
  reducers: {
    openBannerPage: (state: IBannersState, action: PayloadAction<{ banner: IBanner }>): void => {},
    getBannersForCategory: (state: IBannersState, action: PayloadAction<{ categoryId: number }>): void => {},
    setCurrentBanner: (state: IBannersState, action: PayloadAction<IBanner>) => {
      state.currentBanner = action.payload;
    },
    setBanners: (state: IBannersState, action: PayloadAction<IBanner[]>) => {
      state.categoryBanners = action.payload;
    },
  },
});

export const bannersActions = bannersSlice.actions;
export const bannersReducer = bannersSlice.reducer;

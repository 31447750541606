import { useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { getModalDataSelector } from './modalSelectors';
import { ErrorModal } from '../../modals/ErrorModal';
import { MODALS } from './modalConstants';
import { PromptModal } from '../../modals/Prompt';
import { CashoutModal } from '../../modals/CashoutModal';
import { UnexpectedRewardsModal } from '../../modals/UnexpectedRewardsModal/UnexpectedRewardsModal';
import { EsignEmailModal } from '../../modals/esignEmailModal/EsignEmailModal';
import { VeririficationErrorModal } from '../../modals/verificationModals/verificationErrorModal/verificationErrorModal';
import { VerificationStepModal } from '../../modals/verificationModals/verificationStepModal/verificationStepModal';
import { LoyaltyRewardModal } from '../../modals/LoyaltyRewardModal/LoyaltyRewardModal';
import { ScenarioReactionModal } from '../../../pages/salesSimulators/scenarioReactionModal';
import { ExchangeModal } from '../../modals/Exchange/ExchangeModal';
import { AchievementRewardModal } from '../../modals/achievementsModals/achievementRewardModal/AchievementRewardModal';
import { AchievementLevelCompleted } from '../../modals/achievementsModals/achievementLevelCompleted/AchievementLevelCompletedModal';
import { AchievementModal } from '../../modals/achievementModal/achievementModal';
import { AchievementTutorialModal } from '../../modals/achievementTutorialModal/achievementTutorialModal';
import { QuizGameErrorModal } from '../../modals/quizGameModal/quizGameErrorModal';
import { WrongInviteCodeModal } from '../../modals/wrongInviteCodeModal/wrongInviteCodeModal';
import { SMSCodeInputModal } from '../../modals/SMSCodeInputModal/SMSCodeInputModal';
import { TwoButtonsPromptModal } from '../../modals/PromptWithTwoButtons';
import { OrientationModal } from '../../modals/orientationModal/OrientationModal';
import QuizRateModal from '../../modals/quizRatetModal/QuizRateModal';
import HappyBirthdayModal from '../../modals/happyBirthdayModal/HappyBirthdayModal';
import { InputModalForm } from '../../modals/inputModal/InputModal';
import DailyBonusModal from '../../modals/dailyBonusModal/DailyBonusModal';

const modals = {
  // example
  [MODALS.error]: ErrorModal,
  [MODALS.orientation]: OrientationModal,
  [MODALS.prompt]: PromptModal,
  [MODALS.promptWithTwoButtons]: TwoButtonsPromptModal,
  [MODALS.cashout]: CashoutModal,
  [MODALS.unexprectedRewards]: UnexpectedRewardsModal,
  [MODALS.exchange]: ExchangeModal,
  [MODALS.loyaltyReward]: LoyaltyRewardModal,
  [MODALS.esignEmail]: EsignEmailModal,
  [MODALS.verificationError]: VeririficationErrorModal,
  [MODALS.verificationStep]: VerificationStepModal,
  [MODALS.scenarioReaction]: ScenarioReactionModal,
  [MODALS.achievementReward]: AchievementRewardModal,
  [MODALS.achievementLevelCompleted]: AchievementLevelCompleted,
  [MODALS.achievement]: AchievementModal,
  [MODALS.achievementTutorial]: AchievementTutorialModal,
  [MODALS.quizGameError]: QuizGameErrorModal,
  [MODALS.wrongInviteCode]: WrongInviteCodeModal,
  [MODALS.SMSCodeInput]: SMSCodeInputModal,
  [MODALS.quizRate]: QuizRateModal,
  [MODALS.happyBirthday]: HappyBirthdayModal,
  [MODALS.inputModal]: InputModalForm,
  [MODALS.dailyBonus]: DailyBonusModal,
};

export const ModalsComponent = (props) => {
  const { modalData } = props;
  const { modal } = modalData;

  const ModalType = modals[modal];

  useEffect(() => {
    const rootElement = document.getElementById('root');
    Modal.setAppElement(rootElement);
  }, []);

  return ModalType ? <ModalType modal={modal} /> : null;
};

// for some reason useSelector doesnt work (can't find redux context)
// fortunately connect still working
const mapStateToProps = (state) => {
  return {
    modalData: getModalDataSelector(state),
  };
};
export const Modals = connect(mapStateToProps)(ModalsComponent);

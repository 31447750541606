import { createSelector } from 'reselect';
import { RootState } from '../store';

export const selectUsersCount = createSelector([(state: RootState) => state.moderation.count], (count) => count);

export const selectUsers = createSelector([(state: RootState) => state.moderation.users], (users) => users);

export const selectIsFetching = createSelector(
  [(state: RootState) => state.moderation.isFetching],
  (isFetching) => isFetching,
);

import { useHistory } from 'react-router';

import './Lep.scss';

import { Button } from '../../../components/common/Button';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { LepStar } from '../../../icons/lep-star-complete-registration';
import { Routes } from '../../../const';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';

export const LepCompleteRegistration = () => {
  const history = useHistory();

  const handleSignUp = () => {
    history.push(Routes.MainRoute);
  };
  return (
    <div className="lep-page__complete-registration">
      <LepStar className="lep-page__complete-registration-img" />
      <div className="lep-page__complete-registration-header">Thanks for registration!</div>
      <div className="lep-page__complete-registration-description">
        We need some time to moderate your account. Wait for the email or SMS from your JTI representative with the
        following instructions.
      </div>
      <Button
        style={{ marginBottom: isIPhoneWithHomeBar() ? '64px' : '30px' }}
        withoutTheme
        type="button"
        className="lep-page__complete-registration-button"
        onClick={handleSignUp}
      >
        Got it
      </Button>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

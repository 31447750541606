import crypto from 'crypto-js';

const ENCRYPTION_MODE = crypto.mode.CTR;
const ENCRYPTION_PADDING = crypto.pad.NoPadding;

const SECRET_KEY = process.env.REACT_APP_KEY_ANSWERS;

const decryptAnswer = (hash) => {
  const ciphertext = crypto.enc.Hex.parse(hash.content);
  const key = crypto.enc.Utf8.parse(SECRET_KEY);
  const iv = crypto.enc.Hex.parse(hash.iv);

  const decrypted = crypto.AES.decrypt({ ciphertext }, key, {
    iv,
    mode: ENCRYPTION_MODE,
    padding: ENCRYPTION_PADDING,
  }).toString(crypto.enc.Utf8);

  return Number(decrypted);
};

export { decryptAnswer };

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import './autoCheckIn.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import autoCheckInImage from './autoCheckInImage.png';

export const AutoCheckIn = (props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const handleArrowButtonClick = useCallback(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.checkInStepList,
        stepId: 2,
      }),
    );
  }, [dispatch]);

  const onBackCallback = useCallback(() => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.checkInStepList }));
  }, [dispatch]);

  return (
    <div className="auto-check-in__component">
      <div className="check-in__top-section">
        <Button
          withoutTheme
          type="button"
          className="check-in__top-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded fillColor="#fff" className="check-in__top-arrow" />
        </Button>
        <div className="check-in__img-section">
          <img alt="img" src={autoCheckInImage} className="check-in__image" />
        </div>
        <h1 className="check-in__title">{formatMessage('Entrust the check-in application')}</h1>
        <div className="check-in__description-section">
          <p className="check-in__text">{formatMessage('You can check-in automatically.')}</p>
          <h2 className="check-in__bold-text"> {formatMessage('What is needed for this?')}</h2>
          <p className="check-in__text">
            1.
            {formatMessage(
              'Allow jti-club access to your phone’s geodata. Thanks to this, we will confirm that you are working at the address specified in the profile.',
            )}
            <br />
            2.
            {formatMessage(
              'Open the application in the workplace. It will automatically count as one stage of the check-in.',
            )}
          </p>
          <p className="check-in__text">
            {formatMessage(
              'For passing each stage, we will accrue points to your account. Moreover, the final fourth stage costs 5 times more points than the rest. And in total for the entire check-in you will receive 400 additional points.',
            )}
          </p>
          <p className="check-in__text">
            {formatMessage("All your data is protected and won't be transferred to third parties.")}
          </p>
        </div>
        <div className="check-in__bot-section">
          <ArrowButton withoutTheme onClick={() => handleArrowButtonClick()}></ArrowButton>
        </div>
      </div>
    </div>
  );
};

import { ITask } from '../../../../redux/challenge/challengeTypes';
import { Task } from '../../containers';

import styles from './TaskBlock.module.scss';

type Props = {
  tasks: ITask[];
  photoFrame?: string;
  challengeId: number;
  attachmentsButtonEnabled: boolean;
};

export const TaskBlock = ({ tasks, photoFrame, challengeId, attachmentsButtonEnabled }: Props) => {
  return (
    <div className={styles.tasksBlock}>
      {tasks.map((task) => (
        <Task
          key={task.id}
          task={task}
          photoFrame={photoFrame}
          challengeId={challengeId}
          attachmentsButtonEnabled={attachmentsButtonEnabled}
        />
      ))}
    </div>
  );
};

import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import './shopIsNotAvailable.scss';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { Button } from '../../components/common/Button';

import LockImage from '../../images/lock-image.png';
import { Header } from '../../components/common/Header';

const ShopIsNotAvailablePage = ({ onButtonClick }) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className="shop-is-not-available-page">
      <ScrollBlock fromTop>
        <Header customClass="shop-is-not-available-page__header" />
        <div
          className="shop-is-not-available-page__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
        >
          <div
            className="shop-is-not-available-page__content-area-schedule-container"
            style={{
              backgroundImage: `url('${LockImage}')`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className={'shop-is-not-available-page__bottom-text'}>
            <p className="shop-is-not-available-page__content-area-caption">
              {formatMessage('Shop is not available yet')}
            </p>
            <p
              className={classnames(
                'shop-is-not-available-page__content-area-caption',
                'shop-is-not-available-page__content-area-description',
              )}
            >
              {formatMessage('Need to finish Check-in first')}.
            </p>
            <div className={'shop-is-not-available-page__button-container'}>
              <Button onClick={onButtonClick}>{formatMessage('Got it(No bonus shop)')}</Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const ShopIsNotAvailable = withRouter(ShopIsNotAvailablePage);

import { useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, reset, SubmissionError, change } from 'redux-form';

import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { Button } from '../../../components/common/Button';
import { Input } from '../../../components/common/Input';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import {
  required,
  lengthMinMax,
  olderThen18,
  lettersCyrillic,
  usernameBelarus,
  //TODO: add after approval
  // passportNumber,
} from '../../../helpers/formValidations';
import { SigningDisclaimer, SigningPage } from './signing';
import { authApi } from '../../../api/authApi';
import progressBar from '../../../images/Frame2.png';

import './signupPage.scss';

const SignupPageComponent = (props) => {
  const { handleSubmit, formObj, invalid } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const [isSigningDisclaimerOpened, setIsSigningDisclaimerOpened] = useState(false);
  const [isSigningAccepted, setIsSigningAccepted] = useState(false);
  const [isSigningDone, setIsSigningDone] = useState(false);

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 3,
      }),
    );
  }, [dispatch]);

  const submit = () => {
    return authApi
      .validateSignUpForm({
        username: formObj.values.username,
        date_of_birth: formObj.values.date_of_birth,
      })
      .then((response) => {
        const errorObject = {};
        for (const [key, value] of Object.entries(response.data)) {
          if (value) {
            errorObject[key] = value;
          }
        }

        if (Object.keys(errorObject).length !== 0) {
          throw new SubmissionError(errorObject);
        }

        return dispatch(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: 5,
          }),
        );
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const onDecliningRules = () => {
    setIsSigningDisclaimerOpened(false);
  };

  const onAcceptingRules = () => {
    setIsSigningAccepted(true);
  };

  const onSigningDone = (link) => {
    setIsSigningDisclaimerOpened(false);
    setIsSigningAccepted(false);
    setIsSigningDone(true);
    dispatch(change('signupForm', 'signature', link));
  };

  const goBackCallback = () => {
    setIsSigningDisclaimerOpened(true);
    setIsSigningAccepted(false);
  };
  //TODO: add after approval
  // const openInfoModal = () => {
  //   dispatch(
  //     modalActions.openPromptModal({
  //       title: 'Data is not transferred to third parties',
  //       description: 'Identification number is required by the new law on the protection of personal data',
  //       btnText: 'OK',
  //       onButtonClick: () => {
  //         dispatch(modalActions.closeModal());
  //       },
  //     }),
  //   );
  // };

  if (isSigningAccepted) {
    return <SigningPage onDoneClick={onSigningDone} goBackCallback={goBackCallback} />;
  } else if (isSigningDisclaimerOpened) {
    return <SigningDisclaimer onConfirmClick={onAcceptingRules} onCancelClick={onDecliningRules} />;
  } else {
    return (
      <form onSubmit={handleSubmit(submit)} className="signup-page">
        <div className="signup-page__top-section">
          <img src={progressBar} alt="progress bar" className="signup-page__progress-bar" />
          <Button
            withoutTheme
            type="button"
            className="signup-page__back-button"
            onClick={() => {
              onBackCallback();
            }}
          >
            <ArrowIconRounded className="signup-page__top-arrow" />
          </Button>
          <div className="signup-page__title">{formatMessage('Fill out your information')}</div>
          <Input
            name="first_name"
            placeholder={formatMessage('Name')}
            validate={[required, lettersCyrillic, lengthMinMax(2, 15, formatMessage)]}
            customClass="signup-page__input"
          />
          <Input
            name="last_name"
            placeholder={formatMessage('Surname')}
            validate={[required, lettersCyrillic, lengthMinMax(2, 30, formatMessage)]}
            customClass="signup-page__input"
          />
          <Input
            name="middle_name"
            placeholder={'Отчество'}
            validate={[required, lettersCyrillic, lengthMinMax(2, 30, formatMessage)]}
            customClass="signup-page__input"
          />
          <Input
            name="username"
            placeholder={formatMessage('Profile name')}
            validate={[required, usernameBelarus, lengthMinMax(2, 15, formatMessage)]}
            customClass="signup-page__input"
          />
          <Input
            name="date_of_birth"
            placeholder={formatMessage('Date of birth (optional)')}
            type="date"
            validate={[required, olderThen18]}
            customClass="signup-page__input"
          />
          {/*<Input*/}
          {/*  name="passport number"*/}
          {/*  placeholder={'Идентификационный номер паспорта'}*/}
          {/*  customClass="signup-page__input"*/}
          {/*  validate={[required, passportNumber(14, formatMessage)]}*/}
          {/*  InputButton={<InfoIcon className="signup-page__info-icon" onClick={openInfoModal} />}*/}
          {/*/>*/}
        </div>
        <div className="signup-page__bottom-section">
          <ArrowButton withoutTheme disabled={invalid || isSigningDone} />
        </div>
      </form>
    );
  }
};

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    formObj: form.signupForm || { values: {} },
  };
};

const form = reduxForm({
  form: 'signupForm',
  initialValues: {
    invite_code: '',
  },
  destroyOnUnmount: false,
  onSubmit: () => {},
  enableReinitialize: true,
})(SignupPageComponent);

export const SignupPageBelarus = connect(mapStateToProps, {})(form);

import { Field } from 'redux-form';
import classNames from 'classnames';

import './Select.scss';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const CommonSelect = (props) => {
  const {
    customClass,
    options,
    id,
    placeholder,
    withTranslations,
    additional_value,
    meta: { touched, error },
    disabled,
    withObjectValueOption,
    sectionId,
    input,
  } = props;
  const { formatMessage } = useInternationalization();

  const selectClassName = classNames(touched && error ? 'select-block__select-invalid' : 'select-block__select', {
    'select-block__select-placeholder': !input.value,
  });

  return (
    <div className={classNames('select-block', customClass)}>
      <select {...input} id={id} className={selectClassName} disabled={disabled}>
        {placeholder && (
          <option value="" disabled defaultValue>
            {placeholder}
          </option>
        )}
        {additional_value && (
          <option style={{ color: 'black' }} value={null}>
            {additional_value}
          </option>
        )}
        {options.map((option) => (
          <option
            value={
              withObjectValueOption
                ? JSON.stringify({ answer: option.answer, correct: option.correct, section_id: sectionId })
                : option.id
            }
            key={`${id}-${option.id}`}
            style={{ color: 'black' }}
          >
            {withTranslations
              ? formatMessage(option.name)
              : option.name || option.answer + ` (${option.dropdown_index})`}
          </option>
        ))}
      </select>
      {touched && error && <div className="select-block__warning-message">{formatMessage(error)}</div>}{' '}
    </div>
  );
};

export const Select = (props) => {
  return <Field component={CommonSelect} {...props} />;
};

import { all, put, takeEvery, select, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { getSurveyPackIdSelector, getSurveyAnswersSelector } from './surveySelectors';
import { Routes } from '../../const';
import { surveyApi } from '../../api/surveyApi';
import { surveyActions } from './surveySlice';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { ERROR_RESPONSE_TYPES } from './surveyConstants';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { SURVEY_STEPS } from '../../redux/survey/surveyConstants';

export const goToSurveyStepAction = stepId =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.surveyStepList,
    stepId,
  });

export const resetSurveyStepList = () => {
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.surveyStepList,
    stepId: 1,
  });
};

function* onStartSurveySaga({ payload }) {
  try {
    const { survey_id, surveyStatus } = payload;
    if (surveyStatus) {
      const { data } = yield surveyApi.startSurvey(survey_id);
      yield put(surveyActions.setSurveyPackId({ pack_id: survey_id }));
      yield put(surveyActions.setSurveyQuestions(data));
      yield put(push(`${Routes.SurveyRoute}/${survey_id}`));
    } else {
      yield put(push(Routes.noBonusSurveyRoute));
    }
  } catch (error) {}
}

function* onFinishSurveySaga() {
  try {
    const pack_id = yield select(getSurveyPackIdSelector);
    const answers = yield select(getSurveyAnswersSelector);
    const response = yield surveyApi.finishSurvey(pack_id, answers);
    yield put(surveyActions.setSurveyReward({ coins: response.data.coins }));
    yield delay(2000);
    yield put(goToSurveyStepAction(SURVEY_STEPS.RESULT));
  } catch (error) {}
}

function* onstartCertainSurveySaga({ payload }) {
  const { survey_id } = payload;
  try {
    const { data } = yield surveyApi.startSurvey(survey_id);
    yield put(surveyActions.setSurveyPackId({ pack_id: survey_id }));
    yield put(surveyActions.setSurveyQuestions(data));
    yield put(push(`${Routes.SurveyRoute}/${survey_id}`));
  } catch (err) {
    if (err.response && err.response.data.type === ERROR_RESPONSE_TYPES.SURVEY_ALREADY_PLAYED) {
      yield put(push(Routes.noBonusSurveyRoute));
    }
  }
}

function* onResetSurveySaga() {
  yield put(goToSurveyStepAction(SURVEY_STEPS.GAME));
}

function* sagas() {
  yield all([
    takeEvery(surveyActions.startSurvey, onStartSurveySaga),
    takeEvery(surveyActions.finishSurvey, onFinishSurveySaga),
    takeEvery(surveyActions.startCertainSurvey, onstartCertainSurveySaga),
    takeEvery(surveyActions.reset, onResetSurveySaga),
  ]);
}

export const surveySagas = sagas;

import './soloModePage.scss';

import { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { PlusIcon } from '../../../icons/plus-icon';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { RenderGame } from '../Game/renderQuestionComponent';
import { ANSWER_SOURCES } from '../../../redux/specialQuizes/specialQuizesConstants';
import { useFullGameTimer } from '../Game/gameComponent';
import timerAnimation from '../Game/conex_pwa_timer.json';
import {
  getSoloModeQuestionsSelector,
  getGameTimeSelector,
  getCoinsForQuestionSelector,
  getPackIdSelector,
} from '../../../redux/soloMode/soloModeSelectors';
import { soloModeActions } from '../../../redux/soloMode/soloModeSlice';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { Boosters } from '../Boosters/Boosters';
import { getActiveBoosterSelector } from '../../../redux/boosters/boostersSelectors';
import { BOOSTERS_TYPES } from '../../../redux/boosters/boostersConstants';
import { boostersActions } from '../../../redux/boosters/boostersSlice';
import { APP_TYPES } from '../../../const/appConstants';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';

const timerAnimationData = {
  loop: true,
  autoplay: true,
  animationData: timerAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const SoloModeGameComponent = (props) => {
  const { theme, brandId, onLeaveCallback } = props;

  const { formatMessage } = useInternationalization();
  const gameTime = useSelector(getGameTimeSelector);
  const coins = useSelector(getCoinsForQuestionSelector);
  const startTime = useMemo(() => {
    return new Date();
  }, []);

  const dispatch = useDispatch();
  const [gamePoints, setGamePoints] = useState(0);
  const pack_id = useSelector(getPackIdSelector);
  const [timerStopped, setTimerStoped] = useState(true);
  const [timerPaused, setTimerPaused] = useState(false);
  const [bonusTime, setBonusTime] = useState(0);
  const questions = useSelector(getSoloModeQuestionsSelector);
  const [myVariant, setMyVariant] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [checkedAnswers, setCheckedAnswers] = useState({ me: [] });
  const [answers, setAnswers] = useState([]);
  const [timerAnimationProgress, setTimerAnimationProgress] = useState(0);
  const [timeForGame, setTimeForGame] = useState(gameTime);
  const activeBooster = useSelector(getActiveBoosterSelector);

  const onTimerExpiredCallback = useCallback(() => {
    dispatch(soloModeActions.finishSoloMode());
    setTimerPaused(true);
  }, [dispatch]);

  const addBonusTimeCallback = useCallback(() => {
    setBonusTime(5);
    const currentTime = new Date();
    const timeLeft = gameTime * 1000 - (+currentTime - +startTime);
    let newTimerAnimationProgress = timeLeft / (gameTime * 1000);

    setTimerAnimationProgress(newTimerAnimationProgress);
    setTimeForGame(timeLeft / 1000 + 5);
  }, [setBonusTime, gameTime, startTime]);

  const onLeaveCallbackModal = useCallback(() => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('Are you sure you want to finish?'),
        btnText: formatMessage('Yes, exit'),
        onButtonClick: () => {
          amplitudeHelper.soloQuizExit();
          onLeaveCallback();
          dispatch(modalActions.closeModal());
        },
      }),
    );
  }, [dispatch, formatMessage, onLeaveCallback]);

  const nextQuestionCallback = useCallback(() => {
    if (currentQuestion + 1 === Number(questions.length)) {
      setTimerPaused(true);
      dispatch(soloModeActions.finishSoloMode());
      return;
    } else
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1);
      }, 500);
  }, [currentQuestion, dispatch, questions]);

  const onAnswerClicked = useCallback(
    (answer) => {
      if (!myVariant) {
        if (timerPaused) {
          return;
        }
        setMyVariant(answer);
        const currentAnswers = [...answers];
        currentAnswers.push(answer);
        const answersToCheck = checkCorrectAnswers(currentAnswers, questions);
        if (answersToCheck[currentQuestion].isCorrect) {
          setGamePoints(gamePoints + coins);
        } else if (activeBooster === BOOSTERS_TYPES.SECOND_CHANCE) {
          dispatch(boostersActions.secondChanceUsed(answer));
          return;
        }
        setAnswers(currentAnswers);
        setCheckedAnswers({ me: answersToCheck });
        dispatch(soloModeActions.setAnswer({ source: ANSWER_SOURCES.ME, answer }));
        nextQuestionCallback();
      }
    },
    [
      myVariant,
      answers,
      dispatch,
      nextQuestionCallback,
      questions,
      currentQuestion,
      gamePoints,
      coins,
      timerPaused,
      activeBooster,
    ],
  );

  useEffect(() => {
    setMyVariant(null);
    setTimerStoped(false);
  }, [currentQuestion]);

  useEffect(() => {
    setMyVariant(null);
  }, [activeBooster]);

  useFullGameTimer(onTimerExpiredCallback, timeForGame, dispatch);

  useEffect(() => {
    analyticHelper.soloModeQuestions(currentQuestion + 1);
  }, [currentQuestion]);

  if (!questions) {
    onLeaveCallback();
    return null;
  }

  return (
    <div>
      <div className="quiz-page__game">
        <div className="quiz-page__leave-game" onClick={onLeaveCallbackModal}>
          <PlusIcon className="quiz-page__leave-game-icon" style={{ fill: theme.color }} />{' '}
          {formatMessage('Tap to exit')}
        </div>

        <div className="solo-mode-game__points"> {`${formatMessage('Points earned:')} ${gamePoints}`}</div>

        <div className="quiz-page__timer-animation">
          <LottieWrapper
            isClickToPauseDisabled
            options={timerAnimationData}
            width={'auto'}
            isStopped={timerStopped}
            isPaused={timerPaused}
            style={{ margin: 'auto', maxWidth: '340px' }}
            speed={14.93 / gameTime}
            framesToAdd={bonusTime / gameTime}
            animationProgress={timerAnimationProgress}
          />
        </div>

        <RenderGame
          currentQuestion={questions[currentQuestion]}
          questions={questions}
          currentQuestionNumber={currentQuestion}
          theme={theme}
          myVariant={myVariant}
          onAnswerClicked={onAnswerClicked}
          brandId={brandId}
          checkedAnswers={checkedAnswers}
          withoutOpponent={true}
          withBoosters={process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY}
        />
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
          <Boosters
            pack_id={pack_id}
            solo_mode
            currentQuestion={questions[currentQuestion]}
            myVariant={myVariant}
            setMyAnswerCallback={onAnswerClicked}
            roundTime={15}
            addBonusTimeCallback={addBonusTimeCallback}
          />
        )}
      </div>
    </div>
  );
};

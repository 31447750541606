import './RadioButton.scss';

import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const InnerButton = (props) => {
  const { input, customclass } = props;
  return (
    <div className="radio-button__container">
      <input
        id={input.name + ' radioButton'}
        type="checkbox"
        value={input.value}
        className={classnames('radio-button', customclass)}
        onChange={input.onChange}
      />
      <label className="radio-label " htmlFor={input.name + ' radioButton'} />
    </div>
  );
};

export const RadioButton = (props) => <Field component={InnerButton} value={props.startValue} {...props} />;

RadioButton.defaultProps = {
  startValue: false,
};

RadioButton.propTypes = {
  customclass: PropTypes.string,
};

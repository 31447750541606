export const getStorageItem = (key: string): any => {
  const item = localStorage.getItem(key);
  if (item) {
    try {
      return JSON.parse(item);
    } catch (error) {
      return item;
    }
  }
  return null;
};

export const setStorageItem = (key: string, value: any): void => {
  const stringifiedValue = JSON.stringify(value);
  localStorage.setItem(key, stringifiedValue);
};

export const removeStorageItem = (key: string): void => {
  localStorage.removeItem(key);
};

import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import './oppaCreateWallet.scss';

import { ArrowButton } from '../../../../components/common/ArrowButton/ArrowButton';
import { BackArrowButton } from '../../../../components/common/BackArrowButton/BackArrowButton';
import { Input } from '../../../../components/common/Input';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { oppaPersonalNumber, required, oppaAccountNumber } from '../../../../helpers/formValidations';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { getIsFetching } from '../../../../redux/bonusShop/bonusShopSelectors';
import { ContentSpinner } from '../../../../components/common/Spinner/ContentSpinner';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

export let OppaInputDate = (props) => {
  const { handleSubmit, invalid } = props;
  const dispatch = useDispatch();
  const dataWallet = useSelector((s) => s.form?.oppaSetWallet?.values);
  const isFetching = useSelector(getIsFetching);
  const { formatMessage } = useInternationalization();

  const handleNextStep = () => {
    dispatch(
      bonusShopActions.setOppaWallet({
        personal_number: dataWallet.personal_number,
        wallet_number: dataWallet.wallet_number,
      }),
    );
  };

  const handlePreviousStep = () => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.oppaStepList }));
  };
  return (
    <div className="oppa-create-wallet__linkWallet">
      {isFetching ? (
        <ContentSpinner />
      ) : (
        <>
          <BackArrowButton onHistoryReplaceCallback={handlePreviousStep} customClass="oppa__back-button-black" />
          <div className="oppa-create-wallet__linkWallet-header">{formatMessage('Enter your data')}</div>
          <div className="oppa-create-wallet__linkWallet-description">{formatMessage('Use only digits')}</div>
          <form onSubmit={handleSubmit} className="oppa-create-wallet__linkWallet-form">
            <Input
              name="personal_number"
              placeholder={formatMessage('Personal number')}
              type="number"
              pattern="[0-9]*"
              validate={[required, oppaPersonalNumber]}
              customClass="signup-page__input oppa-create-wallet__linkWallet-form-input"
            />
            <Input
              name="wallet_number"
              placeholder={formatMessage('Account number')}
              type="number"
              pattern="[0-9]*"
              validate={[required, oppaAccountNumber(5, 8, formatMessage)]}
              customClass="signup-page__input oppa-create-wallet__linkWallet-form-input"
            />
          </form>
          <div className="oppa-create-wallet__linkWallet-buttonBox">
            <ArrowButton
              customClass={`oppa-create-wallet__linkWallet-button${invalid ? '-disabled' : ''}`}
              withoutTheme
              disabled={invalid}
              onClick={handleNextStep}
            />
          </div>
          <DisclaimerFooter customStyle={{ position: 'static' }} />
        </>
      )}
    </div>
  );
};
OppaInputDate = reduxForm({
  form: 'oppaSetWallet',
  destroyOnUnmount: false,
  onSubmit: () => {},
})(OppaInputDate);

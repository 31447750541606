import { push } from 'react-router-redux';
import { all, put, takeLatest } from '@redux-saga/core/effects';

import { chatApi } from '../../../api/chatApi';
import { supportActions } from './supportSlice';
import { mapTicket, mapTickets } from '../chatServices';
import { ChatRoutes } from '../../../const';

function* fetchTickets() {
  yield put(supportActions.setIsFetching(true));

  try {
    const { data } = yield chatApi.getTickets();

    const tickets = mapTickets(data.tickets);
    yield put(supportActions.ticketsReceived(tickets));
  } catch (e) {
  } finally {
    yield put(supportActions.setIsFetching(false));
  }
}

function* createTicket() {
  yield put(supportActions.setIsFetching(true));

  try {
    const { data } = yield chatApi.createTicket();

    const { id } = mapTicket(data);

    yield put(push(`${ChatRoutes.Support}/${id}`));
  } catch (e) {
  } finally {
    yield put(supportActions.setIsFetching(false));
  }
}

export function* supportSagas() {
  yield all([takeLatest(supportActions.fetchTickets.type, fetchTickets)]);
  yield all([takeLatest(supportActions.createTicket.type, createTicket)]);
}

import { APP_TYPES } from '../const/appConstants';

export const isTaiwan = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.TAIWAN;
export const isTabaterraAZ = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.TABATERRA_AZ;
export const isMybrandAZ = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.MYBRAND_AZ;
export const isIreland = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND;
export const isHungary = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;
export const isBelarus = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS;
export const isAzerbaijan = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.AZERBAIJAN;
export const isArmenia = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA;
export const isGeorgia = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA;
export const isDemo = (): boolean => process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO;

export const isCaucasus = isArmenia() || isAzerbaijan() || isGeorgia();

import { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { withRouter } from 'react-router-dom';

import '../profilePage.scss';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ProfileIreland } from './profileIreland';
import { Progress } from '../progress/progress';
import { NavigationMenu } from '../../../components';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';
import { Routes } from '../../../const';
// import { BaStatistic } from '../baStatistic/BaStatistic';
import { isHungary } from '../../../helpers';
import { checkUserRole } from '../../../helpers/checkUserRole';
import { UserRoles } from '../../../enums';

const ProfilePageComponent = ({ history, location }) => {
  const [activePage, setActivePage] = useState(0);
  const { formatMessage } = useInternationalization();

  const isBA = checkUserRole(UserRoles.BA);

  const setCurrentLocation = (index) => {
    switch (index) {
      case 0:
        history.push(Routes.ProgressRoute);
        return;
      case 1:
        history.push(Routes.ProfileRoute);
        return;
      // case 2:
      //   history.push(Routes.BaStatistic);
      //   return;
      default:
        return;
    }
  };

  const currentIndexOfPage = (location) => {
    switch (location.pathname) {
      case Routes.ProgressRoute:
        return 0;
      case Routes.ProfileRoute:
        return 1;
      // case isHungary() && isBA && Routes.BaStatistic:
      //   return 2;
      default:
        return 0;
    }
  };

  useEffect(() => {
    setActivePage(currentIndexOfPage(location));
  }, [location]);

  return (
    <div className="profile-page">
      <ScrollBlock fromTop>
        <div className="profile-page__swipeable-header">
          <div
            onClick={() => {
              setCurrentLocation(0);
            }}
            className={activePage === 0 ? 'profile-page__active-tab' : 'profile-page__tab'}
          >
            {formatMessage('Progress')}
          </div>
          <div
            onClick={() => {
              amplitudeHelper.openProfile();
              setCurrentLocation(1);
            }}
            className={activePage === 1 ? 'profile-page__active-tab' : 'profile-page__tab'}
          >
            {formatMessage('Profile')}
          </div>
          {isHungary() && isBA && (
            <div
              onClick={() => {
                amplitudeHelper.openProfile();
                setCurrentLocation(2);
              }}
              className={activePage === 2 ? 'profile-page__active-tab' : 'profile-page__tab'}
            >
              {formatMessage('Ba Statistic')}
            </div>
          )}
        </div>
        <SwipeableViews
          index={activePage}
          id="swipable-views"
          onSwitching={(index) => setCurrentLocation(index)}
          className="profile-page__views"
        >
          <Progress location={location} />
          {/* {isHungary() && isBA ? <BaStatistic /> : <></>} */}
          <ProfileIreland location={location} />
        </SwipeableViews>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const ProfileIrelandLayout = withRouter(ProfilePageComponent);

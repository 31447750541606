import { takeLatest, put, all } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { m10PaymentActions } from './m10Slice';
import { m10Api } from '../../api/m10Api';
import { IM10Payout, IResponseExchange, IResponseHistory, IResponsePayout } from './m10Types';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { profileActions } from '../profile/profileSlice';
import { store } from '../store';

function* payoutM10Saga(action: PayloadAction<IM10Payout>) {
  yield put(m10PaymentActions.setFetching(true));
  try {
    const response: IResponsePayout = yield m10Api.payout(action.payload);
    if (response.status === 200) {
      yield put(m10PaymentActions.setIsSuccess(true));
      yield put(modalActions.closeModal());
      // @ts-ignore
      yield put(profileActions.getUserInfo());
    }
  } catch (error: any) {
    yield put(
      modalActions.openErrorModal({
        description: error.response.data.message,
        btnText: 'Ok',
        onButtonClick: () => store.dispatch(modalActions.closeModal()),
      }),
    );
  } finally {
    yield put(m10PaymentActions.setFetching(false));
    yield put(m10PaymentActions.setIsSuccess(false));
  }
}

function* historyM10Saga() {
  yield put(m10PaymentActions.setFetching(true));
  try {
    const response: IResponseHistory = yield m10Api.getHistory();

    if (response.status === 200) {
      yield put(m10PaymentActions.setHistory(response.data));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(m10PaymentActions.setFetching(false));
  }
}

function* exchangeRateM10Saga() {
  yield put(m10PaymentActions.setFetching(true));
  try {
    const response: IResponseExchange = yield m10Api.getExchangeRate();

    if (response.status === 200) {
      yield put(m10PaymentActions.setExchangeRate(response.data.exchangeRate));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(m10PaymentActions.setFetching(false));
  }
}

export function* watchM10Saga() {
  yield all([
    takeLatest(m10PaymentActions.initPayout, payoutM10Saga),
    takeLatest(m10PaymentActions.initGetHistory, historyM10Saga),
    takeLatest(m10PaymentActions.initGetExchange, exchangeRateM10Saga),
  ]);
}

import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import './privacyPolicyUpdate.scss';

import { profileActions } from '../../redux/profile/profileSlice';
import { Button } from '../../components/common/Button';
import { getLegalDocumentsChanges } from '../../redux/profile/profileSelectors';

export const PrivacyPolicyUpdate = () => {
  const dispatch = useDispatch();
  const legalDocuments = useSelector(getLegalDocumentsChanges);

  const handleButtonClick = async () => {
    dispatch(profileActions.confirmLegalDocuments());
  };

  return (
    <div className="privacy-policy-update-page">
      <div className="privacy-policy-update__container">
        <div className="privacy-policy-update__notification">
          <div className="privacy-policy-update__notification-caption">{legalDocuments?.headline_text}</div>
          <div className="privacy-policy-update__notification-text">{legalDocuments?.main_text}</div>
        </div>
        <div className="privacy-policy-update__bottom-section">
          <div className="privacy-policy-update__bottom-section-links">
            {/* eslint-disable-next-line */}
            <a
              className={classnames('privacy-policy-update__bottom-section-links-link', 'link')}
              href={legalDocuments?.privacy_policy_link}
            >
              {legalDocuments?.privacy_policy_label}
            </a>
            {/* eslint-disable-next-line */}
            <a
              className={classnames('privacy-policy-update__bottom-section-links-link', 'link')}
              href={legalDocuments?.terms_and_conditions_link}
            >
              {legalDocuments?.terms_and_conditions_label}
            </a>
          </div>
          <Button customClass="privacy-policy-update__bottom-section-button" onClick={handleButtonClick}>
            {legalDocuments?.button_text}
          </Button>
        </div>
      </div>
    </div>
  );
};

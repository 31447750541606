import './passportStatement.scss';

import { useSelector } from 'react-redux';

import { Button } from '../../components/common/Button';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Routes } from '../../const';
import { getStatementData } from '../../redux/profile/profileSelectors';

export const PassportStatement = ({ history }) => {
  const { formatMessage } = useInternationalization();

  const { header, first_paragraph, second_paragraph, date } = useSelector(getStatementData);

  const onCancelClick = () => {
    history.replace(Routes.AddressRegistration);
  };
  const onConfirmClick = () => {
    history.replace(Routes.PassportSigning);
  };

  return (
    <div className="passport-statement-page">
      <div className="passport-statement-page__content">
        <div>
          <p className="passport-statement-page__content-title">{formatMessage('Statement')}</p>
          <p className="passport-statement-page__content-header">{header}</p>
        </div>
        <div>
          <div className="passport-statement-page__content-line" />
          <div className="passport-statement-page__content-description">
            <p className="passport-statement-page__content-description-block">{first_paragraph}</p>
            <p className="passport-statement-page__content-description-block">{second_paragraph}</p>
          </div>
          <p className="passport-statement-page__content-date">{date}</p>
        </div>
        <div>
          <Button withoutTheme customClass={'cancel-button'} onClick={onCancelClick}>
            {formatMessage('Decline')}
          </Button>
          <Button withoutTheme customClass={'confirm-button'} onClick={onConfirmClick}>
            {formatMessage('Accept')}
          </Button>
        </div>
      </div>
    </div>
  );
};

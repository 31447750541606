import axios from 'axios';

import { authActions } from '../redux/auth/authSlice';
import { modalActions } from '../components/containers/Modal/modalSlice';
import { profileActions } from '../redux/profile/profileSlice';

export const addErrorInterceptor = (store) => {
  const errorHandler = createErrorHandler(store);
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    (error) => errorHandler(error),
  );
};

export const createErrorHandler = (store) => (error) => {
  // formatMessage('Try again')
  let btnText = 'Try again';
  let description;
  let action = () => store.dispatch(modalActions.closeModal());

  if (error.message === 'Network Error') {
    // formatMessage('Internet connection is probably interrupted')
    description = 'Internet connection is probably interrupted';
    // formatMessage('OK')
    btnText = 'OK';
  }

  if (error.response && error.response.data.type === 'PASSPORT_NOT_FOUND') {
    return;
  }

  if (error.response.data.type === 'PASSPORT_REQUIRED') {
    return store.dispatch(profileActions.redirectToBSLimit());
  }
  if (error.response && error.response.status === 401) {
    store.dispatch(authActions.logout());
  }
  if (error.response && error.response.status >= 500) {
    // formatMessage('Server Error')
    description = 'Server Error';
    // formatMessage('Try again later')
    btnText = 'Try again later';
  }
  if (error.response && error.response.status === 400) {
    switch (error.response.data.type) {
      case 'USERNAME_ALREADY_TAKEN': {
        return Promise.reject(new Error('This username is already taken'));
      }
      case 'alert': {
        // formatMessage('Moderation is in progress.')
        description = error.response.data.message || 'Moderation is in progress.';
        btnText = 'OK';
        store.dispatch(
          modalActions.openPromptModal({
            btnText,
            description,
            onButtonClick: action,
          }),
        );
        return;
      }
      default:
        break;
    }
  }

  if (description) {
    store.dispatch(
      modalActions.openErrorModal({
        btnText,
        description,
        onButtonClick: action,
      }),
    );
  }
  return Promise.reject(error);
};

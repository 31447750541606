import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

import './authPage.scss';
import { AUTH_STEPS, STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { EnterPhonePageContainer } from './enterPhonePage/enterPhonePageContainer';
import { NOT_FIRST_VIEW_STORAGE_KEY } from '../../const/localStorageKeys';
import { SignUpSecondStage } from './signupPage/signUpSecondStage/index';
import PasswordEnterPage from './PasswordEnterPage/PasswordEnterPage';
import { VerifyContainer } from './verifyContainer/verifyContainer';
import { getStorageItem } from '../../helpers/localStorageHelper';
import { OnboardingPage } from './onboardingPage/onboardingPage';
import useFeatureToggles from '../../hooks/useFeatureToggles';
import { CheckDataPage } from './checkDataPage/checkDataPage';
import { authActions } from '../../redux/auth/authSlice';
import { SignUpPage } from './signupPage/index';
import { EsignPage } from './esignPage/esignPage';

export const AuthPage = (props: any) => {
  const dispatch = useDispatch();

  const { isOnboardingScreenEnabled } = useFeatureToggles();

  const isFirstVisit = !getStorageItem(NOT_FIRST_VIEW_STORAGE_KEY);

  const StepList = useMemo(() => {
    const data = [
      { id: AUTH_STEPS.ENTER_PHONE, Component: () => <EnterPhonePageContainer /> },
      { id: AUTH_STEPS.VERIFY, Component: () => <VerifyContainer /> },
      { id: AUTH_STEPS.SIGNUP, Component: () => <SignUpPage /> },
      { id: AUTH_STEPS.ESIGN, Component: () => <EsignPage /> },
      // @ts-ignore
      { id: AUTH_STEPS.SIGNUP_SECOND_STAGE, Component: () => <SignUpSecondStage /> },
      { id: AUTH_STEPS.CHECK_DATA, Component: () => <CheckDataPage /> },
      { id: AUTH_STEPS.PASSWORD_ENTER, Component: () => <PasswordEnterPage /> },
    ];

    if (isOnboardingScreenEnabled && isFirstVisit) {
      data.unshift({ id: AUTH_STEPS.ONBOARDING, Component: () => <OnboardingPage /> });
    }

    return createStepListContainer(STEP_LIST_IDS.authStepList, data);
  }, []);

  if (props.location.search && props.location.search.includes('?token=')) {
    dispatch(authActions.authByToken(props.location.search.substring(props.location.search.indexOf('?token=') + 7)));
  }
  return (
    <div className="auth-page">
      <StepList />
    </div>
  );
};

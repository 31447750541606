import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentQuizBoosters: [],
  activeBooster: null,
  boosters: [],
  shop: null,
};

const boostersSlice = createSlice({
  name: 'boosters',
  initialState,
  reducers: {
    getBoostersForQuiz(payload) {},
    setBoostersForQuiz(state, action) {
      const { boosters } = action.payload;
      return {
        ...state,
        currentQuizBoosters: boosters,
      };
    },
    useBooster(payload) {},
    updateBoostersAmount(state, action) {
      const { type, amount } = action.payload;
      const newBoostersArray = [...state.currentQuizBoosters];
      const usedBooster = state.currentQuizBoosters.findIndex((i) => i.type === type);
      newBoostersArray[usedBooster] = { ...state.currentQuizBoosters[usedBooster], amount };

      return {
        ...state,
        currentQuizBoosters: newBoostersArray,
      };
    },
    setActiveBooster(state, action) {
      const type = action.payload;

      return {
        ...state,
        activeBooster: type,
      };
    },
    secondChanceUsed(answer) {},
    getBoostersShop() {},
    setBoostersShop(state, action) {
      const { boostersShop } = action.payload;
      return {
        ...state,
        shop: boostersShop,
      };
    },
    buyBooster({ payload }) {},
    exchangeLeaf({ payload }) {},
  },
});

export const boostersActions = boostersSlice.actions;

export default boostersSlice.reducer;

export const ExclamationPointIcon = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" stroke={props.iconColor || 'white'} />
      <path
        d="M10.0045 6.68707C10.4762 6.68707 10.8522 6.30612 10.8522 5.84354C10.8522 5.37415 10.4762 5 10.0045 5C9.53284 5 9.15002 5.37415 9.15002 5.84354C9.15002 6.30612 9.53284 6.68707 10.0045 6.68707ZM9.26624 15H10.7428V7.84354H9.26624V15Z"
        fill={props.iconColor || 'white'}
      />
    </svg>
  );
};

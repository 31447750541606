import { PASSPORT_PAGES } from '../redux/profile/profileConstants';

const PASSPORT_STRINGS = {
  NAME: 'имя',
  SURNAME: 'фамилия',
  PATRONYMIC: 'отчество',
  ISSUED: 'выдавший',
  RELEASED: 'выдачи',
  PASSPORT_NUMBER: 'state',
};

export const parsePassportData = (passportData, passportPage) => {
  switch (passportPage) {
    case PASSPORT_PAGES.INFO_PAGE: {
      console.log(findName(passportData));
      console.log(findSurname(passportData));
      console.log(findPatronymic(passportData));
      console.log(findIssuedAuthority(passportData));
      console.log(findReleasedDate(passportData));
      return {
        first_name: findName(passportData),
        last_name: findSurname(passportData),
        middle_name: findPatronymic(passportData),
        authority: findIssuedAuthority(passportData),
        date_of_issue: findReleasedDate(passportData),
      };
    }
    case PASSPORT_PAGES.PHOTO_PAGE: {
      console.log(findPassportNumber(passportData));
      return {
        number: findPassportNumber(passportData),
      };
    }

    default:
      return 'default';
  }
};

const findPassportNumber = (data) => {
  const nameStringIndex = data.findIndex((i) => {
    if (i.locale) {
      return false;
    } else {
      return formatString(i.description).includes(PASSPORT_STRINGS.PASSPORT_NUMBER);
    }
  });

  console.log(nameStringIndex);

  let name = '';

  if (nameStringIndex !== -1) {
    name = data[nameStringIndex + 1].description;
  }

  if (name.length > 1) {
    return name;
  } else {
    return '';
  }
};

const findName = (data) => {
  const nameStringIndex = data.findIndex((i) => {
    if (i.locale) {
      return false;
    } else {
      return formatString(i.description).includes(PASSPORT_STRINGS.NAME);
    }
  });

  console.log(nameStringIndex);

  let name = '';

  if (nameStringIndex !== -1) {
    name = data[nameStringIndex + 3].description;
  }

  if (name.length > 1) {
    return formatStringToUCFirst(name);
  } else {
    return '';
  }
};

const findSurname = (data) => {
  const nameStringIndex = data.findIndex((i) => {
    if (i.locale) {
      return false;
    } else {
      return formatString(i.description).includes(PASSPORT_STRINGS.SURNAME);
    }
  });

  console.log(nameStringIndex);

  let name = '';

  if (nameStringIndex !== -1) {
    name = data[nameStringIndex + 3].description;
  }

  if (name.length > 1) {
    return formatStringToUCFirst(name);
  } else {
    return '';
  }
};

const findPatronymic = (data) => {
  const nameStringIndex = data.findIndex((i) => {
    if (i.locale) {
      return false;
    } else {
      return formatString(i.description).includes(PASSPORT_STRINGS.PATRONYMIC);
    }
  });

  console.log(nameStringIndex);

  let name = '';
  if (nameStringIndex !== -1) {
    name = data[nameStringIndex + 3].description;
  }

  if (name.length > 1) {
    return formatStringToUCFirst(name);
  } else {
    return '';
  }
};

const findIssuedAuthority = (data) => {
  const nameStringIndex = data.findIndex((i) => {
    if (i.locale) {
      return false;
    } else {
      return formatString(i.description).includes(PASSPORT_STRINGS.ISSUED);
    }
  });

  console.log(nameStringIndex);

  let name = '';

  if (nameStringIndex !== -1) {
    name = formatStringToUCFirst(data[nameStringIndex + 6].description);
    name += ' ' + data[nameStringIndex + 7].description;
    name += ' ' + formatStringToUCFirst(data[nameStringIndex + 8].description);
    name += ' ' + formatStringToUCFirst(data[nameStringIndex + 9].description);
  }

  if (name.length > 1) {
    return name;
  } else {
    return '';
  }
};

const findReleasedDate = (data) => {
  const nameStringIndex = data.findIndex((i) => {
    if (i.locale) {
      return false;
    } else {
      return formatString(i.description).includes(PASSPORT_STRINGS.RELEASED);
    }
  });

  console.log(nameStringIndex);

  let day,
    month,
    year = '';

  if (nameStringIndex !== -1) {
    day = data[nameStringIndex + 1].description;
    month = data[nameStringIndex + 2].description;
    year = data[nameStringIndex + 3].description;

    return year + '-' + month + '-' + day;
  } else {
    return '';
  }
};

const formatStringToUCFirst = (string) => {
  if (string) {
    const LCString = string.toLowerCase();
    return LCString.charAt(0).toUpperCase() + LCString.slice(1);
  } else {
    return '';
  }
};

const formatString = (string) => string.toLowerCase().replace(/\(.+?\)/g, '');

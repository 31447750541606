export enum UserRoles {
  ADMIN = 'Administrator',
  BA = 'BA',
  JTI_TEAM = 'JTI Team',
  USER = 'User',
  JTI_STAFF = 'JTI Staff',
  BOT = 'Bot',
  TEST = 'Test',
  RSM = 'RSM',
}

import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import './oppaCreateWallet.scss';
import backgroundCreateWallet from './backgroundCreateWallet.svg';

import { Button } from '../../../../components/common/Button';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { BackArrowButton } from '../../../../components/common/BackArrowButton/BackArrowButton';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { Routes } from '../../../../const';
import { ExclamationPointIcon } from '../../../../icons/exclamation-point-icon';
import { getIsFetching as getIsFetchingBonusShop } from '../../../../redux/bonusShop/bonusShopSelectors';
import { getIsFetching as getIsFetchingProfileInfo } from '../../../../redux/profile/profileSelectors';

import { LoadingPage } from '../../../../components/common/Spinner/LoadingPage';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { FAQ_ARTICLE_ID } from '../../../../redux/knowledgeBank/knowledgeBankConstants';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';

export const OppaStartCreateWallet = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useInternationalization();
  const isFetchingPrifileInfo = useSelector(getIsFetchingProfileInfo);
  const isFetchingBonusShop = useSelector(getIsFetchingBonusShop);

  const handlePreviousStep = () => {
    history.push(Routes.BonusShopRoute);
  };

  const handleNextStep = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.oppaStepList }));
  };

  const openHelpArticle = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jti-club.ge/OPPA/OPPA.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  return (
    <>
      {isFetchingPrifileInfo || isFetchingBonusShop ? (
        <LoadingPage />
      ) : (
        <div
          className="oppa-create-wallet__addWallet"
          style={{
            backgroundImage: `url(${backgroundCreateWallet})`,
          }}
        >
          <BackArrowButton onHistoryReplaceCallback={handlePreviousStep} customClass="oppa__back-button-white" />
          <div className="oppa-create-wallet__addWallet-container">
            <ExclamationPointIcon className="oppa-create-wallet__addWallet-container__faq" onClick={openHelpArticle} />
            <div className="oppa-create-wallet__addWallet-container-header">{formatMessage('OPPA e-wallet')}</div>
            <div className="oppa-create-wallet__addWallet-container-description">
              {formatMessage('Add your e-wallet or create a new')}
            </div>

            <Button customClass="oppa__button  oppa-create-wallet__addWallet-container-button" onClick={handleNextStep}>
              {formatMessage('Add')}
            </Button>
            <a
              className="oppa-create-wallet__addWallet-container-perx"
              href={'https://www.oppa.ge/'}
              rel="noopener noreferrer"
              target="_blank"
            >
              {formatMessage('How to create OPPA e-wallet?')}
            </a>
          </div>
          <DisclaimerFooter customStyle={{ position: 'static' }} />
        </div>
      )}
    </>
  );
};

import { object, string, ref } from 'yup';

const requiredMessage = 'common.validation.required';

export const signupMybrandSchema = object().shape({
  name: string().required(requiredMessage),
  surname: string().required(requiredMessage),
  username: string().required(requiredMessage),
  password: string().required(requiredMessage).min(5, 'common.validation.password_length'),
  passwordConfirm: string()
    .required(requiredMessage)
    .oneOf([ref('password')], 'common.validation.passwords_mismatch'),
});

import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Button } from '../../../components/common/Button';
import {
  getQuestionResultSelector,
  getAnswersSelector,
  getQuestionSelector,
} from '../../../redux/questionOfTheDay/questionOfTheDaySelectors';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getWordsFormat } from '../../../helpers/getWordsFormat';

const RESULT_TEXT = {
  // formatMessage('Good job!')
  win: ['Good job!'],
  // formatMessage('Try again tomorrow')
  lose: ['Try again tomorrow'],
};

const WIN_LOSE_COLORS = {
  winColor: '#FFFFFF',
  loseColor: '#FFFFFF',
};

export const QuestionOfTheDayFinalComponent = (props) => {
  const { theme, history } = props;
  const { formatMessage } = useInternationalization();
  const results = useSelector(getQuestionResultSelector);
  const question = useSelector(getQuestionSelector);
  const answers = useSelector(getAnswersSelector);
  const checkedAnswers = checkCorrectAnswers(answers.me, question);

  const correctAnswers = checkedAnswers.filter((i) => i.isCorrect).length;

  const onBtnClickCallback = useCallback(() => {
    history.push('/main');
  }, [history]);

  const getResultText = () => {
    switch (correctAnswers) {
      case 0: {
        return RESULT_TEXT.lose[0];
      }
      default: {
        return RESULT_TEXT.win[0];
      }
    }
  };

  return (
    <div className="quiz-page__daily-question-game-results">
      <div className="quiz-page__spec-quiz-final-body">
        {theme.logo && <div className="quiz-page__daily-question-logo">{theme.logo}</div>}
        <div
          className="quiz-page__spec-quiz-quiz-type"
          style={{ borderBottom: `2px solid ${theme.dailyQuestionBorderColor}`, color: theme.color }}
        >
          {formatMessage('Question of the day').toUpperCase()}
        </div>
        <div
          className="quiz-page__daily-question-final-points"
          style={{ color: results.coins > 0 ? WIN_LOSE_COLORS.winColor : WIN_LOSE_COLORS.loseColor }}
        >
          {`${results.coins} ${formatMessage(getWordsFormat(results.coins, 'points'))}`}
        </div>
      </div>
      <div className="quiz-page__spec-quiz-final-bottom">
        <div className="quiz-page__spec-quiz-result-text">{formatMessage(getResultText())}</div>
        <Button customClass="quiz-page__spec-quiz-result-btn" withoutTheme={true} onClick={() => onBtnClickCallback()}>
          OK
        </Button>
      </div>
    </div>
  );
};

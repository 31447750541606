import { ReactElement } from 'react';
import { useLocation } from 'react-router';

import { NavigationMenu } from '../../../../components';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';

import { ChatHeader } from '../../containers';
import styles from './NavigationWrapper.module.scss';
import classNames from 'classnames';
import { useWindowInnerHeight } from '../../../../hooks/useWindowInnerHeight';
import { BlockHeights } from '../../../../const';
import { APP_TYPES } from '../../../../const/appConstants';

type Props = {
  children: ReactElement;
  title?: string;
  isChat?: boolean;
  onBackClick?: () => void;
};

export const NavigationWrapper = ({ children, title, isChat = false, onBackClick }: Props) => {
  const location = useLocation();
  const windowHeight = useWindowInnerHeight();

  const { NavigationMenuHeight, ChatHeaderHeight, DisclaimerHeight } = BlockHeights;
  const regionsWithoutDisclaimer =
    process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS || process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;

  const navigationHeights = regionsWithoutDisclaimer
    ? NavigationMenuHeight + ChatHeaderHeight
    : NavigationMenuHeight + ChatHeaderHeight + DisclaimerHeight;
  const contentHeight = windowHeight - navigationHeights;

  return (
    <div className={classNames(styles.navigationWrapper, { [styles.white]: isChat })}>
      <ChatHeader title={title} onBackClick={onBackClick} />

      <div className={styles.content}>
        <div className={styles.messageBlock} style={{ height: contentHeight }}>
          {children}
        </div>
      </div>

      <NavigationMenu location={location} />
      <DisclaimerFooter />
    </div>
  );
};

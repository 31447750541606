import { useSelector } from 'react-redux';

import './scenarioPage.scss';

import { MODALS } from '../../components/containers/Modal/modalConstants';
import { OkIcon } from '../../icons/okIcon';
import { PlusIcon } from '../../icons/plus-icon';
import { ModalContainer } from '../../components/containers/Modal/ModalContainer';
import { getModalDataSelector } from '../../components/containers/Modal/modalSelectors';

export const ScenarioReactionModal = (props) => {
  let {
    data: { onClose, title, description, correct },
  } = useSelector(getModalDataSelector);

  const styles = {
    zIndex: 100,
    backgroundColor: correct ? ' #4CBF8F' : '#FF6666',
    opacity: 0.9,
    borderRadius: 16,
  };

  return (
    <ModalContainer modal={MODALS.scenarioReaction} onClose={onClose} customStyles={styles} crossColor="white">
      <div className="scenario-reaction">
        <div className="scenario-reaction__icon">
          {correct ? (
            <OkIcon className="scenario-reaction__icon__ok" />
          ) : (
            <PlusIcon className="scenario-reaction__icon__plus" />
          )}
        </div>
        <div className="scenario-reaction__title">{title}</div>
        <div className="scenario-reaction__description">{description}</div>
      </div>
    </ModalContainer>
  );
};

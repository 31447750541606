import axios from 'axios';

import { API_URL } from './baseApi';

export const profileApi = {
  getUserInfo: async () => {
    return await axios.get(`${API_URL}/v3/profile`);
  },

  getRating: async (filter, brand_id) => {
    let params = {};
    if (brand_id) {
      params = {
        brand_id: brand_id,
      };
    }
    return await axios.get(`${API_URL}/v3/profile/rating/${filter}`, { params });
  },
  getEarned: async (limit = 0, offset = 0) => {
    return await axios.get(`${API_URL}/v3/profile/earned?limit=${limit}&offset=${offset}`);
  },
  changeUserInfo: async (userInfo) => {
    return await axios.put(`${API_URL}/v3/profile`, {
      ...userInfo,
    });
  },
  confirmLegalDocuments: async () => {
    return await axios.post(`${API_URL}/v3/profile/legal-documents/confirm`);
  },
  updateConsentProcessingData: async (consent_processing_data) => {
    return await axios.post(
      `${API_URL}/v3/profile/consent-processing-data?consent_processing_data=${consent_processing_data}`,
    );
  },
  getBAStatistics: async () => {
    return await axios.get(`${API_URL}/v3/ba-statistics`);
  },
};

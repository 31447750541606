import { memo, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './moneyForTheWallet.scss';

import { Balance } from '../../../components/common/Balance';
import { Header } from '../../../components/common/Header';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { NavigationMenu } from '../../../components';
import { Button } from '../../../components/common/Button';
import { CashoutHistory } from '../cashoutHistory/cashoutHistory';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { getUserInfoSelector, getUserBalanceSelector } from '../../../redux/profile/profileSelectors';
import { getHeaderImageSelector } from '../../../redux/bonusShop/bonusShopSelectors';
import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { checkIsIphoneX } from '../../../helpers/checkIsIphoneX';
import { QuestionMarkIcon } from '../../../icons/question-mark-icon';
import { appConstants } from '../../../const/appConstants';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { FAQ_ARTICLE_ID } from '../../../redux/knowledgeBank/knowledgeBankConstants';
import { checkUserRole } from '../../../helpers/checkUserRole';
import { UserRoles } from '../../../enums';

export const MoneyForTheWallet = memo(({ location }) => {
  const { formatMessage } = useInternationalization();
  const { phone } = useSelector(getUserInfoSelector);
  const coins = useSelector(getUserBalanceSelector);
  const headerImage = useSelector(getHeaderImageSelector);
  const dispatch = useDispatch();

  const rate = 1;
  const isModalShown = checkUserRole(UserRoles.BA, UserRoles.JTI_TEAM, UserRoles.RSM);

  const [walletNumber, setWalletNumber] = useState();

  const openHelpArtilce = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jti-club.am/Article_BS_FAQ/Article_BS_FAQ.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(bonusShopActions.getTransfers());
  }, [dispatch]);

  useEffect(() => {
    setWalletNumber(phone?.replace(appConstants.APP_PHONE_CODE, ''));
  }, [phone]);

  useEffect(() => {
    return () => dispatch(modalActions.closeModal());
  }, [dispatch]);

  const onTransferClick = () => {
    if (isModalShown) {
      dispatch(
        modalActions.openPromptModal({
          description: 'Not available for JTI representatives.',
          btnText: 'OK',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else if (coins < 100) {
      dispatch(
        modalActions.openPromptModal({
          title: 'Ooops',
          description: 'You do not have enough coins for this order',
          btnText: 'OK transfer',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else {
      dispatch(
        modalActions.openCashoutModal({
          title: 'Choose amount',
          btnText: 'Move forward',
          symbol: '֏',
          moneyLimit: 12100,
          rate: 1,
          step: 100,
          makeTransfer: (params) => {
            dispatch(bonusShopActions.makeTransfer(params));
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  };

  return (
    <div className="relative-container">
      <div className="money-for-the-wallet-page">
        <Header hasBackButton customClass="money-for-the-wallet-page__header">
          {formatMessage('Cashout')}
        </Header>
        <Balance />
        <div className="money-for-the-wallet-page__content">
          <div
            className="money-for-the-wallet-page__content-image-container"
            style={{
              background: `url(${headerImage})`,
              backgroundSize: 'cover',
            }}
          >
            <div className="money-for-the-wallet-page__content-image-container-wallet-container">
              <QuestionMarkIcon
                className="money-for-the-wallet-page__content-image-container-faq"
                onClick={openHelpArtilce}
              />
              <p className="money-for-the-wallet-page__content-image-container-wallet-container-caption">
                {formatMessage('Your wallet number:')}
              </p>
              <p className="money-for-the-wallet-page__content-image-container-wallet-container-wallet">
                {walletNumber}
              </p>
            </div>
          </div>
          <CashoutHistory symbol="֏" rate={rate} />
        </div>
        <div
          className="money-for-the-wallet-page__content-btn-container"
          style={{ bottom: checkIsIphoneX() ? '100px' : '68px' }}
        >
          <Button customClass={'money-for-the-wallet-page__content-btn-container-button'} onClick={onTransferClick}>
            {formatMessage('Transfer')}
          </Button>
        </div>
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </div>
    </div>
  );
});

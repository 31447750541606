export const KnowledgeBaseIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_122_1132)">
        <rect width="40" height="40" rx="8" fill="#ECECEC" />
        <path
          d="M20 10L8 16.6087L12.3636 19.0099V25.6186L20 29.8261L27.6364 25.6186V19.0099L29.8182 17.8093V25.4203H32V16.6087L20 10ZM27.44 16.6087L20 20.7061L12.56 16.6087L20 12.5113L27.44 16.6087ZM25.4545 24.3078L20 27.3148L14.5455 24.3078V20.2104L20 23.2174L25.4545 20.2104V24.3078Z"
          fill="#252525"
        />
      </g>
      <defs>
        <clipPath id="clip0_122_1132">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

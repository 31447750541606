import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import './choiceCheckIn.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { CheckIcon } from '../../../icons';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { verificationActions } from '../../../redux/verification/verificationSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { VERIFICATION_MODE } from '../../../const/verificationConstants';
import { getPosition } from '../../../helpers/getPosition';
import choiceCheckInImage from './choiceCheckInImage.png';

export const ChoiceCheckIn = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const [isIconDisabled, setIconDisabled] = useState(true);
  const [selectedMode, setNewSelectedMode] = useState(0);

  const handleArrowButtonClick = useCallback(() => {
    if (navigator.geolocation && selectedMode === VERIFICATION_MODE.VERIFICATION_AUTO) {
      getPosition()
        .then((position) => {
          const crd = position.coords;
          dispatch(
            verificationActions.setVerificationMode({
              mode: selectedMode,
              geo_allowed: true,
              latitude: crd.latitude,
              longitude: crd.longitude,
            }),
          );
        })
        .catch(() => {
          dispatch(verificationActions.setVerificationMode({ mode: selectedMode, geo_allowed: false }));
        });
    } else {
      dispatch(verificationActions.setVerificationMode({ mode: selectedMode, geo_allowed: false }));
    }
  }, [dispatch, selectedMode]);

  const onBackCallback = useCallback(() => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.checkInStepList }));
  }, [dispatch]);

  const handleModeBlockClick = (selectedMode) => {
    setNewSelectedMode(selectedMode);
    setIconDisabled(false);
  };

  return (
    <div className="choice-check-in__component">
      <div className="check-in__top-section">
        <Button
          withoutTheme
          type="button"
          className="check-in__top-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded fillColor="#fff" className="check-in__top-arrow" />
        </Button>
        <div className="check-in__img-section">
          <img alt="img" src={choiceCheckInImage} className="check-in__image" />
        </div>
        <h1 className="check-in__title">{formatMessage('How do you want to go through check-in?')}</h1>
        <div className="choice-check-in__select-section">
          <div
            className={`choice-check-in__${
              selectedMode === VERIFICATION_MODE.VERIFICATION_AUTO ? 'selected-mode' : 'mode'
            }`}
            onClick={() => handleModeBlockClick(VERIFICATION_MODE.VERIFICATION_AUTO)}
          >
            <div>
              <h2 className="choice-check-in__bold-text">{formatMessage('I trust jti-club')}</h2>
              <p className="choice-check-in__text">{formatMessage('Automatically')}</p>
            </div>
            {selectedMode === VERIFICATION_MODE.VERIFICATION_AUTO && (
              <div className="choice-check-in__check-icon-container">
                <CheckIcon className="choice-check-in__check-icon" />
              </div>
            )}
          </div>

          <div
            className={`choice-check-in__${
              selectedMode === VERIFICATION_MODE.VERIFICATION_MANUAL ? 'selected-mode' : 'mode'
            }`}
            onClick={() => handleModeBlockClick(VERIFICATION_MODE.VERIFICATION_MANUAL)}
          >
            <div>
              <h2 className="choice-check-in__bold-text">{formatMessage('Independently')}</h2>
              <p className="choice-check-in__text">{formatMessage('Manually')}</p>
            </div>
            {selectedMode === VERIFICATION_MODE.VERIFICATION_MANUAL && (
              <div className="choice-check-in__check-icon-container">
                <CheckIcon className="choice-check-in__check-icon" />
              </div>
            )}
          </div>
        </div>
        <div className="check-in__bot-section">
          <ArrowButton withoutTheme disabled={isIconDisabled} onClick={() => handleArrowButtonClick()} />
        </div>
      </div>
    </div>
  );
};

import { memo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import './tutorialFirstStep.scss';

import { Button } from '../../../components/common/Button';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import { SkipIcon } from '../../../icons/skip-icon';

export const TutorialFirstStep = memo(() => {
  const dispatch = useDispatch();
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { formatMessage } = useInternationalization();

  useEffect(() => {
    let timer;
    if (isButtonClicked) {
      timer = setTimeout(
        () =>
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.transferTutorialStepList,
              stepId: 1,
            }),
          ),
        150,
      );
    }
    if (timer) {
      return () => clearTimeout(timer);
    }
  }, [isButtonClicked, dispatch]);

  const skipButtonAnimation = useSpring({
    opacity: isButtonClicked ? 0 : 1,
    from: { opacity: isButtonClicked ? 0 : 1 },
    config: { duration: 150 },
  });

  const contentAnimation = useSpring({
    bottom: '30px',
    left: isButtonClicked ? '-400px' : '0px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      bottom: isButtonClicked ? '30px' : '-600px',
      left: '0px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const onSkipClick = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure you want to finish?',
        btnText: 'Yes, exit',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(bonusShopActions.closeTutorial());
        },
        onClose: () => dispatch(modalActions.closeModal()),
      }),
    );
  };

  const onStartClick = () => {
    setIsButtonClicked(true);
  };

  return (
    <div className="transfer-tutorial-first-step">
      <animated.div
        style={skipButtonAnimation}
        className="transfer-tutorial-first-step__skip-button"
        onClick={onSkipClick}
      >
        <div className="transfer-tutorial-first-step__skip-button-content">
          <p className="transfer-tutorial-first-step__skip-button-content-text">{formatMessage('Skip')}</p>
          <SkipIcon />
        </div>
      </animated.div>

      <animated.div style={contentAnimation} className="transfer-tutorial-first-step__content">
        <div className="transfer-tutorial-first-step__description">
          <p className="transfer-tutorial-first-step__description-title">
            {formatMessage('How to cash out money to the phone')}
          </p>
          <p className="transfer-tutorial-first-step__description-text1">
            {formatMessage('You could cash out your coins for your telephone. Here is a tutorial how to do that.')}
          </p>
          <p className="transfer-tutorial-first-step__description-text2">
            {formatMessage('Tap the button below to start tutorial or “skip” to proceed')}
          </p>
        </div>

        <div className="transfer-tutorial-first-step__btn-container">
          <Button withoutTheme customClass={'start-button'} onClick={onStartClick}>
            {formatMessage('Start tutorial')}
          </Button>
        </div>
      </animated.div>
    </div>
  );
});

import { useSelector } from 'react-redux';
import classnames from 'classnames';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { analyticHelper } from '../../helpers/analyticHelper';
import { getWordsFormat } from '../../helpers/getWordsFormat';
import { GAME_MODES } from '../../redux/settings/settingsConstants';
import { getGameModeSelector } from '../../redux/settings/settingsSelector';
import { getCurrentComponentSelector } from '../../redux/mainPage/mainPageSelectors';
import { COMPONENT_TYPES } from '../../const/mainScreenConsts';
import { amplitudeHelper } from '../../helpers/ampitudeHelper';
import QuizCard from '../quizCard';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';
import './brandCard.scss';

export const BrandCard = (props) => {
  const { history, brand } = props;
  const { formatMessage } = useInternationalization();
  const gameMode = useSelector(getGameModeSelector);
  const currentComponent = useSelector(getCurrentComponentSelector);
  const { description, text_color, count, image, logo, quiz_tagline } = brand;

  const onCardClickCallback = (brand_id) => {
    if (!currentComponent.payload.brand_id) {
      if (currentComponent.type === COMPONENT_TYPES.SOLO_QUIZ) {
        analyticHelper.fromMainScreenSolo(brand_id);
        amplitudeHelper.soloQuizChooseBrand(brand);
      }
      if (currentComponent.type === COMPONENT_TYPES.QUIZ_WITH_USER) {
        analyticHelper.fromMainScreenH2H(brand_id);
        amplitudeHelper.h2hQuizChooseBrand(brand);
      }
    }
  };

  const chooseBrandCard = (bonusGamesAmount) => {
    if (isFeatureEnabled(CONFIG_KEYS.NON_BONUS_VIEW_ENABLED) && bonusGamesAmount === 0) {
      history.push(`/no-bonus-games-info/${brand.id}`);
    } else {
      if (gameMode === GAME_MODES.SOLO) {
        history.push(`/solo-mode/${brand.id}`);
      } else {
        history.push(`/quiz/${brand.id}`);
      }
    }

    if (currentComponent) {
      if (
        currentComponent.type === COMPONENT_TYPES.SOLO_QUIZ ||
        currentComponent.type === COMPONENT_TYPES.QUIZ_WITH_USER
      ) {
        onCardClickCallback(brand.id);
        return;
      }
    }
    analyticHelper.topicSelect(brand.id);
  };

  const card_description = count
    ? formatMessage(getWordsFormat(count, 'bonus games to play'))
    : formatMessage('Play for fun and improve your ratings');

  if (!image) {
    return null;
  }

  if (isFeatureEnabled(CONFIG_KEYS.QUIZ_CARD_VIEW_ENABLED)) {
    return (
      <QuizCard
        title={quiz_tagline}
        brand={brand}
        onClick={() => chooseBrandCard(count)}
        showBrand={!!quiz_tagline}
        cardDescription={card_description}
      />
    );
  }

  return (
    <div
      className="brand-block"
      style={{
        backgroundImage: `url(${image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        color: text_color,
      }}
      onClick={() => {
        chooseBrandCard(count);
      }}
    >
      <p className={'brand-block__name'}>{description}</p>
      <div className="brand-block__footer">
        <div className="brand-block__footer-info">
          {count ? (
            <>
              <div className="brand-block__footer-info-bonus-games-amount">
                <p className="brand-block__footer-info-bonus-games-amount-caption">{count}</p>
              </div>
              <p className="brand-block__footer-info-caption">
                {formatMessage(getWordsFormat(count, 'bonus games to play'))}
              </p>
            </>
          ) : (
            <p
              className={classnames(
                'brand-block__footer-info-caption',
                'brand-block__footer-info-no-bonus-games-caption',
              )}
            >
              {formatMessage('Play for fun and improve your ratings')}
            </p>
          )}
        </div>
        {logo && <img className="brand-block__footer-logo" alt="img" src={logo} />}
      </div>
    </div>
  );
};

export const BoxRightArrow = ({ fillColor = '#fff', ...props }) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 2L12.2716 8.08346C13.191 8.85264 13.232 10.2523 12.359 11.0738L5 18"
        stroke={fillColor}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

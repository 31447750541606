import { useEffect, useState, useRef, memo, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import styles from './swipeablePages.module.scss';
import { register } from 'swiper/element/bundle';
import { BonusShop } from '../bonusShop';
import { Prizes } from '../prizes';
import { profileActions } from '../../redux/profile/profileSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { NavigationMenu } from '../../components/navigationMenu';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { Balance } from '../../components/common/Balance';
import { getIsFetching } from '../../redux/bonusShop/bonusShopSelectors';
import MyVouchers from '../MyVouchers/MyVouchers';
import { BalanceTransactions } from '../../components/balanceTransactions';
import { Routes } from '../../const';
import { LoadingPage } from '../../components/common/Spinner/LoadingPage';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';

const Tab = memo(({ isActive, title, onClick }) => (
  <div onClick={onClick} className={isActive ? 'tab-active' : 'tab'}>
    {title}
  </div>
));

const SwipeablePage = ({ history, location }) => {
  const { pathname } = location;

  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const tabs = useMemo(() => {
    const tabs = [
      { path: Routes.BonusShopRoute, title: 'Bonus shop' },
      { path: Routes.BalanceRoute, title: 'Balance' },
    ];

    isFeatureEnabled(CONFIG_KEYS.BONUS_SHOP_PRIZES_TAB_ENABLED) &&
      tabs.push({ path: Routes.PrizesRoute, title: 'Prizes' });

    isFeatureEnabled(CONFIG_KEYS.BONUS_SHOP_VOUCHERS_TAB_ENABLED) &&
      tabs.push({ path: Routes.MyVouchers, title: 'bonus_shop.my_vouchers' });

    return tabs;
  }, []);

  const getIndexOfTab = (path) => tabs.indexOf(tabs.find((tab) => tab.path === path));

  const [activePage, setActivePage] = useState(getIndexOfTab(pathname));
  const isFetching = useSelector(getIsFetching);
  const swiperRef = useRef(null);

  useEffect(() => {
    register();

    Object.assign(swiperRef.current, {
      slidesPerView: 1,
      initialSlide: getIndexOfTab(location.pathname),
    });

    swiperRef.current.initialize();
    swiperRef.current.addEventListener('slidechange', () => setActivePage(swiperRef.current.swiper.activeIndex));
  }, []);

  useEffect(() => {
    dispatch(profileActions.resetEarner());
  }, [dispatch]);

  useEffect(() => {
    setCurrentLocation(activePage);
    swiperRef.current?.swiper.slideTo(activePage);
  }, [activePage]);

  const setCurrentLocation = (index) => {
    const { push } = history;

    return push(tabs[index].path);
  };

  if (isFetching) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.root}>
      <div className="header">
        <div className="tabs themed-background">
          {tabs.map(({ title, path }, index) => (
            <Tab
              key={path}
              isActive={activePage === index}
              title={formatMessage(title)}
              onClick={() => setActivePage(index)}
            />
          ))}
        </div>
        <Balance activePage={activePage} />
      </div>
      <swiper-container init="false" ref={swiperRef}>
        <swiper-slide class="slide">
          <BonusShop location={location} history={history} activePage={activePage} />
        </swiper-slide>
        <swiper-slide class="slide">
          <BalanceTransactions location={location} activePage={activePage} />
        </swiper-slide>
        {isFeatureEnabled(CONFIG_KEYS.BONUS_SHOP_PRIZES_TAB_ENABLED) && (
          <swiper-slide class="slide">
            <Prizes location={location} />
          </swiper-slide>
        )}
        {isFeatureEnabled(CONFIG_KEYS.BONUS_SHOP_VOUCHERS_TAB_ENABLED) && (
          <swiper-slide class="slide">
            <MyVouchers isActive={pathname === Routes.MyVouchers} />
          </swiper-slide>
        )}
      </swiper-container>
      <NavigationMenu location={location} />
      <DisclaimerFooter />
    </div>
  );
};

export const SwipeablePages = withRouter(SwipeablePage);

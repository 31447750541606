import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';
import { Routes } from '../../../const';
import './demoTransferMoney.scss';
import { Balance } from '../../../components/common/Balance';
import { Header } from '../../../components/common/Header';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { Button } from '../../../components/common/Button';
import { LoadingPage } from '../../../components/common/Spinner/LoadingPage';
import { DemoCashoutHistory } from '../cashoutHistory/demoCashoutHistory/demoCashoutHistory';
import backgroundImage from './bg-img.jpg';
import { getIsFetching as getIsFetchingProfileInfo } from '../../../redux/profile/profileSelectors';

const CashoutDemo = () => {
  const { formatMessage } = useInternationalization();
  const isFetching = useSelector(getIsFetchingProfileInfo);
  const history = useHistory();

  const handlePreviousStep = () => {
    history.push(Routes.BonusShopRoute);
  };

  return (
    <div className="relative-container">
      {isFetching ? (
        <LoadingPage />
      ) : (
        <div className="demo-money-for-the-card-page">
          <Header goBackCallback={handlePreviousStep} hasBackButton customClass="demo-money-for-the-card-page__header">
            {formatMessage('Cashout')}
          </Header>
          <Balance />
          <div className="demo-money-for-the-card-page__content">
            <div
              className="demo-money-for-the-card-page__content-image-container"
              style={{
                background: `url(${backgroundImage})`,
                backgroundSize: 'cover',
              }}
            >
              <div className="demo-money-for-the-card-page__content-image-container-wallet-container">
                <p className="demo-money-for-the-card-page__content-image-container-wallet-container-caption">
                  E Wallet
                </p>
                <p className="demo-money-for-the-card-page__content-image-container-wallet-container-label">
                  1245 6521 2266
                </p>
              </div>
            </div>
            <DemoCashoutHistory />
          </div>
          <div
            style={{ bottom: isIPhoneWithHomeBar() ? '49px' : '15px' }}
            className="demo-money-for-the-card-page__content-btn-container"
          >
            <Button customClass={'demo-money-for-the-card-page__content-btn-container-button'} disabled>
              {formatMessage('Transfer')}
            </Button>
          </div>
          <DisclaimerFooter withoutNavigation />
        </div>
      )}
    </div>
  );
};

export default CashoutDemo;

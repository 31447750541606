import { io } from 'socket.io-client';
import { WS_URL } from './baseApi';
import { gameActions } from '../redux/game/gameSlice';
import { knowledgeBankActions } from '../redux/knowledgeBank/knowledgeBankSlice';
import { authActions } from '../redux/auth/authSlice';
import { unexpectedRewardsActions } from '../redux/unexpectedRewards/unexpectedRewardsSlice';
import { modalActions } from '../components/containers/Modal/modalSlice';
import { chatActions as newChatActions } from '../redux/chat';

export const Socket = {
  socket: null,
  connect: function (store) {
    this.socket = io(`${WS_URL}?token=${localStorage.getItem('authToken')}`);
    this.socket.on('connect', () => {});
    this.socket.on('invitetogame', (data) => {
      console.log(`socket "invitegame". payload: ${data}`);
      store.dispatch(gameActions.onInvite(JSON.parse(data)));
    });
    this.socket.on('gameanswer', (data) => {
      console.log(`socket "gameanswer". payload: ${data}`);
      const answer = JSON.parse(data);
      store.dispatch(gameActions.setOpponentAnswer({ answer }));
    });
    this.socket.on('invitation', (data) => {
      console.log(`socket "invitation". payload: ${data}`);
      const answer = JSON.parse(data);
      if (answer.type === 'accept') {
        store.dispatch(gameActions.opponentInvitedSuccess());
      } else if (answer.type === 'reject') {
        store.dispatch(gameActions.opponentInvitedFailed());
      }
    });
    this.socket.on('*', (data) => {
      console.log(data);
    });
    this.socket.on('sentmessage', (data) => {
      console.log(`socket "sentmessage". payload: ${data}`);
      const message = JSON.parse(data).message;

      store.dispatch(newChatActions.handleSocket(message));
    });
    this.socket.on('new-reward', (data) => {
      console.log(`socket "new reward". payload: ${data}`);
      store.dispatch(unexpectedRewardsActions.setUnexpectedRewardsSocket(data));
    });
    this.socket.on('new-article', (data) => {
      console.log(`socket "new-article". payload: ${data}`);
      store.dispatch(knowledgeBankActions.socketHandle(data));
    });
    this.socket.on('logout', () => {
      store.dispatch(authActions.logout());
    });
    this.socket.on('achievementreward', (data) => {
      const parsedData = JSON.parse(data);
      console.log(parsedData);
      store.dispatch(
        modalActions.openAchievementModal({
          achievement_name: parsedData.achievement_name,
          icon_image: parsedData.icon_image,
          level: parsedData.level,
          id: parsedData.achievement_id,
        }),
      );
    });
  },
  disconnect: function () {
    this.socket && this.socket.disconnect();
  },
  acceptInvite: function (data) {
    this.socket.emit('invitation', data.user.id, JSON.stringify({ type: 'accept', request_id: data.request_id }));
  },
  declineInvite: function (data) {
    this.socket.emit('invitation', data.user.id, JSON.stringify({ type: 'reject', request_id: data.request_id }));
  },
  sendAnswer: function ({ payload }) {
    this.socket.emit(
      'gameanswer',
      payload.opponent_id,
      JSON.stringify({
        question_id: payload.questionInfo.id,
        question_num: payload.questionInfo.number - 1,
        answer: payload.answer,
        points: payload.points,
      }),
    );
  },
};

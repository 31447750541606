import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  reward: null,
};

const unexpectedRewardsSlice = createSlice({
  name: 'unexpectedRewards',
  initialState,
  reducers: {
    openModal(payload) {},
    setUnexpectedRewardsSocket(state, { payload }) {
      return {
        ...state,
        reward: { ...payload },
      };
    },
    resetRewards() {
      return {
        ...initialState,
      };
    },
  },
});

export const unexpectedRewardsActions = unexpectedRewardsSlice.actions;
export const unexpectedRewardsReducer = unexpectedRewardsSlice.reducer;

import { useEffect, useRef, useState } from 'react';
import lottie from 'lottie-web';

export const LottieWrapper = (props) => {
  const {
    options,
    speed,
    style,
    width,
    height,
    isStopped,
    isPaused,
    maxProgress,
    framesToAdd,
    animationProgress,
    duration,
    onAnimationFinish,
  } = props;
  const { loop, autoplay, animationData, rendererSettings } = options;
  const animationRef = useRef(null);
  const [animationInstance, setAnimationInstance] = useState({});
  const [isMaxPosition, setIsMaxPosition] = useState(false);
  const [maxFramesCount, setMaxFramesCount] = useState();

  useEffect(() => {
    const container = animationRef.current;

    const animation = lottie.loadAnimation({
      name: 'myAnimation',
      container: container,
      renderer: 'svg',
      loop,
      autoplay,
      animationData,
      rendererSettings,
    });

    setAnimationInstance(animation);

    lottie.setSpeed(speed);
  }, [loop, autoplay, animationData, speed, height, width, rendererSettings]);

  useEffect(() => {
    if (Object.entries(animationInstance).length !== 0) {
      setMaxFramesCount(animationInstance.getDuration(true));
    }
  }, [animationInstance]);

  useEffect(() => {
    if (isStopped) {
      lottie.stop();
    }
  }, [isStopped]);

  useEffect(() => {
    if (isPaused) {
      lottie.pause();
    }
  }, [isPaused]);

  useEffect(() => {
    if (!isPaused && !isStopped && !maxProgress) {
      lottie.play();

      if (duration) {
        setTimeout(() => {
          lottie.stop();
          onAnimationFinish && onAnimationFinish();
        }, duration);
      }
    }
  }, [isPaused, isStopped, maxProgress]);

  useEffect(() => {
    if (maxProgress === 0) {
      lottie.stop();
      return;
    }
    if (maxProgress && Object.entries(animationInstance).length !== 0 && !isMaxPosition) {
      const framesCount = animationInstance.getDuration(true);
      animationInstance.playSegments([animationInstance.firstFrame, (framesCount * maxProgress) / 100], true);
      setIsMaxPosition(true);
    }
  }, [maxProgress, animationInstance, isMaxPosition]);

  useEffect(() => {
    if (framesToAdd && Object.entries(animationInstance).length !== 0) {
      const framesCount = animationInstance.getDuration(true);
      const frameToStart =
        ((animationProgress + framesToAdd) * framesCount).toFixed() > framesCount
          ? framesCount
          : ((animationProgress + framesToAdd) * framesCount).toFixed();

      animationInstance.playSegments([framesCount - frameToStart, framesCount], true);
      setIsMaxPosition(true);
    }
  }, [animationInstance, framesToAdd, maxFramesCount, animationProgress]);

  return (
    <div
      ref={animationRef}
      style={{ overflow: 'hidden', margin: '0 auto', outline: 'none', width, height, ...style }}
    />
  );
};

LottieWrapper.defaultProps = {
  speed: 1,
  loop: false,
  autoplay: false,
  isStopped: false,
  isPaused: false,
};

import { takeLatest, select, put, all } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';

import { noInvitePaths } from '../game/gameSagas';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';
import { getUnexpectedRewardsSelector } from './unexpectedRewardsSelectors';
import { unexpectedRewardsActions } from './unexpectedRewardsSlice';

function* showUnexpectedRewardsModalSaga() {
  try {
    const locationSelector = (state) => state.router.location.pathname;
    const path = yield select(locationSelector);
    const unexpectedReward = yield select(getUnexpectedRewardsSelector);

    if (!unexpectedReward) {
      return;
    }
    const notAvailablePath = noInvitePaths.find((element) => path.includes(element));
    if (notAvailablePath) {
      return;
    } else {
      yield put(
        modalActions.openUnexprectedRewardsModal({
          // formatMessage('OK')
          btnText: 'OK',
          onButtonClick: () => {
            store.dispatch(unexpectedRewardsActions.resetRewards());
            store.dispatch(modalActions.closeModal());
          },
          onClose: () => {
            store.dispatch(unexpectedRewardsActions.resetRewards());
            store.dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* sagas() {
  yield all([
    takeLatest(unexpectedRewardsActions.setUnexpectedRewardsSocket, showUnexpectedRewardsModalSaga),
    takeLatest(LOCATION_CHANGE, showUnexpectedRewardsModalSaga),
  ]);
}

export const unexpectedRewardsSagas = sagas;

import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AnswerRows } from '../AnswersRow/AnswersRow';
import { Button } from '../../../components/common/Button';
import {
  getSpecialQuizResultSelector,
  getAnswersSelector,
  getQuestionsSelector,
  getQuizStatusSelector,
  getSpecialQuizModeSelector,
} from '../../../redux/specialQuizes/specialQuizSelectors';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { getUnverificationInfoForBelarus } from '../../../redux/verification/verificationSelectors';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { SPECIAL_QUIZ_MODS } from '../../../redux/specialQuizes/specialQuizesConstants';
import { getWordsFormat } from '../../../helpers/getWordsFormat';
import { Routes } from '../../../const';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../config';

const RESULT_TEXT = {
  total_win: ['Amazing'],
  win: ['Good job!'],
  lose: ['Refresh your memory and try again'],
};

const WIN_LOSE_COLORS = {
  winColor: '#4CBF8F',
  loseColor: '#FF6666',
};

export const SpecialQuizFinalComponent = (props) => {
  const { theme, history, brand } = props;
  const results = useSelector(getSpecialQuizResultSelector);
  const questions = useSelector(getQuestionsSelector);
  const answers = useSelector(getAnswersSelector);
  const is_new = useSelector(getQuizStatusSelector);
  const specQuizMode = useSelector(getSpecialQuizModeSelector);
  const checkedAnswers = checkCorrectAnswers(answers.me, questions);
  const { formatMessage } = useInternationalization();
  const isUnVerifiedBelarus = useSelector(getUnverificationInfoForBelarus);

  const correctAnswers = checkedAnswers.filter((i) => i.isCorrect).length;

  const onBtnClickCallback = useCallback(() => {
    if (specQuizMode === SPECIAL_QUIZ_MODS.SALES_TIP) {
      history.goBack();
      return;
    }
    if (correctAnswers > 0) {
      history.replace(Routes.BonusShopRoute);
    } else {
      history.replace(Routes.KnowledgeBankRoute);
    }
  }, [correctAnswers, history, specQuizMode]);

  const getResultText = () => {
    switch (correctAnswers) {
      case 0: {
        return RESULT_TEXT.lose[0];
      }
      case questions.length: {
        return RESULT_TEXT.total_win[0];
      }
      default: {
        return specQuizMode === SPECIAL_QUIZ_MODS.SALES_TIP ? RESULT_TEXT.lose[0] : RESULT_TEXT.win[0];
      }
    }
  };

  let finishButtonText;
  if (specQuizMode === SPECIAL_QUIZ_MODS.SALES_TIP) {
    finishButtonText = formatMessage('OK');
  } else {
    finishButtonText =
      correctAnswers > 0
        ? formatMessage('Bonus shop after spec quiz')
        : formatMessage('Knowledge bank after spec quiz');
  }

  const getSpecQuizResult = () => {
    if (specQuizMode === SPECIAL_QUIZ_MODS.SALES_TIP) {
      if (is_new && correctAnswers === questions.length) {
        return (
          <div className="quiz-page__spec-quiz-final-points">{`${results.coins} ${formatMessage(
            getWordsFormat(results.coins, 'points'),
          )}`}</div>
        );
      } else {
        return <> </>;
      }
    } else {
      return (
        <div
          className="quiz-page__spec-quiz-final-points"
          style={{ color: results.coins > 0 ? WIN_LOSE_COLORS.winColor : WIN_LOSE_COLORS.loseColor }}
        >
          {is_new && (results.coins || (results.coins === 0 && correctAnswers === 0))
            ? `${results.coins} ${formatMessage(getWordsFormat(results.coins, 'points'))}`
            : isFeatureEnabled(CONFIG_KEYS.SHOW_POINTS_AFTER_FINISH_ADDITIONAL_QUIZ)
            ? `${results.coins} ${formatMessage(getWordsFormat(results.coins, 'points'))}`
            : ''}
        </div>
      );
    }
  };

  return (
    <div className="quiz-page__spec-quiz-game-results">
      <div className="quiz-page__spec-quiz-final-body">
        {brand && brand.logo && !isUnVerifiedBelarus && (
          <div className="quiz-page__spec-quiz-brand-logo" style={{ backgroundImage: `url(${brand.logo})` }} />
        )}
        <div
          className="quiz-page__spec-quiz-quiz-type"
          style={{ borderBottom: `2px solid ${theme.specialQuizBorderColor}` }}
        >
          {formatMessage(specQuizMode === SPECIAL_QUIZ_MODS.SALES_TIP ? 'Sales Tips' : 'Special quiz')}
        </div>
        <div className="quiz-page__spec-quiz-answer-row">
          <AnswerRows answers={checkedAnswers.map((el) => el.isCorrect)} count={questions.length} />
        </div>
        {getSpecQuizResult()}
        <div className="quiz-page__spec-quiz-final-extra-points">
          {results.extraCoins ? `+${results.extraCoins} ` + formatMessage('extra points') : ''}
        </div>
      </div>
      <div className="quiz-page__spec-quiz-final-bottom">
        <div className="quiz-page__spec-quiz-result-text">{formatMessage(getResultText())}</div>
        <Button customClass="quiz-page__spec-quiz-result-btn" withoutTheme onClick={onBtnClickCallback}>
          {finishButtonText}
        </Button>
      </div>
    </div>
  );
};

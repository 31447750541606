import firebase from 'firebase/app';
import 'firebase/messaging';
import { getFirebaseConfig } from './appSettingsHelpers';
import { isIosDevice } from './isIosDeviceHelper';

const config = getFirebaseConfig();

export const initFirebaseMessaging = () => {
  if (isIosDevice || process.env.REACT_APP_ENVIRONMENT.includes('staging')) {
    return;
  }
  firebase.initializeApp(config);

  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    messaging
      .requestPermission()
      .then(function () {
        console.log('have permission');
        return messaging.getToken();
      })
      .then(function (token) {
        if (localStorage.getItem('firebaseToken')) {
          return;
        }
        localStorage.setItem('firebaseToken', token);
      })
      .catch(function (err) {
        console.log('error', err);
      });

    messaging.onMessage(function (payload) {
      console.log('onMessage', payload);
    });
  }
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  answers: {
    me: [],
    opponent: [],
  },
  currentQuestion: 0,
  pack_id: null,
  questions: null,
  is_new: null,
  specialQuizResults: {
    coins: null,
    extraCoins: null,
  },
  specQuizMode: null,
};

const specialQuizSlice = createSlice({
  name: 'specialQuiz',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    setSpecQuizGameMode(state, { payload }) {
      const { gameMode } = payload;

      return {
        ...state,
        specQuizMode: gameMode,
      };
    },
    startSpecialQuiz(state, { payload }) {},
    setQuestions(state, { payload }) {
      const { questions } = payload;
      return { ...state, questions };
    },
    setPackId(state, { payload }) {
      const { pack_id } = payload;
      return {
        ...state,
        pack_id,
      };
    },
    setQuizStatus(state, { payload }) {
      const is_new = payload;

      return {
        ...state,
        is_new,
      };
    },
    setAnswer(state, { payload: { source, answer } }) {
      const newAnswers = { ...state.answers };
      newAnswers[source] = newAnswers[source].concat(answer);
      return {
        ...state,
        answers: newAnswers,
      };
    },
    nextQuestion(state) {
      let currentQuestion = state.currentQuestion;
      if (currentQuestion < state.questions.length - 1) {
        currentQuestion += 1;
      }

      return {
        ...state,
        currentQuestion,
      };
    },
    finishSpecialQuiz(state, { payload }) {},
    goToResult() {},
    setSpecialQuizResultData(state, { payload }) {
      const { coins, extraCoins } = payload;

      return {
        ...state,
        specialQuizResults: {
          coins,
          extraCoins,
        },
      };
    },
  },
});

export const specialQuizActions = specialQuizSlice.actions;
export const specialQuizReducer = specialQuizSlice.reducer;

import { useSelector, useDispatch } from 'react-redux';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getModalDataSelector } from '../../../containers/Modal/modalSelectors';
import { modalActions } from '../../../containers/Modal/modalSlice';
import { getWordsFormat } from '../../../../helpers/getWordsFormat';

import { ModalContainer } from '../../../containers/Modal/ModalContainer';
import { Button } from '../../../common/Button';
import { LottieWrapper } from '../../../lootieWrapper/lootieWrapper';

import { MODALS } from '../../../containers/Modal/modalConstants';
import verificationStep from './verificationStep.json';

import './verificationStepModal.scss';

const styles = {
  width: 343,
};

export const VerificationStepModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const {
    data: { onClose, onButtonClick, coins, btnText, description },
  } = useSelector(getModalDataSelector);

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };

  const rewardMessage = formatMessage(getWordsFormat(coins, '{coins} coins')).replace('{coins}', coins);

  return (
    <ModalContainer modal={MODALS.verificationStep} onClose={onClose} customStyles={styles}>
      <div className="verification-step">
        <div>
          <LottieWrapper
            options={options}
            style={{
              height: '340px',
            }}
          />
        </div>
        <div className="verification-step__reward">{rewardMessage}</div>
        <div className="verification-step__description">{description}</div>
        <Button onClick={handleButtonClick} customClass="verification-step__btn">
          {formatMessage(btnText)}
        </Button>
      </div>
    </ModalContainer>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: verificationStep,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

import './bonusShopCategoryCard.scss';

export const BonusShopCategoryCard = ({ category, onChoose }) => {
  const { name, photo } = category;

  return (
    <div className="category-block" onClick={() => onChoose()}>
      <img className="category-block__image" src={photo} alt="" />
      <div className="category-block__name">
        <p className="category-block__name-caption">{name}</p>
      </div>
    </div>
  );
};

export const ProfileBlackIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_122_1142)">
        <rect width="40" height="40" rx="8" fill="#ECECEC" />
        <circle cx="20" cy="20.4992" r="10.1857" fill="#252525" />
        <path
          d="M24.025 19.35C24.9777 19.35 25.75 18.5777 25.75 17.625C25.75 16.6723 24.9777 15.9 24.025 15.9C23.0723 15.9 22.3 16.6723 22.3 17.625C22.3 18.5777 23.0723 19.35 24.025 19.35Z"
          fill="#ECECEC"
        />
        <path
          d="M15.975 19.35C16.9277 19.35 17.7 18.5777 17.7 17.625C17.7 16.6723 16.9277 15.9 15.975 15.9C15.0223 15.9 14.25 16.6723 14.25 17.625C14.25 18.5777 15.0223 19.35 15.975 19.35Z"
          fill="#ECECEC"
        />
        <path
          d="M20 27.4C22.622 27.4 24.853 25.491 25.75 22.8H14.25C15.147 25.491 17.378 27.4 20 27.4Z"
          fill="#ECECEC"
        />
        <path
          d="M19.9885 9C13.6405 9 8.5 14.152 8.5 20.5C8.5 26.848 13.6405 32 19.9885 32C26.348 32 31.5 26.848 31.5 20.5C31.5 14.152 26.348 9 19.9885 9ZM20 29.7C14.917 29.7 10.8 25.583 10.8 20.5C10.8 15.417 14.917 11.3 20 11.3C25.083 11.3 29.2 15.417 29.2 20.5C29.2 25.583 25.083 29.7 20 29.7Z"
          fill="#252525"
        />
      </g>
      <defs>
        <clipPath id="clip0_122_1142">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './achievements.scss';

import { Header } from '../../components/common/Header';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { formatTimeToString, getTimeLeft } from '../../helpers/timesHelper';
import { Routes } from '../../const';
import { AchievementsSummary } from './summary/achievementsSummary';
import { achievementsActions } from '../../redux/achievements/achievementsSlice';
import { getAchievementsSelector } from '../../redux/achievements/achievementsSelectors';

export const AchievementsPage = ({ history }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(achievementsActions.getAchievements());
  }, [dispatch]);

  const achievements = useSelector(getAchievementsSelector);

  const renderColumns = () => {
    const renderItems = (items, itemHeight) => {
      return items.map((el, i) => {
        const time = getTimeLeft(el.end_date);
        return (
          <div
            key={el.id}
            className="achievements-page__bottom-block-list-item"
            style={{ height: itemHeight(i), backgroundImage: `url(${el.icon_image})` }}
            onClick={() => history.push(`${Routes.SelectedAchievement}/${el.id}`)}
          >
            <div className="achievements-page__bottom-block-list-item-header">
              {el.end_date && formatTimeToString(time) !== '' && (
                <div
                  className="achievements-page__bottom-block-list-item-header-timer"
                  style={{ color: el.status_color }}
                >
                  {formatTimeToString(time)}
                </div>
              )}
              <div
                className="achievements-page__bottom-block-list-item-header-name"
                style={{ marginTop: el.end_date && formatTimeToString(time) ? 0 : '20px', color: el.text_color }}
              >
                {el.name}
              </div>
            </div>
            {el.is_reward_available ? (
              <div
                className="achievements-page__bottom-block-list-item-status"
                style={{ backgroundColor: el.status_color }}
              >
                <p className="achievements-page__bottom-block-list-item-status-text">
                  {formatMessage('Reward is available')}
                </p>
              </div>
            ) : (
              <div className="achievements-page__bottom-block-list-item-level" style={{ color: el.text_color }}>
                {el.level}
              </div>
            )}
          </div>
        );
      });
    };
    return achievements ? (
      <div className="achievements-page__bottom-block-list-row">
        <div className="achievements-page__bottom-block-list-column">
          {renderItems(
            achievements.filter((el, i) => i % 2 === 0),
            (i) => `${140 + (i % 3) * 20}px`,
          )}
        </div>
        <div className="achievements-page__bottom-block-list-column">
          {renderItems(
            achievements.filter((el, i) => i % 2 === 1),
            (i) => `${180 - (i % 3) * 20}px`,
          )}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <div className="achievements-page">
      <ScrollBlock fromTop>
        <Header hasBackButton customClass="achievements-page__header">
          <p className="achievements-page__header-text">{formatMessage('Achievements')}</p>
        </Header>
        <div className="achievements-page__top-block">
          <div className="achievements-page__top-block-headline">{formatMessage('Meat the goal!')}</div>
          <div className="achievements-page__top-block-description">
            {formatMessage('Here is your achievements list. Complete tasks and get rewards!')}
          </div>
        </div>
        <AchievementsSummary customClass="achievements-page__top-block-summary" />
        <div className="achievements-page__bottom-block">
          <div className="achievements-page__bottom-block-list">{renderColumns()}</div>
        </div>
      </ScrollBlock>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  answers: {
    me: [],
  },
  currentQuestion: 0,
  eventModeGame: null,
  result: null,
};

const eventModeSlice = createSlice({
  name: 'eventMode',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    setEventModeGameInfo(state, { payload }) {
      return {
        ...state,
        eventModeGame: payload,
      };
    },
    setAnswer(state, { payload: { source, answer } }) {
      const newAnswers = { ...state.answers };
      newAnswers[source] = newAnswers[source].concat(answer);
      return {
        ...state,
        answers: newAnswers,
      };
    },
    nextQuestion(state) {
      let currentQuestion = state.currentQuestion;
      if (currentQuestion < state.questions.length - 1) {
        currentQuestion += 1;
      }

      return {
        ...state,
        currentQuestion,
      };
    },
    startEventMode() {},
    finishEventMode() {},
    setEventModeResult(state, { payload }) {
      return {
        ...state,
        result: payload,
      };
    },
  },
});

export const eventModeActions = eventModeSlice.actions;
export const eventModeReducer = eventModeSlice.reducer;

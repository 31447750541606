import classnames from 'classnames';

import useChallengeTimeLeft from '../../../../hooks/useChallengeTimeLeftHook';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { IChallenge } from '../../../../redux/challenge/challengeTypes';

import styles from './RemainedTime.module.scss';

type Props = {
  challenge: IChallenge;
};

export const RemainedTime = ({ challenge }: Props) => {
  const { formatMessage } = useInternationalization();

  const challengeTimeRemain = useChallengeTimeLeft(challenge);

  return (
    <div className={classnames(styles.timeRemain, 'team-challenge-time-remain')}>
      {`${challengeTimeRemain.days}${formatMessage('d')} ` +
        `${challengeTimeRemain.hours}${formatMessage('h')} ` +
        `${challengeTimeRemain.minutes}${formatMessage('m')} `}
    </div>
  );
};

import { useTimeLeft } from '../../../hooks/useTimeLeft';

import { BoxRightArrow } from '../../../icons';

import styles from './MysteryShopperCard.module.scss';

type Props = {
  id: number;
  background_image: string;
  name: string;
  end_date: string;
  onCardClick: (id: number) => void;
};

export const MysteryShopperCard = ({ id, background_image, name, end_date, onCardClick }: Props) => {
  const time = useTimeLeft(end_date);

  return (
    <div
      className={styles.container}
      onClick={() => onCardClick(id)}
      style={{ backgroundImage: `url(${background_image})` }}
    >
      <span className={styles.title}>{name}</span>
      <span className={styles.date}>{time}</span>
      <BoxRightArrow className={styles.arrow} fillColor="white" />
    </div>
  );
};

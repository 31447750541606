import { APP_TYPES } from '../../const/appConstants';

const quizDescriptionByAppType = {
  [APP_TYPES.IRELAND]: {
    1: 'Step into the shoes of a modern art expert!',
    2: 'Are you keeping up with the latest news?',
    3: 'Challenge yourself with trivia!',
    4: 'Are you ready to challenge your brain?',
    5: 'The future is coming! Are you ready?',
  },
  [APP_TYPES.HUNGARY]: {
    // formatMessage('Music and travel? What could be better!')
    1: 'Music and travel? What could be better!',
    // formatMessage('Do you know anything about modern art? Prove it!')
    2: 'Do you know anything about modern art? Prove it!',
    // formatMessage('Are you ready to test your brain power?')
    3: 'Are you ready to test your brain power?',
  },
  [APP_TYPES.GEORGIA]: {
    // formatMessage('There is Winston for everyone')
    1: 'There is Winston for everyone',
    // formatMessage("Let's Camel / It's Camel")
    2: "Let's Camel / It's Camel",
    // formatMessage('Brand for exclusive impressions')
    4: 'Brand for exclusive impressions',
  },
  [APP_TYPES.ARMENIA]: {
    // formatMessage('Music and travel? What could be better!')
    1: 'Music and travel? What could be better!',
    // formatMessage('Do you know anything about modern art? Prove it!')
    2: 'Do you know anything about modern art? Prove it!',
    // formatMessage('Are you ready to test your brain power?')
    3: 'Are you ready to test your brain power?',
    // formatMessage('Brand for exclusive impressions')
    4: 'Brand for exclusive impressions',
  },
  [APP_TYPES.AZERBAIJAN]: {
    // formatMessage('Music and travel? What could be better!')
    1: 'Music and travel? What could be better!',
    // formatMessage('Do you know anything about modern art? Prove it!')
    2: 'Do you know anything about modern art? Prove it!',
    // formatMessage('Are you ready to test your brain power?')
    3: 'Are you ready to test your brain power?',
  },
  [APP_TYPES.BELARUS]: {
    1: 'Do you know anything about modern art? Prove it!',
    2: 'Music and travel? What could be better!',
    3: 'Are you ready to test your brain power?',
    4: 'Brand for exclusive impressions',
  },
  [APP_TYPES.DEMO]: {
    // formatMessage('Step into the shoes of a modern art expert!')
    10: 'Step into the shoes of a modern art expert!',
    // formatMessage('Are you keeping up with the latest news?')
    11: 'Are you keeping up with the latest news?',
    // formatMessage('Challenge yourself with trivia!')
    12: 'Challenge yourself with trivia!',
    // formatMessage('Are you ready to challenge your brain?')
    13: 'Are you ready to challenge your brain?',
    // formatMessage('The future is coming! Are you ready?')
    14: 'The future is coming! Are you ready?',
  },
};

export const QUIZ_DESCRIPTIONS = quizDescriptionByAppType[process.env.REACT_APP_APP_TYPE];

export const images = [
  { id: 1, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-1.png' },
  { id: 2, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-2.png' },
  { id: 3, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-3.png' },
  { id: 4, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-4.png' },
  { id: 5, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-5.png' },
  { id: 6, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-6.png' },
  { id: 7, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-7.png' },
  { id: 8, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-8.png' },
  { id: 9, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-9.png' },
  { id: 10, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-10.png' },
  { id: 11, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-11.png' },
  { id: 12, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-12.png' },
  { id: 13, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-13.png' },
  { id: 14, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-14.png' },
  { id: 15, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-15.png' },
  { id: 16, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-16.png' },
  { id: 17, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-17.png' },
  { id: 18, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-18.png' },
  { id: 19, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-19.png' },
  { id: 20, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-20.png' },
  { id: 21, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-21.png' },
  { id: 22, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-22.png' },
  { id: 23, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-23.png' },
  { id: 24, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-24.png' },
  { id: 25, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-25.png' },
  { id: 26, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-26.png' },
  { id: 27, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-27.png' },
  { id: 28, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-28.png' },
  { id: 29, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-29.png' },
  { id: 30, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-30.png' },
  { id: 31, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-31.png' },
  { id: 32, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-32.png' },
  { id: 33, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-33.png' },
  { id: 34, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-34.png' },
  { id: 35, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-35.png' },
  { id: 36, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-36.png' },
  { id: 37, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-37.png' },
  { id: 38, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-38.png' },
  { id: 39, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-39.png' },
  { id: 40, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-40.png' },
  { id: 41, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-41.png' },
  { id: 42, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-42.png' },
  { id: 43, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-43.png' },
  { id: 44, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-44.png' },
  { id: 45, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-45.png' },
  { id: 46, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-46.png' },
  { id: 47, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-47.png' },
  { id: 48, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-48.png' },
  { id: 49, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-49.png' },
  { id: 50, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-50.png' },
  { id: 51, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-51.png' },
  { id: 52, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-52.png' },
  { id: 53, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-53.png' },
  { id: 54, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-54.png' },
  { id: 55, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-55.png' },
  { id: 56, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-56.png' },
  { id: 57, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-57.png' },
  { id: 58, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-58.png' },
  { id: 59, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-59.png' },
  { id: 60, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-60.png' },
  { id: 61, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-61.png' },
  { id: 62, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-62.png' },
  { id: 63, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-63.png' },
  { id: 64, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-64.png' },
  { id: 65, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-65.png' },
  { id: 66, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-66.png' },
  { id: 67, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-67.png' },
  { id: 68, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-68.png' },
  { id: 69, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-69.png' },
  { id: 70, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-70.png' },
  { id: 71, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-71.png' },
  { id: 72, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-72.png' },
  { id: 73, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-73.png' },
  { id: 74, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-74.png' },
  { id: 75, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-75.png' },
  { id: 76, imageUrl: 'https://jticonnect.fra1.digitaloceanspaces.com/images/quiz_avatars/img_user-icon-76.png' },
];

interface IUserInfoBlock {
  count_win: number;
  count_lose: number;
  count_draw: number;
}

interface IRenderUserInfo {
  text: string;
  count: number;
}
export const renderUserInfoBlock = ({ count_win, count_lose, count_draw }: IUserInfoBlock): IRenderUserInfo[] => [
  { text: 'Win', count: count_win },
  { text: 'Lose', count: count_lose },
  { text: 'Draw', count: count_draw },
];

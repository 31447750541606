import './soloModePage.scss';

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getGameTimeSelector } from '../../../redux/soloMode/soloModeSelectors';
import { Button } from '../../../components/common/Button';
import { soloModeActions } from '../../../redux/soloMode/soloModeSlice';
import { getUnverificationInfoForBelarus } from '../../../redux/verification/verificationSelectors';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';
import clocksAnimation from '../clocks-animation.json';

export const SoloModeFirstScreen = (props) => {
  const { formatMessage } = useInternationalization();
  const gameTime = useSelector(getGameTimeSelector);
  const { theme, onBackCallback, brand } = props;
  const dispatch = useDispatch();
  const isUnVerifiedBelarus = useSelector(getUnverificationInfoForBelarus);

  useEffect(() => {
    dispatch(soloModeActions.startGame());
  }, [dispatch]);

  return (
    <div className="solo-mode-start__invite-accepted-form">
      <div>
        <Button className="quiz-page__back-button" onClick={onBackCallback} withoutTheme>
          <ArrowIconRounded className="quiz-page__back-icon" style={{ fill: theme.backIconColor }} />
        </Button>
        <div className="solo-mode-start__invite-accepted-main">
          {!isUnVerifiedBelarus ? (
            <div className="quiz-page__brand-logo-mini" style={{ backgroundImage: `url(${brand.logo})` }}></div>
          ) : null}

          <div className="quiz-page__pregame-text">{formatMessage('Good Luck')}!</div>
        </div>
        <div className="quiz-page__clocks-animation-container">
          <LottieWrapper isStopped={false} isPaused={false} options={clocksAnimationOptions} height={200} width={200} />
        </div>
      </div>

      {gameTime && (
        <div className="solo-mode-start__time-text">
          {formatMessage('You have {gameTime} seconds to answer questions', { gameTime })}
        </div>
      )}
    </div>
  );
};

const clocksAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: clocksAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

SoloModeFirstScreen.propTypes = {
  theme: PropTypes.object.isRequired,
};
SoloModeFirstScreen.defaultProps = {};

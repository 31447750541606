import './oppaTutorial.scss';

import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { createStepListContainer } from '../../../../components/containers/StepList/StepListContainer';
import { OppaTutorialStep1 } from './oppaTutorialStep1';
import { OppaTutorialStep2 } from './oppaTutorialStep2';
import { OppaTutorialStep3 } from './oppaTutorialStep3';
import { OppaTutorialStep4 } from './oppaTutorialStep4';
import { OppaTutorialStep5 } from './oppaTutorialStep5';
import { OppaTutorialStep6 } from './oppaTutorialStep6';
import { useSelector } from 'react-redux';
import { oppaSteps } from '../OPPA';

export const oppaTutorialSteps = {
  step1: 1,
  step2: 2,
  step3: 3,
  step4: 4,
  step5: 5,
  step6: 6,
  stepNull: 7,
};

const StepList = createStepListContainer(STEP_LIST_IDS.oppaTutorialStepList, [
  { id: oppaTutorialSteps.step1, Component: () => <OppaTutorialStep1 /> },
  { id: oppaTutorialSteps.step2, Component: () => <OppaTutorialStep2 /> },
  { id: oppaTutorialSteps.step3, Component: () => <OppaTutorialStep3 /> },
  { id: oppaTutorialSteps.step4, Component: () => <OppaTutorialStep4 /> },
  { id: oppaTutorialSteps.step5, Component: () => <OppaTutorialStep5 /> },
  { id: oppaTutorialSteps.step6, Component: () => <OppaTutorialStep6 /> },
  { id: oppaTutorialSteps.stepNull, Component: () => null },
]);

export const OppaTutorial = () => {
  const transactionPage = useSelector((s) => s.steps[STEP_LIST_IDS.oppaStepList]?.currentStep);

  if (transactionPage !== oppaSteps.mainPage || localStorage.getItem('oppaCompletedTutorial')) return null;

  return <StepList />;
};

import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '../../../const';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../redux/auth/authSlice';

export const EsignRedirectPage = (props) => {
  const { location } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const envelopeId = params.get('envelopeId');
    if (envelopeId) {
      console.log(envelopeId);
      dispatch(authActions.esignLogin({ envelopeId }));
    }
  }, [location, dispatch]);
  return <Redirect to={Routes.MainRoute} />;
};

import classnames from 'classnames';
import { RefObject, useRef } from 'react';

import { ModerationIcon } from '../../../../icons/moderation-icon';
import { AcceptedIcon } from '../../../../icons/accepted-icon';

import { ComponentLike } from '../../../../types';
import { ITask, TaskCardStatus, TaskTypes } from '../../../../redux/challenge/challengeTypes';

import CustomTask from './CustomTask';
import CustomTextAuto from './CustomTextAuto';
import CustomTextManual from './CustomTextManual';
import SalesTipsTask from './SalesTipsTask';
import CommonTask from './CommonTask';

import styles from './TaskCard.module.scss';

const Theme: Record<TaskCardStatus, { className: string; StatusIcon?: ComponentLike }> = {
  [TaskCardStatus.Uncompleted]: {
    className: styles.uncompleted,
  },
  [TaskCardStatus.Completed]: {
    className: styles.completed,
    StatusIcon: AcceptedIcon,
  },
  [TaskCardStatus.Checking]: {
    className: styles.checking,
    StatusIcon: ModerationIcon,
  },
};

type Props = {
  onTaskClick: () => void;
  onCustomImageUploadTaskClick: (inputRef: RefObject<HTMLInputElement>) => void;
  task: ITask;
  photoFrame?: string;
  attachmentsButtonEnabled: boolean;
};

export const TaskCard = ({
  task,
  onTaskClick,
  onCustomImageUploadTaskClick,
  photoFrame,
  attachmentsButtonEnabled,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { description, type, required_task_count, status, progress, done } = task;
  const { className: taskClassName, StatusIcon } = Theme[status];

  const completedTasksCountWithoutProgress = +done;
  const completedTasksWithProgress = progress > required_task_count ? required_task_count : progress;

  const getTaskComponent = () => {
    switch (type) {
      case TaskTypes.Custom:
        return CustomTask;
      case TaskTypes.CustomTextAuto:
        return CustomTextAuto;
      case TaskTypes.CustomTextManual:
        return CustomTextManual;
      case TaskTypes.SalesTips:
        return SalesTipsTask;
      default:
        return CommonTask;
    }
  };
  const TaskComponent = getTaskComponent();

  return (
    <div className={classnames(styles.container, taskClassName)}>
      <TaskComponent
        fileInputRef={fileInputRef}
        description={description}
        StatusIcon={StatusIcon}
        onTaskClick={onTaskClick}
        requiredTaskCount={required_task_count}
        progress={progress}
        completedTasksWithProgress={completedTasksWithProgress}
        completedTasksCountWithoutProgress={completedTasksCountWithoutProgress}
        status={status}
        onCustomImageUploadTaskClick={onCustomImageUploadTaskClick}
        photoFrame={photoFrame}
        attachmentsButtonEnabled={attachmentsButtonEnabled}
      />
    </div>
  );
};

import React, { FormEventHandler, InputHTMLAttributes, useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';

import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { Field, reduxForm, reset } from 'redux-form';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { required } from '../../../helpers/formValidations';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';

import styles from './IdCardType.module.scss';

type Props = {
  invalid: boolean;
  handleSubmit: FormEventHandler<HTMLFormElement>;
};

type FieldProps = {
  input: InputHTMLAttributes<HTMLInputElement>;
  label: string;
  type: string;
  onClick: () => void;
};

const IdCardTypeComponent = ({ invalid, handleSubmit }: Props) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reset('idCardType'));
  }, []);

  const onBackArrowClick = () => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
  };

  const onArrowButtonClick = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
  };

  const renderField = ({ input, label, type, onClick }: FieldProps) => {
    return (
      <div className={styles.wrapper}>
        <input
          className={classnames(styles.answerInput, 'activeAnswer')}
          onClick={onClick}
          placeholder={label}
          id={`${input.value}`}
          type={type}
          {...input}
        />
        <label className={classnames(styles.label, 'activeAnswer')} htmlFor={`${input.value}`}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor={'black'}
      />
      <div className={styles.textWrapper}>
        <span className={styles.title}>{formatMessage('Choose your ID card type')}</span>
        <span className={styles.description}>{formatMessage('Tap the correct type')}</span>
      </div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.inputs}>
          <Field
            component={renderField}
            type="radio"
            value={'1'}
            name={'type'}
            label={formatMessage('Old type')}
            validate={[required]}
          />
          <Field
            component={renderField}
            type="radio"
            value={'2'}
            name={'type'}
            validate={[required]}
            label={formatMessage('New type (from 2018)')}
          />
        </div>
        <ArrowButton
          customClass={styles.nextButton}
          fillcolor={'#01C1B6'}
          withoutTheme
          disabled={invalid}
          onClick={onArrowButtonClick}
        />
      </form>
    </div>
  );
};

export const IdCardType = reduxForm({
  form: 'idCardType',
  destroyOnUnmount: false,
  shouldValidate: () => true,
})(IdCardTypeComponent);

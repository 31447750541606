import axios from 'axios';
import { API_URL } from './baseApi';

export const idCardApi = {
  getIdCard: async () => {
    return await axios.get(`${API_URL}/v1/id-card`);
  },
  sendIdCard: async (file_key: string, id_card_type: number) => {
    return await axios.post(`${API_URL}/v1/id-card`, { file_key, id_card_type });
  },
  sendVerifiedIdCard: async (fields: any) => {
    return await axios.put(`${API_URL}/v1/id-card`, { ...fields });
  },
};

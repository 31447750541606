export const QuestionIcon = (props) => {
  return (
    <svg width="82" height="120" viewBox="0 0 82 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2498 55.2208L46.2435 55.2245C41.0208 58.248 37.5858 61.3177 35.5358 64.9141C33.4999 68.4858 32.7021 72.8035 33.1865 78.5741L33.1906 78.6227L33.2809 81.2059H50.0312V79.1263C50.0312 75.0582 50.8223 71.7558 53.1869 68.6799C55.4637 65.7182 59.0807 63.1318 64.3003 60.1509C69.6195 57.0617 73.5235 53.6392 76.1031 49.7032C78.6686 45.7886 80 41.2513 80 35.7893C80 27.6229 76.5889 20.7441 70.575 15.8666C64.5289 10.9631 55.7381 8 44.9292 8C33.3104 8 24.4374 11.2069 18.4489 16.487C12.9378 21.3461 9.7063 28.1022 9.09699 36.1756H26.3387C26.9124 32.2005 28.6044 28.7989 31.2806 26.2947C34.3707 23.4032 38.6122 21.8582 43.5245 21.8582C48.3653 21.8582 52.5629 23.3058 55.5825 25.9145C58.6211 28.5397 60.3528 32.2499 60.3528 36.4821C60.3528 40.4676 59.5069 43.7046 57.1515 46.7348C54.8839 49.6521 51.3203 52.2356 46.2561 55.2171L46.2498 55.2208ZM28.1498 38.1756H7C7.01389 37.5016 7.04449 36.8348 7.09167 36.1756C8.37006 18.314 21.8226 6 44.9292 6C67.2497 6 82 18.239 82 35.7893C82 47.4126 76.3028 55.4949 65.2986 61.8839C54.9188 67.8109 52.0312 71.5827 52.0312 79.1263V83.2059H31.3496L31.1935 78.7414C30.179 66.6563 34.4714 59.7286 45.2414 53.4936C55.3091 47.5665 58.3528 43.7947 58.3528 36.4821C58.3528 29.1695 52.4214 23.8582 43.5245 23.8582C34.5494 23.8582 28.6181 29.4774 28.1498 38.1756ZM41.9636 118C48.0783 118 52.7627 113.47 52.7627 107.915C52.7627 102.359 48.0783 97.8298 41.9636 97.8298C35.9351 97.8298 31.2425 102.352 31.2425 107.915C31.2425 113.478 35.9351 118 41.9636 118ZM54.7627 107.915C54.7627 114.689 49.0656 120 41.9636 120C34.9396 120 29.2425 114.689 29.2425 107.915C29.2425 101.141 34.9396 95.8298 41.9636 95.8298C49.0656 95.8298 54.7627 101.141 54.7627 107.915Z"
        fill="white"
      />
      <path
        d="M0 32.1756H21.1498C21.6181 23.4774 27.5494 17.8582 36.5245 17.8582C45.4214 17.8582 51.3528 23.1695 51.3528 30.4821C51.3528 37.7947 48.3091 41.5665 38.2414 47.4936C27.4714 53.7286 23.179 60.6563 24.1935 72.7414L24.3496 77.2059H45.0312V73.1263C45.0312 65.5827 47.9188 61.8109 58.2986 55.8839C69.3028 49.4949 75 41.4126 75 29.7893C75 12.239 60.2497 0 37.9292 0C13.9698 0 0.390219 13.2397 0 32.1756ZM34.9636 114C42.0656 114 47.7627 108.689 47.7627 101.915C47.7627 95.1411 42.0656 89.8298 34.9636 89.8298C27.9396 89.8298 22.2425 95.1411 22.2425 101.915C22.2425 108.689 27.9396 114 34.9636 114Z"
        fill="white"
      />
    </svg>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = { brandsInfo: [], isFetching: false };

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    getBrandsGames() {},
    setBrandsGames(state, action) {
      const { brands } = action.payload;
      return {
        ...state,
        brandsInfo: brands,
      };
    },
    setIsFetching(state, { payload }) {
      return {
        ...state,
        isFetching: payload.isFetching,
      };
    },
  },
});

export const brandsActions = brandsSlice.actions;

export default brandsSlice.reducer;

import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ModalContainer } from '../containers/Modal/ModalContainer';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { getModalDataSelector } from '../containers/Modal/modalSelectors';
import { modalActions } from '../containers/Modal/modalSlice';

import { MODALS } from '../containers/Modal/modalConstants';
import { Button } from '../common/Button';
import { ModalTitle } from '../containers/Modal/ModalTitle';

import './Prompt.scss';
import './ErrorModal.scss';

export const PromptModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  let {
    data: {
      onClose,
      description,
      btnText,
      onButtonClick,
      title,
      link,
      linkText,
      image,
      descriptionReplacement,
      isUpdateModal,
    },
  } = useSelector(getModalDataSelector);
  if (description && description.map) {
    description = description.map((el, i) => (
      <>
        <div key={i}>{formatMessage(el)}</div>
        <br />
      </>
    ));
  } else if (description) {
    description = formatMessage(description, descriptionReplacement && { replacement: descriptionReplacement });
  }

  const handleButtonClick = () => {
    setIsButtonDisabled(true);
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.prompt} onClose={onClose} isUpdateModal={isUpdateModal}>
      <div className="prompt-modal">
        {image}
        {title && <ModalTitle>{formatMessage(title)}</ModalTitle>}
        {description && <div className="prompt-modal__description">{description}</div>}
        {linkText && link && (
          <a href={link} target="_blank" rel="noopener noreferrer" className="prompt-modal__link">
            {linkText}
          </a>
        )}
        {btnText && (
          <Button onClick={handleButtonClick} customClass="prompt-modal__btn" disabled={isButtonDisabled}>
            {formatMessage(btnText)}
          </Button>
        )}
      </div>
    </ModalContainer>
  );
};

PromptModal.propTypes = {
  onClose: PropTypes.func,
  description: PropTypes.node,
};
PromptModal.defaultProps = {};

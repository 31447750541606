import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

import {
  getMultibrandQuizIdSelector,
  getMultibrandQuizQuestionsSelector,
} from '../../../redux/multibrandQuiz/multibrandQuizSelectors';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors';
import { multibrandQuizActions } from '../../../redux/multibrandQuiz/multibrandQuizSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { getBrandInfoSelector } from '../../../redux/brands/brandSelectors';
import { getActiveBoosterSelector } from '../../../redux/boosters/boostersSelectors';
import { boostersActions } from '../../../redux/boosters/boostersSlice';

import { APP_TYPES } from '../../../const/appConstants';
import { ANSWER_SOURCES } from '../../../redux/game/gameConstants';
import { BOOSTERS_TYPES } from '../../../redux/boosters/boostersConstants';
import { Boosters } from '../Boosters/Boosters';
import { RenderGame } from '../Game/renderQuestionComponent';
import { PlusIcon } from '../../../icons/plus-icon';

export const MultibrandQuizComponent = () => {
  const [theme, setTheme] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const questions = useSelector(getMultibrandQuizQuestionsSelector);
  const pack_id = useSelector(getMultibrandQuizIdSelector);
  const activeBooster = useSelector(getActiveBoosterSelector);
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const brand = useSelector((state) => getBrandInfoSelector(state, questions[currentQuestion].brand_id));

  const onLeaveCallback = useCallback(() => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure you want to finish?',
        btnText: 'Yes, exit',
        onButtonClick: () => {
          dispatch(goBack());
          dispatch(modalActions.closeModal());
        },
      }),
    );
  }, [dispatch, formatMessage]);

  const [myVariant, setMyVariant] = useState(null);
  const [checkedAnswers, setCheckedAnswers] = useState({ me: [] });
  const [answers, setAnswers] = useState([]);

  const nextQuestionCallback = useCallback(() => {
    if (currentQuestion + 1 === Number(questions.length)) {
      dispatch(multibrandQuizActions.finishMultibrandQuiz());
      return;
    } else setTimeout(() => setCurrentQuestion(currentQuestion + 1), 2500);
  }, [currentQuestion, questions, dispatch]);

  const onAnswerClicked = useCallback(
    (answer) => {
      if (!myVariant) {
        setMyVariant(answer);
        const currentAnswers = [...answers];
        currentAnswers.push(answer);
        const answersToCheck = checkCorrectAnswers(currentAnswers, questions);

        if (!answersToCheck[currentQuestion].isCorrect && activeBooster === BOOSTERS_TYPES.SECOND_CHANCE) {
          dispatch(boostersActions.secondChanceUsed(answer));
          return;
        }
        setAnswers(currentAnswers);
        setCheckedAnswers({ me: checkCorrectAnswers(currentAnswers, questions) });
        dispatch(multibrandQuizActions.setAnswer({ source: ANSWER_SOURCES.ME, answer }));
        nextQuestionCallback();
      }
    },
    [myVariant, answers, nextQuestionCallback, questions, dispatch, activeBooster, currentQuestion],
  );

  useEffect(() => {
    setTheme({
      color: brand ? brand.quiz_element_color : '#ffffff',
      triangleColor: brand ? brand.triangle_color : '#000',
    });
  }, [questions, currentQuestion, brand]);

  useEffect(() => {
    analyticHelper.multibrandQuestion(currentQuestion + 1);
  }, [currentQuestion]);

  useEffect(() => {
    setMyVariant(null);
  }, [currentQuestion]);

  useEffect(() => {
    setMyVariant(null);
  }, [activeBooster]);

  return (
    <div
      className="multibrand-quiz-page__game"
      style={{
        backgroundImage: brand && brand.background_image && `url(${brand.background_image}`,
        color: theme.color,
      }}
    >
      <div className="multibrand-quiz-page__game__leave-game" onClick={onLeaveCallback}>
        <PlusIcon className="multibrand-quiz-page__game__leave-game-icon" style={{ fill: theme.color }} />{' '}
        {formatMessage('Tap to exit')}
      </div>

      <div className="multibrand-quiz-page__game__progress">{`${currentQuestion + 1}/${questions.length}`}</div>

      <RenderGame
        currentQuestion={questions[currentQuestion]}
        questions={questions}
        currentQuestionNumber={currentQuestion}
        theme={theme}
        onAnswerClicked={onAnswerClicked}
        brandId={questions[currentQuestion].brand_id}
        checkedAnswers={checkedAnswers}
        withoutOpponent={true}
        withBoosters={process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY}
      />

      {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
        <Boosters
          pack_id={pack_id}
          solo_mode
          currentQuestion={questions[currentQuestion]}
          myVariant={myVariant}
          setMyAnswerCallback={onAnswerClicked}
          roundTime={15}
        />
      )}
    </div>
  );
};

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Routes } from '../../../../const';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { AvatarIconDefault } from '../../../../icons';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../../config';

import styles from './NewUserComponent.module.scss';

type NewUserComponentProps = {
  userPhoto: string;
  username: string;
  coins: number;
  monthlyEarned: number;
  name?: string;
};

interface ServiceState {
  theme: {
    id: string;
  };
}
interface RootState {
  service: ServiceState;
}

export const NewUserComponent = (props: NewUserComponentProps) => {
  const { formatMessage } = useInternationalization();
  const { userPhoto, username: profileName, coins, monthlyEarned, name } = props;
  const theme = useSelector((state: RootState) => state.service.theme);
  const username = isFeatureEnabled(CONFIG_KEYS.SHOW_USERNAME_INSTEAD_OF_FIRST_NAME_ENABLED) ? name : profileName;

  return (
    <Link to={Routes.ProfileRoute}>
      <div className={styles.userComponent}>
        <div className={styles.imageContainer}>
          {userPhoto ? (
            <img className={styles.userPhoto} src={userPhoto} alt="avatar" />
          ) : (
            <AvatarIconDefault className={styles.userPhoto} withoutBorder={false} />
          )}
        </div>
        <div className={styles.textContainer}>
          <p className={styles.greetings}>{formatMessage('Hi, {username}', { username })}</p>
          <p className={styles.earned}>
            {formatMessage(`Good start! You've earned { monthlyEarned } points this month`, { monthlyEarned })}
          </p>
        </div>
        <div className={`${styles.coins} ${styles[`coins_theme${theme.id}`]}`}>
          <span className={styles.boldCoins}>{coins}</span> {formatMessage('coins')}
        </div>
      </div>
    </Link>
  );
};

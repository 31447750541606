export const DAILY_REWARDS_ACTION_TYPES = {
  START_DAILY_REWARDS: 'dailyRewards/startDailyRewards',
  RESULT_DAILY_REWARDS: 'dailyRewards/resultDailyRewards',
  FINAL_DAILY_REWARDS: 'dailyRewards/finalDailyRewards',
  FINISH_DAILY_REWARDS: 'dailyRewards/finishDailyRewards',
};

export const DAILY_REWARDS_STEPS = {
  GAME: 1,
  RESULT: 2,
  FINISH: 3,
};

export const DAILY_REWARDS_STATUS = {
  NOT_ROLLED: 0,
  ACTIVE: 1,
  UNAVAILABLE: 2,
};

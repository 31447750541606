import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { BackButton, Button } from '../../../../components/common';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { ChatRoutes } from '../../../../const';
import { isHungary } from '../../../../helpers';
import { ButtonType } from '../../../../components/common/CommonButton/types';

import { NewMessageBadge } from '../../components/newMessageBadge/NewMessageBadge';
import { ChatTab } from '../../../../redux/chat/chatTypes';
import { chatSelectors } from '../../../../redux/chat';
import { amplitudeHelper } from '../../../../helpers/ampitudeHelper';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../../config';

import styles from './ChatHeader.module.scss';

type Props = {
  title?: string;
  onBackClick?: () => void;
};

export const ChatHeader = ({ title, onBackClick }: Props) => {
  const { formatMessage } = useInternationalization();
  const { pathname } = useLocation();
  const history = useHistory();
  const hasUnreadNotifications = !!useSelector(chatSelectors.selectUnreadNotificationsCount);
  const hasUnreadSupportMessages = !!useSelector(chatSelectors.selectUnreadSupportMessagesCount);

  const hasUnreadMessages = (chatTab: ChatTab) => {
    return chatTab === ChatTab.Notifications ? hasUnreadNotifications : hasUnreadSupportMessages;
  };

  const routes: Record<ChatTab, string> = {
    [ChatTab.JTI]: ChatRoutes.JTI,
    [ChatTab.Support]: ChatRoutes.Support,
    [ChatTab.Notifications]: ChatRoutes.Notifications,
  };

  const getTabs = () => {
    if (isHungary()) {
      return [ChatTab.Support, ChatTab.JTI];
    } else {
      return [ChatTab.Notifications, ChatTab.Support];
    }
  };

  const openTab = (tab: ChatTab) => {
    if (routes[tab] === ChatRoutes.Support) {
      amplitudeHelper.openSupport();
    }
    history.push(routes[tab]);
  };
  let ticketName: string | undefined = '';
  if (isFeatureEnabled(CONFIG_KEYS.CHAT_WITHOUT_OPTIONS_ENABLED)) {
    ticketName = title?.replace('Other', formatMessage('ticket.question'));
  } else {
    ticketName = title;
  }

  return (
    <div className={styles.chatHeader}>
      {title ? (
        <div className={styles.titleBlock}>
          <BackButton className={styles.backButton} onClick={onBackClick} />
          <div className={styles.title}>{ticketName}</div>
        </div>
      ) : (
        getTabs().map((tab) => (
          <div className={styles.buttonContainer} key={tab}>
            {hasUnreadMessages(tab) && <NewMessageBadge className={styles.newMessageBadge} />}
            <Button
              type={ButtonType.ChatTab}
              onClick={() => {
                openTab(tab);
              }}
              className={styles.button}
              isActive={pathname === routes[tab]}
            >
              {formatMessage(tab)}
            </Button>
          </div>
        ))
      )}
    </div>
  );
};

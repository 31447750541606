export { RatingFilters } from './ratingFilters';
export { Routes, ChatRoutes } from './routes';
export { BlockHeights } from './blockHeights';
export { ColorThemes } from './colorThemes';

export const ORDER_DELIVERY_TIME_SLOTS = [
  [8, 12],
  [12, 15],
  [15, 18],
  [18, 21],
];

export const ORDERS_STATUSES = [
  { value: 1, name: 'NEW' },
  { value: 2, name: 'DELIVERED' },
  { value: 3, name: 'SENT' },
  { value: 4, name: 'REJECTED' },
];

export const AUTH_METHODS = {
  PASSWORD: 'PASSWORD',
  OTP: 'OTP',
  NONE: 'NONE',
} as const;

export const PRODUCT_DELIVERY_CHANNEL = {
  SHIPPING: 1,
  DIGITAL: 2,
};

export const MIN_DELIVERY_TERM_IN_DAYS = 4;

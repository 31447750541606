import { createSelector } from 'reselect';

export const getUserInfoSelector = createSelector([(state) => state.profile.userInfo], (userInfo) => userInfo);

export const getUserBalanceSelector = createSelector([(state) => state.profile.coins], (coins) => coins);

export const getUserCoinsInCurrencySelector = createSelector(
  [(state) => state.profile.coinsInCurrency],
  (coins) => coins,
);

export const getUserLeafSelector = createSelector([(state) => state.profile.userInfo.leaf], (leaf) => leaf);

export const getManagerInfoSelector = createSelector(
  [(state) => state.profile.userInfo.outlet.manager],
  (managerInfo) => managerInfo,
);

export const getUserOutletInfoSelector = createSelector([(state) => state.profile.userInfo.outlet], (outlet) => outlet);

export const getPassportPageStateSelector = createSelector(
  [(state) => state.profile.passportPageState],
  (passportPageState) => passportPageState,
);

export const getPassportDataSelector = createSelector(
  [(state) => state.profile.passportData],
  (passportData) => passportData,
);

export const getCurrentPassportPageSelector = createSelector(
  [(state) => state.profile.currentPassportPage],
  (passportPage) => passportPage,
);

export const getStatementData = createSelector(
  [(state) => state.profile.statementData],
  (statementData) => statementData,
);

export const getPassportPhotos = createSelector([(state) => state.profile.passportPhotos], (photos) => photos);

export const getLegalDocumentsChanges = createSelector(
  [(state) => state.profile.userInfo.legal_documents_changes],
  (legalDocumentsChanges) => legalDocumentsChanges,
);

export const getIsFetchingRating = createSelector(
  [(state) => state.profile.isFetchingRating],
  (getIsFetchingRating) => getIsFetchingRating,
);

export const getAchievementsStats = createSelector(
  [(state) => state.profile.userInfo.achievements_stats],
  (achievements_stats) => achievements_stats,
);

export const getOppaWalletSelector = createSelector(
  [(state) => state.profile.userInfo.wallet_number],
  (wallet_number) => wallet_number,
);

export const getOppaPersonalNumberSelector = createSelector(
  [(state) => state.bonusShop.transfers.personal_number],
  (personal_number) => personal_number,
);

export const getUnicardAccountNumber = createSelector([(state) => state.profile.userInfo.accounts], (accounts) => {
  if (accounts) {
    return accounts.find((account) => account.shop === 'UNICARD');
  } else {
    return null;
  }
});

export const getBAStatisticsSelector = createSelector(
  [(state) => state.profile.baStatistics],
  (baStatistics) => baStatistics,
);

export const getIsFetching = createSelector([(state) => state.profile.isFetching], (isFetching) => isFetching);

export const getInviteCode = createSelector(
  [(state) => state.profile.userInfo.invite_code],
  (invite_code) => invite_code,
);

export const getInviteCodesAmount = createSelector(
  [(state) => state.profile.userInfo.invite_codes_amount],
  (invite_codes_amount) => invite_codes_amount,
);

export const getUserPhoneNumber = createSelector([(state) => state.profile.userInfo.phone], (phone) => phone);

export const getGoalTrackers = createSelector(
  [(state) => state.profile.userInfo.goal_trackers],
  (goal_trackers) => goal_trackers,
);

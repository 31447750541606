export const InfoIcon = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" stroke="white" />
      <path
        d="M7 7.82242H8.69199C8.72945 7.05942 9.20395 6.56651 9.92196 6.56651C10.6337 6.56651 11.1082 7.03241 11.1082 7.67387C11.1082 8.31533 10.8647 8.64619 10.0593 9.1661C9.19771 9.71303 8.85432 10.3207 8.93548 11.3808L8.94797 11.7725H10.6025V11.4146C10.6025 10.7529 10.8335 10.422 11.6639 9.90209C12.5442 9.34166 13 8.63268 13 7.6131C13 6.0736 11.82 5 10.0343 5C8.11759 5 7.03122 6.16138 7 7.82242ZM9.79709 15C10.3652 15 10.821 14.5341 10.821 13.9399C10.821 13.3457 10.3652 12.8798 9.79709 12.8798C9.23517 12.8798 8.7794 13.3457 8.7794 13.9399C8.7794 14.5341 9.23517 15 9.79709 15Z"
        fill="white"
      />
    </svg>
  );
};

import { UncheckedNotificationBadge } from '../common/UncheckedNotificationBadge/UnchakedNotificationBadge';
import { IKBCategory } from '../../pages/knowledgeBank/knowledgeBankTypes';

import styles from './KnowledgeBankCategory.module.scss';

type Props = {
  category: IKBCategory;
  onChoose: () => void;
};

export const KnowledgeBankCategory = ({ category: { name, icon, unread_count }, onChoose }: Props) => {
  return (
    <div className={styles.container} onClick={onChoose}>
      <img className={styles.icon} src={icon} alt="category image" />
      <p className={styles.name}>{name}</p>
      {unread_count > 0 && (
        <UncheckedNotificationBadge withoutTheme customClass={styles.unreadBadge}>
          {unread_count}
        </UncheckedNotificationBadge>
      )}
    </div>
  );
};

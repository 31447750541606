import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import './salesTipsPage.scss';

import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { Header } from '../../components/common/Header';
import { Routes } from '../../const';
import {
  getSelectedTipStepsStepsSelector,
  getSaleTipByIdSelector,
  getIsFetching,
} from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { CheckIcon } from '../../icons';
import { specialQuizActions } from '../../redux/specialQuizes/specialQuizesSlice';
import { SPECIAL_QUIZ_MODS } from '../../redux/specialQuizes/specialQuizesConstants';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';

export const TipStepsPageComponent = ({ match, history, location }) => {
  const selectedTipId = match.params.id;
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetching);
  const salesTipSteps = useSelector(getSelectedTipStepsStepsSelector);
  const selectedTip = useSelector((state) => getSaleTipByIdSelector(state, selectedTipId));
  const { formatMessage } = useInternationalization();

  const [availableTips, setAvailableTips] = useState(0);

  const onBackCallback = useCallback(() => {
    history.replace(Routes.KnowledgeBankRoute + Routes.SalesTips);
  }, [history]);

  const onStepClickCallback = useCallback(
    (step) => {
      if (step.url) {
        dispatch(knowledgeBankActions.openArticle({ article: { ...step, isSalesTip: true } }));
      } else if (step.special_quiz_id) {
        dispatch(specialQuizActions.setSpecQuizGameMode({ gameMode: SPECIAL_QUIZ_MODS.SALES_TIP }));
        dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id: step.special_quiz_id, brand_id: 'sale-tip' }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(knowledgeBankActions.getSalesTipSteps({ selectedTipId }));
  }, [dispatch, selectedTipId]);

  useEffect(() => {
    let undoneTips = salesTipSteps.filter((i) => i.is_new);

    setAvailableTips(salesTipSteps.length - undoneTips.length);
  }, [salesTipSteps]);

  return (
    <div>
      <ScrollBlock fromTop>
        <Header hasBackButton goBackCallback={onBackCallback}>
          <p className="knowledge-bank-page__header-text">{selectedTip.name}</p>
        </Header>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div className="sales-tips__body">
            <div className="sales-tips__tip-steps">
              {salesTipSteps.map((i, index) => (
                <div key={i.id} onClick={index > availableTips ? () => {} : () => onStepClickCallback(i)}>
                  <div className={`sales-tips__tip-step-row${index > availableTips ? '-unavailable' : ''}`}>
                    <div className="sales-tips__tip-step-row-text">
                      <div className="sales-tips__tip-step-row-header">
                        {formatMessage('Step {index}', { index: index + 1 })}
                      </div>
                      <div className="sales-tips__tip-step-row-info">{i.name}</div>
                    </div>
                    {!i.is_new && (
                      <div className="sales-tips__done-step-icon-container">
                        <CheckIcon className="sales-tips__done-step-icon" />
                      </div>
                    )}
                  </div>
                  <div className="sales-tips__divider" />
                </div>
              ))}
            </div>
          </div>
        )}
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const TipStepsPage = withRouter(TipStepsPageComponent);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  isNotificationVisible,
  notificationMessage,
} from '../../../redux/notificationMessage/notificationMessageSelectors';

import { notificationMessageActions } from '../../../redux/notificationMessage/notificationMessageSlice';
import styles from './NotificationMessage.module.scss';

const CLOSE_NOTIFICATION_TIME = 2500;

const NotificationMessage = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector(isNotificationVisible);
  const notificationMesage = useSelector(notificationMessage);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isVisible) {
      timeoutId = setTimeout(() => {
        dispatch(notificationMessageActions.hideNotificationMessage());
      }, CLOSE_NOTIFICATION_TIME);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isVisible]);
  return (
    <>
      {isVisible && (
        <div className={styles.notificationMessageBlock}>
          <div className={styles.notificationMessageText}>{notificationMesage}</div>
        </div>
      )}
    </>
  );
};

export default NotificationMessage;

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useInternationalization } from '../../../hooks/useTranslationHook';

import { Button } from '../../common';

import { MODALS } from '../../containers/Modal/modalConstants';
import { modalActions } from '../../containers/Modal/modalSlice';
import { ModalContainer } from '../../containers/Modal/ModalContainer';

import { birthdaySelectors } from '../../../redux/birthday/birthdaySelectors';

import star from './../../../images/star.svg';
import styles from './HappyBirthdayModal.module.scss';

const HappyBirthdayModal = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const coins = useSelector(birthdaySelectors.selectCoins);

  const onClick = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <ModalContainer modal={MODALS.happyBirthday} customStyles={''}>
      <div className={styles.container}>
        <img className={styles.starImage} src={star} alt="star" />
        <span className={styles.reward}>{formatMessage('+{coins} credits', { coins })}</span>
        <span className={styles.description}>{formatMessage('Congratulations!')}</span>
        <Button className={styles.acceptButton} onClick={onClick}>
          {formatMessage('OK')}
        </Button>
      </div>
    </ModalContainer>
  );
};

export default HappyBirthdayModal;

import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { selectChances } from '../../redux/chance/chanceSelectors';
import { Routes } from '../../const';
import { APP_TYPES } from '../../const/appConstants';
import { RightArrow } from '../../icons';

import './chanceCard.scss';

export const ChanceCard = () => {
  const { formatMessage } = useInternationalization();

  const chances = useSelector(selectChances);
  const chanceId = chances[0]?.id;

  if (process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY || process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO) {
    return (
      <div className="chance-block__link">
        <Link to={`${Routes.ChanceRoute}/${chanceId}`}>
          <div className="chance-block">
            <div className="chance-block__container">
              <div className="chance-block__container-info">
                {formatMessage('Chance')}
                <div className="chance-block__container-info-caption">
                  {formatMessage('Not enough points? Try your luck!')}
                </div>
              </div>

              <div className="chance-block__container-arrow">
                <RightArrow />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  return null;
};

import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import 'swiper/css';

import { CAUCASUS, APP_TYPES } from './const/appConstants';
import './translations/locales';
import './App.scss';

import {
  ArticlePage,
  ChancePage,
  Home,
  KnowledgeBank,
  KnowledgeBankArticles,
  ProfileLayout,
  ProfileSettings,
  ShopMiddleware,
  AuthPage,
  NoBonusGamesInfo,
  BonusShopCategory,
  SuccessfulProductOrder,
  QuizPage,
  MainKBBrands,
  QuestionOfTheDayPage,
  SpecialQuizPage,
  EventModePage,
  SpecialQuizNoBonusGame,
  SoloModePage,
  SingleModePage,
  DailyRewardsPage,
  SalesTipsPage,
  TipStepsPage,
  MainPage,
  MultibrandQuizPage,
  LoyaltyProgramPage,
  ManagerPage,
  CheckInFAQ,
  CheckInInfoPage,
  SalesSimulatorsPage,
  ScenariosListPage,
  AddressRegistration,
  PassportStatement,
  PassportSigning,
  PassportSuccess,
  GetPassportPhoto,
  PassportSettings,
  BonusShopLimitPage,
  InviteCodePage,
  MoneyForThePhone,
  PrivacyPolicyUpdate,
  MoneyForTheWallet,
  DigitalFramePhoto,
  BoostersPage,
  AchievementsPage,
  SelectedAchievementPage,
  OPPA,
  M10,
  PERXMainPage,
  InteractiveArticle,
  ScenarioPage,
  BannerPage,
  Challenges,
  DailyGame,
  Unicard,
  MonthlyConfirmationPage,
  ColleaguesInvitation,
  Registration,
} from './pages';
import { ErrorPage, SuccessPage } from './views';
import { Routes } from './const';

import { AuthorizedRoute } from './components/AuthorizedRoute';
import { NonAuthorizedRoute } from './components/NonAuthorizedRoute/NonAuthorizedRoute';

import { isAuthorizedSelector } from './redux/auth/authSelectors';
import { serviceActions } from './redux/service/serviceSlice';
import { PromoCode } from './pages/promoCode/promoCode';
import { Modals } from './components/containers/Modal/Modals';
import { useDeviceOrientation } from './hooks/useDeviceOrientationHook';
import { Challenge } from './pages/challenge/Challenge';
import { Invitation } from './pages/Invitation/invitation';
import { analyticHelper } from './helpers/analyticHelper';
import { checkIsIphoneX } from './helpers/checkIsIphoneX';
import { SurveyPage } from './pages/quiz/Survey/surveyPage';
import { NoBonusSurveyComponent } from './pages/quiz/Survey/noBonusSurveyComponent';
import { IosAddToHomescreenBaner } from './components/common/IosAddToHomescreenBaner/IosAddToHomescreenBaner';
import { EsignRedirectPage } from './pages/auth/esignPage/esignRedirectPage';
import { AchievementTutorial } from './pages/achievements/achievementsTutorial/achievementsTutorial';
import { LepStart } from './pages/LEP/Steps/LepStart';
import { OppaTutorial } from './pages/Cashout/OPPA/oppaTutorial/oppaTutorial';
import { ProductImage } from './components';
import NotificationMessage from './components/common/NotificationMessage';

import { Notifications, TicketChat, Support, SupportChat, BAChat } from './pages/chat';
import { Recognition } from './pages/recognition';
import { ChatRoutes } from './const';
import { QuestChallenge } from './pages/challenge/containers/challenge/QuestChallenge';
import { isHungary, isIreland, isTaiwan } from './helpers';
import BrandInformationSlider from './pages/KBBrands/BrandInforamtionSlider/BrandInformationSlider';
import { QuickTips } from './pages/quickTips/QuickTips';
import QuickTipResult from './pages/quickTips/result/QuickTipResult';
import { KnowledgeBankHungary } from './pages/knowledgeBank/KnowledgeBankHungary/KnowledgeBankHungary';
import { IdCard } from './pages/idCard/IdCard';
import { IdCardInfo } from './pages/idCard/IdCardInfo/IdCardInfo';
import { IdCardResult } from './pages/idCard/IdCardResult/IdCardResult';
import { Faq } from './pages/recognition/components/Faq/Faq';
import { MysteryShopperList } from './pages/mysteryShopper/MysteryShopperList/MysteryShopperList';
import { Recommendations } from './pages/mysteryShopper/Recommendations/Recommendations';
import { GetResult } from './pages/mysteryShopper/GetResult/GetResult';
import { Moderation } from './pages/mysteryShopper/Moderation/Moderation';
import { Start } from './pages/mysteryShopper/Start/Start';
import { Quiz } from './pages/mysteryShopper/Quiz/Quiz';
import { PassedMysteryShopperList } from './pages/mysteryShopper/PassedMysteryShopperList/PassedMysteryShopperList';
import { ChanceList } from './pages/chancePage/ChanceList/ChanceList';
import { Epul } from './pages/Cashout/epul/Epul';
import { ModerationPageContainer } from './pages/moderation';
import CashoutDemo from './pages/Cashout/CashoutDemo/CashoutDemo';
import { CONFIG_KEYS, isFeatureEnabled } from './config';
import VoucherDetails from './pages/VoucherDetails/VoucherDetails';
import { SupportStub } from './pages/chat/containers/supportStub/SupportStub';
import { useTheme } from './hooks/useTheme';
import usePushSubscription from './hooks/usePushSubscription';
import { settingsActions } from './redux/settings/settingsSlice';
import { GAME_MODES } from './redux/settings/settingsConstants';
import OrderDetails from './pages/OrderDetails/OrderDetails';
import PromoCodeScan from './pages/PromoCodeScan/PromoCodeScan';
import PasswordChangePage from './pages/PasswordChange/PasswordChange';

function App(props) {
  useTheme();

  const dispatch = useDispatch();
  const { isAuthorized, location } = props;
  const theme = useSelector((state) => state.service.theme);
  const [showIosBaner, setShowIosBaner] = useState(false);

  useDeviceOrientation(dispatch);
  usePushSubscription();

  useEffect(() => {
    dispatch(serviceActions.getLinks());
    dispatch(serviceActions.setThemeLocal());
    dispatch(settingsActions.setGameMode(GAME_MODES.SOLO));
    analyticHelper.sessionLaunch();
  }, [dispatch]);

  useEffect(() => {
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isInStandaloneMode = () => 'standalone' in window.navigator && window.navigator.standalone;

    if (isIos() && !isInStandaloneMode() && isFeatureEnabled(CONFIG_KEYS.IOS_ADD_TO_HOME_SCREEN_BANNER_ENABLED)) {
      setShowIosBaner(true);
      setTimeout(() => {
        setShowIosBaner(false);
      }, 10000);
    }
  }, []);

  const getChatRoutes = () => {
    return [
      <AuthorizedRoute
        key="supportChat"
        path={`${ChatRoutes.Support}/:id`}
        component={isFeatureEnabled(CONFIG_KEYS.SUPPORT_WITH_TICKETS_ENABLED) ? TicketChat : SupportStub}
        isAuthorized={isAuthorized}
        withNavigation
      />,
      <AuthorizedRoute
        key="support"
        path={ChatRoutes.Support}
        component={isFeatureEnabled(CONFIG_KEYS.SUPPORT_WITH_TICKETS_ENABLED) ? Support : SupportStub}
        isAuthorized={isAuthorized}
        withNavigation
        exact
      />,
      <AuthorizedRoute
        key="notifications"
        path={ChatRoutes.Notifications}
        component={Notifications}
        isAuthorized={isAuthorized}
        withNavigation
        exact
      />,
    ];
  };

  return (
    <div className={classnames(`theme${theme.id}`, checkIsIphoneX() ? 'iphoneXTheme' : '')}>
      <Switch location={location}>
        <NonAuthorizedRoute exact={true} path={'/'} component={AuthPage} isAuthorized={isAuthorized} />
        <NonAuthorizedRoute path={'/login'} component={EsignRedirectPage} isAuthorized={isAuthorized} />
        {getChatRoutes()}
        <AuthorizedRoute
          path={Routes.MonthlyConfirmation}
          component={MonthlyConfirmationPage}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          exact
          path={Routes.PasswordChange}
          component={PasswordChangePage}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute exact path={Routes.M10MainRoute} component={M10} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.RecognitionFaq} component={Faq} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.Recognition} component={Recognition} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.MainRoute} component={MainPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.SuccessPage} component={SuccessPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.ErrorPage} component={ErrorPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.BonusShopRoute} exact component={ShopMiddleware} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.OrderDetails} exact component={OrderDetails} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.MyVouchers} component={ShopMiddleware} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.PrizesRoute} component={ShopMiddleware} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.BalanceRoute} component={ShopMiddleware} isAuthorized={isAuthorized} />
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.AZERBAIJAN && (
          <AuthorizedRoute path={Routes.MoneyForThePhone} component={MoneyForThePhone} isAuthorized={isAuthorized} />
        )}
        <AuthorizedRoute path={Routes.BrandsRoute} component={Home} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.GetPassportPhoto} component={GetPassportPhoto} isAuthorized={isAuthorized} />
        <AuthorizedRoute
          path={Routes.AddressRegistration}
          component={AddressRegistration}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute path={Routes.PassportStatement} component={PassportStatement} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.PassportSigning} component={PassportSigning} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.PassportSuccess} component={PassportSuccess} isAuthorized={isAuthorized} />
        <AuthorizedRoute
          path={`${Routes.BonusShopRoute}/:id`}
          component={BonusShopCategory}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute path={`${Routes.VoucherDetails}/:id`} component={VoucherDetails} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.IdCard} isAuthorized={isAuthorized} component={IdCard} />
        <AuthorizedRoute path={Routes.IdCardInfo} isAuthorized={isAuthorized} component={IdCardInfo} />
        <AuthorizedRoute path={Routes.IdCardResult} isAuthorized={isAuthorized} component={IdCardResult} />
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
          <AuthorizedRoute path={Routes.Image} component={ProductImage} isAuthorized={isAuthorized} />
        )}
        <AuthorizedRoute
          path={`${Routes.NoBonusGamesRoutes}/:brandId`}
          component={NoBonusGamesInfo}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={`${Routes.NoBonusSpecialQuizRoute}/:brandId`}
          component={SpecialQuizNoBonusGame}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={Routes.SuccessfulOrderRoute}
          component={SuccessfulProductOrder}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={Routes.KnowledgeBankRoute}
          exact
          component={isHungary() ? KnowledgeBankHungary : KnowledgeBank}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={Routes.BrandingBanRoute}
          exact
          component={KnowledgeBankHungary}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={Routes.noBonusSurveyRoute}
          component={NoBonusSurveyComponent}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={`${Routes.KnowledgeBankRoute}/:id/brands`}
          exact
          component={MainKBBrands}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          exact
          path={Routes.KnowledgeBankRoute + Routes.SalesTips}
          component={SalesTipsPage}
          isAuthorized={isAuthorized}
        />
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute
            exact
            path={Routes.KnowledgeBankRoute + Routes.SalesSimulators}
            component={SalesSimulatorsPage}
            isAuthorized={isAuthorized}
          />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute
            exact
            path={`${Routes.KnowledgeBankRoute + Routes.SalesSimulators}/:id`}
            component={ScenariosListPage}
            isAuthorized={isAuthorized}
          />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute
            exact
            path={Routes.PassportSettings}
            component={PassportSettings}
            isAuthorized={isAuthorized}
          />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute exact path={Routes.ScenarioRoute} component={ScenarioPage} isAuthorized={isAuthorized} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute
            exact
            path={Routes.BonusShopLimit}
            component={BonusShopLimitPage}
            isAuthorized={isAuthorized}
          />
        )}
        <AuthorizedRoute
          exact
          path={`${Routes.KnowledgeBankRoute + Routes.SalesTips}/:id`}
          component={TipStepsPage}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          exact
          path={`${Routes.KnowledgeBankRoute}/:id`}
          component={KnowledgeBankArticles}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          exact
          path={`${Routes.KnowledgeBankRoute}/:id/brands/:brand_id`}
          component={KnowledgeBankArticles}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={`${Routes.BrandInformation}/:id`}
          component={BrandInformationSlider}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={Routes.QuestionOfTheDayRoute}
          component={QuestionOfTheDayPage}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={`${Routes.SpecialQuizRoute}/:brandId`}
          component={SpecialQuizPage}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={`${Routes.InvitationRoute}/:brandId`}
          component={Invitation}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute path={`${Routes.SurveyRoute}/:survey_id`} component={SurveyPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.DailyRewardsRoute} component={DailyRewardsPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={`${Routes.QuizRoute}/:brandId`} component={QuizPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.QuickTips} component={QuickTips} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.QuickTipsResult} isAuthorized={isAuthorized} component={QuickTipResult} />
        <AuthorizedRoute path={Routes.MysteryShopperStartPage} component={Start} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.MysteryShopperQuizPage} component={Quiz} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.MysteryShopperList} component={MysteryShopperList} isAuthorized={isAuthorized} />
        <AuthorizedRoute
          path={Routes.PassedMysteryShopperList}
          component={PassedMysteryShopperList}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute
          path={`${Routes.MysteryShopperRecommendations}/:id`}
          component={Recommendations}
          isAuthorized={isAuthorized}
        />
        <AuthorizedRoute path={Routes.MysteryShopperResult} component={GetResult} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.MysteryShopperModeration} component={Moderation} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.ArticleRoute} component={ArticlePage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.BannerRoute} component={BannerPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.DailyGame} component={DailyGame} isAuthorized={isAuthorized} />

        {isFeatureEnabled(CONFIG_KEYS.INTERACTIVE_ARTICLE_ENABLED) && (
          <AuthorizedRoute
            path={Routes.InteractiveArticleRoute}
            component={InteractiveArticle}
            isAuthorized={isAuthorized}
          />
        )}

        <AuthorizedRoute path={`${Routes.ChanceRoute}/:id`} component={ChancePage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.ChanceList} component={ChanceList} isAuthorized={isAuthorized} />
        <AuthorizedRoute
          path={Routes.ProfileRoute}
          component={ProfileLayout}
          isAuthorized={isAuthorized}
          withNavigation
        />
        {(process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND ||
          process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY ||
          process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO) && (
          <AuthorizedRoute
            path={Routes.ProgressRoute}
            component={ProfileLayout}
            isAuthorized={isAuthorized}
            withNavigation
          />
        )}
        <AuthorizedRoute path={Routes.ProfileSettingsRoute} component={ProfileSettings} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.PromoCodeRoute} component={PromoCode} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.PromoCodeScan} component={PromoCodeScan} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={`${Routes.SoloMode}/:brandId`} component={SoloModePage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.ChallengeRoute} component={Challenge} isAuthorized={isAuthorized} exact />
        <AuthorizedRoute
          path={Routes.ColleaguesInvitation}
          component={ColleaguesInvitation}
          isAuthorized={isAuthorized}
          exact
        />
        <NonAuthorizedRoute path={Routes.SelfRegistration} component={Registration} isAuthorized={isAuthorized} />
        {isFeatureEnabled(CONFIG_KEYS.CASHOUT_DEMO_ENABLED) && (
          <Route exact={true} path={Routes.CashoutDemo} component={CashoutDemo} />
        )}
        {isFeatureEnabled(CONFIG_KEYS.TEAM_CHALLENGES_ENABLED) && (
          <>
            <AuthorizedRoute path={Routes.ChallengesRoute} component={Challenges} isAuthorized={isAuthorized} />
            <AuthorizedRoute
              path={`${Routes.ChallengeRoute}/:challengeId`}
              component={QuestChallenge}
              isAuthorized={isAuthorized}
              exact
            />
          </>
        )}
        <AuthorizedRoute path={Routes.EventModeRoute} component={EventModePage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.SingleMode} component={SingleModePage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.MultibrandQuizRoute} component={MultibrandQuizPage} isAuthorized={isAuthorized} />
        <AuthorizedRoute path={Routes.LoyaltyProgramRoute} component={LoyaltyProgramPage} isAuthorized={isAuthorized} />
        {(CAUCASUS.includes(process.env.REACT_APP_APP_TYPE) ||
          process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY ||
          process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS) && (
          <AuthorizedRoute path={Routes.ManagerPageRoute} component={ManagerPage} isAuthorized={isAuthorized} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute path={Routes.CheckInFAQRoute} component={CheckInFAQ} isAuthorized={isAuthorized} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute path={Routes.CheckInInfoRoute} component={CheckInInfoPage} isAuthorized={isAuthorized} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS && (
          <AuthorizedRoute
            path={Routes.CheckInStatusRoute}
            component={ProfileLayout}
            isAuthorized={isAuthorized}
            withNavigation
          />
        )}
        {(process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS ||
          process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY ||
          process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) && (
          <AuthorizedRoute path={Routes.InviteCodeRoute} component={InviteCodePage} isAuthorized={isAuthorized} />
        )}
        <AuthorizedRoute
          path={Routes.PrivacyPolicyUpdate}
          component={PrivacyPolicyUpdate}
          isAuthorized={isAuthorized}
        />
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA && (
          <AuthorizedRoute path={Routes.MoneyForTheWallet} component={MoneyForTheWallet} isAuthorized={isAuthorized} />
        )}
        {(isHungary() || isIreland()) && (
          <AuthorizedRoute path={Routes.DigitalFramePhoto} component={DigitalFramePhoto} isAuthorized={true} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
          <AuthorizedRoute path={Routes.Boosters} component={BoostersPage} isAuthorized={true} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
          <AuthorizedRoute path={Routes.Achievements} component={AchievementsPage} isAuthorized={true} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
          <AuthorizedRoute
            path={`${Routes.SelectedAchievement}/:id`}
            component={SelectedAchievementPage}
            isAuthorized={true}
          />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
          <Route exact={true} path={Routes.LEP} component={LepStart} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && (
          <Route exact={true} path={Routes.PERX} component={PERXMainPage} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA && (
          <AuthorizedRoute exact path={Routes.OPPAMainRoute} component={OPPA} isAuthorized={isAuthorized} />
        )}
        {process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA && (
          <AuthorizedRoute exact path={Routes.Unicard} component={Unicard} isAuthorized={isAuthorized} />
        )}
        <AuthorizedRoute exact path={Routes.Epul} component={Epul} isAuthorized={isAuthorized} />

        {isHungary() && (
          <AuthorizedRoute
            exact
            path={Routes.Moderation}
            component={ModerationPageContainer}
            isAuthorized={isAuthorized}
          />
        )}
        {isHungary() && (
          <AuthorizedRoute exact path={Routes.BaStatistic} component={ProfileLayout} isAuthorized={isAuthorized} />
        )}
        <Redirect to={Routes.MainRoute} />
      </Switch>
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
        <AuthorizedRoute component={AchievementTutorial} isAuthorized={true} />
      )}
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA && (
        <AuthorizedRoute exact path={Routes.OPPAMainRoute} component={OppaTutorial} isAuthorized={true} />
      )}
      {showIosBaner && <IosAddToHomescreenBaner />}
      <Modals />
      <NotificationMessage />
    </div>
  );
}

App.propTypes = {
  isAuthorized: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthorized: isAuthorizedSelector(state),
});

export default connect(mapStateToProps)(App);

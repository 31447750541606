import React from 'react';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { getWordsFormat } from '../../helpers/getWordsFormat';
import { Voucher } from '../../types/Voucher';

interface VouchersCarouselItemProps {
  voucher: Voucher;
  onClick: (voucher: Voucher) => void;
}

const VouchersCarouselItem = ({ voucher, onClick }: VouchersCarouselItemProps) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className="gallery-item">
      <div className="image" style={{ backgroundImage: `url(${voucher.image})` }} />
      <div className="info">
        <div>
          <p className="price">
            {voucher.price} {formatMessage(getWordsFormat(voucher.price, 'points'))}
          </p>
          <hr />
          <p className="name">{voucher.name}</p>
          <p className="description">{voucher.description}</p>
        </div>
        <button className="themed-background" onClick={() => onClick(voucher)}>
          {formatMessage('Order')}
        </button>
      </div>
    </div>
  );
};

export default VouchersCarouselItem;

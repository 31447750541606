import { all, fork, put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { selectPathname } from '../router/routerSelectors';
import { ChatTab, MessageType, RawChatMessage } from './chatTypes';
import { mapMessage } from './chatServices';
import { notificationsSagas } from './notifications/notificationsSagas';
import { supportSagas } from './support/supportSagas';
import { supportChatSagas } from './supportChat/supportChatSagas';
import { ticketChatSagas } from './ticketChat/ticketChatSagas';
import { notificationsActions } from './notifications/notificationsSlice';
import { ticketChatActions } from './ticketChat/ticketChatSlice';
import { supportChatActions } from './supportChat/supportChatSlice';
import { baChatSagas } from './BaChat/BaChatSagas';
import { chatActions } from './chatSlice';
import { chatApi } from '../../api/chatApi';
import { select } from 'redux-saga/effects';
import { supportActions } from './support/supportSlice';
import { ChatRoutes } from '../../const';

function* handleSocket(action: PayloadAction<RawChatMessage>) {
  const message = mapMessage(action.payload);
  const pathname: string = yield select(selectPathname);

  if (message.ticketId) {
    if (pathname === ChatRoutes.Support) {
      yield put(supportActions.updateTicket({ id: message.ticketId, changes: { hasNewMessages: true } }));
    }

    yield put(ticketChatActions.messageReceived(message));
    yield put(chatActions.incrementUnreadMessagesCount(ChatTab.Support));
  } else {
    if (message.type === MessageType.Chat) {
      yield put(supportChatActions.messageReceived(message));
      yield put(chatActions.incrementUnreadMessagesCount(ChatTab.Support));
    } else {
      yield put(notificationsActions.messageReceived(message));
      yield put(chatActions.incrementUnreadMessagesCount(ChatTab.Notifications));
    }
  }
}

function* fetchUnreadMessagesCount() {
  try {
    const { data } = yield chatApi.getUnreadCount();

    yield put(chatActions.setUnreadMessagesCount(data.count));
  } catch (error) {
    console.log('fetchUnreadMessagesCount error', error);
  }
}

function* updateLastCheckedTicketMessage(action: PayloadAction<{ ticketId: string }>) {
  const { ticketId } = action.payload;
  try {
    yield chatApi.updateLastCheckedTicketMessage(ticketId);
  } catch (error) {
    console.log('updateLstCheckedTicketMessage error', error);
  }
}

export function* chatSagas() {
  yield all([
    takeLatest(chatActions.handleSocket.type, handleSocket),
    takeLatest(chatActions.fetchUnreadMessagesCount.type, fetchUnreadMessagesCount),
    takeLatest(chatActions.updateLastCheckedTicketMessage.type, updateLastCheckedTicketMessage),
    fork(notificationsSagas),
    fork(supportSagas),
    fork(ticketChatSagas),
    fork(supportChatSagas),
    fork(baChatSagas),
  ]);
}

import { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, reset, change } from 'redux-form';
import { useSelector } from 'react-redux';

import '../signupPage.scss';

import { ArrowIconRounded } from '../../../../icons/arrow-icon-rounded';
import { Button } from '../../../../components/common/Button';
import { Input } from '../../../../components/common/Input';
import { Select } from '../../../../components/common/Select';
import { ArrowButton } from '../../../../components/common/ArrowButton/ArrowButton';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getPhoneCode } from '../../../../redux/auth/authConstants';
import { required, numbers } from '../../../../helpers/formValidations';

import { authActions } from '../../../../redux/auth/authSlice';
import {
  getManagersSelectors,
  getOutletsSelector,
  getManagersCitiesSelectors,
} from '../../../../redux/service/serviceSelectors';
import { authApi } from '../../../../api/authApi';
import { serviceActions } from '../../../../redux/service/serviceSlice';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

let otherValues = {};

const Signup2ndStageComponent = (props) => {
  const { handleSubmit, formObj, signupForm, invalid, phone } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(serviceActions.getOutletsInfo());
  }, [dispatch]);

  const managers = useSelector(getManagersSelectors);
  const cities = useSelector(getManagersCitiesSelectors);
  const outlets = useSelector(getOutletsSelector);

  otherValues.city = formatMessage('Other city');
  otherValues.legalEntity = formatMessage('Other legal entity');
  otherValues.outletAddress = formatMessage('Other outlet address');
  otherValues.tmr = formatMessage('Other TMR');

  const managersInSelectedCity = managers.filter((i) => i.city_id === Number(formObj.values.city_id));
  const outletsInSelectedCity = outlets.filter((i) => i.city_id === Number(formObj.values.city_id));
  const outletsToShow = outletsInSelectedCity.map((i) => {
    return { id: i.id, name: `${i.street} ${i.building_number}` };
  });

  useEffect(() => {
    if (formObj.values.account_code) {
      dispatch(change('signupSecondStageForm', 'legal_id', ''));
    }
    return;
  }, [formObj.values.account_code, dispatch]);

  useEffect(() => {
    if (formObj.values.legal_id) {
      dispatch(change('signupSecondStageForm', 'account_code', ''));
    }
    return;
  }, [formObj.values.legal_id, dispatch]);

  useEffect(() => {
    const { outlet_address_id, city_id } = formObj.values;
    if (!outlet_address_id) {
      return;
    }
    if (outlet_address_id !== otherValues.outletAddress) {
      const outlet = outletsInSelectedCity.find((i) => i.id === Number(outlet_address_id));
      if (!outlet || outlet.city_id !== Number(city_id)) {
        return;
      }
    }
    if (outlet_address_id === otherValues.outletAddress || city_id === otherValues.city) {
      dispatch(change('signupSecondStageForm', 'manager_id', otherValues.tmr));
    } else {
      const managerInSelectedOutlet =
        outletsInSelectedCity.find((i) => i.id === Number(outlet_address_id)).manager_id ?? otherValues.tmr;
      dispatch(change('signupSecondStageForm', 'manager_id', managerInSelectedOutlet.toString()));
    }
  }, [formObj.values, outletsInSelectedCity, dispatch]);

  useEffect(() => {
    return function cleanup() {
      dispatch(change('signupSecondStageForm', 'manager_id', ''));
    };
  }, [dispatch]);

  const validateAccountCodeCallback = useCallback(() => {
    if (
      (formObj.values.account_code || formObj.values.legal_id) &&
      (!isNaN(Number(formObj.values.account_code)) || !isNaN(Number(formObj.values.legal_id)))
    ) {
      return authApi
        .validateAccountCode({
          username: signupForm.values.username,
          date_of_birth: signupForm.values.date_of_birth,
          invite_code: signupForm.values.invite_code,
          account_code: formObj.values.account_code,
          legal_id: formObj.values.legal_id,
        })
        .then((response) => {
          const { data } = response.data;
          if (data) {
            dispatch(change('signupSecondStageForm', 'city_id', data.city_id));
            dispatch(change('signupSecondStageForm', 'outlet_address_id', data.outlet_address_id));
            dispatch(change('signupSecondStageForm', 'manager_id', data.manager_id));
          }
        });
    }
  }, [dispatch, formObj, signupForm.values]);

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupSecondStageForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 3,
      }),
    );
  }, [dispatch]);

  const submit = () => {
    let secondFormValues = {};
    for (let i in formObj.values) {
      if (Object.values(otherValues).includes(formObj.values[i])) {
        secondFormValues[i] = null;
      } else {
        secondFormValues[i] = formObj.values[i];
      }
    }
    return authApi.signup({ ...signupForm.values, ...secondFormValues, phone: getPhoneCode() + phone }).then(
      (response) => {
        dispatch(authActions.signupSuccess());
        dispatch(reset('signupForm'));
        dispatch(reset('signupSecondStageForm'));
      },
      (error) => {
        if (error.message === 'This username is already taken') {
          dispatch(
            modalActions.openPromptModal({
              title: formatMessage('This username has already taken'),
              description: [formatMessage('Please choose another one to complete the registration.')],
              btnText: formatMessage('OK'),
              onButtonClick: () => {
                dispatch(modalActions.closeModal());
              },
            }),
          );
        }
        if (error.response) {
          const { type } = error.response.data;
          if (type === 'INVITE_CODE_DOES_NOT_EXIST') {
            dispatch(modalActions.openWrongInviteCodeModal());
          }
        }
        dispatch(
          stepListActions.setStep({
            stepListId: STEP_LIST_IDS.authStepList,
            stepId: 3,
          }),
        );
      },
    );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="signup-page">
      <div className="signup-page__top-section">
        <Button
          withoutTheme
          type="button"
          className="signup-page__back-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{formatMessage('Fill in your outlet data')}</div>
        <Input
          name="account_code"
          type="phone"
          placeholder={formatMessage('Account code')}
          onBlur={() => validateAccountCodeCallback()}
          validate={[numbers]}
          customClass="signup-page__input"
        />
        <Input
          name="legal_id"
          type="phone"
          placeholder={formatMessage('Legal id')}
          onBlur={() => validateAccountCodeCallback()}
          validate={[numbers]}
          customClass="signup-page__input"
        />
        <Select
          name="city_id"
          placeholder={formatMessage('City')}
          options={cities}
          validate={[required]}
          additional_value={otherValues.city}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'city_id')}`}
        />
        <Select
          name="outlet_address_id"
          placeholder={formatMessage('Retail address')}
          options={outletsToShow}
          validate={[required]}
          additional_value={otherValues.outletAddress}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'outlet_address_id')}`}
        />
        <Select
          name="manager_id"
          placeholder={formatMessage('JTI representative')}
          options={managersInSelectedCity}
          additional_value={otherValues.tmr}
          validate={[required]}
          disabled={true}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'manager_id')}`}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme disabled={invalid}></ArrowButton>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    formObj: form.signupSecondStageForm || { values: {} },
    signupForm: form.signupForm,
    phone: form.enterPhoneForm.values.phone,
  };
};

const form = reduxForm({
  form: 'signupSecondStageForm',
  initialValues: {},
  destroyOnUnmount: false,
  onSubmit: () => {},
})(Signup2ndStageComponent);

export const SignupSecondStageCaucasus = connect(mapStateToProps, null)(form);

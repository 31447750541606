import { useState, useEffect } from 'react';
import { formatTimeToDateObject } from '../helpers/timesHelper';

export default function useTimer(timeLeft, setTimeLeft, launchInSaga) {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    function formatTime() {
      const { minutes, hours, days, seconds } = formatTimeToDateObject(timeLeft);
      setTime({ minutes, hours, days, seconds });
    }

    let timer;
    if (timeLeft && timeLeft >= 1000) {
      formatTime();
      timer = setInterval(setTimeLeft, 1000, timeLeft - 1000);
    } else if (timeLeft >= 0 && timeLeft < 1000) {
      formatTime();
      setTimeLeft(0);
    }

    return () => clearInterval(timer);
  }, [timeLeft, setTimeLeft, launchInSaga]);

  return time;
}

import { put, takeEvery, delay, select } from 'redux-saga/effects';
import { DAILY_REWARDS_ACTION_TYPES, DAILY_REWARDS_STEPS, DAILY_REWARDS_STATUS } from './dailyRewardsConstants';
import { dailyRewardsApi } from '../../api/dailyRewardsApi';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { dailyRewardsActions } from './dailyRewardsSlice';
import { GAME_ACTION_TYPES } from '../game/gameConstants';
import { getStatusDailyRewards } from './dailyRewardsSelectors';
import { soloModeActions } from '../soloMode/soloModeSlice';
import { APP_TYPES } from '../../const/appConstants';

export const goToDailyRewardsStepAction = (stepId) =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.dailyRewards,
    stepId,
  });

function* onFinishDailyRewardsSaga() {
  try {
    yield dailyRewardsApi.postDailyRewardsResult();
  } catch (error) {
    console.log(error);
  }
}

function* onStartDailyRewardsSaga() {
  try {
    if (process.env.REACT_APP_APP_TYPE !== APP_TYPES.HUNGARY) {
      return;
    }
    const { data } = yield dailyRewardsApi.getDailyRewards();
    yield put(dailyRewardsActions.setDailyRewardsData(data));
    if (data.status === DAILY_REWARDS_STATUS.ACTIVE) {
      yield put(dailyRewardsActions.setDailyRewardsResult({ product_id: data.rolled_product_id }));
      yield put(goToDailyRewardsStepAction(DAILY_REWARDS_STEPS.FINISH));
    }
  } catch (error) {
    console.log(error);
  }
}

function* onFinalDailyRewardsSaga() {
  try {
    yield delay(2500);
    yield put(goToDailyRewardsStepAction(DAILY_REWARDS_STEPS.FINISH));
  } catch (error) {
    console.log(error);
  }
}

function* onResultDailyRewardsSaga() {
  try {
    const drStatus = yield select(getStatusDailyRewards);
    if (drStatus !== DAILY_REWARDS_STATUS.ACTIVE) {
      const { data } = yield dailyRewardsApi.postDailyRewards();
      yield put(dailyRewardsActions.setDailyRewardsRolledProduct({ product_id: data.product_id }));
    }
    yield put(goToDailyRewardsStepAction(DAILY_REWARDS_STEPS.RESULT));
  } catch (error) {
    console.log(error);
  }
}

function* sagas() {
  yield takeEvery(GAME_ACTION_TYPES.SET_QUESTIONS, onStartDailyRewardsSaga);
  yield takeEvery(soloModeActions.setSoloModeData, onStartDailyRewardsSaga);
  yield takeEvery(DAILY_REWARDS_ACTION_TYPES.FINISH_DAILY_REWARDS, onFinishDailyRewardsSaga);
  yield takeEvery(DAILY_REWARDS_ACTION_TYPES.FINAL_DAILY_REWARDS, onFinalDailyRewardsSaga);
  yield takeEvery(DAILY_REWARDS_ACTION_TYPES.RESULT_DAILY_REWARDS, onResultDailyRewardsSaga);
}

export const dailyRewardsSagas = sagas;

import { createSelector } from 'reselect';

export const selectChances = createSelector([(state) => state.chance.chances], (chances) => chances);
export const selectIsFetching = createSelector([(state) => state.chance.isFetching], (isFetching) => isFetching);

export const selectChance = createSelector([(state) => state.chance.chance], (chance) => chance);

export const getIsPlayingSelector = createSelector([(state) => state.chance.isPlaying], (isPlaying) => isPlaying);

export const getResultSelector = createSelector([(state) => state.chance.result], (result) => result);

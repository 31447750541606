import React from 'react';

import { AvatarIconDefault } from '../../../icons';

import styles from './UserInfo.module.scss';

type Props = {
  userAvatar: string;
  username: string;
  color?: string;
};

const UserInfo = ({ userAvatar, username, color }: Props) => {
  return (
    <div className={styles.userInfoContainer}>
      {userAvatar ? (
        <img className={styles.avatarImage} src={userAvatar} style={{ borderColor: color }} alt="" />
      ) : (
        <AvatarIconDefault className={styles.avatarImage} stroke={color} withoutBorder />
      )}
      <div className={styles.username}>{username}</div>
    </div>
  );
};

export default UserInfo;

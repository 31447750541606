import { useDispatch, useSelector } from 'react-redux';
import {
  getDailyRewardsProducts,
  getDailyRewardsWithdrawProduct,
  getDailyRewardsData,
} from '../../../redux/dailyRewards/dailyRewardsSelectors';
import { Routes } from '../../../const';
import { dailyRewardsActions } from '../../../redux/dailyRewards/dailyRewardsSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getWordsFormat } from '../../../helpers/getWordsFormat';
import useDailyRewardsTimeLeft from '../../../hooks/useDailyRewardsTimeLeftHook';

export const DailyRewardsFinishPage = (props) => {
  const { history } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const dailyRewards = useSelector(getDailyRewardsData);
  const dailyProducts = useSelector(getDailyRewardsProducts);
  const withDrawProductId = useSelector(getDailyRewardsWithdrawProduct);
  const dailyProductWinner = dailyProducts.find((product) => product.id === withDrawProductId);
  const timeLeft = useDailyRewardsTimeLeft(dailyRewards.time_left);

  const goBack = () => {
    dispatch(dailyRewardsActions.finishDailyRewards());
    history.replace(Routes.MainRoute);
  };

  return (
    <div className="daily-rewards__finish-page">
      <div />
      <img
        alt="dailyRewardsSelectProductPhoto"
        src={dailyProductWinner.photo}
        className="daily-rewards__finish-page__image"
      />
      <div>
        <div className="daily-rewards__finish-page__points">
          {dailyProductWinner.coins
            ? formatMessage(getWordsFormat(dailyProductWinner.coins, `+{coins} points`), {
                coins: dailyProductWinner.coins,
              })
            : formatMessage(`${dailyProductWinner.name}`)}
        </div>
        <div className="daily-rewards__finish-page__content">
          {formatMessage(`Next daily reward will be available in`)}
          <div className="daily-rewards__finish-page__time-left">{` ${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`}</div>
        </div>
        <div className="daily-rewards__finish-page__button" onClick={() => goBack()}>
          {formatMessage('Got it')}
        </div>
      </div>
    </div>
  );
};

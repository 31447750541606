import React, { useState } from 'react';
import { v1 } from 'uuid';
import classnames from 'classnames';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { BackArrowButton } from '../BackArrowButton/BackArrowButton';
import { RootState } from '../../../redux/store';
import { ArrowButton } from '../ArrowButton/ArrowButton';
import { isAccepted } from '../../../helpers/formValidations';
import { CheckboxInput } from '../CheckboxInput/CheckboxInput';
import styles from './TermsAcceptance.module.scss';

type Props = {
  text: Text[];
  buttonText: string;
  progressBarImage?: string;
  onArrowButtonClick?: () => void;
  onBackArrowClick: () => void;
} & Partial<InjectedFormProps>;

type Text = {
  id: string;
  title: string;
  description: string;
};

const TermsAcceptance = ({
  progressBarImage,
  text,
  buttonText,
  invalid,
  onArrowButtonClick,
  onBackArrowClick,
  handleSubmit,
}: Props) => {
  const [isActive, setActive] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const { formatMessage } = useInternationalization();

  const onCheckboxClick = () => {
    setChecked(!isChecked);
    setActive(false);
  };

  const onClick = () => {
    if (onArrowButtonClick && !invalid) {
      onArrowButtonClick();
    }
    setActive(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.personalDataContainer}>
        {progressBarImage && <img src={progressBarImage} alt="progress bar" className={styles.progressBar} />}
        <BackArrowButton
          withoutTheme
          onHistoryReplaceCallback={onBackArrowClick}
          customClass={styles.backButton}
          buttonColor={'black'}
        />
        {text.map(({ title, description }: Text) => (
          <>
            <span className={styles.title}>{title}</span>
            <p className={styles.description}>{description}</p>
          </>
        ))}
        <div
          className={classnames(styles.checkBoxContainer, {
            [styles.active]: isActive && !isChecked,
            [styles.checked]: isChecked,
          })}
        >
          <CheckboxInput
            name="conditions"
            inputId={v1()}
            validate={[isAccepted]}
            customClass={styles.checkbox}
            customInputClass={styles.input}
            onClick={onCheckboxClick}
          />
          <span className={styles.text}>{formatMessage(`${buttonText}`)}</span>
        </div>
        {isActive && !isChecked && (
          <span className={styles.errorText}>
            {formatMessage('To use the application, you must accept the conditions')}
          </span>
        )}
        <ArrowButton fillcolor={'#01C1B6'} customClass={styles.nextArrowButton} withoutTheme onClick={onClick} />
      </div>
    </form>
  );
};

const mapStateToProps = (state: RootState) => ({});

export default connect(mapStateToProps)(
  reduxForm<{}, Props>({
    form: 'termsAcceptanceForm',
    onSubmit: () => {},
  })(TermsAcceptance),
);

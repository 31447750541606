export const SendMessageIcon = (props) => {
  return (
    <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.02032 1.5215C0.61305 0.855152 1.09261 0 1.87357 0H25.2118C26.087 0 26.54 1.04479 25.9417 1.68358L8.94456 19.832C8.37004 20.4454 7.34097 20.1195 7.22435 19.2872L6.14356 11.5734L12.5262 6.09641L4.81618 7.73202L1.02032 1.5215Z"
        fill="black"
      />
    </svg>
  );
};

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { specialQuizActions } from '../../../redux/specialQuizes/specialQuizesSlice';
import { COMPONENT_TYPES } from '../../../const/mainScreenConsts';

import './iconicItemComponent.scss';
import { Routes } from '../../../const';
import { questionOfTheDaySliceActions } from '../../../redux/questionOfTheDay/questionOfTheDaySlice';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { analyticHelper, MAIN_SCREEN_ACTIVITY_LABELS } from '../../../helpers/analyticHelper';
import { surveyActions } from '../../../redux/survey/surveySlice';
import { multibrandQuizActions } from '../../../redux/multibrandQuiz/multibrandQuizSlice';
import { singleModeActions } from '../../../redux/singleMode/singleModeSlice';
import { isHungary, isIreland } from '../../../helpers';
import { amplitudeHelper } from '../../../helpers/ampitudeHelper';
import { selectChances } from '../../../redux/chance/chanceSelectors';

export const ItemComponentBase = (props) => {
  const { payload, type, image, name, history, isProgressPage, has_more_than_one_quest: hasMoreThanOneQuest } = props;
  const { survey_id, is_new, special_quiz_id, brand, id, coins, question } = payload;

  const chances = useSelector(selectChances);
  const chanceId = chances[0]?.id;

  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const startSpecialQuiz = useCallback(
    (special_quiz) => {
      dispatch(
        specialQuizActions.startSpecialQuiz({
          special_quiz_id: special_quiz.id,
          brand_id: special_quiz.brand_id,
        }),
      );
    },
    [dispatch],
  );

  const openArticle = useCallback(
    (article) => {
      dispatch(knowledgeBankActions.openArticle({ article }));
    },
    [dispatch],
  );

  const progressPageActivityState = +is_new;

  const openQuestionOfTheDay = () => {
    dispatch(questionOfTheDaySliceActions.setQuestions({ question, is_new, coins }));
    dispatch(questionOfTheDaySliceActions.startQuestionOfTheDay());
    if (isProgressPage) {
      amplitudeHelper.profileQod();
      analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.QOD, progressPageActivityState);
    } else {
      analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.QOD);
    }
  };

  const openQuest = () => {
    if (isIreland() || isHungary()) {
      if (hasMoreThanOneQuest) {
        return Routes.ChallengesRoute;
      }
      return `${Routes.ChallengeRoute}/${payload.quest_id}`;
    }
    return Routes.ChallengeRoute;
  };

  switch (type) {
    case COMPONENT_TYPES.SURVEY:
    case COMPONENT_TYPES.KB_ARTICLE:
      return (
        <div
          className="item-container__item-logo-wrap"
          onClick={() => {
            if (!isProgressPage) amplitudeHelper.mainItemArticle();
          }}
        >
          <div
            className="item-container__item-logo"
            onClick={() => {
              if (survey_id) {
                if (is_new) {
                  dispatch(surveyActions.startSurvey({ survey_id, surveyStatus: is_new }));
                } else {
                  history.push(Routes.noBonusSurveyRoute);
                }
              } else if (special_quiz_id) {
                dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id, brand_id: brand.id }));
              } else {
                openArticle(payload);
              }
              isProgressPage
                ? analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.KB_ARTICLE, progressPageActivityState)
                : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_ARTICLE);
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.SPECIAL_QUIZ:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              startSpecialQuiz(payload);
              isProgressPage
                ? analyticHelper.progressPageActivity(
                    MAIN_SCREEN_ACTIVITY_LABELS.SPECIAL_QUIZ,
                    progressPageActivityState,
                  )
                : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.SPECIAL_QUIZ);
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.KB_GAME:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              openArticle(payload);
              if (isProgressPage) {
                analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.KB_GAME, progressPageActivityState);
              } else {
                amplitudeHelper.mainItemKBGame();
                analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_GAME);
              }
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.GENERAL_QUIZ:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              history.push(Routes.BrandsRoute);
              if (isProgressPage) {
                amplitudeHelper.profileQuizzes();
                analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.GENERAL_QUIZZES, +payload.is_new);
              } else {
                analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.GENERAL_QUIZZES);
              }
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.QUEST:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              history.push(openQuest());
              if (isProgressPage) {
                amplitudeHelper.profileChallenge();
                analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.CHALLENGE, progressPageActivityState);
              } else {
                analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.CHALLENGE);
              }
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.LOTTERY:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              history.push(`${Routes.ChanceRoute}/${chanceId}`);
              isProgressPage
                ? analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.CHANCE, progressPageActivityState)
                : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.CHANCE);
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.QUESTION_OF_THE_DAY:
      return (
        <div className="item-container__item-logo-wrap">
          <div className="item-container__item-logo" onClick={openQuestionOfTheDay}>
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.PROMO_CODE:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              history.push(Routes.PromoCodeRoute);
              if (isProgressPage) {
                amplitudeHelper.profilePromocode();
                analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.PROMO_CODE, progressPageActivityState);
              } else {
                analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.PROMO_CODE);
              }
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.MULTIBRAND_QUIZ:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id, is_new }));
              isProgressPage
                ? analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.MB_QUIZ, progressPageActivityState)
                : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.MB_QUIZ);
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.SINGLE_MODE:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              if (is_new) {
                dispatch(singleModeActions.setSingleModeStarted());
                history.push(Routes.SingleMode);
                isProgressPage
                  ? analyticHelper.progressPageActivity(
                      MAIN_SCREEN_ACTIVITY_LABELS.SINGLE_MODE,
                      progressPageActivityState,
                    )
                  : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.SINGLE_MODE);
              } else {
                dispatch(
                  modalActions.openPromptModal({
                    title: formatMessage('Single mode is completed'),
                    description: formatMessage('New quizzes will be available next wednesday'),
                    btnText: formatMessage('Got it'),
                    onButtonClick: () => {
                      dispatch(modalActions.closeModal());
                    },
                  }),
                );
              }
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    case COMPONENT_TYPES.SALES_TIPS:
      return (
        <div className="item-container__item-logo-wrap">
          <div
            className="item-container__item-logo"
            onClick={() => {
              history.push(Routes.KnowledgeBankRoute + Routes.SalesTips);
              isProgressPage
                ? analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.SALES_TIPS, progressPageActivityState)
                : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.SALES_TIPS);
            }}
          >
            <img className="item-container__item-picture" src={image} alt="item-container__item-icon" />
          </div>
          {payload?.is_new && <div className="item-container__item-logo-unread" />}
          <span className="item-name">{name}</span>
        </div>
      );
    default:
      return null;
  }
};

export const IconicItemComponent = withRouter(ItemComponentBase);

import React from 'react';
import { ComponentLike } from '../../../../types';
import { LinearProgressBar } from '../../../../components';

import styles from './TaskCard.module.scss';

type Props = {
  description: string;
  StatusIcon?: ComponentLike;
  onTaskClick: () => void;
  requiredTaskCount: number;
  progress: number;
  completedTasksWithProgress: number;
  completedTasksCountWithoutProgress: number;
};

const CommonTask = ({
  description,
  StatusIcon,
  onTaskClick,
  requiredTaskCount,
  progress,
  completedTasksWithProgress,
  completedTasksCountWithoutProgress,
}: Props) => (
  <div className={styles.task} onClick={onTaskClick}>
    <div className={styles.taskTitle}>{description}</div>
    {StatusIcon && <StatusIcon className={styles.statusIcon} />}
    <LinearProgressBar
      allTasksCount={requiredTaskCount ? requiredTaskCount : 1}
      completedTasksCount={progress ? completedTasksWithProgress : completedTasksCountWithoutProgress}
    />
  </div>
);

export default CommonTask;

import { useSelector } from 'react-redux';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getIsFetching } from '../../../../redux/bonusShop/bonusShopSelectors';
import { Button } from '../../../../components/common/Button';
import { BackButton } from '../../../../components/common';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { ContentSpinner } from '../../../../components/common/Spinner/ContentSpinner';
import { QuestionMarkIcon } from '../../../../icons/question-mark-icon';

import styles from './AddCardPage.module.scss';

type Props = {
  openHelpArticle: (id: string, url: string) => void;
  onButtonClick: () => void;
  onBackClick?: () => void;
  walletLink: string;
  backgroundImage: string;
  description: string;
  title: string;
  walletLinkText: string;
};

export const AddCardPage = ({
  openHelpArticle,
  onButtonClick,
  onBackClick,
  walletLink,
  backgroundImage,
  description,
  title,
  walletLinkText,
}: Props) => {
  const { formatMessage } = useInternationalization();
  const isFetching = useSelector(getIsFetching);

  return (
    <>
      {isFetching ? (
        <ContentSpinner />
      ) : (
        <div
          className={styles.addCardPage}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <BackButton onClick={onBackClick} className={styles.backButton} />
          <div className={styles.bottomContainer}>
            <QuestionMarkIcon className={styles.faq} onClick={openHelpArticle} />
            <div className={styles.title}>{formatMessage(title)}</div>
            <div className={styles.description}>{formatMessage(description)}</div>

            <Button customClass={styles.button} onClick={onButtonClick}>
              {formatMessage('Add')}
            </Button>
            <a className={styles.link} href={walletLink} rel="noopener noreferrer" target="_blank">
              {formatMessage(walletLinkText)}
            </a>
            <DisclaimerFooter customStyle={{ position: 'static' }} />
          </div>
        </div>
      )}
    </>
  );
};

import './OrientationModal.scss';

import { useInternationalization } from './../../../hooks/useTranslationHook';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';

export const OrientationModal = () => {
  const { formatMessage } = useInternationalization();
  return (
    <ModalContainer modal={MODALS.orientation} customStyles={{ height: '100%', width: '100%' }}>
      <div className="orientation-warning-block">
        <p>
          {formatMessage(
            'App works only in portrait mode. Please, rotate your device!(for better experience we advice you to turn off autorotate on your device)',
          )}
        </p>
      </div>
    </ModalContainer>
  );
};

import { API_URL } from './baseApi';
import axios from 'axios';

export const knowledgeBankApi = {
  getCategories: async () => {
    return await axios.get(`${API_URL}/v3/knowledge-bank/categories`);
  },
  getBrands: async () => {
    return await axios.get(`${API_URL}/v3/knowledge-bank/brands`);
  },
  getArticles: async ({ selectedCategoryId, selectedKBBrandId }) => {
    if (selectedKBBrandId) {
      return await axios.get(
        `${API_URL}/v3/knowledge-bank/articles?category_id=${selectedCategoryId}&brand_id=${selectedKBBrandId}`,
      );
    }
    return await axios.get(`${API_URL}/v3/knowledge-bank/articles?category_id=${selectedCategoryId}`);
  },
  getInteractiveArticle: async (id) => {
    return await axios.get(`${API_URL}/v3/interactive-articles/?article_id=${id}`);
  },
  passInteractiveArticle: async (answers) => {
    return await axios.post(`${API_URL}/v3/interactive-articles`, answers);
  },
  passCorrectInteractiveArticle: async (id) => {
    return await axios.post(`${API_URL}/v3/interactive-articles/passed?id=${id}`);
  },
  loseGame: async (articleId) => {
    return await axios.post(`${API_URL}/v3/knowledge-bank/lose`, { article_id: articleId });
  },
  readArticle: async (articleId, articleType, is_sales_tip = false, type, gameProgress) => {
    if (!articleType) {
      return await axios.post(`${API_URL}/v3/knowledge-bank/articles`, {
        article_id: articleId,
        is_sales_tip,
        type,
        game_progress: gameProgress,
      });
    } else {
      return await axios.post(`${API_URL}/v3/knowledge-bank/articles`, {
        article_id: articleId,
        type: articleType,
        is_sales_tip,
        game_progress: gameProgress,
      });
    }
  },
  getSalesTips: async () => {
    return await axios.get(`${API_URL}/v3/knowledge-bank/sales-tips`);
  },
  getSalesTipsSteps: async (tip_id) => {
    return await axios.get(`${API_URL}/v3/knowledge-bank/sales-tips/steps?tip_id=${tip_id}`);
  },
  getSalesSimulators: async () => {
    return await axios.get(`${API_URL}/v1/sales-simulators`);
  },
  getSalesSimulatorScenarios: async (simulator_id) => {
    return await axios.get(`${API_URL}/v1/sales-simulators/scenarios?simulator_id=${simulator_id}`);
  },
  startScenario: async (scenario_id) => {
    return await axios.post(`${API_URL}/v1/sales-simulators/start`, { scenario_id });
  },
  scenarioAnswer: async (id) => {
    return await axios.post(`${API_URL}/v1/sales-simulators/question`, { id });
  },
};

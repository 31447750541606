import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import './manualCheckIn.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import manualCheckInImage from './manualCheckInImage.png';

export const ManualCheckIn = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const handleArrowButtonClick = useCallback(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.checkInStepList,
        stepId: 3,
      }),
    );
  }, [dispatch]);

  const onBackCallback = useCallback(() => {
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.checkInStepList }));
  }, [dispatch]);
  return (
    <div className="manual-check-in__component">
      <div className="check-in__top-section">
        <Button
          withoutTheme
          type="button"
          className="check-in__top-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded fillColor="#fff" className="check-in__top-arrow" />
        </Button>
        <div className="check-in__img-section">
          <img alt="img" src={manualCheckInImage} className="check-in__image" />
        </div>
        <h1 className="check-in__title">{formatMessage('Or check-in independently')}</h1>
        <div className="check-in__description-section">
          <p className="check-in__text">
            {formatMessage(
              'Or you can take the check-in independently and get extra coins for each step that you pass. And for the last fourth step you will get five times more!',
            )}
          </p>
          <h2 className="check-in__bold-text">{formatMessage('What is needed for this?')}</h2>
          <p className="check-in__text">
            1. {formatMessage('Go to the application in the workplace')} <br />
            2. {formatMessage("Go to the 'Shop' section")} <br />
            3. {formatMessage("Select the 'Сheck-in'")} <br />
            4. {formatMessage('Click on the “Go through Check-in” button')}
          </p>
          <p className="check-in__text">
            {formatMessage(
              'From this moment the countdown will start. You will have a week to go through the 4 stages of the check-in at the workplace.',
            )}
          </p>
          <p className="check-in__text">
            {formatMessage(
              'To pass the stage, you need to go to the “Check-in” tab and click on the “Go through stage” button.',
            )}
          </p>
          <p className="check-in__text">
            {formatMessage("If you don't go through all 4 stages an attempt to pass the check-in doesn't count.")}
          </p>
        </div>
        <div className="check-in__bot-section">
          <ArrowButton withoutTheme onClick={() => handleArrowButtonClick()} />
        </div>
      </div>
    </div>
  );
};

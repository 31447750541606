import './passportSigning.scss';

import { Header } from '../../components/common/Header';
import { Signature } from '../../components/common/Signature';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Routes } from '../../const';
import { passportApi } from '../../api/passportApi';

export const PassportSigning = ({ history }) => {
  const { formatMessage } = useInternationalization();

  const goBackCallback = () => {
    history.replace(Routes.PassportStatement);
  };

  const onDoneClick = (signature) => {
    passportApi.signStatement(signature);
    history.replace(Routes.PassportSuccess);
  };

  return (
    <div className="passport-signing-page">
      <Header hasBackButton customClass="passport-signing-page__header" goBackCallback={goBackCallback}>
        <p className="passport-signing-page__header-text">{formatMessage('Signing')}</p>
      </Header>
      <Signature customClass={'passport-signing-page__content'} onDoneClick={onDoneClick} />
    </div>
  );
};

import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import './selectGameMode.scss';

import { getGameModeSelector } from '../../redux/settings/settingsSelector';
import { settingsActions } from '../../redux/settings/settingsSlice';
import { Button } from '../common/Button';
import { GAME_MODES } from '../../redux/settings/settingsConstants';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { isGeneralQuizActiveSelector } from '../../redux/mainPage/mainPageSelectors';

export const SelectGameMode = () => {
  const dispatch = useDispatch();
  const gameMode = useSelector(getGameModeSelector);
  const isGeneralQuizActive = useSelector(isGeneralQuizActiveSelector);
  const { formatMessage } = useInternationalization();

  const setGameModeCallback = useCallback(
    (gameMode) => {
      dispatch(settingsActions.setGameMode(gameMode));
    },
    [dispatch],
  );

  if (isGeneralQuizActive) {
    return (
      <>
        <div className="select-game-mode__devider"></div>

        <div className="select-game-mode__mode-buttons-container">
          <Button
            className={`select-game-mode__mode-button${gameMode === GAME_MODES.SOLO ? '-selected' : ''}`}
            onClick={() => setGameModeCallback(GAME_MODES.SOLO)}
            withoutTheme
          >
            {formatMessage('Solo')}
          </Button>
          <Button
            className={`select-game-mode__mode-button${gameMode === GAME_MODES.HEAD_TO_HEAD ? '-selected' : ''}`}
            onClick={() => setGameModeCallback(GAME_MODES.HEAD_TO_HEAD)}
            withoutTheme
          >
            {formatMessage('Head to Head')}
          </Button>
        </div>
      </>
    );
  }

  return null;
};

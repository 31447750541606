import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
  message: '',
};

const notificationMessageSlice = createSlice({
  name: 'notificationMessage',
  initialState,
  reducers: {
    showNotificationMessage: (state, action: PayloadAction<string>) => {
      state.isVisible = true;
      state.message = action.payload;
    },
    hideNotificationMessage: (state) => {
      state.isVisible = false;
      state.message = '';
    },
  },
});

export const notificationMessageActions = notificationMessageSlice.actions;
export const notificationMessageReducer = notificationMessageSlice.reducer;

import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './salesTipsPage.scss';

import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { Header } from '../../components/common/Header';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Routes } from '../../const';
import { getIsFetching, getSalesTipsSelector } from '../../redux/knowledgeBank/knowledgeBankSelectors';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { NavigationMenu } from '../../components';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';

export const SalesTipsPageComponent = (props) => {
  const { history, location } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const salesTips = useSelector(getSalesTipsSelector);
  const isFetching = useSelector(getIsFetching);

  const onBackCallback = useCallback(() => {
    history.replace(Routes.KnowledgeBankRoute);
  }, [history]);

  const onTipCardClickCallback = useCallback(
    (tip_id) => {
      history.push(`${Routes.KnowledgeBankRoute + Routes.SalesTips}/${tip_id}`);
    },
    [history],
  );

  useEffect(() => {
    dispatch(knowledgeBankActions.getSalesTips());
  }, [dispatch]);

  return (
    <div className="sales-tips">
      <ScrollBlock fromTop>
        <Header hasBackButton goBackCallback={onBackCallback}>
          <p className="sales-tips__header-text">{formatMessage('Sales Tips')}</p>
        </Header>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div className="sales-tips__body">
            <div className="sales-tips__sales-tips-cards">
              {salesTips.map((i) =>
                i.is_new ? (
                  <div className="sales-tips__sales-tips-card" key={i.id}>
                    <div className="sales-tips__sales-tips-card-chain" />
                    <img
                      className="sales-tips__sales-tips-card-incompleted"
                      src={i.incomplete_image}
                      alt="completed-sales-tip"
                      onClick={() => onTipCardClickCallback(i.id)}
                    />
                  </div>
                ) : (
                  <div className="sales-tips__sales-tips-card">
                    <div className="sales-tips__sales-tips-card-chain" />
                    <img
                      className="sales-tips__sales-tips-card-completed"
                      src={i.complete_image}
                      alt="incompleted-sales-tip"
                      onClick={() => onTipCardClickCallback(i.id)}
                    />
                  </div>
                ),
              )}
            </div>
          </div>
        )}
        <NavigationMenu location={location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const SalesTipsPage = withRouter(SalesTipsPageComponent);

import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';


import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getSelectedAchievementSelector, getIsFetching } from '../../../redux/achievements/achievementsSelectors';
import { achievementsActions } from '../../../redux/achievements/achievementsSlice';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';

import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { ProgressBar } from '../../mainPage/userComponent/progressBar';
import { SuccessIcon } from '../../../icons/success-icon';
import { ContentSpinner } from '../../../components/common/Spinner/ContentSpinner';

import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { Routes } from '../../../const';

import './selectedAchievement.scss';

const SelectedAchievement = (props) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const { match, history } = props;
  const achievementId = Number(match.params.id);
  const selectedAchievement = useSelector(getSelectedAchievementSelector);
  const isFetching = useSelector(getIsFetching);

  const tutorialStep = useSelector((state) => {
    if (state.steps) {
      if (state.steps.achievementsTutorialStepList) {
        return state.steps.achievementsTutorialStepList.currentStep;
      }
    }
    return null;
  });

  useEffect(() => {
    dispatch(achievementsActions.getAchievementById(achievementId));
    analyticHelper.achievementTasksOpen(achievementId);
    return () => {
      dispatch(achievementsActions.resetSelectedAchievement());
    };
  }, [dispatch, achievementId]);

  useEffect(() => {
    if (localStorage.getItem('achievementsTutorialCompleted') !== 'true') {
      if (tutorialStep === 2) {
        dispatch(stepListActions.setStep({ stepListId: STEP_LIST_IDS.achievementsTutorialStepList, stepId: 3 }));
      } else if (tutorialStep < 2) {
        history.push(Routes.Achievements);
      }
    }
  }, [dispatch, history, tutorialStep]);

  const getReward = (taskId) => {
    const achievementName = selectedAchievement.name;
    dispatch(achievementsActions.claimReward({ achievementId, taskId, achievementName }));
  };

  const renderTasks = () => {
    return selectedAchievement?.tasks_with_progress
      .filter((task) => !task.is_completed || !task.is_reward_received)
      .map((task) => {
        return (
          <div
            className="selected-achievement-page__bottom-section-tasks-item"
            style={{
              backgroundColor: task.card_color,
              borderColor: task.is_completed && selectedAchievement.status_color,
            }}
          >
            <div
              className="selected-achievement-page__bottom-section-tasks-item-status"
              style={{
                margin: task.is_completed ? '10px 20px' : '5px 20px',
              }}
            >
              {task.is_completed && (
                <SuccessIcon className="selected-achievement-page__bottom-section-tasks-item-status-success" />
              )}
            </div>
            <div className="selected-achievement-page__bottom-section-tasks-item-info">
              <div
                className="selected-achievement-page__bottom-section-tasks-item-info-icon"
                style={{ backgroundImage: `url(${task.task_image})` }}
              />
              <div className="selected-achievement-page__bottom-section-tasks-item-info-right">
                <div
                  className="selected-achievement-page__bottom-section-tasks-item-info-right-name"
                  style={{ color: task.text_color }}
                >
                  {task.name}
                </div>
                {task.is_completed ? (
                  <div className="selected-achievement-page__bottom-section-tasks-item-info-right-reward">
                    <div
                      className="selected-achievement-page__bottom-section-tasks-item-info-right-reward-button"
                      style={{ backgroundColor: task.reward_color }}
                      onClick={() => getReward(task.id)}
                    >
                      <p className="selected-achievement-page__bottom-section-tasks-item-info-right-reward-button-text">
                        {formatMessage('Get reward')}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="selected-achievement-page__bottom-section-tasks-item-info-right-progress-bar">
                    <div
                      className="selected-achievement-page__bottom-section-tasks-item-info-right-progress-bar-text"
                      style={{ color: task.text_color }}
                    >{`${task.number_of_actions_completed}/${task.number_of_actions_required} ${formatMessage(
                      'Completed',
                    )}`}</div>
                    <ProgressBar
                      value={task.number_of_actions_completed}
                      goal={task.number_of_actions_required}
                      firstHalfColor={task.progress_color}
                      secondHalfColor={task.progress_color}
                      style={{ borderRadius: '3px' }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      });
  };

  const renderCompletedTasks = () => {
    return selectedAchievement?.tasks_with_progress
      .filter((task) => task.is_completed && task.is_reward_received)
      .map((task) => {
        return (
          <div
            className="selected-achievement-page__bottom-section-completed-tasks-item"
            style={{ backgroundColor: task?.card_color }}
          >
            <div
              className="selected-achievement-page__bottom-section-completed-tasks-item-icon"
              style={{ backgroundImage: `url(${task?.task_image})` }}
            />
            <div
              className="selected-achievement-page__bottom-section-completed-tasks-item-name"
              style={{ color: task?.text_color }}
            >
              {formatMessage(task?.name)}
            </div>
          </div>
        );
      });
  };

  return (
    <div
      className="selected-achievement-page"
      style={{ backgroundImage: `url(${selectedAchievement?.background_image})` }}
    >
      <ScrollBlock fromTop>
        <div className="selected-achievement-page__top-section">
          <BackArrowButton customClass="selected-achievement-page__top-section-back-button" buttonColor="black" />
        </div>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <div className="selected-achievement-page__bottom-section">
            <div className="selected-achievement-page__bottom-section-header">
              <div
                className="selected-achievement-page__bottom-section-header-caption"
                style={{ color: selectedAchievement?.text_color }}
              >
                {formatMessage(selectedAchievement?.name ?? ' ')}
              </div>
              <div
                className="selected-achievement-page__bottom-section-header-description"
                style={{ color: selectedAchievement?.text_color }}
              >
                {formatMessage(selectedAchievement?.description ?? ' ')}
              </div>
            </div>
            <div className="selected-achievement-page__bottom-section-tasks">{renderTasks()}</div>
            {selectedAchievement?.tasks_with_progress.some((task) => task.is_completed) && (
              <div className="selected-achievement-page__bottom-section-completed">
                <div
                  className="selected-achievement-page__bottom-section-completed-caption"
                  style={{ color: selectedAchievement?.text_color }}
                >
                  {formatMessage('Unlocked Achievements')}
                </div>
                <div className="selected-achievement-page__bottom-section-completed-tasks">
                  {renderCompletedTasks()}
                </div>
              </div>
            )}
          </div>
        )}
      </ScrollBlock>
    </div>
  );
};

export const SelectedAchievementPage = withRouter(SelectedAchievement);

import _ from 'lodash';

import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { gameActions } from '../../redux/game/gameSlice';
import { GAME_STEPS, GAME_STEPS_HUNGARY } from '../../redux/game/gameConstants';
import { OpponentStep } from './OpponentStep';
import { LoadingOpponent } from './LoadingOpponent';
import { InviteAccepted } from './InviteAcceptedStep';
import { GameComponent } from './Game';
import { GameResultStep } from './GameResultStep';
import { goToGameStepAction } from '../../redux/game/gameSagas';
import { getInviteDataSelector } from '../../redux/game/gameSelectors';
import { getBrandInfoSelector } from '../../redux/brands/brandSelectors';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { APP_TYPES } from '../../const/appConstants';
import { images } from './quizContants';

import './quizPage.scss';

export const QuizPageComponent = (props) => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const brandId = Number(brand_id);

  const isHungary = process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY;

  const dispatch = useDispatch();

  const inviteData = useSelector(getInviteDataSelector);
  const brand = useSelector((state) => getBrandInfoSelector(state, brandId));
  const theme = {
    color: brand ? brand.quiz_element_color : '#ffffff',
    triangleColor: brand ? brand.triangle_color : '#000',
  };

  useEffect(() => {
    if (!inviteData) {
      dispatch(gameActions.run({ brand_id: brandId }));
    }

    return () => {
      dispatch(gameActions.reset({ withInviteData: true }));
      dispatch(goToGameStepAction(GAME_STEPS.LOADING));
    };
  }, [dispatch, brandId, inviteData]);

  const onBackCallback = useCallback(() => {
    dispatch(gameActions.reset());
  }, [dispatch]);

  const onLeaveGameCallback = useCallback(() => {
    dispatch(gameActions.leaveGame());
  }, [dispatch]);

  if (!theme) {
    history.push('/main');
    return null;
  }

  const shuffledArrayImages = _.shuffle(images);

  const StartQuizSteps = createStepListContainer(STEP_LIST_IDS.startQuizStepList, [
    {
      id: GAME_STEPS.LOADING,
      Component: () => <LoadingOpponent theme={theme} onBack={onBackCallback} history={history} brand={brand} />,
    },
    {
      id: GAME_STEPS.OPPONENT,
      Component: () => <OpponentStep theme={theme} onBack={onBackCallback} brandId={brandId} />,
    },
    {
      id: GAME_STEPS.INVITE_ACCEPTED,
      Component: () => <InviteAccepted theme={theme} brand={brand} />,
    },
    {
      id: GAME_STEPS.GAME,
      Component: () => <GameComponent theme={theme} onBack={onLeaveGameCallback} history={history} brandId={brandId} />,
    },
    {
      id: GAME_STEPS.RESULT,
      Component: () => <GameResultStep theme={theme} onBack={onBackCallback} brandId={brandId} />,
    },
  ]);

  const StartQuizStepsHungary = createStepListContainer(STEP_LIST_IDS.startQuizStepList, [
    {
      id: GAME_STEPS_HUNGARY.LOADING,
      Component: () => (
        <LoadingOpponent
          theme={theme}
          onBack={onBackCallback}
          history={history}
          brand={brand}
          shuffledImagesArray={shuffledArrayImages}
        />
      ),
    },
    {
      id: GAME_STEPS_HUNGARY.INVITE_ACCEPTED,
      Component: () => <InviteAccepted theme={theme} brand={brand} />,
    },
    {
      id: GAME_STEPS_HUNGARY.GAME,
      Component: () => <GameComponent theme={theme} onBack={onLeaveGameCallback} history={history} brandId={brandId} />,
    },
    {
      id: GAME_STEPS_HUNGARY.RESULT,
      Component: () => <GameResultStep theme={theme} onBack={onBackCallback} brandId={brandId} />,
    },
  ]);

  return (
    <div
      className="quiz-page__with-background-image"
      style={{
        backgroundImage: brand && brand.background_image && `url(${brand.background_image})`,
        color: theme.color,
      }}
    >
      {!isHungary ? <StartQuizSteps /> : <StartQuizStepsHungary />}
      {process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND && <DisclaimerFooter withoutNavigation />}
    </div>
  );
};

export const QuizPage = withRouter(QuizPageComponent);

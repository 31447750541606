import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { stepListActions } from './stepListSlice';
import { currentStepSelector } from './stepListSelector';

export const createStepListContainer = (stepListId, items) => {
  const mapStateToProps = (state) => ({
    currentStep: currentStepSelector(state, stepListId),
  });

  const mapDispatchToProps = {
    initStepList: (items) => stepListActions.initStepList({ stepListId, items }),
    nextStep: () => stepListActions.nextStep({ stepListId }),
    prevStep: () => stepListActions.prevStep({ stepListId }),
    setStep: (stepId) => stepListActions.setStep({ stepListId, stepId }),
  };

  const withConnect = connect(mapStateToProps, mapDispatchToProps);

  const StepList = (props) => {
    const { initStepList, prevStep, nextStep, currentStep } = props;

    useEffect(() => {
      const idList = items.map((i) => ({ id: i.id }));
      initStepList(idList);
    }, [initStepList]);

    const currentStepItem = items.find((i) => i.id === currentStep);

    if (!currentStepItem) {
      console.error('StepContainer. Cand find item with id = ' + currentStep);
      return null;
    }

    const CurrentStepComponent = currentStepItem.Component;

    return <CurrentStepComponent prevStep={prevStep} nextStep={nextStep} />;
  };

  StepList.propTypes = {
    currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    setStep: PropTypes.func.isRequired,
  };

  return compose(withConnect)(StepList);
};

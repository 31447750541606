import React, { useEffect } from 'react';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { InteractiveArticlePage } from './InteractiveArticlePage';
import { InteractiveArticleResultPage } from './interactiveArticleResultPage/InteractiveArticleResultPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAttachedInteractiveArticleSelector,
  getIsFetching,
  getSelectedArticleSelector,
} from '../../../redux/knowledgeBank/knowledgeBankSelectors';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { ContentSpinner } from '../../../components/common/Spinner/ContentSpinner';

const interactiveArticleSteps = {
  questionStep: 1,
  resultStep: 2,
};

export const InteractiveArticle = () => {
  const dispatch = useDispatch();

  const article = useSelector(getSelectedArticleSelector);
  const isFetching = useSelector(getIsFetching);
  const interactiveArticle = useSelector(getAttachedInteractiveArticleSelector);

  useEffect(() => {
    if (!interactiveArticle) {
      dispatch(knowledgeBankActions.getInteractiveArticle(article.id));
    }
  }, [article, dispatch, interactiveArticle]);

  const steps = [
    { id: interactiveArticleSteps.questionStep, Component: () => <InteractiveArticlePage /> },
    { id: interactiveArticleSteps.resultStep, Component: () => <InteractiveArticleResultPage /> },
  ];

  const StepList = createStepListContainer(STEP_LIST_IDS.interactiveArticleStepList, steps);

  return <>{isFetching && !interactiveArticle ? <ContentSpinner /> : <StepList />}</>;
};

import { createSelector } from 'reselect';

export const getCurrentScenarioSelector = createSelector(
  [(state) => state.scenario.currentScenario],
  (scenario) => scenario,
);

export const getScenarioChatMessages = createSelector(
  [(state) => state.scenario.scenarioChatMessages],
  (messages) => messages,
);

export const isAnswerScreenActiveSelector = createSelector(
  [(state) => state.scenario.isAnswerScreenActive],
  (isAnswerScreenActive) => isAnswerScreenActive,
);

export const getPreviousStepScenarioData = createSelector(
  [(state) => state.scenario.previousStepScenario],
  (previousStepScenario) => previousStepScenario,
);

export const isAnswerButtonActiveSelector = createSelector(
  [(state) => state.scenario.isAnswerButtonActive],
  (isAnswerButtonActive) => isAnswerButtonActive,
);

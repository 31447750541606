import { History, LocationState } from 'history';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { Button } from '../../components/common/Button';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import errorImage from '../../images/error.svg';

import styles from './ErrorPage.module.scss';

type Props = {
  history: History<RouterState>;
};

interface RouterState<S = LocationState> {
  description: string;
  buttonText: string;
  image?: string;
  route: string;
}

export const ErrorPage = ({ history }: Props) => {
  const { formatMessage } = useInternationalization();
  const { location }: History<RouterState> = history;

  const { image, buttonText, route, description } = location.state;

  const onButtonClick = () => {
    history.push(route);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          <img className={styles.image} src={image ? image : errorImage} alt="error" />
        </div>
        <span className={styles.description}>{formatMessage(description)}</span>
        <Button customClass={styles.button} onClick={onButtonClick}>
          {formatMessage(buttonText)}
        </Button>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

import { ChallengeTypes } from '../../enums';
import { ITask, RawChallenge } from './challengeTypes';

export const mapChallenges = (challenges: RawChallenge[]) => challenges.map(mapChallenge);

export const mapChallenge = (challenge: RawChallenge) => {
  const {
    attachments_enabled,
    description,
    end_date,
    id,
    name,
    start_date,
    products,
    tasks,
    photo_frame,
    type,
    background_image,
    users_avatars,
  } = challenge;

  return {
    attachmentsEnabled: attachments_enabled,
    description,
    end_date,
    id,
    name,
    startDate: start_date,
    products,
    tasks: tasks.sort(sortTasks),
    photo_frame,
    isTeamChallenge: type === ChallengeTypes.Team,
    backgroundImage: background_image,
    usersAvatars: users_avatars,
  };
};

const sortTasks = (prevTask: ITask, nextTask: ITask) => {
  if (prevTask.id < nextTask.id) {
    return -1;
  } else {
    return 1;
  }
};

import axios from 'axios';
import { API_URL } from './baseApi';
import { TaskCardStatus } from '../redux/challenge/challengeTypes';

export const challengeApi = {
  getChallenges: async () => {
    return await axios.get(`${API_URL}/v8/quests`);
  },
  getChallenge: async () => {
    return await axios.get(`${API_URL}/v3/challenge`);
  },
  getChallengeById: async (id?: number) => {
    return await axios.get(`${API_URL}/v1/quest/${id}`);
  },
  uploadChallengePhoto: async (data: {
    quest_id: number;
    attach_url?: string;
    attach_text?: string;
    task_id?: number;
  }) => {
    return await axios.post(`${API_URL}/v3/challenge/attachments`, data);
  },
  sendAttachmentsForCheck: async ({
    status = 2,
    taskId,
    taskType,
    quest_id,
    answer,
  }: {
    status?: TaskCardStatus;
    taskId: number;
    taskType: string;
    quest_id: number;
    answer?: string;
  }) => {
    return await axios.put(`${API_URL}/v3/challenge/check`, { status, taskId, taskType, quest_id, answer });
  },
  validateAnswer: async (answer: string, taskId: number) => {
    return await axios.post(`${API_URL}/v3/challenge/validate`, { task_id: taskId, answer });
  },
};

import { APP_TYPES } from '../../../const/appConstants';
import { SignupPageIreland } from './signUpPageIreland';
import { SignupPageHungary } from './signUpPageHungary';
import { SignupPageCaucasus } from './signUpPageCaucasus';
import { SignupPageBelarus } from './signupPageBelarus';
import SignUpPageMybrand from './SignUpPageMybrand';

const getSignUpPageLayout = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.HUNGARY:
      return SignupPageHungary;
    case APP_TYPES.BELARUS:
      return SignupPageBelarus;
    case APP_TYPES.ARMENIA:
    case APP_TYPES.AZERBAIJAN:
    case APP_TYPES.GEORGIA:
      return SignupPageCaucasus;
    case APP_TYPES.MYBRAND_AZ:
      return SignUpPageMybrand;
    case APP_TYPES.IRELAND:
    default:
      return SignupPageIreland;
  }
};

export const SignUpPage = getSignUpPageLayout();

export const getCoinsCount = coins => {
  if (coins === undefined || !coins) {
    return '0';
  }

  let pref = '';
  if (coins < 0) {
    pref = '-';
    coins = coins * -1;
  }

  let coinsStr = '' + coins;
  return (
    pref +
    coinsStr
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .join(' ')
      .split('')
      .reverse()
      .join('')
  );
};

import { all } from '@redux-saga/core/effects';
import { put, select, takeLatest } from 'redux-saga/effects';
import { mysteryShopperActions } from './mysteryShopperSlice';
import { mysteryShopperApi } from '../../api/mysteryShopperApi';
import { PayloadAction } from '@reduxjs/toolkit';
import { Routes } from '../../const';
import { push } from 'react-router-redux';
import { mainPageActions } from '../mainPage/mainPageSlice';
import {
  getCriteriaId,
  getFileKeys,
  getQuestionId,
  getUserAnswers,
  selectMysteryShopperId,
} from './mysteryShopperSelectors';
import { IUserAnswers, mysteryShopperQuestionTypes } from './mysteryShopperTypes';
import { reset } from 'redux-form';
import isEmpty from 'is-empty-typed';

function* getMysteryShopper() {
  yield put(mysteryShopperActions.setIsFetching(true));
  yield put(mainPageActions.setIsFetching(true));

  try {
    const { data } = yield mysteryShopperApi.getMysteryShopper();
    yield put(mysteryShopperActions.setMysteryShoppers(data));
    if (data.length > 1) {
      yield put(push(Routes.MysteryShopperList));
    } else {
      yield put(push(Routes.MysteryShopperStartPage));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(mysteryShopperActions.setIsFetching(false));
    yield put(mainPageActions.setIsFetching(false));
  }
}

function* getMysteryShopperQuestions(action: PayloadAction<{ mysteryShopperId: number }>) {
  const { mysteryShopperId } = action.payload;
  yield put(mysteryShopperActions.setIsFetching(true));

  try {
    const { data } = yield mysteryShopperApi.getMysteryShopperQuestions(mysteryShopperId);

    yield put(mysteryShopperActions.setIsSubmitted(false));

    if (isEmpty(data.result)) {
      if (data.on_moderation) {
        yield put(push(Routes.MysteryShopperModeration));
      } else {
        yield put(push(Routes.MysteryShopperResult));
      }
    } else {
      yield put(mysteryShopperActions.setMysteryShopperQuestions(data.result));
      yield put(mysteryShopperActions.setNumberOfQuestions({ total: data.total }));
      yield put(mysteryShopperActions.setNumberOfPassedQuestions(data.passed));
      yield put(mysteryShopperActions.setEndDate(data.end_date));
      yield put(mysteryShopperActions.setBrandId(data.brand_id));

      const unansweredQuestions = data.passed < data.total && data.passed !== 0;

      if (data.passed === 0) {
        yield put(push(Routes.MysteryShopperStartPage));
      }

      if (unansweredQuestions) {
        yield put(push(Routes.MysteryShopperQuizPage));
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(mysteryShopperActions.setIsFetching(false));
  }
}

function* sendMysteryShopperAnswers() {
  yield put(mysteryShopperActions.setIsFetching(true));
  const criteriaId: number = yield select(getCriteriaId);
  const questionId: number = yield select(getQuestionId);
  const values: IUserAnswers = yield select(getUserAnswers);
  const fileKeys: string[] = yield select(getFileKeys);
  const mysteryShopperId: number = yield select(selectMysteryShopperId);
  let userAnswers: string[] = [];

  if (values) {
    const { multiFields, single, openQuestion, value } = values;
    const questionType = single || openQuestion || value;

    if (questionType) {
      userAnswers.push(questionType);
    } else {
      userAnswers = [...multiFields];
    }
  }

  try {
    const { data } = yield mysteryShopperApi.sendAnswer(questionId, criteriaId, userAnswers, fileKeys);

    yield put(mysteryShopperActions.setIsCorrect(data.is_correct));
    yield put(mysteryShopperActions.setCorrectAnswers(data.result.answers));
    yield put(mysteryShopperActions.setIsCorrect(data.result.answers[0]?.is_correct));
    yield put(mysteryShopperActions.setIsSubmitted(true));

    const isOpenQuestion = data.result?.type_id === mysteryShopperQuestionTypes.openQuestion;
    const isPhotoQuestion = data.result?.type_id === mysteryShopperQuestionTypes.addPhotoQuestion;

    if (isOpenQuestion || isPhotoQuestion) {
      yield put(mysteryShopperActions.getMysteryShopperQuestions({ mysteryShopperId }));
      yield put(reset('quizForm'));
      yield put(mysteryShopperActions.resetFileKeys());
    }

    if (data.show_correct_answers && data.on_moderation) {
      yield put(push(Routes.MysteryShopperModeration));
    }
  } catch (e) {
    console.error(e);
  } finally {
    yield put(mysteryShopperActions.setIsFetching(false));
  }
}

function* getRecommendations(action: PayloadAction<{ mysteryShopperId: number }>) {
  const { mysteryShopperId } = action.payload;
  yield put(mysteryShopperActions.setIsFetching(true));

  try {
    const { data } = yield mysteryShopperApi.getMysteryShopperResult(mysteryShopperId);

    yield put(mysteryShopperActions.setRecommendations(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(mysteryShopperActions.setIsFetching(false));
  }
}

export function* mysteryShopperSagas() {
  yield all([
    takeLatest(mysteryShopperActions.getMysteryShoppers, getMysteryShopper),
    takeLatest(mysteryShopperActions.getMysteryShopperQuestions, getMysteryShopperQuestions),
    takeLatest(mysteryShopperActions.sendMysteryShopperAnswers, sendMysteryShopperAnswers),
    takeLatest(mysteryShopperActions.getRecommendations, getRecommendations),
  ]);
}

import { PayloadAction } from '@reduxjs/toolkit';
import { all, takeLatest, put } from '@redux-saga/core/effects';

import { challengeApi } from '../../api/challengeApi';
import { challengeActions } from './challengeSlice';
import { serviceApi } from '../../api/serviceApi';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { mapChallenge, mapChallenges } from './challengeServices';

function* getQuests() {
  yield put(challengeActions.setIsFetching(true));

  try {
    const { data } = yield challengeApi.getChallenges();
    const challenges = mapChallenges(data);

    yield put(challengeActions.setChallenges(challenges));

    yield put(challengeActions.setIsFetching(false));
  } catch (e) {}
}

function* getQuestById(action: PayloadAction<{ id: number }>) {
  const { id } = action.payload;
  yield put(challengeActions.setIsFetching(true));

  try {
    const { data } = yield challengeApi.getChallengeById(id);
    const challenge = mapChallenge(data);

    yield put(challengeActions.setChallenge(challenge));
    yield put(challengeActions.setIsFetching(false));
  } catch (e) {}
}

function* getQuest() {
  yield put(challengeActions.setIsFetching(true));

  try {
    const { data } = yield challengeApi.getChallenge();
    const challenge = mapChallenge(data);

    yield put(challengeActions.setChallenge(challenge));
    yield put(challengeActions.setIsFetching(false));
  } catch (e) {}
}

function* uploadPhotoSaga(action: PayloadAction<{ id: number; formData: object; taskId?: number; taskType?: string }>) {
  const { id, formData, taskId, taskType } = action.payload;
  yield put(challengeActions.setIsFetching(true));

  try {
    const { data } = yield serviceApi.uploadPhoto(formData);

    yield put(
      challengeActions.submit({
        url: data.file_key,
        id: id,
        taskId,
        taskType,
      }),
    );
    yield put(challengeActions.setIsFetching(false));

    yield put(
      modalActions.openPromptModal({
        title: 'Great!',
        description: 'Sit back and relax, your photo has been uploaded successfully.',
        btnText: 'OK',
      }),
    );
  } catch (err) {
    console.error('uploadPhoto error', err);
  }
}

function* submitSaga(
  action: PayloadAction<{ id: number; url?: string; answer?: string; taskId?: number; taskType?: string }>,
) {
  const { id, url, answer, taskId, taskType } = action.payload;
  try {
    yield challengeApi.uploadChallengePhoto({
      quest_id: id,
      attach_url: url,
      attach_text: answer,
      task_id: taskId,
    });
    if (taskId && taskType) {
      yield put(
        challengeActions.sendAttachmentsForCheck({
          taskId,
          taskType,
          quest_id: id,
        }),
      );
    }
  } catch (err) {
    console.error('uploadPhoto error', err);
  }
}

function* sendAttachmentsForCheckSaga(
  action: PayloadAction<{
    taskId: number;
    taskType: string;
    quest_id: number;
    answer?: string;
    status?: number;
  }>,
) {
  const { taskId, taskType, quest_id, answer, status } = action.payload;
  yield put(challengeActions.setIsFetching(true));

  try {
    yield challengeApi.sendAttachmentsForCheck({
      taskId,
      taskType,
      quest_id,
      answer,
      status,
    });
    yield put(challengeActions.getChallengeById({ id: quest_id }));

    yield put(challengeActions.setIsFetching(false));
  } catch (err) {
    console.log('sendingAttachment error');
  }
}

function* takePhotoWithFrameSaga(action: PayloadAction<string>) {
  try {
    yield put(challengeActions.setPhotoWithFrame(action.payload));
    yield put(
      stepListActions.nextStep({
        stepListId: STEP_LIST_IDS.digitalFrameStepList,
      }),
    );
  } catch (err) {
    console.error('takePhotoWithFrame error', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(challengeActions.getChallenges.type, getQuests),
    takeLatest(challengeActions.getChallengeById.type, getQuestById),
    takeLatest(challengeActions.getChallenge.type, getQuest),
    takeLatest(challengeActions.uploadChallengePhoto.type, uploadPhotoSaga),
    takeLatest(challengeActions.submit.type, submitSaga),
    takeLatest(challengeActions.sendAttachmentsForCheck.type, sendAttachmentsForCheckSaga),
    takeLatest(challengeActions.takePhoto.type, takePhotoWithFrameSaga),
  ]);
}

export const challengeSagas = sagas;

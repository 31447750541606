import axios from 'axios';

import { API_URL } from './baseApi';
import { APP_TYPES } from '../const/appConstants';
import { BONUS_SHOP_CATEGORY_TYPES } from '../redux/bonusShop/bonusShopConstants';

const mapOldPrizesFormat = (prize) => ({
  id: prize.id,
  createdAt: prize.created_at,
  categoryPhoto: prize.category_photo,
  categoryName: prize.category_name,
  providerName: prize.provider_name,
  pdfLink: prize.pdf_link,
  price: prize.price,
  viewed: prize.viewed,
  isUsed: prize.is_used,
  orderDetails: prize.order_details,
  status: prize.status,
});

export const bonusShopApi = {
  getCategories: async () => {
    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) {
      return await axios.get(`${API_URL}/v3/products/categories`);
    }
    return await axios.get(`${API_URL}/v10/products/categories`);
  },
  getTransfers: async (isKibritTransfers) => {
    if (
      process.env.REACT_APP_APP_TYPE === APP_TYPES.GEORGIA ||
      process.env.REACT_APP_APP_TYPE === APP_TYPES.AZERBAIJAN ||
      process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND
    ) {
      if (isKibritTransfers) {
        return await axios.get(`${API_URL}/v1/bonus-shop/phone/transfers`);
      }
      return await axios.get(`${API_URL}/v1/bonus-shop/card/transfers`);
    }
    return await axios.get(`${API_URL}/v1/bonus-shop/digital/transfers`);
  },
  makeTransfer: async (params) => {
    return await axios.post(`${API_URL}/v1/bonus-shop/digital/pay`, { ...params });
  },
  setOppaWallet: async ({ personal_number, wallet_number }) => {
    return await axios.post(`${API_URL}/v1/bonus-shop/card`, { personal_number, wallet_number });
  },
  requestOppaSms: async () => {
    return await axios.post(`${API_URL}/v1/bonus-shop/session`);
  },
  makeOppaTransfer: async ({ amount, code }) => {
    return await axios.post(`${API_URL}/v1/bonus-shop/card/pay`, { amount, code });
  },
  setUmicoWallet: async ({ wallet_number }) => {
    return await axios.post(`${API_URL}/v1/bonus-shop/card`, { wallet_number });
  },
  makeUmicoTransfer: async ({ amount }) => {
    return await axios.post(`${API_URL}/v1/bonus-shop/card/pay`, { amount });
  },
  makePerxTransfer: async ({ amount }) => {
    return await axios.post(`${API_URL}/v1/bonus-shop/card/pay`, { amount });
  },
  getPerxCardInvoice: async (cardId) => {
    return await axios.get(`${API_URL}/v1/bonus-shop/card/invoice/${cardId}`);
  },
  getPerxSegmentationCoefficient: async () => {
    return await axios.get(`${API_URL}/v1/bonus-shop/coefficient`);
  },
  getProviders: async (categoryId) => {
    if (process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND) {
      return await axios.get(`${API_URL}/v3/products/providers?categoryId=${categoryId}`);
    }
    return await axios.get(`${API_URL}/v10/products/categories/${categoryId}`);
  },
  getCategoryItems: async (categoryId) => {
    const { data } = await axios.get(`${API_URL}/v10/products/categories/${categoryId}`);
    return data;
  },
  getProducts: async (providerId) => {
    return await axios.get(`${API_URL}/v3/products/provider/products?providerId=${providerId}`);
  },
  getAddress: async () => {
    return await axios.get(`${API_URL}/v1/bonus-shop/address`);
  },
  setPerxUserInfo: async (payload) => {
    return await axios.post(`${API_URL}/v1/bonus-shop/address`, { ...payload });
  },
  makeOrder: async (params) => {
    if (params.type === BONUS_SHOP_CATEGORY_TYPES.EDENRED_VOUCHER) {
      return await axios.post(`${API_URL}/v3/vouchers/redeem`, { id: params.voucherId });
    } else {
      const { productId: product_id, type, product_color_id, order_details } = params;
      const query =
        product_color_id || product_color_id === 0 ? { product_id, type, product_color_id } : { product_id, type };
      return await axios.post(`${API_URL}/v9/products/redeem`, { ...query, order_details });
    }
  },
  checkAvailability: async (params) => {
    const { type, productId: product_id } = params;

    return await axios.post(`${API_URL}/v9/products/availability`, { product_id, type });
  },
  getVouchersList: async () => {
    const { data } = await axios.get(`${API_URL}/v3/vouchers/list`);
    return data;
  },

  getVoucherDetails: async (voucherId) => {
    const { data } = await axios.get(`${API_URL}/v3/vouchers/details/${voucherId}`);
    return data;
  },

  updateVoucherRedemptionStatus: async (transactionId, isRedeemed) => {
    const { data } = await axios.post(`${API_URL}/v3/vouchers/redemption-status`, { transactionId, isRedeemed });
    return data;
  },

  getPrizes: async () => {
    const apiTypes = {
      [APP_TYPES.IRELAND]: 'v3',
      [APP_TYPES.HUNGARY]: 'v9',
      [APP_TYPES.GEORGIA]: 'v9',
      [APP_TYPES.ARMENIA]: 'v9',
      [APP_TYPES.AZERBAIJAN]: 'v9',
      [APP_TYPES.BELARUS]: 'v9',
      [APP_TYPES.DEMO]: 'v9',
      [APP_TYPES.MYBRAND_AZ]: 'v9',
    };
    const apiType = apiTypes[process.env.REACT_APP_APP_TYPE];
    const result = await axios.get(`${API_URL}/${apiType}/products/rewards`);

    if (apiType === 'v9') {
      result.data = result.data.map(mapOldPrizesFormat);
    }

    return result;
  },
  getUnicardUserAccounts: async () => {
    return await axios.get(`${API_URL}/v1/unicard/card`);
  },
  setUnicardWalletNumber: async ({ wallet_number }) => {
    return await axios.post(`${API_URL}/v1/unicard/card`, { wallet_number });
  },
  getUnicardTransfers: async () => {
    return await axios.get(`${API_URL}/v1/unicard/card/transfers`);
  },
  requestUnicardSms: async () => {
    return await axios.post(`${API_URL}/v1/unicard/session`);
  },
  makeUnicardTransfer: async ({ amount, code }) => {
    return await axios.post(`${API_URL}/v1/unicard/card/pay`, { amount, code });
  },
  viewed: async (reward_id) => {
    return await axios.post(`${API_URL}/v9/products/rewards/viewed?reward_id=${reward_id}`);
  },
  isUsed: async (is_used, reward_id) => {
    return await axios.post(`${API_URL}/v9/products/rewards/used?is_used=${is_used}&reward_id=${reward_id}`);
  },
  checkEpulNumber: async () => {
    return await axios.post(`${API_URL}/v10/e-pul/verify`);
  },
  resendEpulSms: async () => {
    return await axios.post(`${API_URL}/v10/e-pul/resend`);
  },
  sendEpulSms: async ({ code }) => {
    return await axios.post(`${API_URL}/v10/e-pul/approve`, { code });
  },
  getEpulTransfers: async () => {
    return await axios.get(`${API_URL}/v10/e-pul/transfers`);
  },
  makeEpulTransfer: async ({ amount }) => {
    return await axios.post(`${API_URL}/v10/e-pul/pay`, { amount });
  },
};

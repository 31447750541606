import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectPassedMysteryShoppers } from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import { IPassedMysteryShopper } from '../../../redux/mysteryShopper/mysteryShopperTypes';
import { serviceActions } from '../../../redux/service/serviceSlice';
import { getIsFetchingSelector } from '../../../redux/service/serviceSelectors';
import { Routes } from '../../../const';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { LoaderContainer } from '../../../components/common';
import { MysteryShopperCard } from '../MysteryShopperCard/MysteryShopperCard';

import styles from './PassedMysteryShopperList.module.scss';

export const PassedMysteryShopperList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const passedMysteryShoppers = useSelector(selectPassedMysteryShoppers);
  const isFetching = useSelector(getIsFetchingSelector);

  const onBackArrowClick = () => {
    history.goBack();
  };

  const onCardClick = (id: number) => {
    history.push(`${Routes.MysteryShopperRecommendations}/${id}`);
  };

  useEffect(() => {
    dispatch(serviceActions.getMonthlyLimitation({}));
  }, []);

  return (
    <LoaderContainer isLoading={isFetching}>
      <div className={styles.container}>
        <BackArrowButton
          withoutTheme
          onHistoryReplaceCallback={onBackArrowClick}
          customClass={styles.backButton}
          buttonColor="black"
        />
        {passedMysteryShoppers &&
          passedMysteryShoppers.map(({ id, background_image, name, result_page_date_to }: IPassedMysteryShopper) => (
            <MysteryShopperCard
              key={id}
              id={id}
              name={name}
              onCardClick={onCardClick}
              background_image={background_image}
              end_date={result_page_date_to}
            />
          ))}
      </div>
    </LoaderContainer>
  );
};

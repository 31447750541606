import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDailyRewardsProducts,
  getDailyRewardsWithdrawProduct,
} from '../../../redux/dailyRewards/dailyRewardsSelectors';
import { dailyRewardsActions } from '../../../redux/dailyRewards/dailyRewardsSlice';
import { DAILY_REWARDS_PRIZES_PADDING } from '../../../const/dailyRewardsConstants';

export const DailyRewardsResult = (props) => {
  const dispatch = useDispatch();
  const products = [];
  const dailyProducts = useSelector(getDailyRewardsProducts);
  const withDrawProductId = useSelector(getDailyRewardsWithdrawProduct);
  const dailyProductWinner = dailyProducts.find((product) => product.id === withDrawProductId);
  const dailyProductsFiltered = dailyProducts.filter((product) => product.id !== withDrawProductId);
  dailyProductsFiltered.push(dailyProductWinner);
  for (let i = 0; i < 30; i++) {
    const count = i % 4;
    products.push(dailyProductsFiltered[count].photo);
  }

  const [classname, setClassname] = useState();
  const [transform, setTransfrom] = useState();

  useEffect(() => {
    dispatch(dailyRewardsActions.finalDailyRewards());
    setClassname('daily-rewards__spinner');
    setTransfrom(`translate(-${window.innerWidth * 11}px)`);
  }, [dispatch]);

  return (
    <div
      className={classname}
      style={{
        marginTop: `${window.innerHeight / 2 - (window.innerWidth / 2 - DAILY_REWARDS_PRIZES_PADDING)}px`,
        left: `-${window.innerWidth / 4 + DAILY_REWARDS_PRIZES_PADDING}px`,
        width: `${window.innerWidth * 15}px`,
        transform: transform,
        transitionProperty: 'transform',
        transitionDuration: '2s',
        transitionTimingFunction: 'cubic-bezier(1, 0, 0, 1)',
      }}
    >
      {products.map((name) => (
        <div
          key={name}
          className="daily-rewards__spinner-content"
          style={{
            width: `${window.innerWidth / 2 - DAILY_REWARDS_PRIZES_PADDING}px`,
            height: `${window.innerWidth / 2 - DAILY_REWARDS_PRIZES_PADDING}px`,
            backgroundImage: `url(${name})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
      ))}
    </div>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type QuizRateState = {
  quizMark: number | null;
  packId: number | null;
};

const initialState: QuizRateState = {
  quizMark: null,
  packId: null,
};

const quizRateSlice = createSlice({
  name: 'quizRate',
  initialState,
  reducers: {
    setQuizRate(state, action: PayloadAction<{ quizMark: number | null; packId: number | null }>): void {
      state.quizMark = action.payload.quizMark;
      state.packId = action.payload.packId;
    },
  },
});

export const quizRatActions = quizRateSlice.actions;
export const quizRateReducer = quizRateSlice.reducer;

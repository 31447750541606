import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import timerAnimation from '../Game/conex_pwa_timer.json';
import {
  getSingleModeRoundTimeSelector,
  getSingleModeQuestionsByStage,
  getSingleModeCurrentStageSelector,
  getSingleModePackIdSelector,
} from '../../../redux/singleMode/singleModeSelectors.js';
import { useInternationalization } from '../../../hooks/useTranslationHook.js';
import { singleModeActions } from '../../../redux/singleMode/singleModeSlice.js';
import { useTimer } from '../Game';
import { PlusIcon } from '../../../icons/plus-icon.js';
import { RenderGame } from '../Game/renderQuestionComponent.js';
import { checkCorrectAnswers } from '../../../redux/game/gameSelectors.js';
import { ANSWER_SOURCES } from '../../../redux/game/gameConstants.js';
import { formQuestionData } from '../EventMode/EventModeComponent.js';
import { modalActions } from '../../../components/containers/Modal/modalSlice.js';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';
import { Boosters } from '../Boosters/Boosters';
import { BOOSTERS_TYPES } from '../../../redux/boosters/boostersConstants';
import { boostersActions } from '../../../redux/boosters/boostersSlice';
import { getActiveBoosterSelector } from '../../../redux/boosters/boostersSelectors';
import { APP_TYPES } from '../../../const/appConstants';

const timerAnimationData = {
  loop: true,
  autoplay: true,
  animationData: timerAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const SingleModeGame = (props) => {
  const { theme, goBackCallback } = props;
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  const questions = useSelector(getSingleModeQuestionsByStage);
  const currentStage = useSelector(getSingleModeCurrentStageSelector);
  const gameTime = useSelector(getSingleModeRoundTimeSelector);
  const [myVariant, setMyVariant] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [checkedAnswers, setCheckedAnswers] = useState({ me: [] });
  const [answers, setAnswers] = useState([]);
  const [timerStopped, setTimerStoped] = useState(false);
  const [timerPaused, setTimerPaused] = useState(false);
  const pack_id = useSelector(getSingleModePackIdSelector);
  const activeBooster = useSelector(getActiveBoosterSelector);

  const onLeaveCallback = useCallback(() => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('Are you sure you want to finish the game?'),
        btnText: formatMessage('Yes, exit'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(singleModeActions.finishSingleMode());
          goBackCallback();
        },
        onClose: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  }, [dispatch, goBackCallback, formatMessage]);

  const finishStageCallback = useCallback(() => {
    setTimerPaused(true);
    setTimerStoped(true);
    dispatch(singleModeActions.finishStage());
  }, [dispatch]);

  const nextQuestionCallback = useCallback(() => {
    if (currentQuestion + 1 === Number(questions.length)) {
      finishStageCallback();
    } else
      setTimeout(() => {
        setTimerStoped(true);
        setCurrentQuestion(currentQuestion + 1);
      }, 500);
  }, [currentQuestion, finishStageCallback, questions]);

  const onTimerExpiredCallback = useCallback(() => {
    finishStageCallback();
  }, [finishStageCallback]);

  const onAnswerClicked = useCallback(
    (answer) => {
      if (!myVariant) {
        setTimerPaused(true);
        setMyVariant(answer);
        const currentAnswers = [...answers];
        currentAnswers.push(answer);
        const answersToCheck = checkCorrectAnswers(currentAnswers, questions);

        if (answersToCheck[currentQuestion].isCorrect) {
          dispatch(singleModeActions.setAnswer({ source: ANSWER_SOURCES.ME, answer }));
          setAnswers(currentAnswers);
          setCheckedAnswers({ me: answersToCheck });
          nextQuestionCallback();
        } else if (activeBooster === BOOSTERS_TYPES.SECOND_CHANCE) {
          dispatch(boostersActions.secondChanceUsed(answer));
        } else {
          setAnswers(currentAnswers);
          setCheckedAnswers({ me: answersToCheck });
          finishStageCallback();
        }
      }
    },
    [
      myVariant,
      answers,
      dispatch,
      nextQuestionCallback,
      questions,
      currentQuestion,
      finishStageCallback,
      activeBooster,
    ],
  );

  useEffect(() => {
    setMyVariant(null);
    setTimerPaused(false);
    setTimerStoped(false);
  }, [currentQuestion]);

  useEffect(() => {
    setCurrentQuestion(0);
    setTimerStoped(false);
  }, [currentStage]);

  useEffect(() => {
    setMyVariant(null);
  }, [activeBooster]);

  useTimer(currentQuestion, onTimerExpiredCallback, gameTime, dispatch);

  if (!questions) {
    onLeaveCallback();
    return null;
  }

  return (
    <div className="single-mode__game">
      <div className="single-mode__game-leave-game" onClick={onLeaveCallback}>
        <PlusIcon className="single-mode__game-leave-game-icon" style={{ fill: theme.color }} />{' '}
        {formatMessage('Tap to exit')}
      </div>

      <div className="single-mode__game-timer-animation">
        <LottieWrapper
          isClickToPauseDisabled
          options={timerAnimationData}
          width={'auto'}
          isStopped={timerStopped}
          isPaused={timerPaused}
          style={{ margin: 'auto', maxWidth: '340px' }}
          speed={15 / gameTime}
        />
      </div>

      <RenderGame
        currentQuestion={formQuestionData(questions[currentQuestion])}
        currentQuestionNumber={currentQuestion}
        questions={questions.map((i) => formQuestionData(i))}
        theme={theme}
        myVariant={myVariant}
        onAnswerClicked={onAnswerClicked}
        checkedAnswers={checkedAnswers}
        withoutOpponent={true}
        withBoosters={process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY}
      />

      {process.env.REACT_APP_APP_TYPE === APP_TYPES.HUNGARY && (
        <Boosters
          pack_id={pack_id}
          solo_mode
          currentQuestion={formQuestionData(questions[currentQuestion])}
          myVariant={myVariant}
          setMyAnswerCallback={onAnswerClicked}
          roundTime={15}
        />
      )}
    </div>
  );
};

import { useCallback } from 'react';
import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { isMobileSafari } from '../../../helpers/checkIsSafariBrowser';
import { isArmenia, isGeorgia } from '../../../helpers';

import { Button } from '../../../components/common/Button';
import { Header } from '../../../components/common/Header';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';

import noBonusGame from '../../noBonusGamesInfo/noBonusGame.json';
import './specialQuizNoBonusGame.scss';

export const SpecialQuizNoBonusGame = (props) => {
  const { match, history } = props;
  const { brandId: brand_id } = match.params;
  const { formatMessage } = useInternationalization();

  const isArmeniaOrGeorgia = isGeorgia() || isArmenia();

  const setNoBonusGamesMessageRead = useCallback(() => history.replace(`/special-quiz/${brand_id}`), [
    brand_id,
    history,
  ]);
  const goBackCallback = useCallback(() => history.replace('/knowledge-bank/2'), [history]);

  return (
    <div className="spec-quiz-no-bonus-game">
      <ScrollBlock fromTop>
        <Header customClass="spec-quiz-no-bonus-game__header" goBackCallback={goBackCallback} />
        <div
          className="spec-quiz-no-bonus-game__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
        >
          <LottieWrapper
            options={options}
            style={{
              height: '340px',
            }}
          />
          <div className={'spec-quiz-no-bonus-game__bottom-text'}>
            <p className="spec-quiz-no-bonus-game__content-area-caption">{formatMessage('Ooops(no SQ games)')}</p>
            <p
              className={classnames(
                'spec-quiz-no-bonus-game__content-area-caption',
                'spec-quiz-no-bonus-game__content-area-description',
              )}
            >
              {formatMessage('You had already got coins for this quiz.')}
              {!isArmeniaOrGeorgia && formatMessage("You can't get any more, but try our extra quiz!")}
            </p>
            <div className={'spec-quiz-no-bonus-game__button-container'}>
              <Button onClick={setNoBonusGamesMessageRead}>{formatMessage('Got it')}</Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: noBonusGame,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

import React from 'react';
import classNames from 'classnames';

import styles from './NewMessageBadge.module.scss';

type Props = {
  className?: string;
};

export const NewMessageBadge = ({ className }: Props) => (
  <div className={classNames(styles.newMessageBadge, className)} />
);

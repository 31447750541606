import { all, put, call, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import request from 'axios';
import { isFunction } from 'lodash';

import { recognitionApi } from '../../api/recognitionApi';
import { serviceApi } from '../../api/serviceApi';
import { recognitionActions } from './recognitionSlice';

function* handleError(error: unknown, callBack?: (status: boolean) => void) {
  if (request.isAxiosError(error) && error.response) {
    const { message } = error?.response?.data;
    yield put(recognitionActions.setError(message));

    if (isFunction(callBack)) {
      yield call(callBack, false);
    }
  }
}

function* fetchBarcodeInfo(action: PayloadAction<{ barcode: number; callBack?: (status: boolean) => void }>) {
  const { barcode, callBack } = action.payload;

  yield put(recognitionActions.setIsFetching(true));
  try {
    const { data } = yield recognitionApi.getBarcodeInfo(barcode);

    yield put(recognitionActions.barcodeInfoReceived(data));

    if (isFunction(callBack)) {
      yield call(callBack, Boolean(data));
    }
  } catch (error) {
    yield handleError(error, callBack);
  } finally {
    yield put(recognitionActions.setIsFetching(false));
  }
}

function* uploadUnknownBarcodePhoto(
  action: PayloadAction<{ barcode: string; formData: object; callBack?: (status: boolean) => void }>,
) {
  const { barcode, formData, callBack } = action.payload;

  yield put(recognitionActions.setIsFetching(true));
  try {
    const { data } = yield serviceApi.uploadPhoto(formData);

    yield put(
      recognitionActions.sendUnknownBarcode({
        barcode,
        photo: data.file_key,
      }),
    );

    if (isFunction(callBack)) {
      yield call(callBack, true);
    }
  } catch (error) {
    yield handleError(error, callBack);
  } finally {
    yield put(recognitionActions.setIsFetching(false));
  }
}

function* sendUnknownBarcode(action: PayloadAction<{ barcode: string; photo: string }>) {
  const { barcode, photo } = action.payload;

  try {
    yield recognitionApi.postUnknownBarcode({ barcode, photo });
  } catch (error) {
    yield handleError(error);
  }
}

export function* recognitionSagas() {
  yield all([takeLatest(recognitionActions.fetchBarcodeInfo.type, fetchBarcodeInfo)]);
  yield all([takeLatest(recognitionActions.uploadUnknownBarcodePhoto.type, uploadUnknownBarcodePhoto)]);
  yield all([takeLatest(recognitionActions.sendUnknownBarcode.type, sendUnknownBarcode)]);
}

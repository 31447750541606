import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import EnterUserInfo from './enterUserInfo';

const mapStateToProps = (state) => {
  const { form, bonusShop } = state;
  const { counties, cities, user } = bonusShop;

  return {
    formValues: form.perx,
    counties: counties || '',
    cities: cities || '',
    personalAddress: form.perx ? form.perx.values.personalAddress : '',
    userEmail: user.email,
    initialValues: {
      name: user.name,
      surname: user.surname,
      eMail: user.email || '',
      countryCode: user.phone_code || '+353(0)',
      mobileNumber: user.phone || '',
      country: user.country || 'Ireland',
    },
  };
};

const form = reduxForm({
  form: 'perx',
  destroyOnUnmount: false,
  onSubmit: () => {},
})(EnterUserInfo);

export const EnterUserInfoContainer = connect(mapStateToProps)(form);

import { all, takeLatest, put, select, call, delay, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { scenarioActions } from './scenarioSlice';
import { SCENARIO_MESSAGE_TYPES } from './scenarioConstants';
import { getCurrentScenarioSelector, getScenarioChatMessages } from './scenarioSelectors';
import { Routes } from '../../const';
import { knowledgeBankApi } from '../../api/knowledgeBankApi';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { store } from '../store';

function* startScenarioSaga({ payload }) {
  const { scenario_id } = payload;
  const { data } = yield knowledgeBankApi.startScenario(Number(scenario_id));
  yield put(scenarioActions.setCurrentScenarioData(data));
  yield put(push(Routes.ScenarioRoute));
  yield call(scenarioControllerSaga);
}

function* scenarioControllerSaga() {
  let scenario = yield select(getCurrentScenarioSelector);
  yield put(scenarioActions.addScenarioChatMessages({  title: false,text:  scenario.step, type: SCENARIO_MESSAGE_TYPES.STEP, key: 0 }))
  yield delay(500);
  for (let i = 0; i < scenario.text.length; i++) {
    const message = { text: scenario.text[i], type: SCENARIO_MESSAGE_TYPES.CUSTOMER, key: i+1 };
    i === 0 ? (message.title = true) : (message.title = false);
    yield put(scenarioActions.addScenarioChatMessages(message));
    yield delay(1450);
  }
  yield put(scenarioActions.setIsAnswerButtonActive(true));
}

function* scenarioAnswerThreadSaga() {
  const scenario = yield select(getCurrentScenarioSelector);
  yield put(scenarioActions.setIsAnswerScreenActive(true));
  yield put(scenarioActions.setIsAnswerButtonActive(false));
  const messages  = yield select(getScenarioChatMessages);
  let chatMessagesQuantity = messages.length;
  const sellerAnswerPayload = yield take(scenarioActions.setSellerAnswer);
  const answer = sellerAnswerPayload.payload.answer;
  const answerResponse = yield knowledgeBankApi.scenarioAnswer(answer.next_question_id);
  yield delay(1000);
  yield put(scenarioActions.setIsAnswerScreenActive(false));
  yield delay(1000);


  for (let i = 0; i < answer.text.length; i++) {
    const message = { text: answer.text[i], type: SCENARIO_MESSAGE_TYPES.SELLER, key: chatMessagesQuantity };
    message.title = i === 0;
    ++chatMessagesQuantity;
    yield put(scenarioActions.addScenarioChatMessages(message));
    yield delay(1500);
  }

  yield put(scenarioActions.setPreviousScenarioData(scenario));
  yield put(scenarioActions.setCurrentScenarioData(answerResponse.data));

  yield delay(1000);

  yield put(
    modalActions.openScenarioReactionModal({
      description: answer.comment.text,
      title: answer.comment.title,
      correct: answer.correct,
      onClose: () => store.dispatch(scenarioActions.reactionModalViewed()),
    }),
  );

  yield take(scenarioActions.reactionModalViewed);
  let newScenario = yield select(getCurrentScenarioSelector);

  yield put(
    scenarioActions.addScenarioChatMessages({
      title: false,
      text: newScenario.step,
      type: SCENARIO_MESSAGE_TYPES.STEP,
      key: chatMessagesQuantity,
    }),
  );

  yield delay(1200);

  for (let i = 0; i < newScenario.text.length; i++) {
    ++chatMessagesQuantity;
    const message = { text: newScenario.text[i], type: SCENARIO_MESSAGE_TYPES.CUSTOMER, key: chatMessagesQuantity };
    message.title = i === 0;
    yield put(scenarioActions.addScenarioChatMessages(message));
    yield delay(1900);
  }
  yield put(scenarioActions.setIsAnswerButtonActive(true));
}

function* sagas() {
  yield all([
    takeLatest(scenarioActions.startScenario, startScenarioSaga),
    takeLatest(scenarioActions.answerButtonClick, scenarioAnswerThreadSaga),
  ]);
}

export const scenarioSagas = sagas;

export const AvatarIconDefault = ({ withoutBorder, ...props }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 47C36.7026 47 47 36.7026 47 24C47 11.2975 36.7026 1 24 1C11.2975 1 1 11.2975 1 24C1 36.7026 11.2975 47 24 47Z"
      stroke={withoutBorder ? 'transparent' : props.stroke || 'black'}
    />
    <path
      d="M8.66602 40.8666C8.66602 34.5082 13.8147 30.5166 20.166 30.5166"
      stroke={props.stroke || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.334 40.8666C39.334 34.5082 34.1853 30.5166 27.834 30.5166"
      stroke={props.stroke || 'black'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0587 27.1846C28.7489 27.1846 32.551 23.3824 32.551 18.6923C32.551 14.0021 28.7489 10.2 24.0587 10.2C19.3685 10.2 15.5664 14.0021 15.5664 18.6923C15.5664 23.3824 19.3685 27.1846 24.0587 27.1846Z"
      stroke={props.stroke || 'black'}
    />
  </svg>
);

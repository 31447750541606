import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';

import { MESSAGE_TYPES_WITH_MENU } from '../../../../redux/chat/chatConstants';
import { chatActions, ticketChatActions, ticketChatSelectors } from '../../../../redux/chat';
import { MessageType, TicketStatus } from '../../../../redux/chat/chatTypes';
import { ChatMenu } from '../../components';
import { Chat } from '../../views';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../../config';
import { getMessageType } from '../../../../helpers/getMessageType';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

export const TicketChat = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id: ticketId } = useParams<{ id: string }>();
  const [problemId, setProblemId] = useState<number | undefined>(undefined);
  const { formatMessage } = useInternationalization();

  const messagesByDate = useSelector(ticketChatSelectors.selectMessagesByDate);
  const lastMessage = useSelector(ticketChatSelectors.selectLastMessage);
  const ticket = useSelector(ticketChatSelectors.selectTicket);
  const problems = useSelector(ticketChatSelectors.selectProblems);
  const hasMore = useSelector(ticketChatSelectors.selectHasMore);
  const isFetching = useSelector(ticketChatSelectors.selectIsFetching);
  const isImageUploading = useSelector(ticketChatSelectors.selectIsImageUploading);
  const totalCount = useSelector(ticketChatSelectors.selectTotal);
  const isInitialLoading = isFetching && !totalCount;
  const isMenuShown = lastMessage && MESSAGE_TYPES_WITH_MENU.includes(lastMessage.type);
  const hasUserSelectedProblem =
    lastMessage?.type !== MessageType.FirstLevel && lastMessage?.type !== MessageType.CustomProblem;
  const headerTitle = isMenuShown ? formatMessage('chat.title') : `#${ticketId} ${ticket?.name || ''}`;
  const canSendMessage = ticket?.status !== TicketStatus.Completed && lastMessage?.type !== MessageType.KnowledgeBank;

  const fetchProblems = () => {
    if (!isFeatureEnabled(CONFIG_KEYS.CHAT_WITHOUT_OPTIONS_ENABLED))
      dispatch(ticketChatActions.fetchProblems({ ticketId, problemId }));
  };

  const fetchMessages = (offset: number = 0) => {
    dispatch(ticketChatActions.fetchMessages({ offset, ticketId }));
  };

  const fetchMore = () => {
    fetchMessages(totalCount);
  };

  const fetchTicket = () => {
    dispatch(ticketChatActions.fetchTicket({ ticketId }));
  };

  const closeTicket = () => {
    dispatch(ticketChatActions.closeTicket({ ticketId }));
  };

  const sendMessage = (text: string) => {
    const type = getMessageType(lastMessage?.type as number);
    dispatch(ticketChatActions.sendMessage({ text, ticketId, type }));
  };

  const uploadImage = (formData: object) => {
    dispatch(ticketChatActions.uploadImage({ formData, ticketId }));
  };

  const chooseProblem = (problemId: number) => {
    dispatch(ticketChatActions.chooseProblem({ ticketId, problemId }));
  };

  const handleProblemClick = (problemId: number) => {
    setProblemId(problemId);
    chooseProblem(problemId);
    fetchProblems();
  };

  const handleOtherClick = (text: string, type?: MessageType) => {
    dispatch(ticketChatActions.chooseOther({ ticketId, text, type }));
  };

  const handleBackClick = () => {
    if (hasUserSelectedProblem) {
      history.goBack();
    } else {
      dispatch(
        modalActions.openPromptWithTwoButtonsModal({
          title: 'ticket.cancellation',
          btnText: 'Yes',
          anotherBtnText: 'No',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
            history.goBack();
          },
          onAnotherButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  };

  const reset = () => {
    dispatch(ticketChatActions.reset());
    dispatch(chatActions.fetchUnreadMessagesCount());
    dispatch(chatActions.updateLastCheckedTicketMessage({ ticketId }));
  };

  useEffect(() => {
    isMenuShown && fetchProblems();
  }, [lastMessage?.id]);

  useEffect(() => {
    setProblemId(ticket?.problemId);
  }, [ticket?.problemId]);

  useEffect(() => {
    fetchTicket();
    fetchMessages();

    return reset;
  }, []);

  return (
    <Chat
      headerTitle={headerTitle}
      messagesByDate={messagesByDate}
      totalCount={totalCount}
      hasMore={hasMore}
      fetchMore={fetchMore}
      isLoading={isInitialLoading || isImageUploading}
      onSendMessageClick={sendMessage}
      onImageUpload={uploadImage}
      canSendMessage={canSendMessage}
      onBackClick={handleBackClick}
    >
      {isMenuShown && (
        <ChatMenu
          lastMessage={lastMessage}
          problems={problems}
          onProblemClick={handleProblemClick}
          onOtherClick={handleOtherClick}
          onYesClick={closeTicket}
        />
      )}
    </Chat>
  );
};

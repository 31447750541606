export const FailedStatusIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="8" cy="8" r="7.5" stroke="#FF6666" />
    <rect
      x="4.67236"
      y="5.33826"
      width="0.941176"
      height="8.47059"
      rx="0.470588"
      transform="rotate(-45 4.67236 5.33826)"
      fill="#FF6666"
    />
    <rect
      x="10.6621"
      y="4.70593"
      width="0.941176"
      height="8.47059"
      rx="0.470588"
      transform="rotate(45 10.6621 4.70593)"
      fill="#FF6666"
    />
  </svg>
);

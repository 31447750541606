import React, { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Field } from 'redux-form';
import { useSelector } from 'react-redux';

import { quizQuestion } from '../../../helpers/formValidations';
import { AnswerType } from '../../../redux/quickTips/quickTipsTypes';
import { quickTipsSelectors } from '../../../redux/quickTips/quickTipsSelectors';

import styles from './QuizQuestion.module.scss';

type Props = {
  answers: AnswerType[];
};

type FieldProps = {
  input: InputHTMLAttributes<HTMLInputElement>;
  label: string;
  type: string;
  onClick: () => void;
  correct: boolean;
};

const QuizQuestion = ({ answers }: Props) => {
  const labelClasses = classNames(styles.label, 'activeAnswer');
  const touched = useSelector(quickTipsSelectors.getIsTouched);
  const error = useSelector(quickTipsSelectors.getErrorText);

  const renderField = ({ input, label, type, onClick, correct }: FieldProps) => {
    const isIncorrectAnswer = correct !== undefined && !correct;

    return (
      <div className={styles.wrapper}>
        <input
          className={classNames(styles.answerInput, { [styles.incorrectAnswer]: isIncorrectAnswer }, 'activeAnswer')}
          onClick={onClick}
          placeholder={label}
          id={`${input.value}`}
          type={type}
          {...input}
        />
        <label className={classNames(labelClasses, { [styles.correctAnswer]: correct })} htmlFor={`${input.value}`}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {answers.map(({ answer, id, correct }) => (
        <Field
          key={id}
          component={renderField}
          type="radio"
          value={answer}
          name="value"
          label={answer}
          correct={correct}
          validate={[quizQuestion]}
        />
      ))}
      {touched && error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export default QuizQuestion;

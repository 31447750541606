import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  answers: {
    me: [],
  },
  currentQuestion: 0,
  coins: null,
  pack_id: null,
  game_time: null,
  questions: null,
  isBonusGameFinished: false,
  coinsForGame: 0,
};

const soloModeSlice = createSlice({
  name: 'soloMode',
  initialState,
  extraReducers: {
    '@@INIT': (payload) => {
      return {
        ...initialState,
        game_time: payload.game_time,
      };
    },
  },
  reducers: {
    run() {},
    startSoloMode(state, { payload }) {},
    startGame() {},
    finishSoloMode() {},
    reset(state, { payload }) {
      return { ...initialState, game_time: state.game_time };
    },
    setSoloModeData(state, { payload }) {
      const { questions, pack_id, game_time, coins } = payload;
      return {
        ...state,
        questions,
        pack_id,
        game_time,
        coins,
      };
    },
    setAnswer(state, { payload: { source, answer } }) {
      const newAnswers = { ...state.answers };
      newAnswers[source] = newAnswers[source].concat(answer);
      return {
        ...state,
        answers: newAnswers,
      };
    },
    nextQuestion(state) {
      let currentQuestion = state.currentQuestion;
      if (currentQuestion < state.questions.length - 1) {
        currentQuestion += 1;
      }

      return {
        ...state,
        currentQuestion,
      };
    },
    setFinishedNotBonusGame(state, { payload }) {
      const bonusGamesAmount = payload;

      return {
        ...state,
        isBonusGameFinished: bonusGamesAmount ? true : false,
      };
    },
    setCoinsForGame(state, { payload }) {
      const { coins } = payload;

      return {
        ...state,
        coinsForGame: coins,
      };
    },
  },
});

export const soloModeActions = soloModeSlice.actions;
export const soloModeReducer = soloModeSlice.reducer;

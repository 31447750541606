import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import './ExchangeModal.scss';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalTitle } from '../../containers/Modal/ModalTitle';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../common/Button';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalSlice';
import { getUserBalanceSelector } from '../../../redux/profile/profileSelectors';
import { MinusIcon } from '../../../icons/minus-money-icon';
import { PlusIcon } from '../../../icons/plus-money-icon';

const styles = {
  width: 343,
};

export const ExchangeModal = (props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const coins = useSelector(getUserBalanceSelector);

  const [currentLeafAmount, setCurrentLeafAmount] = useState(0);

  let {
    data: { onClose, btnText, title, rate, step, makeTransfer },
  } = useSelector(getModalDataSelector);

  const handleButtonClick = () => {
    if (makeTransfer) {
      makeTransfer({ leaf: currentLeafAmount });
      return;
    }

    dispatch(modalActions.closeModal());
  };

  const getCoins = () => {
    return rate * currentLeafAmount;
  };

  const decreaseCoins = () => {
    if (currentLeafAmount !== 0) {
      setCurrentLeafAmount(currentLeafAmount - step);
    }
  };

  const increaseCoins = () => {
    if (coins - getCoins() >= step) {
      setCurrentLeafAmount(currentLeafAmount + step);
    }
  };

  return (
    <ModalContainer modal={MODALS.exchange} onClose={onClose} customStyles={styles}>
      <div className="exchange-modal">
        {title && <ModalTitle>{formatMessage(title)}</ModalTitle>}
        <div className="exchange-modal__counter">
          <MinusIcon disabled={currentLeafAmount === 0} onClick={decreaseCoins} />
          <div className="exchange-modal__counter-rate">
            <p className="exchange-modal__counter-rate-coins">{`${currentLeafAmount} ${formatMessage('leaf')}`}</p>
            <p className="exchange-modal__counter-rate-money">{`${getCoins()} ${formatMessage('credits')}`}</p>
          </div>
          <PlusIcon disabled={coins - getCoins() < step} onClick={increaseCoins} />
        </div>
        {btnText && (
          <Button
            disabled={currentLeafAmount === 0}
            onClick={handleButtonClick}
            customClass={`exchange-modal__btn${currentLeafAmount === 0 ? '-disabled' : ''}`}
          >
            {formatMessage(btnText)}
          </Button>
        )}
      </div>
    </ModalContainer>
  );
};

ExchangeModal.propTypes = {
  onClose: PropTypes.func,
};
ExchangeModal.defaultProps = {};

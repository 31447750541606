import { useCallback } from 'react';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { isMobileSafari } from '../../../helpers/checkIsSafariBrowser';

import { Button } from '../../../components/common/Button';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { Header } from '../../../components/common/Header';
import { ScrollBlock } from '../../../components/common/ScrollBlock';
import { LottieWrapper } from '../../../components/lootieWrapper/lootieWrapper';

import noBonusGame from '../../noBonusGamesInfo/noBonusGame.json';
import './noBonusSurvey.scss';

export const NoBonusSurveyComponent = (props) => {
  const { history } = props;
  const { formatMessage } = useInternationalization();

  const onBackButtonClick = useCallback(() => {
    return history.goBack();
  }, [history]);
  return (
    <div className="no-bonus-survey__page">
      <ScrollBlock fromTop>
        <Header customClass="no-bonus-survey__header"></Header>
        <div
          className="no-bonus-survey__content-area"
          style={{ minHeight: isMobileSafari() ? 'calc(100% - 65px)' : 'calc(100% - 68px)' }}
        >
          <LottieWrapper
            options={options}
            style={{
              height: '340px',
            }}
          />
          <div className="no-bonus-survey__content-area-bottom-text">
            <p className="no-bonus-survey__content-area-bottom-text-caption">
              {formatMessage('Survey is not available anymore')}
            </p>
            <p className="no-bonus-survey__content-area-bottom-text-description">
              {formatMessage('You have already passed this Survey')}
            </p>
            <div className="no-bonus-survey__btn-container">
              <Button customClass="no-bonus-survey__btn-container-btn-component" onClick={() => onBackButtonClick()}>
                {formatMessage('OK')}
              </Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter withoutNavigation />
      </ScrollBlock>
    </div>
  );
};

const options = {
  loop: true,
  autoplay: true,
  animationData: noBonusGame,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { Spinner } from '../../../../components/common';
import { Button } from '../../../../components/common';
import { FocusIcon } from '../../icons/Focus';

import styles from './ScreenTemplate.module.scss';

interface CurrStepData {
  title?: string;
  description?: string;
  centerScreen?: string;
  button?: { text: string; path?: string };
  focusFrameStatus?: boolean;
}

type Props = {
  spinnerStatus?: boolean;
  currStepData?: CurrStepData;
  focusFrameColor?: string;
  setCurrDataScreen: () => void;
};

const ScreenTemplate = ({ currStepData, setCurrDataScreen, focusFrameColor, spinnerStatus }: Props) => {
  if (!currStepData) return null;

  const { formatMessage } = useInternationalization();

  const { title, description, centerScreen, button, focusFrameStatus } = currStepData;

  return (
    <>
      <div className={styles.topWrapper}>
        {title && <div className={styles.title}>{formatMessage(title)}</div>}
        {description && <div className={styles.description}>{formatMessage(description)}</div>}
      </div>
      <div className={styles.middleWrapper}>
        {centerScreen && <img src={centerScreen} />}
        {focusFrameStatus && (
          <div className={styles.focusWrapper}>
            <FocusIcon stroke={focusFrameColor} />
          </div>
        )}
      </div>
      <div className={styles.bottomWrapper}>
        {button?.text && (
          <Button className={styles.button} onClick={setCurrDataScreen}>
            {formatMessage(button.text)}
          </Button>
        )}
      </div>
      {spinnerStatus && (
        <div className={styles.spinnerWrapper}>
          <div className={styles.spinnerBackGround} />
          <Spinner width={200} />
        </div>
      )}
    </>
  );
};

export default ScreenTemplate;

import axios from 'axios';

import { API_URL } from './baseApi';

const sortByName = (arr) => {
  arr.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const serviceApi = {
  getPositions: async () => {
    return await axios.get(`${API_URL}/v3/services/positions`);
  },
  getLinks: async () => {
    return await axios.get(`${API_URL}/v3/profile/links`);
  },
  getManagersCities: async () => {
    const response = await axios.get(`${API_URL}/v3/services/managers/cities`);
    sortByName(response.data);
    return response;
  },
  getManagers: async () => {
    return await axios.get(`${API_URL}/v3/services/managers`);
  },
  uploadPhoto: async (formData) => {
    return await axios.post(`${API_URL}/v3/services/file`, formData);
  },
  getMonthlyLimitation: async () => {
    return await axios.get(`${API_URL}/v6/monthly-limitation`);
  },
  getCities: async () => {
    return await axios.get(`${API_URL}/v8/outlet/cities`);
  },
  getLegalEntities: async (city_id) => {
    if (isNaN(Number(city_id))) {
      return await axios.get(`${API_URL}/v8/outlet/legal-entities`);
    }
    return await axios.get(`${API_URL}/v8/outlet/legal-entities/?city_id=${city_id}`);
  },
  getAddresses: async (legal_entity_id, city_id) => {
    if (isNaN(Number(legal_entity_id))) {
      return await axios.get(`${API_URL}/v8/outlet/addresses`);
    }
    return await axios.get(`${API_URL}/v8/outlet/addresses/?city_id=${city_id}&legal_entity_id=${legal_entity_id}`);
  },
  getManager: async (outlet_address_id) => {
    if (isNaN(Number(outlet_address_id))) {
      return await axios.get(`${API_URL}/v8/outlet/manager`);
    }
    return await axios.get(`${API_URL}/v8/outlet/manager/?outlet_address_id=${outlet_address_id}`);
  },
};

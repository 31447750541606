import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { isIPhoneWithHomeBar } from '../../../../helpers/checkIsIphoneX';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { getUserBalanceSelector } from '../../../../redux/profile/profileSelectors';
import {
  getIsFetching,
  getUnicardTransfersSelector,
  getUnicardUserAccount,
  launchTimerOppaButton as launchUnicardTimerButton,
} from '../../../../redux/bonusShop/bonusShopSelectors';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';
import useTimer from '../../../../hooks/useTimerHook';

import { Routes } from '../../../../const';
import { UserRoles } from '../../../../enums';
import { ContentSpinner } from '../../../../components/common/Spinner/ContentSpinner';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { CashoutHistory } from '../../cashoutHistory/cashoutHistory';
import { FAQ_ARTICLE_ID } from '../../../../redux/knowledgeBank/knowledgeBankConstants';
import { QuestionMarkIcon } from '../../../../icons/question-mark-icon';
import { SettingsIcon } from '../../../../icons';
import { Header } from '../../../../components/common/Header';
import { Balance } from '../../../../components/common/Balance';
import { Button } from '../../../../components/common/Button';
import { checkUserRole } from '../../../../helpers/checkUserRole';

import styles from './UnicardMainPage.module.scss';

export const unicardTimerValue = 180000;

export const UnicardMainPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const history = useHistory();
  const isFetching = useSelector(getIsFetching);
  const coins = useSelector(getUserBalanceSelector);
  const transfers = useSelector(getUnicardTransfersSelector);
  const unicardAccount: string = useSelector(getUnicardUserAccount);

  const launchTimer = useSelector(launchUnicardTimerButton);
  const dateEnd: any = localStorage.getItem('endTimeDisableUnicardButton');
  const [timerLeft, setTimerLeft] = useState(dateEnd - new Date().getTime());
  const { minutes, seconds } = useTimer(timerLeft, setTimerLeft, launchTimer);

  const isModalShown = checkUserRole(UserRoles.BA, UserRoles.JTI_TEAM, UserRoles.RSM);

  const rate: number = 1 / 70;

  useEffect(() => {
    // @ts-ignore
    dispatch(bonusShopActions.getUnicardTransfers());
  }, [dispatch]);

  const openHelpArticle = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jti-club.az/umico/Umico.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  const onBack = () => {
    history.push(Routes.BonusShopRoute);
  };

  const openSettingsPage = () => {
    // @ts-ignore
    dispatch(bonusShopActions.getUnicardUserAccounts());
  };

  const makeUnicardTransfer = (amount: number, code: string, setAsyncError: () => {}) => {
    dispatch(bonusShopActions.makeUnicardTransfer({ amount, code, setAsyncError }));
  };

  const requestSms = (amount: number) => {
    dispatch(
      bonusShopActions.requestUnicardSms({
        currentCoinsReserved: amount,
        makeTransfer: makeUnicardTransfer,
      }),
    );
  };

  const makeTransfer = () => {
    if (isModalShown) {
      dispatch(
        modalActions.openPromptModal({
          description: 'Not available for JTI representatives.',
          btnText: 'OK',
        }),
      );
    } else if (coins < 350) {
      dispatch(
        modalActions.openPromptModal({
          title: 'Ooops',
          description: "You don't have enough сoins for money transfer",
          btnText: 'OK',
        }),
      );
    } else {
      dispatch(
        modalActions.openCashoutModal({
          title: 'Choose amount',
          btnText: 'Proceed',
          symbol: '₾',
          moneyLimit: 50,
          rate: rate,
          step: 70,
          amountMoneyIcons: [5, 10, 20],
          minimumTransferMoney: 350,
          makeTransfer: ({ amount }: { amount: number }) => {
            requestSms(amount);
          },
          onClose: () => {},
        }),
      );
    }
  };

  return (
    <div>
      {isFetching ? (
        <ContentSpinner />
      ) : (
        <div className={styles.mainPage}>
          <Header hasBackButton customClass={styles.header} goBackCallback={onBack}>
            {formatMessage('Unicard')}
          </Header>
          <Balance fixed={false} activePage={0} />

          <div className={styles.container}>
            <SettingsIcon className={styles.settings} onClick={openSettingsPage} />

            <div className={styles.wallet}>
              <p className={styles.title}>{formatMessage('Your UNIWallet:')}</p>
              <p className={styles.walletNumber}>{unicardAccount}</p>
            </div>
            <div className={styles.history}>
              <QuestionMarkIcon className={styles.faq} onClick={openHelpArticle} />
              <CashoutHistory symbol="₾" rate={rate} specificTransfers={transfers} />
            </div>
          </div>
          <div style={{ bottom: isIPhoneWithHomeBar() ? '49px' : '15px' }} className={styles.buttonContainer}>
            {minutes || seconds ? (
              <Button disabled customClass={styles.transferButton}>
                {formatMessage('Next transaction ')}
                {minutes ? ` ${minutes}` : ''}
                {seconds ? ` ${seconds}` : ''}
                {formatMessage(' in')}
              </Button>
            ) : (
              <Button customClass={styles.transferButton} onClick={makeTransfer}>
                {formatMessage('Transfer')}
              </Button>
            )}
          </div>
          <DisclaimerFooter withoutNavigation />
        </div>
      )}
    </div>
  );
};

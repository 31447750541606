import { Field } from 'redux-form';
import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import styles from './Checkbox.module.scss';

export const CommonCheckboxInput = (props) => {
  const {
    options,
    input: { onChange, value },
    meta: { touched, error },
    sectionId,
  } = props;
  const { formatMessage } = useInternationalization();

  const handleOnChange = (event, option) => {
    const newValue = [...value];
    if (event.target.checked) {
      newValue.push({ answer: option.answer, correct: option.correct, section_id: sectionId });
    } else {
      newValue.splice(newValue.map((value) => value.answer).indexOf(option.answer), 1);
    }

    return onChange(newValue);
  };

  return (
    <div>
      <div className={styles.checkboxBlock}>
        {options.map((option, index) => (
          <label
            key={option.answer + index}
            className={classnames(styles.container, touched && error && styles.invalidInput)}
          >
            <input type="checkbox" value={option.answer} onChange={(event) => handleOnChange(event, option)} />
            <span className={styles.checkmark} />
            <span className={styles.checkboxValue}>{option.answer}</span>
          </label>
        ))}
      </div>
      {touched && error && <div className={styles.errorMessage}>{formatMessage(error)}</div>}
    </div>
  );
};

export const CheckboxInput = (props) => {
  return <Field component={CommonCheckboxInput} {...props} />;
};

import { all, takeLatest } from '@redux-saga/core/effects';
import { Socket } from '../../api/SocketHandler';
import { INVITE_ACTIONS } from './inviteConstants';

function* acceptInviteSaga(action) {
  try {
    yield Socket.acceptInvite(action.payload);
  } catch (err) {
    console.error('acceptInviteSaga', err);
  }
}

function* declineInviteSaga(action) {
  try {
    yield Socket.declineInvite(action.payload);
  } catch (err) {
    console.error('acceptInviteSaga', err);
  }
}

function* sendAnswerSaga({ payload }) {
  try {
    yield Socket.sendAnswer({ payload });
  } catch (err) {
    console.error('acceptInviteSaga', err);
  }
}

function* sagas() {
  yield all([
    takeLatest(INVITE_ACTIONS.ACCEPT_INVITE, acceptInviteSaga),
    takeLatest(INVITE_ACTIONS.SEND_ANSWER, sendAnswerSaga),
    takeLatest(INVITE_ACTIONS.DECLINE_INVITE, declineInviteSaga),
  ]);
}
export const inviteSagas = sagas;

import { object, string } from 'yup';

import { getCityDataByName, validateMinWorkingDaysCount, validateWorkingDay } from '../helpers/bonusShopHelpers';
import { PRODUCT_DELIVERY_CHANNEL } from '../const';

const requiredMessage = 'common.validation.required';

export const getOrderDetailsSchema = (deliveryChannel: number) => {
  if (deliveryChannel === PRODUCT_DELIVERY_CHANNEL.DIGITAL) {
    return object().shape({
      email: string().email(),
    });
  }

  const requiredIfDeliveryAvailable = () =>
    string().when('city', {
      is: (city: string) => getCityDataByName(city)?.delivery,
      then: (schema) => schema.required(requiredMessage),
      otherwise: (schema) => schema.notRequired(),
    });

  return object().shape({
    city: string().required(requiredMessage),
    phone: string().required(requiredMessage),
    delivery_date: string().when('city', {
      is: (city: string) => getCityDataByName(city)?.delivery,
      then: (schema) =>
        schema
          .required(requiredMessage)
          .test('date_invalid', 'common.validation.working_days_duration', validateMinWorkingDaysCount)
          .test('date_invalid', 'common.validation.working_day', validateWorkingDay),
      otherwise: (schema) => schema.notRequired(),
    }),
    delivery_time: requiredIfDeliveryAvailable(),
    zip: requiredIfDeliveryAvailable(),
    street: requiredIfDeliveryAvailable(),
    house: requiredIfDeliveryAvailable(),
    apartment: string(),
  });
};

import { createSlice } from '@reduxjs/toolkit';
import { GAME_MODES } from './settingsConstants';

const initialState = {
  settings: null,
  fetching: true,
  gameMode: GAME_MODES.SOLO,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startFetchSettings() {},
    setSettings(state, { payload: settings }) {
      return { ...state, settings, fetching: false };
    },
    setGameMode(state, { payload: gameMode }) {
      return { ...state, gameMode };
    },
    updateApp() {},
  },
});

export const settingsActions = settingsSlice.actions;
export const settingsReducer = settingsSlice.reducer;

import classNames from 'classnames';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { formatDate } from '../../../../helpers';

import { ChatMessage } from '../../../../redux/chat/chatTypes';
import { Message } from '../../containers/message/Message';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../../config';
import styles from './MessageBlock.module.scss';

type Props = {
  date: string;
  messages: ChatMessage[];
  isNotifications?: boolean;
};

export const MessageBlock = ({ date, messages, isNotifications = false }: Props) => {
  const { formatMessage } = useInternationalization();

  const currentDate = formatDate(new Date(), 'dd MMM yyyy');
  const isToday = currentDate === date;
  const isUpdatedNotificationMessage = isFeatureEnabled(CONFIG_KEYS.FACEBOOK_STYLED_NOTIFICATIONS_ENABLED);

  return (
    <div
      className={classNames(
        styles.messageBlock,
        isNotifications ? styles.notifications : styles.chat,
        isUpdatedNotificationMessage ? styles.withoutSpace : '',
      )}
    >
      {!isUpdatedNotificationMessage && <div className={styles.date}>{isToday ? formatMessage('Today') : date}</div>}

      <div className={styles.messages}>
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            className={styles.message}
            isNotification={isNotifications}
            date={date}
          />
        ))}
      </div>
    </div>
  );
};

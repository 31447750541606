import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'is-empty-typed';
import { useHistory } from 'react-router';

import '../profile.scss';

// import { ProfileLeaderboard } from '../../../components';

import { profileActions } from '../../../redux/profile/profileSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { isMobileSafari } from '../../../helpers/checkIsSafariBrowser';
import { UserProfileBaseComponent } from '../userProfileBaseComponent/userProfileBaseComponent';
import { ProfileRulesLinks } from '../profileRulesLinks/profileRulesLinks';
// import { getIsFetchingRating } from '../../../redux/profile/profileSelectors';
// import { Spinner } from '../../../components/common';
import { BoxRightArrow } from '../../../icons';
import { selectPassedMysteryShoppers } from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import { Routes } from '../../../const';
import { moderationActions } from '../../../redux/moderation/moderationSlice';
import { isHungary } from '../../../helpers';
import { checkUserRole } from '../../../helpers/checkUserRole';
import { UserRoles } from '../../../enums';

export const ProfileIreland = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();
  const history = useHistory();

  // const rating = useSelector((state) => state.profile.rating);
  // const isFetching = useSelector(getIsFetchingRating);
  const passedMysteryShoppers = useSelector(selectPassedMysteryShoppers);

  const isBA = checkUserRole(UserRoles.BA);

  useEffect(() => {
    dispatch(
      profileActions.getRating({
        filterName: 'week',
        brand_id: 0,
      }),
    );
    if (isHungary() && isBA) {
      dispatch(moderationActions.getUsersCount());
    }
  }, [dispatch]);

  useEffect(() => {
    analyticHelper.profileOpen();
  }, []);

  const onBlockClick = () => {
    const mysteryShopperId = passedMysteryShoppers[0]?.id;

    if (passedMysteryShoppers.length > 1) {
      history.push(Routes.PassedMysteryShopperList);
    } else {
      history.push(`${Routes.MysteryShopperRecommendations}/${mysteryShopperId}`);
    }
  };

  return (
    <>
      <div className="profile-page__content" style={{ minHeight: isMobileSafari() ? 'calc(100% - 150px)' : 'unset' }}>
        <UserProfileBaseComponent />
        <div className="profile-page__content-leaderboard">
          <div className="profile-page__content-leaderboard-select-filter-block ">
            {!isEmpty(passedMysteryShoppers) && (
              <div
                className="profile-page__content-leaderboard-select-filter-block__mystery-shopper"
                onClick={onBlockClick}
              >
                <span className="profile-page__content-leaderboard-select-filter-block__mystery-shopper-title">
                  {formatMessage('Mystery Shopper recommendations')}
                </span>
                <BoxRightArrow
                  className="profile-page__content-leaderboard-select-filter-block__mystery-shopper-arrow"
                  fillColor={'white'}
                />
              </div>
            )}
            {/* <div className="profile-page__content-leaderboard-select-filter-block-caption"> */}
            {/*   {formatMessage('Weekly leaderboard')} */}
            {/* </div> */}
          </div>
          {/* {isFetching ? <Spinner /> : <ProfileLeaderboard rating={rating} brandId={0} />} */}
        </div>

        <ProfileRulesLinks />
      </div>
    </>
  );
};

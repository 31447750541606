import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { Routes } from '../../../const';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';

import styles from './CheckIdCard.module.scss';

export const CheckIdCard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useInternationalization();

  const onBackArrowClick = () => {
    history.push(Routes.BonusShopRoute);
  };

  const onFillButtonClick = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
  };

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor={'black'}
      />
      <div className={styles.content}>
        <div className={styles.textWrapper}>
          <h1 className={styles.title}>{formatMessage('Collection of Personal Identification Number')}</h1>
          <p className={styles.description}>
            {formatMessage(
              'Due to the fact that we conduct financial transactions, we need your Personal Identification Number to ensure your safety. Your ability to transfer money through Bonus Shop will be limited until we receive requested information',
            )}
          </p>
        </div>
        <Button customClass={styles.button} onClick={onFillButtonClick}>
          {formatMessage('Fill the data')}
        </Button>
      </div>
    </div>
  );
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useTheme = () => {
  const theme = useSelector((state) => state.service.theme);

  useEffect(() => {
    try {
      if (theme) {
        document.querySelector(':root').style.setProperty('--theme-color', theme.baseColor);
      }
    } catch (error) {
      console.warn(error);
    }
  }, [theme.id]);
};

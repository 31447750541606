import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';

import styles from './OrderDetails.module.scss';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { getOrderDetailsSchema } from '../../validation/OrderDetailsSchema';
import { Header } from '../../components/common/Header/Header';
import { Balance } from '../../components/common/Balance';
import { ORDER_DELIVERY_TIME_SLOTS, PRODUCT_DELIVERY_CHANNEL } from '../../const';
import { IBonusShopProduct } from '../../types/IBonusShopProduct';
import OrderDetailsFieldsPhysical from './OrderDetailsFieldsPhysical';
import OrderDetailsFieldsDigital from './OrderDetailsFieldsDigital';
import { OrderDetailsFormData } from './OrderDetails.types';
import { IOrderDetails } from '../../types/IOrderDetails';
import { getCityDataByName } from '../../helpers/bonusShopHelpers';
import { ISelectOption } from '../../types/ISelectOption';

const OrderDetails = () => {
  const { t } = useInternationalization();
  const dispatch = useDispatch();

  const { selectedProviderId, selectedColorId, providers } = useSelector((state: RootStateOrAny) => state.bonusShop);

  const deliveryChannel = useMemo(
    () => providers.find(({ id }: IBonusShopProduct) => id === selectedProviderId)?.delivery_channel,
    [],
  );

  const form = useForm<OrderDetailsFormData>({
    // @ts-ignore
    resolver: yupResolver(getOrderDetailsSchema(deliveryChannel)),
    defaultValues: {
      delivery_time: '',
      delivery_date: '',
      phone: '',
      zip: '',
      city: '',
      street: '',
      house: '',
      apartment: '',
    },
  });

  const { watch, handleSubmit, resetField } = form;

  const city = watch('city');
  const cityData = getCityDataByName(city);

  const timeOptions: ISelectOption[] = useMemo(
    () =>
      ORDER_DELIVERY_TIME_SLOTS.map((slot, index) => ({
        name: t('bonus_shop.order_details.time_slot', { timeFrom: slot[0], timeTo: slot[1] }),
        id: String(index),
        value: slot,
      })),
    [],
  );

  useEffect(() => {
    resetField('delivery_time');
    resetField('delivery_date');
    resetField('apartment');
    resetField('zip');
    resetField('street');
    resetField('house');
  }, [city]);

  const onSubmit = (formData: OrderDetailsFormData) => {
    const delivery_time = timeOptions.find(({ id }) => id === formData.delivery_time)?.value;
    const delivery_date = formData.delivery_date || undefined;

    const order_details: Omit<IOrderDetails, 'id' | 'order_id'> = {
      ...formData,
      delivery_time,
      delivery_date,
    };

    if (cityData) {
      if (cityData && cityData.map_link) {
        order_details.pickup_point_url = cityData.map_link;
        order_details.pickup_point_phone = cityData.phone;
        order_details.pickup_point_contact = cityData.contact_name;
      }
    }

    dispatch(
      bonusShopActions.makeOrder({
        productId: selectedProviderId,
        product_color_id: selectedColorId,
        order_details,
      }),
    );
  };

  console.log(cityData?.map_link);

  return (
    <div className={styles.root}>
      <Header hasBackButton>
        <p className="bonus-shop-category-page__header-text">{t('bonus_shop.order_details.title')}</p>
      </Header>
      <Balance fixed={false} activePage={null} />
      {/* @ts-ignore */}
      <FormProvider {...form}>
        <form className="content themed-background-image" onSubmit={handleSubmit(onSubmit)}>
          {deliveryChannel === PRODUCT_DELIVERY_CHANNEL.DIGITAL ? (
            <OrderDetailsFieldsDigital />
          ) : (
            <OrderDetailsFieldsPhysical timeOptions={timeOptions} />
          )}
          <button>{t('bonus_shop.order_details.form_submit')}</button>
        </form>
      </FormProvider>
    </div>
  );
};

export default OrderDetails;

import { all, put, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { quizApi } from '../../api/quizApi';
import { multibrandQuizActions } from './multibrandQuizSlice';
import { Routes } from '../../const';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import {
  getMultibrandQuizIdSelector,
  getMultibrandQuizQuestionsSelector,
  getMultibrandQuizAnswersSelector,
} from './multibrandQuizSelectors';
import { checkCorrectAnswers } from '../game/gameSelectors';
import { getSelectedArticleSelector } from '../knowledgeBank/knowledgeBankSelectors';
import { MULTIBRAND_QUIZ_STEPS } from './multibrandQuizConstants';

export const goToMultibrandQuizStepAction = (stepId) =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.multibrandQuizStepList,
    stepId,
  });

export const resetMultibrandQuizStepList = () => {
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.multibrandQuizStepList,
    stepId: 1,
  });
};

function* startMultibrandQuizSaga() {
  try {
    const response = yield quizApi.startMultibrandQuiz();
    const { id, is_new } = response.data;
    if (id) {
      const { data } = yield quizApi.startQuiz(id);
      if (data) {
        yield put(multibrandQuizActions.setMultibrandQuizData({ is_new, id, questions: data.questions }));
        yield put(push(Routes.MultibrandQuizRoute));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* startDefinedMultibrandQuizSaga({ payload }) {
  try {
    const { id, is_new } = payload;
    const { data } = yield quizApi.startQuiz(id);
    yield put(goToMultibrandQuizStepAction(MULTIBRAND_QUIZ_STEPS.GAME));
    if (data) {
      yield put(multibrandQuizActions.setMultibrandQuizData({ is_new, id, questions: data.questions }));
      yield put(push(`${Routes.MultibrandQuizRoute}/${id}`));
    }
  } catch (error) {
    console.error(error);
  }
}

function* finishMultibrandQuizSaga() {
  try {
    const pack_id = yield select(getMultibrandQuizIdSelector);
    const answers = yield select(getMultibrandQuizAnswersSelector);
    const questions = yield select(getMultibrandQuizQuestionsSelector);
    const article = yield select(getSelectedArticleSelector);
    const checkedAnswers = checkCorrectAnswers(answers.me, questions);
    const answersStats = [];

    checkedAnswers.forEach((answer) => {
      answersStats.push({ answer_id: answer.answer - 1 });
    });
    const response = yield quizApi.finishMultibrandQuiz(pack_id, answersStats, article.id);
    yield put(multibrandQuizActions.setMultibrandQuizResult(response.data));
    yield put(goToMultibrandQuizStepAction(MULTIBRAND_QUIZ_STEPS.FINISH));
  } catch (error) {
    console.error(error);
  }
}

function* sagas() {
  yield all([
    takeLatest(multibrandQuizActions.startMultibrandQuiz, startMultibrandQuizSaga),
    takeLatest(multibrandQuizActions.finishMultibrandQuiz, finishMultibrandQuizSaga),
    takeLatest(multibrandQuizActions.startDefinedMultibrandQuiz, startDefinedMultibrandQuizSaga),
  ]);
}

export const multibrandSagas = sagas;

export interface IMysteryShopper {
  id: number;
  name: string;
  start_date: string;
  end_date: string;
  status: number;
  brand_id: number;
  background_image: string;
  reward_coefficient: number;
  result_data_to: string;
  target_group_id: number;
  result_page_date_to: string;
}

export interface IQuestion {
  id: number;
  type_id: number;
  question_text: string;
  question_image: string;
  show_price_disclaimer: boolean;
  price_question_answer_length: number;
  criteria_id: number;
  answers: IAnswer[];
}

export interface IAnswer {
  id?: number;
  answer_text: string;
  is_correct?: boolean;
}

export const mysteryShopperQuestionTypes = {
  multiplyChoiceQuestion: 1,
  singleChoiceQuestion: 2,
  priceQuestion: 3,
  openQuestion: 4,
  addPhotoQuestion: 5,
};

export interface ICriteria {
  id: number;
  name: string;
  percent_of_correct_answers: number;
  recommendation: string;
}

export interface IRecommendations {
  criterias: ICriteria[];
  general_score: number;
  points: number;
  sum_of_correct: number;
}

export interface IUserAnswers {
  photo: string;
  multiFields: string[];
  single: string;
  openQuestion: string;
  value: string;
}

export interface IPassedMysteryShopper {
  background_image: string;
  end_date: string;
  id: number;
  is_passed: boolean;
  name: string;
  result_page_date_to: string;
}

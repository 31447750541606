import './achievementTutorialModal.scss';

import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { useDispatch } from 'react-redux';
import { Button } from '../../common/Button/Button';
import { modalActions } from '../../containers/Modal/modalSlice';
import { stepListActions } from '../../containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../containers/StepList/stepListConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const AchievementTutorialModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const onButtonClick = () => {
    dispatch(modalActions.closeModal());
    let scrollRef = document.getElementById('scrollable');
    if (scrollRef) {
      scrollRef.style.overflow = 'auto';
    }
    localStorage.setItem('achievementsTutorialCompleted', true);
    dispatch(stepListActions.setStep({ stepListId: STEP_LIST_IDS.achievementsTutorialStepList, stepId: 5 }));
  };

  return (
    <ModalContainer modal={MODALS.achievementTutorial}>
      <div className="achievement-modal">
        <div className="achievement-modal__title">{formatMessage('Are you sure you want to finish? (tutorial)')}</div>
        <Button className="achievement-modal__btn" onClick={onButtonClick}>
          {formatMessage('Yes, exit')}
        </Button>
      </div>
    </ModalContainer>
  );
};

import PropTypes from 'prop-types';

export const AnswerComponent = (props) => {
  const { width, height, pathWidth, pathDeep, borderRadius, myAnswer, opponentAnswer, answerColor, text } = props;

  const halfPath = pathWidth / 2;
  const halfHeight = height / 2;

  const getColor = () => {
    if (answerColor === 'correct') {
      return '#09b519';
    } else if (answerColor === 'wrong') {
      return '#DE4D4D';
    } else {
      return '#fff';
    }
  };

  return (
    <>
      {<div className={`quiz-page__answer-text ${answerColor}`}>{text}</div>}
      <svg className={props.className} width={width} height={height}>
        <path
          d={`
            M${borderRadius} 0  
            Q 0 0, 0 ${borderRadius}
            V ${halfHeight - halfPath} 
            L ${pathDeep}  ${halfHeight} 
            L 0 ${halfHeight + halfPath} 
            V ${height - borderRadius} 
            Q 0 ${height}, ${borderRadius} ${height}
            H ${width - borderRadius}
            Q ${width} ${height}, ${width} ${height - borderRadius}
            V ${halfHeight + halfPath} 
            L ${width - pathDeep}  ${halfHeight}
            L ${width} ${halfHeight - halfPath} 
            V ${borderRadius}
            Q ${width} 0, ${width - borderRadius} 0
            H ${borderRadius}
            `}
        />

        <path
          fill={myAnswer ? 'transparent' : getColor()}
          stroke={myAnswer ? 'transparent' : getColor()}
          d={`M 0 ${halfHeight - halfPath}  L ${pathDeep}  ${halfHeight} L 0 ${halfHeight + halfPath}`}
        />

        <path
          fill={opponentAnswer ? 'transparent' : getColor()}
          stroke={opponentAnswer ? 'transparent' : getColor()}
          d={`M ${width} ${halfHeight - halfPath}  L ${width - pathDeep}  ${halfHeight} L ${width} ${
            halfHeight + halfPath
          }`}
        />
      </svg>
    </>
  );
};

AnswerComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  pathWidth: PropTypes.number,
  pathDeep: PropTypes.number,
  borderRadius: PropTypes.number,
  opponentAnswer: PropTypes.bool,
  myAnswer: PropTypes.bool,
};
AnswerComponent.defaultProps = {
  width: 200,
  height: 120,
  pathWidth: 20,
  pathDeep: 20,
  borderRadius: 8,
  opponentAnswer: false,
  myAnswer: false,
};

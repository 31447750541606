import { createSelector } from 'reselect';
import { APP_TYPES } from '../../const/appConstants';

export const getVerificationInfo = createSelector(
  [(state) => state.verification.verificationInfo],
  (verificationInfo) => verificationInfo,
);

export const getUnverificationInfoForBelarus = createSelector(
  [(state) => state.verification.verificationInfo],
  ({ verified }) => !verified && process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS,
);

export const getVerificationIsFetching = createSelector(
  [(state) => state.verification.isFetching],
  (isFetching) => isFetching,
);

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRefferalUser } from './selfRegistrationTypes';

const initialState = {
  isFetching: false,
  reward: 0,
  isSuccess: false,
  error: '',
};
const selfRegistrationSlice = createSlice({
  name: 'selfRegistration',
  initialState,
  reducers: {
    initGetReward: () => {},
    initUserCreate: (state, action: PayloadAction<IRefferalUser>) => {},
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
    setReward: (state, action: PayloadAction<number>) => {
      state.reward = action.payload;
    },
  },
});

export const selfRegistrationActions = selfRegistrationSlice.actions;
export const selfRegistrationReducer = selfRegistrationSlice.reducer;

import { createSelector } from 'reselect';

export const getSettingsSelector = createSelector(
  [state => state.settings.settings],
  settings => settings,
);

export const getGameModeSelector = createSelector(
  [state => state.settings.gameMode],
  gameMode => gameMode,
);

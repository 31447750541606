import classnames from 'classnames';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { analyticHelper } from '../../helpers/analyticHelper';
import { knowledgeBankActions } from '../../redux/knowledgeBank/knowledgeBankSlice';
import { specialQuizActions } from '../../redux/specialQuizes/specialQuizesSlice';
import { multibrandQuizActions } from '../../redux/multibrandQuiz/multibrandQuizSlice';
import { surveyActions } from '../../redux/survey/surveySlice';

import { brands } from './brands';
import { APP_LOCALE, APP_TYPE, APP_TYPES, KB_CATEGORY_TYPES } from '../../const/appConstants';
import { ArticleRewardHighIcon } from '../../icons/article-reward-high';
import { ArticleRewardMediumIcon } from '../../icons/article-reward-medium';
import { RewardCoefficients } from '../../enums';
import QuizCard from '../quizCard';

export const KnowledgeBankArticleCard = ({ article, showBrand, showDate, analyticReport }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const {
    name,
    is_new,
    category_id,
    brand,
    minutes_to_read,
    published_at,
    url,
    retail_price,
    special_quiz_id,
    survey_id,
    is_multibrand_quiz,
    reward_coefficients,
  } = article;

  const onChoose = () => {
    analyticReport(article);
    dispatch(knowledgeBankActions.setCurrentArticle(article));
    if (url) {
      dispatch(knowledgeBankActions.openArticle({ article }));
      analyticHelper.openArticle();
    } else if (special_quiz_id) {
      if (is_multibrand_quiz) {
        dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id: special_quiz_id, is_new }));
        return;
      }
      dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id, brand_id: brand.id }));
    } else if (survey_id) {
      dispatch(surveyActions.startSurvey({ survey_id, surveyStatus: is_new }));
      analyticHelper.startSurvey(survey_id);
    } else {
      console.log('Do something');
    }
  };

  const rewardElement = () => {
    const rewardCoefficients = _.orderBy(reward_coefficients, ['coefficient'], ['desc']).map((rewardCoefficient, i) => {
      let rewardType = RewardCoefficients.Medium;
      if (i === reward_coefficients.length - 1) rewardType = RewardCoefficients.Low;
      if (i === 0) rewardType = RewardCoefficients.High;
      return { ...rewardCoefficient, rewardType };
    });

    const currentCoefficient = _.find(rewardCoefficients, (rewardCoefficient) => {
      const now = new Date();
      const startDate = new Date(rewardCoefficient.start_date);
      const endDate = rewardCoefficient.end_date && new Date(rewardCoefficient.end_date);
      const isMatched = endDate ? now > startDate && now < endDate : now > startDate;
      return isMatched;
    });

    let iconElement = null;
    let className = '';

    switch (currentCoefficient?.rewardType) {
      case RewardCoefficients.High:
        className = 'quiz-card-block-info-block-reward-high';
        iconElement = <ArticleRewardHighIcon />;
        break;
      case RewardCoefficients.Medium:
        className = 'quiz-card-block-info-block-reward-medium';
        iconElement = <ArticleRewardMediumIcon />;
        break;
      default:
        className = 'quiz-card-block-info-block-badge';
        break;
    }

    return (
      <p className={className}>
        {iconElement}
        {moment(published_at).locale(APP_LOCALE).format('D MMMM')}
      </p>
    );
  };

  const currentBrand = brand ? brands.find((constBrand) => constBrand.id === brand.id) : null;

  const isProductsCategoryForRusRegion =
    APP_TYPE === APP_TYPES.BELARUS && category_id === KB_CATEGORY_TYPES.JTI_PRODUCTS_FOR_RU_REGION;

  if (!retail_price && isProductsCategoryForRusRegion) {
    return null;
  } else {
    return (
      <QuizCard
        title={name}
        brand={brand}
        onClick={onChoose}
        isNew={is_new}
        minutesToRead={minutes_to_read}
        publishedAt={published_at}
        showBrand={showBrand}
        showDate={showDate}
        minutesToReadMessage={formatMessage('{minutes_to_read} min read', { minutes_to_read })}
        currentBrand={currentBrand}
        rewardElement={rewardElement}
      />
    );
  }
};

import { all, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { quizApi } from '../../api/quizApi';
import { quizRatActions } from './quizRateSlice';
import { IQuizRateType } from './quizRateTypes';

function* quizRateSaga(action: PayloadAction<IQuizRateType>): Generator {
  const { quizMark, packId } = action.payload;
  try {
    yield quizApi.setQuizRate(packId, quizMark);
  } catch (err) {
    console.error('quizRateSaga: ', err);
  }
}

function* sagas() {
  yield all([takeLatest(quizRatActions.setQuizRate, quizRateSaga)]);
}

export const quizRateSagas = sagas;

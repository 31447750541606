import { all, call, takeLatest, put } from 'redux-saga/effects';
import { SERVICE_ACTION_TYPES } from './serviceConstants';
import { serviceActions } from './serviceSlice';
import { knowledgeBankActions } from '../knowledgeBank/knowledgeBankSlice';
import { COMPONENT_TYPES } from '../../const/mainScreenConsts';
import { serviceApi } from '../../api/serviceApi';
import { authApi } from '../../api/authApi';
import { Routes } from '../../const';
import { idCardActions } from '../idCard/idCardSlice';
import { mysteryShopperActions } from '../mysteryShopper/mysteryShopperSlice';
import { CONFIG_KEYS, getConfigFor } from '../../config';

function* getPositionsSaga() {
  try {
    const response = yield serviceApi.getPositions();

    if (response.data) {
      yield put(serviceActions.setPositions({ positions: response.data }));
    }
  } catch (err) {
    console.error('getPositions error', err);
  }
}

function* getLinksSaga() {
  try {
    const response = yield serviceApi.getLinks();

    if (response.data) {
      yield put(serviceActions.setLinks({ links: response.data }));
    }
  } catch (err) {
    console.error('getLinks error', err);
  }
}

function* getManagersCitiesSaga() {
  try {
    const response = yield serviceApi.getManagersCities();

    if (response.data) {
      yield put(serviceActions.setManagersCities({ managersCities: response.data }));
    }
  } catch (err) {
    console.error('getCities error', err);
  }
}

function* getManagersSaga() {
  try {
    const response = yield serviceApi.getManagers();

    if (response.data) {
      yield put(serviceActions.setManagers({ managers: response.data }));
    }
  } catch (err) {
    console.error('getManagers error', err);
  }
}

function* uploadPhotoSaga({ payload }) {
  try {
    yield put(serviceActions.setIsFetching(true));
    yield put(idCardActions.setIsFetching(true));
    const { formData, uploadPhotoCallback } = payload;
    const { data } = yield serviceApi.uploadPhoto(formData);
    uploadPhotoCallback(data.file_key);
  } catch (err) {
    console.error('uploadPhoto error', err);
  } finally {
    yield put(serviceActions.setIsFetching(false));
  }
}

function* setThemeLocalSaga({ payload }) {
  try {
    if (payload && payload.themeId) {
      yield put(serviceActions.setThemeId({ themeId: payload.themeId }));
    } else {
      const themeId = localStorage.getItem('themeId');

      if (!themeId) {
        const defaultThemeId = getConfigFor(CONFIG_KEYS.DEFAULT_APP_THEME_ID);
        localStorage.setItem('themeId', defaultThemeId);
        yield put(serviceActions.setThemeId({ themeId: defaultThemeId }));
      } else {
        yield put(serviceActions.setThemeId({ themeId }));
      }
    }
  } catch (err) {
    console.error('setThemeLocal error', err);
  }
}

function* getMonthlyLimitationSaga() {
  yield put(serviceActions.setIsFetching(true));

  try {
    const { data } = yield serviceApi.getMonthlyLimitation();

    if (
      data &&
      (window.location.pathname === Routes.ProgressRoute || window.location.pathname === Routes.ProfileRoute)
    ) {
      const KBArticles = data.activities
        .filter(
          (component) => component.type === COMPONENT_TYPES.KB_ARTICLE || component.type === COMPONENT_TYPES.KB_GAME,
        )
        .map((article) => article.payload);

      yield put(knowledgeBankActions.setArticles({ articles: KBArticles }));
    }

    yield put(mysteryShopperActions.setPassedMysteryShoppers(data.mystery_shoppers));

    yield put(serviceActions.setMonthlyLimitation({ ...data }));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(serviceActions.setIsFetching(false));
  }
}

function* getOutletsInfoSaga() {
  try {
    const { data } = yield authApi.getOutletsInfo();
    yield put(serviceActions.setOutletsInfo(data));
  } catch (error) {
    console.error('getOutletsInfoSaga error', error);
  }
}

function* getCitiesSaga() {
  try {
    const { data } = yield serviceApi.getCities();
    yield put(serviceActions.setCitiesInfo(data));
  } catch (error) {
    console.error('getCitiesSaga error', error);
  }
}

function* getLegalEntitiesSaga({ payload }) {
  try {
    const { data } = yield serviceApi.getLegalEntities(payload.city_id);
    yield put(serviceActions.setLegalEntities(data));
  } catch (error) {
    console.error('getCitiesSaga error', error);
  }
}

function* getAddressesSaga({ payload: { city_id, legal_entity_id } }) {
  try {
    const { data } = yield serviceApi.getAddresses(legal_entity_id, city_id);
    yield put(serviceActions.setAddresses(data));
  } catch (error) {
    console.error('getCitiesSaga error', error);
  }
}

function* getManagerSaga({ payload }) {
  try {
    const { data } = yield serviceApi.getManager(payload.outlet_address_id);
    yield put(serviceActions.setManager(data));
  } catch (error) {
    console.error('getCitiesSaga error', error);
  }
}

function* sagas() {
  yield all([
    call(getPositionsSaga),
    call(getManagersCitiesSaga),
    call(getManagersSaga),
    takeLatest(SERVICE_ACTION_TYPES.GET_LINKS, getLinksSaga),
    takeLatest(SERVICE_ACTION_TYPES.UPLOAD_PHOTO, uploadPhotoSaga),
    takeLatest(SERVICE_ACTION_TYPES.SET_THEME_LOCAL, setThemeLocalSaga),
    takeLatest(serviceActions.getMonthlyLimitation, getMonthlyLimitationSaga),
    takeLatest(serviceActions.getOutletsInfo, getOutletsInfoSaga),
    takeLatest(serviceActions.getCitiesInfo, getCitiesSaga),
    takeLatest(serviceActions.getLegalEntities, getLegalEntitiesSaga),
    takeLatest(serviceActions.getAddresses, getAddressesSaga),
    takeLatest(serviceActions.getManager, getManagerSaga),
  ]);
}

export const serviceSagas = sagas;

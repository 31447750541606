import { useCallback, useRef, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import './getPassportPhotoPage.scss';

import { Button } from '../../components/common/Button';
import { profileActions } from '../../redux/profile/profileSlice';
import { PASSPORT_PAGES } from '../../redux/profile/profileConstants';
import { PlusIcon } from '../../icons/plus-icon';
import { getCurrentPassportPageSelector } from '../../redux/profile/profileSelectors';
import { Routes } from '../../const';
import { s3FilePaths } from '../../const/appConstants';

export const GetPassportPhotoComponent = ({ history }) => {
  const dispatch = useDispatch();
  const currentPassportPage = useSelector(getCurrentPassportPageSelector);
  const [processingState, setProcessingState] = useState(false);
  const filesInputRef = useRef(null);

  useEffect(() => {
    setProcessingState(false);
  }, [currentPassportPage]);

  const goBack = useCallback(() => {
    if (currentPassportPage === PASSPORT_PAGES.REGISTRATION_PAGE) {
      return history.replace(Routes.PassportSettings);
    } else {
      dispatch(profileActions.setCurrentPassportPage({ passportPage: PASSPORT_PAGES.INFO_PAGE }));
      return history.replace(Routes.PassportSettings);
    }
  }, [dispatch, history, currentPassportPage]);

  const unploadPhotoFromGallery = useCallback(() => {
    const files = filesInputRef && filesInputRef.current && filesInputRef.current.files;
    const file = files[0];
    if (!file || !file.type.match(/image.*/)) return;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('file_path', s3FilePaths.passport);

    const reader = new FileReader();
    reader.onloadend = function () {
      dispatch(
        profileActions.uploadPassportPhoto({
          passportData: formData,
          passportPage: currentPassportPage,
          base64PassportPhoto: reader.result,
        }),
      );
    };
    reader.readAsDataURL(file);
    setProcessingState(true);
  }, [currentPassportPage, dispatch]);

  const renderInfoBlock = () => {
    switch (currentPassportPage) {
      case PASSPORT_PAGES.INFO_PAGE:
        return (
          <div className="get-passport-photo__mask__info-block">
            <div className="get-passport-photo__mask__info-block-title">Откройте страницу</div>
            <div className="get-passport-photo__mask__info-block-page">31</div>
          </div>
        );
      case PASSPORT_PAGES.PHOTO_PAGE:
        return (
          <div className="get-passport-photo__mask__info-block">
            <div className="get-passport-photo__mask__info-block-title">Откройте страницу</div>
            <div className="get-passport-photo__mask__info-block-page-passport">33</div>
          </div>
        );
      case PASSPORT_PAGES.REGISTRATION_PAGE:
        return (
          <div className="get-passport-photo__mask__info-block">
            <div className="get-passport-photo__mask__info-block-title">Откройте страницу</div>
            <div className="get-passport-photo__mask__info-block-page-registration">с пропиской</div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="get-passport-photo">
      {processingState && <div className="get-passport-photo__block"></div>}
      <div className="get-passport-photo__mask">
        <div className="get-passport-photo__mask__top">
          <div className="get-passport-photo__mask__close-icon-container">
            <PlusIcon className="get-passport-photo__mask__close-icon" onClick={goBack} />
          </div>
          {renderInfoBlock()}
          {processingState && (
            <div className="get-passport-photo__spinner">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>

        <div className="get-passport-photo__mask__bottom">
          <div className="get-passport-photo__mask__info-block-text">Поднесите паспорт и сделайте фото</div>
          <Button
            className="get-passport-photo__upload-button"
            onClick={(e) => {
              filesInputRef.current.click();
            }}
          >
            Добавить фото
            <input
              type="file"
              id={'challenge-page-file'}
              hidden={true}
              ref={filesInputRef}
              onChange={unploadPhotoFromGallery}
              accept="image/*"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

/* 

useEffect(() => {

    const supported = 'mediaDevices' in navigator;
    
    const player = document.getElementById('player');
    const canvas = document.getElementById('canvas');
    const captureButton = document.getElementById('capture');
    const screenshotImage = document.querySelector('img');

    const uploadPhotoCallback = () => {
      canvas.width = player.videoWidth;
      canvas.height = player.videoHeight;
      canvas.getContext('2d').drawImage(player, 0, 0);
      screenshotImage.src = canvas.toDataURL('image/webp');
      const passportPhotoFile = dataURItoBlob(screenshotImage.src);
      const formData = new FormData(document.forms[0]);
      formData.append('photo', passportPhotoFile);
      formData.append('passport', true);

      dispatch(
        profileActions.uploadPassportPhoto({
          passportData: formData,
          passportPage: currentPassportPage,
        }),
      );

      setProcessingState(true);
    };

    captureButton.addEventListener('click', uploadPhotoCallback);
    let constraints = {};
    if (isMobile()) {
      constraints = {
        video: {
          facingMode: {
            exact: 'environment',
          },
        },
      };
    } else {
      constraints = { video: true };
    }

    if (supported) {
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        player.srcObject = stream;
      });
    }

    return function unregister() {
      captureButton.removeEventListener('click', uploadPhotoCallback);
    };
  }, [supported, dispatch, currentPassportPage]);


        <div className="get-passport-photo__mask__mid">
          <div className="get-passport-photo__mask__left"></div>
          <div className="get-passport-photo__mask__frame"></div>
          <div className="get-passport-photo__mask__right"></div>
        </div>

          <Button className="get-passport-photo__photo-button" id="capture" style={{ display: 'none' }}>
            Сделать фото
          </Button>

      <video
        className="get-passport-photo__video-player"
        id="player"
        controls
        autoplay="true"
        muted="muted"
        playsInline
      ></video>
      <img alt="" style={{ display: 'none' }} />
      <canvas id="canvas" style={{ display: 'none' }} width={320} height={240}></canvas>

*/

export const GetPassportPhoto = withRouter(GetPassportPhotoComponent);

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { surveyActions } from '../../../../../redux/survey/surveySlice';
import { Routes } from '../../../../../const';
import { specialQuizActions } from '../../../../../redux/specialQuizes/specialQuizesSlice';
import { analyticHelper, MAIN_SCREEN_ACTIVITY_LABELS } from '../../../../../helpers/analyticHelper';
import { knowledgeBankActions } from '../../../../../redux/knowledgeBank/knowledgeBankSlice';
import { multibrandQuizActions } from '../../../../../redux/multibrandQuiz/multibrandQuizSlice';
import { amplitudeHelper } from '../../../../../helpers/ampitudeHelper';

import ItemComponent, { Component } from '../ItemComponent';

type Props = {
  components: Component[];
  type: string;
};

export type ComponentPayload = {
  id: number;
  survey_id: number;
  is_new: boolean;
  special_quiz_id: number;
  brand: { id: number; name: string };
  brand_id: number;
  isProgressPage: boolean;
};

export type Handlers = Record<string, (payload: ComponentPayload) => void>;

const ItemComponentContainer = ({ components }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const openArticle = (article: ComponentPayload) => {
    dispatch(knowledgeBankActions.openArticle({ article }));
  };

  const startSpecialQuiz = (special_quiz: ComponentPayload) => {
    dispatch(
      specialQuizActions.startSpecialQuiz({
        special_quiz_id: special_quiz.id,
        brand_id: special_quiz.brand_id,
      }),
    );
  };

  const handleSurveyOrKBArticleClick = (payload: ComponentPayload) => {
    const { survey_id, is_new, special_quiz_id, brand, isProgressPage } = payload;

    const progressPageActivityState = +is_new;

    if (survey_id) {
      if (is_new) {
        dispatch(surveyActions.startSurvey({ survey_id, surveyStatus: is_new }));
      } else {
        history.push(Routes.noBonusSurveyRoute);
      }
    } else if (special_quiz_id) {
      dispatch(specialQuizActions.startSpecialQuiz({ special_quiz_id, brand_id: brand.id }));
    } else {
      openArticle(payload);
    }
    isProgressPage
      ? analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.KB_ARTICLE, progressPageActivityState)
      : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_ARTICLE);
  };

  const handleSpecialQuiz = (payload: ComponentPayload) => {
    const { is_new, isProgressPage } = payload;

    const progressPageActivityState = +is_new;

    startSpecialQuiz(payload);
    isProgressPage
      ? analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.SPECIAL_QUIZ, progressPageActivityState)
      : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.SPECIAL_QUIZ);
  };

  const handleKBGame = (payload: ComponentPayload) => {
    const { is_new, isProgressPage } = payload;

    const progressPageActivityState = +is_new;

    openArticle(payload);
    if (isProgressPage) {
      analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.KB_GAME, progressPageActivityState);
    } else {
      amplitudeHelper.mainItemKBGame();
      analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.KB_GAME);
    }
  };

  const handleMultibrandQuiz = (payload: ComponentPayload) => {
    const { is_new, id, isProgressPage } = payload;

    const progressPageActivityState = +is_new;

    dispatch(multibrandQuizActions.startDefinedMultibrandQuiz({ id, is_new }));
    isProgressPage
      ? analyticHelper.progressPageActivity(MAIN_SCREEN_ACTIVITY_LABELS.MB_QUIZ, progressPageActivityState)
      : analyticHelper.onMainScreenItemClick(MAIN_SCREEN_ACTIVITY_LABELS.MB_QUIZ);
  };

  const handlers: Handlers = {
    SURVEY: handleSurveyOrKBArticleClick,
    KB_ARTICLE: handleSurveyOrKBArticleClick,
    SPECIAL_QUIZ: handleSpecialQuiz,
    KB_GAME: handleKBGame,
    MULTIBRAND_QUIZ: handleMultibrandQuiz,
  };

  return (
    <div style={{ overflow: 'hidden' }}>
      <ItemComponent components={components} handlers={handlers} />
    </div>
  );
};

export default ItemComponentContainer;

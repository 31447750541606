import { useDispatch } from 'react-redux';

import { analyticHelper } from '../../helpers/analyticHelper';
import { bannersActions } from '../../redux/banners/bannersSlice';
import { IBanner } from '../../redux/banners/bannersTypes';
import './bannerCard.scss';

interface PropsType {
  banner: IBanner;
}

export const BannerCard = ({ banner }: PropsType) => {
  const dispatch = useDispatch();
  const { url } = banner;

  const handleClick = () => {
    if (url) {
      dispatch(bannersActions.openBannerPage({ banner }));
      analyticHelper.openBannerPage();
    }
  };

  return (
    <div className="banner-block" onClick={handleClick}>
      <img src={banner.image} />
    </div>
  );
};

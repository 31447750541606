import './LoadingPage.scss';

import { Header } from '../Header';
import { Spinner } from './Spinner';

export const LoadingPage = (props) => {
  const { header = true } = props;

  return (
    <div className="loading-page">
      {header && <Header />}
      <div className="loading-page__content-area">
        <Spinner style={{ paddingBottom: header ? 68 : 0, width: 100 }} />
      </div>
    </div>
  );
};

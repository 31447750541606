import { Field } from 'redux-form';
import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';

import styles from './CommonRadioButton.module.scss';

export const CommonRadioButton = (props) => {
  const {
    options,
    input: { onChange, value },
    meta: { touched, error },
    sectionId,
    customClass,
  } = props;
  const { formatMessage } = useInternationalization();

  return (
    <div>
      <div className={styles.radioBlock}>
        {options.map((option, index) => (
          <label
            key={option.answer + index}
            className={classnames(
              styles.radioContainer,
              value.answer === option.answer && customClass,
              touched && error && styles.invalidInput,
            )}
          >
            <input
              type="radio"
              checked={value.answer === option.answer}
              onChange={() => onChange({ answer: option.answer, correct: option.correct, section_id: sectionId })}
            />
            <span className={styles.radioCheckmark} />
            <span className={styles.radioValue}>{option.answer}</span>
          </label>
        ))}
      </div>
      {touched && error && <div className={styles.errorMessage}>{formatMessage(error)}</div>}
    </div>
  );
};

export const RadioButtonInput = (props) => {
  return <Field component={CommonRadioButton} {...props} />;
};

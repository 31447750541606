export const BonusShopBlackIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_122_1122)">
        <rect width="40" height="40" rx="8" fill="#ECECEC" />
        <path
          d="M29.2 13.6115H26.693C26.8195 13.2541 26.9 12.8622 26.9 12.4586C26.9 10.5449 25.359 9 23.45 9C22.2425 9 21.196 9.62256 20.575 10.5564L20 11.3288L19.425 10.5449C18.804 9.62256 17.7575 9 16.55 9C14.641 9 13.1 10.5449 13.1 12.4586C13.1 12.8622 13.1805 13.2541 13.307 13.6115H10.8C9.5235 13.6115 8.5115 14.6376 8.5115 15.9173L8.5 28.599C8.5 29.8787 9.5235 30.9048 10.8 30.9048H29.2C30.4765 30.9048 31.5 29.8787 31.5 28.599V15.9173C31.5 14.6376 30.4765 13.6115 29.2 13.6115ZM23.45 11.3058C24.0825 11.3058 24.6 11.8246 24.6 12.4586C24.6 13.0927 24.0825 13.6115 23.45 13.6115C22.8175 13.6115 22.3 13.0927 22.3 12.4586C22.3 11.8246 22.8175 11.3058 23.45 11.3058ZM16.55 11.3058C17.1825 11.3058 17.7 11.8246 17.7 12.4586C17.7 13.0927 17.1825 13.6115 16.55 13.6115C15.9175 13.6115 15.4 13.0927 15.4 12.4586C15.4 11.8246 15.9175 11.3058 16.55 11.3058ZM29.2 22.8346H10.8V15.9173H16.642L14.25 19.1799L16.113 20.5288L20 15.2256L23.887 20.5288L25.75 19.1799L23.358 15.9173H29.2V22.8346Z"
          fill="#252525"
        />
      </g>
      <defs>
        <clipPath id="clip0_122_1122">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

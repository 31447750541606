import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { username, lengthMinMax } from '../../../helpers/formValidations';
import { Input } from '../../../components/common/Input';
import { Routes } from '../../../const';
import { InfoIcon } from '../../../icons/info-icon';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { SetThemeComponent } from './setThemeComponent';
import { ProfilePhoto } from '../../profile/ProfilePhoto/ProfilePhoto';

import '../profileSettings.scss';

const UserInfo = ({ handleSubmit, history, initialValues, show_passport_menu }) => {
  const { formatMessage } = useInternationalization();

  return (
    <form onSubmit={handleSubmit} className="edit-profile-form">
      <div className="edit-profile-form__photo">
        <ProfilePhoto />
      </div>
      <div className="profile-settings-page__content-fields">
        <p className={classnames('profile-settings-page__content-color-themes-caption', 'your-data-caption')}>
          {formatMessage('Your data:')}
        </p>
        <>
          <Input
            name="username"
            customClass="profile-settings-page__input"
            validate={[username, lengthMinMax(2, 15, formatMessage)]}
          />
          <Input name="traffic_code" type="number" customClass="profile-settings-page__input" disabled />
          <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
            {formatMessage('JTI representative:')}
          </p>
          <Input
            name="manager_name"
            customClass="profile-settings-page__input"
            id="managers"
            disabled
            InputButton={
              <InfoIcon
                className="profile-settings-page__info-icon"
                onClick={() => history.push(Routes.ManagerPageRoute)}
              />
            }
          />
          <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
            {formatMessage('Outlet data:')}
          </p>
          <Input name="city" customClass="profile-settings-page__input" disabled />
          <Input name="legal_entity" customClass="profile-settings-page__input" disabled />
          <Input name="address" customClass="profile-settings-page__input" disabled />
          {show_passport_menu && (
            <>
              <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
                {formatMessage('Passport data:')}
              </p>
              <div onClick={() => history.push(Routes.PassportSettings)}>
                <Input
                  className="profile-settings-page__input"
                  name="passport"
                  disabled
                  InputButton={<ArrowIconRounded className="profile-settings-page__arrow-icon" />}
                />
              </div>
            </>
          )}
        </>
      </div>
      <SetThemeComponent initialValues={initialValues} />
    </form>
  );
};

const form = reduxForm({
  form: 'userInfoForm',
  initialValues: {},
  enableReinitialize: true,
  destroyOnUnmount: false,
})(UserInfo);

export const UserInfoForm = withRouter(form);

import { useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { flatten, isString } from 'lodash';

import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getAttachedInteractiveArticleSelector } from '../../../redux/knowledgeBank/knowledgeBankSelectors';

import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { Button } from '../../../components/common/Button';
import { CheckboxTypeQuestion } from './questionTypes/CheckboxQuestion';
import { RadioButtonTypeQuestion } from './questionTypes/RadionButtonQuestion';
import { InputTypeQuestion } from './questionTypes/InputQuestion';
import { SelectTypeQuestion } from './questionTypes/SelectQuestion';

import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { getUserIdSelector } from '../../../redux/auth/authSelectors';

import styles from './InteractiveArticlePage.module.scss';

export const InteractiveArticle = ({ handleSubmit, dispatch, formValues }) => {
  const { formatMessage } = useInternationalization();

  const { id, background_color, sections } = useSelector(getAttachedInteractiveArticleSelector);
  const userId = useSelector(getUserIdSelector);
  const contentRef = useRef(null);

  const [isDisabled, setIsDisabled] = useState(false);

  const isContentHeightLessThanWindow = () => {
    const contentHeight = contentRef.current?.scrollHeight;
    const displayHeight = window.innerHeight;

    return contentHeight <= displayHeight;
  };

  const questionTypes = {
    manual: InputTypeQuestion,
    checkbox: CheckboxTypeQuestion,
    dropdown: SelectTypeQuestion,
    radiobutton: RadioButtonTypeQuestion,
  };

  const detectQuestionTypes = (questions) => {
    return questions.reduce((questions, question) => {
      if (!questions.includes(question.type.name)) {
        questions.push(question.type.name);
      }
      return questions;
    }, []);
  };

  const isManual = detectQuestionTypes(sections).includes('manual');

  const prepareUserAnswers = (result) => {
    const userAnswersButDropdown = flatten(result.filter((answer) => typeof answer !== 'string'));

    const dropdownUserAnswers = result.reduce((answers, answer) => {
      if (isString(answer)) {
        answers.push(JSON.parse(answer));
      }
      return answers;
    }, []);

    return [...userAnswersButDropdown, ...dropdownUserAnswers];
  };

  const isInteractivePassed = (result) => {
    const userCorrectAnswers = result.filter((answer) => answer.correct);

    const allAnswers = sections.map((section) => section.answers);
    const allCorrectAnswers = flatten(allAnswers).filter((answer) => answer.correct);

    const hasIncorrectAnswers = result.length > userCorrectAnswers.length;
    const hasUserFoundAllCorrectAnswers = allCorrectAnswers.length === userCorrectAnswers.length;

    return !hasIncorrectAnswers && hasUserFoundAllCorrectAnswers;
  };

  const handleButtonClick = () => {
    setIsDisabled(true);

    const userAnswers = flatten(Object.values(formValues.values));

    if (isManual) {
      dispatch(
        knowledgeBankActions.passInteractiveArticle({
          answersBySections: [
            {
              interactive_article_id: id,
              user_id: userId,
              section_id: sections[0].id,
              correct: false,
              moderation: false,
              user_answers: userAnswers,
            },
          ],
        }),
      );

      dispatch(
        stepListActions.nextStep({
          stepListId: STEP_LIST_IDS.interactiveArticleStepList,
          props: { manual: true, correct: false, backgroundColor: background_color },
        }),
      );
    } else {
      const preparedUserAnswers = prepareUserAnswers(userAnswers);
      const isCorrect = !isManual && isInteractivePassed(preparedUserAnswers);

      const isSectionCorrect = (id) => {
        const sectionsCorrectAnswers = sections.map((section) => ({
          id: section.id,
          answer: section.answers.filter((answer) => answer.correct),
        }));
        const currentSectionCorrectAnswers = sectionsCorrectAnswers.find((section) => section.id === id).answer;

        const userSectionAnswer = preparedUserAnswers.filter((answer) => answer.section_id === id);
        return userSectionAnswer.length === currentSectionCorrectAnswers.length;
      };

      const answersBySections = Object.values(
        preparedUserAnswers.reduce((sections, answer) => {
          const group = sections[answer.section_id] || {
            interactive_article_id: id,
            user_id: userId,
            section_id: answer.section_id,
            correct: isSectionCorrect(answer.section_id),
            moderation: true,
            user_answers: [],
          };

          group.user_answers.push(answer.answer);
          sections[answer.section_id] = group;

          sections[answer.section_id].correct = !!sections[answer.section_id]?.correct && !!answer.correct;

          return sections;
        }, {}),
      );
      dispatch(
        knowledgeBankActions.passInteractiveArticle({
          answersBySections,
          isCorrect,
          id,
          background_color,
        }),
      );
    }
    setIsDisabled(false);
  };

  return (
    <div
      ref={contentRef}
      className={styles.interactiveArticlePageContainer}
      style={{ background: `${background_color}`, height: isContentHeightLessThanWindow() || isManual ? '100%' : '' }}
    >
      <BackArrowButton withoutTheme customClass={styles.backButton} />
      <form className={styles.formContainer} onSubmit={handleSubmit(handleButtonClick)}>
        {sections.map((section, index) => {
          const Component = questionTypes[section.type.name];

          return (
            <div key={section.type.name + index}>
              <Component name={section.type.name} {...section} />
              {sections.length > 1 && <span className={styles.delimiter} />}
            </div>
          );
        })}
        <div className={styles.btnContainer} style={{ paddingBottom: isIPhoneWithHomeBar() ? '34px' : '' }}>
          <Button disabled={isDisabled} className={styles.nextButton}>
            {formatMessage('interactive_article.next')}
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  formValues: state.form.interactive,
});

export const InteractiveArticleForm = reduxForm({ form: 'interactive' })(InteractiveArticle);

export const InteractiveArticlePage = connect(mapStateToProps)(InteractiveArticleForm);

import axios from 'axios';

import { API_URL } from './baseApi';
export const surveyApi = {
  startSurvey: async pack_id => {
    return await axios.post(`${API_URL}/v1/surveys/start`, {
      pack_id,
    });
  },
  finishSurvey: async (pack_id, multiple_answers) => {
    return await axios.post(`${API_URL}/v10/surveys/finish`, {
      pack_id,
      multiple_answers,
    });
  },
};

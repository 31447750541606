import { createSelector } from 'reselect';

export const currentStepSelector = createSelector(
  [(state) => state.steps, (state, stepListId) => stepListId],
  (steps, stepListId) => {
    if (steps[stepListId]) {
      return steps[stepListId].currentStep;
    }
    return 1;
  },
);

import { createSelector } from 'reselect';
import { QUIZ_WITHOUT_BRAND_IDS } from '../../const/brandsConstants';

export const getBrandIdFromRouteSelector = createSelector([(state) => state.router.location], (location) => {
  let brandId = location.pathname.split('/')[2];
  const parsed = parseInt(brandId);

  if (typeof parsed === 'number') return parsed;
  return null;
});

export const getBrandsInfoSelector = createSelector([(state) => state.brands.brandsInfo], (brandsInfo) => brandsInfo);

export const getBrandInfoSelector = createSelector(
  [getBrandsInfoSelector, (state, brand_id) => brand_id],
  (brandsInfo, brand_id) => {
    return brandsInfo.find((i) => i.id === Number(brand_id));
  },
);

export const getBonusGamesAmountSelector = createSelector(
  [(state, brand_id) => getBrandInfoSelector(state, brand_id)],
  (brandInfo) => {
    return brandInfo.count || 0;
  },
);

export const getQuizOfTheDayDataSelector = createSelector([(state) => state.brands.brandsInfo], (brands) => {
  if (brands) {
    return brands.find((i) => i.id === QUIZ_WITHOUT_BRAND_IDS.QUESTION_OF_THE_DAY_ID);
  } else return null;
});

export const getIsFetching = createSelector([(state) => state.brands.isFetching], (isFetching) => isFetching);

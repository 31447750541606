import { all, put, takeLatest } from 'redux-saga/effects';

import { mainPageActions } from './mainPageSlice';
import { mainScreenApi } from '../../api/mainScreenApi';
import { COMPONENT_TYPES } from '../../const/mainScreenConsts';
import { Routes } from '../../const';
import { knowledgeBankActions } from '../knowledgeBank/knowledgeBankSlice';

function* mainPageSaga() {
  yield put(mainPageActions.setIsFetching(true));
  try {
    const response = yield mainScreenApi.getMainScreenApi();
    if (response.data) {
      const mainScreenData = response.data;
      if (mainScreenData && window.location.pathname === Routes.MainRoute) {
        const allComponents = mainScreenData.screen.sections.reduce(
          (arr, section) => arr.concat(section.components),
          [],
        );
        const KBArticles = allComponents
          .filter(
            (component) => component.type === COMPONENT_TYPES.KB_ARTICLE || component.type === COMPONENT_TYPES.KB_GAME,
          )
          .map((article) => article.payload);

        yield put(knowledgeBankActions.setArticles({ articles: KBArticles }));
      }
      yield put(mainPageActions.setMainScreenData({ mainScreenData }));
    }
  } catch (error) {
    yield put(mainPageActions.onRequestFailure({ error }));
  } finally {
    yield put(mainPageActions.setIsFetching(false));
  }
}

function* sagas() {
  yield all([takeLatest(mainPageActions.getMainScreenData, mainPageSaga)]);
}

export const mainPageSagas = sagas;

import './ContentSpinner.scss';
import { PreloaderIcon } from '../../../icons/preloader-icon';

export const ContentSpinner = (props) => {
  const { height, width = 75, style } = props;

  return (
    <div className="lottie-spinner">
      <div className="lottie-spinner__content" style={style}>
        <PreloaderIcon width={width} height={height} />
      </div>
    </div>
  );
};

import './userComponent.scss';

export const ProgressBar = (props) => {
  const { value, goal, firstHalfColor, secondHalfColor, withThumb } = props;
  const percent = (value / goal) * 100;
  return (
    <div className="progress-bar__wrapper">
      <div
        className={'progress-bar__lower-range-trace'}
        style={{
          width: `${percent}%`,
          backgroundColor: percent < 50 ? firstHalfColor : secondHalfColor,
        }}
      ></div>
      {withThumb && (
        <span
          className="progress-bar__thumb"
          style={{
            backgroundColor: percent < 50 ? firstHalfColor : secondHalfColor,
          }}
        ></span>
      )}
    </div>
  );
};

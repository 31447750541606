export const noAnswerIcon = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" r="7.5" stroke="#B2B2B2" />
      <path
        d="M4.74715 8.75C5.1749 8.75 5.5 8.42442 5.5 8C5.5 7.57558 5.1749 7.25 4.74715 7.25C4.3308 7.25 4 7.57558 4 8C4 8.42442 4.3308 8.75 4.74715 8.75Z"
        fill="#B2B2B2"
        stroke="#B2B2B2"
        strokeWidth="0.1"
      />
      <path
        d="M8.03133 8.75C8.45908 8.75 8.78418 8.42442 8.78418 8C8.78418 7.57558 8.45908 7.25 8.03133 7.25C7.61498 7.25 7.28418 7.57558 7.28418 8C7.28418 8.42442 7.61498 8.75 8.03133 8.75Z"
        fill="#B2B2B2"
        stroke="#B2B2B2"
        strokeWidth="0.1"
      />
      <path
        d="M11.3155 8.75C11.7433 8.75 12.0684 8.42442 12.0684 8C12.0684 7.57558 11.7433 7.25 11.3155 7.25C10.8992 7.25 10.5684 7.57558 10.5684 8C10.5684 8.42442 10.8992 8.75 11.3155 8.75Z"
        fill="#B2B2B2"
        stroke="#B2B2B2"
        strokeWidth="0.1"
      />
    </svg>
  );
};

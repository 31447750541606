import { mysteryShopperColors } from '../redux/mysteryShopper/mysteryShopperConstants';

export const WarningIcon = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 10 10"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 9.375C7.41625 9.375 9.375 7.41625 9.375 5C9.375 2.58375 7.41625 0.625 5 0.625C2.58375 0.625 0.625 2.58375 0.625 5C0.625 7.41625 2.58375 9.375 5 9.375Z"
        stroke={props.fillcolor ? props.fillcolor : mysteryShopperColors.red}
      />
      <path
        d="M4.2915 2.21558L4.38672 5.7605H5.3645L5.46338 2.21558H4.2915ZM4.87744 7.60986C5.29126 7.60986 5.54395 7.37183 5.54395 6.9873C5.54395 6.59912 5.29126 6.36108 4.87744 6.36108C4.46729 6.36108 4.21094 6.59912 4.21094 6.9873C4.21094 7.37183 4.46729 7.60986 4.87744 7.60986Z"
        fill={props.fillcolor ? props.fillcolor : mysteryShopperColors.red}
      />
    </svg>
  );
};

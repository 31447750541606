import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useTimeLeft } from '../../../hooks/useTimeLeft';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Routes } from '../../../const';
import { Button } from '../../../components/common/Button';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { selectEndDate, selectMysteryShopperId } from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import shopper from '../../../images/shopperIcon.png';
import getResultImage from '../../../images/get-result-mystery-shopper.png';

import styles from './GetResult.module.scss';

export const GetResult = () => {
  const { formatMessage } = useInternationalization();
  const history = useHistory();
  const mysteryShopperId = useSelector(selectMysteryShopperId);

  const endDate = useSelector(selectEndDate);

  const time = useTimeLeft(endDate);

  const onBackArrowClick = () => {
    history.push(Routes.MainRoute);
  };

  const handleClick = () => {
    history.push(`${Routes.MysteryShopperRecommendations}/${mysteryShopperId}`);
  };

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor="white"
      />
      <div className={styles.mainWrapper}>
        <span className={styles.title}>
          {formatMessage('Mystery Shopper will be finished in')} {time}
        </span>
        <div className={styles.resultImageWrapper}>
          <img className={styles.resultImage} src={getResultImage} alt="Get result" />
        </div>
      </div>
      <div className={styles.iconWrapper}>
        <img className={styles.icon} src={shopper} alt="shopper" />
      </div>
      <Button onClick={handleClick} withoutTheme customClass={styles.button}>
        {formatMessage('Continue')}
      </Button>
    </div>
  );
};

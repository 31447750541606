import { Button } from '../Button';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';

import './BackArrowButton.scss';

export const BackArrowButton = ({
  customClass,
  withoutTheme,
  onHistoryReplaceCallback, // dispatch some methods and work with history replace
  onBack = () => {}, // dispatch some methods
  buttonColor,
  ...otherProps
}) => {
  const dispatch = useDispatch();

  return (
    <Button
      type="button"
      withoutTheme
      {...otherProps}
      customClass={customClass ? customClass : null}
      onClick={() => {
        if (onHistoryReplaceCallback) {
          onHistoryReplaceCallback();
        } else {
          dispatch(goBack());
          onBack();
        }
      }}
    >
      <ArrowIconRounded className="header-arrow" style={{ fill: buttonColor }} />
    </Button>
  );
};

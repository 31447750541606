import { createSelector } from 'reselect';

import { checkCorrectAnswers } from '../game/gameSelectors';

export const getSingleModeAnswersSelector = createSelector([(state) => state.singleMode.answers], (answers) => answers);

export const isSingleModeStartedSelector = createSelector(
  [(state) => state.singleMode.isStarted],
  (isStarted) => isStarted,
);

export const getSingleModeRoundTimeSelector = createSelector([(state) => state.singleMode.gameData], (gameData) => {
  if (gameData && gameData.round_time) {
    return gameData.round_time;
  } else {
    return null;
  }
});

export const getSingleModeQuestions = createSelector([(state) => state.singleMode.gameData], (gameData) => {
  if (gameData && gameData.pack && gameData.pack.questions) {
    return gameData.pack.questions;
  } else return null;
});

export const getSingleModeCurrentStageSelector = createSelector(
  [(state) => state.singleMode.currentStage],
  (currentStage) => currentStage,
);

export const getSingleModeQuestionsByStage = createSelector(
  [getSingleModeQuestions, getSingleModeCurrentStageSelector],
  (questions, currentStage) => {
    if (questions && questions[currentStage]) {
      return questions[currentStage];
    } else {
      return [];
    }
  },
);

// export const getSingleModeCoinsSelector = createSelector([(state) => state.singleMode.gameData], (gameData) => {
//   if (gameData && gameData.pack) {
//     return {
//       first_stage_coins: gameData.pack.first_stage_coins,
//       second_stage_coins: gameData.pack.second_stage_coins,
//       third_stage_coins: gameData.pack.third_stage_coins,
//       all_stage_bonus: gameData.pack.all_stage_bonus,
//     };
//   }
// });

export const getSingleModeCoinsByStageSelector = createSelector(
  [(state) => state.singleMode.gameData, getSingleModeCurrentStageSelector],
  (gameData, stage) => {
    if (gameData && gameData.pack) {
      switch (stage) {
        case 0: {
          return { points: gameData.pack.first_stage_coins * gameData.reward_coefficient };
        }
        case 1: {
          return { points: gameData.pack.second_stage_coins * gameData.reward_coefficient };
        }
        case 2: {
          return {
            points: gameData.pack.third_stage_coins * gameData.reward_coefficient,
            extraPoints: gameData.pack.all_stage_bonus * gameData.reward_coefficient,
          };
        }
        default:
          return null;
      }
    }
  },
);

export const getSingleModePackIdSelector = createSelector([(state) => state.singleMode.gameData], (gameData) => {
  if (gameData && gameData.pack) {
    return gameData.pack.id;
  }
});

export const isStageSuccessSelector = createSelector(
  [(state) => state.singleMode.answers, getSingleModeQuestions, getSingleModeCurrentStageSelector],
  (answers, questions, stage) => {
    const currentQuestions = questions[stage];

    if (currentQuestions.length === answers.me.length) {
      const checkedAnswers = checkCorrectAnswers(answers.me, currentQuestions);

      return !checkedAnswers.find((i) => i.isCorrect !== true);
    }

    return false;
  },
);

export const getFinishedStagesCount = createSelector([(state) => state.singleMode.finishedStages], (stages) => stages);

export const getCoinsForGameSelector = createSelector(
  [(state) => state.singleMode.coinsForGame],
  (coinsForGame) => coinsForGame,
);

export const getSingleModeTotalCoinsSelector = createSelector(
  [getFinishedStagesCount, (state) => state.singleMode.gameData],
  (finishedStages, gameData) => {
    if (gameData && gameData.pack) {
      switch (finishedStages) {
        case 0:
          return 0;
        case 1:
          return gameData.pack.first_stage_coins * gameData.reward_coefficient;
        case 2:
          return (gameData.pack.first_stage_coins + gameData.pack.second_stage_coins) * gameData.reward_coefficient;
        case 3:
          return (
            (gameData.pack.first_stage_coins + gameData.pack.second_stage_coins + gameData.pack.third_stage_coins) *
            gameData.reward_coefficient
          );
        default:
          return 0;
      }
    }
  },
);

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goBack } from 'connected-react-router';

import { ScrollBlock } from '../../components/common/ScrollBlock';
import { BackArrowButton } from '../../components/common/BackArrowButton/BackArrowButton';
import { PostMessageEvents } from '../../enums';
import { selectCurrentBanner } from '../../redux/banners/bannersSelectors';
import './bannerPage.scss';

export const BannerPage = () => {
  const dispatch = useDispatch();
  const banner = useSelector(selectCurrentBanner);

  const bannerUrl = banner?.url;
  const isImage = bannerUrl?.match(/\.(gif|jpg|jpeg|tiff|png)$/i);

  useEffect(() => {
    const handleMessageEvent = (event: MessageEvent) => {
      if (event.data === PostMessageEvents.ArticleCompleted) {
        dispatch(goBack());
      } else if (event.data === PostMessageEvents.CloseIframe) {
        dispatch(goBack());
      }
    };

    window.addEventListener('message', handleMessageEvent);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    };
  });

  return (
    <ScrollBlock fromTop>
      <BackArrowButton
        withoutTheme
        customClass={'back-arrow-button'}
        onHistoryReplaceCallback={null}
        buttonColor={null}
      />
      {isImage ? (
        <img src={bannerUrl} alt="Banner" className="banner-page__content" />
      ) : (
        <iframe
          title="Banner"
          className="banner-page__content"
          frameBorder="no"
          height={window.innerHeight}
          width={window.innerWidth}
          src={bannerUrl}
        />
      )}
    </ScrollBlock>
  );
};

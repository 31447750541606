import { interactiveArticleRequiredCheckbox } from '../../../../helpers/formValidations';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { CheckboxInput } from '../../../../components/common/Checkbox';

import styles from '../InteractiveArticlePage.module.scss';

export const CheckboxTypeQuestion = ({ name, id, answers, title }) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{formatMessage('interactive_article.checkbox_question.title')}</div>
      <CheckboxInput name={name + id} options={answers} sectionId={id} validate={interactiveArticleRequiredCheckbox} />
    </div>
  );
};

import { useEffect } from 'react';
import isEmpty from 'is-empty-typed';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ChatRoutes } from '../../../../const';
import { Button, LoaderContainer } from '../../../../components/common';
import { ButtonType } from '../../../../components/common/CommonButton/types';

import { chatActions, supportActions, supportSelectors } from '../../../../redux/chat';
import { NavigationWrapper, Ticket } from '../../components';
import styles from './Suppport.module.scss';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

export const Support = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const history = useHistory();

  const isFetching = useSelector(supportSelectors.selectIsFetching);
  const tickets = useSelector(supportSelectors.selectAll);

  const createTicket = () => {
    dispatch(supportActions.createTicket());
  };

  const openTicketChat = (ticketId: number) => {
    history.push(`${ChatRoutes.Support}/${ticketId}`);
  };

  useEffect(() => {
    dispatch(supportActions.fetchTickets());

    return () => {
      dispatch(supportActions.reset());
    };
  }, []);
  return (
    <NavigationWrapper>
      <div className={styles.support}>
        <LoaderContainer isLoading={isFetching} className={styles.loaderContainer}>
          <>
            <Button type={ButtonType.CreateTicket} className={styles.createTicketButton} onClick={createTicket}>
              {formatMessage('chat.solve_the_problem')}
            </Button>

            <div className={styles.tickets}>
              {tickets.map((ticket) => (
                <Ticket key={ticket.id} ticket={ticket} onClick={openTicketChat} />
              ))}
            </div>
          </>
        </LoaderContainer>
      </div>
    </NavigationWrapper>
  );
};

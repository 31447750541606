import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModerationState, IUser } from './moderationTypes';

const initialState: IModerationState = {
  isFetching: false,
  count: 0,
  users: [],
};

const moderationSlice = createSlice({
  name: 'moderation',
  initialState,
  reducers: {
    setIsFetching(state: IModerationState, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    getUsersCount() {},
    setUsersCount(state, action: PayloadAction<number>) {
      state.count = action.payload;
    },
    getUsersForModeration() {},
    setUsersForModeration(state, action: PayloadAction<IUser[]>) {
      state.users = action.payload;
    },
    moderateUsers(state, action: PayloadAction<number[]>) {},
    deleteUsers(state, action: PayloadAction<number[]>) {},
  },
});

export const moderationActions = moderationSlice.actions;
export const moderationReducer = moderationSlice.reducer;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import GoalDetails from '../GoalDetails';
import { IGoal, IGoalTracker, GoalStatus } from '../GoalTrackerTypes';
import CalendarIcon from '../../../images/calendar.svg';
import { formatDate } from '../../../helpers';
import GoalTrackerProgressBar from '../GoalTrackerProgressBar';
import RewardedGoal_1 from '../../../images/RewardedGoal_1.jpg';
import RewardedGoal_2 from '../../../images/RewardedGoal_2.jpg';
import { getLastRewardedGoal, getRewardedModalContent } from '../../../helpers/goalsHelper';
import { modalActions } from '../../containers/Modal/modalSlice';

import { goalTrackerActions } from '../../../redux/goalTracker/goalTrackerSlice';

import styles from './GoalTrackerInfo.module.scss';

interface IGoalTrackerInfo {
  goalTracker: IGoalTracker;
}

const GoalTrackerInfo = ({ goalTracker }: IGoalTrackerInfo) => {
  const { formatMessage } = useInternationalization();
  const { goals, progress, id } = goalTracker;
  const dispatch = useDispatch();
  const lastRewardedGoal = getLastRewardedGoal(goals);
  const rewardModalContent = getRewardedModalContent(lastRewardedGoal as IGoal);

  useEffect(() => {
    if (lastRewardedGoal?.status === GoalStatus.REWARDED) {
      dispatch(
        modalActions.openPromptWithTwoButtonsModal({
          descriptionImage: !lastRewardedGoal?.nextGoalReward ? RewardedGoal_1 : RewardedGoal_2,
          description: formatMessage(rewardModalContent?.description, {
            coins: lastRewardedGoal?.reward,
            nextGoalCoins: lastRewardedGoal?.nextGoalReward,
          }),
          modalHeader: formatMessage(rewardModalContent?.modalHeader),
          btnText: formatMessage(rewardModalContent?.btnText),
          onButtonClick: () => {
            dispatch(goalTrackerActions.confirmReward(id));
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  }, [lastRewardedGoal]);

  return (
    <div className={styles.goalInfoContainer}>
      <div className={styles.goalTrackerTitle}>{formatMessage('goal_tracker.title')}</div>
      <div className={styles.goalInformation}>
        <div className={styles.goalsDescriptionContainer}>
          {goals?.map((goal: IGoal, index: number) => (
            <GoalDetails key={goal.id} goal={goal} number={index + 1} />
          ))}
        </div>

        <div className={styles.goalDate}>
          <img src={CalendarIcon} />
          <div className={styles.test}>
            <div className={styles.dateTitle}>{formatMessage('goal_tracker.due_date')}</div>
            <div>{formatDate(new Date(goalTracker.date_end), 'dd.MM.yyyy')}</div>
          </div>
        </div>
      </div>

      <div className={styles.progressTitle}>
        <span className={styles.purchasingTitle}>{formatMessage('goal_tracker.purchasing_progress')}</span>
        <b>{`  ${progress} ${formatMessage('goal_tracker.packs')} `}</b>
        <span className={styles.lastUpdate}>
          {`(${formatMessage('goal_tracker.last_update')}`}
          <b>{` ${formatDate(new Date(goalTracker.created_at), 'dd.MM.yyyy')}`}</b>
          {')'}
        </span>
      </div>

      <GoalTrackerProgressBar goals={goals} progress={progress} />
    </div>
  );
};
export default GoalTrackerInfo;

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getVerificationInfo, getVerificationIsFetching } from '../../redux/verification/verificationSelectors';

import { SwipeablePages } from '../swipeablePages';
import { ShopIsNotAvailable } from './shopIsNotAvailable';
import { APP_TYPES } from '../../const/appConstants.js';
import { LoadingPage } from '../../components/common/Spinner/LoadingPage';

import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';

import { analyticHelper } from '../../helpers/analyticHelper';
import { getIsFetching as getIsFetchingBonusShop } from '../../redux/bonusShop/bonusShopSelectors';

const ShopMiddlewarePage = () => {
  const isFetchingVerified = useSelector(getVerificationIsFetching);
  const isFetchingBonusShop = useSelector(getIsFetchingBonusShop);
  const { verified } = useSelector(getVerificationInfo);
  const [isVerified, setIsVerified] = useState(verified);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(bonusShopActions.getCategories());
    analyticHelper.openBonusShop();
  }, [dispatch]);

  useEffect(() => {
    if (verified) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  }, [verified]);

  const isUnVerifiedBelarus = !isVerified && process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS;
  const isLoadingBelarus = isFetchingVerified && process.env.REACT_APP_APP_TYPE === APP_TYPES.BELARUS;

  const handleButtonClick = () => {
    setIsVerified(true);
  };

  if (isLoadingBelarus || isFetchingBonusShop) {
    return <LoadingPage />;
  } else {
    if (isUnVerifiedBelarus) {
      return <ShopIsNotAvailable onButtonClick={handleButtonClick} />;
    } else {
      return <SwipeablePages />;
    }
  }
};

export const ShopMiddleware = withRouter(ShopMiddlewarePage);

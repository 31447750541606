import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const stepListSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    initStepList(state, action) {
      return handleInitStepList(state, action.payload);
    },
    nextStep(state, action) {
      return handleNextStep(state, action.payload);
    },
    prevStep(state, action) {
      return handlePrevStep(state, action.payload);
    },
    setStep(state, action) {
      return handleSetStep(state, action.payload);
    },
    resetStepList(state, action) {
      return { ...initialState };
    },
  },
});

const handleInitStepList = (state, { stepListId, items }) => {
  const currentStep = items[0].id;
  return {
    ...state,
    [stepListId]: { stepListId, items, currentStep },
  };
};

const handleNextStep = (state, { stepListId, props }) => {
  // debugger
  const stepListState = state[stepListId];
  const { items, currentStep } = stepListState;

  const currentStepIndex = items.findIndex((i) => i.id === currentStep);
  const nextIndex = currentStepIndex + 1;

  if (!items[nextIndex]) {
    return state;
  }

  return {
    ...state,
    [stepListId]: {
      ...stepListState,
      currentStep: items[nextIndex].id,
      ...props,
    },
  };
};

const handlePrevStep = (state, { stepListId }) => {
  const stepListState = state[stepListId];
  const { items, currentStep } = stepListState;

  const currentStepIndex = items.findIndex((i) => i.id === currentStep);
  const prevIndex = currentStepIndex - 1;

  if (!items[prevIndex]) {
    return state;
  }

  return {
    ...state,
    [stepListId]: {
      ...stepListState,
      currentStep: items[prevIndex].id,
    },
  };
};

const handleSetStep = (state, { stepListId, stepId, props }) => {
  const stepListState = state[stepListId];
  return {
    ...state,
    [stepListId]: {
      ...stepListState,
      currentStep: stepId,
      ...props,
    },
  };
};

export const stepListActions = stepListSlice.actions;

export default stepListSlice.reducer;

export const JTIIcon = (props: { className?: string }) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M39.5 20C39.5 30.7696 30.7696 39.5 20 39.5C9.23045 39.5 0.5 30.7696 0.5 20C0.5 9.23045 9.23045 0.5 20 0.5C30.7696 0.5 39.5 9.23045 39.5 20Z"
        fill="white"
        stroke="black"
      />
      <path
        d="M12.4716 16.8145C12.6736 16.8145 12.8438 16.9882 12.8438 17.1902V26.4028C12.8438 26.9806 12.6311 27.2925 12.3936 27.2818C12.1561 27.2606 12.0108 27.1117 11.83 26.6544C11.6031 26.0979 11.231 25.9384 10.7702 25.9172C10.3873 25.8994 9.77766 26.2007 9.68549 26.7147C9.59688 27.2287 9.73157 27.8065 10.5894 28.129C11.7378 28.5544 12.7516 27.8454 12.7516 27.8454L15.24 26.1688C15.24 26.1688 15.8496 25.825 16.1084 25.4315C16.4345 24.9566 16.4239 24.3646 16.4239 24.3646V17.1902C16.4239 16.9776 16.5905 16.8145 16.7925 16.8145H17.108C17.5369 16.8145 17.6964 16.5238 17.7283 16.3998C17.785 16.2084 17.8984 16.0878 18.2246 16.0878H19.2738C19.5892 16.0878 19.8515 16.219 19.8515 16.6656V25.5379C19.8515 25.5379 19.8622 25.8179 20.0288 25.9384C20.2556 26.1015 20.5144 25.9987 20.6278 25.9172C20.7625 25.825 23.0063 24.3717 23.0063 24.3717C23.3111 24.1696 23.3111 23.7975 23.3111 23.7975V16.5416C23.3111 16.2793 23.538 16.0878 23.7967 16.0878H25.9271C25.9271 16.0878 25.9483 16.0878 25.9838 16.0772L26.0051 16.0382V14.95L25.9838 14.9075L25.9413 14.9004H18.0828C17.6751 14.9004 17.4731 15.223 17.4412 15.3435C17.3845 15.5349 17.225 15.6767 16.9556 15.6767H11.9541C11.6386 15.6767 11.3798 15.9283 11.3798 16.2438C11.3798 16.5664 11.6386 16.818 11.9541 16.818H12.4716V16.8145Z"
        fill="black"
      />
      <path
        d="M29.5358 13C29.8406 13 30.0781 13.2517 30.0781 13.5742C30.0781 13.8897 29.8406 14.1378 29.5358 14.1378H27.5189H26.8773C26.5725 14.1378 26.335 13.8862 26.335 13.5636C26.335 13.2517 26.5725 13 26.8773 13H29.5358"
        fill="black"
      />
      <path
        d="M26.4805 14.896V23.5024C26.4805 23.5024 26.5017 23.7753 26.6612 23.8959C26.8846 24.0695 27.1469 23.9561 27.2603 23.8888C27.395 23.7966 29.6352 22.3433 29.6352 22.3433C29.94 22.1413 29.94 21.7691 29.94 21.7691V14.896H26.4805Z"
        fill="black"
      />
    </svg>
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuickTip, ResultType } from './quickTipsTypes';

export interface QuickTipsState {
  quickTip: IQuickTip | null;
  isFetching: boolean;
  result: ResultType | null;
}

const initialState: QuickTipsState = {
  quickTip: null,
  isFetching: false,
  result: null,
};

const quickTipsSlice = createSlice({
  name: 'quickTips',
  initialState,
  reducers: {
    setIsFetching(state: QuickTipsState, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    passQuickTip() {},
    getQuickTip() {},
    setQuickTip(state: QuickTipsState, action: PayloadAction<IQuickTip>) {
      state.quickTip = action.payload;
    },
    setResult(state: QuickTipsState, action: PayloadAction<ResultType>) {
      state.result = action.payload;
    },
    resetQuickTip() {
      return initialState;
    },
  },
});

export const quickTipsActions = quickTipsSlice.actions;
export const quickTipsReducer = quickTipsSlice.reducer;

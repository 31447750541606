import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import '../profileSettings.scss';

import { Input } from '../../../components/common/Input';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { SetThemeComponent } from './setThemeComponent';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../config';

const UserInfo = (props) => {
  const { formatMessage } = useInternationalization();
  const { handleSubmit, initialValues } = props;

  return (
    <form onSubmit={handleSubmit} className="edit-profile-form">
      <div className="profile-settings-page__content-fields">
        {isFeatureEnabled(CONFIG_KEYS.EXTENDED_PROFILE_INFO_ENABLED) && (
          <>
            <p className={classnames('profile-settings-page__content-color-themes-caption', 'your-data-caption')}>
              {formatMessage('Your data:')}
            </p>

            <Input name="username" customClass="profile-settings-page__input" />

            <Input name="traffic_code" customClass="profile-settings-page__input" disabled />
          </>
        )}
        <p className={classnames('profile-settings-page__content-color-themes-caption', 'jti-caption')}>
          {formatMessage('JTI representative:')}
        </p>
        <Input name="manager_name" customClass="profile-settings-page__input" id="managers" disabled />
      </div>
      {isFeatureEnabled(CONFIG_KEYS.THEME_GALLERY_ENABLED) && <SetThemeComponent initialValues={initialValues} />}
    </form>
  );
};

const form = reduxForm({
  form: 'userInfoForm',
})(UserInfo);

export const UserInfoIreland = withRouter(form);

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './multibrandQuizPage.scss';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { MULTIBRAND_QUIZ_STEPS } from '../../../redux/multibrandQuiz/multibrandQuizConstants';
// import { MultibrandQuizNoBonusGame } from './noBonusMultiBrand';
import { MultibrandQuizComponent } from './multibrandQuizComponent';
import { MultibrandQuizFinal } from './multibrandQuizFinal';
import {
  getMultibrandQuizIdSelector,
  isMultibrandQuizNewSelector,
} from '../../../redux/multibrandQuiz/multibrandQuizSelectors';
import { goToMultibrandQuizStepAction } from '../../../redux/multibrandQuiz/multibrandQuizSagas';
import { Routes } from '../../../const';
import { multibrandQuizActions } from '../../../redux/multibrandQuiz/multibrandQuizSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { MultibrandQuizNoBonusGame } from './noBonusMultiBrand';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../config';

const MultibrandQuizPageComponent = (props) => {
  const { history } = props;
  const is_new = useSelector(isMultibrandQuizNewSelector);
  const quiz_id = useSelector(getMultibrandQuizIdSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!quiz_id) {
      history.replace(Routes.MainRoute);
      return;
    }
    if (is_new) {
      dispatch(goToMultibrandQuizStepAction(MULTIBRAND_QUIZ_STEPS.GAME));
    }

    return () => dispatch(multibrandQuizActions.reset());
  }, [is_new, dispatch, quiz_id, history]);

  useEffect(() => {
    analyticHelper.startMultibrandQuiz(quiz_id);
  }, [quiz_id]);

  const stepListContainerItems = [
    {
      id: MULTIBRAND_QUIZ_STEPS.GAME,
      Component: () => <MultibrandQuizComponent history={history} />,
    },
    {
      id: MULTIBRAND_QUIZ_STEPS.FINISH,
      Component: () => <MultibrandQuizFinal history={history} />,
    },
  ];

  if (isFeatureEnabled(CONFIG_KEYS.NON_BONUS_VIEW_ENABLED)) {
    stepListContainerItems.unshift({
      id: MULTIBRAND_QUIZ_STEPS.NO_BONUS_QUIZ,
      Component: () => <MultibrandQuizNoBonusGame />,
    });
  }

  const MultibrandQuizStepListContainer = createStepListContainer(
    STEP_LIST_IDS.multibrandQuizStepList,
    stepListContainerItems,
  );

  return (
    <div className="multibrand-quiz-page">
      <MultibrandQuizStepListContainer />
    </div>
  );
};
export const MultibrandQuizPage = withRouter(MultibrandQuizPageComponent);

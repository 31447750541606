import { useEffect } from 'react';
import { isIosDevice } from '../helpers/isIosDeviceHelper';
import { modalActions } from '../components/containers/Modal/modalSlice';

export const useDeviceOrientation = (dispatch) => {
  const setOrienatation = () => {
    if (isIosDevice) {
      if (window.orientation !== 0) {
        dispatch(modalActions.openOrientationModal());
      } else {
        dispatch(modalActions.closeModal());
      }
    } else {
      if (window.screen.orientation.type.includes('landscape')) {
        dispatch(modalActions.openOrientationModal());
      } else {
        dispatch(modalActions.closeModal());
      }
    }
  };

  useEffect(() => {
    window.addEventListener('orientationchange', setOrienatation);

    return () => {
      window.removeEventListener('orientationchange', setOrienatation);
    };
  });
};

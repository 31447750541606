import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { VoucherCategory, CategoriesValue } from '../index';

import styles from './SwitchCategoryButton.module.scss';

interface ISwitchCategoryButton {
  id: string;
  text: CategoriesValue;
  onClick: () => void;
  isActive: VoucherCategory;
}

const SwitchCategoryButton = ({ text, onClick, id, isActive }: ISwitchCategoryButton) => {
  const { formatMessage } = useInternationalization();
  const isActiveBtn = id === isActive;
  return (
    <button
      className={classnames(styles.switchButton, {
        [styles.active]: isActiveBtn,
        themedButton: isActiveBtn,
      })}
      onClick={onClick}
    >
      {formatMessage(text)}
    </button>
  );
};

export default SwitchCategoryButton;

import { APP_TYPES } from '../../const/appConstants';
import { InviteCodePageBelarus } from './inviteCodeBelarus/inviteCodePageBelarus';
import { InviteCodePageIreland } from './inviteCodeIreland/inviteCodePageIreland';

const getInviteCodePage = () => {
  switch (process.env.REACT_APP_APP_TYPE) {
    case APP_TYPES.IRELAND:
      return InviteCodePageIreland;
    case APP_TYPES.HUNGARY:
      return InviteCodePageIreland;
    case APP_TYPES.BELARUS:
      return InviteCodePageBelarus;
    default:
      return InviteCodePageIreland;
  }
};

export const InviteCodePage = getInviteCodePage();

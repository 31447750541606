import { useDispatch, useSelector } from 'react-redux';

import './errorPage.scss';
import Error from '../../../../images/cashout-error.png';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { Button } from '../../../../components/common/Button';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { perxSteps } from '../perxTrensferMoneyPage';
import { getIsFetching } from '../../../../redux/bonusShop/bonusShopSelectors';
import { ContentSpinner } from '../../../../components/common/Spinner/ContentSpinner';

export const PerxErrorPage = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const isFetching = useSelector(getIsFetching);

  const handleOnClickButton = () => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.perxStepList,
        stepId: perxSteps.transfer,
      }),
    );
  };

  return (
    <>
      {isFetching ? (
        <ContentSpinner />
      ) : (
        <div className="perx-error-page">
          <div className="perx-error-page__content">
            <div className="perx-error-page__content-image-container">
              <img className="perx-error-page__content-image-container-image" src={Error} alt="card" />
            </div>
            <div className="perx-error-page__content-title-container">
              <h1 className="perx-error-page__content-title-container-label">{formatMessage('Ooops')}</h1>
              <h1 className="perx-error-page__content-title-container-title">
                {formatMessage('Something went wrong. Check your virtual card and try again later')}
              </h1>
            </div>
            <div className={'perx-error-page__content-button-container'}>
              <Button customClass={'error-page__content-button-container-btn'} onClick={handleOnClickButton}>
                {formatMessage('Got it')}
              </Button>
            </div>
          </div>
          <DisclaimerFooter withoutNavigation />
        </div>
      )}
    </>
  );
};

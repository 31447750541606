import classNames from 'classnames';

import { ChatMessage, MessageType, Problem } from '../../../../redux/chat/chatTypes';
import styles from './ChatMenu.module.scss';
import { amplitudeHelper } from '../../../../helpers/ampitudeHelper';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../../config';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

type Props = {
  problems: Problem[];
  lastMessage?: ChatMessage;
  onProblemClick: (problemId: number) => void;
  onOtherClick: (text: string, type?: MessageType) => void;
  onYesClick: () => void;
};

export const ChatMenu = ({ problems, lastMessage, onProblemClick, onOtherClick, onYesClick }: Props) => {
  const { formatMessage } = useInternationalization();
  const problemClasses = classNames(styles.problem, { themedProblem: true });
  const value = isFeatureEnabled(CONFIG_KEYS.CHAT_WITHOUT_OPTIONS_ENABLED)
    ? formatMessage('chat.solve_the_problem')
    : 'Other';
  const otherMessageType = isFeatureEnabled(CONFIG_KEYS.CHAT_WITHOUT_OPTIONS_ENABLED)
    ? MessageType.SolveMyProblem
    : undefined;
  const getProblemsMenu = () => (
    <>
      {problems.map((problem) => (
        <div
          className={problemClasses}
          key={problem.id}
          onClick={() => {
            amplitudeHelper.supportChooseProblem(problem);
            onProblemClick(problem.id);
          }}
        >
          {problem.name}
        </div>
      ))}
      <div
        className={problemClasses}
        onClick={() => {
          amplitudeHelper.supportOther();
          onOtherClick(value, otherMessageType);
        }}
      >
        {value}
      </div>
    </>
  );

  const getConfirmationMenu = () => (
    <>
      <div className={problemClasses} onClick={onYesClick}>
        Yes
      </div>
      <div className={problemClasses} onClick={() => onOtherClick('No')}>
        No
      </div>
    </>
  );

  return (
    <div className={styles.chatMenu}>
      {lastMessage?.type === MessageType.Confirmation ? getConfirmationMenu() : getProblemsMenu()}
    </div>
  );
};

export const QuestionMarkIcon = (props) => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="9.5" fill="white" stroke="white" />
      <path
        d="M7 7.70698H8.19302C8.26977 6.7093 8.93953 6.08837 9.99302 6.08837C11.0326 6.08837 11.7023 6.72326 11.7023 7.58837C11.7023 8.33488 11.3884 8.77442 10.5791 9.26977C9.62326 9.84186 9.17674 10.4698 9.18372 11.3907V12.0326H10.4047V11.5791C10.4047 10.8465 10.6558 10.4558 11.5419 9.93256C12.4349 9.39535 13 8.62093 13 7.52558C13 6.08837 11.8 5 10.0419 5C8.0814 5 7.07674 6.20698 7 7.70698ZM9.79767 15.214C10.2512 15.214 10.593 14.8651 10.593 14.4186C10.593 13.9651 10.2512 13.6233 9.79767 13.6233C9.35116 13.6233 9.00233 13.9651 9.00233 14.4186C9.00233 14.8651 9.35116 15.214 9.79767 15.214Z"
        fill="black"
      />
    </svg>
  );
};

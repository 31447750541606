import { Controller, useFormContext } from 'react-hook-form';

import { useInternationalization } from '../../hooks/useTranslationHook';
import { CommonInput } from '../../components/common/Input';
import { OrderDetailsFormData } from './OrderDetails.types';

const OrderDetailsFieldsDigital = () => {
  const { control } = useFormContext<OrderDetailsFormData>();

  const { t } = useInternationalization();

  return (
    <Controller
      control={control}
      name="email"
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <CommonInput
          meta={{ touched: true, error: error && t(error?.message) }}
          input={{ value, onChange }}
          placeholder={t(`bonus_shop.order_details.form_fields.email`)}
        />
      )}
    />
  );
};

export default OrderDetailsFieldsDigital;

import { ReactElement, ReactNode } from 'react';
import classnames from 'classnames';

import { Primary, CreateTicket, Arrow, ChatTab } from './views';
import { ButtonType, ButtonViewProps } from './types';
import styles from './Button.module.scss';

export const BUTTON_VIEWS: Record<ButtonType, (props: ButtonViewProps) => ReactElement> = {
  [ButtonType.Transparent]: ({ children }) => <>{children}</>,
  [ButtonType.Primary]: Primary,
  [ButtonType.CreateTicket]: CreateTicket,
  [ButtonType.ChatTab]: ChatTab,
  [ButtonType.Arrow]: Arrow,
};

type Props = {
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
  type?: ButtonType;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  buttonAction?: ButtonAction;
};

type ButtonAction = 'button' | 'reset' | 'submit';

export const Button = ({
  onClick,
  isActive = false,
  isDisabled = false,
  type = ButtonType.Primary,
  className,
  children,
  disabled = false,
  buttonAction = 'button',
}: Props) => {
  const ViewComponent = BUTTON_VIEWS[type];

  return (
    <button type={buttonAction} className={classnames(styles.button, className)} onClick={onClick} disabled={disabled}>
      <ViewComponent isActive={isActive} isDisabled={isDisabled}>
        {children}
      </ViewComponent>
    </button>
  );
};

export const MODAL_ACTION_TYPES = {
  OPEN_MODAL: 'modal/openModal',
  CLOSE_MODAL: 'modal/closeModal',
};

export const MODALS = {
  error: 'error',
  orientation: 'orientation',
  prompt: 'prompt',
  promptWithTwoButtons: 'promptWithTwoButtons',
  cashout: 'cashout',
  unexprectedRewards: 'unexprectedRewards',
  loyaltyReward: 'loyaltyReward',
  verificationError: 'verificationError',
  verificationStep: 'verificationStep',
  esignEmail: 'esignEmail',
  scenarioReaction: 'scenarioReaction',
  exchange: 'exchange',
  achievementReward: 'achievementReward',
  achievementLevelCompleted: 'achievementLevelCompleted',
  achievement: 'achievement',
  achievementTutorial: 'achievementTutorial',
  quizGameError: 'quizGameError',
  wrongInviteCode: 'wrongInviteCode',
  SMSCodeInput: 'SMSCodeInput',
  quizRate: 'quizRate',
  happyBirthday: 'happyBirthday',
  inputModal: 'inputModal',
  dailyBonus: 'dailyBonus',
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { profileActions } from '../../redux/profile/profileSlice';
import { getManagerInfoSelector, getUserOutletInfoSelector } from '../../redux/profile/profileSelectors';
import { analyticHelper } from '../../helpers/analyticHelper';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { UserInfoForm } from './userInfoForms/userInfoFormCaucasus';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { ProfileSettingsBottomArea } from './profileSettingsBottomArea/profileSettingsBottomArea';
import { ProfileRulesLinks } from '../profile/profileRulesLinks/profileRulesLinks';

import './profileSettings.scss';

export const ProfileSettingsCaucasus = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.profile.userInfo);
  const theme = useSelector((state) => state.service.theme);
  const manager = useSelector(getManagerInfoSelector);
  const outlet = useSelector(getUserOutletInfoSelector);

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const handleSaveChanges = (values) => {
    const changedUserInfo = {
      username: values.username,
    };
    localStorage.setItem('themeId', theme.id);
    dispatch(profileActions.changeUserInfo({ userInfo: changedUserInfo }));
  };

  return (
    <div className="profile-settings-page">
      <div
        className="profile-settings-page__content"
        style={{ minHeight: isMobileSafari() ? 'calc(100% - 60px)' : 'unset', paddingBottom: '66px' }}
      >
        {userInfo.id && (
          <UserInfoForm
            trafficCode={userInfo.traffic_code}
            onSubmit={handleSaveChanges}
            initialValues={{
              username: userInfo.username,
              traffic_code: userInfo.traffic_code,
              manager_name: `${manager.name} ${manager.surname}`,
              city: `${outlet.city.name}`,
              legal_entity: `${outlet.legal_entity.name}`,
              address: `${outlet.outlet_address.name}`,
            }}
          />
        )}
        <ProfileRulesLinks />
        <ProfileSettingsBottomArea />
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  answers: [],
  pack_id: null,
  questions: null,
  surveyResults: {
    coins: null,
  },
};

const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
    startSurvey(state, { payload }) {},
    startCertainSurvey(state, { payload }) {},
    setSurveyPackId(state, { payload }) {
      const { pack_id } = payload;
      return {
        ...state,
        pack_id,
      };
    },
    setSurveyQuestions(state, { payload }) {
      const { questions } = payload;
      return {
        ...state,
        questions,
      };
    },
    setSurveyReward(state, { payload }) {
      const { coins } = payload;
      return {
        ...state,
        surveyResults: {
          coins,
        },
      };
    },
    setAnswer(state, { payload }) {
      const { answer } = payload;
      const newAnswers = [...state.answers];
      newAnswers.push([answer - 1]); // Decrease answer index by 1 because on server indexing starts with 0
      return {
        ...state,
        answers: newAnswers,
      };
    },
    setMultipleAnswers(state, { payload }) {
      const { multipleAnswers } = payload;
      multipleAnswers.forEach((element, index) => {
        multipleAnswers[index] = element - 1;
      });
      const newAnswers = [...state.answers];
      newAnswers.push(multipleAnswers); // Decrease answer index by 1 because on server indexing starts with 0
      return {
        ...state,
        answers: newAnswers,
      };
    },

    finishSurvey(state, { payload }) {},
  },
});

export const surveyActions = surveySlice.actions;
export const surveyReducer = surveySlice.reducer;

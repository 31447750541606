import classnames from 'classnames';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { priceQuestion, quickTipsInputLength } from '../../../helpers/formValidations';
import { CodeInput } from '../../../components/common/CodeInput';

import styles from './PriceQuestion.module.scss';

type Props = {
  isFourDigitAnswer?: boolean;
  customInputClass?: string;
  correctAnswer?: string;
  isHintShown?: boolean;
  isAnswerCorrect?: boolean;
  dotColor: string;
  customCorrectAnswerClass?: string;
  customIncorrectAnswerClass?: string;
  snackbar?: boolean;
  disabled?: boolean;
  numberOfInputs: number;
  priceValue?: string;
};

const PriceQuestion = ({
  customInputClass,
  correctAnswer,
  isHintShown,
  isAnswerCorrect,
  dotColor,
  customCorrectAnswerClass,
  customIncorrectAnswerClass,
  snackbar,
  disabled,
  numberOfInputs,
  priceValue,
}: Props) => {
  const { formatMessage } = useInternationalization();
  const maxInputLength = 1;

  const dotClasses = classnames(
    styles.dot,
    { [styles.dotWithFourInputs]: numberOfInputs === 4 },
    { [styles.passedQuestionDot]: correctAnswer },
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <span className={dotClasses} style={{ backgroundColor: dotColor }} />
        <CodeInput
          customClass={classnames(
            customInputClass || styles.input,
            { [customCorrectAnswerClass ? customCorrectAnswerClass : styles.correctAnswer]: isAnswerCorrect },
            {
              [customIncorrectAnswerClass ? customIncorrectAnswerClass : styles.incorrectAnswer]:
                correctAnswer && !isAnswerCorrect,
            },
          )}
          maxLength={maxInputLength}
          inputContainerStyle={styles.inputContainer}
          name={'value'}
          numberOfInputs={numberOfInputs}
          validate={[priceQuestion(formatMessage), quickTipsInputLength(numberOfInputs)]}
          snackbar={snackbar}
          disabled={disabled}
          priceValue={priceValue}
        />
        {isHintShown && <span className={styles.questionHint}>{`Correct answer is ${correctAnswer}`}</span>}
      </div>
    </div>
  );
};

export default PriceQuestion;

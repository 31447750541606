import { interactiveArticleRequiredRadio } from '../../../../helpers/formValidations';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { RadioButtonInput } from '../../../../components/common/CommonRadioButton';

import styles from '../InteractiveArticlePage.module.scss';

export const RadioButtonTypeQuestion = ({ id, name, title, instruction, answers, ...props }) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{formatMessage('interactive_article.radio_button_question.title')}</div>
      <RadioButtonInput
        name={name + id}
        sectionId={id}
        validate={interactiveArticleRequiredRadio}
        options={answers}
        {...props}
      />
    </div>
  );
};

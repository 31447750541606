import { combineReducers } from '@reduxjs/toolkit';

import * as chatConstants from './chatConstants';
import * as chatServices from './chatServices';
import { chatSelectors } from './chatSelectors';
import { notificationsSelectors } from './notifications/notificationsSelectors';
import { supportSelectors } from './support/supportSelectors';
import { ticketChatSelectors } from './ticketChat/ticketChatSelectors';
import { supportChatSelectors } from './supportChat/supportChatSelectors';
import { baChatSelectors } from './BaChat/BaChatSelectors';
import { notificationsActions, notificationsReducer } from './notifications/notificationsSlice';
import { ticketChatActions, ticketChatReducer } from './ticketChat/ticketChatSlice';
import { supportActions, supportReducer } from './support/supportSlice';
import { supportChatActions, supportChatReducer } from './supportChat/supportChatSlice';
import { baChatActions, baChatReducer } from './BaChat/BaChatSlice';
import { chatSagas } from './chatSagas';
import { chatReducer as commonReducer, chatActions } from './chatSlice';

const chatReducer = combineReducers({
  notifications: notificationsReducer,
  support: supportReducer,
  ticketChat: ticketChatReducer,
  supportChat: supportChatReducer,
  baChat: baChatReducer,
  common: commonReducer,
});

export {
  notificationsSelectors,
  notificationsActions,
  supportChatSelectors,
  supportChatActions,
  ticketChatSelectors,
  ticketChatActions,
  supportSelectors,
  supportActions,
  chatReducer,
  chatActions,
  baChatSelectors,
  baChatActions,
  chatConstants,
  chatServices,
  chatSelectors,
  chatSagas,
};

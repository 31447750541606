import ReactGA4 from 'react-ga4';

export const initGoogleAnalytics = () => {
  try {
    const tracking_id = process.env.REACT_APP_ANALYTICS_ID as string;
    if (!tracking_id) {
      throw new Error('Missing tracking Identifier');
    }
    ReactGA4.initialize(tracking_id);
    ReactGA4.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: '',
    });
  } catch (error) {
    console.warn(`Google analytics initialisation error: ${error}.`);
  }
};

import '../quizPage.scss';

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { EventModeComponent } from './EventModeComponent';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { EVENT_MODE_STEPS } from '../../../redux/eventMode/eventModeConstants';
import { getEventModeQuizData } from '../../../redux/eventMode/eventModeSelectors';
import { EventModeResult } from './EventModeResult';
import { EventModeRewards } from './EventModeRewards';
import { EventFinalPage } from './EventModeFinalPage';
import { eventModeActions } from '../../../redux/eventMode/eventModeSlice';

export const EventModePageComponent = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const eventModeData = useSelector(getEventModeQuizData);

  useEffect(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.seasonQuizStepList,
        stepId: 1,
      }),
    );
    return () => dispatch(eventModeActions.reset());
  }, [dispatch]);

  const onBackCallback = useCallback(() => {
    history.push('/main');
  }, [history]);

  if (!eventModeData) {
    return null;
  }
  const { background_image, text_color } = eventModeData;
  const theme = { color: text_color, background: background_image };

  const EventModeStepList = createStepListContainer(STEP_LIST_IDS.eventModeStepList, [
    {
      id: EVENT_MODE_STEPS.GAME,
      Component: () => <EventModeComponent theme={theme} history={history} onLeaveCallback={onBackCallback} />,
    },
    {
      id: EVENT_MODE_STEPS.RESULT,
      Component: () => <EventModeResult theme={theme} eventModeData={eventModeData} />,
    },
    {
      id: EVENT_MODE_STEPS.GIFTS,
      Component: () => <EventModeRewards theme={theme} eventModeData={eventModeData} />,
    },
    {
      id: EVENT_MODE_STEPS.FINAL,
      Component: () => <EventFinalPage history={history} />,
    },
  ]);

  return (
    <div
      className="quiz-page"
      style={{ background: `url(${background_image})`, color: text_color, backgroundSize: 'cover' }}
    >
      <EventModeStepList />
    </div>
  );
};

export const EventModePage = withRouter(EventModePageComponent);

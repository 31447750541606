import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getInputModalValue, getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalSlice';
import { inputModal, lengthMinMax, required } from '../../../helpers/formValidations';
import { challengeApi } from '../../../api/challengeApi';

import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalTitle } from '../../containers/Modal/ModalTitle';
import { Button } from '../../common/Button';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { Input } from '../../common/Input';

import styles from './InputModal.module.scss';

let task_id: number;

const asyncValidate = (values: any) => {
  return challengeApi.validateAnswer(values.challenge, task_id).then((res) => {
    const { data } = res;
    if (!data) {
      throw {
        challenge: 'Your code is invalid',
      };
    }
  });
};

const InputModal = ({ handleSubmit, reset }: InjectedFormProps) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const modalInputData: any = useSelector(getInputModalValue);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const {
    data: { title, description, onButtonClick, btnText, inputName, taskId },
  } = useSelector(getModalDataSelector);

  const onClose = () => {
    dispatch(modalActions.closeModal());
    reset();
  };

  const handleButtonClick = () => {
    setIsButtonDisabled(true);

    if (onButtonClick) {
      onButtonClick(modalInputData.challenge);
    }
    onClose();
  };

  task_id = taskId;

  return (
    <ModalContainer modal={MODALS.inputModal} onClose={onClose}>
      <div className={styles.inputModal}>
        <ModalTitle>{formatMessage(title)}</ModalTitle>
        <div className={styles.description}>{formatMessage(description)}</div>
        <form onSubmit={handleSubmit(handleButtonClick)}>
          <Input
            name={inputName}
            type="text"
            customClass={styles.input}
            placeholder={formatMessage('Type your answer in field')}
            validate={[required, inputModal, lengthMinMax(1, 15, formatMessage)]}
          />
          <Button customClass={styles.button} disabled={isButtonDisabled}>
            {formatMessage(btnText)}
          </Button>
        </form>
      </div>
    </ModalContainer>
  );
};

export const InputModalForm = reduxForm({
  form: 'inputModal',
  asyncValidate,
  asyncBlurFields: ['challenge'],
  destroyOnUnmount: false,
  onSubmit: () => {},
})(InputModal);

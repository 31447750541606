import { useEffect } from 'react';

export const usePreloadImagesHook = imagesUrls => {
  useEffect(() => {
    imagesUrls.forEach(url => {
      var img = new Image();
      img.src = url;
    });
  });
};

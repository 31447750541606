import { useCallback, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, reset, change } from 'redux-form';
import { useSelector } from 'react-redux';

import '../signupPage.scss';

import { ArrowIconRounded } from '../../../../icons/arrow-icon-rounded';
import { Button } from '../../../../components/common/Button';
import { Select } from '../../../../components/common/Select';
import { ArrowButton } from '../../../../components/common/ArrowButton/ArrowButton';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getPhoneCode } from '../../../../redux/auth/authConstants';
import { required } from '../../../../helpers/formValidations';

import { authActions } from '../../../../redux/auth/authSlice';
import {
  getCitiesSelectors,
  getLegalEntitiesSelector,
  getAddressesSelector,
  getAvailableManager,
} from '../../../../redux/service/serviceSelectors';
import { authApi } from '../../../../api/authApi';
import { serviceActions } from '../../../../redux/service/serviceSlice';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { Input } from '../../../../components/common/Input';

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

const otherValues = {
  city_id: 'Más város',
  legal_entity_id: 'Más kereskedő',
  outlet_address_id: 'Más cím',
  manager_id: 'Más TM',
};

const Signup2ndStageComponent = (props) => {
  const { handleSubmit, formObj, signupForm, invalid, phone } = props;
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(serviceActions.getCitiesInfo());
  }, [dispatch]);

  useEffect(() => {
    if (formObj.values.city_id) {
      dispatch(serviceActions.getLegalEntities({ city_id: formObj.values.city_id }));
      dispatch(reset('signupSecondStageForm'));
      dispatch(change('signupSecondStageForm', 'city_id', formObj.values.city_id));
    }
  }, [formObj.values.city_id, dispatch]);

  useEffect(() => {
    if (formObj.values.city_id && formObj.values.legal_entity_id) {
      if (formObj.values.legal_entity_id === otherValues.legal_entity_id) {
        dispatch(change('signupSecondStageForm', 'outlet_address_id', otherValues.outlet_address_id));
      } else {
        dispatch(change('signupSecondStageForm', 'outlet_address_id', ''));
        dispatch(change('signupSecondStageForm', 'manager_id', ''));
      }
      dispatch(
        serviceActions.getAddresses({
          city_id: formObj.values.city_id,
          legal_entity_id: formObj.values.legal_entity_id,
        }),
      );
    }
  }, [formObj.values.city_id, formObj.values.legal_entity_id, dispatch]);

  useEffect(() => {
    if (formObj.values.outlet_address_id) {
      if (formObj.values.outlet_address_id === otherValues.outlet_address_id) {
        dispatch(change('signupSecondStageForm', 'manager_id', otherValues.manager_id));
      }
      dispatch(serviceActions.getManager({ outlet_address_id: formObj.values.outlet_address_id }));
    }
  }, [formObj.values.outlet_address_id, dispatch]);

  const cities = useSelector(getCitiesSelectors);
  const legal_entities = useSelector(getLegalEntitiesSelector);
  const outlets = useSelector(getAddressesSelector);
  const manager = useSelector(getAvailableManager);

  useEffect(() => {
    if (manager) {
      dispatch(change('signupSecondStageForm', 'manager_id', `${manager.name} ${manager.surname}`.trim()));
    }
  }, [dispatch, manager]);

  useEffect(() => {
    return function cleanup() {
      dispatch(change('signupSecondStageForm', 'manager_id', ''));
    };
  }, [dispatch]);

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupSecondStageForm'));
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 3,
      }),
    );
  }, [dispatch]);

  const submit = () => {
    const secondFormValues = {};
    for (const i in formObj.values) {
      if (formObj.values[i] === otherValues[i]) {
        secondFormValues[i] = null;
      } else {
        secondFormValues[i] = formObj.values[i];
      }
    }

    return authApi
      .signup({ ...signupForm.values, ...secondFormValues, manager_id: manager.id, phone: getPhoneCode() + phone })
      .then(
        () => {
          dispatch(authActions.signupSuccess());
          dispatch(reset('signupForm'));
          dispatch(reset('signupSecondStageForm'));
        },
        (error) => {
          if (error.message === 'This username is already taken') {
            dispatch(
              stepListActions.setStep({
                stepListId: STEP_LIST_IDS.authStepList,
                stepId: 3,
              }),
            );
            dispatch(
              modalActions.openPromptModal({
                title: formatMessage('This username has already taken'),
                description: [formatMessage('Please choose another one to complete the registration.')],
                btnText: formatMessage('OK'),
                onButtonClick: () => {
                  dispatch(modalActions.closeModal());
                },
              }),
            );
          }
        },
      );
  };

  return (
    <form onSubmit={handleSubmit(submit)} className="signup-page">
      <div className="signup-page__top-section">
        <Button
          withoutTheme
          type="button"
          className="signup-page__back-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded className="signup-page__top-arrow" />
        </Button>
        <div className="signup-page__title">{formatMessage('Fill in your outlet data')}</div>
        <Select
          name="city_id"
          placeholder={formatMessage('City')}
          options={cities}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'city_id')}`}
        />
        <Select
          name="legal_entity_id"
          placeholder={formatMessage('Retailer name')}
          options={legal_entities}
          disabled={!legal_entities.length}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'legal_entity_id')}`}
        />
        <Select
          name="outlet_address_id"
          placeholder={formatMessage('Retail address')}
          options={outlets}
          disabled={!outlets.length}
          validate={[required]}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'outlet_address_id')}`}
        />
        <Input
          name="manager_id"
          placeholder={formatMessage('TM')}
          validate={[required]}
          disabled={true}
          customClass={`signup-page__input${getSelectFontColor(formObj.values, 'manager_id')}`}
        />
      </div>
      <div className="signup-page__bottom-section">
        <ArrowButton withoutTheme disabled={invalid} />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;

  return {
    formObj: form.signupSecondStageForm || { values: {} },
    signupForm: form.signupForm,
    phone: form.enterPhoneForm.values.phone,
  };
};

const form = reduxForm({
  form: 'signupSecondStageForm',
  initialValues: {},
  destroyOnUnmount: false,
  onSubmit: () => {},
})(Signup2ndStageComponent);

export const SignupSecondStageHungary = connect(mapStateToProps, null)(form);

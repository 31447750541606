import { createSelector } from 'reselect';
import { ANSWER_SOURCES } from './gameConstants';
import { decryptAnswer } from '../../helpers/cryptCorrectAnswers';

export const isOpponentBotSelector = createSelector(
  [(state) => state.game.opponent],
  (opponent) => !!opponent && opponent.bot,
);
export const getOpponentSelector = createSelector([(state) => state.game.opponent], (opponent) => opponent);

export const getIsBonusGameFinishedSelector = createSelector(
  [(state) => state.game.isBonusGameFinished],
  (isBonusGameFinished) => isBonusGameFinished,
);

export const getCoinsForGameSelector = createSelector(
  [(state) => state.game.coinsForGame],
  (coinsForGame) => coinsForGame,
);

export const getInviteDataSelector = createSelector([(state) => state.game.inviteData], (inviteData) => inviteData);

export const isTimerStoped = createSelector([(state) => state.game.gameTimer.isStopped], (isStopped) => isStopped);

export const isTimerPaused = createSelector([(state) => state.game.gameTimer.isPaused], (isPaused) => isPaused);

export const getPackIdSelector = createSelector([(state) => state.game.pack_id], (pack_id) => pack_id);

export const getQuestionsSelector = createSelector([(state) => state.game.questions], (questions) => questions);

export const getMyCurrentPointsSelector = createSelector([(state) => state.game.gameTimer.me], (points) => points);

export const getAnswersSelector = createSelector([(state) => state.game.answers], (answers) => answers);

export const selectIsFetching = createSelector([(state) => state.game.isFetching], (isFetching) => isFetching);

export const getCurrentQuestionNumberSelector = createSelector(
  [(state) => state.game.currentQuestion],
  (currentQuestion) => currentQuestion,
);

export const getCurrentQuestionSelector = createSelector(
  [getCurrentQuestionNumberSelector, getQuestionsSelector],
  (currentQuestionIndex, questions) => {
    return { ...questions[currentQuestionIndex], questionIndex: currentQuestionIndex };
  },
);

export const getIsDoublePointsQuestion = createSelector(
  [getCurrentQuestionSelector],
  ({ double_points, product_question }) => double_points && product_question,
);

export const isCurrentQuestionLastSelector = createSelector(
  [getCurrentQuestionNumberSelector, getQuestionsSelector],
  (currentQuestion, questions) => {
    return currentQuestion === questions.length - 1;
  },
);

export const getRoundTimeSelector = createSelector([(state) => state.game.round_time], (time) => time);

export const getBotAnswerDataSelector = createSelector([getCurrentQuestionSelector], (currentQuestion) => {
  return {
    bot_answer: currentQuestion.bot_answer,
    bot_timer: currentQuestion.bot_timer,
  };
});

export const getMyQuizPointsSelector = createSelector(
  [(state) => state.game.gamePoints.me],
  (gamePoints) => gamePoints,
);

export const getOpponentQuizPointsSelector = createSelector(
  [(state) => state.game.gamePoints.opponent],
  (gamePoints) => gamePoints,
);

export const getQuizResultsSelector = createSelector(
  [getMyQuizPointsSelector, getOpponentQuizPointsSelector],
  (my, opponents) => {
    if (my > opponents) {
      return 'win';
    } else if (my === opponents) {
      return 'draw';
    } else {
      return 'lose';
    }
  },
);

export const isBotAnsweredAllQuestionsSelector = createSelector(
  [getAnswersSelector, getQuestionsSelector],
  (answers, questions) => {
    return answers[ANSWER_SOURCES.OPPONENT].length === questions.length;
  },
);

export const checkAnswersSelector = createSelector([getAnswersSelector, getQuestionsSelector], (answers, questions) => {
  const myAnswers = checkCorrectAnswers(answers[ANSWER_SOURCES.ME], questions);
  const opponentAnswers = checkCorrectAnswers(answers[ANSWER_SOURCES.OPPONENT], questions);

  return {
    [ANSWER_SOURCES.ME]: myAnswers,
    [ANSWER_SOURCES.OPPONENT]: opponentAnswers,
  };
});

export const checkCorrectAnswers = (answers, questions) => {
  return Object.values(answers).map((answer, index) => {
    let correctAnswer;
    if (questions instanceof Array) {
      correctAnswer = Number.isInteger(questions[index].correct_answers[0])
        ? questions[index].correct_answers[0]
        : decryptAnswer(questions[index].correct_answers[0]);
    } else {
      correctAnswer = decryptAnswer(questions.correct_answers[0]);
    }

    const isCorrect = correctAnswer === answer;
    return {
      answer,
      isCorrect,
      correctAnswer,
    };
  });
};

import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import {
  getIsFetching,
  selectMysteryShopperRecommendations,
} from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import { ICriteria } from '../../../redux/mysteryShopper/mysteryShopperTypes';
import { mysteryShopperActions } from '../../../redux/mysteryShopper/mysteryShopperSlice';
import { Routes } from '../../../const';
import { BackArrowButton } from '../../../components/common/BackArrowButton/BackArrowButton';
import { LoaderContainer } from '../../../components/common';
import { Button } from '../../../components/common/Button';
import { Criteria } from './Criteria/Criteria';

import styles from './Recommendations.module.scss';

export const Recommendations = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  const { formatMessage } = useInternationalization();

  const isFetching = useSelector(getIsFetching);
  const recommendations = useSelector(selectMysteryShopperRecommendations);

  const onBackArrowClick = () => {
    history.goBack();
  };

  const onButtonClick = () => {
    history.push(Routes.MainRoute);
  };

  useEffect(() => {
    dispatch(mysteryShopperActions.getRecommendations({ mysteryShopperId: parseInt(id, 10) }));
  }, []);

  return (
    <LoaderContainer isLoading={isFetching}>
      <div className={styles.container}>
        <BackArrowButton
          withoutTheme
          onHistoryReplaceCallback={onBackArrowClick}
          customClass={styles.backButton}
          buttonColor="white"
        />
        <div className={styles.generalScore}>{recommendations?.general_score}</div>
        <span className={styles.title}>
          Hmm... <br />+{recommendations?.points} points
        </span>
        <span className={styles.description}>
          {formatMessage(
            'You are being checked by a mystery shopper for a short period. You can see the criteria for successful completion of the check below. Be great and get rewards!',
          )}
        </span>
        {recommendations?.criterias.map(({ name, percent_of_correct_answers, recommendation, id }: ICriteria) => (
          <Criteria
            key={id}
            name={name}
            percentOfCorrectAnswers={percent_of_correct_answers}
            recommendation={recommendation}
          />
        ))}
        <Button onClick={onButtonClick} withoutTheme customClass={styles.button}>
          {formatMessage('Got it')}
        </Button>
      </div>
    </LoaderContainer>
  );
};

import { createSelector } from 'reselect';

export const getSurveyQuestionsSelector = createSelector(
  [state => state.survey.questions],
  questions => questions,
);

export const getSurveyAnswersSelector = createSelector(
  [state => state.survey.answers],
  answers => answers,
);

export const getSurveyPackIdSelector = createSelector(
  [state => state.survey.pack_id],
  pack_id => pack_id,
);

export const getSurveyReward = createSelector(
  [state => state.survey.surveyResults.coins],
  coins => coins,
);

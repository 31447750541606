import { useDispatch } from 'react-redux';

import './oppaCreateWallet.scss';
import img from './imgCreateWalletSuccess.svg';

import { Button } from '../../../../components/common/Button';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { oppaSteps } from '../OPPA';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { profileActions } from '../../../../redux/profile/profileSlice';

export const OppaCreateWalletSuccess = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const handleNextStep = async () => {
    await dispatch(profileActions.getUserInfo());
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.oppaStepList,
        stepId: oppaSteps.mainPage,
      }),
    );
  };
  return (
    <div className="oppa-create-wallet__success">
      <div className="oppa-create-wallet__success-pictureBox">
        <img src={img} alt="Success Create Wallet" title="Success Create Wallet" />
      </div>
      <div className="oppa-create-wallet__success-container">
        <div className="oppa-create-wallet__success-container-header">{formatMessage('Success')}</div>
        <div className="oppa-create-wallet__success-container-description">
          {formatMessage('Your e-wallet is added')}
        </div>
        <Button customClass="oppa__button  oppa-create-wallet__success-container-button" onClick={handleNextStep}>
          {formatMessage('OK')}
        </Button>
      </div>
      <DisclaimerFooter customStyle={{ position: 'static' }} />
    </div>
  );
};

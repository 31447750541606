import { useHistory, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'react-redux';

import { isMobileSafari } from '../../../helpers/checkIsSafariBrowser';
import { NavigationMenu } from '../../../components';
import { UserProfileBaseComponent } from '../userProfileBaseComponent/userProfileBaseComponent';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { ProfileRulesLinks } from '../profileRulesLinks/profileRulesLinks';
import { Header } from '../../../components/common/Header';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import AppVersion from '../../../components/AppVersion/AppVersion';
import { Button } from '../../../components/common/CommonButton/Button';
import { Routes } from '../../../const';
import { getInviteCodesAmount } from '../../../redux/profile/profileSelectors';
import { isFeatureEnabled, CONFIG_KEYS } from '../../../config';
import { LogoutDeleteAccount } from '../../../components/LogoutDeleteAccount/LogoutDeleteAccount';

import styles from './DefaultProfileLayout.module.scss';

const DefaultProfilePage = ({ location }: RouteComponentProps) => {
  const { formatMessage } = useInternationalization();
  const history = useHistory();
  const inviteCodesAmount = useSelector(getInviteCodesAmount);

  const goToInvitationPage = () => {
    history.push(Routes.ColleaguesInvitation);
  };

  return (
    <div className={styles.page}>
      <AppVersion />
      <Header>
        <p className={styles.header}>{formatMessage('Profile')}</p>
      </Header>
      <div className={styles.content} style={{ minHeight: isMobileSafari() ? 'calc(100% - 150px)' : 'unset' }}>
        <UserProfileBaseComponent />

        {isFeatureEnabled(CONFIG_KEYS.SHOW_REFFERAL_MODULE_ENABLED) && !!inviteCodesAmount && (
          <Button className={styles.goToInvitationButton} onClick={goToInvitationPage}>
            <div className={styles.invitationButtonComponent}>{formatMessage('profile.invite_button')}</div>
          </Button>
        )}

        <ProfileRulesLinks />
        {isFeatureEnabled(CONFIG_KEYS.SHOW_LOGOUT_DELETE_ACCOUNT_BLOCK) && (
          <LogoutDeleteAccount className={styles.logoutDeleteSpacing} />
        )}
      </div>
      <NavigationMenu location={location} />
      <DisclaimerFooter />
    </div>
  );
};

export const DefaultProfileLayout = withRouter(DefaultProfilePage);

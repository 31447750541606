import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { ChatMessage, MessageType, Problem, Ticket, TicketStatus } from '../chatTypes';
import { ascDateComparer } from '../chatServices';

interface TicketChatState {
  isFetching: boolean;
  isImageUploading: boolean;
  ticket: Ticket | undefined;
  problems: Problem[];
  messages: EntityState<ChatMessage>;
  hasMore: boolean;
}

export const messageAdaptor = createEntityAdapter<ChatMessage>({
  selectId: (message) => message.id,
  sortComparer: (a, b) => ascDateComparer(a.createdAt, b.createdAt),
});

const initialState: TicketChatState = {
  isFetching: true,
  isImageUploading: false,
  ticket: undefined,
  problems: [],
  messages: messageAdaptor.getInitialState(),
  hasMore: false,
};

const ticketChatSlice = createSlice({
  name: 'ticketChat',
  initialState,
  reducers: {
    fetchTicket: (state, action: PayloadAction<{ ticketId: string }>): void => {},
    closeTicket: (state, action: PayloadAction<{ ticketId: string }>): void => {},
    ticketReceived: (state, action: PayloadAction<Ticket>): TicketChatState => ({ ...state, ticket: action.payload }),
    fetchMessages: (state, action: PayloadAction<{ offset: number; ticketId: string }>): void => {},
    sendMessage: (
      state,
      action: PayloadAction<{ text: string; image?: object; ticketId: string; type?: number }>,
    ): void => {},
    uploadImage: (state, action: PayloadAction<{ formData: object; ticketId: string }>): void => {},
    chooseOther: (state, action: PayloadAction<{ ticketId: string; text: string; type?: MessageType }>): void => {},
    setIsFetching: (state, action: PayloadAction<boolean>): TicketChatState => ({
      ...state,
      isFetching: action.payload,
    }),
    setIsImageUploading: (state, action: PayloadAction<boolean>): TicketChatState => ({
      ...state,
      isImageUploading: action.payload,
    }),
    setHasMore: (state, action: PayloadAction<boolean>): TicketChatState => ({
      ...state,
      hasMore: action.payload,
    }),
    chooseProblem: (state, action: PayloadAction<{ problemId: number; ticketId: string }>): void => {},
    fetchProblems: (state, action: PayloadAction<{ ticketId: string; problemId?: number }>): void => {},
    problemsReceived: (state, action: PayloadAction<Problem[]>): TicketChatState => ({
      ...state,
      problems: action.payload,
    }),
    messagesReceived: (state, action: PayloadAction<ChatMessage[]>): void => {
      messageAdaptor.upsertMany(state.messages, action);
    },
    messageReceived: (state, action: PayloadAction<ChatMessage>): void => {
      messageAdaptor.upsertOne(state.messages, action);
    },
    setTicketStatusResolved: (state, action: PayloadAction<{ status: TicketStatus }>): TicketChatState => ({
      ...state,
      ticket: state.ticket && { ...state.ticket, status: action.payload.status },
    }),
    reset: (): TicketChatState => ({
      ...initialState,
    }),
  },
});

export const ticketChatActions = ticketChatSlice.actions;
export const ticketChatReducer = ticketChatSlice.reducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IBirthdayState {
  coins: number | null;
}

const initialState: IBirthdayState = {
  coins: null,
};

const birthdaySlice = createSlice({
  name: 'birthday',
  initialState,
  reducers: {
    sendBirthdayCoins(): void {},
    fetchCoins(state, action: PayloadAction<number>): void {
      state.coins = action.payload;
    },
  },
});

export const birthdayActions = birthdaySlice.actions;
export const birthdayReducer = birthdaySlice.reducer;

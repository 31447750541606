import React from 'react';
import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { CheckIdCard } from './CheckIdCard/CheckIdCard';
import { IdCardType } from './IdCardType/IdCardType';
import { AddPhoto } from './AddPhoto/AddPhoto';
import { IdCardResult } from './IdCardResult/IdCardResult';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { VerifyIdCard } from './VerifyIdCard/VerifyIdCard';

export const IdCard = () => {
  const steps = {
    checkIdCard: 1,
    idCardType: 2,
    idCardPhoto: 3,
    verifyIdCard: 4,
    idCardResult: 5,
  };

  const items = [
    { id: steps.checkIdCard, Component: () => <CheckIdCard /> },
    { id: steps.idCardType, Component: () => <IdCardType /> },
    { id: steps.idCardPhoto, Component: () => <AddPhoto /> },
    { id: steps.verifyIdCard, Component: () => <VerifyIdCard /> },
    { id: steps.idCardResult, Component: () => <IdCardResult /> },
  ];

  const StepList = createStepListContainer(STEP_LIST_IDS.idCardStepList, items);

  return (
    <>
      <StepList />
      <DisclaimerFooter withoutNavigation />
    </>
  );
};

import React from 'react';
import { BackArrowButton } from '../../../../components/common/BackArrowButton/BackArrowButton';
import { useHistory } from 'react-router';

import styles from './Faq.module.scss';

export const Faq = () => {
  const history = useHistory();

  const onBackArrowClick = () => {
    history.goBack();
  };

  return (
    <div className={styles.container}>
      <BackArrowButton
        withoutTheme
        onHistoryReplaceCallback={onBackArrowClick}
        customClass={styles.backButton}
        buttonColor={'black'}
      />
      <img
        className={styles.image}
        src="https://jticonnect.fra1.digitaloceanspaces.com/knowledge-bank/articles/Interactive_articles_tutorial.jpg"
        alt="faq"
      />
    </div>
  );
};

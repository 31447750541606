import { interactiveArticleRequiredInput } from '../../../../helpers/formValidations';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { Input } from '../../../../components/common/Input';

import styles from '../InteractiveArticlePage.module.scss';

export const InputTypeQuestion = ({ name, title }) => {
  const { formatMessage } = useInternationalization();

  return (
    <div className={styles.content}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>{formatMessage('interactive_article.input_question.title')}</div>
      <Input
        validate={[interactiveArticleRequiredInput]}
        customClass={styles.inputContainer}
        type="text"
        name={name}
        placeholder={formatMessage('interactive_article.input_question.placeholder')}
      />
    </div>
  );
};

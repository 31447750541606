import { all } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, takeLatest } from 'redux-saga/effects';
import { idCardActions } from './idCardSlice';
import { idCardApi } from '../../api/idCardApi';
import { idCardSelectors } from './idCardSelectors';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';

function* sendIdCard() {
  try {
    const fileKey: string = yield select(idCardSelectors.selectFileKey);
    const idCardType: string = yield select(idCardSelectors.selectIdCardType);
    yield put(idCardActions.setErrorMessage(''));

    const { data } = yield idCardApi.sendIdCard(fileKey, Number(idCardType));

    yield put(idCardActions.setIdCardData(data));

    yield put(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
  } catch (e) {
    console.error(e);
    yield put(idCardActions.setErrorMessage('Something goes wrong, try one more time'));
  } finally {
    yield put(idCardActions.setIsFetching(false));
  }
}

function* sendVerifiedIdCard({ payload }: PayloadAction<any>) {
  const { date_of_birth, first_name, last_name, number } = payload;
  try {
    yield put(idCardActions.setIsFetching(true));

    const { data } = yield idCardApi.sendVerifiedIdCard({ date_of_birth, first_name, last_name, number });

    yield put(idCardActions.setIdCardData(data));

    yield put(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.idCardStepList }));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(idCardActions.setIsFetching(false));
  }
}

function* getIdCard() {
  try {
    yield put(idCardActions.setIsFetching(true));

    const { data } = yield idCardApi.getIdCard();

    yield put(idCardActions.setIdCardData(data));
  } catch (e) {
    console.error(e);
  } finally {
    yield put(idCardActions.setIsFetching(false));
  }
}

export function* idCardSagas() {
  yield all([
    takeLatest(idCardActions.sendIdCard, sendIdCard),
    takeLatest(idCardActions.sendVerifiedIdCard, sendVerifiedIdCard),
    takeLatest(idCardActions.getIdCard, getIdCard),
  ]);
}

import { useEffect, useState } from 'react';

export const useDeviceScreenHeight = () => {
  const [height, setHeight] = useState(window.screen.height);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.screen.height);
    };
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
}

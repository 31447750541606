import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { goBack, push } from 'connected-react-router';

import { PASSPORT_PAGES, PASSPORT_PAGE_STATES, PROFILE_ACTION_TYPES } from './profileConstants';
import { profileActions } from './profileSlice';
import { profileApi } from '../../api/profileApi';
import { Routes } from '../../const';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { parsePassportData } from '../../helpers/passportParsesHelper';
import { passportApi } from '../../api/passportApi';
import { getPassportDataSelector, getPassportPhotos } from './profileSelectors';
import { CONSENT_STATUSES } from '../../components/NewTermsSteps/termsConstants';
import { CONFIG_KEYS, isFeatureEnabled } from '../../config';
import { getUpdatedGoalTracker } from '../../helpers/goalsHelper';

export function* getUserInfoSaga() {
  try {
    yield put(profileActions.setIsFetching(true));
    const response = yield profileApi.getUserInfo();
    if (response.data) {
      if (isFeatureEnabled(CONFIG_KEYS.SHOW_GOAL_PROGRESS_ENABLED) && response.data.goal_trackers) {
        yield put(
          profileActions.setUserInfo({
            userInfo: {
              ...response.data,
              goal_trackers: getUpdatedGoalTracker(response.data.goal_trackers),
            },
          }),
        );
      } else {
        yield put(profileActions.setUserInfo({ userInfo: response.data }));
      }
      localStorage.setItem('balance', response.data.coins);
      if (response.data.legal_documents_changes) {
        yield put(push('/privacy-policy-update'));
      }
      if (
        isFeatureEnabled(CONFIG_KEYS.MONTHLY_COMPLIANCE_CHECK_ENABLED) &&
        !response.data.is_monthly_validation_passed
      ) {
        yield put(push(Routes.MonthlyConfirmation));
      }
      if (isFeatureEnabled(CONFIG_KEYS.DAILY_BONUS_ENABLED) && !response.data.has_daily_bonus_today) {
        yield put(modalActions.openDailyBonusModal());
      }
    }
  } catch (err) {
    console.error('getUserInfo error', err);
  } finally {
    yield put(profileActions.setIsFetching(false));
  }
}

function* getRatingSaga({ payload: { filterName, brand_id } }) {
  try {
    yield put(profileActions.setIsFeinchigRating(true));
    const response = yield profileApi.getRating(filterName, brand_id);

    if (response.data) {
      yield put(profileActions.setRating({ rating: response.data }));
    }
  } catch (err) {
    console.error('getRating error', err);
  } finally {
    yield put(profileActions.setIsFeinchigRating(false));
  }
}

function* getEarnedSaga({ payload: { limit, offset } }) {
  try {
    const response = yield profileApi.getEarned(limit, offset);

    if (response.data && response.data) {
      yield put(profileActions.setEarned({ earned: response.data }));
      localStorage.setItem('balance', response.data.coins);
    }
  } catch (err) {
    console.error('getEarned error', err);
  }
}

function* goToSettingsSaga() {
  try {
    yield put(push(Routes.ProfileSettingsRoute));
  } catch (err) {
    console.error('goToSettings error', err);
  }
}

function* changeUserInfoSaga({ payload: { userInfo } }) {
  try {
    yield profileApi.changeUserInfo(userInfo);

    yield put(
      modalActions.openPromptModal({
        description: 'All changes are saved',
        btnText: 'OK',
      }),
    );
    yield call(getUserInfoSaga);
  } catch (err) {
    console.error('changeUserInfo error', err);
  }
}
function* updateMonthlyValidationSaga({ payload: { userInfo } }) {
  try {
    yield profileApi.changeUserInfo(userInfo);

    yield put(push(Routes.MainRoute));
  } catch (err) {
    console.error('Update monthly validation error', err);
  }
}

function* redirectToBSLimitPageSaga() {
  yield put(push(Routes.BonusShopLimit));
}

function* uploadPassportPhotoSaga({ payload: { passportData, passportPage, base64PassportPhoto } }) {
  try {
    const passportPhotos = yield select(getPassportPhotos);
    const { data } = yield passportApi.uploadPassportPhoto(passportData);

    if (passportPage === PASSPORT_PAGES.REGISTRATION_PAGE) {
      yield put(
        profileActions.setPassportPhotos({ passportPhotos: { ...passportPhotos, registration_page: data.file_key } }),
      );
      yield put(push(Routes.AddressRegistration));

      return;
    }
    const response = yield passportApi.getTextFromPhotoBase64(base64PassportPhoto);

    const newPassportData = parsePassportData(response.responses[0].textAnnotations, passportPage);
    const parsedPassportData = yield select(getPassportDataSelector);

    yield put(profileActions.setPassportData({ passportData: { ...parsedPassportData, ...newPassportData } }));
    if (passportPage === PASSPORT_PAGES.INFO_PAGE) {
      yield put(profileActions.setCurrentPassportPage({ passportPage: PASSPORT_PAGES.PHOTO_PAGE }));
      yield put(profileActions.setPassportPhotos({ passportPhotos: { id_page: data.file_key } }));
    } else if (passportPage === PASSPORT_PAGES.PHOTO_PAGE) {
      yield put(profileActions.setPassportPageState({ passportPageState: PASSPORT_PAGE_STATES.FILLING }));
      yield put(profileActions.setPassportPhotos({ passportPhotos: { ...passportPhotos, photo_page: data.file_key } }));
      yield put(push(Routes.PassportSettings));
    }
  } catch (error) {
    yield put(
      modalActions.openPromptModal({
        title: 'Ooops',
        description: 'Reload the page and try again',
        btnText: 'OK',
        onButtonClick: () => document.location.reload(),
        onClose: () => document.location.reload(),
      }),
    );
  }
}

function* uploadPassportDataSagas() {
  const registrationForm = yield select((state) => state.form.addressRegistrationForm.values);
  const passportData = yield select(getPassportDataSelector);
  const passportPhotos = yield select(getPassportPhotos);
  const registationInfo = {
    city: `${registrationForm.cityType} ${registrationForm.city}`,
    street: `${registrationForm.streetType} ${registrationForm.street}`,
    building: registrationForm.building,
    corps: registrationForm.corps,
    apartment: registrationForm.apartment,
  };
  const { data } = yield passportApi.setPassportData({ ...passportData, ...registationInfo, ...passportPhotos });
  yield put(profileActions.setStatementData({ statementData: data }));
  yield put(push(Routes.PassportStatement));
}

function* getPassportDataSaga() {
  try {
    const response = yield passportApi.getPassportData();
    if (response && response.data) {
      yield put(profileActions.setPassportData({ passportData: response.data }));
      yield put(profileActions.setPassportPageState({ passportPageState: PASSPORT_PAGE_STATES.READ_ONLY }));
    }
  } catch (error) {
    if (error.response.data.type === 'PASSPORT_ON_MODERATION') {
      yield put(goBack());
      yield put(
        modalActions.openPromptModal({
          title: 'Ooops',
          btnText: 'OK',
          description: 'Your passport in moderation',
        }),
      );
    } else {
      console.error(error);
    }
  }
}

function* confirmLegalDocumentsSaga() {
  try {
    yield profileApi.confirmLegalDocuments();
    yield put(goBack());
  } catch (error) {
    console.error('confirmLegalDocumentsSaga error', error);
  }
}

function* updateConsentProcessingDateSaga({ payload: { consent_processing_data } }) {
  try {
    yield profileApi.updateConsentProcessingData(consent_processing_data);
    if (consent_processing_data === CONSENT_STATUSES.REMIND) {
      yield call(getUserInfoSaga);
    }
  } catch (e) {
    console.error(e);
  }
}

function* getBAStatisticsSaga() {
  try {
    const { data } = yield profileApi.getBAStatistics();

    yield put(profileActions.setBAStatistics(data));
  } catch (error) {
    console.error(error);
  }
}

function* sagas() {
  yield all([
    takeLatest(PROFILE_ACTION_TYPES.GET_USER_INFO, getUserInfoSaga),
    takeLatest(PROFILE_ACTION_TYPES.GET_RATING, getRatingSaga),
    takeLatest(PROFILE_ACTION_TYPES.GET_EARNED, getEarnedSaga),
    takeLatest(PROFILE_ACTION_TYPES.GO_TO_SETTINGS, goToSettingsSaga),
    takeLatest(PROFILE_ACTION_TYPES.CHANGE_USER_INFO, changeUserInfoSaga),
    takeLatest(profileActions.redirectToBSLimit, redirectToBSLimitPageSaga),
    takeLatest(profileActions.uploadPassportPhoto, uploadPassportPhotoSaga),
    takeLatest(profileActions.uploadPassportData, uploadPassportDataSagas),
    takeLatest(profileActions.getPassportData, getPassportDataSaga),
    takeLatest(profileActions.confirmLegalDocuments, confirmLegalDocumentsSaga),
    takeLatest(profileActions.updateConsentProcessingData.type, updateConsentProcessingDateSaga),
    takeLatest(profileActions.getBAStatistics, getBAStatisticsSaga),
    takeLatest(profileActions.updateMonthlyValidation, updateMonthlyValidationSaga),
  ]);
}

export const profileSagas = sagas;

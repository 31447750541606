/*
   Moves cursor between inputs when updated because every symbol is a separate input
*/

export const handleKeyUp = (e, maxLength) => {
  const backspaceCode = 8;
  if (e.keyCode === backspaceCode && e.target.value.length === 0) {
    let prev = e.target.previousElementSibling;
    while (prev) {
      if (prev === null) break;
      if (prev.tagName.toLowerCase() === 'input') {
        prev.focus();
        break;
      }
      prev = prev.previousElementSibling;
    }
  } else if (e.target.value.length === maxLength) {
    let next = e.target.nextElementSibling;

    while (next) {
      if (next === null) break;
      if (next.tagName.toLowerCase() === 'input') {
        next.focus();
        break;
      }
      next = next.nextElementSibling;
    }
  }
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import '../profileSettings.scss';

import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { authActions } from '../../../redux/auth/authSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const ProfileSettingsBottomAreaBelarus = () => {
  const { formatMessage } = useInternationalization();

  const dispatch = useDispatch();

  useEffect(() => {
    analyticHelper.editProfileOpen();
  }, []);

  const handleLogout = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Do you want to log out?',
        description: 'Tap “Yes” to continue',
        btnText: 'Yes',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.logout());
        },
      }),
    );
  };

  const removeAccount = () => {
    dispatch(
      modalActions.openPromptModal({
        title: 'Are you sure?',
        description: 'Removing your account is irrevocable. You can no longer recover your data',
        btnText: 'Proceed',
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          dispatch(authActions.removeAccount());
        },
      }),
    );
  };

  return (
    <div className="profile-settings-page__content-invite-block">
      <div className="logout-delete-account-block">
        <p className="logout-delete-account-block-caption" onClick={handleLogout}>
          {formatMessage('Log out')}
        </p>
        <p className="logout-delete-account-block-caption" onClick={removeAccount}>
          {formatMessage('Remove account')}
        </p>
      </div>
    </div>
  );
};

export const AvatarIcon = (props) => (
  <svg width="120" height="120" viewBox="0 0 124 124" fill="white" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62 122C95.1371 122 122 95.1371 122 62C122 28.8629 95.1371 2 62 2C28.8629 2 2 28.8629 2 62C2 95.1371 28.8629 122 62 122Z"
      stroke={props.stroke || 'black'}
      strokeWidth="3"
    />
    <path
      d="M22 106C22 89.4128 35.4315 79 52 79"
      stroke={props.stroke || 'black'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M102 106C102 89.4128 88.5685 79 72 79"
      stroke={props.stroke || 'black'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M62.1538 70.3077C74.3891 70.3077 84.3077 60.3891 84.3077 48.1538C84.3077 35.9186 74.3891 26 62.1538 26C49.9186 26 40 35.9186 40 48.1538C40 60.3891 49.9186 70.3077 62.1538 70.3077Z"
      stroke={props.stroke || 'black'}
      strokeWidth="3"
    />
    <path d="M99.5 74V57" stroke={props.stroke || 'black'} strokeWidth="3" strokeLinecap="round" />
    <path d="M108 65.5H91" stroke={props.stroke || 'black'} strokeWidth="3" strokeLinecap="round" />
  </svg>
);

import { Controller, useFormContext } from 'react-hook-form';
import { useMemo } from 'react';
import moment from 'moment';

import shippingData from '../../const/mybrand-az/shippingData.json';
import { FieldType, OrderDetailsFormData } from './OrderDetails.types';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { CommonSelect } from '../../components/common/Select';
import { CommonInput } from '../../components/common/Input';
import OrderPickupPointDetails from '../../components/OrderPickupPointDetails/OrderPickupPointDetails';
import { getCityDataByName } from '../../helpers/bonusShopHelpers';
import { ISelectOption } from '../../types/ISelectOption';

interface OrderDetailsFieldsPhysicalProps {
  timeOptions: ISelectOption[];
}

const dateMin = moment().format('YYYY-MM-DD');
const dateMax = moment().add(2, 'M').format('YYYY-MM-DD');

const OrderDetailsFieldsPhysical = ({ timeOptions }: OrderDetailsFieldsPhysicalProps) => {
  const { control, watch } = useFormContext<OrderDetailsFormData>();
  const { t } = useInternationalization();

  const fields: FieldType[] = useMemo(() => {
    return [{ name: 'zip' }, { name: 'street' }, { name: 'house' }, { name: 'apartment' }];
  }, []);

  const cityOptions: ISelectOption[] = useMemo(
    () =>
      shippingData.map(({ city_name }) => ({
        id: city_name,
        name: city_name,
        value: city_name,
      })),
    [],
  );

  const city = watch('city');
  const cityData = getCityDataByName(city);
  const isDeliveryAvailable = !!cityData?.delivery;

  return (
    <>
      <Controller
        control={control}
        name="city"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <CommonSelect
            meta={{ touched: true, error: error && t(error?.message) }}
            input={{ value, onChange }}
            placeholder={t('bonus_shop.order_details.form_fields.city')}
            options={cityOptions}
          />
        )}
      />

      {city && (
        <>
          <Controller
            control={control}
            name="phone"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CommonInput
                meta={{ touched: true, error: error && t(error?.message) }}
                input={{ value, type: 'tel', inputmode: 'tel', onChange }}
                placeholder={t(`bonus_shop.order_details.form_fields.phone`)}
              />
            )}
          />
        </>
      )}

      {isDeliveryAvailable ? (
        <>
          <Controller
            control={control}
            name="delivery_date"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CommonInput
                meta={{ touched: true, error: error && t(error?.message) }}
                input={{ value, onChange, type: 'date', min: dateMin, max: dateMax }}
                placeholder={t(`bonus_shop.order_details.form_fields.date`)}
              />
            )}
          />
          <Controller
            control={control}
            name="delivery_time"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <CommonSelect
                meta={{ touched: true, error: error && t(error?.message) }}
                input={{ value, onChange }}
                placeholder={t(`bonus_shop.order_details.form_fields.time`)}
                options={timeOptions}
              />
            )}
          />
          {fields.map(({ name, ...rest }) => (
            <Controller
              key={name}
              control={control}
              name={name}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <CommonInput
                  meta={{ error: error && t(error?.message), touched: true }}
                  input={{ value, onChange, ...rest }}
                  placeholder={t(`bonus_shop.order_details.form_fields.${name}`)}
                />
              )}
            />
          ))}
        </>
      ) : (
        <OrderPickupPointDetails
          orderDetails={{
            city,
            pickup_point_contact: cityData?.contact_name!,
            pickup_point_phone: cityData?.phone!,
            pickup_point_url: cityData?.map_link!,
          }}
          className="pickupPointDetails"
        />
      )}
    </>
  );
};

export default OrderDetailsFieldsPhysical;

export const HomeBlackIcon = (props) => {
  return (
    <svg {...props} width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 19.4995V9.4995C22 9.3615 21.943 9.2305 21.844 9.1365L12.344 2.1365C12.152 1.9545 11.849 1.9545 11.656
        2.1365L2.156 9.1365C2.057 9.2305 2 9.3615 2 9.4995V19.4995C2 19.7755 2.224 19.9995 2.5 19.9995H9.5C9.776 19.9995
        10 19.7755 10 19.4995V13.9995H14V19.4995C14 19.7755 14.224 19.9995 14.5 19.9995H21.5C21.776 19.9995 22 19.7755 22 19.4995Z"
        fill="black"
      />
    </svg>
  );
};

import { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getStepDailyRewards } from '../../../redux/dailyRewards/dailyRewardsSelectors';
import { DailyRewardsComplete } from '../../../icons/daily-rewards-complete-icon';
import { DailyRewardsHidden } from '../../../icons/daily-rewards-hidden-icon';
import { DailyRewardsSuperPrize } from '../../../icons/daily-rewards-super-prize';
import { dailyRewardsActions } from '../../../redux/dailyRewards/dailyRewardsSlice';
import { DAILY_REWARDS_COMMON_DAYS, DAILY_REWARDS_LAST_DAY } from '../../../const/dailyRewardsConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const DailyRewardsComponent = (props) => {
  const dailyRewardsStep = useSelector(getStepDailyRewards);
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const [isClicked, setIsClicked] = useState(false);

  const nextStepCallback = useCallback(() => {
    if (isClicked) {
      return;
    }
    dispatch(dailyRewardsActions.resultDailyRewards());
    setIsClicked(true);
  }, [dispatch, isClicked]);

  const takePrizesContent = (number) => {
    if (number > dailyRewardsStep) {
      return (
        <div key={number} className="daily-rewards__start-page__prizes daily-rewards__start-page__prizes-hidden">
          <div className="daily-rewards__start-page__prizes-hidden-content">
            <DailyRewardsHidden />
          </div>
        </div>
      );
    }
    if (number === dailyRewardsStep) {
      return (
        <div key={number} className="daily-rewards__start-page__prizes daily-rewards__start-page__prizes-active">
          <div className="daily-rewards__start-page__prizes-active-content" onClick={() => nextStepCallback()}>
            {formatMessage('Tap')}
          </div>
        </div>
      );
    }
    if (number < dailyRewardsStep) {
      return (
        <div key={number} className="daily-rewards__start-page__prizes daily-rewards__start-page__prizes-complete">
          <div className="daily-rewards__start-page__prizes-complete-content">
            <DailyRewardsComplete />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="daily-rewards__start-page">
      <div>
        <div className="daily-rewards__start-page__header">{formatMessage('Daily reward')}</div>
        <div className="daily-rewards__start-page__content">
          {formatMessage(
            'Come back and play at least 1 quiz every day to collect your daily rewards! Play quizzes 7 days in a row to get the super prize!',
          )}
        </div>
        <div className="daily-rewards__start-page__prizes__wrapper">
          {DAILY_REWARDS_COMMON_DAYS.map((number) => takePrizesContent(number))}
        </div>
      </div>
      <div>
        {dailyRewardsStep === DAILY_REWARDS_LAST_DAY ? (
          <div className="daily-rewards__start-page__prizes__super-prize__icon">
            <DailyRewardsSuperPrize onClick={() => nextStepCallback()} />
          </div>
        ) : (
          <div className="daily-rewards__start-page__prizes__super-prize__icon daily-rewards__start-page__prizes__super-prize__icon-hidden">
            <DailyRewardsSuperPrize />
          </div>
        )}
        <div className="daily-rewards__start-page__prizes__super-prize__text">{formatMessage('Super Prize')}</div>
      </div>
    </div>
  );
};

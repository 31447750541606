import { memo, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './moneyForThePhone.scss';

import { Balance } from '../../../components/common/Balance';
import { Header } from '../../../components/common/Header';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { Button } from '../../../components/common/Button';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { getUserInfoSelector, getUserBalanceSelector } from '../../../redux/profile/profileSelectors';
import {
  getTransfersSelector,
  getFirstViewSelector,
  getHeaderImageSelector,
} from '../../../redux/bonusShop/bonusShopSelectors';
import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { QuestionMarkIcon } from '../../../icons/question-mark-icon';
import { TransferTutorial } from '../../transferTutorial/transferTutorial';
import { CashoutHistory } from '../cashoutHistory/cashoutHistory';
import { FAQ_ARTICLE_ID } from '../../../redux/knowledgeBank/knowledgeBankConstants';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { Routes } from '../../../const';
import { checkUserRole } from '../../../helpers/checkUserRole';
import { UserRoles } from '../../../enums';

export const MoneyForThePhone = memo(({ history }) => {
  const { formatMessage } = useInternationalization();
  const { phone } = useSelector(getUserInfoSelector);
  const coins = useSelector(getUserBalanceSelector);
  const headerImage = useSelector(getHeaderImageSelector);
  const transfers = useSelector(getTransfersSelector);
  const notFirstView = useSelector(getFirstViewSelector);
  const notFirstViewFromLocal = localStorage.getItem('transferToMobileNotFirstView');
  const theme = useSelector((state) => state.service.theme);
  const dispatch = useDispatch();

  const rate = 1 / 110;
  const isModalShown = checkUserRole(UserRoles.BA, UserRoles.JTI_TEAM, UserRoles.RSM);

  const handlePreviousStep = () => {
    history.push(Routes.BonusShopRoute);
  };

  useEffect(() => {
    return () => dispatch(modalActions.closeModal());
  }, [dispatch]);

  useEffect(() => {
    dispatch(bonusShopActions.setTutorialPassed(notFirstViewFromLocal));
  }, [notFirstViewFromLocal, dispatch]);

  const openHelpArticle = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jti-club.az/articles/Bonus_Shop_tutorial_AZE.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  const [showTutorial, setShowTutorial] = useState(false);

  useEffect(() => {
    if (showTutorial) {
      if (
        document.getElementsByClassName(`theme${theme.id}`) &&
        document.getElementsByClassName(`theme${theme.id}`).length &&
        !notFirstView
      ) {
        document.getElementsByClassName(`theme${theme.id}`)[0].style.overflow = 'hidden';
        return () => {
          if (
            document.getElementsByClassName(`theme${theme.id}`) &&
            document.getElementsByClassName(`theme${theme.id}`).length
          ) {
            document.getElementsByClassName(`theme${theme.id}`)[0].style.overflow = 'auto';
          }
        };
      }
    }
  }, [theme.id, showTutorial, notFirstView]);

  const openTutorial = useCallback(() => {
    if (!notFirstView && transfers && transfers.length === 0) {
      setShowTutorial(true);
    } else if (notFirstView || (transfers && transfers.length !== 0)) {
      setShowTutorial(false);
    }
  }, [notFirstView, transfers]);

  const closeTutorial = useCallback(() => {
    setShowTutorial(false);
    dispatch(bonusShopActions.closeTutorial());
  }, [dispatch]);

  useEffect(() => {
    dispatch(bonusShopActions.getTransfers({ isKibritTransfers: true }));
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(openTutorial, 500);
    return () => clearTimeout(timer);
  }, [notFirstView, openTutorial]);

  useEffect(() => {
    if (notFirstView) {
      closeTutorial();
    }
  }, [notFirstView, closeTutorial]);

  const openTransferWindow = () => {
    if (isModalShown) {
      dispatch(
        modalActions.openPromptModal({
          description: 'Not available for JTI representatives.',
          btnText: 'OK',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else if (coins < 550) {
      dispatch(
        modalActions.openPromptModal({
          title: 'Ooops',
          description: 'You do not have enough coins for this order',
          btnText: 'OK transfer',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else {
      dispatch(
        modalActions.openCashoutModal({
          title: 'Select the amount',
          btnText: 'Move forward',
          symbol: '₼',
          moneyLimit: 100,
          rate: rate,
          step: 550,
          makeTransfer: (params) => {
            dispatch(bonusShopActions.makeTransfer(params));
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    }
  };

  return (
    <div className="relative-container">
      {showTutorial && <TransferTutorial closeTutorial={closeTutorial} />}
      <div className="money-for-the-phone-page">
        <Header goBackCallback={handlePreviousStep} hasBackButton customClass="money-for-the-phone-page__header">
          {formatMessage('Money for the phone')}
        </Header>
        <Balance />
        <div className="money-for-the-phone-page__content">
          <div
            className="money-for-the-phone-page__content-image-container"
            style={{
              background: `url(${headerImage})`,
              backgroundSize: 'cover',
            }}
          >
            <div className="money-for-the-phone-page__content-image-container-phone-container">
              <QuestionMarkIcon
                className="money-for-the-phone-page__content-image-container-faq"
                onClick={openHelpArticle}
              />
              <p className="money-for-the-phone-page__content-image-container-phone-container-caption">
                {formatMessage('Your phone number:')}
              </p>
              <p className="money-for-the-phone-page__content-image-container-phone-container-phone">{phone}</p>
            </div>
          </div>
          <CashoutHistory showTutorial={showTutorial} symbol="₼" rate={rate} specificTransfers={transfers} />
        </div>
        <div className="money-for-the-phone-page__content-btn-container">
          <Button customClass={'money-for-the-phone-page__content-btn-container-button'} onClick={openTransferWindow}>
            {formatMessage('Cashout')}
          </Button>
        </div>
        <DisclaimerFooter withoutNavigation />
      </div>
    </div>
  );
});

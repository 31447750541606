import { createSelector } from 'reselect';

export const getAchievementsSelector = createSelector(
  [(state) => state.achievements.achievements],
  (achievements) => achievements,
);

export const getSelectedAchievementSelector = createSelector(
  [(state) => state.achievements.selectedAchievement],
  (selectedAchievement) => selectedAchievement,
);

export const getIsFetching = createSelector([(state) => state.achievements.isFetching], (isFetching) => isFetching);

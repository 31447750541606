import axios from 'axios';
import { API_URL } from './baseApi';

export const boostersApi = {
  getBoostersForQuiz: async (pack_id, solo_mode = false) => {
    return await axios.get(`${API_URL}/v1/boosters?pack_id=${pack_id}${solo_mode ? `&solo_mode=${solo_mode}` : ''}`);
  },
  useBooster: async (booster_id, pack_id, question_number) => {
    return await axios.post(`${API_URL}/v1/boosters/use`, { booster_id, pack_id, question_number });
  },
  getBoostersShop: async () => {
    return await axios.get(`${API_URL}/v1/boosters/shop`);
  },
  buyBooster: async (booster_id, amount) => {
    return await axios.post(`${API_URL}/v1/boosters/buy`, { booster_id, amount });
  },
  exchangeLeaf: async (leaf) => {
    return await axios.post(`${API_URL}/v1/boosters/exchange`, { leaf });
  },
};

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useTimeLeft } from '../../../hooks/useTimeLeft';
import { COMPONENT_TYPES } from '../../../const/mainScreenConsts';
import { Routes } from '../../../const';
import { analyticHelper, MAIN_SCREEN_ACTIVITY_LABELS } from '../../../helpers/analyticHelper';
import { BoxRightArrow } from '../../../icons';
import { Payload } from './fadingBlockTypes';
import { mysteryShopperActions } from '../../../redux/mysteryShopper/mysteryShopperSlice';

import styles from './FadingComponent.module.scss';

type Props = {
  type: string;
  image: string;
  payload: Payload;
  has_more_than_one_ms: boolean;
};

const FadingComponent = ({
  type,
  image,
  payload: { end_date, mystery_shopper_id, brand },
  has_more_than_one_ms,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const timeLeft = useTimeLeft(end_date);

  const isMysteryShopper = type === COMPONENT_TYPES.MYSTERY_SHOPPER;
  const showTimeLeft = isMysteryShopper && !has_more_than_one_ms;

  const onBlockClick = () => {
    switch (type) {
      case COMPONENT_TYPES.QUICK_TIP:
        history.push(Routes.QuickTips);
        analyticHelper.onMainScreenModuleClick(MAIN_SCREEN_ACTIVITY_LABELS.QUICK_TIPS);
        return;
      case COMPONENT_TYPES.MYSTERY_SHOPPER:
        dispatch(mysteryShopperActions.setMysteryShopperId({ mysteryShopperId: mystery_shopper_id }));
        if (has_more_than_one_ms) {
          history.push(Routes.MysteryShopperList);
        } else {
          dispatch(mysteryShopperActions.getMysteryShopperQuestions({ mysteryShopperId: mystery_shopper_id }));
        }
        return;
      default:
        return null;
    }
  };

  return (
    <div
      className={styles.fadingBlock}
      style={{
        backgroundImage: `url(${image})`,
      }}
      onClick={onBlockClick}
    >
      <span style={{ color: brand.text_color }} className={styles.title}>
        {brand.name}
      </span>
      {showTimeLeft && <span className={styles.timeLeft}>{timeLeft}</span>}
      <BoxRightArrow className={styles.arrow} fillColor={'white'} />
    </div>
  );
};

export default FadingComponent;

import { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import './tutorialSixthStep.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { QuestionMarkIcon } from '../../../icons/question-mark-icon';
import { FullArrowIcon } from '../../../icons/full-arrow-icon';
import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';

export const TutorialSixthStep = memo(({ closeTutorial }) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();

  const [isButtonClicked, setIsButtonClicked] = useState(false);

  useEffect(() => {
    let timer;
    if (isButtonClicked) {
      timer = setTimeout(() => dispatch(bonusShopActions.closeTutorial()), 150);
    }
    if (timer) {
      return () => clearTimeout(timer);
    }
  }, [isButtonClicked, dispatch]);

  const arrowAnimation = useSpring({
    right: isButtonClicked ? '120px' : '60px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      right: isButtonClicked ? '60px' : '120px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const faqAnimation = useSpring({
    opacity: isButtonClicked ? '0' : '1',
    from: {
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const contentAnimation = useSpring({
    right: isButtonClicked ? '600px' : '0px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      right: isButtonClicked ? '0px' : '-600px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const onFinishClick = () => {
    setIsButtonClicked(true);
  };

  return (
    <div className="transfer-tutorial-sixth-step">
      <div className="transfer-tutorial-sixth-step__faq-block">
        <animated.div style={faqAnimation} className="transfer-tutorial-sixth-step__faq-block-icon">
          <QuestionMarkIcon />
        </animated.div>
        <animated.div style={arrowAnimation} className="arrow">
          <FullArrowIcon />
        </animated.div>
      </div>
      <animated.div style={contentAnimation} className="transfer-tutorial-sixth-step__content">
        <div className="transfer-tutorial-sixth-step__description">
          <p className="transfer-tutorial-sixth-step__description-step-number">5/5</p>
          <p className="transfer-tutorial-sixth-step__description-title">{formatMessage('Questions')}</p>
          <p className="transfer-tutorial-sixth-step__description-text">
            {formatMessage(
              'More information about money cash out you could find pressing “?” button. Hope you find this tutorial helpful!',
            )}
          </p>
        </div>

        <div className="transfer-tutorial-first-step__btn-container">
          <Button withoutTheme customClass={'finish-button'} onClick={onFinishClick}>
            {formatMessage('Finish')}
          </Button>
        </div>
      </animated.div>
    </div>
  );
});

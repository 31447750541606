import { useIntl } from 'react-intl';
import en from '../translations/en.json';

export const useInternationalization = () => {
  const { formatMessage: intlFormatMessage } = useIntl();

  const formatMessage = (id, values) => {
    return intlFormatMessage({ id: id, defaultMessage: en[id] }, { ...values });
  };

  return { formatMessage, t: formatMessage };
};

// @t-s-nocheck
import React, { useEffect, useState } from 'react';
import { bonusShopActions } from '../../redux/bonusShop/bonusShopSlice';
import { useDispatch, useSelector } from 'react-redux';
import { bonusShopApi } from '../../api/bonusShopApi';

import styles from './VouchersCarousel.module.scss';
import { Header } from '../common/Header';
import { Balance } from '../common/Balance';
import { DisclaimerFooter } from '../disclaimerFooter/disclaimerFooter';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { modalActions } from '../containers/Modal/modalSlice';
import VouchersCarouselItem from './VouchersCarouselItem';
import { Voucher } from '../../types/Voucher';
import { NavigationMenu } from '../navigationMenu';
import { LoadingPage } from '../common/Spinner/LoadingPage';
import { getCurrentCatergory, getIsFetching } from '../../redux/bonusShop/bonusShopSelectors';

interface VouchersCarouselProps {
  selectedCategoryId: string;
}

const VouchersCarousel = ({ selectedCategoryId }: VouchersCarouselProps) => {
  const { formatMessage } = useInternationalization();
  const isFetching = useSelector(getIsFetching);
  const dispatch = useDispatch();
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const selectedCategory = useSelector(getCurrentCatergory);

  useEffect(() => {
    (async () => {
      const result = await bonusShopApi.getCategoryItems(selectedCategoryId);
      setVouchers(result);
    })();
  }, []);

  const order = (voucher: Voucher) => {
    dispatch(bonusShopActions.makeOrder({ voucherId: voucher.id }));
  };

  const handleOrderBtnClick = (voucher: Voucher) => {
    dispatch(
      modalActions.openPromptModal({
        btnText: formatMessage('Order(Confirm)'),
        title: formatMessage('Are you sure you want to order a product?'),
        description: formatMessage("Please note that after confirmation you won't be able to cancel the order."),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
          order(voucher);
        },
      }),
    );
  };

  if (isFetching) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.root}>
      <Header hasBackButton>
        <p className="bonus-shop-category-page__header-text">{selectedCategory?.name}</p>
      </Header>
      <Balance fixed={false} activePage={null} />
      <div className="gallery">
        {vouchers.map((voucher) => (
          <VouchersCarouselItem voucher={voucher} onClick={handleOrderBtnClick} />
        ))}
      </div>
      <NavigationMenu location={location} />
      <DisclaimerFooter />
    </div>
  );
};

export default VouchersCarousel;

export const KNOWLEDGE_BANK_ACTION_TYPES = {
  GET_CATEGORIES: 'knowledgeBank/getCategories',
  GET_KBBRANDS: 'knowledgeBank/getKBBrands',
  SOCKET_HANDLE: 'knowledgeBank/socketHandle',
  CHOOSE_CATEGORY: 'knowledgeBank/chooseCategory',
  CHOOSE_KBBRAND: 'knowledgeBank/chooseKBBrand',
  GET_ARTICLES: 'knowledgeBank/getArticles',
  GET_INTERACTIVE_ARTICLE: 'knowledgeBank/getInteractiveArticle',
  PASS_INTERACTIVE_ARTICLE: 'knowledgeBank/passInteractiveArticle',
  PASS_CORRECT_INTERACTIVE_ARTICLE: 'knowledgeBank/passCorrectInteractiveArticle',
  OPEN_ARTICLE: 'knowledgeBank/openArticle',
  READ_ARTICLE: 'knowledgeBank/readArticle',
  GET_SALES_TIPS: 'knowledgeBank/getSalesTips',
  GET_SALES_TIP_STEPS: 'knowledgeBank/getSalesTipSteps',
  SET_SALES_TIP_STEPS: 'knowledgeBank/getSalesTipSteps',
};

export const KNOWLEDGE_BANK_CATEGORIES_TYPES = {
  SALES_TIPS: 3,
  SALES_SIMULATORS: 4,
  NEWS: 1,
  TIPS_AND_TRICKS: 5,
  SPECIAL_QUIZ: 2,
  MINI_GAMES: 6,
  GENERAL_QUIZES: 7,
};

export const FAQ_ARTICLE_ID = 'FAQ_ARTICLE_ID';

import '@google/model-viewer';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { Routes } from '../../../../const';
import { Button } from '../../../../components/common';
import playAnimation from '../../images/play.svg';
import { BARCODE_PAGE } from '../../constants/general';
import { recognitionSelectors } from '../../../../redux/recognition';
import { Spinner } from '../../../../components/common';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { analyticHelper } from '../../../../helpers/analyticHelper';
import { getUserIdSelector } from '../../../../redux/auth/authSelectors';

import styles from './AnimationModel.module.scss';

const STATUS_FAILED = 'failed';
const STATUS_SESSION_STARTED = 'session-started';
const STATUS_NOT_PRESENTING = 'not-presenting';
const STOP_TIME_ANIMATION = 600000;

const AnimationModel = ({ setChangeComponent }) => {
  const barcodeInfo = useSelector(recognitionSelectors.getBarcodeInfoSelector);
  const isFetching = useSelector(recognitionSelectors.getIsFetching);
  const dispatch = useDispatch();
  const history = useHistory();
  const timeoutRef = useRef(null);
  const userId = useSelector(getUserIdSelector);
  const { formatMessage } = useInternationalization();

  useEffect(() => {
    const modelViewer = document.querySelector('model-viewer');
    modelViewer.addEventListener('ar-status', (event) => {
      if (event.detail.status === STATUS_SESSION_STARTED) {
        timeoutRef.current = setTimeout(() => {
          history.push(Routes.MainRoute);
        }, STOP_TIME_ANIMATION);
      }

      if (event.detail.status === STATUS_NOT_PRESENTING) {
        clearTimeout(timeoutRef.current);
      }

      if (event.detail.status === STATUS_FAILED) {
        analyticHelper.onArNotSupportedScreen(userId);

        dispatch(
          modalActions.openPromptModal({
            title: 'Oops',
            description: `Sorry, it seems like your device doesn't support AR technology`,
            btnText: 'OK',
            onButtonClick: () => {
              dispatch(modalActions.closeModal());
            },
          }),
        );
      }
    });

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const {
    pwa_animation,
    android_animation,
    background_image,
    model,
    co,
    tar_content,
    nicotine_content,
    format,
    filter_type,
    price,
    article_url,
    cuff_paper,
  } = barcodeInfo;

  return (
    <div className={styles.wrapper}>
      <model-viewer
        ios-src={pwa_animation}
        src={android_animation}
        ar
        ar-modes="webxr"
        camera-controls
        shadow-intensity="1"
        autoplay
        environment-image="neutral"
      >
        <>
          {isFetching && (
            <div slot="poster" className={styles.spinnerWrapper}>
              <div className={styles.spinnerBackGround} />
              <Spinner width={200} />
            </div>
          )}

          <span slot="ar-button" className={styles.arButton}>
            <img src={playAnimation} />
          </span>
          <div className={styles.bottomWrapper}>
            <a href={article_url} target="_blank">
              <span className={styles.infoWrapper}>
                <img className={styles.backGroundBlock} src={background_image} />
                <span className={styles.title}>
                  {model} - {price} HUF
                </span>
                <span className={styles.description}>
                  <span>
                    {formatMessage('Tar content')}: <span className={styles.strong}> {tar_content} </span>
                  </span>
                  <span>
                    {formatMessage('Format')}: <span className={styles.strong}> {format}</span>
                  </span>
                  <span>
                    {formatMessage('Nicotine content')}: <span className={styles.strong}> {nicotine_content} </span>
                  </span>
                  <span>
                    {formatMessage('Cuff paper')}: <span className={styles.strong}> {cuff_paper} </span>
                  </span>
                  <span>
                    {formatMessage('CO')}: <span className={styles.strong}> {co}</span>
                  </span>
                  <span>
                    {formatMessage('Filter type')}: <span className={styles.strong}> {filter_type}</span>
                  </span>
                  <span className={styles.weak}> mg/cigaretta </span>
                </span>
                <span className={styles.nextButton} />
              </span>
            </a>
            <Button className={styles.button} onClick={() => setChangeComponent(BARCODE_PAGE)}>
              {formatMessage('Scan another pack')}
            </Button>
          </div>
        </>
      </model-viewer>
    </div>
  );
};

export default AnimationModel;

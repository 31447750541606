import { useSelector, useDispatch } from 'react-redux';

import './EsignEmailModal.scss';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../common/Button';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalSlice';
import checkEmailImage from './e-sing_animation.gif';
import { stepListActions } from '../../containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../containers/StepList/stepListConstants';

const styles = {
  width: 343,
  height: 'calc(100% - 80px)',
};

export const EsignEmailModal = (props) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const {
    data: { onClose, onButtonClick },
  } = useSelector(getModalDataSelector);

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.authStepList,
        stepId: 1,
      }),
    );
    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.esignEmail} onClose={onClose} customStyles={styles}>
      <div className="esign-modal">
        <img className="esign-modal__image" src={checkEmailImage} alt="check-email" />
        <div>
          <div className="esign-modal__title">{formatMessage('Keep an eye on your inbox')}</div>
          <div className="esign-modal__description">{formatMessage("We'll be in touch soon")}</div>
          <Button onClick={handleButtonClick} customClass="esign-modal__btn">
            {formatMessage('OK')}
          </Button>
        </div>
      </div>
    </ModalContainer>
  );
};

export const HomeBlackIcon = (props) => {
  return (
    <svg width="40" height="40" {...props} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_122_1116)">
        <rect width="40" height="40" rx="8" fill="#ECECEC" />
        <path d="M19.5 10L8 20.437H11.45V29.7143H18.35V22.7563H20.65V29.7143H27.55V20.437H31L19.5 10Z" fill="#252525" />
      </g>
      <defs>
        <clipPath id="clip0_122_1116">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

import { memo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring';

import colors from '../../../styles/colors.scss';
import './tutorialFourthStep.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getTransfersSelector } from '../../../redux/bonusShop/bonusShopSelectors';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { formatDate } from '../../../helpers/dateFormattingWithLocalization';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { FullArrowIcon } from '../../../icons/full-arrow-icon';
import { AcceptedIcon } from '../../../icons/accepted-icon';
import { ModerationIcon } from '../../../icons/moderation-icon';
import { FailedIcon } from '../../../icons/failed-icon';

export const TutorialFourthStep = memo(() => {
  const dispatch = useDispatch();
  const transfers = useSelector(getTransfersSelector);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const { formatMessage } = useInternationalization();

  useEffect(() => {
    let timer;
    if (isButtonClicked) {
      timer = setTimeout(
        () =>
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.transferTutorialStepList,
              stepId: 5,
            }),
          ),
        150,
      );
    }
    if (timer) {
      return () => clearTimeout(timer);
    }
  }, [isButtonClicked, dispatch]);

  const arrowAnimation = useSpring({
    top: isButtonClicked ? '200px' : '120px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      top: isButtonClicked ? '120px' : '200px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const transferAnimation = useSpring({
    opacity: isButtonClicked ? '0' : '1',
    from: {
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const contentAnimation = useSpring({
    right: isButtonClicked ? '600px' : '0px',
    opacity: isButtonClicked ? '0' : '1',
    from: {
      right: isButtonClicked ? '0px' : '-600px',
      opacity: isButtonClicked ? '1' : '0',
    },
    config: { duration: 150 },
  });

  const getIcon = (status) => {
    switch (status) {
      case 1:
        return <AcceptedIcon className="money-for-the-phone-page__content-history-list-item-status-icon" />;
      case 2:
        return <FailedIcon className="money-for-the-phone-page__content-history-list-item-status-icon" />;
      case 3:
        return <ModerationIcon className="money-for-the-phone-page__content-history-list-item-status-icon" />;
      default:
        return <AcceptedIcon className="money-for-the-phone-page__content-history-list-item-status-icon" />;
    }
  };

  const getColor = (status) => {
    switch (status) {
      case 1:
        return colors.successColor;
      case 2:
        return colors.failedColor;
      case 3:
        return colors.moderationColor;
      default:
        return colors.successColor;
    }
  };

  const onArrowClick = () => {
    setIsButtonClicked(true);
  };

  return (
    <div className="transfer-tutorial-fourth-step">
      <animated.div style={transferAnimation} className="transfer-tutorial-fourth-step__transfer-block">
        <p className="transfer-tutorial-fourth-step__transfer-block-caption">{formatMessage('Operations:')}</p>
        {transfers && transfers.length && (
          <>
            <div className="transfer-tutorial-fourth-step__transfer-block-list-item" key={transfers[0].id}>
              <div className="transfer-tutorial-fourth-step__transfer-block-list-item-info">
                <p className="transfer-tutorial-fourth-step__transfer-block-list-item-info-amount">
                  ₼{transfers[0].amount}
                </p>
                <p className="transfer-tutorial-fourth-step__transfer-block-list-item-info-date">
                  {formatDate(new Date(transfers[0].created_at), 'dd.MM.yy')}
                </p>
              </div>
              <div
                className="transfer-tutorial-fourth-step__transfer-block-list-item-status"
                style={{ color: getColor(transfers[0].status) }}
              >
                {formatMessage(`${transfers[0].status} status`)} {getIcon(transfers[0].status)}
              </div>
            </div>
          </>
        )}
        <animated.div style={arrowAnimation} className="arrow">
          <FullArrowIcon />
        </animated.div>
      </animated.div>
      <animated.div style={contentAnimation} className="transfer-tutorial-fourth-step__content">
        <div className="transfer-tutorial-fourth-step__description">
          <p className="transfer-tutorial-fourth-step__description-step-number">3/5</p>
          <p className="transfer-tutorial-fourth-step__description-title">
            {formatMessage('Transaction is completed')}
          </p>
          <p className="transfer-tutorial-fourth-step__description-text">
            {formatMessage('Money will be added to your balance soon')}
          </p>
        </div>

        <ArrowButton customClass="transfer-tutorial-second-step__arrow-button" withoutTheme onClick={onArrowClick} />
      </animated.div>
    </div>
  );
});

import { all } from '@redux-saga/core/effects';
import { put, select, takeLatest, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { Routes } from '../../const';
import { quickTipsActions } from './quickTipsSlice';
import { quickTipsApi } from '../../api/quickTipsApi';
import { quickTipsSelectors } from './quickTipsSelectors';

function* getQuickTip() {
  yield put(quickTipsActions.setIsFetching(true));
  try {
    const { data } = yield quickTipsApi.getQuickTip();
    yield put(quickTipsActions.setQuickTip({ ...data }));
  } catch (e) {
    yield put(push(Routes.MainRoute));
    console.error(e);
  } finally {
    yield put(quickTipsActions.setIsFetching(false));
  }
}

function* passQuickTip() {
  const currentAnswer: string = yield select(quickTipsSelectors.getCurrentAnswer);
  const { id } = yield select(quickTipsSelectors.getQuickTip);

  try {
    const { data } = yield quickTipsApi.passQuickTip(id, currentAnswer);
    yield put(quickTipsActions.setQuickTip({ ...data.quick_tip }));
    yield put(quickTipsActions.setResult({ ...data }));
    yield delay(1500);
    yield put(push(Routes.QuickTipsResult));
  } catch (e) {
    console.error(e);
  }
}

export function* quickTipsSagas() {
  yield all([
    takeLatest(quickTipsActions.passQuickTip, passQuickTip),
    takeLatest(quickTipsActions.getQuickTip, getQuickTip),
  ]);
}

export const DailyRewardsSuperPrize = (props) => (
  <svg width="68" height="76" viewBox="0 0 68 76" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      opacity="0.5"
      d="M45.2192 0.5C38.0059 0.5 33.4927 17.1142 31.8725 24.2676C31.6797 25.0752 31.9497 25.9213 32.5283 26.4982C32.9912 26.9597 33.6084 27.2289 34.2641 27.2289C34.457 27.2289 34.6113 27.2289 34.8042 27.152C41.979 25.5367 58.6431 21.037 58.6431 13.8452C58.6431 6.49959 52.6255 0.5 45.2192 0.5ZM42.8662 11.23C45.605 11.23 47.8809 13.4606 47.9194 15.9989C47.418 17.0758 43.4834 18.8449 38.8545 20.2294C40.2046 15.6143 41.9404 11.73 42.8662 11.23Z"
      fill="url(#paint0_linear)"
    />
    <path
      opacity="0.5"
      d="M36.62 24.2676C35.0384 17.1142 30.5252 0.5 23.2732 0.5C15.9054 0.5 9.88782 6.49959 9.88782 13.8837C9.88782 21.0755 26.552 25.5752 33.7269 27.1905C33.9197 27.2289 34.074 27.2674 34.2669 27.2674C34.9227 27.2674 35.5399 26.9982 36.0028 26.5367C36.5814 25.9213 36.8128 25.0752 36.62 24.2676ZM20.6501 16.2297C20.6501 13.4991 22.8874 11.23 25.4333 11.1916C26.5134 11.6915 28.2879 15.6143 29.6765 20.2294C25.0476 18.8449 21.113 17.1142 20.6501 16.2297Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M8.93332 73.2578L8.93319 73.2564L4.77085 30.4477C4.51368 27.8028 6.59235 25.5122 9.24973 25.5122H58.9619C61.6192 25.5122 63.6979 27.8028 63.4407 30.4477L59.2784 73.2564L59.2783 73.2578C59.1864 74.2305 58.3568 74.9999 57.3313 74.9999H10.8803C9.85477 74.9999 9.02521 74.2305 8.93332 73.2578Z"
      fill="url(#paint2_linear)"
      stroke="#01C1B6"
    />
    <path
      d="M1.17321 26.6177L1.17319 26.6175C1.08212 25.5084 1.96056 24.5657 3.10742 24.5657H65.5428C66.6897 24.5657 67.5681 25.5084 67.4771 26.6175L67.477 26.6177L66.8094 34.7976C66.6188 37.1331 64.6677 38.9315 62.3243 38.9315H6.32592C3.98258 38.9315 2.03146 37.1331 1.84084 34.7976L1.17321 26.6177Z"
      fill="url(#paint3_linear)"
      stroke="#01C1B6"
    />
    <path
      d="M27.3057 24.8052C27.3057 20.7671 30.5845 17.498 34.6348 17.498C38.6852 17.498 41.964 20.7671 41.964 24.8052H27.3057Z"
      fill="#85E5D5"
    />
    <path d="M41.5739 25.0122H27.3057V75.4999H41.5739V25.0122Z" fill="url(#paint4_linear)" />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="31.8211"
        y1="13.8759"
        x2="58.6418"
        y2="13.8759"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C2EBED" />
        <stop offset="0.311" stop-color="#2BD0C3" />
        <stop offset="1" stop-color="#00B5C0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="9.89808"
        y1="13.8952"
        x2="36.6854"
        y2="13.8952"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C2EBED" />
        <stop offset="0.311" stop-color="#2BD0C3" />
        <stop offset="1" stop-color="#00B5C0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="34.1058"
        y1="25.0122"
        x2="34.1058"
        y2="75.4999"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.494792" stop-color="white" />
        <stop offset="1" stop-color="#E6FFFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="34.3251"
        y1="24.0657"
        x2="34.3251"
        y2="39.4315"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.494792" stop-color="white" />
        <stop offset="1" stop-color="#E6FFFF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="36.4054"
        y1="26.2444"
        x2="12.2009"
        y2="59.5363"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#C2EBED" />
        <stop offset="0.311" stop-color="#2BD0C3" />
        <stop offset="1" stop-color="#00B5C0" />
      </linearGradient>
    </defs>
  </svg>
);

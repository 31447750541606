import { useCallback, useEffect } from 'react';

import { Button } from '../../../components/common/Button';
import { getSurveyReward } from '../../../redux/survey/surveySelectors';
import { Routes } from '../../../const/routes';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { useSelector } from 'react-redux';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { getWordsFormat } from '../../../helpers/getWordsFormat';

export const SurveyResultComponent = (props) => {
  const { history } = props;
  const { formatMessage } = useInternationalization();
  const coins = useSelector(getSurveyReward);
  const onBtnBack = useCallback(() => {
    return history.replace(Routes.KnowledgeBankRoute);
  }, [history]);

  useEffect(() => {
    analyticHelper.surveyResult();
  }, []);

  return (
    <div className="survey-page__final-screen">
      <div className="survey-page__survey-headline">
        <div className="survey-page__survey-text">{formatMessage('Survey')}</div>
      </div>
      <div className="survey-page__reward-block">{`${coins} ${formatMessage(getWordsFormat(coins, 'points'))}`}</div>
      <div className="survey-page__done-block">
        <div className="survey-page__done-text">{formatMessage('Great')}</div>
        <Button customClass="survey-page__result-btn" withoutTheme={true} onClick={() => onBtnBack()}>
          {formatMessage('OK')}
        </Button>
      </div>
    </div>
  );
};

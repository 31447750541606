import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import moment from 'moment-timezone';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Header } from '../../../components/common/Header';
import { idCardSelectors } from '../../../redux/idCard/idCardSelectors';
import { ContentSpinner } from '../../../components/common/Spinner/ContentSpinner';
import { Input } from '../../../components/common/Input';
import { RootState } from '../../../redux/store';
import { Button } from '../../../components/common/Button';
import { Routes } from '../../../const';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';
import { appTimeZone } from '../../../const/appConstants';

import styles from './IdCardInfo.module.scss';

const IdCardInfoComponent = () => {
  const history = useHistory();
  const { formatMessage } = useInternationalization();
  const isFetching = useSelector(idCardSelectors.selectIsFetching);

  const onGoBackClick = () => {
    history.goBack();
  };

  const onButtonClick = () => {
    history.push(Routes.IdCard);
  };

  return (
    <>
      {isFetching ? (
        <ContentSpinner />
      ) : (
        <div className={classnames(styles.container, 'idCard')}>
          <Header hasBackButton goBackCallback={onGoBackClick}>
            <span className={styles.title}>{formatMessage('ID card')}</span>
          </Header>
          <form>
            <div className={styles.content}>
              <span className={styles.caption}>{formatMessage('Your data:')}</span>
              <span className={styles.label}>{formatMessage('PIN code:')}</span>
              <Input name="number" customClass="profile-settings-page__input" disabled />
              <span className={styles.label}>{formatMessage('Name')}</span>
              <Input name="first_name" customClass="profile-settings-page__input" disabled />
              <span className={styles.label}>{formatMessage('Surname')}</span>
              <Input name="last_name" customClass="profile-settings-page__input" disabled />
              <span className={styles.label}>{formatMessage('Date of birth')}</span>
              <Input name="date_of_birth" customClass="profile-settings-page__input" disabled />
            </div>
            <div className={styles.wrapper}>
              <span className={styles.title}>{formatMessage('Found a mistake?')}</span>
              <span className={styles.description}>{formatMessage('To change your data tap the button below')}</span>
              <Button withoutTheme customClass={styles.button} onClick={onButtonClick}>
                {formatMessage('Update data')}
              </Button>
            </div>
          </form>
          <DisclaimerFooter withoutNavigation />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { idCard } = state;

  return {
    initialValues: {
      first_name: idCard?.idCardData?.first_name,
      last_name: idCard?.idCardData?.last_name,
      number: idCard?.idCardData?.number,
      date_of_birth: moment(idCard?.idCardData?.date_of_birth).tz(appTimeZone).format('DD.MM.YYYY'),
    },
  };
};

export const form = reduxForm({
  form: 'idCardInfo',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(IdCardInfoComponent);

export const IdCardInfo = connect(mapStateToProps)(form);

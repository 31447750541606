export const ChatBlackIcon = (props) => {
  return (
    <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M22.2268 3H1.77324C0.792646 3 0 3.81755 0 4.82895V18.171C0 19.1825 0.792646 20 1.77324 20H22.2268C23.2074 20 24 19.1825 24 18.171V4.82895C24 3.81755 23.1992 3 22.2268 3ZM22.0225 4.09569L12.5271 13.527C12.38 13.6703 12.1839 13.7462 11.9959 13.7462C11.7998 13.7462 11.6118 13.6787 11.4648 13.5354L1.77324 4.09569H22.0225ZM1.06231 4.90481L7.62411 11.3019L1.06231 17.9435V4.90481ZM22.9377 17.9772L16.3269 11.2766L22.9377 4.70253V17.9772Z"
        fill="black"
      />
    </svg>
  );
};

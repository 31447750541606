import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './singleModePage.scss';

import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { SINGLE_MODE_STEPS } from '../../../redux/singleMode/singleModeConstants';
import { SingleModeRules } from './singleModeRules';
import { SingleModeGame } from './singleModeGame';
import { SingleModeStages } from './singleModeStages';
import { isSingleModeStartedSelector } from '../../../redux/singleMode/singleModeSelectors.js';
import { singleModeActions } from '../../../redux/singleMode/singleModeSlice.js';

const SingleModePageComponent = ({ history }) => {
  const dispatch = useDispatch();
  const goBackCallback = useCallback(() => {
    history.replace('/main');
  }, [history]);

  const isSingleModeStarted = useSelector(isSingleModeStartedSelector);

  useEffect(() => {
    if (!isSingleModeStarted) {
      goBackCallback();
      dispatch(singleModeActions.reset());
    }

    return () => {
      goBackCallback();
      dispatch(singleModeActions.reset());
    };
  }, [dispatch, isSingleModeStarted, goBackCallback]);

  const theme = { color: '#FFFFFF', background: 'inherit' };

  const SingleModeStepList = createStepListContainer(STEP_LIST_IDS.singleModeStepList, [
    {
      id: SINGLE_MODE_STEPS.RULES,
      Component: () => <SingleModeRules goBackCallback={goBackCallback} />,
    },
    {
      id: SINGLE_MODE_STEPS.GAME,
      Component: () => <SingleModeGame theme={theme} goBackCallback={goBackCallback} />,
    },
    {
      id: SINGLE_MODE_STEPS.STAGES,
      Component: () => <SingleModeStages goBackCallback={goBackCallback} />,
    },
  ]);

  return (
    <div className="single-mode">
      <SingleModeStepList />
    </div>
  );
};

export const SingleModePage = withRouter(SingleModePageComponent);

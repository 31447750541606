import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { knowledgeBankActions } from '../../../../redux/knowledgeBank/knowledgeBankSlice';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { Routes } from '../../../../const';

import { FAQ_ARTICLE_ID } from '../../../../redux/knowledgeBank/knowledgeBankConstants';
import { ExclamationPointIcon } from '../../../../icons/exclamation-point-icon';
import { Button } from '../../../../components/common/Button';
import { BackArrowButton } from '../../../../components/common/BackArrowButton/BackArrowButton';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { getHeaderImageSelector, getPerxSegmentationCoefficient } from '../../../../redux/bonusShop/bonusShopSelectors';
import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import backgroundImage from '../perx-background-image.jpg';
import { getUserBalanceSelector } from '../../../../redux/profile/profileSelectors';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';

import './createCard.scss';

export const CreateCard = () => {
  const { formatMessage } = useInternationalization();
  const history = useHistory();
  const dispatch = useDispatch();
  const background = useSelector(getHeaderImageSelector) || backgroundImage;
  const coins = useSelector(getUserBalanceSelector);
  const coefficient = useSelector(getPerxSegmentationCoefficient);

  const minimumTransferValue = 20;

  const handleOnBackButton = () => {
    history.push(Routes.BonusShopRoute);
  };

  const openHelpArticle = useCallback(() => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://api.jticonnex.com/Perx_info_.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  }, [dispatch]);

  const onAddClickCallback = useCallback(() => {
    if (coins < minimumTransferValue * coefficient) {
      dispatch(
        modalActions.openPromptModal({
          title: 'Ooops',
          description: "You have not enough points. You can't redeem less than {replacement} points",
          descriptionReplacement: `${minimumTransferValue * coefficient}`,
          btnText: 'OK',
          onButtonClick: () => {
            dispatch(modalActions.closeModal());
          },
          onClose: () => {
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else {
      dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.perxStepList }));
    }
  }, [dispatch, coins]);

  return (
    <div className="create-perx-card-page">
      <BackArrowButton
        onHistoryReplaceCallback={handleOnBackButton}
        withoutTheme
        customClass="create-perx-card-page__back-button"
      />
      <div className="create-perx-card-page__content">
        <div
          className="create-perx-card-page__content-image-container"
          style={{
            background: `url(${background})`,
            backgroundSize: 'cover',
          }}
        />
        <div className="create-perx-card-page__content-card">
          <ExclamationPointIcon className="create-perx-card-page__content-card__faq" onClick={openHelpArticle} />
          <div className="create-perx-card-page__content-card__title-container">
            <div className="create-perx-card-page__content-card__title-container-title">
              {formatMessage('Perx Virtual Mastercard')}
            </div>
            <div className="create-perx-card-page__content-card__title-container-add-card-title">
              {formatMessage('Exchange your points for money. Before you start make sure you have enough points.')}
            </div>
          </div>
          <div className="create-perx-card-page__content-card__btn-container">
            <Button
              customClass={'create-perx-card-page__content-card__btn-container-button'}
              onClick={onAddClickCallback}
            >
              {formatMessage('Redeem Points')}
            </Button>
          </div>
        </div>
      </div>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

export const ID_CARD_TYPE = {
  OLD: 1,
  NEW: 2,
};

export const ID_CARD_STATUS = {
  NO_DATA: 1,
  MODERATION: 2,
  ACCEPTED: 3,
  DUPLICATE_CARD: 4,
  INCORRECT_DATA: 5,
  NOT_CITIZEN: 6,
  WRONG_IMAGE: 7,
  OTHER_REASON: 8,
};

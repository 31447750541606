import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { reducer as formReducer } from 'redux-form';
import stepListReducer from '../components/containers/StepList/stepListSlice';
import authReducer from './auth/authSlice';
import brandsReducer from './brands/brandsSlice';
import bonusShopReducer from './bonusShop/bonusShopSlice';
import profileReducer from './profile/profileSlice';
import serviceReducer from './service/serviceSlice';
import verificationReducer from './verification/verificationSlice';
import { modalReducer } from '../components/containers/Modal/modalSlice';
import { gameReducer } from './game/gameSlice';
import { settingsReducer } from './settings/settingsSlice';
import { challengeReducer } from './challenge/challengeSlice';
import { chanceReducer } from './chance/chanceSlice';
import { knowledgeBankReducer } from './knowledgeBank/knowledgeBankSlice';
import { inviteReducer } from './invite/inviteSlice';
import { specialQuizReducer } from './specialQuizes/specialQuizesSlice';
import { questionOfTheDaySliceReducer } from './questionOfTheDay/questionOfTheDaySlice';
import { eventModeReducer } from './eventMode/eventModeSlice';
import { soloModeReducer } from './soloMode/soloModeSlice';
import { singleModeReducer } from './singleMode/singleModeSlice';
import { dailyRewardsReducer } from './dailyRewards/dailyRewardsSlice';
import { unexpectedRewardsReducer } from './unexpectedRewards/unexpectedRewardsSlice';
import { mainPageReducer } from './mainPage/mainPageSlice';
import { surveyReducer } from './survey/surveySlice';
import { multibrandQuizReducer } from './multibrandQuiz/multibrandQuizSlice';
import { loyaltyProgramReducer } from './loyaltyProgram/loyaltyProgramSlice';
import { scenarioReducer } from './scenario/scenarioSlice';
import boostersReducer from './boosters/boostersSlice';
import achievementsReducer from './achievements/achievementsSlice';
import { lepReducer } from './LEP/lepSlice';
import { chatReducer } from './chat';
import { recognitionReducer } from './recognition';
import { quizRateReducer } from './quizRate/quizRateSlice';
import { birthdayReducer } from './birthday/birthdaySlice';
import { bannersReducer } from './banners/bannersSlice';
import { dailyGamesReducer } from './dailyGames/dailyGamesSlice';
import { quickTipsReducer } from './quickTips/quickTipsSlice';
import { idCardReducer } from './idCard/idCardSlice';
import { mysteryShopperReducer } from './mysteryShopper/mysteryShopperSlice';
import { moderationReducer } from './moderation/moderationSlice';
import { selfRegistrationReducer } from './selfRegistration/selfRegistrationSlice';
import { m10PaymentReducer } from './m10Payment/m10Slice';
import { notificationMessageReducer } from './notificationMessage/notificationMessageSlice';

export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    steps: stepListReducer,
    form: formReducer,
    auth: authReducer,
    brands: brandsReducer,
    bonusShop: bonusShopReducer,
    profile: profileReducer,
    service: serviceReducer,
    modal: modalReducer,
    game: gameReducer,
    settings: settingsReducer,
    invite: inviteReducer,
    challenge: challengeReducer,
    chat: chatReducer,
    recognition: recognitionReducer,
    knowledgeBank: knowledgeBankReducer,
    chance: chanceReducer,
    specialQuizes: specialQuizReducer,
    questionOfTheDay: questionOfTheDaySliceReducer,
    eventMode: eventModeReducer,
    soloMode: soloModeReducer,
    singleMode: singleModeReducer,
    dailyRewards: dailyRewardsReducer,
    unexpectedRewards: unexpectedRewardsReducer,
    mainPage: mainPageReducer,
    survey: surveyReducer,
    multibrandQuiz: multibrandQuizReducer,
    loyaltyProgram: loyaltyProgramReducer,
    verification: verificationReducer,
    scenario: scenarioReducer,
    boosters: boostersReducer,
    achievements: achievementsReducer,
    lep: lepReducer,
    quizRate: quizRateReducer,
    birthday: birthdayReducer,
    banners: bannersReducer,
    dailyGames: dailyGamesReducer,
    quickTips: quickTipsReducer,
    idCard: idCardReducer,
    mysteryShopper: mysteryShopperReducer,
    moderation: moderationReducer,
    selfRegistration: selfRegistrationReducer,
    m10: m10PaymentReducer,
    notificationMessage: notificationMessageReducer,
  });

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import VouchersByCategory from '../../components/VouchersByCategory';
import { VoucherTransactionWithDetails } from '../../types/Voucher';
import { bonusShopApi } from '../../api/bonusShopApi';
import { LoadingPage } from '../../components/common/Spinner/LoadingPage';
import { Routes } from '../../const';

interface VouchersProps {
  isActive?: boolean;
}

const MyVouchers = ({ isActive }: VouchersProps) => {
  const { push } = useHistory();
  const [vouchers, setVouchers] = useState<VoucherTransactionWithDetails[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    const fetchDataAsync = async () => {
      const result = await bonusShopApi.getVouchersList();
      if (JSON.stringify(result) !== JSON.stringify(vouchers)) {
        setVouchers(result);
      }
    };

    fetchDataAsync();
  }, [isActive]);

  const handleVoucherClick = async (transaction: VoucherTransactionWithDetails) => {
    push(`${Routes.VoucherDetails}/${transaction.id}`);
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return <VouchersByCategory vouchers={vouchers} onClick={handleVoucherClick} />;
};

export default MyVouchers;

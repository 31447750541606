import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getBoostersForQuizSelector } from '../../../redux/boosters/boostersSelectors';
import { boostersActions } from '../../../redux/boosters/boostersSlice';
import { decryptAnswer } from '../../../helpers/cryptCorrectAnswers';

import './Boosters.scss';

export const Boosters = ({
  pack_id,
  solo_mode,
  currentQuestion,
  myVariant,
  setMyAnswerCallback,
  roundTime,
  addBonusTimeCallback,
}) => {
  const dispatch = useDispatch();
  const [boostersDisabled, setBoosterDisabled] = useState(false);

  useEffect(() => {
    dispatch(boostersActions.getBoostersForQuiz({ pack_id, solo_mode }));
  }, [dispatch, pack_id, solo_mode]);

  useEffect(() => {
    let answersElements;
    if (currentQuestion.type.id === 3) {
      answersElements = document.getElementsByClassName('quiz-page__image-answer-container');
    } else {
      answersElements = document.getElementsByClassName('quiz-page__answer');
    }
    for (let i = 0; i < answersElements.length; i++) {
      answersElements[i].style.opacity = null;
    }
    setBoosterDisabled(false);
    dispatch(boostersActions.setActiveBooster(null));
  }, [currentQuestion, dispatch]);

  useEffect(() => {
    let answersElements;
    if (currentQuestion.type.id === 3) {
      answersElements = document.getElementsByClassName('quiz-page__image-answer-container');
    } else {
      answersElements = document.getElementsByClassName('quiz-page__answer-svg');
    }

    const correctAnswer = Number.isInteger(currentQuestion.correct_answers[0])
      ? currentQuestion.correct_answers[0]
      : decryptAnswer(currentQuestion.correct_answers[0]);

    const correctAnswerIndex = correctAnswer - 1;
    answersElements[correctAnswerIndex].style.border = null;
    answersElements[correctAnswerIndex].style.boxSizing = null;
    answersElements[correctAnswerIndex].style.borderRadius = null;
  }, [myVariant, currentQuestion]);

  const boosters = useSelector(getBoostersForQuizSelector);

  const useBoosterCallback = useCallback(
    (booster) => {
      dispatch(
        boostersActions.useBooster({
          booster,
          question: currentQuestion,
          setMyAnswerCallback,
          roundTime,
          addBonusTimeCallback,
          pack_id,
        }),
      );
      setBoosterDisabled(true);
    },
    [currentQuestion, dispatch, setMyAnswerCallback, roundTime, addBonusTimeCallback, pack_id],
  );

  return (
    <div className={boostersDisabled ? 'boosters_disabled' : 'boosters'}>
      {boosters.map((item) => (
        <div
          key={item.type}
          className="boosters__booster"
          style={{
            backgroundImage: item.amount > 0 ? `url(${item.icon})` : `url(${item.disabled_icon})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          // eslint-disable-next-line  react-hooks/rules-of-hooks
          onClick={boostersDisabled || item.amount <= 0 ? null : () => useBoosterCallback(item)}
        >
          <div className="boosters__amount">{item.amount}</div>
        </div>
      ))}
    </div>
  );
};

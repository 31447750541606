import { useSelector, useDispatch } from 'react-redux';

import { analyticHelper } from '../../../../helpers/analyticHelper';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { getModalDataSelector } from '../../../containers/Modal/modalSelectors';
import { modalActions } from '../../../containers/Modal/modalSlice';

import { ModalContainer } from '../../../containers/Modal/ModalContainer';
import { Button } from '../../../common/Button';

import { MODALS } from '../../../containers/Modal/modalConstants';
import achievementLevelCompleted from './achievementLevelCompleted.gif';

import './AchievementLevelCompletedModal.scss';

const styles = {
  width: 343,
};

export const AchievementLevelCompleted = () => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const {
    data: { onClose, onButtonClick, achievementName, level },
  } = useSelector(getModalDataSelector);

  const handleButtonClick = () => {
    analyticHelper.achievementNewLevel(level);
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };

  const messages = [
    'It wasn’t too hard for you, was it?',
    'Wow! It seems like nothing can stop you from another win',
    'Another win is in your pocket, congratulations!',
  ];
  const randomNumber = Math.floor(Math.random() * messages.length);

  return (
    <ModalContainer modal={MODALS.achievementLevelCompleted} onClose={onClose} customStyles={styles}>
      <div className="achievement-level-completed">
        <div>
          <img className="achievement-level-completed__reward-image" src={achievementLevelCompleted} alt="reward" />
        </div>
        <div className="achievement-level-completed__reward">
          {formatMessage('{achievementName} {level} unlocked!', { achievementName, level })}
        </div>
        <div className="achievement-level-completed__description">{formatMessage(messages[randomNumber])}</div>
        <Button onClick={handleButtonClick} customClass="achievement-level-completed__btn">
          {formatMessage('OK')}
        </Button>
      </div>
    </ModalContainer>
  );
};

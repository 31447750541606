import { put, takeEvery, select } from 'redux-saga/effects';

import { QUESTION_OF_THE_DAY_ACTION_TYPES, QUESTION_OF_THE_DAY_STEPS } from './questionOfTheDayConstants';
import { questionOfTheDayApi } from '../../api/questionOfTheDayApi';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { questionOfTheDaySliceActions } from './questionOfTheDaySlice';
import { checkCorrectAnswers } from '../game/gameSelectors';
import { getAnswersSelector, getCoinsSelector, getQuestionSelector } from './questionOfTheDaySelectors';
import { push } from 'connected-react-router';
import { modalActions } from '../../components/containers/Modal/modalSlice';
import { Routes } from '../../const';

export const goToQuestionOfTheDayStepAction = (stepId) =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.questionOfTheDayStepList,
    stepId,
  });

function* onFinishQuestionOfTheDaySaga() {
  try {
    const answer = yield select(getAnswersSelector);
    const question = yield select(getQuestionSelector);
    const coins = yield select(getCoinsSelector);
    const checkedAnswers = checkCorrectAnswers(answer.me, question);
    const correct_answers = checkedAnswers.filter((i) => i.isCorrect);
    yield questionOfTheDayApi.finishQuestionOfTheDay(question.id, correct_answers.length > 0);
    const resultCoins = correct_answers.length > 0 ? coins : 0;
    yield put(questionOfTheDaySliceActions.setQuestionOfTheDayResultData({ coins: resultCoins }));
    yield put(goToQuestionOfTheDayStepAction(QUESTION_OF_THE_DAY_STEPS.RESULT));
  } catch (error) {
    console.log('onFinishQuestionOfTheDaySaga', error);
  }
}

function* onStartQuestionOfTheDaySaga() {
  try {
    const { data } = yield questionOfTheDayApi.getQuestionOfTheDay();
    yield put(questionOfTheDaySliceActions.setQuestions(data));
    yield put(push(Routes.QuestionOfTheDayRoute));
  } catch (error) {
    yield put(
      modalActions.openPromptModal({
        title: 'Good thing come to those who wait',
        description: 'Question of the day is available only once per day. Come back tomorrow.',
        btnText: 'Got it',
      }),
    );
    console.log(error);
  }
}

function* sagas() {
  yield takeEvery(QUESTION_OF_THE_DAY_ACTION_TYPES.START_QUESTION_OF_THE_DAY, onStartQuestionOfTheDaySaga);
  yield takeEvery(QUESTION_OF_THE_DAY_ACTION_TYPES.SET_ANSWER, onFinishQuestionOfTheDaySaga);
}

export const questionOfTheDaySagas = sagas;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IGoalTrackerRequests {
  isFetching: boolean;
  errorMessage: string;
}

const initialState: IGoalTrackerRequests = {
  isFetching: false,
  errorMessage: '',
};

const goalTrackerSlice = createSlice({
  name: 'goalTracker',
  initialState,
  reducers: {
    confirmReward(state, action: PayloadAction<number>) {},
    setIsFetching(state, action: PayloadAction<boolean>) {
      state.isFetching = action.payload;
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
  },
});

export const goalTrackerActions = goalTrackerSlice.actions;

import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Routes } from '../../const';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { challengeActions } from '../../redux/challenge/challengeSlice';
import { challengeSelectors } from '../../redux/challenge/challengeSelectors';
import { analyticHelper } from '../../helpers/analyticHelper';
import { isHungary, isIreland } from '../../helpers';

import { PlusIcon } from '../../icons/plus-icon';
import { Button } from '../../components/common/Button';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';

import './getDigitalFramePhoto.scss';

export const ResultPhotoPage = (props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const photoWithFrame = useSelector(challengeSelectors.selectPhotoWithFrame);
  const { challenge, history } = props;

  const isRegionsWithTeamChallenge = isHungary() || isIreland();

  useEffect(() => {
    const canvas = document.getElementById('img');
    const context = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      context.drawImage(img, 0, 0);
    };
    img.src = photoWithFrame;
  }, [challenge, photoWithFrame]);

  const goBack = useCallback(() => {
    history.replace(isRegionsWithTeamChallenge ? `${Routes.ChallengeRoute}/${challenge.id}` : Routes.ChallengeRoute);
  }, [history]);

  const confirmCallback = useCallback(() => {
    const canvas = document.getElementById('img');
    canvas.toBlob((blob) => {
      const formData = new FormData();
      formData.append('photo', blob, `${uuid()}.png`);

      const data = isRegionsWithTeamChallenge
        ? { formData: formData, id: challenge.id, taskType: 'CUSTOM', taskId: history.location.state.taskId }
        : {
            formData: formData,
            id: challenge.id,
          };

      dispatch(challengeActions.uploadChallengePhoto(data));
      analyticHelper.challengePhoto();
      goBack();
    });
  }, [dispatch, challenge, goBack]);

  const retryCallback = useCallback(() => {
    dispatch(
      stepListActions.prevStep({
        stepListId: STEP_LIST_IDS.digitalFrameStepList,
      }),
    );
  }, [dispatch]);

  return (
    <div className="get-digital-frame-photo">
      <div className="get-digital-frame-photo__mask">
        <div className="get-digital-frame-photo__mask__top">
          <div className="get-digital-frame-photo__mask__close-icon-container">
            <PlusIcon className="get-digital-frame-photo__mask__close-icon" onClick={goBack} />
          </div>
        </div>
        <div className="get-digital-frame-photo__mask__bottom">
          <div style={{ display: 'flex' }}>
            <Button className="get-digital-frame-photo__result-button" onClick={retryCallback}>
              {formatMessage('Again')}
            </Button>
            <Button className="get-digital-frame-photo__result-button" onClick={confirmCallback}>
              {formatMessage('OK')}
            </Button>
          </div>
        </div>
      </div>
      <canvas id="img" width={window.innerWidth} height={window.innerHeight} />
    </div>
  );
};

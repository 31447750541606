import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit';

import { ChatMessage } from '../chatTypes';
import { descDateComparer } from '../chatServices';

interface NotificationsState {
  isFetching: boolean;
  messages: EntityState<ChatMessage>;
  hasMore: boolean;
}

export const messageAdaptor = createEntityAdapter<ChatMessage>({
  selectId: (message) => message.id,
  sortComparer: (a, b) => descDateComparer(a.createdAt, b.createdAt),
});

const initialState: NotificationsState = {
  isFetching: true,
  messages: messageAdaptor.getInitialState(),
  hasMore: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchMessages: (state, action: PayloadAction<{ offset: number }>): void => {},
    setIsFetching: (state, action: PayloadAction<boolean>): NotificationsState => ({
      ...state,
      isFetching: action.payload,
    }),
    setHasMore: (state, action: PayloadAction<boolean>): NotificationsState => ({
      ...state,
      hasMore: action.payload,
    }),
    messagesReceived: (state, action: PayloadAction<ChatMessage[]>): void => {
      messageAdaptor.upsertMany(state.messages, action);
    },
    messageReceived: (state, action: PayloadAction<ChatMessage>): void => {
      messageAdaptor.upsertOne(state.messages, action);
    },
    reset: (): NotificationsState => ({
      ...initialState,
    }),
  },
});

export const notificationsActions = notificationsSlice.actions;
export const notificationsReducer = notificationsSlice.reducer;

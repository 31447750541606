import { useSelector } from 'react-redux';

import { selectIsFetching, selectUsers } from '../../../../redux/moderation/moderationSelectors';
import { isIPhoneWithHomeBar } from '../../../../helpers/checkIsIphoneX';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { Header } from '../../../../components/common/Header';
import { NavigationMenu } from '../../../../components';
import { UserCard } from '../index';
import { IUser } from '../../../../redux/moderation/moderationTypes';
import { LoaderContainer } from '../../../../components/common';
import { ModerationBlock } from '../index';

import styles from './ModerationPage.module.scss';
import isEmpty from 'is-empty-typed';

type Props = {
  usersForModeration: number[];
  usersForModerationCount: number;
  onModerateUser: () => void;
  onDeleteUser: () => void;
  handleUser: (id: number) => void;
};

export const ModerationPage = ({
  usersForModeration,
  usersForModerationCount,
  onModerateUser,
  onDeleteUser,
  handleUser,
}: Props) => {
  const { formatMessage } = useInternationalization();
  const users = useSelector(selectUsers);
  const isFetching = useSelector(selectIsFetching);

  return (
    <div className={styles.page}>
      <LoaderContainer isLoading={isFetching} className={styles.loaderContainer}>
        <>
          <Header customClass={styles.header} hasBackButton buttonColor={'black'}>
            {formatMessage('Moderation')}
          </Header>
          <div className={styles.content} style={{ paddingBottom: isIPhoneWithHomeBar() ? '258px' : '224px' }}>
            {isEmpty(users) ? (
              <div className={styles.empty}>{formatMessage('There are no users for moderate')}</div>
            ) : (
              users.map((user: IUser) => {
                const isChecked = usersForModeration.includes(user.id);
                return <UserCard key={user.id} user={user} handleUser={handleUser} isChecked={isChecked} />;
              })
            )}
          </div>
          {!isEmpty(users) && (
            <div className={styles.moderationBlock} style={{ bottom: isIPhoneWithHomeBar() ? '87px' : '53px' }}>
              <ModerationBlock
                usersForModerationCount={usersForModerationCount}
                onModerateUser={onModerateUser}
                onDeleteUser={onDeleteUser}
              />
            </div>
          )}
        </>
      </LoaderContainer>

      <NavigationMenu location={location} />
    </div>
  );
};

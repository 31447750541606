import { RootState } from '../store';

const selectIsRateModalShown = (state: RootState): number | null => state.game.rateQuiz;
const selectPackIdHeadToHead = (state: RootState): number | null => state.game.pack_id;
const selectPackIdSoloMode = (state: RootState): number | null => state.soloMode.pack_id;

export const quizRateSelectors = {
  selectIsRateModalShown,
  selectPackIdHeadToHead,
  selectPackIdSoloMode,
};

import { useDispatch, useSelector } from 'react-redux';

import './oppaWalletSettings.scss';

import { STEP_LIST_IDS } from '../../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../../components/containers/StepList/stepListSlice';
import { DisclaimerFooter } from '../../../../components/disclaimerFooter/disclaimerFooter';
import { bonusShopActions } from '../../../../redux/bonusShop/bonusShopSlice';
import { useInternationalization } from '../../../../hooks/useTranslationHook';
import { modalActions } from '../../../../components/containers/Modal/modalSlice';
import { oppaSteps } from '../OPPA';
import bannerCard from '../../../../images/banner_card-change.png';
import { Button } from '../../../../components/common/Button';
import { Header } from '../../../../components/common/Header';
import { getOppaPersonalNumberSelector, getOppaWalletSelector } from '../../../../redux/profile/profileSelectors';

export let OppaWalletSettings = (props) => {
  const dispatch = useDispatch();
  const walletNumber = useSelector(getOppaWalletSelector);
  const personalNumber = useSelector(getOppaPersonalNumberSelector);
  const { formatMessage } = useInternationalization();

  const openChangeWindow = () => {
    dispatch(
      modalActions.openPromptWithTwoButtonsModal({
        title: 'Change your e-wallet?',
        description: 'Your previous data will be replaced completely. Are you sure you want to change your e-wallet?',
        btnText: 'Yes',
        anotherBtnText: 'No',
        onButtonClick: () => {
          dispatch(
            bonusShopActions.setOppaWallet({
              personal_number: null,
              wallet_number: null,
            }),
          );
          dispatch(modalActions.closeModal());
          dispatch(
            stepListActions.setStep({
              stepListId: STEP_LIST_IDS.oppaStepList,
              stepId: oppaSteps.addWallet,
            }),
          );
        },
        onAnotherButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const handlePreviousStep = () => {
    dispatch(stepListActions.setStep({ stepListId: STEP_LIST_IDS.oppaStepList, stepId: oppaSteps.mainPage }));
  };

  return (
    <div className="relative-container">
      <div className="oppa-wallet-settings">
        <Header goBackCallback={handlePreviousStep} hasBackButton customClass="oppa-wallet-settings__header">
          {formatMessage('Settings')}
        </Header>
        <div className="oppa-wallet-settings__content">
          <div className="oppa-wallet-settings__content-wallet-container">
            <div className="oppa-wallet-settings__content-wallet-container__field">
              <div className="oppa-wallet-settings__content-wallet-container__field-title">
                {formatMessage('Personal number')}
              </div>
              <div className="oppa-wallet-settings__content-wallet-container__field-card-number">{personalNumber}</div>
            </div>
            <div className="oppa-wallet-settings__content-wallet-container__field">
              <div className="oppa-wallet-settings__content-wallet-container__field-title">
                {formatMessage('Account number')}
              </div>
              <div className="oppa-wallet-settings__content-wallet-container__field-card-number">{walletNumber}</div>
            </div>
          </div>
          <div className="oppa-wallet-settings__content-image-container">
            <img className="oppa-wallet-settings__content-image-container-image" src={bannerCard} alt="settings" />
            <div className="oppa-wallet-settings__content-image-container-button-container">
              <h1 className="oppa-wallet-settings__content-image-container-button-container-title">
                {formatMessage('Change your e-wallet')}
              </h1>
              <Button
                withoutTheme
                customClass={'oppa-wallet-settings__content-image-container-button-container-btn'}
                onClick={openChangeWindow}
              >
                {formatMessage('Continue')}
              </Button>
            </div>
          </div>
        </div>
        <DisclaimerFooter withoutNavigation />
      </div>
    </div>
  );
};

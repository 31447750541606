import classNames from 'classnames';

import styles from './BonusShopStub.module.scss';
import { APP_TYPE } from '../../../const/appConstants';

const BonusShopStub = () => {
  return (
    <div className={styles.root}>
      <div className={classNames('stub', APP_TYPE?.toLowerCase())} />
    </div>
  );
};

export default BonusShopStub;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IM10Payout, IM10History, IM10State } from './m10Types';

const initialState: IM10State = {
  isFetching: false,
  isSuccess: false,
  rate: 1,
  history: [],
};

const m10PaymentSlice = createSlice({
  name: 'm10Payment',
  initialState,
  reducers: {
    initPayout: (state, action: PayloadAction<IM10Payout>) => {},
    initGetHistory: () => {},
    initGetExchange: () => {},
    setHistory: (state, action: PayloadAction<IM10History[]>) => {
      state.history = action.payload;
    },
    setExchangeRate: (state, action: PayloadAction<number>) => {
      state.rate = action.payload;
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
  },
});

export const m10PaymentActions = m10PaymentSlice.actions;
export const m10PaymentReducer = m10PaymentSlice.reducer;

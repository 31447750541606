import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { InjectedFormProps, reduxForm, reset } from 'redux-form';
import isEmpty from 'is-empty-typed';

import { ExitButton } from '../../../components/common/ExitButton/ExitButton';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { ErrorText } from '../../../components/common/ErrorText/ErrorText';
import { WhiteSpinner } from '../../../components/common/Spinner/WhiteSpinner/WhiteSpinner';
import { Routes } from '../../../const';
import { AddPhotoQuestion, MultipleChoice, OpenQuestion, SingleChoice } from '../questions';
import {
  getFileKeys,
  getIsFetching,
  getNumberOfPassedQuestions,
  selectBrandId,
  selectErrorText,
  selectIsCorrect,
  selectIsSingleChoiceFieldTouched,
  selectIsSubmitted,
  selectMysteryShopperId,
  selectNumberOfQuestions,
  selectPriceQuestionValue,
  selectQuestions,
  selectSingleChoiceErrorText,
} from '../../../redux/mysteryShopper/mysteryShopperSelectors';
import { IAnswer, mysteryShopperQuestionTypes } from '../../../redux/mysteryShopper/mysteryShopperTypes';
import PriceQuestion from '../../quickTips/priceQuestion/PriceQuestion';
import { mysteryShopperActions } from '../../../redux/mysteryShopper/mysteryShopperSlice';
import { getBrandInfoSelector } from '../../../redux/brands/brandSelectors';

import styles from './Quiz.module.scss';

export const QuizComponent = ({ handleSubmit }: InjectedFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const questions = useSelector(selectQuestions);
  const totalQuestions = useSelector(selectNumberOfQuestions);
  const passedQuestions = useSelector(getNumberOfPassedQuestions);
  const isCorrect = useSelector(selectIsCorrect);
  const isSubmitted = useSelector(selectIsSubmitted);
  const mysteryShopperId = useSelector(selectMysteryShopperId);
  const fileKeys = useSelector(getFileKeys);
  const errorText = useSelector(selectErrorText);
  const priceValue = useSelector(selectPriceQuestionValue);
  const isFetching = useSelector(getIsFetching);
  const singleChoiceErrorText = useSelector(selectSingleChoiceErrorText);
  const touched = useSelector(selectIsSingleChoiceFieldTouched);
  const brandId = useSelector(selectBrandId);
  // @ts-ignore
  const brandInfo = useSelector((state) => getBrandInfoSelector(state, brandId));

  const question = questions[0];

  const isPhotoQuestion = question.type_id === mysteryShopperQuestionTypes.addPhotoQuestion;

  const getQuestion = () => {
    switch (question?.type_id) {
      case mysteryShopperQuestionTypes.multiplyChoiceQuestion:
        return <MultipleChoice answers={question?.answers} disabled={isSubmitted} />;
      case mysteryShopperQuestionTypes.singleChoiceQuestion:
        return (
          <>
            {question?.answers.map(({ id, answer_text, is_correct }: IAnswer) => (
              <SingleChoice
                key={id}
                value={answer_text}
                id={`${id}`}
                label={answer_text}
                isCorrect={is_correct}
                disabled={isSubmitted}
              />
            ))}
            {touched && singleChoiceErrorText && <ErrorText text={singleChoiceErrorText} />}
          </>
        );
      case mysteryShopperQuestionTypes.priceQuestion:
        const correctAnswer = question?.answers[0].answer_text;
        const isHintShown = correctAnswer !== undefined && !isCorrect;
        const numberOfInputs = question?.price_question_answer_length;
        const showPriceDisclaimer = question?.show_price_disclaimer;

        return (
          <div className={styles.priceQuestionContainer}>
            <div className={styles.imageWrapper}>
              <img className={styles.image} src={question?.question_image} alt="question" />
              {showPriceDisclaimer && <img className={styles.disclaimer} src={brandInfo.disclaimer} alt="disclaimer" />}
            </div>
            <PriceQuestion
              snackbar
              dotColor="white"
              customInputClass={styles.priceQuestionInput}
              customCorrectAnswerClass={styles.correctAnswer}
              customIncorrectAnswerClass={styles.incorrectAnswer}
              isAnswerCorrect={isCorrect}
              correctAnswer={correctAnswer}
              disabled={isSubmitted}
              numberOfInputs={numberOfInputs}
              priceValue={priceValue}
            />
            {isHintShown && <span className={styles.hint}>{`Correct answer is ${correctAnswer}`}</span>}
          </div>
        );
      case mysteryShopperQuestionTypes.openQuestion:
        return <OpenQuestion />;
      case mysteryShopperQuestionTypes.addPhotoQuestion:
        return <AddPhotoQuestion />;
    }
  };

  const handleExitButton = () => {
    history.push(Routes.MainRoute);
  };

  const onSubmit = () => {
    if (isEmpty(fileKeys) && isPhotoQuestion) {
      dispatch(mysteryShopperActions.setErrorText('Add at least one photo'));
      return;
    }
    if (isSubmitted) {
      dispatch(mysteryShopperActions.getMysteryShopperQuestions({ mysteryShopperId: mysteryShopperId }));
      dispatch(mysteryShopperActions.setIsCorrect(false));
      dispatch(reset('quizForm'));
    } else {
      dispatch(
        mysteryShopperActions.setIds({
          criteriaId: question.criteria_id,
          questionId: question.id,
        }),
      );
      dispatch(mysteryShopperActions.sendMysteryShopperAnswers());
    }
  };

  useEffect(() => {
    return () => {
      dispatch(mysteryShopperActions.resetFileKeys());
    };
  }, []);

  return (
    <div className={styles.container}>
      {isFetching ? (
        <WhiteSpinner />
      ) : (
        <>
          <ExitButton onClick={handleExitButton} />
          <span className={styles.currentQuestion}>{`${passedQuestions + 1}/${totalQuestions}`}</span>
          {<span className={styles.questionText}>{question?.question_text}</span>}
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {getQuestion()}
            <ArrowButton type="submit" customClass={styles.nextButton} fillcolor="white" withoutTheme />
          </form>
          {errorText && <ErrorText text={errorText} />}
        </>
      )}
    </div>
  );
};

export const Quiz = reduxForm({
  form: 'quizForm',
  destroyOnUnmount: false,
  shouldValidate: () => true,
  keepDirtyOnReinitialize: true,
})(QuizComponent);

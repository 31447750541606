import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  competitions: [],
  selectedCompetition: {},
  codes: [],
  registration_banner: '',
  winner_banner: '',
  isFetching: false,
};

const lepSlice = createSlice({
  name: 'lep',
  initialState,
  reducers: {
    getCompetitions() {},
    setCompetitions(state, { payload }) {
      return { ...state, ...payload };
    },
    setActivatedCodes(state, { payload }) {
      payload.codes = payload.codes.map((el) => {
        if (el.status === 1) {
          const durationShowWinCard = 86400000;
          el.showWin = new Date(el.won_at) - new Date() + durationShowWinCard > 0;
        }

        return el;
      });
      return { ...state, ...payload };
    },
    getCodes() {},
    setCode() {},
    setSelectedCompetition(state, { payload: { selectedCompetition } }) {
      let draw_end_timer = new Date(selectedCompetition.draw_end_date).getTime() - new Date().getTime();
      selectedCompetition = { ...selectedCompetition, draw_end_timer };
      return { ...state, selectedCompetition };
    },
    signUp() {},
    setIsFetching(state, { payload }) {
      return { ...state, isFetching: payload.isFetching };
    },
    resetCodes(state) {
      return { ...state, codes: [] };
    },
  },
});

export const lepActions = lepSlice.actions;
export const lepReducer = lepSlice.reducer;

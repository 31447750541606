import { combineReducers } from '@reduxjs/toolkit';

import { recognitionSelectors } from './recognitionSelectors';

import { recognitionSagas } from './recognitionSagas';
import { recognitionReducer as commonReducer, recognitionActions } from './recognitionSlice';

const recognitionReducer = combineReducers({
  common: commonReducer,
});

export { recognitionReducer, recognitionActions, recognitionSelectors, recognitionSagas };

import { BonusShopBlackIcon as BonusShopBlackIconThin } from '../../icons/thin/bonus-shop-black';
import { BonusShopIcon as BonusShopIconThin } from '../../icons/thin/bonus-shop';
import { ChatBlackIcon as ChatBlackIconThin } from '../../icons/thin/chat-black';
import { ChatIcon as ChatIconThin } from '../../icons/thin/chat';
import { HomeBlackIcon as HomeBlackIconThin } from '../../icons/thin/home-black';
import { HomeIcon as HomeIconThin } from '../../icons/thin/home';
import { KnowledgeBaseBlackIcon as KnowledgeBaseBlackIconThin } from '../../icons/thin/knowledge-base-black';
import { KnowledgeBaseIcon as KnowledgeBaseIconThin } from '../../icons/thin/knowledge-base';
import { ProfileBlackIcon as ProfileBlackIconThin } from '../../icons/thin/profile-black';
import { ProfileIcon as ProfileIconThin } from '../../icons/thin/profile';
import {
  HomeIcon,
  HomeBlackIcon,
  BonusShopIcon,
  BonusShopBlackIcon,
  KnowledgeBaseIcon,
  KnowledgeBaseBlackIcon,
  ProfileIcon,
  ProfileBlackIcon,
  ChatIcon,
  ChatBlackIcon,
} from '../../icons';

export const AppSections = {
  HOME: 'HOME',
  BONUS_SHOP: 'BONUS_SHOP',
  KNOWLEDGE_BANK: 'KNOWLEDGE_BANK',
  CHAT: 'CHAT',
  PROFILE: 'PROFILE',
} as const;

type AppSectionsType = typeof AppSections[keyof typeof AppSections];

const iconsConfig = [
  {
    [AppSections.HOME]: {
      DEFAULT: <HomeIconThin />,
      ACTIVE: <HomeBlackIconThin />,
    },
    [AppSections.BONUS_SHOP]: {
      DEFAULT: <BonusShopIconThin />,
      ACTIVE: <BonusShopBlackIconThin />,
    },
    [AppSections.KNOWLEDGE_BANK]: {
      DEFAULT: <KnowledgeBaseIconThin />,
      ACTIVE: <KnowledgeBaseBlackIconThin />,
    },
    [AppSections.CHAT]: {
      DEFAULT: <ChatIconThin />,
      ACTIVE: <ChatBlackIconThin />,
    },
    [AppSections.PROFILE]: {
      DEFAULT: <ProfileIconThin />,
      ACTIVE: <ProfileBlackIconThin />,
    },
  },
  {
    [AppSections.HOME]: {
      DEFAULT: <HomeIcon />,
      ACTIVE: <HomeBlackIcon />,
    },
    [AppSections.BONUS_SHOP]: {
      DEFAULT: <BonusShopIcon />,
      ACTIVE: <BonusShopBlackIcon />,
    },
    [AppSections.KNOWLEDGE_BANK]: {
      DEFAULT: <KnowledgeBaseIcon />,
      ACTIVE: <KnowledgeBaseBlackIcon />,
    },
    [AppSections.CHAT]: {
      DEFAULT: <ChatIcon />,
      ACTIVE: <ChatBlackIcon />,
    },
    [AppSections.PROFILE]: {
      DEFAULT: <ProfileIcon />,
      ACTIVE: <ProfileBlackIcon />,
    },
  },
];

export const getIcon = (themeId: number, appSection: AppSectionsType, isActive: boolean) => {
  const { ACTIVE, DEFAULT } = iconsConfig[themeId][appSection]!;
  return isActive ? ACTIVE : DEFAULT;
};

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import '../profileSettings.scss';

import { Button } from '../../../components/common/Button';
import { ColorThemes } from '../../../const';
import { serviceActions } from '../../../redux/service/serviceSlice';
import { analyticHelper } from '../../../helpers/analyticHelper';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const SetThemeComponent = (props) => {
  const { formatMessage } = useInternationalization();
  const { initialValues } = props;

  const dispatch = useDispatch();
  const theme = useSelector((state) => state.service.theme);
  const themeId = localStorage.getItem('themeId');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const userInfoForm = useSelector((state) => state.form.userInfoForm.values);
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    if (JSON.stringify(initialValues) === JSON.stringify(userInfoForm)) {
      setIsFormChanged(false);
    } else {
      setIsFormChanged(true);
    }
  }, [userInfoForm, initialValues]);

  const onSave = () => {
    analyticHelper.profileEditSave();
  };

  useEffect(() => {
    if (theme.id === themeId) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [theme, themeId]);

  return (
    <div className="profile-settings-page__content-color-themes">
      <p className="profile-settings-page__content-color-themes-caption">{formatMessage('App theme:')}</p>
      <div className="profile-settings-page__content-color-themes-selector">
        {theme &&
          ColorThemes.map((constTheme) => (
            <div
              key={`theme-${constTheme.id}`}
              className={classnames('profile-settings-page__content-color-themes-selector-theme', {
                selected: constTheme.id === theme.id,
              })}
              style={{
                backgroundImage: `url(${constTheme.back})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
              onClick={() => dispatch(serviceActions.setThemeLocal({ themeId: constTheme.id }))}
            />
          ))}
      </div>
      <Button
        withoutTheme
        type="submit"
        customClass={classnames({ disabled: isButtonDisabled && !isFormChanged })}
        disabled={isButtonDisabled && !isFormChanged}
        onClick={() => onSave()}
      >
        {formatMessage('Save')}
      </Button>
    </div>
  );
};

import { useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { reduxForm, reset } from 'redux-form';

import './Lep.scss';

import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { authApi } from '../../../api/authApi';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { getPositionsSelectors } from '../../../redux/service/serviceSelectors';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { Button } from '../../../components/common/Button';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { Input } from '../../../components/common/Input';
import {
  accountNumber,
  characters,
  lengthMinMax,
  lengthPhone,
  lepEmail,
  letters,
  required,
} from '../../../helpers/formValidations';
import { InfoIcon } from '../../../icons/info-icon';
import { Select } from '../../../components/common/Select';
import { APP_TYPES } from '../../../const/appConstants';
import { getLepSelectedCompetitionsSelector } from '../../../redux/LEP/lepSelector';
import { lepActions } from '../../../redux/LEP/lepSlice';
import { DisclaimerFooter } from '../../../components/disclaimerFooter/disclaimerFooter';

const getSelectFontColor = (form, name) => {
  if (form[name]) {
    return '';
  } else {
    return '-default';
  }
};

const asyncValidate = (values) => {
  const { traffic_code } = values;

  if (/[^0-9]/g.test(traffic_code) || traffic_code.length !== 7) {
    return Promise.resolve().then((res) => {
      //eslint-disable-next-line no-throw-literal
      throw {
        traffic_code: 'Account Number consists of 7 numerals',
      };
    });
  }

  return authApi.verifyCode(traffic_code).then((response) => {
    const { data } = response;
    if (data.traffic_code) {
      //eslint-disable-next-line no-throw-literal
      throw {
        traffic_code: data.traffic_code,
      };
    }
  });
};

const LepForm = (props) => {
  const { handleSubmit, invalid, formObj } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const positions = useSelector(getPositionsSelectors);
  const { background_image, accent_color } = useSelector(getLepSelectedCompetitionsSelector);
  const countryCode = '+353 (0) ';
  const phoneLength = process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA ? 8 : 9;

  const onBackCallback = useCallback(() => {
    dispatch(reset('signupFormLep'));
    dispatch(stepListActions.prevStep({ stepListId: STEP_LIST_IDS.lepStepList }));
  }, [dispatch]);

  const openAccountCodeInfoModal = () => {
    dispatch(
      modalActions.openPromptModal({
        title: formatMessage('JTI Account Number'),
        description: [formatMessage('Please ask your JTI representative or Store Manager for your JTI account number')],
        btnText: formatMessage('OK'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
      }),
    );
  };

  const submit = () => {
    dispatch(lepActions.setCode());
  };

  return (
    <div
      className="lep-page__signup"
      style={{
        backgroundColor: accent_color,
        backgroundImage: `url(${background_image})`,
      }}
    >
      <form onSubmit={handleSubmit(submit)} className="signup-page">
        <div className="signup-page__top-section">
          <Button
            withoutTheme
            type="button"
            className="signup-page__back-button"
            onClick={() => {
              onBackCallback();
            }}
          >
            <ArrowIconRounded className="signup-page__top-arrow" />
          </Button>
          <div className="signup-page__title">{formatMessage('Fill in all the fields')}</div>
          <Input
            name="email"
            type="email"
            placeholder={formatMessage('Email')}
            validate={[required, lepEmail]}
            customClass="signup-page__input"
          />
          <Input
            name="name"
            placeholder={formatMessage('Name')}
            validate={[required, letters, lengthMinMax(2, 15, formatMessage)]}
            customClass="signup-page__input"
          />
          <Input
            name="surname"
            placeholder={formatMessage('Surname')}
            validate={[required, letters, lengthMinMax(2, 30, formatMessage)]}
            customClass="signup-page__input"
          />
          <div className="signup-page__account-code-container">
            <Input
              name="traffic_code"
              placeholder={formatMessage('JTI Account Number')}
              validate={[required, accountNumber]}
              customClass="signup-page__input"
              InputButton={<InfoIcon className="signup-page__info-icon" onClick={openAccountCodeInfoModal} />}
            />
          </div>
          <Input
            name="phone"
            placeholder={formatMessage('Mobile number')}
            validate={[required, lengthPhone(phoneLength, countryCode)]}
            customClass="signup-page__input"
            countryCode={countryCode}
          />
          <Select
            name="position_id"
            placeholder={formatMessage('Job title (optional)')}
            options={positions}
            customClass={`signup-page__input${getSelectFontColor(formObj.values, 'position_id')}`}
          />
          <Input
            name="username"
            placeholder={formatMessage('Profile name (optional)')}
            validate={[characters, lengthMinMax(2, 15, formatMessage)]}
            customClass="signup-page__input"
          />
        </div>
        <div className="signup-page__bottom-section">
          <ArrowButton withoutTheme disabled={invalid} />
        </div>
      </form>
      <DisclaimerFooter withoutNavigation />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { form } = state;
  return {
    formObj: form.signupFormLep || { values: {} },
    initialValues: { phone: '+353 (0) ', traffic_code: '' },
  };
};

const form = reduxForm({
  form: 'signupFormLep',
  destroyOnUnmount: false,
  asyncValidate,
  asyncChangeFields: ['traffic_code'],
  onSubmit: () => {},
})(LepForm);

export const LepEnterFields = connect(mapStateToProps, null)(form);

import { withRouter } from 'react-router-dom';

import './checkInInfoPage.scss';

import { createStepListContainer } from '../../components/containers/StepList/StepListContainer';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { InfoCheckIn } from './infoCheckIn/infoCheckIn';
import { AutoCheckIn } from './autoCheckIn/autoCheckIn';
import { ManualCheckIn } from './manualCheckIn/manualCheckIn';
import { ChoiceCheckIn } from './choiceCheckIn/choiceCheckIn';
import { ScrollBlock } from '../../components/common/ScrollBlock/ScrollBlock';

export const CheckInInfoPageComponent = (props) => {
  const { history } = props;

  const StepList = createStepListContainer(STEP_LIST_IDS.checkInStepList, [
    {
      id: 0,
      Component: () => <InfoCheckIn history={history} />,
    },
    {
      id: 1,
      Component: () => <AutoCheckIn />,
    },
    {
      id: 2,
      Component: () => <ManualCheckIn />,
    },
    {
      id: 3,
      Component: () => <ChoiceCheckIn />,
    },
  ]);

  return (
    <div className="check-in-page">
      <ScrollBlock fromTop>
        <StepList />
      </ScrollBlock>
    </div>
  );
};

export const CheckInInfoPage = withRouter(CheckInInfoPageComponent);

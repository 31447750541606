export const SINGLE_MODE_STEPS = {
  RULES: 1,
  GAME: 2,
  STAGES: 3,
};

export const SINGLE_MODE_ACTION_TYPES = {
  START_SINGLE_MODE: 'singleMode/startSingleMode',
  FINISH_SINGLE_MODE: 'singleMode/finishSingleMode',
  FINISH_STAGE: 'singleMode/finishStage',
  NEXT_STAGE: 'singleMode/nextStage',
  RESET: 'singleMode/reset',
};

import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useHistory } from 'react-router';

import { bonusShopActions } from '../../../redux/bonusShop/bonusShopSlice';
import { FAQ_ARTICLE_ID } from '../../../redux/knowledgeBank/knowledgeBankConstants';
import { knowledgeBankActions } from '../../../redux/knowledgeBank/knowledgeBankSlice';
import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { createStepListContainer } from '../../../components/containers/StepList/StepListContainer';
import { AddCardPage } from '../unicard/unicardAddCard';
import { PhoneNumber } from './PhoneNumber/PhoneNumber';
import { EpulMainPage } from './EpulMainPage/EpulMainPage';
import backgroundImage from '../../../images/background-images/epul-background.png';
import { Routes } from '../../../const';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';

import styles from './Epul.module.scss';

export const epulSteps = {
  addCardPage: 1,
  phoneNumber: 2,
  mainPage: 3,
  settings: 4,
};

export const Epul = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(bonusShopActions.getEpulTransfers({}));
  }, [dispatch]);

  const openHelpArticle = () => {
    const article = {
      id: FAQ_ARTICLE_ID,
      url: 'https://fra1.digitaloceanspaces.com/jti-club-az/bonus-shop/epul-articles/e-pul.html',
    };
    dispatch(knowledgeBankActions.openArticle({ article }));
  };

  const onBackClick = () => {
    history.push(Routes.BonusShopRoute);
  };

  const onButtonClick = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.epulStepsList }));
  };

  const steps = [
    {
      id: epulSteps.addCardPage,
      Component: () => (
        <AddCardPage
          openHelpArticle={openHelpArticle}
          onButtonClick={onButtonClick}
          onBackClick={onBackClick}
          walletLink="https://fra1.digitaloceanspaces.com/jti-club-az/bonus-shop/epul-articles/e-pul-2.html"
          backgroundImage={backgroundImage}
          description="We are syncing EPULWallet with your phone number linked to the app"
          title="Epul"
          walletLinkText="How to create EPULWallet?"
        />
      ),
    },
    {
      id: epulSteps.phoneNumber,
      Component: () => <PhoneNumber />,
    },
    {
      id: epulSteps.mainPage,
      Component: () => <EpulMainPage />,
    },
  ];

  const StepList = createStepListContainer(STEP_LIST_IDS.epulStepsList, steps);

  return (
    <div className={styles.container} style={{ height: isIPhoneWithHomeBar() ? 'calc(100% - 49px)' : '100%' }}>
      <StepList />
    </div>
  );
};

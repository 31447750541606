import { createSelector } from 'reselect';
import { RootState } from '../store';

const getBarcodeInfoSelector = createSelector(
  [(state: RootState) => state.recognition.common.barcode],
  (barcode) => barcode,
);

const getIsFetching = createSelector(
  [(state: RootState) => state.recognition.common.isFetching],
  (isFetching) => isFetching,
);

export const recognitionSelectors = {
  getBarcodeInfoSelector,
  getIsFetching,
};

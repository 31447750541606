import { useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { challengeActions } from '../../redux/challenge/challengeSlice';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { Routes } from '../../const';
import { isHungary, isIreland } from '../../helpers';

import { PlusIcon } from '../../icons/plus-icon';
import { Button } from '../../components/common/Button';

import './getDigitalFramePhoto.scss';

export const TakePhotoPage = (props) => {
  const supported = 'mediaDevices' in navigator;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const filesInputRef = useRef(null);
  const { history, challenge } = props;

  useEffect(() => {
    const player = document.getElementById('player');
    const canvas = document.getElementById('canvas');
    const captureButton = document.getElementById('capture');

    let constraints = {
      video: {
        // width: window.innerWidth,
        // height: window.innerHeight,
        facingMode: {
          exact: 'environment',
        },
      },
    };

    if (supported && player) {
      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        player.srcObject = stream;
      });
    }

    const frame = new Image();
    frame.crossOrigin = 'anonymous';
    frame.onload = () => {
      timerCallback();
    };
    frame.src = challenge?.photo_frame;

    const computeFrame = () => {
      const context = canvas.getContext('2d');
      context.drawImage(player, 0, 0, canvas.width, canvas.height);
      context.drawImage(frame, 0, window.innerHeight - canvas.width * 2.1, canvas.width, canvas.width * 2.1);
    };

    const timerCallback = () => {
      computeFrame();
      setTimeout(() => {
        timerCallback();
      }, 0);
    };

    const captureCallback = () => {
      const data = canvas.toDataURL('image/png');
      dispatch(challengeActions.takePhoto(data));
    };
    captureButton.addEventListener('click', captureCallback);
  }, [supported, dispatch, challenge]);

  const uploadPhotoFromGallery = useCallback(() => {
    const canvas = document.createElement('canvas');
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
    const context = canvas.getContext('2d');

    const files = filesInputRef && filesInputRef.current && filesInputRef.current.files;
    const file = files[0];
    if (!file || !file.type.match(/image.*/)) return;

    const image = new Image();
    const frame = new Image();
    frame.crossOrigin = 'anonymous';
    frame.onload = () => {
      context.drawImage(frame, 0, window.innerHeight - canvas.width * 2.1, canvas.width, canvas.width * 2.1);
      dispatch(challengeActions.takePhoto(canvas.toDataURL('image/png')));
    };

    image.src = URL.createObjectURL(file);
    image.onload = () => {
      if (image.width > image.height) {
        const ratio = window.innerWidth / window.innerHeight;
        const resizedWidth = image.height * ratio;
        const sx = (image.width - resizedWidth) / 2;
        context.drawImage(image, sx, 0, resizedWidth, image.height, 0, 0, canvas.width, canvas.height);
      } else {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
      }
      frame.src = challenge?.photo_frame;
    };
  }, [dispatch, challenge]);

  const goBack = useCallback(() => {
    const regionsWithTeamChallenge = isHungary() || isIreland();
    history.replace(regionsWithTeamChallenge ? `${Routes.ChallengeRoute}/${challenge.id}` : Routes.ChallengeRoute);
  }, [history]);

  return (
    <div className="get-digital-frame-photo">
      <div className="get-digital-frame-photo__mask">
        <div className="get-digital-frame-photo__mask__top">
          <div className="get-digital-frame-photo__mask__close-icon-container">
            <PlusIcon className="get-digital-frame-photo__mask__close-icon" onClick={goBack} />
          </div>
        </div>
        <div className="get-digital-frame-photo__mask__bottom">
          <Button
            className="get-digital-frame-photo__upload-button"
            onClick={(e) => {
              filesInputRef.current.click();
            }}
          >
            {formatMessage('Upload from gallery')}
            <input
              type="file"
              id={'challenge-page-file'}
              hidden={true}
              ref={filesInputRef}
              onChange={uploadPhotoFromGallery}
              accept="image/*"
            />
          </Button>
          <Button className="get-digital-frame-photo__photo-button" id="capture">
            {formatMessage('Take a photo')}
          </Button>
        </div>
      </div>
      <img alt="" />
      <canvas
        className="get-digital-frame-photo__canvas"
        id="canvas"
        width={window.innerWidth}
        height={window.innerHeight}
      />
      <video
        className="get-digital-frame-photo__video-player"
        id="player"
        controls
        autoPlay
        muted="muted"
        playsInline
      />
    </div>
  );
};

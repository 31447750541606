import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

import './home.scss';

import { dailyRewardsActions } from '../../redux/dailyRewards/dailyRewardsSlice';
import { MainScreenCard } from '../../components';
import { Header } from '../../components/common/Header';
import { ScrollBlock } from '../../components/common/ScrollBlock';
import { analyticHelper } from '../../helpers/analyticHelper';
import { NavigationMenu } from '../../components';
import { DisclaimerFooter } from '../../components/disclaimerFooter/disclaimerFooter';
import { isMobileSafari } from '../../helpers/checkIsSafariBrowser';
import { appConstants } from '../../const/appConstants';
import { SelectGameMode } from '../../components/selectGameMode/selectGameMode';
import { brandsActions } from '../../redux/brands/brandsSlice';
import { getBrandsInfoSelector, getIsFetching } from '../../redux/brands/brandSelectors';
import { ContentSpinner } from '../../components/common/Spinner/ContentSpinner';
import { useInternationalization } from '../../hooks/useTranslationHook';
import { isFeatureEnabled, CONFIG_KEYS } from '../../config';

export const HomeComponent = (props) => {
  const { formatMessage } = useInternationalization();
  const { history } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dailyRewardsActions.startDailyRewards());
    analyticHelper.mainPageOpen();
  }, [dispatch]);

  useEffect(() => {
    dispatch(brandsActions.getBrandsGames());
  }, [dispatch]);

  const brands = useSelector(getBrandsInfoSelector);
  const isFetching = useSelector(getIsFetching);

  return (
    <div className="home-page">
      <ScrollBlock fromTop>
        <Header hasBackButton>
          <p className="home-page__header-text">{formatMessage('Brand quiz catalogue')}</p>
        </Header>
        {isFetching ? (
          <ContentSpinner />
        ) : (
          <>
            {isFeatureEnabled(CONFIG_KEYS.QUIZ_MODE_SWITCHER_ENABLED) && <SelectGameMode />}
            <div
              className={classnames('home-page__content-area', {
                'home-page__without-alignment': isFeatureEnabled(CONFIG_KEYS.QUIZ_CARD_VIEW_ENABLED),
              })}
              style={{ minHeight: isMobileSafari() ? 'calc(100% - 165px)' : 'unset' }}
            >
              {brands.map((brand) => (
                <MainScreenCard key={brand.id} brand={brand} history={history} />
              ))}
            </div>
          </>
        )}
        <NavigationMenu location={props.location} />
        <DisclaimerFooter />
      </ScrollBlock>
    </div>
  );
};

export const Home = withRouter(HomeComponent);

import { useSelector, useDispatch } from 'react-redux';

import './LoyaltyRewardModal.scss';

import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { MODALS } from '../../containers/Modal/modalConstants';
import { getWordsFormat } from '../../../helpers/getWordsFormat';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../common/Button';
import { getModalDataSelector } from '../../containers/Modal/modalSelectors';
import { modalActions } from '../../containers/Modal/modalSlice';
import rewardImage from './loyalty_reward_picture.png';

const styles = {
  width: 343,
};

export const LoyaltyRewardModal = (props) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const {
    data: { onClose, onButtonClick, coins },
  } = useSelector(getModalDataSelector);

  const handleButtonClick = () => {
    if (onButtonClick) {
      onButtonClick();
      return;
    }

    dispatch(modalActions.closeModal());
  };
  return (
    <ModalContainer modal={MODALS.loyaltyReward} onClose={onClose} customStyles={styles}>
      <div className="loyalty-reward">
        <div>
          <img className="loyalty-reward__reward-image" src={rewardImage} alt="reward" />
        </div>
        <div className="loyalty-reward__reward">
          {formatMessage(getWordsFormat(coins, `+{coins} points`), { coins })}
        </div>
        <div className="loyalty-reward__description">{formatMessage('Congratulations! The reward is yours!')}</div>
        <Button onClick={handleButtonClick} customClass="loyalty-reward__btn">
          {formatMessage('Claim')}
        </Button>
      </div>
    </ModalContainer>
  );
};

import { useDispatch } from 'react-redux';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const AchievementsTutorialStep3 = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const handleSetStepTutorial = () => {
    dispatch(stepListActions.nextStep({ stepListId: STEP_LIST_IDS.achievementsTutorialStepList }));
  };

  return (
    <>
      <div className="achievements-tutorial__blackout-firstTaskItem-top" />
      <div className="achievements-tutorial__blackout-firstTaskItem-left" />
      <div className="achievements-tutorial__blackout-firstTaskItem-right" />
      <div className="achievements-tutorial__blackout-firstTaskItem-bottom" />
      <div className="achievements-tutorial__lockClick-firstTaskItem" />
      <div className="achievements-tutorial__descriptionBox-bottom">
        <div className="achievements-tutorial__descriptionBox-step">3/4</div>
        <div className="achievements-tutorial__descriptionBox-header">{formatMessage('Choice to made')}</div>
        <div className="achievements-tutorial__descriptionBox-description">
          {formatMessage('Choose the task you want to complete from the list and track your progress')}
        </div>
      </div>
      <ArrowButton customClass={'achievements-tutorial__button-next'} onClick={() => handleSetStepTutorial(4)} />
    </>
  );
};

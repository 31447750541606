import { applyMiddleware, createStore } from 'redux';
import { save, load } from 'redux-localstorage-simple';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import LogRocket from 'logrocket';

import { rootSaga } from './rootSaga';
import { createRootReducer } from './rootReducer';
import history from '../helpers/history';

const sagaMiddleware = createSagaMiddleware();
const rootReducer = createRootReducer(history);

export const store = createStore(
  rootReducer,
  load({
    states: [
      'brands.brandsInfo',
      'knowledgeBank.categories',
      'knowledgeBank.selectedArticle',
      'knowledgeBank.salesTips',
      'bonusShop.categories',
      'bonusShop.currentCategoryId',
      'settings.gameMode',
      'mainPage.currentComponent',
      'dailyGames.currentDailyGame',
      'mysteryShopper',
    ],
  }),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      sagaMiddleware,
      save({
        states: [
          'brands.brandsInfo',
          'knowledgeBank.categories',
          'knowledgeBank.selectedArticle',
          'knowledgeBank.salesTips',
          'bonusShop.categories',
          'bonusShop.currentCategoryId',
          'settings.gameMode',
          'mainPage.currentComponent',
          'dailyGames.currentDailyGame',
          'mysteryShopper',
        ],
      }),
      LogRocket.reduxMiddleware(),
    ),
  ),
);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

import { all, put, takeEvery, takeLeading, delay, select } from 'redux-saga/effects';

import { SINGLE_MODE_ACTION_TYPES, SINGLE_MODE_STEPS } from './singleModeConstants';
import { singleModeActions } from './singleModeSlice';
import { stepListActions } from '../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../components/containers/StepList/stepListConstants';
import { quizApi } from '../../api/quizApi';
import {
  getSingleModeCurrentStageSelector,
  isStageSuccessSelector,
  getFinishedStagesCount,
  getSingleModePackIdSelector,
} from './singleModeSelectors';

export const goToSingleModeStepAction = stepId =>
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.singleModeStepList,
    stepId,
  });

export const resetSingleModeStepList = () => {
  stepListActions.setStep({
    stepListId: STEP_LIST_IDS.singleModeStepList,
    stepId: 1,
  });
};

function* onSingleModeStartSaga() {
  try {
    const { data } = yield quizApi.startSingleMode();
    yield put(singleModeActions.setSingleGameModeData(data));
    yield put(goToSingleModeStepAction(SINGLE_MODE_STEPS.GAME));
  } catch (error) {
    console.error(error);
  }
}

function* onSingleModeFinishStageSaga() {
  yield delay(1000);
  const isStageSuccess = yield select(isStageSuccessSelector);
  if (isStageSuccess) {
    const currentStage = yield select(getSingleModeCurrentStageSelector);
    yield put(singleModeActions.setFinishedStages({ stage: currentStage + 1 }));
  }
  yield put(goToSingleModeStepAction(SINGLE_MODE_STEPS.STAGES));
}

function* onSingleModeNextStageSaga() {
  const currentStage = yield select(getSingleModeCurrentStageSelector);
  yield put(singleModeActions.resetAnswers());
  yield put(singleModeActions.setStage({ stage: currentStage + 1 }));
  yield put(goToSingleModeStepAction(SINGLE_MODE_STEPS.GAME));
}

function* onSingleModeFinishSaga() {
  try {
    const finishedStages = yield select(getFinishedStagesCount);
    const packId = yield select(getSingleModePackIdSelector);
    yield quizApi.finishSingleMode(finishedStages, packId);
  } catch (error) {
    console.error(error);
  }
}

function* onResetSingleModeSaga() {
  yield put(
    stepListActions.setStep({
      stepListId: STEP_LIST_IDS.singleModeStepList,
      stepId: 1,
    }),
  );
}

function* sagas() {
  yield all([
    takeEvery(SINGLE_MODE_ACTION_TYPES.START_SINGLE_MODE, onSingleModeStartSaga),
    takeLeading(SINGLE_MODE_ACTION_TYPES.FINISH_STAGE, onSingleModeFinishStageSaga),
    takeEvery(SINGLE_MODE_ACTION_TYPES.NEXT_STAGE, onSingleModeNextStageSaga),
    takeLeading(SINGLE_MODE_ACTION_TYPES.FINISH_SINGLE_MODE, onSingleModeFinishSaga),
    takeEvery(SINGLE_MODE_ACTION_TYPES.RESET, onResetSingleModeSaga),
  ]);
}

export const singleModeSagas = sagas;

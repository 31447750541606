import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Layout } from '../../views';
import { AnimationModel, Screen } from '../../components';
import { BARCODE_PAGE, ANIMATION_PAGE } from '../../constants/general';
import { analyticHelper } from '../../../../helpers/analyticHelper';
import { getUserIdSelector } from '../../../../redux/auth/authSelectors';

export const Recognition = () => {
  let renderComponent = null;
  const userId = useSelector(getUserIdSelector);

  const [changeComponentState, setChangeComponentState] = useState(BARCODE_PAGE);

  switch (changeComponentState) {
    case BARCODE_PAGE:
      renderComponent = <Screen setChangeComponent={setChangeComponentState} />;
      break;
    case ANIMATION_PAGE:
      analyticHelper.onOpenProductInfoPage(userId);
      analyticHelper.onArAnimationScreen(userId);
      renderComponent = <AnimationModel setChangeComponent={setChangeComponentState} />;
      break;

    default:
      renderComponent = null;
      break;
  }

  return <Layout>{renderComponent}</Layout>;
};

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import './infoCheckIn.scss';

import { useInternationalization } from '../../../hooks/useTranslationHook';
import { Button } from '../../../components/common/Button';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { ArrowIconRounded } from '../../../icons/arrow-icon-rounded';
import { stepListActions } from '../../../components/containers/StepList/stepListSlice';
import { STEP_LIST_IDS } from '../../../components/containers/StepList/stepListConstants';
import infoCheckInImage from './infoCheckInImage.png';

export const InfoCheckIn = (props) => {
  const { history } = props;
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const handleArrowButtonClick = useCallback(() => {
    dispatch(
      stepListActions.setStep({
        stepListId: STEP_LIST_IDS.checkInStepList,
        stepId: 1,
      }),
    );
  }, [dispatch]);

  const onBackCallback = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div className="info-check-in__component">
      <div className="check-in__top-section">
        <Button
          withoutTheme
          type="button"
          className="check-in__top-button"
          onClick={() => {
            onBackCallback();
          }}
        >
          <ArrowIconRounded fillColor="#fff" className="check-in__top-arrow" />
        </Button>
        <div className="check-in__img-section">
          <img alt="img" src={infoCheckInImage} className="check-in__image" />
        </div>
        <h1 className="check-in__title">{formatMessage('Check-in')}</h1>
        <p className="check-in__pre-title">{formatMessage("Check-in (eng. 'check-in')")}</p>
        <div className="check-in__description-section">
          <h2 className="check-in__bold-text">{formatMessage('What is it?')}</h2>
          <p className="check-in__text">
            {formatMessage(
              "When registered in the application, you indicated the address of your workplace. Using geolocation, we will make sure that you work at the specified address and haven't changed the type of activity.",
            )}
          </p>
          <p className="check-in__text">
            {formatMessage(
              'Remember: this App is available only to retailers working with tobacco products. The check-in confirms this status, so its regular passage is the main condition for using jti-club.',
            )}
          </p>

          <h2 className="check-in__bold-text">{formatMessage('How to pass?')}</h2>
          <p className="check-in__text">
            1. {formatMessage('Open for the application access to the geolocation of your phone.')} <br />
            2. {formatMessage('Install 4 geomarks per week in your workplace.')} <br />
            3. {formatMessage('Check in every two months.')} <br />
          </p>
          <p className="check-in__text">
            {formatMessage(
              'If you miss one of these steps, we, unfortunately, will be forced to block access to the application for you.',
            )}
          </p>
          <p className="check-in__text">
            {formatMessage('If you complete the check-in, you will have access to the application Bonus Shop.')}
          </p>
          <p className="check-in__text">{formatMessage('Contact your JTI representative for more information.')}</p>
        </div>
        <div className="check-in__bot-section">
          <ArrowButton withoutTheme onClick={() => handleArrowButtonClick()}></ArrowButton>
        </div>
      </div>
    </div>
  );
};

import { useHistory } from 'react-router-dom';
import { Button } from '../CommonButton/Button';

import { ButtonType } from '../CommonButton/types';

type Props = {
  className?: string;
  onClick?: () => void;
};

export const BackButton = ({ className, onClick }: Props) => {
  const history = useHistory();

  return <Button type={ButtonType.Arrow} onClick={onClick ? onClick : history.goBack} className={className} />;
};

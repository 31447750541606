import { useEffect } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import { username, lengthPhone, lengthMinMax, letters, required } from '../../../helpers/formValidations';
import { APP_TYPES } from '../../../const/appConstants';
import { Input } from '../../../components/common/Input';
import { getPhoneCode } from '../../../redux/auth/authConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { selfRegistrationActions } from '../../../redux/selfRegistration/selfRegistrationSlice';
import { IRefferalUser } from '../../../redux/selfRegistration/selfRegistrationTypes';
import { PhoneNumberInput } from '../../../components/common/PhoneNumberInput';
import { modalActions } from '../../../components/containers/Modal/modalSlice';
import { isUserSuccessfullyCreated } from '../../../redux/selfRegistration/selfRegistrationSelectors';

import styles from './selfRegistrationForm.module.scss';

interface ISelfRegistrationForm {
  inviteCode: string | null;
  goBackHanlder: () => void;
}

const SelfRegistration = ({
  inviteCode,
  invalid,
  handleSubmit,
  goBackHanlder,
}: InjectedFormProps<ISelfRegistrationForm> & any) => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();
  const isUserCreated = useSelector(isUserSuccessfullyCreated);
  const phoneLength = process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA ? 8 : 9;
  const phoneCode = getPhoneCode();

  const submit = (formData: IRefferalUser) => {
    const phone = `${phoneCode}${formData.phone}`;

    dispatch(
      modalActions.openPromptWithTwoButtonsModal({
        title: phone,
        description: formatMessage('self_registration.modal.description'),
        btnText: formatMessage('self_registration.modal.btn_edit'),
        anotherBtnText: formatMessage('self_registration.modal.btn_finish'),
        onButtonClick: () => {
          dispatch(modalActions.closeModal());
        },
        onAnotherButtonClick: () => {
          dispatch(
            selfRegistrationActions.initUserCreate({
              ...formData,
              invite_code: inviteCode,
              phone,
            }),
          );
        },
      }),
    );
  };

  useEffect(() => {
    if (isUserCreated) {
      goBackHanlder();
      dispatch(modalActions.closeModal());
    }
  }, [isUserCreated]);

  return (
    <form className={styles.selfRegistrationFormContainer} onSubmit={handleSubmit(submit)}>
      <div>
        <h2 className={styles.selfRegistrationTitle}>{formatMessage('self_registration.title')}</h2>
        <Input
          name="surname"
          customClass={styles.registrationInput}
          validate={[required, letters, lengthMinMax(1, 20, formatMessage)]}
          placeholder={formatMessage('self_registration.surname')}
        />
        <Input
          name="name"
          customClass={styles.registrationInput}
          validate={[required, letters, lengthMinMax(1, 20, formatMessage)]}
          placeholder={formatMessage('self_registration.name')}
        />
        <PhoneNumberInput
          name="phone"
          customClass={styles.phoneNumber}
          placeholder={formatMessage('self_registration.phone')}
          containerClass={styles.phoneNumberContainer}
          validate={[required, lengthPhone(phoneLength, '')]}
        />
      </div>

      <div className={styles.submitBtnContainer}>
        <ArrowButton
          withoutTheme
          customClass={classnames(styles.submitBtn, {
            [styles.disabled]: invalid,
          })}
          disabled={invalid}
        />
      </div>
    </form>
  );
};

export const SelfRegistrationForm = reduxForm<{}, ISelfRegistrationForm>({
  form: 'selfRegistrationForm',
  destroyOnUnmount: false,
  onSubmit: () => {},
})(SelfRegistration);

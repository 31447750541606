export enum TaskTypes {
  PlayedQuizzesNumber = 1,
  QuizzesOnTopicNumber = 2,
  WonQuizzesNumber = 3,
  KudosNumber = 4,
  PurchasedGiftsNumber = 5,
  Custom = 6,
  TakenPartLotteriesNumber = 7,
  ReadArticlesNumber = 8,
  SpecialQuizzesNumber = 9,
  SpentCoinsNumber = 10,
  MultibrandQuizzesNumber = 11,
  DailyQuestionsPlayedNumber = 12,
  DailyQuestionsWonNumber = 13,
  KBGamesWonNumber = 14,
  SingleModePlayedNumber = 15,
  SingleModePlayedInRowNumber = 16,
  WonKnowledgeBaseEntity = 17,
  KnowledgeBaseEntity = 18,
  WonDailyQuestion = 19,
  DailyQuestion = 20,
  SpecialQuiz = 21,
  Lottery = 22,
  SalesTips = 23,
  CustomTextManual = 24,
  CustomTextAuto = 25,
  LinkToChat = 26,
  NumberOfWonQuickTips = 27,
  NumberOfPlayedQuickTips = 28,
}

export enum TaskStatus {
  Uncompleted,
  Completed,
  Checking,
}

export interface RawChallenge {
  id: number;
  name: string;
  description: string;
  start_date: string;
  end_date: string;
  attachments_enabled: boolean;
  photo_frame: string;
  products: IProduct[];
  tasks: ITask[];
  type: number;
  background_image: string;
  users_avatars: Array<string>;
}

export interface IChallenge {
  id: number;
  name: string;
  description: string;
  startDate: string;
  end_date: string;
  attachmentsEnabled: boolean;
  photo_frame: string;
  products: IProduct[];
  tasks: ITask[];
  isTeamChallenge: boolean;
  backgroundImage: string;
  usersAvatars?: Array<string>;
}

export interface IProduct {
  id: number;
  type: number;
  coins: number;
  tasks_to_complete: number | null;
  photo: string;
  name: string;
  description: string;
  is_taken: boolean;
}

export interface ITask {
  id: number;
  description: string;
  photo_frame: string | null;
  done: boolean;
  type: number;
  required_task_count: number;
  status: TaskCardStatus;
  progress: number;
  entity_id: number;
  category_id: number;
}

export enum TaskCardStatus {
  Uncompleted,
  Completed,
  Checking,
}

export enum CustomTaskTypesWithSalesTips {
  Custom = 6,
  SalesTips = 23,
  CustomTextManual = 24,
  CustomTextAuto = 25,
}

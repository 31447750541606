import { ReactElement, MouseEvent } from 'react';
import classNames from 'classnames';

import styles from './LoaderContainer.module.scss';
import { Spinner } from '../Spinner/Spinner';

type Props = {
  isLoading: boolean;
  isContentVisible?: boolean;
  className?: string;
  children: ReactElement;
};

export const LoaderContainer = ({ isLoading, children, className }: Props) => {
  const onContentClick = (event: MouseEvent) => event.preventDefault();

  if (isLoading) {
    return (
      <div className={classNames(styles.loaderContainer, className)} onClick={onContentClick}>
        <Spinner style={{ width: '100px' }} />
      </div>
    );
  }

  return children;
};

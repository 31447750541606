import { useState } from 'react';
import classnames from 'classnames';

import defaultNotificationImg from '../../images/defaultNotificationImg.svg';
import styles from './NotificationBlock.module.scss';

type NotificationBlockProps = {
  isClickable: boolean;
  onButtonClick: () => void;
  status: boolean;
  date: string;
  name: string;
  className?: string;
  image?: string;
  time?: string;
  caption?: string;
  text?: string;
};

const NotificationBlock = ({
  isClickable,
  image,
  time,
  onButtonClick,
  caption,
  text,
  status,
  date,
  name,
}: NotificationBlockProps) => {
  const [isNewNotification, setIsNewNotification] = useState<boolean>(!!status || false);

  const onClickHandler = (): void => {
    if (isClickable) {
      onButtonClick();
    }
    if (!!status) {
      setIsNewNotification(false);
    }
  };

  return (
    <div
      className={classnames(styles.notificationMessageBlock, isNewNotification ? styles.newNotification : '')}
      onClick={onClickHandler}
    >
      <div className={styles.notificationMessageContent}>
        <div className={styles.notificationMessageImageBlock}>
          <img className={styles.image} src={image ? image : defaultNotificationImg} />
        </div>
        <div className={styles.notificationMessageTextBlock}>
          <div className={styles.time}>{`${date} ${time}`}</div>
          {caption && <div className={styles.caption}>{caption}</div>}
          {text && <div className={styles.text}>{text.replace('[name]', name)}</div>}
        </div>
      </div>
    </div>
  );
};

export default NotificationBlock;

import { ChatMessage, Problem, RawChatMessage, RawProblem, RawTicket, Ticket } from './chatTypes';

export const mapMessages = (messages: RawChatMessage[]): ChatMessage[] => messages.map(mapMessage);

export const mapMessage = (message: RawChatMessage): ChatMessage => {
  const {
    id,
    type,
    created_at,
    message: { text, caption, link, image, article, code, category, daily_game, button_text, task_id, challenge_id },
    user_id,
    ticket_id,
    status,
  } = message;

  return {
    id,
    type,
    text,
    caption,
    link,
    image,
    createdAt: created_at,
    code,
    article,
    ticketId: ticket_id,
    isAdmin: user_id === 1,
    status,
    categoryId: category?.id,
    brandId: article?.brand?.id,
    dailyGame: daily_game,
    buttonText: button_text,
    taskId: task_id,
    challengeId: challenge_id,
  };
};

export const mapTickets = (tickets: RawTicket[]): Ticket[] => tickets.map(mapTicket);

export const mapTicket = (ticket: RawTicket): Ticket => {
  const { id, created_at, last_message_date, ticket_step, problem, status, user, has_new_messages } = ticket;

  return {
    id,
    name: problem?.name,
    status,
    user,
    createdAt: created_at,
    problemId: ticket_step,
    lastMessageDate: last_message_date,
    hasNewMessages: has_new_messages,
  };
};

export const mapProblems = (problems: RawProblem[]): Problem[] => problems.map(mapProblem);

export const mapProblem = (problem: RawProblem): Problem => {
  const { parent_id, image, id, level, url, name, should_start_chat } = problem;

  return {
    id,
    level,
    url,
    name,
    image,
    shouldStartChat: should_start_chat,
    parentId: parent_id,
  };
};

export const ascDateComparer = (a: string, b: string) => {
  return new Date(a).valueOf() - new Date(b).valueOf();
};

export const descDateComparer = (a: string, b: string) => {
  return new Date(b).valueOf() - new Date(a).valueOf();
};

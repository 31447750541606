import { useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { challengeSelectors } from '../../../../redux/challenge/challengeSelectors';
import { challengeActions } from '../../../../redux/challenge/challengeSlice';
import { useInternationalization } from '../../../../hooks/useTranslationHook';

import { TeamChallenge } from '../index';
import { Header } from '../../../../components/common/Header';
import { LoaderContainer } from '../../../../components/common';
import { TaskBlock } from '../../components';
import { RemainedTime } from '../../components/timeRemain/RemainedTime';
import { PersonalChallenge } from '../../components/personalChallenge/PersonalChallenge';

import styles from './QuestChallenge.module.scss';

export const QuestChallenge = ({ history }: RouteComponentProps) => {
  const { formatMessage } = useInternationalization();
  const dispatch = useDispatch();
  const { challengeId } = useParams<{ challengeId: string }>();
  const challenge = useSelector(challengeSelectors.selectCurrentChallenge);
  const isFetching = useSelector(challengeSelectors.selectIsFetching);

  useEffect(() => {
    dispatch(challengeActions.getChallengeById({ id: parseInt(challengeId, 10) }));
  }, []);

  const headerTitle = challenge?.isTeamChallenge ? 'Team challenge' : 'Challenge';

  const ChallengeComponent = challenge?.isTeamChallenge ? TeamChallenge : PersonalChallenge;

  return (
    <div className={styles.challenge}>
      <Header hasBackButton goBackCallback={() => history.goBack()} buttonColor="white">
        <p className={styles.headerTitle}>{formatMessage(headerTitle)}</p>
      </Header>
      <LoaderContainer isLoading={isFetching} className={styles.loaderContainer}>
        <div className={styles.content}>
          {challenge && <RemainedTime challenge={challenge} />}
          {challenge && (
            <ChallengeComponent
              description={challenge.description}
              name={challenge.name}
              avatars={challenge.usersAvatars}
              photo={challenge.products[0]?.photo}
            />
          )}
          {challenge && challenge.tasks && (
            <TaskBlock
              tasks={challenge.tasks}
              photoFrame={challenge?.photo_frame}
              challengeId={challenge.id}
              attachmentsButtonEnabled={challenge.attachmentsEnabled}
            />
          )}
        </div>
      </LoaderContainer>
    </div>
  );
};

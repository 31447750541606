import './warningFooter.scss';

import { isIPhoneWithHomeBar } from '../../../helpers/checkIsIphoneX';
import { APP_TYPES } from '../../../const/appConstants';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import footerText from '../../../images/footer-text.png';
import { FooterBy } from '../../../icons/footer-by-text';
import { CONFIG_KEYS, isFeatureEnabled } from '../../../config';

export const WarningFooter = () => {
  const { formatMessage } = useInternationalization();

  const takeFooter = () => {
    switch (process.env.REACT_APP_APP_TYPE) {
      case APP_TYPES.HUNGARY:
        return (
          <div className="footer__container footer__container__hungary">
            <img alt="img" src={footerText} className="footer__image" />
          </div>
        );
      case APP_TYPES.IRELAND:
        return (
          <div className="footer__container">
            <h2 className="footer__text">
              {formatMessage('Result of smoking - death')}. <br />
              {formatMessage('Smoking kills')}.
            </h2>
          </div>
        );
      case APP_TYPES.ARMENIA:
      case APP_TYPES.AZERBAIJAN:
      case APP_TYPES.GEORGIA:
        return null;
      case APP_TYPES.BELARUS:
        return (
          <div className="footer__container__by">
            <FooterBy />
          </div>
        );
      case APP_TYPES.DEMO:
        return (
          <div className="footer__container">
            <h2 className="footer__text">Health Warning</h2>
          </div>
        );
      default:
        return (
          <div className="footer__container">
            <h2 className="footer__text">
              {formatMessage('Result of smoking - death')}. <br />
              {formatMessage('Smoking kills')}.
            </h2>
          </div>
        );
    }
  };

  return <>{isFeatureEnabled(CONFIG_KEYS.CIGARETTES_STYLE_WARNING_ENABLED) && takeFooter()}</>;
};

import { FooterBy } from '../../icons/footer-by-text';
import './KBBrands.scss';

export const KBBrandsBelarus = ({ brands, selectKBBrand }) => {
  return (
    <div className="KB-choose-brand__brands_belarus">
      {brands &&
        brands.length &&
        brands.map((brand) => (
          <div
            key={brand.id}
            className="KB-choose-brand__brands-brand_belarus"
            src={brand.icon}
            style={{ backgroundImage: `url(${brand.icon})` }}
            alt="KB_brands"
            onClick={() => selectKBBrand(brand.id)}
          />
        ))}
      <div className="KB-choose-brand__warning_belarus">
        <FooterBy />
      </div>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';
import { ColorThemes } from '../../const';

const initialState = {
  positions: [],
  managersCities: [],
  managers: [],
  links: {},
  theme: {},
  monthlyLimitation: {},
  outlets: [],
  cities: [],
  legal_entities: [],
  addresses: [],
  availableManager: null,
  isFetching: null,
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    getPositions() {},
    setPositions(state, action) {
      const { positions } = action.payload;
      return {
        ...state,
        positions,
      };
    },
    setLinks(state, action) {
      const { links } = action.payload;
      return {
        ...state,
        links,
      };
    },
    getLinks() {},
    getManagersCities() {},
    setManagersCities(state, action) {
      const { managersCities } = action.payload;
      return {
        ...state,
        managersCities,
      };
    },
    getManagers() {},
    setManagers(state, action) {
      const { managers } = action.payload;
      return {
        ...state,
        managers,
      };
    },
    uploadPhoto(formData) {},
    setThemeLocal(themeId) {},
    setThemeId(state, action) {
      const { themeId } = action.payload;
      return {
        ...state,
        theme: ColorThemes.find((theme) => theme.id === themeId),
      };
    },
    getMonthlyLimitation() {},
    setMonthlyLimitation(state, { payload }) {
      return {
        ...state,
        monthlyLimitation: payload,
      };
    },
    getOutletsInfo() {},
    setOutletsInfo(state, action) {
      return {
        ...state,
        outlets: action.payload,
      };
    },
    getCitiesInfo() {},
    setCitiesInfo(state, action) {
      return {
        ...state,
        cities: action.payload,
      };
    },
    getLegalEntities() {},
    setLegalEntities(state, action) {
      return {
        ...state,
        legal_entities: action.payload,
      };
    },
    getAddresses() {},
    setAddresses(state, action) {
      return {
        ...state,
        addresses: action.payload,
      };
    },
    getManager() {},
    setManager(state, action) {
      return {
        ...state,
        availableManager: action.payload,
      };
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
  },
});

export const serviceActions = serviceSlice.actions;

export default serviceSlice.reducer;

import PropTypes from 'prop-types';

import './enterPhonePage.scss';
import { useInternationalization } from '../../../hooks/useTranslationHook';
import { APP_TYPES } from '../../../const/appConstants';
import { required, length } from '../../../helpers/formValidations';
import { PhoneNumberInput } from '../../../components/common/PhoneNumberInput';
import { ArrowButton } from '../../../components/common/ArrowButton/ArrowButton';
import { WarningFooter } from '../warningFooter/warningFooter';
import useFeatureToggles from '../../../hooks/useFeatureToggles';
import { toInternational } from '../../../helpers/numberFormatter';

export const EnterPhonePage = ({ formObj, handleSubmit, sendPhone, authByPhone, invalid }) => {
  const { formatMessage } = useInternationalization();
  const { isAuthDisabled } = useFeatureToggles();
  const phoneLength = process.env.REACT_APP_APP_TYPE === APP_TYPES.ARMENIA ? 8 : 9;

  const handlePhoneNumber = () => {
    const phone = toInternational(formObj.values.phone);

    isAuthDisabled ? authByPhone(phone) : sendPhone(phone);
  };

  return (
    <form onSubmit={handleSubmit} className="enter-phone-page">
      <div className="enter-phone-page__top-section">
        <h1 className="enter-phone-page__title">{formatMessage('Enter your phone number')}</h1>
        <PhoneNumberInput
          validate={[required, length(phoneLength)]}
          customClass="enter-phone-page__phone"
          name="phone"
          codeZero={
            process.env.REACT_APP_APP_TYPE === APP_TYPES.IRELAND || process.env.REACT_APP_APP_TYPE === APP_TYPES.DEMO
          }
        />
        <p className="enter-phone-page__description">
          {formatMessage('A verification code will be sent to the specified number to access the application')}
        </p>
      </div>
      <div className="enter-phone-page__bot-section">
        <ArrowButton withoutTheme disabled={invalid} onClick={handlePhoneNumber} />
        <p className="enter-phone-page__text">
          {formatMessage(
            'This application is for professional tobacco retailers only. Please use it only on your phone and don’t show it to consumers. If you are not longer working as a shop assistant, please delete your account and the application itself.',
          )}
        </p>
        {process.env.REACT_APP_APP_TYPE !== APP_TYPES.BELARUS ? <WarningFooter /> : null}
      </div>
    </form>
  );
};

EnterPhonePage.propTypes = {
  formObj: PropTypes.object.isRequired,
  sendPhone: PropTypes.func.isRequired,
  authByPhone: PropTypes.func.isRequired,
};

import './quizGameErrorModal.scss';

import { MODALS } from '../../containers/Modal/modalConstants';
import { ModalContainer } from '../../containers/Modal/ModalContainer';
import { useDispatch } from 'react-redux';
import { Button } from '../../common/Button/Button';
import { modalActions } from '../../containers/Modal/modalSlice';
import { useInternationalization } from '../../../hooks/useTranslationHook';

export const QuizGameErrorModal = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useInternationalization();

  const onButtonClick = () => {
    dispatch(modalActions.closeModal());
  };

  return (
    <ModalContainer modal={MODALS.quizGameError}>
      <div className="quizGame-modal">
        <div className="quizGame-modal__title">
          {formatMessage('Oops! We have some technical issues right now. Please, try again')}
        </div>
        <Button className="quizGame-modal__btn" onClick={onButtonClick}>
          {formatMessage('OK')}
        </Button>
      </div>
    </ModalContainer>
  );
};
